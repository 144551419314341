import {
  CreateOrganizationPageLayoutRequest,
  Field,
  ObjectClassDefinition,
  PageLayout,
} from "../../../../api/types/objectTypes";

export interface ObjectLayoutState {
  configurationId: string;
  objectDefinition: ObjectClassDefinition;
  fieldGroups: LayoutFieldGroup[];
  isDirty: boolean;
  isAddingFieldToGroup?: string;
}

export interface LayoutFieldGroup {
  name: string;
  fields: LayoutField[];
}

export interface LayoutField extends Field {
  isVisible: boolean;
}

export const getInitialState = (
  objectDefinition: ObjectClassDefinition,
  configurationId: string
): ObjectLayoutState => {
  return {
    configurationId,
    objectDefinition,
    fieldGroups: [],
    isDirty: false,
  };
};

// Actions

type StateAction = (state: ObjectLayoutState) => ObjectLayoutState;

export const initLayoutAction =
  (layout: PageLayout): StateAction =>
  (state) => {
    const fieldGroups = layout.fieldGroups.map((fieldGroup) => {
      const fields = fieldGroup.fields.reduce<LayoutField[]>((result, field) => {
        const fieldDefinition = state.objectDefinition.fields.find((f) => f.id === field.fieldId);
        if (fieldDefinition !== undefined) {
          result.push({
            ...fieldDefinition,
            isVisible: field.isVisible,
          });
        }

        return result;
      }, []);

      return {
        name: fieldGroup.name,
        fields,
      };
    });

    return {
      ...state,
      fieldGroups,
      isDirty: false,
    };
  };

export const toggleFieldVisibilityAction =
  (fieldId: string): StateAction =>
  (state) => {
    const fieldGroups = state.fieldGroups.map((fieldGroup) => {
      const fields = fieldGroup.fields.map((field) =>
        field.id === fieldId ? { ...field, isVisible: !field.isVisible } : field
      );
      return { ...fieldGroup, fields };
    });

    return {
      ...state,
      fieldGroups,
      isDirty: true,
    };
  };

export const toggleGroupVisibilityAction =
  (groupName: string): StateAction =>
  (state) => {
    const fieldGroups = state.fieldGroups.map((fieldGroup) => {
      if (fieldGroup.name !== groupName) {
        return fieldGroup;
      }

      const isGroupVisible = fieldGroup.fields.some((field) => field.isVisible);
      const fields = fieldGroup.fields.map((field) => ({ ...field, isVisible: !isGroupVisible }));
      return { ...fieldGroup, fields };
    });

    return {
      ...state,
      fieldGroups,
      isDirty: true,
    };
  };

export const removeFieldAction =
  (fieldId: string): StateAction =>
  (state) => {
    const fieldGroups = state.fieldGroups.map((fieldGroup) => {
      const fields = fieldGroup.fields.filter((field) => field.id !== fieldId);
      return { ...fieldGroup, fields };
    });

    return {
      ...state,
      fieldGroups,
      isDirty: true,
    };
  };

export const removeGroupAction =
  (groupName: string): StateAction =>
  (state) => {
    const fieldGroups = state.fieldGroups.filter((fieldGroup) => fieldGroup.name !== groupName);

    return {
      ...state,
      fieldGroups,
      isDirty: true,
    };
  };

export const addGroupAction =
  (groupName: string): StateAction =>
  (state) => {
    const fieldGroups = [...state.fieldGroups, { name: groupName, fields: [] }];

    return {
      ...state,
      fieldGroups,
      isDirty: true,
    };
  };

export const startAddingFieldAction =
  (groupName: string): StateAction =>
  (state) => ({
    ...state,
    isAddingFieldToGroup: groupName,
  });

export const cancelAddingFieldAction = (): StateAction => (state) => ({
  ...state,
  isAddingFieldToGroup: undefined,
});

export const addFieldAction =
  (groupName: string, fieldId: string): StateAction =>
  (state) => {
    const field = state.objectDefinition.fields.find((f) => f.id === fieldId);
    if (!field) {
      return state;
    }

    const fieldGroups = state.fieldGroups.map((fieldGroup) => {
      if (fieldGroup.name !== groupName) {
        return fieldGroup;
      }

      const fields = [...fieldGroup.fields, { ...field, isVisible: true }];
      return { ...fieldGroup, fields };
    });

    return {
      ...state,
      fieldGroups,
      isDirty: true,
      isAddingFieldToGroup: undefined,
    };
  };

export const renameGroupAction =
  (oldGroupName: string, newGroupName: string): StateAction =>
  (state) => {
    const fieldGroups = state.fieldGroups.map((fieldGroup) =>
      fieldGroup.name === oldGroupName ? { ...fieldGroup, name: newGroupName } : fieldGroup
    );

    return {
      ...state,
      fieldGroups,
      isDirty: true,
    };
  };

export const moveGroupAction =
  (dropIndex: number, group: LayoutFieldGroup): StateAction =>
  (state) => {
    const fieldGroups = [...state.fieldGroups.filter((g) => g.name !== group.name)];

    if (dropIndex >= 0) {
      fieldGroups.splice(dropIndex, 0, group);
    } else {
      fieldGroups.push(group);
    }

    return {
      ...state,
      fieldGroups,
      isDirty: true,
    };
  };

export const moveFieldAction =
  (sourceGroupName: string, targetGroupName: string, dropIndex: number, field: LayoutField): StateAction =>
  (state) => {
    const fieldGroups = state.fieldGroups.map((fieldGroup) => {
      const fields =
        fieldGroup.name === sourceGroupName ? fieldGroup.fields.filter((f) => f.id !== field.id) : fieldGroup.fields;

      if (fieldGroup.name === targetGroupName) {
        if (dropIndex >= 0) {
          fields.splice(dropIndex, 0, field);
        } else {
          fields.push(field);
        }
      }

      return { ...fieldGroup, fields };
    });

    return {
      ...state,
      fieldGroups,
      isDirty: true,
    };
  };

// Selectors

type StateSelector<T> = (state: ObjectLayoutState) => T;

export const getCreatePageLayoutRequest: StateSelector<CreateOrganizationPageLayoutRequest> = (state) => ({
  configurationId: state.configurationId,
  fieldGroups: state.fieldGroups.map((fieldGroup) => ({
    name: fieldGroup.name,
    fields: fieldGroup.fields.map((field) => ({
      fieldId: field.id,
      isVisible: field.isVisible,
    })),
  })),
});

export const getAvailableFields: StateSelector<LayoutField[]> = (state) => {
  const layoutFieldIds = new Set<string>(
    state.fieldGroups.flatMap((fieldGroup) => fieldGroup.fields.map((field) => field.id))
  );

  return state.objectDefinition.fields
    .filter((field) => !layoutFieldIds.has(field.id))
    .map((field) => ({
      ...field,
      isVisible: false,
    }));
};
