import { distinctBy } from "../../../../../shared/utilities/arrayHelper";
import { formatDate } from "../../../../../shared/utilities/dateUtils";
import { requiredValidator } from "../../../../../shared/utilities/validators";
import {
  CreateDataImportRequest,
  DataImportInfo,
  DataImportMetadata,
  DataImportOption,
  DataImportTarget,
} from "../../../../api/types/dataImportTypes";
import { FileInfo } from "../../../../api/types/fileTypes";
import { dataImportTargetCaptionMap } from "../../../../utilities/enumCaptions";
import { OperationStatus } from "../importDataPagesTypes";

export interface ImportCsvDataPageState {
  currentStep: NavigationStep;
  selectedObjectType?: string;
  targetOptions: ImportCsvTargetOption[];
  importDescription: string;
  importDescriptionValidationError?: string;
  sourceFiles: ImportSourceFileInfo[];
  createImportStatus?: OperationStatus;
  importCreationError?: string;
  dataImport?: DataImportInfo;
  dataImportMetadata?: DataImportMetadata;
  applyImportStatus?: OperationStatus;
  validateImportStatus?: OperationStatus;
}

export interface NavigationStep {
  key: NavigationStepKey;
  label: string;
  previousStepKey?: NavigationStepKey;
  nextStepKey?: NavigationStepKey;
}

export type NavigationStepKey = "data" | "upload" | "review";

export interface ImportCsvTargetOption extends DataImportOption {
  key: string;
  label: string;
  selected: boolean;
}

export interface ImportSourceFileInfo {
  targetOptionKey: string;
  fileInfo: FileInfo;
  status: "in_progress" | "ready" | "error";
  errorMessage?: string;
}

type StateAction = (state: ImportCsvDataPageState) => ImportCsvDataPageState;

export const getInitialState = (preSelectedObjectType: string | undefined): ImportCsvDataPageState => {
  const initialState: ImportCsvDataPageState = {
    currentStep: navigationStepsRecord.data,
    targetOptions: [],
    importDescription: "",
    sourceFiles: [],
  };

  return preSelectedObjectType ? changeObjectTypeSelectionAction(preSelectedObjectType)(initialState) : initialState;
};

const navigationStepsList: NavigationStep[] = [
  {
    key: "data",
    label: "Data",
    nextStepKey: "upload",
  },
  {
    key: "upload",
    label: "Upload",
    previousStepKey: "data",
    nextStepKey: "review",
  },
  {
    key: "review",
    label: "Review & Finalize",
    previousStepKey: "upload",
  },
];

const navigationStepsRecord: Record<NavigationStepKey, NavigationStep> = navigationStepsList.reduce(
  (result, step) => {
    result[step.key] = step;
    return result;
  },
  {} as Record<NavigationStepKey, NavigationStep>
);

const stepKeys: NavigationStepKey[] = Object.keys(navigationStepsRecord) as NavigationStepKey[];

// Selectors

export const stepLabels: string[] = Object.values(navigationStepsRecord).map((step) => step.label);

export const getCurrentStepIndex = (state: ImportCsvDataPageState): number => stepKeys.indexOf(state.currentStep.key);

export const getObjectTypeOptions = (state: ImportCsvDataPageState): { value: string; label: string }[] =>
  distinctBy(state.targetOptions, (t) => t.objectType)
    .map(({ objectType, objectName }) => ({
      value: objectType,
      label: objectName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getSelectedTargetOptions = (state: ImportCsvDataPageState): ImportCsvTargetOption[] =>
  state.targetOptions.filter((t) => t.selected);

export const getSelectedDataTypeTargetsWithFiles = (
  state: ImportCsvDataPageState
): [ImportCsvTargetOption, ImportSourceFileInfo | undefined][] =>
  getSelectedTargetOptions(state).map((t) => [t, state.sourceFiles.find((f) => f.targetOptionKey === t.key)]);

export const isPreviousStepAllowed = (state: ImportCsvDataPageState): boolean =>
  state.createImportStatus === undefined && state.applyImportStatus === undefined;

export const isNextStepAllowed = (state: ImportCsvDataPageState): boolean => {
  switch (state.currentStep.key) {
    case "data": {
      return getSelectedTargetOptions(state).length > 0;
    }
    case "upload": {
      return (
        Boolean(state.importDescription.trim()) &&
        getSelectedDataTypeTargetsWithFiles(state).every(([, file]) => file?.status === "ready") &&
        state.createImportStatus === undefined &&
        !state.importCreationError
      );
    }
    case "review": {
      return state.applyImportStatus === undefined;
    }
    default: {
      return false;
    }
  }
};

export const getCreateDataImportRequest = (state: ImportCsvDataPageState): CreateDataImportRequest => {
  const selectedTargetsWithFiles = getSelectedDataTypeTargetsWithFiles(state);
  return {
    description: state.importDescription,
    files: selectedTargetsWithFiles
      .filter(([, sourceFile]) => sourceFile?.status === "ready")
      .map(([target, sourceFile]) => ({
        fileDataCatalogueId: sourceFile?.fileInfo.catalogueId ?? "",
        fileImportTypeTarget: target.target,
        fileImportObjectTypeTarget: target.objectType,
        fileImportEntityTypeTarget: target.bcEntityTypeCode,
      })),
  };
};

export const dataImportTargetsWithSupportedExport: DataImportTarget[] = [
  "PlatformObjects",
  "PortfolioMonitoringMetrics",
  "PortfolioMonitoringMetricValues",
];

// Actions

const getTargetOptionLabel = (importOption: DataImportOption): string =>
  `${importOption.bcEntityTypeName || importOption.objectName}: ${dataImportTargetCaptionMap[importOption.target]}`;

const createCsvTargetOption = (importOption: DataImportOption): ImportCsvTargetOption => ({
  ...importOption,
  key: `${importOption.objectType}_${importOption.target}_${importOption.bcEntityTypeCode ?? ""}`,
  label: getTargetOptionLabel(importOption),
  selected: false,
});

export const initTargetOptions =
  (importOptions: DataImportOption[]): StateAction =>
  (state) => ({
    ...state,
    targetOptions: importOptions.map(createCsvTargetOption),
  });

export const previousStepAction = (): StateAction => (state) => {
  if (state.currentStep.previousStepKey) {
    return { ...state, currentStep: navigationStepsRecord[state.currentStep.previousStepKey] };
  }
  return state;
};

export const nextStepAction = (): StateAction => (state) => {
  if (state.currentStep.nextStepKey) {
    return { ...state, currentStep: navigationStepsRecord[state.currentStep.nextStepKey] };
  }
  return state;
};

export const changeObjectTypeSelectionAction =
  (selectedObjectType: string): StateAction =>
  (state) => {
    const targetOptions = state.targetOptions.map((t) => ({ ...t, selected: false }));
    return {
      ...state,
      selectedObjectType,
      targetOptions,
    };
  };

export const changeTargetOptionSelectionAction =
  (key: string, newSelected: boolean): StateAction =>
  (state) => {
    const newTargetOptions = state.targetOptions.map((t) => (t.key === key ? { ...t, selected: newSelected } : t));
    const firstSelectedDataTypeTarget = newTargetOptions.filter((t) => t.selected)[0]; // TBD when selected multiple targets
    const importDescription = firstSelectedDataTypeTarget
      ? `${firstSelectedDataTypeTarget.label} - ${formatDate(new Date())}`
      : "";

    return {
      ...state,
      targetOptions: newTargetOptions,
      importDescription,
    };
  };

export const updateImportDescriptionAction =
  (importDescription: string): StateAction =>
  (state) => {
    const validationResult = requiredValidator(importDescription);

    return {
      ...state,
      importDescription,
      importDescriptionValidationError: validationResult.isValid ? undefined : validationResult.error,
    };
  };

export const deleteSourceFileAction =
  (targetOptionKey: string): StateAction =>
  (state) => {
    const sourceFiles = state.sourceFiles.filter((file) => file.targetOptionKey !== targetOptionKey);
    return { ...state, sourceFiles };
  };

export const startFileUploadAction =
  (targetOptionKey: string, fileName: string, fileSize: number): StateAction =>
  (state) => {
    const sourceFiles: ImportSourceFileInfo[] = [
      ...state.sourceFiles,
      {
        targetOptionKey,
        status: "in_progress",
        fileInfo: {
          id: "",
          catalogueId: "",
          createdBy: "",
          createdAt: "",
          fileName,
          fileSize,
        },
      },
    ];

    return { ...state, sourceFiles };
  };

export const addInvalidFileAction =
  (targetOptionKey: string, fileName: string, fileSize: number, errorMessage: string): StateAction =>
  (state) => {
    const sourceFiles: ImportSourceFileInfo[] = [
      ...state.sourceFiles,
      {
        targetOptionKey,
        status: "error",
        errorMessage,
        fileInfo: {
          id: "",
          catalogueId: "",
          createdBy: "",
          createdAt: "",
          fileName,
          fileSize,
        },
      },
    ];

    return { ...state, sourceFiles };
  };

export const setFileUploadErrorAction =
  (targetOptionKey: string, errorMessage: string): StateAction =>
  (state) => {
    const sourceFiles: ImportSourceFileInfo[] = state.sourceFiles.map((file) =>
      file.targetOptionKey === targetOptionKey ? { ...file, status: "error", errorMessage } : file
    );

    return { ...state, sourceFiles };
  };

export const setFileUploadCompletedAction =
  (targetOptionKey: string, fileInfo: FileInfo): StateAction =>
  (state) => {
    const sourceFiles: ImportSourceFileInfo[] = state.sourceFiles.map((file) =>
      file.targetOptionKey === targetOptionKey ? { ...file, status: "ready", fileInfo } : file
    );

    return { ...state, sourceFiles };
  };

export const updateCreateImportStatusAction =
  (createImportStatus: OperationStatus): StateAction =>
  (state) => {
    return {
      ...state,
      createImportStatus,
      importCreationError: undefined,
      dataImport: undefined,
      dataImportMetadata: undefined,
    };
  };

export const setImportCreationErrorAction =
  (errorMessage: string): StateAction =>
  (state) => {
    return {
      ...state,
      createImportStatus: undefined,
      importCreationError: errorMessage,
      dataImport: undefined,
      dataImportMetadata: undefined,
    };
  };

export const removeImportCreationErrorAction = (): StateAction => (state) => {
  return {
    ...state,
    createImportStatus: undefined,
    importCreationError: undefined,
    dataImport: undefined,
    dataImportMetadata: undefined,
  };
};

export const finishCreatingImportAction =
  (dataImport: DataImportInfo, dataImportMetadata: DataImportMetadata): StateAction =>
  (state) => {
    return nextStepAction()({
      ...state,
      createImportStatus: undefined,
      importCreationError: undefined,
      dataImport,
      dataImportMetadata,
    });
  };

export const updateApplyImportStatusAction =
  (applyImportStatus: OperationStatus | undefined): StateAction =>
  (state) => {
    return {
      ...state,
      applyImportStatus,
    };
  };

export const updateValidateImportStatusAction =
  (validateImportStatus: OperationStatus | undefined): StateAction =>
  (state) => {
    return {
      ...state,
      validateImportStatus,
    };
  };

export const finishValidateImportAction =
  (dataImport: DataImportInfo, dataImportMetadata: DataImportMetadata): StateAction =>
  (state) => {
    return {
      ...state,
      validateImportStatus: undefined,
      dataImport,
      dataImportMetadata,
    };
  };
