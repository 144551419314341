import { Avatar, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

const clientLogoStyles: Record<"small" | "medium", SxProps<Theme>> = {
  small: (theme) => ({ width: theme.spacing(3), height: theme.spacing(3), borderRadius: theme.spacing(0.25) }),
  medium: (theme) => ({ width: theme.spacing(4), height: theme.spacing(4) }),
};

interface Props {
  logoSrc?: string;
  size: keyof typeof clientLogoStyles;
  sx?: SxProps;
}

const ClientLogo = ({ logoSrc, size, children, sx }: PropsWithChildren<Props>) => {
  const styleFunction = clientLogoStyles[size];

  return (
    <Avatar
      src={logoSrc}
      variant="rounded"
      alt="Client Logo"
      slotProps={{
        img: { style: { maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" } },
      }}
      sx={(theme) => ({ ...sx, ...(typeof styleFunction === "function" ? styleFunction(theme) : {}) })}
    >
      {children}
    </Avatar>
  );
};

export default ClientLogo;
