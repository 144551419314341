import FunctionsRoundedIcon from "@mui/icons-material/FunctionsRounded";
import { useClientContext } from "../../context/ClientContext";
import { getBusinessCentralUrl } from "../../utilities/navigationHelper";
import OpenExternalLinkMenuItem from "../common/OpenExternalLinkMenuItem";

const FundOpsMenuLinkItem = () => {
  const { settings } = useClientContext();
  const businessCentralUrl = getBusinessCentralUrl(settings.businessCentralSettings);

  if (!businessCentralUrl) {
    return null;
  }

  return <OpenExternalLinkMenuItem Icon={FunctionsRoundedIcon} title="Fund Operations" href={businessCentralUrl} />;
};

export default FundOpsMenuLinkItem;
