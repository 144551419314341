import { useCallback } from "react";
import { Outlet, useParams } from "react-router-dom";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { ObjectDefinitionContextProvider } from "./ObjectDefinitionContext";

const ObjectDefinitionWrapperPage = () => {
  const { objectType } = useParams();

  const getObjectDefinition = useCallback(
    () => adminApi.getOrganizationObjectDefinitionByObjectType(objectType || ""),
    [objectType]
  );

  const [objectDefinition, objectFetchError, { isFetching: isFetchingObject, setData: setObjectDefinition }] =
    useFetch(getObjectDefinition);

  const [configurations, configurationsError, { isFetching: isFetchingConfigurations }] = useFetch(
    adminApi.getDataModelConfigurations
  );

  const [allObjectDefinitions, allObjectDefinitionsError, { isFetching: isFetchingAllObjectDefinitions }] = useFetch(
    adminApi.getOrganizationObjectDefinitions
  );

  const fetchError = objectFetchError || configurationsError || allObjectDefinitionsError;
  if (fetchError) {
    logError(fetchError, "[ObjectDefinitionWrapperPage]");
    return <DataLoadingFailed title="Failed to load object details" />;
  }

  if (
    objectDefinition === undefined ||
    configurations === undefined ||
    allObjectDefinitions === undefined ||
    isFetchingObject ||
    isFetchingConfigurations ||
    isFetchingAllObjectDefinitions
  ) {
    return <InlineLoader />;
  }

  return (
    <ObjectDefinitionContextProvider
      objectDefinition={objectDefinition}
      configurations={configurations}
      allObjectDefinitions={allObjectDefinitions}
      onUpdateObjectDefinition={setObjectDefinition}
    >
      <Outlet />
    </ObjectDefinitionContextProvider>
  );
};

export default ObjectDefinitionWrapperPage;
