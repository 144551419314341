import { Stack, Typography } from "@mui/material";
import { OptionsEntityFieldConfiguration } from "../../../../../api/types/objectTypes";
import { entityOptionFieldTypeCaptionMap } from "../../../../../utilities/enumCaptions";

interface Props {
  fieldConfiguration: OptionsEntityFieldConfiguration;
}

const SelectFieldConfigViewer = ({ fieldConfiguration }: Props) => {
  return (
    <Stack spacing={1} pt={2}>
      <Typography variant="subtitle2">Options</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography color="text.secondary">Dictionary</Typography>
        <Typography>{entityOptionFieldTypeCaptionMap[fieldConfiguration.fieldType]}</Typography>
      </Stack>
    </Stack>
  );
};

export default SelectFieldConfigViewer;
