import { Stack, Typography } from "@mui/material";
import CopyToBufferButton from "../../../../../shared/components/inputs/CopyToBufferButton";
import { ExpenseManagementSettings } from "../../../../api/adminApi";

interface Props {
  settings: ExpenseManagementSettings | undefined;
}

const InvoicesActionsToolbar = ({ settings }: Props) => {
  return (
    <>
      {settings?.inboxEmail && (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography color="text.secondary">Email your invoice to</Typography>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography>{settings.inboxEmail}</Typography>
            <CopyToBufferButton textToCopy={settings.inboxEmail} color="action" />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default InvoicesActionsToolbar;
