import { Avatar, Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  secondaryTitle?: string;
  typeName: string;
  logoUrl?: string;
}

const ObjectTitle = ({ title, secondaryTitle, typeName, logoUrl }: Props) => (
  <Stack direction="row" spacing={1} alignItems="center">
    {logoUrl && (
      <Avatar
        src={logoUrl}
        variant="rounded"
        alt="logo"
        slotProps={{ img: { sx: { objectFit: "contain" } } }}
        sx={{ width: 80, height: 32 }}
      />
    )}
    <Stack spacing={0.5}>
      <Stack direction="row" spacing={1} alignItems="baseline">
        <Typography variant="h6">{title}</Typography>
        {secondaryTitle && (
          <Typography variant="subtitle1" color="text.secondary">
            {secondaryTitle}
          </Typography>
        )}
      </Stack>
      <Typography variant="caption" color="text.secondary">
        {typeName}
      </Typography>
    </Stack>
  </Stack>
);

export default ObjectTitle;
