import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

export interface CrmField {
  id: string;
  name: string;
}

interface Props {
  crmFieldId: string;
  options: CrmField[];
  hasError?: boolean;
  onChange: (crmFieldId: string) => void;
}

const CrmFieldEdit = ({ crmFieldId, options, hasError, onChange }: Props) => {
  const handleChange = (e: SelectChangeEvent<string>) => onChange(e.target.value);

  return (
    <Select
      value={crmFieldId}
      onChange={handleChange}
      displayEmpty
      error={hasError}
      sx={(theme) => ({ height: theme.spacing(4.5), width: theme.spacing(25) })}
    >
      <MenuItem key="_" value="">
        <em>None</em>
      </MenuItem>
      {options.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CrmFieldEdit;
