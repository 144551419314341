import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FundDetails, FundPermissionsResponse, FundStage } from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import { ObjectClassDefinition, ObjectPermissions } from "../../../../api/types/objectTypes";
import { useClientContext } from "../../../../context/ClientContext";
import FeatureToggleBoundary from "../../../common/FeatureToggleBoundary";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import FullHeightFlexPage from "../../../common/layout/FullHeightFlexPage";
import StretchedScrollableContent from "../../../common/layout/StretchedScrollableContent";
import ObjectTitle from "../../../common/ObjectTitle";
import { getDataCatalogueEntityType } from "../../../entityFields/entityTypesHelper";
import EntityCardFiles from "../../../entityFields/files/EntityCardFiles";
import FundCommunicationMatrixTab from "./communication-matrix/FundCommunicationMatrixTab";
import FundDocumentsTab from "./documents/FundDocumentsTab";
import FundMainInfoTab from "./main/FundMainInfoTab";

interface Props {
  fundDetails: FundDetails;
  accessCategories: Category[];
  fundPermissions: FundPermissionsResponse;
  objectDefinition: ObjectClassDefinition;
  objectPermissions: ObjectPermissions;
  onFundDetailsChange: (details: Partial<FundDetails>) => void;
}

const tabPanelSx = { height: "100%", m: 0, px: 3, pt: 2.5, pb: 0 };

const getFundStageCategories = (stage: FundStage | undefined, accessCategories: Category[]) => {
  switch (stage) {
    case "Investment": {
      return accessCategories.filter((c) => c.type === "Message");
    }
    case "Fundraising": {
      return accessCategories.filter((c) => c.type === "Fundraising");
    }
    default: {
      return [...accessCategories];
    }
  }
};

const FundDetailsPage = ({
  fundDetails,
  fundPermissions,
  accessCategories,
  objectDefinition,
  objectPermissions,
  onFundDetailsChange,
}: Props) => {
  const { tab } = useParams();
  const { hasAnyPermission, isPortalFeatureEnabled } = useClientContext();
  const [selectedTab, setSelectedTab] = useState(tab || "main");

  const hasPermissionsToViewFiles = hasAnyPermission(objectPermissions.filesReadPermissions);
  const hasPermissionsToManageFiles = hasAnyPermission(objectPermissions.filesWritePermissions);

  const { id, name, stage } = fundDetails;

  const onTabChange = (newTab: string) => {
    setSelectedTab(newTab);
    window.history.replaceState({}, document.title, window.location.pathname.replace(/\/[^/]+$/, `/${newTab}`));
  };

  const fundStageCategories = getFundStageCategories(stage, accessCategories);

  return (
    <TabContext value={selectedTab}>
      <FullHeightFlexPage>
        <Box>
          <GeneralPageHeader
            TitleComponent={<ObjectTitle title={name} typeName={objectDefinition.objectName} />}
            hideBorderLine
            showDefaultBackButtonTo=".."
          />
          <TabList onChange={(_, tab) => onTabChange(tab)}>
            <Tab sx={{ ml: 3 }} label="Main Info" value="main" />
            <Tab label="Investor Documents" value="documents" />
            {hasPermissionsToViewFiles && isPortalFeatureEnabled("EntityFilesEnabled") && (
              <Tab label="Files" value="files" />
            )}
            <Tab label="Communication Matrix" value="communication-matrix" />
          </TabList>
        </Box>
        <StretchedScrollableContent>
          <TabPanel value="main" sx={{ ...tabPanelSx, backgroundColor: "background.grey" }}>
            <FundMainInfoTab
              fundDetails={fundDetails}
              objectDefinition={objectDefinition}
              objectPermissions={objectPermissions}
              onFundDetailsChange={onFundDetailsChange}
            />
          </TabPanel>
          <TabPanel value="documents" sx={tabPanelSx}>
            <FundDocumentsTab fundId={id} categories={accessCategories} fundPermissions={fundPermissions.permissions} />
          </TabPanel>
          {hasPermissionsToViewFiles && (
            <FeatureToggleBoundary featureToggle="EntityFilesEnabled">
              <TabPanel value="files" sx={{ height: "100%", m: 0, px: 0, py: 0 }}>
                <EntityCardFiles
                  entityType={getDataCatalogueEntityType(objectDefinition.objectType)}
                  entityId={id}
                  entityName={name}
                  hasPermissionsToManage={hasPermissionsToManageFiles}
                />
              </TabPanel>
            </FeatureToggleBoundary>
          )}
          <TabPanel value="communication-matrix" sx={tabPanelSx}>
            <FundCommunicationMatrixTab categories={fundStageCategories} fundPermissions={fundPermissions} />
          </TabPanel>
        </StretchedScrollableContent>
      </FullHeightFlexPage>
    </TabContext>
  );
};

export default FundDetailsPage;
