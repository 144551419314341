import { Stack, Typography } from "@mui/material";
import PersonMainDetails from "../../../../common/PersonMainDetails";

interface Props {
  name: string;
  email: string;
  isCurrentUser?: boolean;
}

const ManagedMemberDetails = ({ name, email, isCurrentUser }: Props) =>
  isCurrentUser ? (
    <Stack direction="row" alignItems="center" width="100%" spacing={3}>
      <PersonMainDetails name={name} email={email} />
      <Typography>(me)</Typography>
    </Stack>
  ) : (
    <PersonMainDetails name={name} email={email} />
  );

export default ManagedMemberDetails;
