import HelpIcon from "@mui/icons-material/HelpOutline";
import { Stack, Tooltip, Typography } from "@mui/material";
import { EmailTrackingStatus } from "../../api/types/emailsTypes";
import DetailedTooltipTitle from "../common/DetailedTooltipTitle";

interface Props {
  status: EmailTrackingStatus;
  deliveryFailureMessage?: string;
  deliveryFailureMessageExplanation?: string;
}

export const getEmailTrackingStatusText = (status: EmailTrackingStatus): string =>
  status === "PermanentFailure" ? "Bounced" : status;

const EmailStatus = ({ status, deliveryFailureMessage, deliveryFailureMessageExplanation }: Props) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Typography color={status === "PermanentFailure" ? "error.main" : "text.primary"}>
      {getEmailTrackingStatusText(status)}
    </Typography>
    {deliveryFailureMessage && (
      <Tooltip
        title={<DetailedTooltipTitle title={deliveryFailureMessage} details={deliveryFailureMessageExplanation} />}
      >
        <HelpIcon sx={(t) => ({ color: t.palette.secondary.light })} />
      </Tooltip>
    )}
  </Stack>
);

export default EmailStatus;
