import AddIcon from "@mui/icons-material/AddRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RenameIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  Button,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import HiddenIcon from "../../../../icons/HiddenIcon";
import VisibleIcon from "../../../../icons/VisibleIcon";
import DragAndDropList from "../../../common/DragAndDropList";
import AddFieldSelect from "./AddFieldSelect";
import LayoutFieldItem from "./LayoutFieldItem";
import { useObjectLayoutContext } from "./ObjectLayoutContext";
import {
  LayoutField,
  LayoutFieldGroup,
  addFieldAction,
  cancelAddingFieldAction,
  getAvailableFields,
  removeGroupAction,
  startAddingFieldAction,
  toggleGroupVisibilityAction,
} from "./objectLayoutState";

interface Props {
  group: LayoutFieldGroup;
  hideFieldsAndActions?: boolean;
  onRenameGroup?: (groupName: string) => void;
  onDropField?: (index: number, draggingField: LayoutField | undefined) => void;
  onDragField?: (field: LayoutField) => void;
}

const LayoutGroupCard = ({ group, hideFieldsAndActions, onRenameGroup, onDropField, onDragField }: Props) => {
  const { layoutState, setLayoutState } = useObjectLayoutContext();
  const { sendNotification } = useNotificationContext();

  const handleToggleVisibility = () => {
    setLayoutState(toggleGroupVisibilityAction(group.name));
  };

  const handleRemove = () => {
    setLayoutState(removeGroupAction(group.name));
    sendNotification("Group removed from layout. All group fields can be added back later if needed.");
  };

  const handleAddFieldClick = () => {
    setLayoutState(startAddingFieldAction(group.name));
  };

  const handleCancelAddingField = () => {
    setLayoutState(cancelAddingFieldAction());
  };

  const handleAddField = (fieldId: string) => {
    setLayoutState(addFieldAction(group.name, fieldId));
  };

  const isGroupVisible = group.fields.some((f) => f.isVisible);
  const isAddingFieldToGroup = layoutState.isAddingFieldToGroup === group.name;
  const isAddingFieldToAnyGroup = Boolean(layoutState.isAddingFieldToGroup);

  const availableFields = getAvailableFields(layoutState);

  return (
    <Paper variant="outlined" sx={{ mt: 1 }}>
      <MenuList sx={{ py: 0, "& .MuiListItemIcon-root": { minWidth: 20 } }}>
        <MenuItem draggable sx={{ px: 0.8, py: 0.8, borderBottom: "1px solid", borderBottomColor: "divider" }}>
          <DragIndicatorIcon sx={{ cursor: "grab", mr: 0.5, color: "action.active" }} />
          <ListItemText sx={{ flexGrow: 1 }}>
            <Typography component="span" variant="subtitle2">
              {group.name}
            </Typography>
          </ListItemText>
          {!hideFieldsAndActions && (
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => onRenameGroup?.(group.name)} disabled={isAddingFieldToAnyGroup}>
                <RenameIcon />
              </IconButton>
              {group.fields.length > 0 && (
                <IconButton
                  sx={{ ml: 1 }}
                  edge="end"
                  onClick={handleToggleVisibility}
                  disabled={isAddingFieldToAnyGroup}
                >
                  {isGroupVisible ? <VisibleIcon /> : <HiddenIcon />}
                </IconButton>
              )}
              <IconButton sx={{ ml: 1 }} edge="end" onClick={handleRemove} disabled={isAddingFieldToAnyGroup}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </MenuItem>
        {!hideFieldsAndActions && (
          <DragAndDropList<LayoutField>
            type="layout_field"
            items={group.fields}
            onDrop={onDropField}
            onDrag={onDragField}
            renderItem={(field) => <LayoutFieldItem field={field} disableActions={isAddingFieldToGroup} />}
            emptyPlaceholder={
              <Paper
                elevation={0}
                sx={{
                  alignItems: "center",
                  marginBottom: 1,
                  mx: 1,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  No fields yet. Please add fields or drag and drop fields to the group.
                </Typography>
              </Paper>
            }
            renderCustomDragItemLayer={(field) => (
              <Paper sx={{ width: "32rem" }}>
                <LayoutFieldItem field={field} disableActions />
              </Paper>
            )}
            options={{ notDraggable: () => isAddingFieldToGroup }}
          />
        )}
      </MenuList>
      {isAddingFieldToGroup && (
        <AddFieldSelect fields={availableFields} onAdd={handleAddField} onCancel={handleCancelAddingField} />
      )}
      <Button
        variant="text"
        startIcon={<AddIcon />}
        sx={{ ml: 0.8 }}
        disabled={availableFields.length === 0 || isAddingFieldToAnyGroup}
        onClick={handleAddFieldClick}
      >
        Add Field
      </Button>
    </Paper>
  );
};

export default LayoutGroupCard;
