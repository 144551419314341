import { Avatar, Box, Stack, Typography } from "@mui/material";
import { blue, orange } from "@mui/material/colors";
import { GridColDef } from "@mui/x-data-grid-premium";
import HorizontalFill from "../../../../../../../shared/components/HorizontalFill";
import { convertISODate } from "../../../../../../../shared/utilities/dateUtils";
import { ScenarioTableBlockColumn, TableBlockColumn, TableBlockRow } from "../../../../../../api/types/inputFormTypes";

export type RowModel = TableBlockRow;

const ScenarioAvatar = ({ scenarioName }: { scenarioName: string }) => {
  if (scenarioName === "Actual") {
    return (
      <Avatar sx={{ width: 16, height: 16, bgcolor: blue[50], color: blue[700] }}>
        <Typography variant="caption" fontWeight={500}>
          A
        </Typography>
      </Avatar>
    );
  }

  if (scenarioName === "Budget") {
    return (
      <Avatar sx={{ width: 16, height: 16, bgcolor: orange[50], color: orange[700] }}>
        <Typography variant="caption" fontWeight={500}>
          B
        </Typography>
      </Avatar>
    );
  }

  return <Box width={16} height={16} />;
};

const ScenarioColumnHeader = ({ columnDefinition }: { columnDefinition: ScenarioTableBlockColumn }) => {
  return (
    <Stack width="100%">
      <Box display="flex" justifyContent="space-between">
        <ScenarioAvatar scenarioName={columnDefinition.scenarioName} />
        <Typography variant="subtitle2">{convertISODate(columnDefinition.titleAsDate, "MMM yyyy")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <HorizontalFill />
        <Typography variant="caption" color="text.secondary">
          {columnDefinition.subtitle}
        </Typography>
      </Box>
    </Stack>
  );
};

const NameCell = ({ row }: { row: RowModel }) => {
  if (row.type === "Metric") {
    return (
      <Typography noWrap pl={row.indentationLevel * 2}>
        {row.name}
      </Typography>
    );
  }

  return (
    <Typography variant="subtitle2" noWrap pl={row.indentationLevel * 2}>
      {row.name}
    </Typography>
  );
};

const isColumnEditable = (columnDef: TableBlockColumn): boolean =>
  columnDef.scenarioName === "Actual" &&
  (columnDef.dateRange === "Current" ||
    (columnDef.dateRange === "Previous" &&
      columnDef.previousPeriodShift !== undefined &&
      columnDef.previousPeriodShift >= -3));

export const getColumns = (columnDefinitions: TableBlockColumn[]): GridColDef<RowModel>[] => {
  const scenarioColumnDefinitions = columnDefinitions.filter((column) => column.type === "Scenario");

  const metricColumn: GridColDef<RowModel> = {
    field: "name",
    headerName: "Metric",
    flex: 1,
    minWidth: 300,
    cellClassName: ({ row }) => (row.type === "Total" ? "total-cell" : ""),
    renderCell: ({ row }) => <NameCell row={row} />,
  };

  const scenarioColumns: GridColDef<RowModel>[] = scenarioColumnDefinitions.map((columnDef) => ({
    field: columnDef.id,
    width: 150,
    type: "number",
    editable: isColumnEditable(columnDef),
    renderHeader: () => <ScenarioColumnHeader columnDefinition={columnDef} />,
    headerClassName: () => (isColumnEditable(columnDef) ? "" : "readonly-header"),
    cellClassName: ({ row }) =>
      row.type === "Total" ? "total-cell" : isColumnEditable(columnDef) ? "" : "readonly-cell",
    valueGetter: (_, row) =>
      row.type === "Section" ? "" : row.type === "Total" ? "" : (row.values ?? {})[columnDef.id],
    valueSetter: (newValue, row) => {
      if (row.type === "Section" || row.type === "Total") {
        return row;
      }

      return { ...row, values: { ...row.values, [columnDef.id]: newValue } };
    },
  }));

  return [metricColumn, ...scenarioColumns];
};
