import { Chip, Stack } from "@mui/material";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import { SelectOption } from "../filterTypes";

interface Props {
  selectedValues: string[];
  options: SelectOption[];
  onRemoveOption: (optionValue: string) => void;
  onClear: () => void;
}

const SelectedOptionsPanel = ({ selectedValues, options, onRemoveOption, onClear }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={1}
      p={1}
      border={1}
      borderColor="divider"
      borderRadius="4px"
    >
      <Stack direction="row" flexWrap="wrap" flexGrow={1} spacing={1} rowGap={1}>
        {selectedValues.map((value) => {
          const label = options.find((option) => option.value === value)?.label ?? value;
          return <Chip variant="outlined" key={value} label={label} onDelete={() => onRemoveOption(value)} />;
        })}
      </Stack>
      {selectedValues.length > 0 && <CloseIconButton onClick={onClear} />}
    </Stack>
  );
};

export default SelectedOptionsPanel;
