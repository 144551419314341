import MoneyIcon from "@mui/icons-material/AttachMoney";
import { Box, Button, Container, Stack } from "@mui/material";
import { useReducer } from "react";
import { useLocation } from "react-router-dom";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import SearchField from "../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { DescriptiveDate, thisQuarter, thisYear } from "../../../../shared/utilities/dateFilterHelper";
import adminApi from "../../../api/adminApi";
import DateRangeSelect from "../../common/DateRangeSelect";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import MultiSelect from "../../common/MultiSelect";
import DocumentCollectionsTable from "./DocumentCollectionsTable";
import { getInitialState, reducer } from "./state";

const DocumentCollections = () => {
  const { state: navigationState } = useLocation();

  const [state, dispatch] = useReducer(reducer, getInitialState());

  const [resp, fetchError] = useFetch(adminApi.getDocumentCollections, (data) =>
    dispatch({ type: "RESET", items: data.documentCollections })
  );

  if (fetchError) {
    logError(fetchError, "[DocumentCollections]");
    return <DataLoadingFailed title="Loading document collections failed" />;
  }

  const handleSearch = (searchTerm: string) => dispatch({ type: "SEARCH", searchTerm });

  const handleDateRangeChange = (dateRange: DescriptiveDate) => dispatch({ type: "FILTER_DATES", dateRange });

  const handleFundsFilterChange = (selectedFunds: string[]) => dispatch({ type: "FILTER_FUNDS", selectedFunds });

  const handleClearFilters = () => dispatch({ type: "CLEAR_FILTERS" });

  const showClearFilters = state.dateRange !== undefined || state.selectedFunds.length > 0;

  return (
    <>
      <GeneralPageHeader title="Document Collections" />
      <Container maxWidth={false} sx={{ height: "100%", py: 2.5 }}>
        <Box display="flex" flexDirection="column" width="100%" height="100%" pb={5}>
          {resp?.documentCollections && (
            <Stack mt={1} mb={1} direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={2} alignItems="center">
                <MultiSelect
                  placeholder="Funds"
                  options={state.allFunds.map((fundName) => ({ value: fundName, label: fundName }))}
                  selectedValues={state.selectedFunds}
                  onChange={handleFundsFilterChange}
                  StartIcon={MoneyIcon}
                />
                <DateRangeSelect
                  predefinedRanges={[thisQuarter, thisYear]}
                  customRangeTitle="Custom date range"
                  dateRange={state.dateRange}
                  onChange={handleDateRangeChange}
                />
                {showClearFilters && (
                  <Button variant="text" color="primary" onClick={handleClearFilters}>
                    Clear All
                  </Button>
                )}
              </Stack>
              <SearchField initialValue={state.searchTerm} onSearch={handleSearch} />
            </Stack>
          )}
          <DocumentCollectionsTable
            documentCollections={state.filteredItems}
            loading={resp === undefined}
            selectedId={navigationState?.documentCollectionId}
          />
        </Box>
      </Container>
    </>
  );
};

export default DocumentCollections;
