import { ClickAwayListener, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { formatISO, isValid, parseISO } from "date-fns";
import deepEqual from "fast-deep-equal";
import { useEffect, useState } from "react";
import { formatDate } from "../../../../shared/utilities/dateUtils";
import { ValidationResult, invalidResult, validResult } from "../../../../shared/utilities/validators";
import { FieldAttribute } from "../../../api/types/objectTypes";
import { useFieldValuesContext } from "../FieldValuesContext";
import { findNextEntityInput, findPreviousEntityInput } from "../entityFieldNavigator";
import { EntityFieldComponentPropsBase } from "../entityFieldTypes";
import FieldValueWrapper from "./FieldValueWrapper";

const minDateString = "0001-01-01"; // Used to indicate an empty date value on the backend

const getValidDateValue = (value: unknown): Date | null => {
  const isMinDate = (date: Date): boolean => date.getFullYear() === 1 && date.getMonth() === 0 && date.getDate() === 1;
  const date = value instanceof Date ? value : typeof value === "string" ? parseISO(value) : null;
  return date && isValid(date) && !isMinDate(date) ? date : null;
};

const EntityDateField = ({
  value,
  fieldId,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  const date = getValidDateValue(value);

  const handleChange = (newValue: Date | null, validationResult: ValidationResult) => {
    const dateISOString = newValue ? formatISO(newValue, { representation: "date" }) : minDateString;
    onChange(dateISOString, fieldId, validationResult);
  };

  return (
    <EntityDateFieldValueComponent
      value={date}
      fieldAttributes={fieldAttributes}
      onChange={handleChange}
      fieldInputId={fieldInputId}
    />
  );
};

interface EntityDateFieldConfigurationComponentProps {
  value: Date | null;
  fieldAttributes: FieldAttribute[];
  onChange: (newValue: Date | null, validationResult: ValidationResult) => void;
  fieldInputId?: string;
}

interface DateValidationResult extends ValidationResult {
  showValidation?: boolean;
}

const EntityDateFieldValueComponent = ({
  value,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityDateFieldConfigurationComponentProps) => {
  const [date, setDate] = useState(value);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<DateValidationResult>(validResult());
  const { updateFieldValuesState } = useFieldValuesContext();

  const handleDateChange = (date: Date | null) => {
    if (date && !isValid(date)) {
      const invalidDateResult = invalidResult("Invalid date");
      setValidationResult(invalidDateResult);
      onChange(null, invalidDateResult);
      return;
    }

    if (fieldAttributes.includes("Required") && date === null) {
      const requiredInvalidResult = invalidResult("Required field. Please select the necessary date.");
      setValidationResult(requiredInvalidResult);
      onChange(null, requiredInvalidResult);
    } else {
      setValidationResult(validResult());
    }

    setDate(date);
  };

  useEffect(() => {
    if (value !== undefined && !deepEqual(value, date)) {
      setDate(value || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleValueOnLeave = () => {
    if (!validationResult.isValid) {
      setValidationResult({ ...validationResult, showValidation: true });
      return;
    }

    if (!deepEqual(date, value)) {
      onChange(date, validationResult);
    } else {
      updateFieldValuesState({ fieldEditState: "viewing", isValid: true });
    }

    setIsEdit(false);
  };

  const displayValue = date ? formatDate(date) : "";

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleValueOnLeave();
      return;
    }

    if (e.key === "Tab" && fieldInputId) {
      const nextElement = e.shiftKey ? findPreviousEntityInput(fieldInputId) : findNextEntityInput(fieldInputId);
      if (nextElement) {
        e.preventDefault();
        nextElement.click();
      }
      return;
    }
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    const openCalendar = document.querySelector(".MuiDateCalendar-root");
    const targetNode = event.target as Node;
    if (openCalendar && targetNode && openCalendar.contains(targetNode)) {
      return;
    }

    handleValueOnLeave();
  };

  return (
    <Stack direction="row">
      {isEdit && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <DatePicker
            autoFocus
            disabled={fieldAttributes.includes("ReadOnly")}
            value={date}
            onChange={handleDateChange}
            slotProps={{
              field: {
                clearable: true,
              },
              textField: {
                size: "small",
                error: !validationResult.isValid && validationResult.showValidation,
                helperText: validationResult.showValidation ? validationResult.error : "",
                InputProps: {
                  error: !validationResult.isValid && validationResult.showValidation,
                  sx: { width: 240 },
                },
                onKeyDown: handleKeyDown,
              },
            }}
          />
        </ClickAwayListener>
      )}
      {!isEdit && (
        <FieldValueWrapper
          id={fieldInputId}
          isReadonly={fieldAttributes.includes("ReadOnly")}
          displayValue={displayValue}
          isEdit={!validationResult.isValid}
          onEditChange={setIsEdit}
        />
      )}
    </Stack>
  );
};

export default EntityDateField;
