import { SvgIcon, SvgIconProps } from "@mui/material";

const SelectFieldTypeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M9.70817 12.2087C9.7915 12.292 9.88873 12.3337 9.99984 12.3337C10.1109 12.3337 10.2082 12.292 10.2915 12.2087L12.6248 9.87533C12.7637 9.73644 12.7984 9.58366 12.729 9.41699C12.6596 9.25033 12.5276 9.16699 12.3332 9.16699H7.6665C7.47206 9.16699 7.34012 9.25033 7.27067 9.41699C7.20123 9.58366 7.23595 9.73644 7.37484 9.87533L9.70817 12.2087ZM9.99984 18.3337C8.84706 18.3337 7.76373 18.1149 6.74984 17.6774C5.73595 17.2399 4.854 16.6462 4.104 15.8962C3.354 15.1462 2.76025 14.2642 2.32275 13.2503C1.88525 12.2364 1.6665 11.1531 1.6665 10.0003C1.6665 8.84755 1.88525 7.76421 2.32275 6.75033C2.76025 5.73644 3.354 4.85449 4.104 4.10449C4.854 3.35449 5.73595 2.76074 6.74984 2.32324C7.76373 1.88574 8.84706 1.66699 9.99984 1.66699C11.1526 1.66699 12.2359 1.88574 13.2498 2.32324C14.2637 2.76074 15.1457 3.35449 15.8957 4.10449C16.6457 4.85449 17.2394 5.73644 17.6769 6.75033C18.1144 7.76421 18.3332 8.84755 18.3332 10.0003C18.3332 11.1531 18.1144 12.2364 17.6769 13.2503C17.2394 14.2642 16.6457 15.1462 15.8957 15.8962C15.1457 16.6462 14.2637 17.2399 13.2498 17.6774C12.2359 18.1149 11.1526 18.3337 9.99984 18.3337ZM9.99984 16.667C11.8609 16.667 13.4373 16.0212 14.729 14.7295C16.0207 13.4378 16.6665 11.8614 16.6665 10.0003C16.6665 8.13921 16.0207 6.56283 14.729 5.27116C13.4373 3.97949 11.8609 3.33366 9.99984 3.33366C8.13873 3.33366 6.56234 3.97949 5.27067 5.27116C3.979 6.56283 3.33317 8.13921 3.33317 10.0003C3.33317 11.8614 3.979 13.4378 5.27067 14.7295C6.56234 16.0212 8.13873 16.667 9.99984 16.667Z"
      fill="#8E9BA9"
    />
  </SvgIcon>
);

export default SelectFieldTypeIcon;
