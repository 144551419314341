import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import { PropsWithChildren } from "react";
import CloseIconButton from "../../../shared/components/CloseIconButton";

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitButtonText?: string;
  submitButtonDisabled?: boolean;
  dialogProps?: Partial<DialogProps>;
}
const SimpleDialog = ({
  open,
  title,
  onClose,
  onSubmit,
  children,
  submitButtonText,
  submitButtonDisabled,
  dialogProps,
}: PropsWithChildren<Props>) => {
  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      <DialogTitle>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6">{title}</Typography>
          <CloseIconButton onClick={onClose} />
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end", px: 3, py: 2 }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button disabled={submitButtonDisabled} sx={{ ml: 1 }} onClick={onSubmit} variant="contained" color="primary">
          {submitButtonText || "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
