import { Stack } from "@mui/material";
import { AccessLevelEntity, MemberRole } from "../../../../../api/types/userManagementTypes";
import { CompanyAccessLevel, CompanyWithAccess } from "../definitions";
import DistributeCompanyPermissions from "./DistributeCompanyPermissions";
import EntitiesAccess from "./EntitiesAccess";
import { useInviteMembersContext } from "./InviteMembersContext";
import ProductPermissions from "./ProductPermissions";

interface Props {
  companyAccess: [CompanyWithAccess, CompanyAccessLevel];
}

const AccessSection = ({ companyAccess }: Props) => {
  const { selectedCompanies, updateSelectedCompanies, updateSelectedCompanyAccess } = useInviteMembersContext();

  const [company, accessLevel] = companyAccess;

  const onRolesSelected = (selectedRoles: MemberRole[]) => {
    updateCompany({ roles: selectedRoles });
  };

  const onEntitiesSelected = (selectedEntities: AccessLevelEntity[], accessToAllEntities: boolean) => {
    updateCompany({ entities: selectedEntities, accessToAllEntities });
  };

  const updateCompany = (update: Partial<CompanyWithAccess>) => {
    const companyUpdate = {
      ...company,
      ...update,
    };
    updateSelectedCompanyAccess(companyUpdate, accessLevel);
    updateSelectedCompanies([...selectedCompanies.filter((c) => c.clientCode !== company.clientCode), companyUpdate]);
  };

  return (
    <Stack justifyContent="space-between" sx={{ height: "100%", width: "100%" }}>
      {accessLevel === "Product" && (
        <>
          <ProductPermissions
            clientCode={company.clientCode}
            selectedRoles={company.roles}
            onSelectedChanged={onRolesSelected}
          />
          {selectedCompanies.length > 1 && <DistributeCompanyPermissions />}
        </>
      )}
      {accessLevel === "Entity" && (
        <EntitiesAccess
          clientCode={company.clientCode}
          selectedEntities={company.entities}
          onSelectedChanged={onEntitiesSelected}
          accessToAllEntities={company.accessToAllEntities}
        />
      )}
    </Stack>
  );
};

export default AccessSection;
