import ForwardEmailIcon from "@mui/icons-material/ForwardToInboxRounded";
import { Avatar, Box, Card, CardContent, Stack, Switch, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

interface Props {
  enabled: boolean;
  disabled?: boolean;
  onChange: (enabled: boolean) => void;
}

const AutomaticInvitesSection = ({ enabled, disabled, onChange }: Props) => {
  return (
    <Box width="100%">
      <Typography variant="subtitle1" py={2}>
        Automation
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Avatar variant="rounded" sx={{ width: 32, height: 32, bgcolor: grey[100] }}>
              <ForwardEmailIcon color="action" />
            </Avatar>
            <Stack spacing={0.5} pr={0.5} width="100%">
              <Typography variant="subtitle2">Automatic Invitation Emails</Typography>
              <Typography color="text.secondary">
                Enable this option to automatically send invitation emails when a contact is assigned a portal role and
                there is at least one live investor document published for them.
              </Typography>
            </Stack>
            <Switch disabled={disabled} checked={enabled} onChange={(_, checked) => onChange(checked)} />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AutomaticInvitesSection;
