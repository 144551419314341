import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi, { Fund } from "../../../../api/adminApi";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import { ViewType } from "../../../common/ViewSelector";
import FundsCardList from "./FundsCardList";
import FundsFilterToolbar from "./FundsFiltersToolbar";
import FundsList from "./FundsList";

const FundStructureFundsPage = () => {
  const navigate = useNavigate();

  const [viewType, setViewType] = useState<ViewType>("List");
  const [filteredFunds, setFilteredFunds] = useState<Fund[]>([]);

  const getFunds = useCallback(() => adminApi.searchFunds({ fieldIds: [], includeInvestorCount: true }), []);

  const [fundsResp, error, { isFetching }] = useFetch(getFunds, (resp) =>
    setFilteredFunds(resp.items.sort((a, b) => a.name.localeCompare(b.name)))
  );

  const navigateToFund = useCallback(
    (fundId: string) => {
      navigate(`./${fundId}/main`);
    },
    [navigate]
  );

  if (error) {
    logError(error, "FundStructureFundsPage");
    return <DataLoadingFailed bgColor="none" title="Loading funds" />;
  }

  const funds = fundsResp?.items ?? [];
  const isLoading = isFetching || fundsResp === undefined;

  return (
    <Box height="100vh" display="flex" overflow="hidden" flexDirection="column">
      <GeneralPageHeader title="Funds" />
      <FundsFilterToolbar
        funds={funds}
        filteredFunds={filteredFunds}
        onFilter={setFilteredFunds}
        viewType={viewType}
        setViewType={setViewType}
        disabled={isLoading}
      />
      <Box flex={1} overflow="auto" mx={3} mb={2.5}>
        {viewType === "List" && <FundsList onFundClick={navigateToFund} funds={filteredFunds} isLoading={isLoading} />}
        {viewType === "Grid" && (
          <FundsCardList onFundClick={navigateToFund} funds={filteredFunds} isLoading={isLoading} />
        )}
      </Box>
    </Box>
  );
};

export default FundStructureFundsPage;
