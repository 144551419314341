import { ApiGatewaySyncLogs } from "../../../../api/adminApi";
import { LogSeverity } from "../../../../api/types/logTypes";
import { formatLogTimestamp } from "../../../common/syncLogs/utils";

export interface LogsTableRowData {
  key: string;
  severity: LogSeverity;
  completed: string;
  description: string;
  numberOfChanges: number;
  details: ApiGatewaySyncLogs;
}

const itemToRowData = (item: ApiGatewaySyncLogs, index: number): LogsTableRowData => ({
  key: `${index}_${item.startTime}_${item.endTime}`,
  severity: item.severity,
  completed: formatLogTimestamp(item.endTime),
  description: item.description,
  numberOfChanges: item.numberOfChanges,
  details: item,
});

export const getRows = (items: ApiGatewaySyncLogs[]): LogsTableRowData[] =>
  items.map((item, i) => itemToRowData(item, i));
