import { Button, ButtonProps } from "@mui/material";
import { PropsWithChildren } from "react";

const OutlinedIconButton = ({ children, ...props }: PropsWithChildren<ButtonProps>) => {
  const { sx, ...buttonProps } = props;
  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={children}
      sx={{ width: 32, height: 32, minWidth: 32, p: 0, "& .MuiButton-startIcon": { m: 0 }, ...sx }}
      {...buttonProps}
    />
  );
};

export default OutlinedIconButton;
