import { createSvgIcon } from "@mui/material";

const FilesIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor">
    <path d="M5.58341 17.5C5.18063 17.5 4.82299 17.3681 4.51049 17.1042C4.19799 16.8403 4.00702 16.5069 3.93757 16.1042L2.66674 8.47917C2.62507 8.22917 2.6841 8.00347 2.84382 7.80208C3.00355 7.60069 3.21535 7.5 3.47924 7.5H16.5209C16.7848 7.5 16.9966 7.60069 17.1563 7.80208C17.316 8.00347 17.3751 8.22917 17.3334 8.47917L16.0626 16.1042C15.9931 16.5069 15.8022 16.8403 15.4897 17.1042C15.1772 17.3681 14.8195 17.5 14.4167 17.5H5.58341ZM4.50007 9.16667L5.56257 15.8333H14.4376L15.5001 9.16667H4.50007ZM8.33341 12.5H11.6667C11.9029 12.5 12.1008 12.4201 12.2605 12.2604C12.4202 12.1007 12.5001 11.9028 12.5001 11.6667C12.5001 11.4306 12.4202 11.2326 12.2605 11.0729C12.1008 10.9132 11.9029 10.8333 11.6667 10.8333H8.33341C8.0973 10.8333 7.89938 10.9132 7.73966 11.0729C7.57993 11.2326 7.50007 11.4306 7.50007 11.6667C7.50007 11.9028 7.57993 12.1007 7.73966 12.2604C7.89938 12.4201 8.0973 12.5 8.33341 12.5ZM5.00007 6.66667C4.76396 6.66667 4.56605 6.58681 4.40632 6.42708C4.2466 6.26736 4.16674 6.06944 4.16674 5.83333C4.16674 5.59722 4.2466 5.39931 4.40632 5.23958C4.56605 5.07986 4.76396 5 5.00007 5H15.0001C15.2362 5 15.4341 5.07986 15.5938 5.23958C15.7535 5.39931 15.8334 5.59722 15.8334 5.83333C15.8334 6.06944 15.7535 6.26736 15.5938 6.42708C15.4341 6.58681 15.2362 6.66667 15.0001 6.66667H5.00007ZM6.66674 4.16667C6.43063 4.16667 6.23271 4.08681 6.07299 3.92708C5.91327 3.76736 5.83341 3.56944 5.83341 3.33333C5.83341 3.09722 5.91327 2.89931 6.07299 2.73958C6.23271 2.57986 6.43063 2.5 6.66674 2.5H13.3334C13.5695 2.5 13.7674 2.57986 13.9272 2.73958C14.0869 2.89931 14.1667 3.09722 14.1667 3.33333C14.1667 3.56944 14.0869 3.76736 13.9272 3.92708C13.7674 4.08681 13.5695 4.16667 13.3334 4.16667H6.66674Z" />
  </svg>,
  "FilesIcon"
);

export default FilesIcon;
