import { CrmSyncLogs } from "../../../../api/crmConnectorsApi";
import { LogSeverity } from "../../../../api/types/logTypes";
import { formatLogTimestamp } from "../../../common/syncLogs/utils";

export interface LogsTableRowData {
  key: string;
  severity: LogSeverity;
  completed: string;
  contacts: number;
  investors: number;
  details: CrmSyncLogs;
}

const itemToRowData = (item: CrmSyncLogs, index: number): LogsTableRowData => ({
  key: `${index}_${item.startTime}_${item.endTime}`,
  severity: item.severity,
  completed: formatLogTimestamp(item.endTime),
  contacts: item.contactsAdded + item.contactsUpdated + item.contactsRemoved,
  investors: item.investorsAdded + item.investorsUpdated,
  details: item,
});

export const getRows = (items: CrmSyncLogs[]): LogsTableRowData[] => items.map(itemToRowData);
