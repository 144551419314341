import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { formatNumber } from "../../../../../../shared/utilities/formatters";

interface Props {
  vendor?: string;
  dueDate?: string;
  currencyCode?: string;
  amount?: number;
}

const InvoiceSummaryData = ({ amount, currencyCode, dueDate, vendor }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        bgcolor: grey[50],
        borderRadius: 1,
        p: 2,
        border: `1px solid ${theme.palette.divider}`,
      })}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1.5}>
          <Avatar variant="rounded">
            <ReceiptLongRoundedIcon color="action" />
          </Avatar>
          <Stack spacing={0.5}>
            <Typography variant="subtitle1">{vendor}</Typography>
            <Stack direction="row" spacing={0.5}>
              <Typography variant="caption" color="text.secondary">
                Payment Due:
              </Typography>
              <Typography variant="caption">{dueDate && convertISODate(dueDate)}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Typography variant="h5">
          {amount && formatNumber(amount, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencyCode}
        </Typography>
      </Stack>
    </Box>
  );
};

export default InvoiceSummaryData;
