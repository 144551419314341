import { EntityFieldType, Field } from "../../api/types/objectTypes";
import { FieldGroupModel } from "./entityFieldTypes";

const formatEntityInputId = (entity: string, fieldIndex: number): string => `ef_${entity}_${fieldIndex}`;

const parseEntityInputId = (elementId: string) => {
  const parts = elementId.split("_");
  return {
    entity: parts[1] ?? "",
    fieldIndex: parseInt(parts[2] ?? ""),
  };
};

const navigatableFieldTypes: EntityFieldType[] = [
  "Text",
  "Email",
  "Phone",
  "Url",
  "Number",
  "Money",
  "Percent",
  "Select",
  "MultiSelect",
  "UserDefinedOptionsSelect",
  "UserDefinedOptionsMultiSelect",
  "Date",
  "Lookup",
];

const isFieldNavigatableViaTab = (field: Field): boolean =>
  !field.attributes.includes("ReadOnly") && navigatableFieldTypes.includes(field.type);

export const getFieldInputIdsPerGroup = (
  entity: string,
  visibleFieldGroups: FieldGroupModel[]
): Map<string, string>[] => {
  const result: Map<string, string>[] = [];
  let index = 0;

  for (const fieldGroup of visibleFieldGroups) {
    const fieldIdToInputIdMap = new Map<string, string>();
    for (const field of fieldGroup.fields) {
      if (isFieldNavigatableViaTab(field)) {
        fieldIdToInputIdMap.set(field.id, formatEntityInputId(entity, index));
        index++;
      }
    }
    result.push(fieldIdToInputIdMap);
  }

  return result;
};

export const findNextEntityInput = (elementId: string): HTMLElement | undefined => {
  const { entity, fieldIndex } = parseEntityInputId(elementId);
  return Number.isNaN(fieldIndex)
    ? undefined
    : document.getElementById(formatEntityInputId(entity, fieldIndex + 1)) ?? undefined;
};

export const findPreviousEntityInput = (elementId: string): HTMLElement | undefined => {
  const { entity, fieldIndex } = parseEntityInputId(elementId);
  return Number.isNaN(fieldIndex) || fieldIndex === 0
    ? undefined
    : document.getElementById(formatEntityInputId(entity, fieldIndex - 1)) ?? undefined;
};
