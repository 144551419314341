import { SvgIcon, SvgIconProps } from "@mui/material";

const DocumentIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <mask
      id="mask0_690_18787"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill={props.fill ?? "#C7DBE5"} />
    </mask>
    <g mask="url(#mask0_690_18787)">
      <path
        d="M7.49967 15.0003H12.4997C12.7358 15.0003 12.9337 14.9205 13.0934 14.7607C13.2531 14.601 13.333 14.4031 13.333 14.167C13.333 13.9309 13.2531 13.733 13.0934 13.5732C12.9337 13.4135 12.7358 13.3337 12.4997 13.3337H7.49967C7.26356 13.3337 7.06565 13.4135 6.90592 13.5732C6.7462 13.733 6.66634 13.9309 6.66634 14.167C6.66634 14.4031 6.7462 14.601 6.90592 14.7607C7.06565 14.9205 7.26356 15.0003 7.49967 15.0003ZM7.49967 11.667H12.4997C12.7358 11.667 12.9337 11.5871 13.0934 11.4274C13.2531 11.2677 13.333 11.0698 13.333 10.8337C13.333 10.5975 13.2531 10.3996 13.0934 10.2399C12.9337 10.0802 12.7358 10.0003 12.4997 10.0003H7.49967C7.26356 10.0003 7.06565 10.0802 6.90592 10.2399C6.7462 10.3996 6.66634 10.5975 6.66634 10.8337C6.66634 11.0698 6.7462 11.2677 6.90592 11.4274C7.06565 11.5871 7.26356 11.667 7.49967 11.667ZM4.99967 18.3337C4.54134 18.3337 4.14898 18.1705 3.82259 17.8441C3.4962 17.5177 3.33301 17.1253 3.33301 16.667V3.33366C3.33301 2.87533 3.4962 2.48296 3.82259 2.15658C4.14898 1.83019 4.54134 1.66699 4.99967 1.66699H10.9788C11.2011 1.66699 11.4129 1.70866 11.6143 1.79199C11.8156 1.87533 11.9927 1.99338 12.1455 2.14616L16.1872 6.18783C16.34 6.3406 16.458 6.51769 16.5413 6.71908C16.6247 6.92046 16.6663 7.13227 16.6663 7.35449V16.667C16.6663 17.1253 16.5031 17.5177 16.1768 17.8441C15.8504 18.1705 15.458 18.3337 14.9997 18.3337H4.99967ZM10.833 6.66699V3.33366H4.99967V16.667H14.9997V7.50033H11.6663C11.4302 7.50033 11.2323 7.42046 11.0726 7.26074C10.9129 7.10102 10.833 6.9031 10.833 6.66699Z"
        fill={props.fill ?? "#C7DBE5"}
      />
    </g>
  </SvgIcon>
);

export default DocumentIcon;
