import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import ColorTag from "../../../../../../shared/components/ColorTag";
import { CompanyWithAccess } from "../definitions";

interface Props {
  company: CompanyWithAccess;
  total: number;
}

const CompanyEntities = ({ company, total }: Props) => {
  const entities = company.entities.sort((a, b) => a.name.localeCompare(b.name));
  const twoOrLessEntitiesToShow = entities.length > 2 ? entities.slice(0, 2) : entities;
  const allEntities = entities.length === total || company.accessToAllEntities;
  const assignedEntitiesCount = company.entities.length;
  const totalsLabel = allEntities ? `${total}` : `${assignedEntitiesCount}/${total}`;

  return (
    <Stack sx={{ height: 3 }} direction="row" justifyItems="center" alignItems="center" spacing={4}>
      <Typography sx={{ width: "3rem" }} variant="subtitle2">
        Entities
      </Typography>
      {!company.entities.length && !company.accessToAllEntities && (
        <Typography>Set entities access. Access will be limited to specified entities.</Typography>
      )}
      <Stack direction="row" justifyItems="center" alignItems="center" spacing={2.5}>
        {allEntities && <Typography>All Entities</Typography>}
        {!allEntities && <Typography>{twoOrLessEntitiesToShow.map((entity) => entity.name).join(";  ")}</Typography>}
        {!company.accessToAllEntities && <ColorTag text={totalsLabel} color={grey[200]} textColor="text.secondary" />}
      </Stack>
    </Stack>
  );
};

export default CompanyEntities;
