import { createTheme } from "@mui/material";
import { darkThemeOptions, lightThemeOptions } from "../shared/theme";

const customizedLightThemeOptions = {
  ...lightThemeOptions,
  components: {
    ...lightThemeOptions.components,
  },
};

const customizedDarkThemeOptions = {
  ...darkThemeOptions,
  components: {
    ...darkThemeOptions.components,
  },
};

const lightTheme = createTheme(customizedLightThemeOptions);
const darkTheme = createTheme(customizedDarkThemeOptions);

export { lightTheme, darkTheme };
