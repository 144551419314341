import { Typography } from "@mui/material";
import { Fragment } from "react";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { Maybe } from "../../../../../../shared/types";

interface Props {
  vendor: Maybe<string>;
  entity: Maybe<string>;
  dueDate: Maybe<string>;
}

const InvoiceDetailsHeaderTitle = ({ dueDate, entity, vendor }: Props) => {
  const titleElements = [];
  if (vendor) {
    titleElements.push(vendor);
  }
  if (entity) {
    titleElements.push(entity);
  }
  if (dueDate) {
    titleElements.push(convertISODate(dueDate));
  }

  if (titleElements.length === 0) {
    return null;
  }

  return (
    <Typography variant="h6">
      {titleElements.flatMap((element, index) => {
        const elementJsx = <Fragment key={`element-${index}`}>{element}</Fragment>;
        return index === titleElements.length - 1
          ? [elementJsx]
          : [
              elementJsx,
              <Typography component={"span"} mx={0.5} key={`separator-${index}`}>
                &bull;
              </Typography>,
            ];
      })}
    </Typography>
  );
};

export default InvoiceDetailsHeaderTitle;
