import { Box, Divider, Popover } from "@mui/material";
import { useMemo, useRef } from "react";
import ScrollableFlexContainer from "../../ScrollableFlexContainer";
import PdfDocumentWrapper from "./PdfDocumentWrapper";
import PdfPagesContainer from "./PdfPagesContainer";
import PdfThumbnailsContainer from "./PdfThumbnailsContainer";
import PdfViewerControls from "./PdfViewerControls";
import { usePdfViewer } from "./pdfViewerState";
import { pdfViewerStyles } from "./pdfViewerStyles";

interface Props {
  fileSrc: string;
  fileName: string;
}

const PdfPreview = ({ fileSrc, fileName }: Props) => {
  const {
    viewerState,
    containerRef,
    pageRefs,
    containerSize,
    handleScroll,
    navigateToPage,
    toggleThumbnails,
    updateCurrentPageInputValue,
    handleDocumentLoaded,
    updateLoadingProgress,
    zoomIn,
    zoomOut,
  } = usePdfViewer();

  const titleRef = useRef<HTMLDivElement | null>(null);

  const containerOrientation = useMemo(
    () =>
      containerSize?.height && containerSize?.width
        ? containerSize.height > containerSize.width
          ? "portrait"
          : "landscape"
        : undefined,
    [containerSize?.height, containerSize?.width]
  );

  const pageHeight = useMemo(
    () => (containerSize?.height && containerOrientation === "landscape" ? containerSize?.height - 48 : undefined),
    [containerOrientation, containerSize?.height]
  );

  const pageWidth = useMemo(
    () => (containerSize?.width && containerOrientation === "portrait" ? containerSize?.width - 48 : undefined),
    [containerOrientation, containerSize?.width]
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box ref={titleRef} py={1} px={2.5}>
        <PdfViewerControls
          fileName={fileName}
          viewerState={viewerState}
          hideFileName
          onNavigateToPage={navigateToPage}
          onToggleThumbnails={toggleThumbnails}
          onPageInputValueChange={updateCurrentPageInputValue}
          onZoomOut={zoomOut}
          onZoomIn={zoomIn}
        />
      </Box>
      <Divider />
      <Box flex={1} ref={containerRef} bgcolor={pdfViewerStyles.bgcolor}>
        <ScrollableFlexContainer onScroll={handleScroll} sx={{ height: "100%" }}>
          <Box height="100%">
            <PdfDocumentWrapper
              fileSrc={fileSrc}
              viewerState={viewerState}
              onDocumentLoad={handleDocumentLoaded}
              onLoadingProgress={updateLoadingProgress}
            >
              <Popover
                open={viewerState.showThumbnails}
                onClose={() => toggleThumbnails()}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                anchorEl={titleRef.current}
                sx={{ "& .MuiPopover-paper": { maxHeight: containerSize?.height } }}
              >
                <PdfThumbnailsContainer viewerState={viewerState} />
              </Popover>

              <PdfPagesContainer
                viewerState={viewerState}
                pageRefs={pageRefs}
                pageHeight={pageHeight}
                pageWidth={pageWidth}
              />
            </PdfDocumentWrapper>
          </Box>
        </ScrollableFlexContainer>
      </Box>
    </Box>
  );
};

export default PdfPreview;
