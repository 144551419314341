import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props extends AccordionProps {
  titleComponent: React.ReactNode;
}

const SectionAccordion = ({ titleComponent, children, ...accordionProps }: PropsWithChildren<Props>) => {
  return (
    <Accordion
      disableGutters
      defaultExpanded
      sx={(t) => ({ boxShadow: "none", borderRadius: 1, border: `1px solid ${t.palette.divider}` })}
      {...accordionProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{titleComponent}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SectionAccordion;
