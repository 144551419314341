import { Autocomplete, ClickAwayListener, ListItem, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Maybe } from "../../../../shared/types";
import { ValidationResult, invalidResult, validResult } from "../../../../shared/utilities/validators";
import { FieldAttribute, MultiLabelSelectOption } from "../../../api/types/objectTypes";
import { useFieldValuesContext } from "../FieldValuesContext";
import { findNextEntityInput, findPreviousEntityInput } from "../entityFieldNavigator";
import FieldValueWrapper from "./FieldValueWrapper";

interface Props {
  fieldId: string;
  value?: Maybe<string>;
  options: MultiLabelSelectOption[];
  fieldAttributes: FieldAttribute[];
  onChange: (value: string, validationResult: ValidationResult) => void;
  fieldInputId?: string;
  displayValueComponent?: JSX.Element | JSX.Element[] | null;
}

interface SelectState {
  isOpened: boolean;
  isEdit: boolean;
  validationResult: ValidationResult;
  selectedValue: Maybe<string>;
}

const EntityFieldSelectComponent = ({
  fieldId,
  value,
  options,
  fieldAttributes,
  onChange,
  fieldInputId,
  displayValueComponent,
}: Props) => {
  const { validateUniqueFieldValue } = useFieldValuesContext();

  const [state, setState] = useState<SelectState>({
    selectedValue: value,
    isOpened: false,
    isEdit: false,
    validationResult: validResult(),
  });

  useEffect(() => {
    if (value !== state.selectedValue) {
      setState((current) => ({ ...current, selectedValue: value }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const validate = async () => {
    const { selectedValue } = state;
    if (fieldAttributes.includes("Required") && !selectedValue) {
      return invalidResult("Required field. Please select the necessary value in the select field.");
    }

    const isValid = options.some((o) => o.value === selectedValue) || !selectedValue || selectedValue === value;
    if (!isValid) {
      return invalidResult("Invalid value");
    }

    if (fieldAttributes.includes("ObjectIdentifier")) {
      const uniqueValidationResult = await validateUniqueFieldValue(fieldId, selectedValue);
      if (!uniqueValidationResult.isValid) {
        return uniqueValidationResult;
      }
    }

    return validResult();
  };

  const handleValueOnLeave = async () => {
    const validationResult = await validate();

    setState((current) => ({
      ...current,
      isOpened: !validationResult.isValid,
      isEdit: !validationResult.isValid,
      validationResult,
    }));

    onChange(state.selectedValue || "", validationResult);
  };

  const handleEditChange = (edit: boolean) => {
    if (edit) {
      setState((current) => ({ ...current, isOpened: true, isEdit: true, validationResult: validResult() }));
    } else {
      setState((current) => ({ ...current, isOpened: false, isEdit: false }));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab" && fieldInputId) {
      const nextElement = e.shiftKey ? findPreviousEntityInput(fieldInputId) : findNextEntityInput(fieldInputId);
      if (nextElement) {
        e.preventDefault();
        nextElement.click();
      }
      return;
    }
  };

  const selectedOption = state.selectedValue ? options.find((o) => o.value === state.selectedValue) : undefined;

  return (
    <>
      {!state.isEdit && (
        <FieldValueWrapper
          id={fieldInputId}
          isReadonly={fieldAttributes.includes("ReadOnly")}
          displayValue={selectedOption?.label || state.selectedValue || ""}
          isEdit={false}
          onEditChange={handleEditChange}
          emptyPlaceholder="Select"
          displayValueComponent={displayValueComponent}
        />
      )}
      {state.isEdit && (
        <ClickAwayListener onClickAway={handleValueOnLeave}>
          <Autocomplete
            fullWidth
            open={state.isOpened}
            options={options}
            onClose={() => setState((current) => ({ ...current, isOpened: false }))}
            getOptionLabel={({ label }) => label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={selectedOption ?? null}
            onChange={(_e, newValue) => {
              setState((current) => ({
                ...current,
                validationResult: validResult(),
                selectedValue: typeof newValue === "string" ? newValue : newValue?.value,
              }));
            }}
            renderInput={(params) => (
              <TextField
                autoFocus
                {...params}
                error={!state.validationResult.isValid}
                helperText={state.validationResult.error}
                onKeyDown={handleKeyDown}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.value}>
                <Stack>
                  <Typography>{option.label}&nbsp;</Typography>
                  {option.secondaryLabel && (
                    <Typography color="text.secondary">{option.secondaryLabel}&nbsp;</Typography>
                  )}
                </Stack>
              </ListItem>
            )}
          />
        </ClickAwayListener>
      )}
    </>
  );
};

export default EntityFieldSelectComponent;
