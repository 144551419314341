import { parseISO } from "date-fns";
import {
  distinct,
  groupByToMap,
  maxBy,
  minBy,
  numberComparerBy,
  sumBy,
} from "../../../../shared/utilities/arrayHelper";
import { convertISODateTime, formatDate } from "../../../../shared/utilities/dateUtils";
import { formatFileSize, getFileExtension } from "../../../../shared/utilities/fileHelper";
import { commaSeparated } from "../../../../shared/utilities/stringHelper";
import { defined } from "../../../../shared/utilities/typeHelper";
import { DataCatalogueEntityType, FileDetails, FileInfo, FileTag } from "../../../api/types/fileTypes";

export interface TagSelectItem {
  id: string;
  label: string;
}

const entityTypeNames = new Map<DataCatalogueEntityType, string>([
  ["Fund", "Fund"],
  ["Investor", "Investor"],
  ["Contact", "Contact"],
  ["DocumentCollection", "Document Collection"],
  ["PostMessageRequest", "Investor Portal"],
  ["Vendor", "Vendor"],
  ["InvesteeFund", "Fund Investment"],
  ["PortfolioCompany", "Portfolio Company"],
  ["Entity", "Entity"],
  ["PlatformObject", "Platform Object"],
]);

const orderedEntityTypes = [...entityTypeNames.keys()];

export const getEntityTitle = (entityType: DataCatalogueEntityType) => entityTypeNames.get(entityType) ?? entityType;

export const orderedEntityTypesForTags: DataCatalogueEntityType[] = ["Fund", "Investor", "Contact"];

export const groupTagsByEntityType = (tags: FileTag[]): Map<DataCatalogueEntityType, FileTag[]> =>
  groupByToMap(
    tags.flatMap((tag) => tag.entityTypes.map((entityType) => ({ ...tag, entityTypes: [entityType] }))),
    (tag) => defined(tag.entityTypes[0])
  );

export const getFileRelationsAttributes = (fileDetails: FileDetails): [string, string][] => {
  const relations = fileDetails.relations
    .filter((rel) => orderedEntityTypes.includes(rel.relationship.relationshipEntityType))
    .sort(numberComparerBy((rel) => orderedEntityTypes.indexOf(rel.relationship.relationshipEntityType)));

  const relationGroups = groupByToMap(relations, (rel) => rel.relationship.relationshipEntityType);

  const relationAttributes: [string, string][] = [];
  relationGroups.forEach((relations, entityType) =>
    relationAttributes.push([
      getEntityTitle(entityType),
      commaSeparated(...relations.map((r) => r.relatedItemName).sort()),
    ])
  );
  return relationAttributes;
};

const getFileType = (file: FileInfo) => getFileExtension(file.fileName).slice(1).toUpperCase();

export const getFileInformationAttributes = (fileDetails: FileDetails): [string, string][] => {
  const fileType = getFileType(fileDetails);
  const fileSize = formatFileSize(fileDetails.fileSize);
  const uploadedBy = fileDetails.createdByUsername ?? "";
  const uploadedAt = convertISODateTime(fileDetails.createdAt);

  return [
    ["File type", fileType],
    ["Size", fileSize],
    ["Uploaded by", uploadedBy],
    ["Uploaded at", uploadedAt],
  ];
};

const formatWords = (words: string[], maxWords: number) =>
  words.length <= maxWords
    ? commaSeparated(...words)
    : commaSeparated(...words.slice(maxWords), `and ${words.length - maxWords + 1} more`);

export const getMultipleFilesInformationAttributes = (files: FileInfo[]): [string, string][] => {
  const fileTypes = formatWords(distinct(files.map(getFileType)).sort(), 3);
  const totalSize = formatFileSize(sumBy(files, (f) => f.fileSize));
  const uploadedBy = formatWords(distinct(files.map((file) => file.createdByUsername ?? "")).sort(), 3);
  const uploadedFrom = minBy(files, (f) => parseISO(f.createdAt).getTime());
  const uploadedUntil = maxBy(files, (f) => parseISO(f.createdAt).getTime());
  const uploadedFromDate = formatDate(new Date(uploadedFrom));
  const uploadedUntilDate = formatDate(new Date(uploadedUntil));
  const uploaded =
    uploadedFromDate === uploadedUntilDate ? uploadedFromDate : `${uploadedFromDate} - ${uploadedUntilDate}`;

  return [
    ["File types", fileTypes],
    ["Total size", totalSize],
    ["Uploaded by", uploadedBy],
    ["Uploaded", uploaded],
  ];
};

export const extractFileTags = (fileDetails: FileDetails): TagSelectItem[] => {
  return fileDetails.relations
    .filter((rel) => rel.relationship.relationshipEntityType === "Tag")
    .map((rel) => ({ id: rel.relationship.relatedItemId, label: rel.relatedItemName }));
};
