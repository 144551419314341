import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { distinctBy } from "../../../../../../shared/utilities/arrayHelper";
import adminApi, { FundraisingDocument, InvestorFund } from "../../../../../api/adminApi";
import { Category } from "../../../../../api/types/accessTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import FundraisingDocumentFilters from "../../common/FundraisingDocumentFilters";
import {
  filterFundraisingDocuments,
  FundraisingDocumentsFilterValue,
  getInitialFilters,
} from "../../common/fundraisingDocumentsFiltering";
import FundraisingDocumentsGrid from "../../common/FundraisingDocumentsGrid";

interface Props {
  investorId: string;
  categories: Category[];
  investorFunds: InvestorFund[];
}

const InvestorFundraisingDocumentsTab = ({ investorId, categories, investorFunds }: Props) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<FundraisingDocumentsFilterValue>(getInitialFilters());

  const fetchDocuments = useCallback(() => adminApi.getInvestorFundraisingDocuments(investorId), [investorId]);

  const [allDocuments, fetchError, { isFetching }] = useFetch(fetchDocuments);

  if (fetchError) {
    logError(fetchError, "[InvestorFundraisingDocumentsTab]");
    return <DataLoadingFailed bgColor="none" title="Loading investor documents failed" />;
  }

  const handleFiltersChange = (filterValue: FundraisingDocumentsFilterValue) => {
    setFilters(filterValue);
  };

  const handleClearFilters = () => {
    setFilters({ ...getInitialFilters(), searchTerm: filters.searchTerm });
  };

  const handleNavigateToDocumentActivity = (document: FundraisingDocument) => {
    navigate(
      `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investors}/${investorId}/${pageRoutes.fundraisings}/${document.fundraisingId}/${pageRoutes.documents}/${document.id}/${pageRoutes.activity}`
    );
  };

  const categoryOptions = categories
    .filter((c) => c.type === "Fundraising")
    .map(({ externalId, name }) => ({ value: externalId, label: name }));

  const fundOptions = distinctBy(
    investorFunds.map(({ fundId, fundName }) => ({ value: fundId, label: fundName })),
    ({ value }) => value
  );

  const documents = filterFundraisingDocuments(allDocuments ?? [], filters);

  return (
    <Stack spacing={2.5} sx={{ height: "100%" }}>
      <FundraisingDocumentFilters
        categoryOptions={categoryOptions}
        fundOptions={fundOptions}
        filters={filters}
        onChange={handleFiltersChange}
        onClear={handleClearFilters}
        isLoading={isFetching}
        recordsCount={documents.length}
        totalRecordsCount={allDocuments?.length ?? 0}
      />
      <FundraisingDocumentsGrid
        documents={documents}
        categories={categories}
        isLoading={isFetching}
        excludeColumns={["fundInvestors.investorName"]}
        onNavigateToDocumentActivity={handleNavigateToDocumentActivity}
      />
    </Stack>
  );
};

export default InvestorFundraisingDocumentsTab;
