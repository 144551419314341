import { Chip } from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";
import StatusChip from "../../../shared/components/StatusChip";
import { PortalRole } from "../../api/types/accessTypes";
import { portalRoleCaptionMap } from "../../utilities/enumCaptions";

interface Props {
  roles: PortalRole[];
}

const PortalRoleChip = ({ roles }: Props) => {
  const resolvedRole = roles.includes("LP_Admin") ? "LP_Admin" : roles.includes("LP_Member") ? "LP_Member" : "None";

  switch (resolvedRole) {
    case "LP_Admin": {
      return <StatusChip label={portalRoleCaptionMap["LP_Admin"]} color={blue[700]} />;
    }
    case "LP_Member": {
      return <StatusChip label={portalRoleCaptionMap["LP_Member"]} color={green[700]} />;
    }
    default: {
      return <Chip sx={{ backgroundColor: "transparent", border: 1, borderColor: grey[200] }} label="None" />;
    }
  }
};

export default PortalRoleChip;
