import { Box, Button } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { GridSlotsComponentsProps } from "@mui/x-data-grid-premium";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    onLineAdd: () => void;
    disabled?: boolean;
  }
}

const InvoiceLinesGridFooter = ({ onLineAdd, disabled }: NonNullable<GridSlotsComponentsProps["footer"]>) => {
  return (
    <Box sx={{ px: 1, borderTop: "1px solid", borderColor: "inherit" }}>
      <Button startIcon={<AddRoundedIcon />} color="secondary" onClick={onLineAdd} disabled={disabled}>
        Add Line
      </Button>
    </Box>
  );
};

export default InvoiceLinesGridFooter;
