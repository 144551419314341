import React, { PropsWithChildren, useCallback } from "react";
import DeleteReport from "../../../../../../../shared/reporting/components/reports/DeleteReport";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import { ApiReportsManagerContextProvider } from "./ReportsUiManagerContext";
import { DeleteReportItem, ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";
import { ApiResponse } from "../../../../../../../shared/api/types";
import DuplicateReport from "../DuplicateReport";
import ShareReportWrapper from "../../components/ShareReportWrapper";
import { useApiReportsContext } from "./ReportsContext";

interface Props {
  reports: ReportInfo[];
  deleteReport: (reports: DeleteReportItem[]) => Promise<ApiResponse<unknown>>;
  duplicate: (report: ReportInfo, name: string, groupId: string | undefined) => Promise<ReportInfo | undefined>;
  onDeleted?: () => void;
}
export default function ApiReportsManager({
  reports,
  children,
  onDeleted,
  deleteReport,
  duplicate,
}: PropsWithChildren<Props>) {
  const { sendNotification } = useNotificationContext();
  const { actions } = useApiReportsContext();
  const { report: locale } = useLocalization();

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);

  const [reportsToDelete, setReportsToDelete] = React.useState<ReportInfo[]>([]);
  const [reportToDuplicate, setReportToDuplicate] = React.useState<ReportInfo>();
  const [reportToShare, setReportToShare] = React.useState<ReportInfo>();

  const deleteReports = useCallback(
    (ids: string[]) => {
      const toDelete = reports.filter((r) => ids.includes(r.reportId));
      setReportsToDelete(toDelete);
      setOpenDeleteDialog(true);
    },
    [reports]
  );

  const duplicateReport = useCallback((report: ReportInfo) => {
    setReportToDuplicate(report);
    setOpenDuplicateDialog(true);
  }, []);

  const shareReport = useCallback((report: ReportInfo) => {
    setReportToShare(report);
    setOpenShareDialog(true);
  }, []);

  return (
    <ApiReportsManagerContextProvider
      deleteReports={deleteReports}
      duplicateReport={duplicateReport}
      shareReport={shareReport}
    >
      {children}
      {openDeleteDialog && reportsToDelete.length > 0 && (
        <DeleteReport
          reports={reportsToDelete}
          doDelete={deleteReport}
          onDeleted={() => {
            sendNotification(reportsToDelete.length > 1 ? locale.deleted_multiple : locale.deleted);
            onDeleted?.();
          }}
          onClose={() => {
            setOpenDeleteDialog(false);
            setReportsToDelete([]);
          }}
        />
      )}
      {openDuplicateDialog && reportToDuplicate && (
        <DuplicateReport
          report={reportToDuplicate}
          duplicateReport={duplicate}
          onChanged={() => sendNotification(locale.duplicated)}
          onClose={() => {
            setOpenDuplicateDialog(false);
            setReportToDuplicate(undefined);
          }}
        />
      )}

      {openShareDialog && reportToShare && (
        <ShareReportWrapper
          report={reportToShare}
          onClose={() => {
            setOpenShareDialog(false);
            setReportToShare(undefined);
          }}
          updateReportAuthorizationInfo={actions.updateReportAuthorizationInfo}
        />
      )}
    </ApiReportsManagerContextProvider>
  );
}
