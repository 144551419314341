import {
  ValidationResult,
  combineValidators,
  requiredValidator,
  uniqueValidator,
  validResult,
} from "../../../../../shared/utilities/validators";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";

interface EditConfigurationState {
  name: string;
  validationResult: ValidationResult;
  editedConfiguration: DataModelConfiguration | undefined;
  otherNames: string[];
}

export const getInitialEditConfigurationState = (
  allConfigurations: DataModelConfiguration[],
  editedConfiguration?: DataModelConfiguration
): EditConfigurationState => ({
  name: editedConfiguration?.name ?? "",
  validationResult: validResult(),
  editedConfiguration,
  otherNames: allConfigurations.filter((c) => c.id !== editedConfiguration?.id).map((c) => c.name),
});

// Actions

type StateAction = (state: EditConfigurationState) => EditConfigurationState;

const validate = (otherNames: string[]) =>
  combineValidators(requiredValidator, uniqueValidator("Configuration with this name already exists", otherNames));

export const updateNameAction =
  (value: string): StateAction =>
  (state) => ({
    ...state,
    name: value,
    validationResult: validate(state.otherNames)(value),
  });

// Selectors

type StateSelector<T> = (state: EditConfigurationState) => T;

export const isFormDirty: StateSelector<boolean> = (state) => {
  if (!state.editedConfiguration) {
    return state.name.trim() !== "";
  }

  return state.name.trim().toLowerCase() !== state.editedConfiguration.name.trim().toLowerCase();
};
