import { Button, Popover, Stack, Typography } from "@mui/material";
import { minutesToMilliseconds } from "date-fns";
import { useLayoutEffect, useState } from "react";
import { isAppUpdateAvailable, isAppUpdatePopupExpired, saveLastAppUpdatePopupTime } from "../services/appVersion";

interface Props {
  cooldownInMinutes: number;
}

const UpdateAppPopover = ({ cooldownInMinutes }: Props) => {
  const [isOpen, setOpen] = useState(false);

  useLayoutEffect(() => {
    const isUpdateAvailable = isAppUpdateAvailable();
    if (!isUpdateAvailable) {
      setOpen(false);
      return;
    }

    if (isAppUpdatePopupExpired(minutesToMilliseconds(cooldownInMinutes))) {
      saveLastAppUpdatePopupTime();
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <Popover
      open={isOpen}
      onClose={() => setOpen(false)}
      anchorPosition={{ top: 20, left: window.innerWidth - 20 }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ "& .MuiPaper-root": { borderWidth: 1, borderStyle: "solid", borderColor: "primary.main" } }}
    >
      <Stack spacing={1} py={1} px={1} width="17rem" bgcolor="background.grey">
        <Typography variant="subtitle2" textAlign="center">
          A new version of the app is available.
        </Typography>
        <Typography color="text.secondary" textAlign="center">
          Please update to ensure it works correctly.
        </Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Update
        </Button>
      </Stack>
    </Popover>
  );
};

export default UpdateAppPopover;
