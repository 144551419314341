import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SelectedCompany, SelectedCompanyReport } from "./SelectedCompany";
import { ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";

export type ValidationState = {
  companies: SelectedCompany[];
};

const initialState: ValidationState = { companies: [] };

const validationStateSlice = createSlice({
  name: "validationState",
  initialState: initialState,
  reducers: {
    setCompaniesAction: (state, action: PayloadAction<{ companyCodes: string[]; reports: ReportInfo[] }>) => {
      const newCompanyList = action.payload.companyCodes.map((code): SelectedCompany => {
        const alreadySelectedCompany = state.companies.find((c) => c.code === code);
        if (alreadySelectedCompany) {
          return alreadySelectedCompany;
        }

        return {
          code,
          reports: action.payload.reports.map((r) => ({
            companyCode: code,
            validating: false,
            validated: false,
            report: r,
          })),
        };
      });
      state.companies = newCompanyList;
    },
    removeCompanyAction: (state, action: PayloadAction<string>) => {
      const company = state.companies.find((c) => c.code === action.payload);
      if (company) {
        company.reports.forEach((r) => r.ct?.cancel());
        const newCompanyList = state.companies.filter((c) => c.code !== action.payload);
        state.companies = newCompanyList;
      }
    },
    updateReportAction: (
      state,
      action: PayloadAction<{ report: SelectedCompanyReport; changes: Partial<SelectedCompanyReport> }>
    ) => {
      const report = state.companies
        .find((c) => c.code === action.payload.report.companyCode)
        ?.reports.find((r) => r.report.reportId === action.payload.report.report.reportId);

      if (report) {
        Object.assign(report, action.payload.changes);
      }
    },
    updateCompanyAction: (state, action: PayloadAction<{ code: string; changes: Partial<SelectedCompany> }>) => {
      const company = state.companies.find((c) => c.code === action.payload.code);

      if (company) {
        Object.assign(company, action.payload.changes);
      }
    },
  },
});

export default validationStateSlice;

export const actions = validationStateSlice.actions;
