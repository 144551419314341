import { pageRoutes } from "../../../../routes";
import ObjectsListPage from "../../../entityFields/grid/ObjectsListPage";

const PortfolioCompaniesPage = () => {
  return (
    <ObjectsListPage
      objectType="PortfolioCompany"
      objectDetailsPathPattern={`/:clientCode/${pageRoutes.portfolio}/${pageRoutes.portfolioCompanies}/:id`}
      objectsTitle="Portfolio Companies"
      showLogos
    />
  );
};

export default PortfolioCompaniesPage;
