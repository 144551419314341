import { List, ListItem, ListItemText, Typography } from "@mui/material";

interface CompaniesListItem {
  label: string;
  value: string;
  iconSrc?: string;
}

interface CompaniesListProps {
  items: CompaniesListItem[];
}

const CompaniesList = ({ items }: CompaniesListProps) => {
  return (
    <List dense>
      {items.map((item) => (
        <ListItem key={item.value} dense>
          {item.iconSrc && <img src={item.iconSrc} alt="" width={20} height={20} />}
          <ListItemText sx={{ ml: 1 }} primary={<Typography>{item.label}</Typography>} />
        </ListItem>
      ))}
    </List>
  );
};

export default CompaniesList;
