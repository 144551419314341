import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import biClient, { CreateReportGroup } from "../../../../../../api/biApi";
import { SelectedCompany } from "./SelectedCompany";
import copyingStateSlice, { actions } from "./CopyingState";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { getAllNonExistedGroups } from "./getAllNonExistedGroups";
import { CopyCompany, CopyCompanyReport } from "./CopyCompany";

export default function useReportCopying(
  companies: SelectedCompany[],
  copyConditionValues: boolean,
  override: boolean
) {
  const [state, dispatch] = useReducer(copyingStateSlice.reducer, copyingStateSlice.getInitialState());
  const nonExistGroups = useMemo(() => getAllNonExistedGroups(companies), [companies]);

  const [groupsCreating, setGroupsCreating] = useState(nonExistGroups.length > 0);

  const copyCompaniesRef = useRef<CopyCompany[]>([]);
  const canceledRef = useRef(false);

  const reportsToCopy = useMemo(() => {
    return state.companies.flatMap((c) =>
      c.reports.filter((r) => r.copying === false && r.copied === false && !r.error)
    );
  }, [state.companies]);

  useEffect(() => {
    dispatch(actions.setCompaniesAction({ companies }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    copyCompaniesRef.current = state.companies;
  }, [state.companies]);

  const copyReport = useCallback(
    async (report: CopyCompanyReport) => {
      dispatch(actions.updateReportAction({ report, changes: { copying: true } }));

      const ct = biClient.copyReport(
        {
          reportId: report.report.reportId,
          fromCompanyCode: report.report.clientCode,
          toCompanyCode: report.toCompanyCode,
          copyConditionValues,
          override,
          groupIdSelectedByUser: report.userSelectedGroupId,
        },
        (resp) => {
          const changes: Partial<CopyCompanyReport> = { copying: false };
          if (!resp.success || resp.error) {
            changes.error = true;
          } else {
            changes.copied = true;
            changes.newReportId = resp.data.reportId;
          }
          dispatch(actions.updateReportAction({ report, changes }));
        },
        () => {
          dispatch(actions.updateReportAction({ report, changes: { copying: false, copied: false, error: true } }));
        }
      );
      dispatch(actions.updateReportAction({ report, changes: { ct } }));
    },
    [copyConditionValues, override]
  );

  useEffect(() => {
    if (canceledRef.current || groupsCreating) return;
    const reports = copyCompaniesRef.current.flatMap((c) => c.reports);
    const inProgressCount = reports.filter((r) => r.copying === true).length;
    const toCopy = reports.filter((r) => !r.copying && !r.copied && !r.error);
    if (inProgressCount < 3 && toCopy.length > 0) {
      copyReport(defined(toCopy[0]));
    }
  }, [copyReport, groupsCreating, reportsToCopy]);

  const retryCopy = useCallback((report: CopyCompanyReport) => {
    dispatch(actions.updateReportAction({ report, changes: { error: false, copied: false, copying: false } }));
  }, []);

  const cancel = useCallback(() => {
    canceledRef.current = true;
    copyCompaniesRef.current.forEach((r) => {
      r.reports.forEach((r) => {
        if (r.ct) {
          r.ct.cancel();
        }
      });
    });
  }, []);

  const createGroups = useCallback(async (groups: CreateReportGroup[]) => {
    try {
      await biClient.createReportGroups(groups);
    } catch (e) {
      //empty
    } finally {
      setGroupsCreating(false);
    }
  }, []);

  useEffect(() => {
    if (nonExistGroups.length > 0) {
      createGroups(nonExistGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonExistGroups]);

  return { copying: state.companies, groupsCreating, retryCopy, cancel };
}
