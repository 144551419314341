import {
  FilterHandler,
  OperatorOption,
  TextFieldValue,
  TextFilterDefinition,
  TextFilterOperator,
  TextFilterValue,
} from "../filterTypes";

export const textFilterOperatorOptions: readonly OperatorOption<TextFilterOperator>[] = [
  { label: "contains", value: "contains" },
  { label: "does not contain", value: "does_not_contain" },
  { label: "is empty", value: "empty" },
  { label: "is not empty", value: "not_empty" },
] as const;

export const textFilterHandler: FilterHandler<TextFilterValue, TextFieldValue, TextFilterDefinition> = {
  getDefaultValue: (definition: TextFilterDefinition) => ({
    operator: definition.operatorOptions?.[0] ?? "contains",
    text: "",
  }),

  isActive: ({ operator, text }: TextFilterValue) => {
    switch (operator) {
      case "empty": {
        return true;
      }
      case "not_empty": {
        return true;
      }
      case "contains": {
        return !!text;
      }
      case "does_not_contain": {
        return !!text;
      }
    }
  },

  formatValue: ({ operator, text }: TextFilterValue) => {
    switch (operator) {
      case "empty": {
        return "is empty";
      }
      case "not_empty": {
        return "is not empty";
      }
      case "contains": {
        return `contains "${text}"`;
      }
      case "does_not_contain": {
        return `does not contain "${text}"`;
      }
    }
  },

  matchesFieldValue: ({ operator, text }: TextFilterValue, fieldValue: TextFieldValue) => {
    switch (operator) {
      case "empty": {
        return !fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0);
      }

      case "not_empty": {
        return !!fieldValue && (!Array.isArray(fieldValue) || fieldValue.length > 0);
      }

      case "contains": {
        if (!text) {
          return true;
        }
        if (!fieldValue) {
          return false;
        }
        if (Array.isArray(fieldValue)) {
          return fieldValue.some((value) => !!value && value.toLowerCase().includes(text.toLowerCase()));
        }
        return fieldValue.toLowerCase().includes(text.toLowerCase());
      }

      case "does_not_contain": {
        if (!text) {
          return true;
        }
        if (!fieldValue) {
          return true;
        }
        if (Array.isArray(fieldValue)) {
          return fieldValue.every((value) => !value || !value.toLowerCase().includes(text.toLowerCase()));
        }
        return !fieldValue.toLowerCase().includes(text.toLowerCase());
      }
    }
  },
};
