import { SvgIcon, SvgIconProps } from "@mui/material";

export const DocumentSuccess = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ width: 80, height: 81, ...props.sx }} viewBox="0 0 80 81">
      <g clipPath="url(#clip0_2939_171917)">
        <path
          d="M8 0.5H72C76.4 0.5 80 4.1 80 8.5V72.5C80 76.9 76.4 80.5 72 80.5H8C3.6 80.5 0 76.9 0 72.5V8.5C0 4.1 3.6 0.5 8 0.5Z"
          fill="white"
        />
        <path
          d="M63.6629 68.7251H15.4629V9.2251H51.3629L63.6629 21.5251V68.7251ZM20.4629 63.7251H58.5629V23.6251L49.2629 14.3251H20.4629V63.7251Z"
          fill="#C7DBE5"
        />
        <path d="M61.163 26.8251H46.563V11.7251H51.563V21.8251H61.163V26.8251Z" fill="#C7DBE5" />
        <path d="M68.5361 48.4996L51 66.0356L54.5355 69.5711L72.0716 52.0351L68.5361 48.4996Z" fill="#00A866" />
        <path d="M47.5355 55.5001L44 59.0356L54.2011 69.2368L57.7366 65.7013L47.5355 55.5001Z" fill="#00A866" />
        <path d="M52.4631 34.6255H26.6631V39.6255H52.4631V34.6255Z" fill="#C7DBE5" />
        <path d="M43 45.5005H27V50.5005H43V45.5005Z" fill="#C7DBE5" />
      </g>
      <defs>
        <clipPath id="clip0_2939_171917">
          <rect width="80" height="80" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export default DocumentSuccess;
