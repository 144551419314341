import { Avatar } from "@mui/material";
import SparklesIcon from "../../../icons/SparklesIcon";

interface Props {
  variant?: "large" | "regular";
}

const AssistantAvatar = ({ variant }: Props) => {
  const avatarSize = variant === "large" ? 56 : 40;
  const imageSize = variant === "large" ? 32 : 24;
  return (
    <Avatar
      sx={(t) => ({
        bgcolor: t.palette.primary.main,
        color: t.palette.common.white,
        width: avatarSize,
        height: avatarSize,
      })}
    >
      <SparklesIcon sx={{ width: imageSize, height: imageSize }} />
    </Avatar>
  );
};

export default AssistantAvatar;
