import { useEffect } from "react";

const useSetInterval = (callback: () => void, delayMs: number) => {
  useEffect(() => {
    const timer = setInterval(callback, delayMs);

    return () => {
      clearInterval(timer);
    };
  }, [callback, delayMs]);
};

export default useSetInterval;
