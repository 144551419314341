import { Box, Paper, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import UpdateAppPopover from "../shared/components/UpdateAppPopover";
import LeftSideRootMenu from "./components/leftSideMenu/LeftSideRootMenu";

const Main = ({ children }: PropsWithChildren) => (
  <Box component="main" display="flex" flexDirection="column" flex={1} minWidth="100%">
    <UpdateAppPopover cooldownInMinutes={5} />
    <Stack direction="row" alignItems="start" width="100%" flexGrow={1}>
      <LeftSideRootMenu />
      <Paper
        elevation={0}
        sx={() => ({
          py: 0,
          pl: 0,
          flexGrow: 1,
          height: "100%",
        })}
      >
        <Box display="flex" flexDirection="column" height="100%">
          {children}
        </Box>
      </Paper>
    </Stack>
  </Box>
);

export default Main;
