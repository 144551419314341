import { WorkflowStatus, WorkflowType } from "../../../../shared/api/changeRequestTypes";
import { AssignedEntityType, ChangeRequestChangeSetType } from "../../../api/types/changeRequestTypes";

const workflowTypeLabels: Record<WorkflowType, string> = {
  InvestorDetailsChangeRequest: "Investor Details",
  InvestorBankAccountChangeRequest: "Bank Details",
};

const changeSetTypeLabels: Record<ChangeRequestChangeSetType, string> = {
  Investor: "Investor Details",
  BankAccount: "Bank Details",
  FundInvestorBankAccountAssignment: "Fund Assignment",
};

const assignedEntityTypesLabels: Record<AssignedEntityType, string> = {
  FundInvestor: "Fund Assignments",
};

export const formatWorkflowType = (workflowType: WorkflowType): string =>
  workflowTypeLabels[workflowType] ?? workflowType;

export const formatChangeSetType = (changeSetType: ChangeRequestChangeSetType): string =>
  changeSetTypeLabels[changeSetType] ?? changeSetType;

export const formatAssignedEntityType = (assignedEntityType: AssignedEntityType): string =>
  assignedEntityTypesLabels[assignedEntityType] ?? assignedEntityType;

export const hasApprovalInfo = (workflowStatus: WorkflowStatus) => ["Completed", "Rejected"].includes(workflowStatus);

export const isChangeTypeWithNewValues = (changeSetType: ChangeRequestChangeSetType) => changeSetType === "BankAccount";
