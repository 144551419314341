import { Box, InputBase, InputBaseProps, Paper, Popper } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import { FocusEvent, useCallback, useState } from "react";
import { InvoiceDetailsLine } from "../invoiceDetailsState";

interface Props {
  params: GridRenderEditCellParams<InvoiceDetailsLine, string>;
}

const InvoiceLineEditTextarea = ({ params }: Props) => {
  const { id, field, value, colDef } = params;
  const [valueState, setValueState] = useState(value);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const apiRef = useGridApiContext();

  const handleRef = useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = useCallback<NonNullable<InputBaseProps["onChange"]>>(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 300 }, event);
    },
    [apiRef, field, id]
  );

  return (
    <Box sx={{ position: "relative", alignSelf: "flex-start" }}>
      <Box
        ref={handleRef}
        sx={{
          height: 1,
          width: colDef.computedWidth,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth, px: 1.75 }}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{ textarea: { resize: "both" }, width: "100%" }}
              onChange={handleChange}
              inputProps={{
                onFocus: (event: FocusEvent<HTMLInputElement>) => event.target.select(),
              }}
              autoFocus
            />
          </Paper>
        </Popper>
      )}
    </Box>
  );
};

export default InvoiceLineEditTextarea;
