import { useState } from "react";
import OperationConfirmationPopup, { ConfirmationPopupData } from "../components/common/OperationConfirmationPopup";

const useConfirmationPopUp = () => {
  const [confirmationPopupData, setConfirmationPopupData] = useState<ConfirmationPopupData | undefined>();
  const isOpen = confirmationPopupData !== undefined;

  return {
    isOpenConfirmationPopup: isOpen,
    closeConfirmationDialog: () => setConfirmationPopupData(undefined),
    setConfirmationPopupData,
    ConfirmationPopupComponent: <OperationConfirmationPopup data={confirmationPopupData} />,
  };
};

export default useConfirmationPopUp;
