import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import BadgeDetached from "../../../../../../shared/components/BadgeDetached";

export type DocumentsFilterValue = "All" | "Viewed" | "NotViewed";

interface Props {
  allCount: number;
  viewedCount: number;
  notViewedCount: number;
  value: DocumentsFilterValue;
  onChange: (value: DocumentsFilterValue) => void;
}

const DocumentsFilter = ({ allCount, viewedCount, notViewedCount, value, onChange }: Props) => {
  const handleChange = (_: React.MouseEvent<HTMLElement>, value: DocumentsFilterValue) => {
    onChange(value);
  };

  return (
    <ToggleButtonGroup value={value} exclusive onChange={handleChange} sx={{ height: "2.25rem" }}>
      <ToggleButton value="All" sx={{ gap: 1 }}>
        All <BadgeDetached badgeContent={allCount.toLocaleString()} color="secondary" />
      </ToggleButton>
      <ToggleButton value="Viewed" sx={{ gap: 1 }}>
        Viewed <BadgeDetached badgeContent={viewedCount.toLocaleString()} color="secondary" />
      </ToggleButton>
      <ToggleButton value="NotViewed" sx={{ gap: 1 }}>
        Not Viewed <BadgeDetached badgeContent={notViewedCount.toLocaleString()} color="secondary" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default DocumentsFilter;
