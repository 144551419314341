import { EntityFieldConfiguration, EntityFieldType } from "../../../../../api/types/objectTypes";

export const entityFieldTypesForUdf: readonly EntityFieldType[] = [
  "Text",
  "Email",
  "Phone",
  "Url",
  "Number",
  "Money",
  "Percent",
  "Date",
  "Checkbox",
  "UserDefinedOptionsSelect",
  "UserDefinedOptionsMultiSelect",
  "Lookup",
] as const;

export const getDefaultFieldConfigForUdf = (fieldType: EntityFieldType): EntityFieldConfiguration | undefined => {
  switch (fieldType) {
    case "Text": {
      return {
        $type: "Text",
      };
    }
    case "Email": {
      return {
        $type: "Text",
        maxLength: 250,
      };
    }
    case "Phone": {
      return {
        $type: "Text",
        maxLength: 250,
      };
    }
    case "Url": {
      return {
        $type: "Text",
        maxLength: 250,
      };
    }
    case "Number": {
      return {
        $type: "Number",
        precision: 0,
      };
    }
    case "Money": {
      return {
        $type: "Money",
        currencyCode: "USD",
        precision: 2,
      };
    }
    case "Percent": {
      return {
        $type: "Percent",
        precision: 1,
      };
    }
    case "UserDefinedOptionsSelect": {
      return {
        $type: "UserDefinedOptionsSelect",
        userDefinedOptions: [],
        allowNewOptions: true,
      };
    }
    case "UserDefinedOptionsMultiSelect": {
      return {
        $type: "UserDefinedOptionsSelect",
        userDefinedOptions: [],
        allowNewOptions: true,
      };
    }
    case "Date": {
      return {
        $type: "Date",
      };
    }
    case "Lookup": {
      return {
        $type: "Lookup",
        objectType: "",
      };
    }
    default: {
      return undefined;
    }
  }
};

export const isObjectTypeAllowedAsLookupTarget = (objectType: string): boolean =>
  !["Fund", "Investor", "Contact"].includes(objectType);
