import { SvgIcon, SvgIconProps } from "@mui/material";

const DwhShareIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 62 56" {...props}>
    <path d="M33.537 14.2744H28.3965V28.4624H33.537V14.2744Z" fill="#C7DBE5" />
    <path
      d="M54.7159 24.1445H7.11426V43.1646H28.499V47.2771V53.5485H33.6395V47.2771V43.1646H54.8187V24.1445H54.7159ZM49.5753 38.024H12.2548V29.2851H49.5753V38.024Z"
      fill="#C7DBE5"
    />
    <path
      d="M45.3944 34.907C46.0411 33.7076 45.5929 32.211 44.3934 31.5644C43.1939 30.9177 41.6973 31.3659 41.0507 32.5654C40.4041 33.7649 40.8523 35.2615 42.0518 35.9081C43.2512 36.5547 44.7478 36.1065 45.3944 34.907Z"
      fill="#C7DBE5"
    />
    <path
      d="M54.7158 19.4151H7.11426V0.39502H54.7158V19.4151ZM12.2548 14.2745H49.5753V5.53558H12.2548V14.2745Z"
      fill="#C7DBE5"
    />
    <path
      d="M45.4069 11.1517C46.0535 9.95219 45.6053 8.45562 44.4058 7.809C43.2063 7.16237 41.7098 7.61056 41.0632 8.81004C40.4165 10.0095 40.8647 11.5061 42.0642 12.1527C43.2637 12.7994 44.7603 12.3512 45.4069 11.1517Z"
      fill="#C7DBE5"
    />
    <path d="M61.1933 50.4644H0.740234V55.6049H61.1933V50.4644Z" fill="#C7DBE5" />
  </SvgIcon>
);

export default DwhShareIcon;
