import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Box, Stack, Tooltip } from "@mui/material";
import { green, red } from "@mui/material/colors";
import HorizontalFill from "../../../../../../../shared/components/HorizontalFill";
import TypographyTooltipEllipsis from "../../../../../../../shared/components/TypographyTooltipEllipsis";
import { ImportFieldDefinition } from "../../../../../../api/types/dataImportTypes";
import { DataImportChangeRowModel } from "./dataImportChangesGridTypes";

interface Props {
  row: DataImportChangeRowModel;
  importFieldDefinition: ImportFieldDefinition;
  highlightChanges: boolean;
}

const DataImportCsvValueGridCell = ({ row, importFieldDefinition, highlightChanges }: Props) => {
  const change = row.fieldValueChanges.find(({ key }) => key === importFieldDefinition.name);
  if (!change) {
    return null;
  }

  const { oldValue, newValue, validationError } = change;

  // We do not really use the type and configuration: values come as text from CSV
  // type={importFieldDefinition.type}
  // configuration={importFieldDefinition.configuration}
  const oldValueString = oldValue === undefined || oldValue === null ? "" : oldValue.toString();
  const newValueString = newValue === undefined || newValue === null ? "" : newValue.toString();

  if (validationError) {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        {newValue ? <TypographyTooltipEllipsis text={newValueString} /> : <HorizontalFill />}
        <Tooltip title={validationError}>
          <ErrorIcon color="error" />
        </Tooltip>
      </Box>
    );
  }

  if (highlightChanges && oldValueString !== newValueString) {
    return (
      <Stack direction="row" spacing={0.5}>
        <TypographyTooltipEllipsis
          text={oldValueString}
          typographyProps={{ bgcolor: red[50], sx: { textDecoration: "line-through" } }}
        />
        <TypographyTooltipEllipsis text={newValueString} typographyProps={{ bgcolor: green[50] }} />
      </Stack>
    );
  }

  if (!newValueString) {
    return null;
  }

  return <TypographyTooltipEllipsis text={newValueString} />;
};

export default DataImportCsvValueGridCell;
