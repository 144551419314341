import { Button, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { ApiGatewaySyncLogs } from "../../../../api/adminApi";
import LogDetailsDialog from "../../../common/syncLogs/LogDetailsDialog";
import LogStatusIcon from "../../../common/syncLogs/LogStatusIcon";
import { LogsTableRowData, getRows } from "./logsTableDataProvider";
import LogsTableWrapper from "../../../common/syncLogs/LogsTableWrapper";

interface Props {
  logItems: ApiGatewaySyncLogs[];
}

interface LogsTableRowProps extends LogsTableRowData {
  onView: (details: ApiGatewaySyncLogs) => void;
}

const LogsTableHeadRow = () => (
  <TableRow>
    <TableCell>Status</TableCell>
    <TableCell>Completed</TableCell>
    <TableCell>Description</TableCell>
    <TableCell align="right">Changes</TableCell>
    <TableCell></TableCell>
  </TableRow>
);

const LogsTableRow = ({ severity, completed, description, numberOfChanges, details, onView }: LogsTableRowProps) => {
  const handleViewClick = () => onView(details);

  return (
    <TableRow hover>
      <TableCell width="10%">
        <LogStatusIcon severity={severity} />
      </TableCell>
      <TableCell>{completed}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell align="right" width="15%">
        {numberOfChanges}
      </TableCell>
      <TableCell align="right" className="LogsTable-actions" width="15%" padding="checkbox">
        <Button variant="text" color="primary" onClick={handleViewClick}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

const LogsTable = ({ logItems }: Props) => {
  const [logDetails, setLogDetails] = useState<ApiGatewaySyncLogs>();
  const [showLogDetails, setShowLogDetails] = useState(false);

  const handleViewDetails = (logDetails: ApiGatewaySyncLogs) => {
    setLogDetails(logDetails);
    setShowLogDetails(true);
  };

  const handleLogDetailsClose = () => setShowLogDetails(false);

  const rows = getRows(logItems);

  return (
    <>
      <LogsTableWrapper maxHeight="calc(100vh - 400px)">
        <TableHead>
          <LogsTableHeadRow />
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <LogsTableRow {...row} onView={handleViewDetails} key={row.key} />
          ))}
        </TableBody>
      </LogsTableWrapper>
      <LogDetailsDialog open={showLogDetails} onClose={handleLogDetailsClose} logDetails={logDetails} />
    </>
  );
};

export default LogsTable;
