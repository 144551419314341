import { Stack } from "@mui/material";
import { useClientContext } from "../../../../context/ClientContext";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import { MembersContextProvider } from "./MembersContext";
import InviteMembersContainer from "./invite-members/InviteMembersContainer";
import { InviteMembersContextProvider } from "./invite-members/InviteMembersContext";
import MembersGridToolbar from "./members-list/MembersGridToolbar";
import MembersListContainer from "./members-list/MembersListContainer";
import MembersGridActionsBar from "./members-list/filters/MembersGridActionsBar";

const MembersSettingsPage = () => {
  const { hasAnyPermission, isFundAdmin } = useClientContext();
  const hasAccessToManageOrganizationUsers = hasAnyPermission(["ManageOrganizationUsers"]) && isFundAdmin;

  return (
    <MembersContextProvider
      hasAccessToManageOrganizationUsers={hasAccessToManageOrganizationUsers}
      hasAccessToManageOrganizationOwners={hasAccessToManageOrganizationUsers}
    >
      <GeneralPageHeader title="Members">
        <MembersGridActionsBar />
      </GeneralPageHeader>
      <Stack spacing={2} sx={{ height: "100%", width: "100%", px: 3, pb: 1, pt: 2 }}>
        <MembersGridToolbar />
        <MembersListContainer />
        <InviteMembersContextProvider>
          <InviteMembersContainer />
        </InviteMembersContextProvider>
      </Stack>
    </MembersContextProvider>
  );
};

export default MembersSettingsPage;
