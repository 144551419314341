import { setDefaultOptions } from "date-fns";
import { de, enCA, enGB, enUS, fr, Locale } from "date-fns/locale";

const supportedLocales = [enUS, enGB, enCA, de, fr];
const defaultLocale = enUS;

export const applyDateFnsLocaleFromBrowser = (): Locale => {
  const { language } = window.navigator;
  const locale =
    supportedLocales.find((locale) => locale.code === language) ??
    supportedLocales.find((locale) => locale.code === language.split("-")[0]) ??
    defaultLocale;

  setDefaultOptions({ locale });
  return locale;
};
