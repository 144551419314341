import { FundStructureDocument } from "../../../../../api/adminApi";
import { FundStructureDocumentsFilterValue } from "../../common/fundStructureDocumentsState";

const matchesSearch = (contactDocument: FundStructureDocument, searchTerm: string) =>
  contactDocument.documentCollectionName.toLowerCase().includes(searchTerm) ||
  contactDocument.name.toLowerCase().includes(searchTerm);

// Filtering by fund and investor names since contact document does not contain IDs
const matchesFilters = (contactDocument: FundStructureDocument, filter: FundStructureDocumentsFilterValue) =>
  matchesSearch(contactDocument, filter.searchTerm.trim().toLowerCase()) &&
  (filter.fundIds.length === 0 || filter.fundIds.some((fundName) => contactDocument.fundNames.includes(fundName))) &&
  (filter.fundInvestorIds.length === 0 || filter.fundInvestorIds.includes(contactDocument.investorName)) &&
  (filter.categoryIds.length === 0 || filter.categoryIds.includes(contactDocument.categoryId)) &&
  (filter.statuses.length === 0 || filter.statuses.includes(contactDocument.status));

export const filterContactDocuments = (
  allContactDocuments: FundStructureDocument[],
  filter: FundStructureDocumentsFilterValue
): FundStructureDocument[] => allContactDocuments.filter((doc) => matchesFilters(doc, filter));
