import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export type SeverityFilter = "All" | "WarningsOnly" | "ErrorsOnly";

interface Props {
  value: SeverityFilter;
  onChange: (severity: SeverityFilter) => void;
}

const LogsSeverityFilter = ({ value, onChange }: Props) => {
  const handleChange = (_: React.MouseEvent<HTMLElement>, value: SeverityFilter) => {
    onChange(value);
  };

  return (
    <ToggleButtonGroup color="primary" value={value} exclusive onChange={handleChange}>
      <ToggleButton value="All">All</ToggleButton>
      <ToggleButton value="WarningsOnly">Alert</ToggleButton>
      <ToggleButton value="ErrorsOnly">Failed</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default LogsSeverityFilter;
