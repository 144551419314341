import CalendarIcon from "@mui/icons-material/CalendarTodayOutlined";
import {
  Divider,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { useState } from "react";
import DateRangeSelector from "../../../shared/components/dateRangeSelector/DateRangeSelector";
import { DescriptiveDate } from "../../../shared/utilities/dateFilterHelper";

interface Props {
  predefinedRanges: DescriptiveDate[];
  customRangeTitle: string;
  dateRange: DescriptiveDate | undefined;
  onChange: (dateRange: DescriptiveDate) => void;
  disabled?: boolean;
}

const DateRangeSelect = ({ predefinedRanges, customRangeTitle, dateRange, onChange, disabled }: Props) => {
  const [customRangeRefEl, setCustomRangeRefEl] = useState<Element | null>(null);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [selectedCustomRange, setSelectedCustomRange] = useState<DescriptiveDate>();

  const handleChange = ({ target }: SelectChangeEvent) => {
    const dateRange = predefinedRanges.find((d) => d.title === target.value);
    if (dateRange) {
      setSelectedCustomRange(undefined);
      onChange(dateRange);
    }
  };

  const handleCustomPeriodClick = () => {
    setShowCustomRange(true);
  };

  const handleApplyCustomRange = ([from, to]: [Date, Date]) => {
    const custom = {
      title: customRangeTitle,
      description: `${format(from, "MMM dd")}-${format(to, "MMM dd")}`,
      from: startOfDay(from).toISOString(),
      to: endOfDay(to).toISOString(),
    };

    setSelectedCustomRange(custom);
    onChange(custom);
    setShowCustomRange(false);
  };

  return (
    <>
      <Select
        disabled={disabled}
        ref={(r: Element) => setCustomRangeRefEl(r)}
        value={dateRange?.title ?? ""}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <CalendarIcon color="secondary" />
          </InputAdornment>
        }
        displayEmpty
        sx={(theme) => ({ width: theme.spacing(24), ".MuiInputBase-input": { color: theme.palette.secondary.main } })}
        renderValue={(rangeTitle) => {
          if (!rangeTitle) {
            return "Date";
          }

          const selectedRange = predefinedRanges.find((d) => d.title === rangeTitle) ?? selectedCustomRange;
          if (selectedRange) {
            return selectedRange.description ?? rangeTitle;
          }

          return rangeTitle;
        }}
      >
        {predefinedRanges.map((dateRange) => (
          <MenuItem key={dateRange.title} value={dateRange.title}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="16rem">
              <Typography>{dateRange.title}</Typography>
              <Typography color="text.secondary">{dateRange.description}</Typography>
            </Stack>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem key={customRangeTitle} value={customRangeTitle} onClick={handleCustomPeriodClick}>
          <Typography>{customRangeTitle}</Typography>
        </MenuItem>
      </Select>
      <Popover
        open={showCustomRange}
        anchorEl={customRangeRefEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <DateRangeSelector
          from={dateRange?.from ? parseISO(dateRange.from) : new Date()}
          to={dateRange?.to ? parseISO(dateRange.to) : new Date()}
          onApply={handleApplyCustomRange}
          onCancel={() => setShowCustomRange(false)}
        />
      </Popover>
    </>
  );
};

export default DateRangeSelect;
