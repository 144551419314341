import { logError } from "../../../../shared/logging";
import { EntityFieldComponentProps, EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { useFieldValuesContext } from "../FieldValuesContext";
import { getInvalidFieldValueError, getLookupDisplayValue, getSelectOptionsForLookup } from "../helpers";
import ObjectPageLink from "../ObjectPageLink";
import EntityFieldSelectComponent from "./EntityFieldSelectComponent";

interface EntityLookupFieldComponentProps extends EntityFieldComponentProps<string> {
  targetObjectType: string;
}

const EntityLookupFieldComponent = ({
  targetObjectType,
  value,
  fieldId,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityLookupFieldComponentProps) => {
  const { lookupObjects, lookupOptionsByObjectType } = useFieldValuesContext();

  const availableLookupReferences = lookupOptionsByObjectType[targetObjectType] ?? [];
  const objectLookupReferences = lookupObjects[fieldId] ?? [];

  const options = getSelectOptionsForLookup(availableLookupReferences);

  const selectedOptionLabel = getLookupDisplayValue(value, availableLookupReferences, objectLookupReferences);

  return (
    <EntityFieldSelectComponent
      fieldId={fieldId}
      value={value}
      options={options}
      fieldAttributes={fieldAttributes}
      onChange={(value, validationResult) => onChange(value, fieldId, validationResult)}
      fieldInputId={fieldInputId}
      displayValueComponent={
        value && selectedOptionLabel ? (
          <ObjectPageLink objectType={targetObjectType} objectId={value}>
            {selectedOptionLabel}
          </ObjectPageLink>
        ) : null
      }
    />
  );
};

const EntityLookupField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityLookupField]");
    return null;
  }

  if (fieldConfiguration?.$type !== "Lookup") {
    const error = `Invalid configuration for Lookup field: ${fieldConfiguration?.$type}`;
    logError(error, "EntityLookupField");
    return null;
  }

  return (
    <EntityLookupFieldComponent
      targetObjectType={fieldConfiguration.objectType}
      value={value}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={onChange}
      fieldInputId={fieldInputId}
    />
  );
};

export default EntityLookupField;
