import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { convertISODateTimeWithSeconds } from "../../../../shared/utilities/dateUtils";
import { ApplicationLogs, LogRecord } from "../../../api/types/logTypes";
import KeyValueColumn from "../KeyValueColumn";
import LogStatusIcon from "./LogStatusIcon";

interface Props {
  logDetails: ApplicationLogs;
  hideSeverityColumn?: boolean;
}

interface HeaderProps {
  startTime: string;
  endTime: string;
}

const Header = ({ startTime, endTime }: HeaderProps) => (
  <Stack direction="row" spacing={4} mb={3}>
    <KeyValueColumn title="Start time" value={convertISODateTimeWithSeconds(startTime)} />
    <KeyValueColumn title="End time" value={convertISODateTimeWithSeconds(endTime)} />
  </Stack>
);

const DetailsTableHeadRow = ({ hideSeverityColumn }: { hideSeverityColumn?: boolean }) => (
  <TableRow>
    {!hideSeverityColumn && <TableCell>Status</TableCell>}
    <TableCell>Description</TableCell>
    <TableCell align="right">Time</TableCell>
  </TableRow>
);

const DetailsTableRow = ({ logRecord, hideSeverityColumn }: { logRecord: LogRecord; hideSeverityColumn?: boolean }) => (
  <TableRow>
    {!hideSeverityColumn && (
      <TableCell width={80}>
        <LogStatusIcon severity={logRecord.severity} />
      </TableCell>
    )}
    <TableCell width={480}>{logRecord.message}</TableCell>
    <TableCell width={240} align="right">
      <Typography color="text.secondary">{convertISODateTimeWithSeconds(logRecord.time)}</Typography>
    </TableCell>
  </TableRow>
);

const LogDetails = ({ logDetails, hideSeverityColumn }: Props) => (
  <>
    <Header startTime={logDetails.startTime} endTime={logDetails.endTime} />
    <Table>
      <TableHead>
        <DetailsTableHeadRow hideSeverityColumn={hideSeverityColumn} />
      </TableHead>
      <TableBody>
        {logDetails.records.map((record, i) => (
          <DetailsTableRow key={`${record.time}_${i}`} logRecord={record} hideSeverityColumn={hideSeverityColumn} />
        ))}
      </TableBody>
    </Table>
  </>
);

export default LogDetails;
