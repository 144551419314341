import { stringComparerBy } from "../../../../shared/utilities/arrayHelper";
import { ObjectClassDefinitionInfo } from "../../../api/types/objectTypes";
import CubeIcon from "../../../icons/CubeIcon";
import VerticalNavigationMenuItem from "../../common/navigation/VerticalNavigationMenuItem";
import VerticalNavigationSection from "../../common/navigation/VerticalNavigationSection";
import VerticalNavigationWrapper from "../../common/navigation/VerticalNavigationWrapper";

interface Props {
  selectedObjectType: string | undefined;
  onSelectedObjectTypeChange: (objectType: string) => void;
  objectDefinitions: ObjectClassDefinitionInfo[];
}

const ObjectsPageNavigation = ({ selectedObjectType, onSelectedObjectTypeChange, objectDefinitions }: Props) => {
  return (
    <VerticalNavigationWrapper>
      <VerticalNavigationSection title="Record Types">
        {objectDefinitions.sort(stringComparerBy((d) => d.objectName)).map((objectClassDefinition) => {
          const isItemSelected = selectedObjectType === objectClassDefinition.objectType;
          return (
            <VerticalNavigationMenuItem
              key={objectClassDefinition.objectType}
              text={objectClassDefinition.objectName}
              Icon={<CubeIcon color={isItemSelected ? "primary" : "secondary"} />}
              selected={isItemSelected}
              onClick={() => onSelectedObjectTypeChange(objectClassDefinition.objectType)}
            />
          );
        })}
      </VerticalNavigationSection>
    </VerticalNavigationWrapper>
  );
};

export default ObjectsPageNavigation;
