import { SvgIcon, SvgIconProps } from "@mui/material";

const DocumentIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 80 80" {...props}>
    <path
      d="M63.6629 68.225H15.4629V8.72498H51.3629L63.6629 21.025V68.225ZM20.4629 63.225H58.5629V23.125L49.2629 13.825H20.4629V63.225Z"
      fill="#C7DBE5"
    />
    <path d="M61.163 26.325H46.563V11.225H51.563V21.325H61.163V26.325Z" fill="#C7DBE5" />
    <path d="M52.4631 34.1254H26.6631V39.1254H52.4631V34.1254Z" fill="#C7DBE5" />
    <path d="M43 45.0004H27V50.0004H43V45.0004Z" fill="#C7DBE5" />
  </SvgIcon>
);

export default DocumentIcon;
