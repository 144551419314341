import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import ConsentAgreementLabel from "../../../../../shared/components/consentAgreement/ConsentAgreementLabel";
import ConsentDialogBody from "../../../../../shared/components/consentAgreement/ConsentDialogBody";
import ConsentDialogContent from "../../../../../shared/components/consentAgreement/ConsentDialogContent";
import { useLocalization } from "../../../../hooks/useLocalization";

interface Props {
  open: boolean;
  logoUrl: string | undefined;
  clientTitle: string;
  consentName: string;
  consentContent: string;
  onClose: () => void;
}

const ConsentPreviewDialog = ({ open, logoUrl, consentName, consentContent, onClose }: Props) => {
  const { consent_agreement: pageLocale } = useLocalization();
  const theme = useTheme();
  const borderLineStyle = `1px ${theme.palette.divider} solid`;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      scroll="paper"
      PaperProps={{
        sx: {
          maxWidth: theme.spacing(78.5),
        },
      }}
      fullWidth={fullScreen}
    >
      <DialogTitle sx={{ py: 2, px: 3, borderBottom: borderLineStyle }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={1}>
            <Typography variant="h6">Preview</Typography>
            <Typography>Example of how the consent would look like for a portal user. </Typography>
          </Stack>
          <CloseIconButton onClick={onClose} />
        </Stack>
      </DialogTitle>

      <ConsentDialogContent
        sx={{
          border: borderLineStyle,
          borderRadius: 2,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.25)",
          m: 3,
        }}
      >
        <ConsentDialogBody
          title={`${pageLocale.welcome_to} Investor Portal`}
          showTitle
          logoUrl={logoUrl}
          consentContent={consentContent}
          acceptFormGroup={
            <FormGroup sx={{ my: "1rem" }}>
              <FormControlLabel
                control={<Checkbox color="primary" disabled />}
                label={<ConsentAgreementLabel label={pageLocale.agree_to} consentName={consentName} />}
              />
            </FormGroup>
          }
          saveButton={
            <Button disabled fullWidth variant="contained" sx={{ height: "3rem" }}>
              Continue
            </Button>
          }
        />
      </ConsentDialogContent>

      <DialogActions sx={{ borderTop: borderLineStyle, px: 2, py: 1.5 }}>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentPreviewDialog;
