const MIN_SEARCH_CHAR_LENGTH = 3;

export type Searchable<T> = T & {
  searchTerms: string[];
};

export const applySearch = <T>(
  searchTerm: string,
  items: Searchable<T>[],
  minSearchCharLength = MIN_SEARCH_CHAR_LENGTH
): Searchable<T>[] =>
  searchTerm.length < minSearchCharLength
    ? items
    : items.filter((item) => item.searchTerms.some((term) => term.includes(searchTerm.toLowerCase())));
