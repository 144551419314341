import CircleIcon from "@mui/icons-material/Circle";
import { Box, Theme } from "@mui/material";
import { MessagePublishStatus } from "../../../../../api/types/documentCollectionTypes";

interface Props {
  status: MessagePublishStatus;
}

const getStatusColorAndText = (status: MessagePublishStatus) =>
  status === "Live"
    ? { statusText: "Live", statusColor: (theme: Theme) => theme.palette.success.main }
    : { statusText: "Preview", statusColor: (theme: Theme) => theme.palette.info.main };

const DocumentStatus = ({ status }: Props) => {
  const { statusText, statusColor } = getStatusColorAndText(status);

  return (
    <Box display="flex" alignItems="center">
      <CircleIcon
        sx={(theme) => ({
          color: statusColor(theme),
          mr: theme.spacing(1),
          fontSize: "0.5rem",
        })}
      />
      {statusText}
    </Box>
  );
};

export default DocumentStatus;
