import { createSvgIcon } from "@mui/material";

const VerifiedShieldIcon = createSvgIcon(
  <svg viewBox="0 0 17 16" fill="none">
    <g>
      <path
        d="M7.79996 8.46693L6.86663 7.53359C6.73329 7.40026 6.57774 7.33359 6.39996 7.33359C6.22218 7.33359 6.06663 7.40026 5.93329 7.53359C5.79996 7.66693 5.73329 7.82526 5.73329 8.00859C5.73329 8.19193 5.79996 8.35026 5.93329 8.48359L7.33329 9.90026C7.46663 10.0336 7.62218 10.1003 7.79996 10.1003C7.97774 10.1003 8.13329 10.0336 8.26663 9.90026L11.1 7.06693C11.2333 6.93359 11.3 6.77526 11.3 6.59193C11.3 6.40859 11.2333 6.25026 11.1 6.11693C10.9666 5.98359 10.8083 5.91693 10.625 5.91693C10.4416 5.91693 10.2833 5.98359 10.15 6.11693L7.79996 8.46693ZM8.49996 14.6003C8.42218 14.6003 8.34996 14.5947 8.28329 14.5836C8.21663 14.5725 8.14996 14.5558 8.08329 14.5336C6.58329 14.0336 5.38885 13.1086 4.49996 11.7586C3.61107 10.4086 3.16663 8.95582 3.16663 7.40026V4.25026C3.16663 3.97248 3.24718 3.72248 3.40829 3.50026C3.5694 3.27804 3.77774 3.11693 4.03329 3.01693L8.03329 1.51693C8.18885 1.46137 8.3444 1.43359 8.49996 1.43359C8.65551 1.43359 8.81107 1.46137 8.96663 1.51693L12.9666 3.01693C13.2222 3.11693 13.4305 3.27804 13.5916 3.50026C13.7527 3.72248 13.8333 3.97248 13.8333 4.25026V7.40026C13.8333 8.95582 13.3888 10.4086 12.5 11.7586C11.6111 13.1086 10.4166 14.0336 8.91663 14.5336C8.84996 14.5558 8.78329 14.5725 8.71663 14.5836C8.64996 14.5947 8.57774 14.6003 8.49996 14.6003ZM8.49996 13.2669C9.65551 12.9003 10.6111 12.1669 11.3666 11.0669C12.1222 9.96693 12.5 8.7447 12.5 7.40026V4.25026L8.49996 2.75026L4.49996 4.25026V7.40026C4.49996 8.7447 4.87774 9.96693 5.63329 11.0669C6.38885 12.1669 7.3444 12.9003 8.49996 13.2669Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  "VerifiedShieldIcon"
);

export default VerifiedShieldIcon;
