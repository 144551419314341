import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  position?: "fixed" | "absolute";
  zIndex?: number;
}

const CenteredWrapper = ({ children, position, zIndex }: PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        position: position || "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: zIndex,
      }}
    >
      {children}
    </Box>
  );
};

export default CenteredWrapper;
