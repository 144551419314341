import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { ImportCsvDataPageState } from "./importCsvDataPageState";

interface Props {
  pageState: ImportCsvDataPageState;
  setPageState: Dispatch<SetStateAction<ImportCsvDataPageState>>;
}

type ContextValue = Props;

const ImportCsvDataContext = createContext<ContextValue | undefined>(undefined);

export const ImportCsvDataContextProvider = ({ pageState, setPageState, children }: PropsWithChildren<Props>) => {
  return <ImportCsvDataContext.Provider value={{ pageState, setPageState }}>{children}</ImportCsvDataContext.Provider>;
};

export const useImportCsvDataContext = () =>
  defined(useContext(ImportCsvDataContext), "Attempt to use ImportCsvDataContext without provider");
