import { SvgIconComponent } from "@mui/icons-material";
import { Checkbox, InputAdornment, ListItemText, MenuItem, Select } from "@mui/material";
import TypographyTooltipEllipsis from "../../../shared/components/TypographyTooltipEllipsis";
import { stringComparerBy } from "../../../shared/utilities/arrayHelper";

export interface MultiSelectOption<T extends string> {
  value: T;
  label: string;
}

interface Props<T extends string> {
  placeholder: string;
  options: MultiSelectOption<T>[];
  selectedValues: T[];
  onChange: (selectedValues: T[]) => void;
  StartIcon?: SvgIconComponent;
  sortValues?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  onClose?: () => void;
}

const MultiSelect = <T extends string>({
  placeholder,
  options,
  selectedValues,
  onChange,
  StartIcon,
  sortValues,
  disabled,
  fullWidth,
  onClose,
}: Props<T>) => (
  <Select<T[]>
    disabled={disabled}
    displayEmpty
    multiple
    fullWidth={fullWidth}
    value={selectedValues}
    onClose={onClose}
    startAdornment={
      StartIcon && (
        <InputAdornment position="start">
          <StartIcon color="secondary" />
        </InputAdornment>
      )
    }
    sx={(t) => ({
      width: fullWidth ? undefined : t.spacing(24),
      ".MuiInputBase-input": {
        color: selectedValues.length === 0 ? t.palette.text.secondary : t.palette.text.primary,
      },
    })}
    renderValue={(selected) =>
      selected.length === 0
        ? placeholder
        : options
            .filter(({ value }) => selected.includes(value))
            .map(({ label }) => label)
            .join(", ")
    }
    onChange={({ target }) => {
      const selected = target.value;
      if (Array.isArray(selected)) {
        onChange(selected);
      }
    }}
    MenuProps={{ sx: (t) => ({ maxHeight: t.spacing(55) }) }}
  >
    {(sortValues ? options.sort(stringComparerBy((o) => o.label)) : options).map(({ value, label }) => (
      <MenuItem key={value} value={value} dense>
        <Checkbox checked={selectedValues.includes(value)} />
        <ListItemText
          sx={(theme) => ({ maxWidth: theme.spacing(24) })}
          primary={<TypographyTooltipEllipsis text={label} typographyProps={{ component: "span" }} />}
        />
      </MenuItem>
    ))}
  </Select>
);

export default MultiSelect;
