import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";

interface Props {
  value: number;
  onChange: (value: number) => void;
}

const RowsPerPageSelector = ({ value, onChange }: Props) => {
  const options = [10, 20, 50, 100, 200];

  const handleChange = (e: SelectChangeEvent<number>) => onChange(+e.target.value);

  return (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      <Typography variant="caption" color="text.secondary">
        Rows per page:
      </Typography>
      <Select<number>
        value={value}
        onChange={handleChange}
        disableUnderline
        variant="standard"
        renderValue={(val) => <Typography variant="body2">{val}</Typography>}
      >
        {options.map((val) => (
          <MenuItem key={val.toString()} value={val}>
            {val.toString()}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default RowsPerPageSelector;
