import { Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import objectHash from "object-hash";
import { useCallback, useReducer } from "react";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { FundPermissions } from "../../../../../api/adminApi";
import { Category } from "../../../../../api/types/accessTypes";
import FundStructureDocumentFilters from "../../common/FundStructureDocumentFilters";
import FundStructureDocumentsGrid from "../../common/FundStructureDocumentsGrid";
import {
  FundStructureDocumentsFilterValue,
  getInitialState,
  reducer,
  stateToRequest,
} from "../../common/fundStructureDocumentsState";

interface Props {
  fundId: string;
  categories: Category[];
  fundPermissions: FundPermissions[];
}

const excludeGridColumns = ["fundNames", "views", "downloads", "totalViewTimeSeconds", "totalCompletionPercentage"];

const FundDocumentsTab = ({ fundId, categories, fundPermissions }: Props) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const apiRef = useGridApiRef();

  const fetchFundDocuments = useCallback(
    () => adminApi.searchFundDocuments(fundId, stateToRequest(state.page, state.filter)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fundId, objectHash(state.filter), state.page]
  );

  const [, fetchError, { isFetching }] = useFetch(fetchFundDocuments, (data) => dispatch({ type: "load_data", data }));

  if (fetchError) {
    logError(fetchError.message, "FundDocumentsTab:searchFundDocuments");
    return <DataLoadingFailed bgColor="none" title="Loading fund documents failed" />;
  }

  const handleFiltersChange = (filterValue: FundStructureDocumentsFilterValue) => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    dispatch({ type: "filter", filterValue });
  };

  const handleClearFilters = () => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    dispatch({ type: "clear_filters" });
  };

  const handleGridScrollEnd = () => setTimeout(() => dispatch({ type: "next_page_requested" }), 100);

  const categoryOptions = categories
    .filter((c) => c.type === "Message")
    .map(({ externalId, name }) => ({ value: externalId, label: name }));

  const investorOptions = fundPermissions.map(({ fundInvestorId, investorName }) => ({
    value: fundInvestorId,
    label: investorName,
  }));

  return (
    <Stack spacing={2.5} sx={{ height: "100%" }}>
      <FundStructureDocumentFilters
        categoryOptions={categoryOptions}
        investorOptions={investorOptions}
        filterState={state.filter}
        onChange={handleFiltersChange}
        onClear={handleClearFilters}
        isLoading={isFetching}
        recordsCount={state.documents.length}
        totalRecordsCount={state.totalRecords}
      />
      <FundStructureDocumentsGrid
        documents={state.documents}
        categories={categories}
        excludeColumns={excludeGridColumns}
        onRowsScrollEnd={handleGridScrollEnd}
        isLoading={isFetching}
        apiRef={apiRef}
      />
    </Stack>
  );
};

export default FundDocumentsTab;
