import { PropsWithChildren, useCallback, useState } from "react";
import { useLocalization } from "../../../../hooks/useLocalization";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { useReportTemplatesContext } from "../../../../../shared/reporting/contexts/ReportTemplatesContext";
import { ReportTemplate } from "../../../../../shared/reporting/api/biClient.types";
import { ReportTemplateUiManagerContextProvider } from "./ReportTemplatesUiManagerContext";
import DeleteTemplateDialog from "../../../../../shared/reporting/components/reportTemplates/dialogs/DeleteTemplateDialog";
import biClient from "../../../../../shared/reporting/api/biClient";

interface Props {
  onDeleted?: () => void;
}
export default function ReportTemplatesUiManager({ children, onDeleted }: PropsWithChildren<Props>) {
  const { sendNotification } = useNotificationContext();
  const { report: locale } = useLocalization();
  const { reportTemplates } = useReportTemplatesContext();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [templatesToDelete, setTemplatesToDelete] = useState<ReportTemplate[]>([]);

  const deleteTemplates = useCallback(
    (ids: string[]) => {
      const toDelete = reportTemplates.filter((r) => ids.includes(r.reportId));
      setTemplatesToDelete(toDelete);
      setOpenDeleteDialog(true);
    },
    [reportTemplates]
  );

  return (
    <ReportTemplateUiManagerContextProvider deleteTemplates={deleteTemplates}>
      {children}
      {openDeleteDialog && templatesToDelete.length > 0 && (
        <DeleteTemplateDialog
          templates={templatesToDelete}
          doDelete={(templates) =>
            biClient.deleteReportTemplates({
              templates: templates.map((t) => ({ templateId: t.reportId, organization: t.organization })),
            })
          }
          onDeleted={() => {
            sendNotification(locale.deleted);
            onDeleted?.();
          }}
          onClose={() => {
            setOpenDeleteDialog(false);
            setTemplatesToDelete([]);
          }}
        />
      )}
    </ReportTemplateUiManagerContextProvider>
  );
}
