import { orange } from "@mui/material/colors";
import StatusChip from "../../../../../../../shared/components/StatusChip";
import { DataImportChangeRequest } from "../../../../../../api/types/dataImportTypes";

interface Props {
  changeRequest: DataImportChangeRequest;
}

const DataImportChangeActionTag = ({ changeRequest }: Props) => {
  if (changeRequest.isExcluded) {
    return <StatusChip label="Excluded" color={orange[700]} />;
  }

  if (changeRequest.hasValidationErrors) {
    return <StatusChip label="Error" color={(t) => t.palette.error.main} />;
  }

  switch (changeRequest.action) {
    case "CreateNew": {
      return <StatusChip label="New" color={(t) => t.palette.info.main} />;
    }
    case "UpdateExisting": {
      return <StatusChip label="Updated" color={(t) => t.palette.success.main} />;
    }
    case "DoNothing": {
      return <StatusChip label="Existing" color={(t) => t.palette.text.secondary} />;
    }
    default: {
      return null;
    }
  }
};

export default DataImportChangeActionTag;
