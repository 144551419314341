import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, Link, Stack, Typography } from "@mui/material";
import { useClientContext } from "../../../../../context/ClientContext";
import { getBusinessCentralTransactionUrl } from "../../../../../utilities/navigationHelper";
import InvoiceStatus from "../shared/InvoiceStatus";
import InvoiceDetailsHeaderTitle from "./InvoiceDetailsHeaderTitle";
import { InvoiceBaseData } from "./invoiceDetailsState";

interface Props {
  data: InvoiceBaseData;
  loading: boolean;
  disabledApproveButton?: boolean;
  disabledSaveButton?: boolean;
  hideInvoiceManagementControls?: boolean;
  hideOpenTransaction?: boolean;
  readonly: boolean;
  onBackClick: () => void;
  onApproveClick: () => void;
  onRejectClick: () => void;
  onSaveClick: () => void;
}

const InvoiceDetailsHeader = ({
  onBackClick,
  data,
  onApproveClick,
  onRejectClick,
  onSaveClick,
  loading,
  disabledApproveButton,
  hideInvoiceManagementControls,
  hideOpenTransaction,
  readonly,
  disabledSaveButton,
}: Props) => {
  const {
    settings: { businessCentralSettings },
  } = useClientContext();

  const transactionUrl = getBusinessCentralTransactionUrl(
    businessCentralSettings,
    data.businessCentralTransactionNo,
    data.businessCentralPostedTransactionNo,
    data.transactionTypeCode
  );

  return (
    <Stack direction="row" justifyContent="space-between" py={2}>
      <Stack direction="row" spacing={1}>
        <Stack>
          <IconButton onClick={onBackClick}>
            <ArrowBackRoundedIcon />
          </IconButton>
        </Stack>
        <Stack spacing={0.5}>
          <Stack direction="row" spacing={2} alignItems="center">
            <InvoiceDetailsHeaderTitle vendor={data.vendor} entity={data.entity} dueDate={data.dueDate} />
            {data.status && <InvoiceStatus status={data.status} />}
          </Stack>
          <Typography variant="caption" color="secondary">
            Invoice
          </Typography>
        </Stack>
      </Stack>
      {!hideOpenTransaction && transactionUrl && (
        <Box>
          <Button
            component={Link}
            href={transactionUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="navigate"
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNewRoundedIcon />}
          >
            Open Transaction
          </Button>
        </Box>
      )}
      {!hideInvoiceManagementControls && (
        <Box sx={{ "& > button:not(:last-of-type)": { mr: 1 } }}>
          <Button
            variant="outlined"
            color="success"
            startIcon={<CheckIcon />}
            onClick={onApproveClick}
            disabled={disabledApproveButton || readonly}
          >
            Approve & Create Transaction
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<CloseRoundedIcon />}
            onClick={onRejectClick}
            disabled={readonly}
          >
            Reject
          </Button>
          <LoadingButton
            variant="contained"
            onClick={onSaveClick}
            loading={loading}
            disabled={disabledSaveButton || readonly}
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </Stack>
  );
};

export default InvoiceDetailsHeader;
