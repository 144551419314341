import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { DocumentInfo, InvestorDeliveryInfo } from "../../../../../api/types/documentCollectionTypes";
import { parseRowId } from "./documentsTableDataProvider";

export interface FundLevelSelectedItems {
  selectedDocument?: DocumentInfo;
  selectedInvestor?: InvestorDeliveryInfo;
}

export const getSelectedItemsForFundLevel = (
  selection: GridRowSelectionModel | undefined,
  documents: DocumentInfo[]
): FundLevelSelectedItems => {
  if (selection === undefined) {
    return {};
  }

  const selectedRowId = selection[0];
  if (typeof selectedRowId !== "string") {
    return {};
  }

  const { documentId, investorId } = parseRowId(selectedRowId);

  let selectedDocumentId = documentId;

  if (selectedDocumentId === undefined) {
    return {};
  }

  if (selectedDocumentId.includes("/")) {
    selectedDocumentId = selectedDocumentId.split("/")[1];
  }

  const selectedDocument = selectedDocumentId ? documents.find((doc) => doc.id === selectedDocumentId) : undefined;
  if (selectedDocument === undefined) {
    return {};
  }

  const selectedInvestor = investorId
    ? selectedDocument.investorDeliveries.find((d) => d.investorId === investorId)
    : undefined;

  return {
    selectedDocument,
    selectedInvestor,
  };
};
