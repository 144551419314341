import { Chip, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useState } from "react";
import StatusChip from "../../../../../../shared/components/StatusChip";
import { convertISODate, convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import {
  AccessRole,
  InvitationStatus,
  OrganizationDeactivatedMember,
  OrganizationMember,
  OrganizationMemberInvitation,
} from "../../../../../api/types/userManagementTypes";
import { useMembersContext } from "../MembersContext";
import OrganizationRoleStatusTag from "../OrganizationRoleStatusTag";
import { filterOrganizationRoles, formatClientUserType } from "../definitions";
import ManagedMemberDetails from "../invite-members/ManagedMemberDetails";
import CompaniesListPopover from "./CompaniesListPopover";

interface OrganizationMemberProps {
  member: OrganizationMember;
}

const MemberRoleCell = ({ roles }: { roles: AccessRole[] }) => {
  const organizationRoles = filterOrganizationRoles(roles);
  return (
    <Stack direction="row" alignContent="center" spacing={0.5}>
      {organizationRoles.map((role) => (
        <OrganizationRoleStatusTag key={role} role={role} />
      ))}
    </Stack>
  );
};

const CompanyCell = ({ member }: OrganizationMemberProps) => {
  const [popUpAnchorEl, setPopUpAnchorEl] = useState<HTMLElement | undefined>();
  const { companiesList } = useMembersContext();

  if (member.hasAccessToAllClients) {
    return <Typography>All</Typography>;
  }

  if (member.clientsAccess.length === 0) {
    return <Typography>None</Typography>;
  }

  const filteredCompaniesList = companiesList.filter((company) =>
    member.clientsAccess.some((client) => client.clientCode === company.value)
  );

  const [firstClient] = filteredCompaniesList;
  const restCompanies = filteredCompaniesList.slice(1);

  const toggleShowCompanies = (event: React.MouseEvent<HTMLElement>) => {
    setPopUpAnchorEl(popUpAnchorEl ? undefined : event.currentTarget);
  };

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      {firstClient && <Typography>{firstClient?.label}</Typography>}
      {restCompanies.length > 0 && <Chip onClick={toggleShowCompanies} label={"+" + restCompanies.length} />}
      <CompaniesListPopover
        anchorEl={popUpAnchorEl}
        onClose={() => setPopUpAnchorEl(undefined)}
        companiesWithAccess={restCompanies}
      />
    </Stack>
  );
};

const InvitationStatusCell = ({ status }: { status: InvitationStatus }) => {
  switch (status) {
    case "PendingApproval": {
      return (
        <StatusChip
          label="Pending Approval"
          withDot
          color={(t) => t.palette.warning.main}
          tooltipText="This user is awaiting approval by Entrilia to verify the details. The invitation email will be sent once the review is complete."
        />
      );
    }
    case "Sent": {
      return <StatusChip label="Approved" withDot color={(t) => t.palette.success.main} />;
    }
    default: {
      return null;
    }
  }
};

export const getMembersGridColumns = ({
  currentUserId,
}: {
  currentUserId: string;
}): GridColDef<OrganizationMember>[] => [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1.5,
    renderCell: ({ row }) => (
      <ManagedMemberDetails name={row.name} email={row.email} isCurrentUser={row.userId === currentUserId} />
    ),
  },
  {
    field: "userType",
    headerName: "Type",
    sortable: false,
    flex: 1,
    valueFormatter: (value) => (value ? formatClientUserType(value) : ""),
  },
  {
    field: "organizationPermissions",
    headerName: "Role",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <MemberRoleCell roles={row.organizationPermissions.roles} />,
  },
  {
    field: "clientsAccess",
    headerName: "Companies",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <CompanyCell member={row} />,
  },
  {
    field: "firstAppearanceDate",
    headerName: "First Invited",
    sortable: false,
    width: 120,
    valueFormatter: (value) => (value ? convertISODate(value) : ""),
  },
  {
    field: "lastActivity",
    headerName: "Last Activity",
    sortable: false,
    width: 120,
    valueFormatter: (value) => (value ? convertISODate(value) : ""),
  },
];

export const getDeactivatedMembersGridColumns = (): GridColDef<OrganizationDeactivatedMember>[] => [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1.5,
    renderCell: ({ row }) => <ManagedMemberDetails name={row.name} email={row.email} />,
  },
  {
    field: "disabledAt",
    headerName: "Deactivated at",
    sortable: false,
    flex: 1,
    valueFormatter: (value) => (value ? convertISODateTime(value) : ""),
  },
];

export const getPendingInvitesGridColumns = (): GridColDef<OrganizationMemberInvitation>[] => [
  {
    field: "email",
    headerName: "Email",
    sortable: false,
    flex: 1.5,
    renderCell: ({ row }) => row.email,
  },
  {
    field: "isGuest",
    headerName: "Type",
    sortable: false,
    flex: 1,
    valueFormatter: (value) => (value ? "External" : "Internal"),
  },
  {
    field: "organizationRole",
    headerName: "Role",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <MemberRoleCell roles={[row.organizationRole]} />,
  },
  {
    field: "invitedAt",
    headerName: "Invited at",
    sortable: false,
    flex: 1,
    valueFormatter: (value) => (value ? convertISODateTime(value) : ""),
  },
  {
    field: "invitationStatus",
    headerName: "Status",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <InvitationStatusCell status={row.invitationStatus} />,
  },
];
