import { SvgIcon, SvgIconProps } from "@mui/material";

const DashedArrowIconRight = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 76 18" {...props}>
    <path
      d="M75.06 9L67.56 4.66987V13.3301L75.06 9ZM0.393311 9.75H2.46738V8.25H0.393311V9.75ZM6.61553 9.75H10.7637V8.25H6.61553V9.75ZM14.9118 9.75H19.06V8.25H14.9118V9.75ZM23.2081 9.75H27.3563V8.25H23.2081V9.75ZM31.5044 9.75H35.6526V8.25H31.5044V9.75ZM39.8007 9.75H43.9489V8.25H39.8007V9.75ZM48.097 9.75H52.2452V8.25H48.097V9.75ZM56.3933 9.75H60.5415V8.25H56.3933V9.75ZM64.6896 9.75H68.8378V8.25H64.6896V9.75ZM75.06 9L60.06 0.339746V17.6603L75.06 9ZM0.393311 10.5H2.46738V7.5H0.393311V10.5ZM6.61553 10.5H10.7637V7.5H6.61553V10.5ZM14.9118 10.5H19.06V7.5H14.9118V10.5ZM23.2081 10.5H27.3563V7.5H23.2081V10.5ZM31.5044 10.5H35.6526V7.5H31.5044V10.5ZM39.8007 10.5H43.9489V7.5H39.8007V10.5ZM48.097 10.5H52.2452V7.5H48.097V10.5ZM56.3933 10.5H60.5415V7.5H56.3933V10.5ZM64.6896 10.5H68.8378V7.5H64.6896V10.5Z"
      fill="#00A866"
    />
  </SvgIcon>
);
export default DashedArrowIconRight;
