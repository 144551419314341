import { createSvgIcon } from "@mui/material";

const VisibleIcon = createSvgIcon(
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none">
    <path
      d="M9 10.3333C10.0417 10.3333 10.9271 9.96867 11.6562 9.2395C12.3854 8.51033 12.75 7.62492 12.75 6.58325C12.75 5.54158 12.3854 4.65617 11.6562 3.927C10.9271 3.19784 10.0417 2.83325 9 2.83325C7.95833 2.83325 7.07292 3.19784 6.34375 3.927C5.61458 4.65617 5.25 5.54158 5.25 6.58325C5.25 7.62492 5.61458 8.51033 6.34375 9.2395C7.07292 9.96867 7.95833 10.3333 9 10.3333ZM9 8.83325C8.375 8.83325 7.84375 8.6145 7.40625 8.177C6.96875 7.7395 6.75 7.20825 6.75 6.58325C6.75 5.95825 6.96875 5.427 7.40625 4.9895C7.84375 4.552 8.375 4.33325 9 4.33325C9.625 4.33325 10.1562 4.552 10.5938 4.9895C11.0312 5.427 11.25 5.95825 11.25 6.58325C11.25 7.20825 11.0312 7.7395 10.5938 8.177C10.1562 8.6145 9.625 8.83325 9 8.83325ZM9 12.8333C7.13889 12.8333 5.44097 12.3333 3.90625 11.3333C2.37153 10.3333 1.15972 9.01381 0.270833 7.37492C0.201389 7.24992 0.149306 7.12145 0.114583 6.9895C0.0798611 6.85756 0.0625 6.72214 0.0625 6.58325C0.0625 6.44436 0.0798611 6.30895 0.114583 6.177C0.149306 6.04506 0.201389 5.91659 0.270833 5.79159C1.15972 4.1527 2.37153 2.83325 3.90625 1.83325C5.44097 0.833252 7.13889 0.333252 9 0.333252C10.8611 0.333252 12.559 0.833252 14.0937 1.83325C15.6285 2.83325 16.8403 4.1527 17.7292 5.79159C17.7986 5.91659 17.8507 6.04506 17.8854 6.177C17.9201 6.30895 17.9375 6.44436 17.9375 6.58325C17.9375 6.72214 17.9201 6.85756 17.8854 6.9895C17.8507 7.12145 17.7986 7.24992 17.7292 7.37492C16.8403 9.01381 15.6285 10.3333 14.0937 11.3333C12.559 12.3333 10.8611 12.8333 9 12.8333ZM9 11.1666C10.5694 11.1666 12.0104 10.7534 13.3229 9.927C14.6354 9.10061 15.6389 7.98603 16.3333 6.58325C15.6389 5.18047 14.6354 4.06589 13.3229 3.2395C12.0104 2.41311 10.5694 1.99992 9 1.99992C7.43056 1.99992 5.98958 2.41311 4.67708 3.2395C3.36458 4.06589 2.36111 5.18047 1.66667 6.58325C2.36111 7.98603 3.36458 9.10061 4.67708 9.927C5.98958 10.7534 7.43056 11.1666 9 11.1666Z"
      fill="currentColor"
    />
  </svg>,
  "VisibleIcon"
);

export default VisibleIcon;
