import StatusChip from "../../../../../shared/components/StatusChip";
import { ObjectClassDefinitionSource } from "../../../../api/types/objectTypes";

interface Props {
  objectSource: ObjectClassDefinitionSource;
}

const ObjectSourceBadge = ({ objectSource }: Props) => {
  switch (objectSource) {
    case "Custom":
      return <StatusChip height="smaller" label="Custom" color={(t) => t.palette.info.main} />;
    case "System":
      return <StatusChip height="smaller" label="Standard" color={(t) => t.palette.text.secondary} />;
    default:
      return null;
  }
};

export default ObjectSourceBadge;
