import {
  SortCompareFn,
  combineComparers,
  priorityComparer,
  stringComparerBy,
} from "../../../../shared/utilities/arrayHelper";
import { isEntriliaClientCode, isEntriliaManagedOrganization } from "../../../../shared/utilities/clientHelper";
import { ClientInfo } from "../../../api/types/clientTypes";

interface OrganizationGroup {
  organization: ClientInfo;
  clients: ClientInfo[];
}

export interface OrganizationCompaniesGroup {
  organization: ClientInfo | undefined;
  companies: ClientInfo[];
}

export type GroupSelectionState = [number, number];

const groupFilteredClients = (organizations: ClientInfo[], companies: ClientInfo[]) => {
  const notManagedClients: ClientInfo[] = [];

  const organizationGroups: OrganizationGroup[] = organizations.map((c) => ({ organization: c, clients: [] }));

  for (const companyClient of companies) {
    if (companyClient.managedByClients.length === 0) {
      notManagedClients.push(companyClient);
      continue;
    }

    for (const organizationCode of companyClient.managedByClients) {
      if (!isEntriliaClientCode(organizationCode) || companyClient.managedByClients.length === 1) {
        const organizationGroup = organizationGroups.find(
          (group) => group.organization.clientCode === organizationCode
        );
        if (organizationGroup) {
          organizationGroup.clients.push(companyClient);
        }
      }
    }
  }

  return {
    organizationGroups,
    notManagedClients,
  };
};

const organizationGroupComparer: SortCompareFn<OrganizationGroup> = combineComparers(
  priorityComparer((g) => isEntriliaClientCode(g.organization.clientCode)),
  priorityComparer((g) => isEntriliaManagedOrganization(g.organization.clientCode)),
  (a, b) => a.organization.title.localeCompare(b.organization.title)
);

export const getSortedOrganizationCompanyGroups = (
  organizations: ClientInfo[],
  companies: ClientInfo[]
): OrganizationCompaniesGroup[] => {
  const { organizationGroups, notManagedClients } = groupFilteredClients(organizations, companies);
  const sortedOrganizationGroups = organizationGroups.sort(organizationGroupComparer);

  const result: OrganizationCompaniesGroup[] = [];
  if (notManagedClients.length > 0) {
    result.push({ organization: undefined, companies: notManagedClients });
  }

  for (const organizationGroup of sortedOrganizationGroups) {
    if (organizationGroup.clients.length > 0) {
      result.push({
        organization: organizationGroup.organization,
        companies: organizationGroup.clients.sort(stringComparerBy((c) => c.title)),
      });
    }
  }

  return result;
};

export const getInitialGroupSelectionState = (): GroupSelectionState => [-1, -1];

export const updateGroupSelectionState = (
  currentState: GroupSelectionState,
  nodeLengths: number[],
  direction: -1 | 1
): GroupSelectionState => {
  const [currentGroupIndex, currentNodeIndex] = currentState;
  const currentGroupLength = nodeLengths[currentGroupIndex];
  if (currentGroupLength === undefined) {
    return direction === 1 ? [0, -1] : currentState;
  }

  const nodeIndex = currentNodeIndex + direction;
  if (nodeIndex < -1) {
    return currentGroupIndex === 0
      ? currentState
      : [currentGroupIndex - 1, (nodeLengths[currentGroupIndex - 1] ?? 0) - 1];
  }

  if (nodeIndex >= currentGroupLength) {
    return currentGroupIndex === nodeLengths.length - 1 ? currentState : [currentGroupIndex + 1, -1];
  }

  return [currentGroupIndex, nodeIndex];
};
