import { Divider, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography } from "@mui/material";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import { useMembersContext } from "../MembersContext";
import { CompanyAccessLevel, CompanyWithAccess } from "../definitions";
import CompanyEntities from "./CompanyEntities";
import CompanyProducts from "./CompanyProducts";
import { useInviteMembersContext } from "./InviteMembersContext";

interface CompanyWithAccessPermissionsProps {
  company: CompanyWithAccess;
  onRemove: (clientCode: string) => void;
}

const CompanyWithAccessPermissions = ({ company, onRemove }: CompanyWithAccessPermissionsProps) => {
  const { companies, companiesList } = useMembersContext();

  const { selectedCompanyAccess, updateSelectedCompanyAccess } = useInviteMembersContext();

  const companyItem = companiesList.find((c) => c.value === company.clientCode);
  const entityLevelAccess = !!companies.find((c) => c.clientCode === company.clientCode)?.entityLevelAccess;

  const onAccessSelected = (accessLevel: CompanyAccessLevel) => () => {
    updateSelectedCompanyAccess(company, accessLevel);
  };

  const isAccessSelected = (accessLevel: CompanyAccessLevel) => {
    return selectedCompanyAccess?.[0].clientCode === company.clientCode && selectedCompanyAccess?.[1] === accessLevel;
  };

  const totalEntitiesInCompany = companies.find((c) => c.clientCode === company.clientCode)?.entities?.length || 0;

  return (
    <Paper variant="outlined" sx={(theme) => ({ backgroundColor: theme.palette.background.grey })}>
      <Stack>
        <Stack
          justifyContent="space-between"
          spacing={1.5}
          alignItems="center"
          direction="row"
          sx={{ pl: 2, pr: 1, py: 1 }}
        >
          <Stack direction="row" spacing={1.5} alignItems="center" flexGrow={1}>
            <img src={companyItem?.iconSrc} alt="" width={24} height={24} />
            <Typography variant="subtitle1">{companyItem?.label}</Typography>
          </Stack>
          <CloseIconButton
            color="secondary"
            sx={{ borderRadius: 2 }}
            onClick={() => onRemove(companyItem?.value || "")}
          />
        </Stack>
        <Divider />
        <List sx={{ py: 0 }}>
          <ListItem onClick={onAccessSelected("Product")} disablePadding>
            <ListItemButton selected={isAccessSelected("Product")}>
              <ListItemText
                primary={<CompanyProducts company={company} />}
                sx={{ py: 0.5 }}
                primaryTypographyProps={{ component: "span" }}
              />
            </ListItemButton>
          </ListItem>
          {entityLevelAccess && (
            <ListItem onClick={onAccessSelected("Entity")} disablePadding>
              <ListItemButton selected={isAccessSelected("Entity")}>
                <ListItemText
                  primary={<CompanyEntities company={company} total={totalEntitiesInCompany} />}
                  sx={{ py: 0.5 }}
                  primaryTypographyProps={{ component: "span" }}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Stack>
    </Paper>
  );
};

export default CompanyWithAccessPermissions;
