import { Menu, PopoverOrigin, SxProps, Theme, ThemeProvider } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";

interface PopupMenuProps {
  anchor: JSX.Element;
  transformOrigin?: PopoverOrigin;
  anchorOrigin?: PopoverOrigin;
  paperProps?: SxProps<Theme> | undefined;
  theme: Theme;
}

const PopupMenu = ({
  anchor,
  children,
  transformOrigin,
  anchorOrigin,
  paperProps,
  theme,
}: PropsWithChildren<PopupMenuProps>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = !!anchorEl;

  return (
    <ThemeProvider theme={theme}>
      {React.cloneElement(anchor, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        PaperProps={{
          elevation: 0,
          sx: paperProps,
        }}
      >
        {children}
      </Menu>
    </ThemeProvider>
  );
};

export default PopupMenu;
