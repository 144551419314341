import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import RevertIcon from "@mui/icons-material/ReplayRounded";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { useDataImportChangesListActionsContext } from "./DataImportChangesListActionsContext";
import { DataImportChangeRowModel } from "./dataImportChangesGridTypes";

interface Props {
  row: DataImportChangeRowModel;
  isInEditMode: boolean;
}

const DataImportChangesListActionsCell = ({ row, isInEditMode }: Props) => {
  const { onExcludeChange, onRevertExcludedChange, onCancelEditing, onApplyChanges } =
    useDataImportChangesListActionsContext();

  const buttonWidth = "4rem";

  if (isInEditMode) {
    return (
      <Stack py={1} direction="row" spacing={0.5} width="100%">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => onCancelEditing(row.id)}
          sx={{ width: buttonWidth, minWidth: buttonWidth }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onApplyChanges(row.id)}
          sx={{ width: buttonWidth, minWidth: buttonWidth }}
        >
          Apply
        </Button>
      </Stack>
    );
  }

  return row.isExcluded ? (
    <Tooltip title="Revert excluded record">
      <IconButton
        color="secondary"
        disabled={!row.action || row.action === "DoNothing"}
        onClick={() => onRevertExcludedChange(row.id)}
      >
        <RevertIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title="Exclude record">
      <IconButton color="error" disabled={row.action === "DoNothing"} onClick={() => onExcludeChange(row.id)}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DataImportChangesListActionsCell;
