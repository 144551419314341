import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { ListItemData } from "../../../../../../../shared/components/inputs/CheckItemsList";
import SearchField from "../../../../../../../shared/components/inputs/SearchField";
import CompaniesIcon from "../../../../../../../shared/icons/CompaniesIcon";
import { getSelectedSx } from "../../definitions";

interface Props {
  availableCompanies: ListItemData[];
  selectedValues: ListItemData[];
  onChange: (values: ListItemData[]) => void;
  disabled?: boolean;
}

const CompaniesFilter = ({ availableCompanies, selectedValues, onChange, disabled }: Props) => {
  const [searchFilter, setSearchFilter] = useState("");

  const maxCompaniesToShowInListWoScroll = 10;
  const showSearch = availableCompanies.length > maxCompaniesToShowInListWoScroll;

  const handleSelectAll = () => {
    if (availableCompanies.length === selectedValues.length) {
      onChange([]);
    } else {
      onChange(availableCompanies);
    }
  };

  return (
    <FormControl variant="outlined">
      <Select
        disabled={disabled}
        onKeyDownCapture={(e) => {
          e.stopPropagation();
        }}
        sx={(theme) => ({
          ...getSelectedSx(theme, selectedValues.length > 0),
          width: theme.spacing(20),
          ".MuiInputBase-input": { color: theme.palette.secondary.main },
        })}
        displayEmpty
        multiple
        value={selectedValues.map((li) => li.value)}
        onChange={(e) => {
          const selected = e.target.value as string[];
          if (selected.includes("")) {
            handleSelectAll();
          } else {
            onChange(availableCompanies.filter((li) => selected.includes(li.value)));
          }
        }}
        startAdornment={
          <InputAdornment position="start">
            <CompaniesIcon color={disabled ? "disabled" : "secondary"} />
          </InputAdornment>
        }
        renderValue={(selected) =>
          selected.length > 0
            ? availableCompanies
                .filter((li) => selected.includes(li.value))
                .map((c) => c.label)
                .join(", ")
            : "Companies"
        }
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          transformOrigin: { vertical: "top", horizontal: "center" },
          PaperProps: {
            sx: {
              maxHeight: (maxCompaniesToShowInListWoScroll + 1) * 50 + 90,
            },
          },
        }}
      >
        {showSearch && (
          <Box sx={{ m: 2 }}>
            <SearchField onSearch={setSearchFilter} />
          </Box>
        )}

        <MenuItem dense onClick={handleSelectAll} value="">
          <Checkbox sx={{ mr: 1 }} checked={availableCompanies.length === selectedValues.length} />
          <ListItemText primary={<Typography>All companies</Typography>} />
        </MenuItem>

        {availableCompanies
          .filter((li) => li.label.toLowerCase().includes(searchFilter.toLowerCase()))
          .map((item) => (
            <MenuItem key={item.value} value={item.value} dense>
              <Checkbox checked={selectedValues.some((i) => i.value === item.value)} />
              {item.iconSrc && (
                <ListItemIcon sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={item.iconSrc} alt="" width={20} height={20} />
                </ListItemIcon>
              )}
              <ListItemText primary={<Typography>{item.label}</Typography>} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CompaniesFilter;
