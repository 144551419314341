import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useObjectDefinitionContext } from "../ObjectDefinitionContext";
import DisplayFieldsSection from "./DisplayFIeldsSection";
import ObjectIdentifierFieldsSection from "./ObjectIdentifierFieldsSection";
import ObjectProductsTable from "./ObjectProductsTable";
import ObjectSupportedFeaturesSection from "./ObjectSupportedFeaturesSection";

interface SectionHeaderProps {
  title: string;
  subtitle?: string;
}

const SectionHeader = ({ title, subtitle }: SectionHeaderProps) => (
  <Box width="100%" py={2}>
    <Typography variant="subtitle1">{title}</Typography>
    {subtitle && <Typography color="text.secondary">{subtitle}</Typography>}
  </Box>
);

const SectionSeparator = () => <Divider sx={{ my: 3 }} />;

const ObjectDetailsGeneral = () => {
  const { objectDefinition } = useObjectDefinitionContext();

  return (
    <Container maxWidth="md" disableGutters>
      <Stack spacing={2.5}>
        <SectionHeader title="General" />
        <TextField disabled variant="outlined" label="Name" value={objectDefinition.objectName} />
        <Stack spacing={2}>
          <FormControlLabel
            disabled
            control={<Checkbox disabled checked={Boolean(objectDefinition.businessCentralType)} />}
            label={<Typography>Linked to a Fund Operations table</Typography>}
          />
          {objectDefinition.businessCentralType && (
            <TextField
              disabled
              select
              variant="outlined"
              label="Table Name"
              value={objectDefinition.businessCentralType}
            >
              <MenuItem value={objectDefinition.businessCentralType}>{objectDefinition.businessCentralType}</MenuItem>
            </TextField>
          )}
        </Stack>
        <Stack>
          <SectionHeader title="Product" subtitle="Products associated with this object type." />
          <ObjectProductsTable products={objectDefinition.productAreas} />
        </Stack>
      </Stack>

      <SectionSeparator />

      <SectionHeader
        title="Supported Features"
        subtitle="Customize the functionalities available for this object type."
      />
      <ObjectSupportedFeaturesSection disabled features={objectDefinition.supportedFeatures} />

      <SectionSeparator />

      <SectionHeader
        title="Unique Identifier"
        subtitle="Current unique identifier for this object type. The unique identifier ensures that each record of the object type can be distinctly recognized."
      />
      <ObjectIdentifierFieldsSection allFields={objectDefinition.fields} />

      <SectionSeparator />

      <SectionHeader
        title="Display Fields"
        subtitle="Fields used to display the object in the system. The primary is used to name each object record, while the secondary adds more context."
      />
      <DisplayFieldsSection
        primaryDisplayFieldId={objectDefinition.displayFields.primaryFieldId}
        secondaryDisplayFieldId={objectDefinition.displayFields.secondaryFieldId}
        allFields={objectDefinition.fields}
      />
    </Container>
  );
};

export default ObjectDetailsGeneral;
