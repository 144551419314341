import ConsentsContainer from "./ConsentsContainer";
import { ConsentsContextProvider } from "./ConsentsContext";

const ConsentsSection = () => {
  return (
    <ConsentsContextProvider>
      <ConsentsContainer />
    </ConsentsContextProvider>
  );
};

export default ConsentsSection;
