import { Grid, Stack } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import { Outlet } from "react-router-dom";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import biClient from "../../../../shared/reporting/api/biClient";
import { GroupsContextProvider } from "../../../../shared/reporting/components/groups/contexts/GroupsContext";
import useGroups from "../../../../shared/reporting/components/groups/hooks/useGroups";
import { ReportTemplatesContextProvider } from "../../../../shared/reporting/contexts/ReportTemplatesContext";
import { useClientContext } from "../../../context/ClientContext";
import { useLocalization } from "../../../hooks/useLocalization";
import { UiReportsContextProvider } from "./reports/ui-reports/contexts/ReportsContext";
import UiReportsManager from "./reports/ui-reports/contexts/ReportsUiManager";
import useReports from "./hooks/useReports";
import useReportTemplates from "./templates/hooks/useReportTemplates";
import { BiApiClientBase, BiApiClientProvider } from "../../../../shared/reporting/contexts/BiApiClientProviderContext";

export default function CompanyUiReportsPage() {
  const { clientCode } = useClientContext();
  const { reports: locale } = useLocalization();
  const { reports, ui: reportsUi, actions: reportsActions } = useReports(biClient.uiReports.getReports, "Ui");
  const { groups, actions: groupActions, ui: groupsUi } = useGroups({ ...biClient.reportGroup });

  const {
    templates,
    actions: reportTemplateActions,
    ui: templatesUi,
  } = useReportTemplates({
    getTemplates: biClient.getCompanyReportTemplates,
  });

  useEffect(() => {
    groupActions.load(clientCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCode, groupActions.load]);

  if (!reportsUi.loaded || !groupsUi.loaded) {
    return (
      <FullSize>
        <InlineLoader text={locale.loading_label} />
      </FullSize>
    );
  }

  if (reportsUi.error !== undefined || groupsUi.error !== undefined) {
    return (
      <FullSize>
        <DataLoadingFailed />
      </FullSize>
    );
  }

  return (
    <Grid container flex={1} height="100%">
      <BiApiClientProvider<BiApiClientBase>
        api={{
          validateName: biClient.uiReports.validateReportName,
          getReportSharing: biClient.uiReports.getReportSharing,
          updateReportSharing: biClient.uiReports.updateReportSharing,
        }}
      >
        <ReportTemplatesContextProvider
          groupsUi={{ loaded: true }}
          actions={reportTemplateActions}
          reportTemplates={templates}
          ui={templatesUi}
        >
          <UiReportsContextProvider reports={reports} ui={reportsUi} actions={reportsActions}>
            <GroupsContextProvider
              groups={groups}
              ui={groupsUi}
              actions={{ ...groupActions, reloadReports: reportsActions.reloadReports }}
            >
              <UiReportsManager
                deleteReport={reportsActions.deleteReports}
                duplicate={reportsActions.duplicateReport}
                reports={reports}
                generateApiReport={reportsActions.generateApiReport}
              >
                <Outlet />
              </UiReportsManager>
            </GroupsContextProvider>
          </UiReportsContextProvider>
        </ReportTemplatesContextProvider>
      </BiApiClientProvider>
    </Grid>
  );
}
function FullSize({ children }: PropsWithChildren) {
  return <Stack sx={{ height: "100%" }}>{children}</Stack>;
}
