import { SvgIcon, SvgIconProps } from "@mui/material";

const DataBackupIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 40, height: 40 }} viewBox="0 0 40 40" {...props}>
      <rect width="40" height="40" rx="8" fill="#F6F8FA" />
      <path d="M21.3978 12.8145H18.9563V19.5531H21.3978V12.8145Z" fill="#C7DBE5" />
      <path
        d="M31.457 17.502H8.8483V26.5357H19.0051V28.4889V31.4676H21.4466V28.4889V26.5357H31.5058V17.502H31.457ZM29.0154 24.0941H11.2898V19.9435H29.0154V24.0941Z"
        fill="#C7DBE5"
      />
      <path
        d="M31.457 15.2564H8.8483V6.22266H31.457V15.2564ZM11.2898 12.8148H29.0154V8.6642H11.2898V12.8148Z"
        fill="#C7DBE5"
      />
      <path
        d="M27.0354 11.3326C27.3425 10.7629 27.1296 10.0521 26.5599 9.74495C25.9902 9.43783 25.2794 9.6507 24.9723 10.2204C24.6652 10.7901 24.878 11.5009 25.4477 11.808C26.0174 12.1152 26.7283 11.9023 27.0354 11.3326Z"
        fill="#C7DBE5"
      />
      <path d="M34.5332 30.0039H5.82071V32.4454H34.5332V30.0039Z" fill="#C7DBE5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3333 21.5547H25.1111V23.7105C25.6333 23.1698 26.2519 22.7219 26.9415 22.3932C28.3305 21.7311 29.913 21.5973 31.3935 22.0169C32.8739 22.4364 34.1509 23.3805 34.9861 24.6729C35.8212 25.9653 36.1572 27.5175 35.9315 29.0396C35.7057 30.5617 34.9335 31.9495 33.7591 32.9438C32.5848 33.9381 31.0886 34.4708 29.5502 34.4425C28.0117 34.4142 26.5362 33.8268 25.3992 32.79C24.2622 31.7531 23.5416 30.3379 23.372 28.8085L25.1072 28.616C25.2301 29.7238 25.7521 30.7489 26.5756 31.4999C27.3991 32.2509 28.4679 32.6764 29.5823 32.6969C30.6967 32.7174 31.7804 32.3315 32.631 31.6113C33.4816 30.8911 34.0409 29.8859 34.2045 28.7834C34.368 27.6809 34.1246 26.5566 33.5197 25.6205C32.9148 24.6844 31.9898 24.0005 30.9174 23.6966C29.8451 23.3927 28.6988 23.4896 27.6927 23.9692C27.3073 24.1529 26.9525 24.388 26.6367 24.6658H28.2222V26.4436H25.1111H23.3333V24.6658V21.5547Z"
        fill="#00A866"
      />
    </SvgIcon>
  );
};

export default DataBackupIcon;
