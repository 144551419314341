import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Stack, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi, { ApiGatewaySubscription, ApiGatewaySubscriptionState } from "../../../api/adminApi";
import { useClientContext } from "../../../context/ClientContext";
import AddSubscriptionDialog from "./AddSubscriptionDialog";
import ApiGatewaySubscriptionsTable from "./ApiGatewaySubscriptionsTable";
import DeleteSubscriptionDialog from "./DeleteSubscriptionDialog";
import EditSubscriptionDialog from "./EditSubscriptionDialog";
import { getInitialState, reducer } from "./apiGatewayPageState";

const ApiGatewaySubscriptions = () => {
  const { hasPermissions } = useClientContext();

  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [state, dispatch] = useReducer(reducer, getInitialState());

  const [subscriptionsResp, subscriptionsFetchError] = useFetch(
    adminApi.getApiGatewaySubscriptions,
    ({ subscriptions }) => dispatch({ type: "LoadSubscriptions", subscriptions })
  );

  const [openAddSubscriptionDialog, setOpenAddSubscriptionDialog] = useState(false);
  const [editedSubscription, setEditedSubscription] = useState<ApiGatewaySubscription>();
  const [deletedSubscription, setDeletedSubscription] = useState<ApiGatewaySubscription>();

  if (subscriptionsFetchError) {
    logError(subscriptionsFetchError.message || "getApiGatewaySubscriptions failed", "ApiGatewaySubscriptions");
    return <DataLoadingFailed title="Loading API Gateway subscriptions failed" />;
  }

  if (subscriptionsResp === undefined) {
    return <InlineLoader />;
  }

  const hasAccessToManage = hasPermissions(["ManageClientIntegrations"]);

  const handleAddClick = () => {
    if (hasAccessToManage) {
      setOpenAddSubscriptionDialog(true);
    }
  };

  const handleEditClick = (subscription: ApiGatewaySubscription) => {
    if (hasAccessToManage) {
      setEditedSubscription(subscription);
    }
  };

  const handleToggleState = async (
    subscription: ApiGatewaySubscription,
    subscriptionState: ApiGatewaySubscriptionState
  ) => {
    if (!hasAccessToManage) {
      return;
    }

    const successStateText = subscriptionState === "Active" ? "activated" : "suspended";
    const failedStateText = subscriptionState === "Active" ? "activate" : "suspend";
    dispatch({ type: "ToggleSubscriptionState", subscriptionId: subscription.id, subscriptionState });
    try {
      await adminApi.updateApiGatewaySubscription(subscription.id, { state: subscriptionState });
      sendNotification(`Subscription for ${subscription.username} successfully ${successStateText}`);
    } catch (error) {
      logError(error, "EditApiGatewaySubscriptionDialog");
      sendNotificationError(`Failed to ${failedStateText} subscription for ${subscription.username}`);
    } finally {
      dispatch({ type: "ToggleSubscriptionStateFinished", subscriptionId: subscription.id });
    }
  };

  const handleDeleteClick = (subscription: ApiGatewaySubscription) => {
    if (hasAccessToManage) {
      setDeletedSubscription(subscription);
    }
  };

  const handleSubscriptionAdded = (subscription: ApiGatewaySubscription) => {
    setOpenAddSubscriptionDialog(false);
    dispatch({ type: "AddSubscription", subscription });
  };

  const handleSubscriptionUpdated = (subscription: ApiGatewaySubscription) => {
    setEditedSubscription(undefined);
    dispatch({ type: "UpdateSubscription", subscription });
  };

  const handleSubscriptionDeleted = (subscriptionId: string) => {
    setDeletedSubscription(undefined);
    dispatch({ type: "DeleteSubscription", subscriptionId });
  };

  return (
    <>
      <Stack spacing={2.5}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="subtitle1">Subscriptions</Typography>
          </Stack>
          {hasAccessToManage && (
            <Button variant="contained" onClick={handleAddClick} startIcon={<AddIcon />}>
              Add Subscription
            </Button>
          )}
        </Stack>
        <ApiGatewaySubscriptionsTable
          subscriptions={state.subscriptions}
          readOnly={!hasAccessToManage}
          updatingSubscriptionIds={state.updatingSubscriptionIds}
          onEditClick={handleEditClick}
          onToggleState={handleToggleState}
          onDeleteClick={handleDeleteClick}
        />
      </Stack>

      <AddSubscriptionDialog
        open={openAddSubscriptionDialog}
        onCancel={() => setOpenAddSubscriptionDialog(false)}
        onSubmit={handleSubscriptionAdded}
      />
      <EditSubscriptionDialog
        open={Boolean(editedSubscription)}
        onCancel={() => setEditedSubscription(undefined)}
        onSubmit={handleSubscriptionUpdated}
        subscription={editedSubscription}
      />
      <DeleteSubscriptionDialog
        open={Boolean(deletedSubscription)}
        onCancel={() => setDeletedSubscription(undefined)}
        onSubmit={handleSubscriptionDeleted}
        subscription={deletedSubscription}
      />
    </>
  );
};

export default ApiGatewaySubscriptions;
