import { logError } from "../../../../shared/logging";
import { PercentEntityFieldConfiguration } from "../../../api/types/objectTypes";
import { EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError } from "../helpers";
import EntityNumberComponent from "./EntityNumberComponent";

const EntityPercentField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "number" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityPercentField]");
    return null;
  }

  return (
    <EntityNumberComponent
      precision={(fieldConfiguration as PercentEntityFieldConfiguration)?.precision ?? 0}
      textPostfix="%"
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      value={value}
      onChange={onChange}
      fieldInputId={fieldInputId}
    />
  );
};

export default EntityPercentField;
