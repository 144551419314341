import CalendarIcon from "@mui/icons-material/CalendarTodayRounded";
import NumbersIcon from "@mui/icons-material/NumbersRounded";
import TitleIcon from "@mui/icons-material/TitleRounded";
import { Stack, Typography } from "@mui/material";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { formatNumber } from "../../../../../../shared/utilities/formatters";
import { InfoSectionBlockItem } from "../../../../../api/types/inputFormTypes";
import CubeIcon from "../../../../../icons/CubeIcon";
import SelectFieldTypeIcon from "../../../../../icons/fieldTypes/SelectFieldTypeIcon";

interface Props {
  item: InfoSectionBlockItem;
}

const PropertyIcon = ({ valueType }: { valueType: string }) => {
  switch (valueType) {
    case "Text": {
      return <TitleIcon color="action" />;
    }
    case "Number": {
      return <NumbersIcon color="action" />;
    }
    case "Date": {
      return <CalendarIcon color="action" />;
    }
    case "Select": {
      return <SelectFieldTypeIcon color="action" />;
    }
    default: {
      return <CubeIcon color="action" />;
    }
  }
};

const getPropertyValueText = (item: InfoSectionBlockItem): string => {
  const { propertyValue, valueType } = item;

  if (propertyValue === undefined || propertyValue === null) {
    return "";
  }

  switch (valueType) {
    case "Date": {
      return convertISODate(propertyValue.toString());
    }
    case "Number": {
      return formatNumber(Number(propertyValue));
    }
    default: {
      return propertyValue.toString();
    }
  }
};

const InfoSectionFormBlockLine = ({ item }: Props) => {
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <PropertyIcon valueType={item.valueType} />
      <Typography color="text.secondary" noWrap width="8.5rem">
        {item.propertyName}
      </Typography>
      <Typography>{getPropertyValueText(item)}</Typography>
    </Stack>
  );
};

export default InfoSectionFormBlockLine;
