import { CreateReportGroup } from "../../../../../../api/biApi";
import { SelectedCompany } from "./SelectedCompany";

export function getAllNonExistedGroups(companies: SelectedCompany[]) {
  const result = companies.flatMap((c) =>
    c.reports
      .filter((r) => r.result?.group.exist === false)
      .map((r) => ({ name: r.result?.group.name, toCompanyCode: c.code }))
      .reduce((acc, cur) => {
        if (!acc.find((a) => a.name === cur.name)) {
          acc.push(cur);
        }
        return acc;
      }, [] as CreateReportGroup[])
  );
  return result;
}
