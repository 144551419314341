import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props {
  value: string | undefined;
  options: FilterOption[];
  onChange: (value: string) => void;
}

interface FilterOption {
  value: string;
  text: string;
}

const CrmFilterEdit = ({ value, options, onChange }: Props) => {
  const handleChange = (e: SelectChangeEvent<string>) => onChange(e.target.value);

  return (
    <Select
      value={value}
      onChange={handleChange}
      displayEmpty
      sx={(theme) => ({ height: theme.spacing(4.5), width: theme.spacing(25) })}
    >
      {options.map(({ value, text }) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CrmFilterEdit;
