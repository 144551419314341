import { createSvgIcon } from "@mui/material";

const QueryStatsIcon = createSvgIcon(
  <svg viewBox="0 0 20 21" fill="currentColor">
    <path d="M1.25003 14L4.39586 8.95833C4.54864 8.72222 4.76045 8.59375 5.03128 8.57291C5.30211 8.55208 5.53475 8.64583 5.7292 8.85416L7.50003 10.9167L10.1459 6.60416C10.2986 6.34027 10.5313 6.20833 10.8438 6.20833C11.1563 6.20833 11.3959 6.33333 11.5625 6.58333L13.1042 8.89583C12.7848 8.90972 12.4827 8.94791 12.1979 9.01041C11.9132 9.07291 11.632 9.15972 11.3542 9.27083L10.8959 8.58333L8.33336 12.75C8.18059 12.9861 7.96531 13.1181 7.68753 13.1458C7.40975 13.1736 7.17364 13.0764 6.9792 12.8542L5.20836 10.7917L2.6667 14.8958C2.5417 15.1042 2.35767 15.2326 2.11461 15.2812C1.87156 15.3299 1.65281 15.2778 1.45836 15.125C1.27781 15 1.17017 14.8299 1.13545 14.6146C1.10073 14.3993 1.13892 14.1944 1.25003 14ZM17.9792 19.6667L15.375 17.0625C15.0973 17.2569 14.7882 17.4028 14.4479 17.5C14.1077 17.5972 13.757 17.6458 13.3959 17.6458C12.3542 17.6458 11.4688 17.2812 10.7396 16.5521C10.0104 15.8229 9.64586 14.9375 9.64586 13.8958C9.64586 12.8542 10.0104 11.9687 10.7396 11.2396C11.4688 10.5104 12.3542 10.1458 13.3959 10.1458C14.4375 10.1458 15.3229 10.5104 16.0521 11.2396C16.7813 11.9687 17.1459 12.8542 17.1459 13.8958C17.1459 14.2569 17.0973 14.6076 17 14.9479C16.9028 15.2882 16.757 15.6042 16.5625 15.8958L19.1667 18.4792L17.9792 19.6667ZM13.3959 15.9792C13.9792 15.9792 14.4723 15.7778 14.875 15.375C15.2778 14.9722 15.4792 14.4792 15.4792 13.8958C15.4792 13.3125 15.2778 12.8194 14.875 12.4167C14.4723 12.0139 13.9792 11.8125 13.3959 11.8125C12.8125 11.8125 12.3195 12.0139 11.9167 12.4167C11.5139 12.8194 11.3125 13.3125 11.3125 13.8958C11.3125 14.4792 11.5139 14.9722 11.9167 15.375C12.3195 15.7778 12.8125 15.9792 13.3959 15.9792ZM15.25 9.3125C14.9861 9.20138 14.7118 9.11111 14.4271 9.04166C14.1424 8.97222 13.8473 8.93055 13.5417 8.91666L17.3334 2.91666C17.4584 2.72222 17.6389 2.59722 17.875 2.54166C18.1111 2.48611 18.3264 2.53472 18.5209 2.6875C18.7014 2.8125 18.8125 2.98263 18.8542 3.19791C18.8959 3.41319 18.8611 3.61805 18.75 3.8125L15.25 9.3125Z" />
  </svg>,
  "QueryStatsIcon"
);

export default QueryStatsIcon;
