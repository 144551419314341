import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import HtmlEditor from "../../../../../shared/components/htmlEditor/HtmlEditor";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import CategorySelector from "./CategorySelector";
import ConsentActivationDate from "./ConsentActivationDate";
import ConsentUpdateVariantDialog from "./ConsentUpdateVariantDialog";
import { ConsentEditable, SaveStatus, useConsentsContext } from "./ConsentsContext";

const SaveButtonTextMap: Record<SaveStatus, string> = {
  Saved: "Saved",
  Saving: "Saving",
  Ready: "Save",
};

const htmlEditorToolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  ["clean"],
];

const ConsentSettings = () => {
  const {
    editableConsent,
    categories,
    updateEditableConsent,
    isLoading,
    showConsentPreview,
    handleConsentSave,
    saveState,
    consents,
    isSaveDisabled,
    canEdit,
  } = useConsentsContext();

  const [showUpdateVariantDialog, setShowUpdateVariantDialog] = useState(false);

  const alreadyExists =
    editableConsent?.isNew && consents.some((c) => c.name.toLowerCase() === editableConsent?.name.toLowerCase());
  const notAllowedSymbolInName =
    editableConsent?.isNew && editableConsent?.name !== "" && !/^[a-zA-Z0-9-_. ]+$/.test(editableConsent?.name || "");
  const previewDisabled = !editableConsent?.name?.trim() || !editableConsent?.editedContent?.trim();
  const disabled = !editableConsent?.isNew || isLoading || saveState === "Saving" || !canEdit;
  const changesDisabled = previewDisabled || isSaveDisabled || alreadyExists || notAllowedSymbolInName || !canEdit;
  const isNotEditable = isLoading || saveState === "Saving" || !canEdit;

  const handleChange = (key: keyof ConsentEditable, value: string | undefined | null) => {
    updateEditableConsent({ [key]: value });
  };

  const handleCategoryChange = (value: string | undefined) => handleChange("categoryId", value);
  const handleNameChange = useDebounce((name: string) => handleChange("name", name), 200);
  const handleActivationDateChange = (date: string | undefined | null) => handleChange("activeFrom", date);
  const handleContentChange = (content: string) => handleChange("editedContent", content);

  const handleSave = () => {
    if (editableConsent) {
      if (editableConsent.isNew || !editableConsent.activeFrom) {
        handleConsentSave(false);
      } else {
        setShowUpdateVariantDialog(true);
      }
    }
  };

  const onSaveVariantDialogClose = (updateVersion: boolean) => {
    handleConsentSave(updateVersion);
    setShowUpdateVariantDialog(false);
  };

  return (
    <Stack spacing={2.5}>
      <Stack spacing={2}>
        <Typography variant="subtitle1">Consent Settings</Typography>
        <Stack direction="row" alignItems="start" spacing={2}>
          <TextField
            sx={(theme) => ({ width: theme.spacing(44) })}
            onChange={(e) => handleNameChange(e.target.value.trim())}
            disabled={disabled}
            label="Consent name"
            defaultValue={editableConsent?.name}
            spellCheck
            error={alreadyExists || notAllowedSymbolInName}
            helperText={
              (alreadyExists && "Consent with this name already exists") ||
              (notAllowedSymbolInName && "Not allowed symbols in name")
            }
          />

          <CategorySelector
            disabled={disabled}
            value={editableConsent?.categoryId}
            onChange={handleCategoryChange}
            label="Message category"
            categories={categories}
          />
        </Stack>
      </Stack>

      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">Body</Typography>
          <Button
            disabled={isLoading || previewDisabled}
            onClick={showConsentPreview}
            variant="outlined"
            startIcon={<VisibilityIcon />}
          >
            Preview
          </Button>
        </Stack>

        <HtmlEditor
          disabled={isNotEditable}
          initialHtmlContent={editableConsent?.content ?? ""}
          onChange={handleContentChange}
          styles={{ editor: { minHeight: "12rem" } }}
          toolbar={htmlEditorToolbar}
        />
      </Stack>

      <ConsentActivationDate
        disabled={isNotEditable}
        onChange={handleActivationDateChange}
        activeFrom={editableConsent?.activeFrom}
      />

      <Stack direction="row" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={changesDisabled}
          onClick={handleSave}
          startIcon={
            (saveState === "Saved" && <CheckIcon />) || (saveState === "Saving" && <CircularProgress size={20} />)
          }
        >
          {SaveButtonTextMap[saveState]}
        </Button>
      </Stack>

      {!editableConsent?.isNew && showUpdateVariantDialog && (
        <ConsentUpdateVariantDialog
          open={showUpdateVariantDialog}
          onClose={() => setShowUpdateVariantDialog(false)}
          onSave={onSaveVariantDialogClose}
        />
      )}
    </Stack>
  );
};

export default ConsentSettings;
