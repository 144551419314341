import SaveIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Button, Stack, Typography } from "@mui/material";
import { HandledApiResponse } from "../../../../shared/api/axiosHelper";
import { logError } from "../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../shared/services/downloadFile";
import DocumentIcon from "../../../icons/DocumentIcon";

interface Props {
  documentCount: number;
  getDownloadUrlForSelectedDocuments: () => Promise<HandledApiResponse<string>>;
}

const MultiDocumentPreview = ({ documentCount, getDownloadUrlForSelectedDocuments }: Props) => {
  const handleDownloadClick = async () => {
    const [url, error] = await getDownloadUrlForSelectedDocuments();
    if (error) {
      logError(error, "[MultiDocumentPreview]");
      return;
    }

    downloadFileFromUrl(url);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1} width="100%" height="100%">
      <Stack alignItems="center" spacing={2}>
        <DocumentIcon sx={{ width: 80, height: 80 }} />
        <Typography color="text.secondary">{`Selected ${documentCount} files`}</Typography>
        <Button color="primary" variant="outlined" onClick={handleDownloadClick} startIcon={<SaveIcon />}>
          Download
        </Button>
      </Stack>
    </Box>
  );
};

export default MultiDocumentPreview;
