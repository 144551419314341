import { TextField, TextFieldProps } from "@mui/material";
import { parseNumber } from "../utilities/numberHelper";
import CloseIconButton from "./CloseIconButton";

type Props = TextFieldProps & {
  onNumberChange: (number: number | undefined, value: string) => void;
  clearable?: boolean;
};

const NumberTextField = ({ onNumberChange, clearable, ...props }: Props) => (
  <TextField
    {...props}
    onChange={({ target }) => onNumberChange(parseNumber(target.value), target.value)}
    onKeyDown={(e) => {
      if (e.key.length === 1 && !/[.,-\d]/.test(e.key)) {
        e.preventDefault();
      }
    }}
    inputProps={{ inputMode: "numeric" }}
    InputProps={{
      endAdornment: clearable && !!props.value && (
        <CloseIconButton onClick={() => onNumberChange(undefined, "")} sx={{ p: 0 }} />
      ),
    }}
  />
);

export default NumberTextField;
