import { GridColDef } from "@mui/x-data-grid-premium";
import { convertISODateTime } from "../../../../shared/utilities/dateUtils";
import { InvestorChangeRequest } from "../../../api/types/changeRequestTypes";
import WorkflowStatusTag from "./WorkflowStatusTag";
import { formatWorkflowType, hasApprovalInfo } from "./changeRequestsFormatters";

export const getColumnDefinitions = (): GridColDef<InvestorChangeRequest>[] => [
  {
    field: "investorName",
    headerName: "Investor",
    sortable: false,
    flex: 1,
    minWidth: 160,
  },
  {
    field: "referenceCode",
    headerName: "Reference",
    sortable: false,
    width: 150,
    cellClassName: "highlighted-action",
  },
  {
    field: "workflowType",
    headerName: "Type",
    sortable: false,
    width: 150,
    valueFormatter: (value) => formatWorkflowType(value),
  },
  {
    field: "workflowStatus",
    headerName: "Status",
    sortable: false,
    width: 150,
    renderCell: ({ row }) => <WorkflowStatusTag workflowStatus={row.workflowStatus} />,
  },
  {
    field: "requestedByUserName",
    headerName: "Requested by",
    sortable: false,
    flex: 0.5,
  },
  {
    field: "requestedAt",
    headerName: "Requested at",
    sortable: false,
    width: 200,
    valueFormatter: (value) => convertISODateTime(value),
  },
  {
    field: "approvalStatusUpdatedByUserName",
    headerName: "Reviewed by",
    sortable: false,
    flex: 0.5,
    valueGetter: (_, row) => (hasApprovalInfo(row.workflowStatus) ? (row.approvalStatusUpdatedByUserName ?? "-") : "-"),
  },
  {
    field: "approvalStatusUpdatedAt",
    headerName: "Reviewed at",
    sortable: false,
    width: 200,
    valueGetter: (_, row) =>
      hasApprovalInfo(row.workflowStatus) && row.approvalStatusUpdatedAt
        ? (convertISODateTime(row.approvalStatusUpdatedAt) ?? "-")
        : "-",
  },
];
