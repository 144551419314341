import ColumnIcon from "@mui/icons-material/ViewWeekOutlined";
import { IconButton, Popover, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import ColumnSelectionEditor from "./ColumnSelectionEditor";

interface Props {
  disabled?: boolean;
}

const ColumnSelector = ({ disabled }: Props) => {
  const [editing, setEditing] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleEditorClose = () => setEditing(false);

  if (disabled) {
    return (
      <IconButton disabled>
        <ColumnIcon />
      </IconButton>
    );
  }

  return (
    <>
      <Tooltip arrow title="Columns">
        <IconButton ref={buttonRef} onClick={() => setEditing(!editing)}>
          <ColumnIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={editing}
        onClose={handleEditorClose}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <ColumnSelectionEditor onClose={handleEditorClose} />
      </Popover>
    </>
  );
};

export default ColumnSelector;
