import { Box, Pagination, Stack } from "@mui/material";
import RowsPerPageSelector from "./RowsPerPageSelector";

interface Props {
  totalPages: number;
  rowsPerPage: number;
  pageIndex: number;
  onRowsPerPageChange: (value: number) => void;
  onPageIndexChange: (value: number) => void;
}

const GridPaginationFooter = ({
  totalPages,
  rowsPerPage,
  pageIndex,
  onRowsPerPageChange,
  onPageIndexChange,
}: Props) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={1}>
      <Stack direction="row" alignItems="center" spacing={2.5}>
        <Box pt={1}>
          <RowsPerPageSelector value={rowsPerPage} onChange={onRowsPerPageChange} />
        </Box>
        <Pagination
          color="primary"
          page={pageIndex + 1}
          count={totalPages}
          onChange={(_, pageNum) => onPageIndexChange(pageNum - 1)}
        />
      </Stack>
    </Box>
  );
};

export default GridPaginationFooter;
