import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, DialogActions, FormControl, Grid, InputLabel, Select, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import DialogHeader from "../../../../../shared/components/dialog/DialogHeader";
import PromptDialog from "../../../../../shared/components/dialog/PromptDialog";
import { ReportGroup } from "../../../../../shared/reporting/api/biClient.types";
import { useGroupsContext } from "../../../../../shared/reporting/components/groups/contexts/GroupsContext";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { useClientContext } from "../../../../context/ClientContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import { GroupType } from "../GroupType";

interface Props {
  group: ReportGroup;
  type: GroupType;
  onDeleted: () => void;
  onClose: () => void;
}

export const DeleteGroupDialog = (props: Props) => {
  const { group, onDeleted, onClose, type } = props;
  const { clientCode } = useClientContext();
  const { groups: reportGroups, actions: groupActions } = useGroupsContext();
  const { groups: locale } = useLocalization();

  const groups = defined(reportGroups[clientCode]);

  const [newGroupId, setNewGroupId] = useState(groups[0]?.id || "");
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string>();
  const [levelSelectionRequired, setLevelSelectionRequired] = useState(false);

  const filteredGroups = useMemo(() => {
    return groups.filter((rg) => rg.id !== group.id);
  }, [group.id, groups]);

  const onDeleteReport = useCallback(() => {
    setSaving(true);
    groupActions
      .delete(clientCode, group.id)
      .then(() => {
        onDeleted();
        onClose();
      })
      .catch((error) => {
        if (error?.response?.data?.error?.message?.includes("REPORTS_ATTACHED")) {
          setLevelSelectionRequired(true);
        } else {
          setError(locale.delete_error);
        }
        setSaving(false);
      });
  }, [groupActions, clientCode, group.id, onDeleted, onClose, locale.delete_error]);

  const onChangeReportLevel = useCallback(() => {
    setSaving(true);
    if (groupActions.replace === undefined || groupActions.reloadReports === undefined) {
      throw new Error(`Replace ${type} group handler is not defined`);
    }

    groupActions
      .replace(clientCode, group.id, newGroupId, groupActions.reloadReports)
      .then(() => {
        onDeleteReport();
      })
      .catch(() => {
        setError(locale.delete_error);
        setSaving(false);
      });
  }, [groupActions, clientCode, group.id, newGroupId, onDeleteReport, locale.delete_error, type]);

  const onSave = useCallback(() => {
    if (levelSelectionRequired) {
      onChangeReportLevel();
    } else {
      onDeleteReport();
    }
  }, [levelSelectionRequired, onChangeReportLevel, onDeleteReport]);

  return (
    <PromptDialog open={true} sx={{ width: 510 }}>
      <DialogHeader text="Delete Group" disabled={saving} onClose={() => onClose()} />
      {error && (
        <Alert sx={{ mb: ".5rem" }} severity="error">
          {error}
        </Alert>
      )}
      <Grid
        container
        sx={{
          px: 2.5,
          py: 1,
          gap: 2,
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ p: "1rem 0" }}>
          <svg width="55" height="47" viewBox="0 0 55 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 30L52.8 44.7" stroke="#BDBDBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M52.8 30L38 44.7" stroke="#BDBDBD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M30.5 45H2V2H20L25.5 10H53.5V22.5"
              stroke="#BDBDBD"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Typography color={(theme) => theme.palette.text.secondary}>
          Are you sure you want to delete the Group ?
        </Typography>
        <Typography variant="h6" color={(theme) => theme.palette.text.primary} sx={{ pb: 2 }}>
          {group.caption}
        </Typography>
        {levelSelectionRequired && (
          <>
            <Typography color={(theme) => theme.palette.text.secondary} pb={2}>
              {`There are ${type}s attached to this Group, select new Group for those ${type}s`}:
            </Typography>
            <FormControl fullWidth disabled={saving}>
              <InputLabel>Group</InputLabel>
              <Select
                itemID="id"
                value={newGroupId}
                title={"caption"}
                label="Group"
                onChange={(evt) => setNewGroupId(evt.target.value as string)}
              >
                {filteredGroups.map((rg) => {
                  return (
                    <MenuItemStyled key={rg.id} value={rg.id}>
                      <Typography>{rg.caption}</Typography>
                    </MenuItemStyled>
                  );
                })}
              </Select>
            </FormControl>
          </>
        )}
      </Grid>
      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" disabled={saving} onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton variant="contained" disabled={saving} loading={saving} onClick={onSave}>
          Delete
        </LoadingButton>
      </DialogActions>
    </PromptDialog>
  );
};

export default DeleteGroupDialog;
