import { TabPanel } from "@mui/lab";
import { Breakpoint, Container } from "@mui/material";
import { PropsWithChildren } from "react";
import { DetailsPageTab } from "../fundraisingsPageTypes";

interface Props {
  value: DetailsPageTab;
  containerWidth?: Breakpoint;
}

const FundraisingDetailsTabPanel = ({ value, containerWidth, children }: PropsWithChildren<Props>) => {
  return (
    <TabPanel value={value} sx={{ height: "100%", flex: 1, px: 0, py: 0 }}>
      <Container maxWidth={containerWidth ?? false} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {children}
      </Container>
    </TabPanel>
  );
};

export default FundraisingDetailsTabPanel;
