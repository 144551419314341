import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import { logError } from "../../../../../../shared/logging";
import TabLabelWithCount from "../../../../common/TabLabelWithCount";
import { useMembersContext } from "../MembersContext";
import DeactivatedMembersGrid from "./DeactivatedMembersGrid";
import MembersGrid from "./MembersGrid";
import PendingInvitesGrid from "./PendingInvitesGrid";

const MembersListContainer = () => {
  const {
    activeToolbarTab,
    switchActiveToolbarTab,
    members,
    filteredMembers,
    deactivatedMembers,
    filteredDeactivatedMembers,
    pendingInvites,
    filteredPendingInvites,
    fetchError,
  } = useMembersContext();

  if (fetchError) {
    logError(fetchError, "[MembersListContainer]");
    return <DataLoadingFailed />;
  }

  const fullWithHeightTabPanelSx = { p: 0, height: "100%", width: "100%" };

  return (
    <TabContext value={activeToolbarTab}>
      <TabList onChange={(_e, value) => switchActiveToolbarTab(value)} aria-label="Members">
        <Tab
          label={
            <TabLabelWithCount
              label="Active"
              count={filteredMembers.length}
              total={members.length}
              isActive={activeToolbarTab === "active"}
            />
          }
          value="active"
        />
        <Tab
          label={
            <TabLabelWithCount
              label="Deactivated"
              count={filteredDeactivatedMembers.length}
              total={deactivatedMembers.length}
              isActive={activeToolbarTab === "deactivated"}
            />
          }
          value="deactivated"
        />
        <Tab
          label={
            <TabLabelWithCount
              label="Pending Invites"
              total={pendingInvites.length}
              count={filteredPendingInvites.length}
              isActive={activeToolbarTab === "pending"}
            />
          }
          value="pending"
        />
      </TabList>
      <TabPanel sx={{ ...fullWithHeightTabPanelSx }} value="active">
        <MembersGrid />
      </TabPanel>
      <TabPanel sx={{ ...fullWithHeightTabPanelSx }} value="deactivated">
        <DeactivatedMembersGrid />
      </TabPanel>
      <TabPanel sx={{ ...fullWithHeightTabPanelSx }} value="pending">
        <PendingInvitesGrid />
      </TabPanel>
    </TabContext>
  );
};

export default MembersListContainer;
