import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import ApiGatewaySubscriptions from "./ApiGatewaySubscriptions";
import Logs from "./logs/Logs";

type Tabs = "subscriptions" | "logs";

const ApiGatewayTabs = () => {
  const [selectedTab, setSelectedTab] = useState<Tabs>("subscriptions");

  const handleTabChange = (_: SyntheticEvent, value: Tabs) => setSelectedTab(value);

  return (
    <TabContext value={selectedTab}>
      <TabList onChange={handleTabChange}>
        <Tab label="Subscriptions" value="subscriptions" />
        <Tab label="Logs" value="logs" />
      </TabList>
      <TabPanel value="subscriptions">
        <ApiGatewaySubscriptions />
      </TabPanel>
      <TabPanel value="logs">
        <Logs />
      </TabPanel>
    </TabContext>
  );
};

export default ApiGatewayTabs;
