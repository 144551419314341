import { SvgIcon, SvgIconProps } from "@mui/material";

const DwhShareDeletedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 80 80" {...props}>
    <path d="M42.2187 26.6642H37.3994V39.9654H42.2187V26.6642Z" fill="#C7DBE5" />
    <path
      d="M62.0738 35.9173H17.4473V53.7486H37.4954V57.604V63.4836H42.3147V57.604V53.7486H62.1702V35.9173H62.0738ZM57.2545 48.9293H22.2665V40.7366H57.2545V48.9293Z"
      fill="#C7DBE5"
    />
    <path
      d="M53.3349 46.0072C53.9411 44.8827 53.521 43.4797 52.3964 42.8735C51.2719 42.2672 49.8689 42.6874 49.2627 43.8119C48.6565 44.9365 49.0766 46.3395 50.2012 46.9457C51.3257 47.5519 52.7287 47.1317 53.3349 46.0072Z"
      fill="#C7DBE5"
    />
    <path
      d="M62.0738 31.4835H17.4473V13.6522H62.0738V31.4835ZM22.2665 26.6642H57.2545V18.4714H22.2665V26.6642Z"
      fill="#C7DBE5"
    />
    <path
      d="M53.3467 23.7366C53.9529 22.6121 53.5327 21.2091 52.4082 20.6029C51.2836 19.9967 49.8806 20.4168 49.2744 21.5414C48.6682 22.6659 49.0884 24.0689 50.2129 24.6752C51.3374 25.2814 52.7404 24.8612 53.3467 23.7366Z"
      fill="#C7DBE5"
    />
    <path d="M68.1464 60.592H11.4717V65.4113H68.1464V60.592Z" fill="#C7DBE5" />
    <path d="M71.5361 52.0001L54 69.5361L57.5355 73.0716L75.0716 55.5356L71.5361 52.0001Z" fill="#F44336" />
    <path d="M57.5355 52L54 55.5355L71.5361 73.0716L75.0716 69.5361L57.5355 52Z" fill="#F44336" />
  </SvgIcon>
);

export default DwhShareDeletedIcon;
