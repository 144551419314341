import { Theme } from "@mui/material";

export const getCheckedGridSx = (theme: Theme, showToolbar: boolean) => ({
  ".grid-row-restricted": {
    ".MuiCheckbox-root .MuiSvgIcon-root": {
      color: theme.palette.text.disabled,
    },
  },

  ".MuiDataGrid-columnHeader": showToolbar
    ? {
        display: "none",
        position: "absolute",
        zIndex: 0,
      }
    : {},

  ".MuiDataGrid-columnHeaders": {
    ".MuiCheckbox-root": {
      display: "none",
      "&.Mui-checked": {
        display: "inherit",
      },
      "&.MuiCheckbox-indeterminate": {
        display: "inherit",
      },
    },
    ":hover .MuiCheckbox-root": {
      display: "inherit",
    },
  },

  ".MuiDataGrid-row": {
    ".MuiDataGrid-cellCheckbox .MuiCheckbox-root": {
      display: "none",
      "&.Mui-checked": {
        display: "inherit",
      },
      "&.MuiCheckbox-indeterminate": {
        display: "inherit",
      },
    },

    ":hover .MuiDataGrid-cellCheckbox .MuiCheckbox-root": {
      display: "inherit",
    },
  },
});
