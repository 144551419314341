import { Grid, Typography } from "@mui/material";
import { ClientInfo } from "../../../api/types/clientTypes";
import CompanyTypeBadge from "../../common/CompanyTypeBadge";
import ClientIcon from "./ClientIcon";

interface Props {
  client: ClientInfo;
  clientOrganization?: ClientInfo;
}

const ClientSelectorValue = ({ client, clientOrganization }: Props) => (
  <Grid container sx={{ display: "flex", flexWrap: "nowrap", alignItems: "center", gap: 1 }}>
    <ClientIcon clientType={client.type} logoUrl={client.branding.logoMarkUrl} color="white" />
    <Grid
      item
      sx={{
        ml: 1,
        display: "grid",
        flexDirection: "column",
        flex: 1,
        ">.MuiTypography-root": { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" },
      }}
    >
      <Typography color="white">{client.title}</Typography>
      {clientOrganization && (
        <Typography sx={{ fontSize: "0.625rem", color: "#ABB3BB" }}>{clientOrganization.title}</Typography>
      )}
    </Grid>
    {client.type === "FundManager" && (
      <Grid item display={"flex"}>
        <CompanyTypeBadge type={client.companyType} />
      </Grid>
    )}
  </Grid>
);

export default ClientSelectorValue;
