import { useGridApiContext } from "@mui/x-data-grid-premium";
import { Checkbox, Link, Stack, SxProps, Theme } from "@mui/material";
import { AccessPermission } from "../../../../../../shared/api/types";
import AuthorizedBox from "../../../../../../shared/components/AuthorizedBox";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
interface Props {
  report: Pick<ReportInfo, "name" | "reportId" | "authorization">;
  href: string;
  permissions: AccessPermission[];
  sx?: SxProps<Theme>;
}

export default function NameColumn({ href, report, permissions, sx }: Props) {
  const apiRef = useGridApiContext();
  const selected = apiRef.current.isRowSelected(report.reportId);
  return (
    <Stack width="100%" direction={"row"} alignItems="center" sx={sx}>
      <AuthorizedBox permissions={permissions}>
        <Checkbox checked={selected} onChange={(_, checked) => apiRef.current.selectRow(report.reportId, checked)} />
      </AuthorizedBox>
      <Link
        href={href}
        target="_blank"
        className="highlighted-action"
        noWrap
        underline="none"
        sx={{ color: "text.primary", pl: 5.3 }}
      >
        <TypographyTooltipEllipsis text={report.name} />
      </Link>
    </Stack>
  );
}
