import { Stack } from "@mui/material";
import { useRef, useState } from "react";
import NumberTextField from "../../../../../shared/components/NumberTextField";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import OperatorSelect from "../OperatorSelect";
import { FilterValue, NumberFilter, NumberFilterOperator } from "../filterTypes";
import { numberFilterOperatorOptions } from "../handlers/numberFilter";
import EmptyFilterBody from "./EmptyFilterBody";

interface Props {
  filter: NumberFilter;
  onUpdateValue: (newValue: FilterValue) => void;
}

const isEmptyFilterBody = (operator: NumberFilterOperator) => ["empty", "not_empty"].includes(operator);
const hasTwoValues = (operator: NumberFilterOperator) => operator === "between";

const getOperatorOptions = (filter: NumberFilter) =>
  filter.operatorOptions !== undefined
    ? numberFilterOperatorOptions.filter((option) => filter.operatorOptions?.includes(option.value))
    : numberFilterOperatorOptions;

const NumberFilterEditor = ({ filter, onUpdateValue }: Props) => {
  const firstInputRef = useRef<HTMLInputElement>(null);

  const [firstTextValue, setFirstTextValue] = useState(filter.value.number?.toString() ?? "");
  const [secondTextValue, setSecondTextValue] = useState(filter.value.secondNumber?.toString() ?? "");

  const handleOperatorChange = (operator: NumberFilterOperator) => {
    if (isEmptyFilterBody(operator)) {
      setFirstTextValue("");
      setSecondTextValue("");
      onUpdateValue({ operator, number: undefined, secondNumber: undefined });
    } else {
      setSecondTextValue("");
      onUpdateValue({ operator, number: filter.value.number, secondNumber: undefined });
    }

    setTimeout(() => firstInputRef.current?.focus(), 100);
  };

  const updateNumberValuesInFilter = useDebounce((number: number | undefined, secondNumber: number | undefined) => {
    onUpdateValue({
      operator: filter.value.operator,
      number,
      secondNumber,
    });
  }, 500);

  const handleFirstTextValueChange = (number: number | undefined, textValue: string) => {
    setFirstTextValue(textValue);
    updateNumberValuesInFilter(number, filter.value.secondNumber);
  };

  const handleSecondTextValueChange = (number: number | undefined, textValue: string) => {
    setSecondTextValue(textValue);
    updateNumberValuesInFilter(filter.value.number, number);
  };

  const emptyFilterBody = isEmptyFilterBody(filter.value.operator);
  const showTwoInputs = hasTwoValues(filter.value.operator);
  const operatorOptions = getOperatorOptions(filter);

  return (
    <Stack spacing={2} p={2} sx={(t) => ({ width: t.spacing(47.5) })}>
      <OperatorSelect<NumberFilterOperator>
        filterName={filter.name}
        options={operatorOptions}
        value={filter.value.operator}
        onChange={handleOperatorChange}
      />
      {emptyFilterBody && <EmptyFilterBody />}
      {!emptyFilterBody && (
        <Stack direction="row" spacing={1}>
          <NumberTextField
            inputRef={firstInputRef}
            autoFocus
            fullWidth
            clearable
            value={firstTextValue}
            onNumberChange={handleFirstTextValueChange}
          />
          {showTwoInputs && (
            <NumberTextField fullWidth clearable value={secondTextValue} onNumberChange={handleSecondTextValueChange} />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default NumberFilterEditor;
