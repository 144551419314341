import { useState, useEffect } from "react";

const usePageLeaveConfirmation = (unsavedChanges: boolean) => {
  const [shouldPrompt, setShouldPrompt] = useState(unsavedChanges);

  useEffect(() => {
    const unloadHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };

    if (shouldPrompt) {
      window.addEventListener("beforeunload", unloadHandler);
    } else {
      window.removeEventListener("beforeunload", unloadHandler);
    }

    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [unsavedChanges, shouldPrompt]);

  return { setShouldPrompt };
};

export default usePageLeaveConfirmation;
