import { useGridApiContext } from "@mui/x-data-grid-premium";
import { useCallback, useMemo } from "react";
import { checkSelection } from "../../../../../../shared/utilities/dataGridApiHelper";
import CustomDataGridHeaderToolbar from "../../../../common/grid/CustomDataGridHeaderToolbar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, Stack } from "@mui/material";

interface Props {
  selectedIds: string[];
  deleteTemplates: (ids: string[]) => void;
}

export default function OrganizationTemplateGridCustomHeader({ selectedIds, deleteTemplates }: Props) {
  const apiRef = useGridApiContext();

  const { children, checked } = useMemo(() => checkSelection(apiRef), [apiRef]);

  const clearSelection = useCallback(() => {
    apiRef.current.selectRows(children, false);
  }, [apiRef, children]);

  return (
    <CustomDataGridHeaderToolbar
      sx={{
        px: 0,
        height: 38,
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
      fullSelection={checked}
      selection={[selectedIds, clearSelection]}
    >
      <Stack direction="row" gap={1}>
        <Button
          variant="text"
          color="error"
          startIcon={<DeleteOutlineIcon color="error" />}
          onClick={() => deleteTemplates(selectedIds)}
        >
          Delete
        </Button>
      </Stack>
    </CustomDataGridHeaderToolbar>
  );
}
