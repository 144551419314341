import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";
import { DataImportInfo } from "../../../api/types/dataImportTypes";
import { PaginatedItemsState } from "../../../state/paginatedState";

interface Props {
  pageState: PaginatedItemsState<DataImportInfo>;
  setPageState: Dispatch<SetStateAction<PaginatedItemsState<DataImportInfo>>>;
  onDeleteImport: (imoport: DataImportInfo) => void;
  refreshData: () => void;
}

type ContextValue = Props;

const ImportDataContext = createContext<ContextValue | undefined>(undefined);

export const ImportDataContextProvider = ({
  pageState,
  setPageState,
  refreshData,
  onDeleteImport,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <ImportDataContext.Provider value={{ pageState, setPageState, refreshData, onDeleteImport }}>
      {children}
    </ImportDataContext.Provider>
  );
};

export const useImportDataContext = () =>
  defined(useContext(ImportDataContext), "Attempt to use useImportDataContext without provider");
