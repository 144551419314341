import adminApi, { BackupMasterApplicationLogs } from "../../../../api/adminApi";
import LogsWrapper from "../../../common/syncLogs/LogsWrapper";
import DataBackupLogsTable from "./DataBackupLogsTable";

const DataBackupLogsPage = () => {
  return (
    <LogsWrapper<BackupMasterApplicationLogs>
      fetchLogsFromApi={(params) => adminApi.getDataBackupLogs(params)}
      renderTable={(logItems) => <DataBackupLogsTable logItems={logItems} />}
    />
  );
};

export default DataBackupLogsPage;
