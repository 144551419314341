import { ApprovalRequest } from "../../../api/mock/approvals/approvalsTypes";

export const approvalRequestSearchMatch = (approval: ApprovalRequest, searchTerm: string): boolean => {
  return (
    approval.name.toLowerCase().includes(searchTerm) ||
    approval.transactionNo.toLowerCase().includes(searchTerm) ||
    approval.transactionType.toLowerCase().includes(searchTerm) ||
    approval.entityNames.some((entityName) => entityName.toLowerCase().includes(searchTerm)) ||
    approval.counterpartyNames.some((counterpartyName) => counterpartyName.toLowerCase().includes(searchTerm))
  );
};
