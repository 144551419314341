import {
  PassthroughConfiguration,
  PassthroughConnectionState,
  PassthroughEnvironment,
} from "../../../api/types/passthroughTypes";

type CurrentProcess = "testingConnection" | "togglingIntegration" | "updatingConfiguration";

export interface PassthroughPageState {
  enabled: boolean;
  lastChangedBy?: string;
  lastChangedOn?: string;
  configuration: PassthroughConfiguration;
  alertMessage?: PassthroughPageAlert;
  activeProcess?: CurrentProcess;
  isConfigurationDirty: boolean;
}

interface PassthroughPageAlert {
  severity: "info" | "error";
  message: string;
}

export const getInitialState = (): PassthroughPageState => ({
  enabled: false,
  configuration: {
    environment: PassthroughEnvironment.Us,
    apiKey: "",
  },
  isConfigurationDirty: false,
});

type Action =
  | {
      type: "SetConnectionState";
      payload: PassthroughConnectionState;
    }
  | {
      type: "SetConfiguration";
      payload: PassthroughConfiguration;
    }
  | {
      type: "UpdateConfiguration";
      payload: Partial<PassthroughConfiguration>;
    }
  | {
      type: "SetAlert";
      payload?: PassthroughPageAlert;
    }
  | {
      type: "SetActiveProcess";
      payload: CurrentProcess;
    }
  | {
      type: "ResetActiveProcess";
    }
  | {
      type: "ResetConfigurationDirty";
    };

export const reducer = (state: PassthroughPageState, action: Action): PassthroughPageState => {
  switch (action.type) {
    case "SetAlert": {
      return {
        ...state,
        alertMessage: action.payload,
      };
    }
    case "SetConnectionState": {
      return {
        ...state,
        enabled: action.payload.enabled,
        lastChangedBy: action.payload.lastChangedBy,
        lastChangedOn: action.payload.lastChangedOn,
      };
    }
    case "SetConfiguration": {
      return {
        ...state,
        configuration: { ...action.payload },
      };
    }
    case "UpdateConfiguration": {
      return {
        ...state,
        configuration: { ...state.configuration, ...action.payload },
        isConfigurationDirty: true,
      };
    }
    case "SetActiveProcess": {
      return {
        ...state,
        activeProcess: action.payload,
      };
    }
    case "ResetActiveProcess": {
      return {
        ...state,
        activeProcess: undefined,
      };
    }
    case "ResetConfigurationDirty": {
      return {
        ...state,
        isConfigurationDirty: false,
      };
    }
    default:
      return state;
  }
};
