import { Stack, Typography } from "@mui/material";
import { InfoSectionBlockAttributes, InfoSectionBlockContent } from "../../../../../api/types/inputFormTypes";
import InfoSectionFormBlockLine from "./InfoSectionFormBlockLine";
import SectionAccordion from "./SectionAccordion";

interface Props {
  attributes: InfoSectionBlockAttributes;
  content: InfoSectionBlockContent;
}

const InfoSectionFormBlock = ({ attributes, content }: Props) => {
  return (
    <SectionAccordion titleComponent={<Typography variant="h6">{attributes.title}</Typography>}>
      <Stack spacing={2} pb={1}>
        {content.items.map((item, index) => (
          <InfoSectionFormBlockLine key={index} item={item} />
        ))}
      </Stack>
    </SectionAccordion>
  );
};

export default InfoSectionFormBlock;
