import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  teal,
  yellow,
} from "@mui/material/colors";
import ColorTag from "../../../shared/components/ColorTag";
import { sumBy } from "../../../shared/utilities/arrayHelper";

interface Props {
  categoryName: string;
}

const otherColors = [
  blueGrey[50],
  blueGrey[100],
  deepPurple[50],
  deepPurple[100],
  indigo[50],
  indigo[100],
  pink[50],
  pink[100],
  deepOrange[50],
  deepOrange[100],
  amber[100],
  amber[200],
  cyan[100],
  cyan[200],
  lightBlue[100],
  lightBlue[200],
  lightGreen[100],
  lightGreen[200],
  lime[100],
  lime[200],
  yellow[100],
  yellow[200],
];

const getColorFromNameHash = (categoryName: string): string => {
  const stringHash = sumBy(categoryName.split(""), (ch) => ch.charCodeAt(0));
  return otherColors[stringHash % otherColors.length] || grey[50];
};

const categoryColorMap: Record<string, string> = {
  Notices: green[50],
  Reports: blue[50],
  Other: purple[50],
  Legal: teal[50],
  Tax: orange[50],
  General: brown[50],
};

const CategoryTag = ({ categoryName }: Props) => (
  <ColorTag
    key={categoryName}
    text={categoryName}
    color={categoryColorMap[categoryName] || getColorFromNameHash(categoryName)}
  />
);

export default CategoryTag;
