import { TextField } from "@mui/material";
import { grey } from "@mui/material/colors";

const MappingObjectText = ({ text }: { text: string }) => (
  <TextField
    variant="outlined"
    disabled
    value={text}
    sx={(theme) => ({ width: theme.spacing(25), bgcolor: grey[100] })}
  />
);

export default MappingObjectText;
