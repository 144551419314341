import { Alert, AlertTitle, Button, Typography } from "@mui/material";

interface Props {
  onRefresh: () => void;
}

const ImportInProgressAlert = ({ onRefresh }: Props) => {
  return (
    <Alert severity="info" sx={{ position: "relative" }}>
      <AlertTitle>Import in progress</AlertTitle>
      <Button
        variant="text"
        color="inherit"
        sx={{ position: "absolute", right: 12, top: 12, py: 0 }}
        onClick={onRefresh}
      >
        Refresh
      </Button>
      <Typography>Depending on the size, it can take a few minutes.</Typography>
    </Alert>
  );
};

export default ImportInProgressAlert;
