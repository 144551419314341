import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import DwhDataSharing from "./DwhDataSharing";
import Logs from "./logs/Logs";

type Tabs = "data_sharing" | "logs";

const DwhConnectorTabs = () => {
  const [selectedTab, setSelectedTab] = useState<Tabs>("data_sharing");

  const handleTabChange = (_: SyntheticEvent, value: Tabs) => setSelectedTab(value);

  return (
    <TabContext value={selectedTab}>
      <TabList onChange={handleTabChange}>
        <Tab label="Data Sharing" value="data_sharing" />
        <Tab label="Logs" value="logs" />
      </TabList>
      <TabPanel value="data_sharing">
        <DwhDataSharing />
      </TabPanel>
      <TabPanel value="logs">
        <Logs />
      </TabPanel>
    </TabContext>
  );
};

export default DwhConnectorTabs;
