import { Popover, Stack, Typography } from "@mui/material";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import CompaniesList from "../CompaniesList";

interface CompaniesListDialogProps {
  anchorEl: HTMLElement | undefined;
  onClose: () => void;
  companiesWithAccess: ListItemData[];
}

const CompaniesListPopover = ({ anchorEl, onClose, companiesWithAccess }: CompaniesListDialogProps) => {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack spacing={0.5} sx={{ p: 1 }}>
        <Typography color="textSecondary" variant="subtitle2">
          Companies
        </Typography>
        <CompaniesList items={companiesWithAccess} />
      </Stack>
    </Popover>
  );
};

export default CompaniesListPopover;
