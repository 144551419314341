import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import { ReportAccessType, ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import ReportActions from "./ReportActions";
import ReportAuthorizedBox from "../../../../../../shared/components/ReportAuthorizedBox";
import EditReportIcon from "../../../../../../shared/icons/EditReportIcon";
import { getEditApiReportUrl, getViewApiReportUrl } from "../utilities/editReportUrl";

interface Props<T extends ReportInfo> {
  report: T;
  onReportSelected: (report?: T) => void;
}

const ReportMenuAction = <T extends ReportInfo>({ report, onReportSelected }: Props<T>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleViewReport = useCallback(() => {
    return getViewApiReportUrl(report.clientCode, report.reportId);
  }, [report]);

  const handleEditReport = useCallback(() => {
    return getEditApiReportUrl(report.clientCode, report.reportId);
  }, [report]);

  const handleClose = useCallback(() => {
    onReportSelected(undefined);
    setAnchorEl(null);
  }, [onReportSelected]);

  return (
    <>
      <ReportAuthorizedBox
        clientCode={report.clientCode}
        permissions={["ManageReports"]}
        requiredAccess={ReportAccessType.Write}
        access={report.authorization.access}
      >
        <IconButton>
          <IconButton href={getEditApiReportUrl(report.clientCode, report.reportId)} target="_blank">
            <EditReportIcon />
          </IconButton>
        </IconButton>
      </ReportAuthorizedBox>
      <IconButton
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          onReportSelected(report);
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <ReportActions
        open={!!anchorEl}
        anchorEl={anchorEl}
        report={report}
        onRun={handleViewReport}
        onEdit={handleEditReport}
        onClose={handleClose}
      />
    </>
  );
};

export default ReportMenuAction;
