import { Alert, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { convertISODateTime } from "../../../../shared/utilities/dateUtils";
import { useClientContext } from "../../../context/ClientContext";
import StartResyncDialog, { StartResyncResult } from "./StartResyncDialog";

interface Props {
  lastSyncTime: string | undefined;
}

const ResyncSection = ({ lastSyncTime }: Props) => {
  const { hasPermissions } = useClientContext();

  const [startResyncInfo, setStartResyncInfo] = useState<StartResyncResult>();
  const [showResyncDialog, setShowResyncDialog] = useState(false);

  const hasAccessToManage = hasPermissions(["ManageClientIntegrations"]);

  const handleResyncClick = () => {
    if (hasAccessToManage) {
      setShowResyncDialog(true);
    }
  };

  const handleResyncStart = (result: StartResyncResult) => {
    setShowResyncDialog(false);
    setStartResyncInfo(result);
  };

  const formattedLastSyncTime = lastSyncTime ? convertISODateTime(lastSyncTime) : "";

  return (
    <>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Setup</Typography>
          {formattedLastSyncTime && (
            <Stack>
              <Typography variant="caption" color="text.secondary">
                Last synchronization
              </Typography>
              <Typography>{formattedLastSyncTime}</Typography>
            </Stack>
          )}
        </Stack>
        {hasAccessToManage && (
          <Button variant="outlined" color="secondary" onClick={handleResyncClick} sx={{ width: "9.5rem" }}>
            Re-Synchronization
          </Button>
        )}
        {startResyncInfo && (
          <Alert severity={startResyncInfo.severity} onClose={() => setStartResyncInfo(undefined)}>
            {startResyncInfo.message}
          </Alert>
        )}
      </Stack>

      <StartResyncDialog
        open={showResyncDialog}
        onClose={() => setShowResyncDialog(false)}
        onStart={handleResyncStart}
      />
    </>
  );
};

export default ResyncSection;
