import { Box, Checkbox, FormControlLabel, Paper, Stack, Typography } from "@mui/material";
import PortalPageStatusSelect from "./PortalPageStatusSelect";
import { ToggleValue } from "./state";

interface Props {
  title: string;
  toggleValue: ToggleValue;
  onToggleChange: (value: ToggleValue) => void;
  toggleDisabled?: boolean;
  checkboxLabel?: string;
  checkedValue?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  checkboxDisabled?: boolean;
}

const PortalPageSettingsEdit = ({
  title,
  toggleValue,
  onToggleChange,
  toggleDisabled,
  checkboxLabel,
  checkedValue,
  onCheckedChange,
  checkboxDisabled,
}: Props) => {
  return (
    <Paper variant="outlined" square={false} sx={{ width: "47.5rem", borderRadius: "8px" }}>
      <Stack width="100%" spacing={1.25} p={1.5} pr={1.5} pl={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="subtitle2">{title}</Typography>
          <PortalPageStatusSelect value={toggleValue} onChange={onToggleChange} disabled={toggleDisabled} />
        </Box>
        {checkedValue !== undefined && onCheckedChange !== undefined && (
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedValue}
                disabled={checkboxDisabled}
                onChange={(_, checked) => onCheckedChange(checked)}
              />
            }
            label={<Typography>{checkboxLabel}</Typography>}
          />
        )}
      </Stack>
    </Paper>
  );
};

export default PortalPageSettingsEdit;
