import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import BackdropLoader from "../../../shared/components/fullScreenLoader/BackdropLoader";

export interface ConfirmationPopupData {
  title: string;
  description?: string;
  onClose: () => void;
  icon?: JSX.Element;
}

interface Props {
  data: ConfirmationPopupData | undefined;
  isLoading?: boolean;
}

const OperationConfirmationPopup = ({ data, isLoading }: Props) => {
  const { title, description, onClose, icon } = data || {};
  return (
    <Dialog open={!!data} fullWidth={true} maxWidth="xs" scroll="paper" onClose={onClose}>
      <DialogContent sx={{ pt: 6 }}>
        <Stack spacing={1} alignItems="center" justifyContent={"center"}>
          {icon}
          <Typography variant="h6">{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 2, py: 1.5 }}>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
      <BackdropLoader open={!!isLoading} />
    </Dialog>
  );
};

export default OperationConfirmationPopup;
