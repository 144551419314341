import { Grid } from "@mui/material";
import { DataSubmissionLayout, ResolvedBlockContent } from "../../../../../api/types/inputFormTypes";
import DataSubmissionFormBlock from "./DataSubmissionFormBlock";
import { DataSubmissionFormContextProvider } from "./DataSubmissionFormContext";

interface Props {
  layout: DataSubmissionLayout;
  blockContents: ResolvedBlockContent[];
}

const DataSubmissionForm = ({ layout, blockContents }: Props) => {
  const getBlockContent = (blockId: string) =>
    blockContents.find((blockContent) => blockContent.blockId === blockId)?.content;

  return (
    <DataSubmissionFormContextProvider getBlockContent={getBlockContent}>
      <Grid container sx={{ flex: 1, py: 2.5, px: 3 }} spacing={1}>
        {layout.blocks.map((block) => (
          <DataSubmissionFormBlock key={block.id} layout={block} />
        ))}
      </Grid>
    </DataSubmissionFormContextProvider>
  );
};

export default DataSubmissionForm;
