import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { EntityOptionFieldType } from "../../../../api/types/objectTypes";
import {
  allMetricAggegationTypes,
  allMetricDataTypes,
  Metric,
  MetricAggregationType,
  MetricDataType,
  NumberDisplayStyle,
} from "../../../../api/types/portfolioMonitoringTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { metricAggregationTypeCaptionMap } from "../../../../utilities/enumCaptions";
import BorderedRadioLabel from "../../../common/BorderedRadioLabel";
import NumberPrecisionSelect from "../../../common/NumberPrecisionSelect";
import { getSelectOptionsFromDictionaries } from "../../../entityFields/helpers";
import MetricDataTypeItem from "../MetricDataTypeItem";
import {
  commonDictionaryOptions,
  extensionSourceOptions,
  formatPreviewValue,
  getAllowedNumberStyles,
  isAggregationTypeEnabled,
  isFormatConfigurationEnabled,
  isValueSourceConfigurationEnabled,
  MetricEditForm,
  MetricEditorFormState,
  MetricExtensionSource,
  MetricFormField,
  MetricValueSource,
  validateForm,
  valueSourceOptions,
} from "./metricEditorHelper";

interface Props {
  form: MetricEditorFormState;
  onChange: (newForm: MetricEditorFormState) => void;
  otherMetricNames: string[];
  allMetricCategories: string[];
  editedMetric?: Metric;
}

const MetricEditor = ({ form, onChange, otherMetricNames, allMetricCategories, editedMetric }: Props) => {
  const { dictionaries } = useClientContext();

  const selectedValuesCommonDictionaryMemo = useMemo(
    () =>
      getSelectOptionsFromDictionaries(form.valuesGlobalDictionaryName, dictionaries)
        .map((o) => o.label)
        .join("\n"),
    [dictionaries, form.valuesGlobalDictionaryName]
  );

  const selectedExtensionCommonDictionaryMemo = useMemo(
    () =>
      getSelectOptionsFromDictionaries(form.extensionGlobalDictionaryName, dictionaries)
        .map((o) => o.label)
        .join("\n"),
    [dictionaries, form.extensionGlobalDictionaryName]
  );

  const handleFieldChange = (update: Partial<MetricEditForm>) => {
    const updatedFields = Object.keys(update) as MetricFormField[];

    const newForm = validateForm(
      {
        ...form,
        ...update,
        touchedFields: distinct([...form.touchedFields, ...updatedFields]),
      },
      otherMetricNames
    );

    onChange(newForm);
  };

  return (
    <Stack spacing={2.5}>
      <Select
        fullWidth
        value={form.dataType}
        onChange={(e) => handleFieldChange({ dataType: e.target.value as MetricDataType })}
        renderValue={(value) => <MetricDataTypeItem dataType={value} />}
        disabled={editedMetric !== undefined}
      >
        {allMetricDataTypes.map((dataType) => (
          <MenuItem key={dataType} value={dataType}>
            <MetricDataTypeItem dataType={dataType} />
          </MenuItem>
        ))}
      </Select>

      {isValueSourceConfigurationEnabled(form.dataType) && (
        <Stack pt={0.5} spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="value-source-select-label">Source</InputLabel>
            <Select
              labelId="value-source-select-label"
              label="Source"
              value={form.valueSource}
              onChange={(e) =>
                handleFieldChange({
                  valueSource: e.target.value as MetricValueSource,
                })
              }
            >
              {valueSourceOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {form.valueSource === "common_dictionary" && (
            <FormControl fullWidth>
              <InputLabel id="values-common-dictionary-select-label">Dictionary</InputLabel>
              <Select
                labelId="values-common-dictionary-select-label"
                label="Dictionary"
                value={form.valuesGlobalDictionaryName}
                onChange={(e) =>
                  handleFieldChange({
                    valuesGlobalDictionaryName: e.target.value as EntityOptionFieldType,
                  })
                }
              >
                {commonDictionaryOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {form.valueSource === "common_dictionary" && (
            <TextField fullWidth multiline rows={8} disabled value={selectedValuesCommonDictionaryMemo} />
          )}

          {form.valueSource === "static_values" && (
            <Stack spacing={0.5}>
              <Typography color="text.secondary">Place each option on its own line</Typography>
              <TextField
                fullWidth
                multiline
                rows={8}
                value={form.allowedValuesMemo}
                onChange={(e) => handleFieldChange({ allowedValuesMemo: e.target.value })}
                error={Boolean(form.validationErrors.allowedValuesMemo)}
                helperText={form.validationErrors.allowedValuesMemo}
              />
            </Stack>
          )}
        </Stack>
      )}

      <TextField
        fullWidth
        label="Name"
        value={form.name}
        onChange={(e) => handleFieldChange({ name: e.target.value })}
        error={Boolean(form.validationErrors.name)}
        helperText={form.validationErrors.name}
      />

      <TextField
        fullWidth
        multiline
        rows={4}
        label="Description"
        value={form.description}
        onChange={(e) => handleFieldChange({ description: e.target.value })}
      />

      <Autocomplete
        fullWidth
        freeSolo
        includeInputInList
        options={allMetricCategories}
        value={form.category}
        onInputChange={(_, category) => handleFieldChange({ category })}
        renderInput={(params) => <TextField {...params} label="Category" />}
      />

      {isAggregationTypeEnabled(form.dataType) && (
        <Stack pt={0.5} spacing={1}>
          <Typography variant="subtitle1" py={1.5}>
            Aggregation Type
          </Typography>
          <FormControl sx={{ mt: 0.5 }}>
            <RadioGroup
              defaultValue="PointInTime"
              value={form.aggregationType}
              sx={{ rowGap: 1 }}
              onChange={(e) => handleFieldChange({ aggregationType: e.target.value as MetricAggregationType })}
            >
              {allMetricAggegationTypes.map((aggregationType) => (
                <BorderedRadioLabel<MetricAggregationType>
                  key={aggregationType}
                  value={aggregationType}
                  selectedValue={form.aggregationType}
                  label={metricAggregationTypeCaptionMap[aggregationType]}
                  sx={{ pl: 1.5 }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Stack>
      )}

      {isFormatConfigurationEnabled(form.dataType) && (
        <Stack pt={0.5} spacing={1}>
          <Typography variant="subtitle1" py={1.5}>
            Default Format
          </Typography>

          <Stack direction="row" spacing={1}>
            <FormControl fullWidth>
              <InputLabel id="number-display-style-select-label">Display Style</InputLabel>
              <Select
                labelId="number-display-style-select-label"
                label="Display Style"
                value={form.numberDisplayStyle ?? "_"}
                onChange={(e) =>
                  handleFieldChange({
                    numberDisplayStyle: e.target.value === "_" ? undefined : (e.target.value as NumberDisplayStyle),
                  })
                }
              >
                <MenuItem value="_">None</MenuItem>
                {getAllowedNumberStyles(form.dataType).map((displayStyle) => (
                  <MenuItem key={displayStyle} value={displayStyle}>
                    {autoFormatCamelCase(displayStyle)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="decimal-precision-select-label">Decimal Precision</InputLabel>
              <NumberPrecisionSelect
                labelId="decimal-precision-select-label"
                label="Decimal Precision"
                precision={form.numberOfDecimals}
                maxPrecision={6}
                onPrecisionChange={(numberOfDecimals) => handleFieldChange({ numberOfDecimals })}
              />
            </FormControl>
          </Stack>

          <Typography variant="body2" color="text.secondary">
            Preview: {formatPreviewValue(form)}
          </Typography>
        </Stack>
      )}

      <Stack pt={0.5} spacing={2}>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Stack>
            <Typography variant="subtitle1" py={1.5}>
              Extended Metric
            </Typography>
            <Typography color="text.secondary">
              Extend this metric with an extra dimension (e.g., Country, Product, etc.) to collect more granular data.
              This setting cannot be changed once the metric is created.
            </Typography>
          </Stack>

          <Switch
            checked={form.isExtended}
            onChange={(_, checked) => handleFieldChange({ isExtended: checked })}
            disabled={Boolean(editedMetric?.extensionConfiguration?.extensionName)}
          />
        </Box>

        {form.isExtended && (
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              label="Extension Name"
              value={form.extensionName}
              onChange={(e) => handleFieldChange({ extensionName: e.target.value })}
              error={Boolean(form.validationErrors.extensionName)}
              helperText={form.validationErrors.extensionName}
            />

            <FormControl fullWidth>
              <InputLabel id="extension-source-select-label">Source</InputLabel>
              <Select
                labelId="extension-source-select-label"
                label="Source"
                value={form.extensionSource}
                onChange={(e) =>
                  handleFieldChange({
                    extensionSource: e.target.value as MetricExtensionSource,
                  })
                }
              >
                {extensionSourceOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}

        {form.isExtended && form.extensionSource === "common_dictionary" && (
          <FormControl fullWidth>
            <InputLabel id="extension-common-dictionary-select-label">Dictionary</InputLabel>
            <Select
              labelId="extension-common-dictionary-select-label"
              label="Dictionary"
              value={form.extensionGlobalDictionaryName}
              onChange={(e) =>
                handleFieldChange({
                  extensionGlobalDictionaryName: e.target.value as EntityOptionFieldType,
                })
              }
            >
              {commonDictionaryOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {form.isExtended && form.extensionSource === "common_dictionary" && (
          <TextField fullWidth multiline rows={8} disabled value={selectedExtensionCommonDictionaryMemo} />
        )}

        {form.isExtended && form.extensionSource === "static_values" && (
          <Stack spacing={0.5}>
            <Typography color="text.secondary">Place each option on its own line</Typography>
            <TextField
              fullWidth
              multiline
              rows={8}
              value={form.allowedExtensionValuesMemo}
              onChange={(e) => handleFieldChange({ allowedExtensionValuesMemo: e.target.value })}
              error={Boolean(form.validationErrors.allowedExtensionValuesMemo)}
              helperText={form.validationErrors.allowedExtensionValuesMemo}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default MetricEditor;
