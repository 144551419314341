import { SvgIconComponent } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/LanguageRounded";
import { Box } from "@mui/material";
import { FundraisingStatus } from "../../../../../shared/api/fundraisingTypes";
import PaperIcon from "../../../../icons/PaperIcon";
import SuccessIcon from "../../../../icons/SuccessIcon";
import VisibleIcon from "../../../../icons/VisibleIcon";

interface Props {
  status: FundraisingStatus;
}

const CombinedIcon = ({ Icon }: { Icon: SvgIconComponent }) => (
  <Box position="relative" pb={1} pr={1} width={88} height={88}>
    <PaperIcon sx={{ width: 80, height: 80 }} />
    <Box position="absolute" width={26} height={26} bgcolor="white" bottom={13} right={13} zIndex={1000}>
      <Icon color="primary" sx={{ width: 26, height: 26 }} />
    </Box>
  </Box>
);

const FundraisingStatusUpdateIcon = ({ status }: Props) => {
  switch (status) {
    case "Preview":
      return <CombinedIcon Icon={VisibleIcon} />;
    case "Live":
      return <CombinedIcon Icon={LanguageIcon} />;
    case "Completed":
      return <SuccessIcon sx={{ width: 80, height: 80 }} />;
    default:
      return null;
  }
};

export default FundraisingStatusUpdateIcon;
