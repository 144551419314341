import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import { DataSubmissionBlockContent } from "../../../../../api/types/inputFormTypes";

interface Props {
  getBlockContent: (blockId: string) => DataSubmissionBlockContent | undefined;
}

const DataSubmissionFormContext = createContext<Props | undefined>(undefined);

export const DataSubmissionFormContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <DataSubmissionFormContext.Provider value={contextValue}>{children}</DataSubmissionFormContext.Provider>
);

export const useDataSubmissionFormContext = () =>
  defined(useContext(DataSubmissionFormContext), "Attempt to use DataSubmissionFormContext without provider");
