import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Divider,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import UserAvatar from "../../../shared/components/UserAvatar";
import { autoFormatCamelCase } from "../../../shared/utilities/stringHelper";
import { useClientContext } from "../../context/ClientContext";
import { useUserContext } from "../../context/UserContext";
import variables from "../../variables";
import MenuLinkItem from "../common/MenuLinkItem";
import MultilineTooltipTitle from "../common/MultilineTooltipTitle";
import OpenExternalLinkMenuItem from "../common/OpenExternalLinkMenuItem";
import UserInfoMenuItem from "../common/UserInfoMenuItem";
import AccountPopupMenu from "./AccountPopupMenu";

const BottomMenu = () => {
  const { name, email, avatarImage, isBetaAccessAvailable, isBetaAccessEnabled, updateBetaAccessEnabled } =
    useUserContext();

  const { clientCode, settings } = useClientContext();

  const betaFeaturesTooltipLines = [
    "Current Beta Features:",
    "",
    ...(settings.betaOnlyFeatures.length > 0
      ? settings.betaOnlyFeatures.map((f) => `•︎  ${autoFormatCamelCase(f)}`).sort()
      : ["None"]),
  ];

  return (
    <MenuList>
      {isBetaAccessAvailable && (
        <Tooltip
          arrow
          enterDelay={500}
          enterNextDelay={500}
          title={<MultilineTooltipTitle lines={betaFeaturesTooltipLines} />}
        >
          <MenuItem>
            <FormControlLabel
              control={
                <Switch
                  checked={isBetaAccessEnabled}
                  onChange={(_, checked) => updateBetaAccessEnabled(checked)}
                  sx={{ mr: -1 }}
                />
              }
              label={<Typography>Beta Features</Typography>}
              labelPlacement="start"
              sx={{
                justifyContent: "space-between",
                width: "100%",
                ml: 0,
              }}
            />
          </MenuItem>
        </Tooltip>
      )}

      <OpenExternalLinkMenuItem Icon={HelpOutlineIcon} title="Help Center" href={variables.HELP_CENTER_URI} />

      <Divider />

      <AccountPopupMenu
        renderElement={
          <MenuItem>
            <ListItemIcon>
              <UserAvatar size="small" userName={name} avatarImage={avatarImage} />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </MenuItem>
        }
      >
        <UserInfoMenuItem userName={name} email={email} avatarImage={avatarImage} />
        <Divider />
        <MenuLinkItem title="Log out" href={`/${clientCode}/logout`} Icon={LogoutIcon} />
      </AccountPopupMenu>
    </MenuList>
  );
};

export default BottomMenu;
