import { Box, Button, Paper, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CreateNewFolderIcon from "../../../../../shared/icons/CreateNewFolderIcon";
import DragAndDropList from "../../../common/DragAndDropList";
import EditGroupDialog from "./EditGroupDialog";
import LayoutGroupCard from "./LayoutGroupCard";
import { useObjectLayoutContext } from "./ObjectLayoutContext";
import { LayoutField, LayoutFieldGroup, moveFieldAction, moveGroupAction } from "./objectLayoutState";

interface DialogState {
  openDialog?: "edit_group";
  editedGroupName?: string;
}

type FieldAndGroupName = [LayoutField, string];

const ObjectLayoutEditor = () => {
  const { layoutState, setLayoutState } = useObjectLayoutContext();
  const [editGroupDialogState, setEditGroupDialogState] = useState<DialogState>({});

  const draggedField = useRef<FieldAndGroupName | undefined>(undefined);

  const handleDragField = (groupName: string) => (field: LayoutField) => {
    draggedField.current = [field, groupName];
  };

  const handleDropField = (groupName: string) => (index: number, field: LayoutField | undefined) => {
    if (field) {
      setLayoutState(moveFieldAction(groupName, groupName, index, field));
      return;
    }

    // When field is moved from a different group, the callback arg will be undefined
    const fieldAndGroupName = draggedField.current;
    if (fieldAndGroupName) {
      const [field, sourceGroupName] = fieldAndGroupName;
      setLayoutState(moveFieldAction(sourceGroupName, groupName, index, field));
    }
  };

  const handleDropGroup = (index: number, draggingGroup: LayoutFieldGroup | undefined) => {
    if (draggingGroup) {
      setLayoutState(moveGroupAction(index, draggingGroup));
    }
  };

  return (
    <>
      <Paper variant="outlined">
        <Box display="flex" justifyContent="space-between" width="100%" py={2} px={2.5}>
          <Typography variant="h6">Details</Typography>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CreateNewFolderIcon />}
            onClick={() => setEditGroupDialogState({ openDialog: "edit_group" })}
          >
            Add Group
          </Button>
        </Box>
        <Box width="100%" px={2.5}>
          <DndProvider backend={HTML5Backend}>
            <DragAndDropList<LayoutFieldGroup>
              type="layout_group"
              items={layoutState.fieldGroups}
              onDrop={handleDropGroup}
              renderItem={(group, index) => (
                <LayoutGroupCard
                  key={`${group.name}-${index}`}
                  group={group}
                  onDragField={handleDragField(group.name)}
                  onDropField={handleDropField(group.name)}
                  onRenameGroup={(groupName) =>
                    setEditGroupDialogState({ openDialog: "edit_group", editedGroupName: groupName })
                  }
                />
              )}
              renderCustomDragItemLayer={(group) => (
                <Paper sx={{ width: "32rem" }}>
                  <LayoutGroupCard group={group} hideFieldsAndActions />
                </Paper>
              )}
              options={{ notDraggable: () => Boolean(layoutState.isAddingFieldToGroup) }}
            />
          </DndProvider>
        </Box>
      </Paper>
      {editGroupDialogState.openDialog === "edit_group" && (
        <EditGroupDialog
          editedGroupName={editGroupDialogState.editedGroupName}
          onClose={() => setEditGroupDialogState({})}
        />
      )}
    </>
  );
};

export default ObjectLayoutEditor;
