import { Paper, Table, TableContainer } from "@mui/material";
import { PropsWithChildren } from "react";

const RoundedTable = ({ children }: PropsWithChildren) => (
  <Paper variant="outlined">
    <TableContainer>
      <Table
        sx={(t) => ({
          "& .MuiTableCell-root": {
            py: 1.375,
            borderRight: "1px solid",
            borderColor: t.palette.divider,
            "&:last-child": {
              borderRight: 0,
            },
          },
          "& .MuiTableRow-root": {
            "&:last-child": {
              td: {
                borderBottom: 0,
              },
            },
          },
        })}
      >
        {children}
      </Table>
    </TableContainer>
  </Paper>
);

export default RoundedTable;
