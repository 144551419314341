import React, { PropsWithChildren, useContext } from "react";
import { ChangeReportGroupRequest, Report, ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { ReportActionsContextType, ReportContextType } from "../../../contexts/reportContext.types";

type UiReportContextType<T extends ReportInfo> = ReportContextType<T> & {
  actions: UiReportActionsContextType<T>;
};

interface UiReportActionsContextType<T extends ReportInfo> extends ReportActionsContextType<T> {
  changeReportGroup: (request: ChangeReportGroupRequest) => Promise<Report | undefined>;
}

const UiReportsContext = React.createContext<UiReportContextType<ReportInfo> | undefined>(undefined);

export const UiReportsContextProvider = ({
  children,
  ...props
}: PropsWithChildren<UiReportContextType<ReportInfo>>) => {
  return <UiReportsContext.Provider value={props}>{children}</UiReportsContext.Provider>;
};

export function useUiReportsContext<T extends ReportInfo>() {
  const context = useContext(UiReportsContext);
  return defined(context as unknown as UiReportContextType<T>);
}
