import { SvgIcon, SvgIconProps } from "@mui/material";

const DashedArrowIconLeft = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 76 18" {...props}>
    <path
      d="M0.393394 9L7.89339 4.66987V13.3301L0.393394 9ZM75.0601 9.75H72.986V8.25H75.0601V9.75ZM68.8378 9.75H64.6897V8.25H68.8378V9.75ZM60.5415 9.75H56.3934V8.25H60.5415V9.75ZM52.2452 9.75H48.0971V8.25H52.2452V9.75ZM43.9489 9.75H39.8008V8.25H43.9489V9.75ZM35.6527 9.75H31.5045V8.25H35.6527V9.75ZM27.3564 9.75H23.2082V8.25H27.3564V9.75ZM19.0601 9.75H14.9119V8.25H19.0601V9.75ZM10.7638 9.75H6.61562V8.25H10.7638V9.75ZM0.393394 9L15.3934 0.339746V17.6603L0.393394 9ZM75.0601 10.5H72.986V7.5H75.0601V10.5ZM68.8378 10.5H64.6897V7.5H68.8378V10.5ZM60.5415 10.5H56.3934V7.5H60.5415V10.5ZM52.2452 10.5H48.0971V7.5H52.2452V10.5ZM43.9489 10.5H39.8008V7.5H43.9489V10.5ZM35.6527 10.5H31.5045V7.5H35.6527V10.5ZM27.3564 10.5H23.2082V7.5H27.3564V10.5ZM19.0601 10.5H14.9119V7.5H19.0601V10.5ZM10.7638 10.5H6.61562V7.5H10.7638V10.5Z"
      fill="#C7DBE5"
    />
  </SvgIcon>
);

export default DashedArrowIconLeft;
