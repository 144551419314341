const tryCreateURL = (urlString: string) => {
  try {
    return new URL(urlString);
  } catch (error) {
    return undefined;
  }
};

export const tryGetUrlWithProtocol = (value: string) => {
  const urlWithProtocol = /^[a-z]+:\/\//i.test(value) ? value : `https://${value}`;
  const url = tryCreateURL(urlWithProtocol);
  return url && url.hostname.includes(".") && ["http:", "https:"].includes(url.protocol) ? url : undefined;
};
