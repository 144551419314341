import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useState } from "react";
import SearchField from "../../../../shared/components/inputs/SearchField";
import { stringComparerBy } from "../../../../shared/utilities/arrayHelper";
import { useFilterContext } from "./FilterContext";
import FilterTypeIcon from "./FilterTypeIcon";

interface Props {
  onSelected: (filterId: string) => void;
}

const AddFilterSelector = ({ onSelected }: Props) => {
  const { filterState } = useFilterContext();
  const [filters, setFilters] = useState(filterState.availableFilters);
  const handleSearch = (value: string) => {
    setFilters(
      !value
        ? filterState.availableFilters
        : filterState.availableFilters.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()))
    );
  };

  return (
    <Stack
      width="100%"
      height="100%"
      spacing={1.5}
      py={0.5}
      sx={(t) => ({ maxHeight: t.spacing(56) })}
      overflow="hidden"
    >
      {filterState.availableFilters.length > 10 && (
        <>
          <Box px={2} pt={1.5}>
            <SearchField fullWidth autoFocus placeholder="Filter by..." onSearch={handleSearch} />
          </Box>
          <Divider />
        </>
      )}
      <List sx={{ width: "100%", overflow: "auto" }}>
        {filters.sort(stringComparerBy((f) => f.name)).map((filter) => (
          <ListItem key={filter.id} value={filter.name} dense disablePadding>
            <ListItemButton onClick={() => onSelected(filter.id)} dense>
              <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                <FilterTypeIcon filterType={filter.type} />
              </ListItemIcon>
              <ListItemText primary={filter.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default AddFilterSelector;
