import { DwhShare, DwhListingType, PatchDwhShareRequest } from "../../../api/types/dwhTypes";

export type UpdateShareFormFields = keyof UpdateShareForm;

export interface UpdateShareForm {
  description: string;
}

export const initialUpdateShareForm = (share: DwhShare | undefined): UpdateShareForm => ({
  description: share?.description ?? "",
});

export const mapUpdateShareFormToRequest = (form: UpdateShareForm): PatchDwhShareRequest => ({
  description: form.description.trim() || undefined,
});

export const isFormEdited = (form: UpdateShareForm, share: DwhShare): boolean =>
  form.description.trim() !== (share.description?.trim() ?? "");

export const formatDwhShareType = (type: DwhListingType) => {
  switch (type) {
    case "AllTables":
      return "All Tables";
    case "Qashqade":
      return "Qashqade";
    case "Cascata":
      return "Cascata";
    default:
      return type;
  }
};
