import { SvgIcon, SvgIconProps } from "@mui/material";

const GridViewRoundedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <mask id="mask0_16061_6085" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_16061_6085)">
        <path
          d="M4.16667 9.16667C3.70833 9.16667 3.31597 9.00347 2.98958 8.67708C2.66319 8.35069 2.5 7.95833 2.5 7.5V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H7.5C7.95833 2.5 8.35069 2.66319 8.67708 2.98958C9.00347 3.31597 9.16667 3.70833 9.16667 4.16667V7.5C9.16667 7.95833 9.00347 8.35069 8.67708 8.67708C8.35069 9.00347 7.95833 9.16667 7.5 9.16667H4.16667ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V12.5C2.5 12.0417 2.66319 11.6493 2.98958 11.3229C3.31597 10.9965 3.70833 10.8333 4.16667 10.8333H7.5C7.95833 10.8333 8.35069 10.9965 8.67708 11.3229C9.00347 11.6493 9.16667 12.0417 9.16667 12.5V15.8333C9.16667 16.2917 9.00347 16.684 8.67708 17.0104C8.35069 17.3368 7.95833 17.5 7.5 17.5H4.16667ZM12.5 9.16667C12.0417 9.16667 11.6493 9.00347 11.3229 8.67708C10.9965 8.35069 10.8333 7.95833 10.8333 7.5V4.16667C10.8333 3.70833 10.9965 3.31597 11.3229 2.98958C11.6493 2.66319 12.0417 2.5 12.5 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V7.5C17.5 7.95833 17.3368 8.35069 17.0104 8.67708C16.684 9.00347 16.2917 9.16667 15.8333 9.16667H12.5ZM12.5 17.5C12.0417 17.5 11.6493 17.3368 11.3229 17.0104C10.9965 16.684 10.8333 16.2917 10.8333 15.8333V12.5C10.8333 12.0417 10.9965 11.6493 11.3229 11.3229C11.6493 10.9965 12.0417 10.8333 12.5 10.8333H15.8333C16.2917 10.8333 16.684 10.9965 17.0104 11.3229C17.3368 11.6493 17.5 12.0417 17.5 12.5V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H12.5ZM4.16667 7.5H7.5V4.16667H4.16667V7.5ZM12.5 7.5H15.8333V4.16667H12.5V7.5ZM12.5 15.8333H15.8333V12.5H12.5V15.8333ZM4.16667 15.8333H7.5V12.5H4.16667V15.8333Z"
          fill={props.fill}
        />
      </g>
    </SvgIcon>
  );
};

export default GridViewRoundedIcon;
