import { getChangedFields } from "../../../../../shared/utilities/objectHelper";
import { ClientInfo, CompanyType } from "../../../../api/types/clientTypes";

export interface GeneralCompanySettingsState {
  initialSettings: ClientSettings;
  currentSettings: ClientSettings;
  defaultLogomarkUrl?: string | undefined;
  isLoading: boolean;
  formHasChanges: boolean;
  changes: Partial<ClientSettings>;
}

type Action =
  | { type: "UpdateSettings"; payload: Partial<ClientSettings> }
  | { type: "SetIsLoading"; payload: boolean }
  | { type: "Reset" }
  | { type: "SetSavedSettings"; payload: Partial<ClientInfo> }
  | { type: "ResetLogo" };

interface ClientSettings {
  logomarkUrl?: string | undefined;
  logomarkImage?: File | undefined;
  entityLevelAccess?: boolean;
  companyType?: CompanyType;
}

export const getInitialState = (data: ClientSettings): GeneralCompanySettingsState => {
  return {
    currentSettings: { ...data },
    initialSettings: { ...data },
    defaultLogomarkUrl: data.logomarkUrl,
    isLoading: false,
    formHasChanges: false,
    changes: {},
  };
};

export const reducer = (state: GeneralCompanySettingsState, action: Action): GeneralCompanySettingsState => {
  switch (action.type) {
    case "UpdateSettings": {
      const newState = {
        ...state,
        currentSettings: { ...state.currentSettings, ...action.payload },
      };
      const changes = getChangedFields(state.initialSettings, newState.currentSettings);
      newState.changes = changes;
      newState.formHasChanges = Object.keys(changes).length > 0;
      return newState;
    }
    case "SetIsLoading":
      return { ...state, isLoading: action.payload };
    case "Reset":
      return {
        ...state,
        currentSettings: { ...state.initialSettings },
        defaultLogomarkUrl: state.initialSettings.logomarkUrl,
        changes: {},
        formHasChanges: false,
      };
    case "SetSavedSettings":
      return getInitialState({
        entityLevelAccess: action.payload.entityLevelAccess || false,
        companyType: action.payload.companyType || "Production",
        logomarkUrl: action.payload.branding?.logoMarkUrl,
      });
    case "ResetLogo": {
      const newState = {
        ...state,
        currentSettings: { ...state.currentSettings, logomarkUrl: state.defaultLogomarkUrl, logomarkImage: undefined },
      };
      const changes = getChangedFields(state.initialSettings, newState.currentSettings);
      newState.changes = changes;
      newState.formHasChanges = Object.keys(changes).length > 0;
      return newState;
    }

    default:
      return state;
  }
};
