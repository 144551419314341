import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import BadgeDetached from "../../../../../../shared/components/BadgeDetached";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import { useClientContext } from "../../../../../context/ClientContext";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { useCopyToEligibleCompanies } from "../hooks/useCopyToEligibleCompanies";
import { CompaniesList } from "./CompanyList";
import SelectCompaniesDialog from "./SelectCompaniesDialog";
import { ReportValidationType } from "./hooks/useReportValidation";
import { SelectedCompanyReport } from "./hooks/SelectedCompany";
import { ValidationDetailsContainer } from "./validationDetails/ValidationDetailsContainer";

interface Props {
  validation: ReportValidationType;
  copyConditionValues: boolean;
  onCopyConditionValues: (value: boolean) => void;
}
export default function CopyReportContent({ validation, copyConditionValues, onCopyConditionValues }: Props) {
  const { reports_copyto: locale } = useLocalization();
  const { clientCode } = useClientContext();
  const eligibleCompanies = useCopyToEligibleCompanies();

  const [showSelectCompany, setShowSelectCompany] = useState(false);
  const [activeCompanyReport, setActiveCompanyReport] = useState<SelectedCompanyReport>();

  const activeCompany = useMemo(() => {
    return validation.selectedCompanies.find((c) => c.code === activeCompanyReport?.companyCode);
  }, [activeCompanyReport, validation.selectedCompanies]);

  const selectedCompanyCodes = useMemo(
    () => validation.selectedCompanies.map((c) => c.code),
    [validation.selectedCompanies]
  );

  const companyList = useMemo(() => {
    return eligibleCompanies
      .filter((c) => c.clientCode !== clientCode)
      .map(
        (c): ListItemData => ({
          value: c.clientCode,
          label: c.title,
          iconSrc: c.branding.logoMarkUrl,
        })
      );
  }, [clientCode, eligibleCompanies]);

  useEffect(() => {
    if (activeCompanyReport) {
      const report = validation.selectedCompanies
        .find((c) => c.code === activeCompanyReport.companyCode)
        ?.reports.find((r) => r.report.reportId === activeCompanyReport.report.reportId);

      setActiveCompanyReport(report);
    } else {
      setActiveCompanyReport(validation.selectedCompanies.at(0)?.reports.at(0));
    }
  }, [activeCompanyReport, validation.selectedCompanies]);

  return (
    <Grid container sx={{ flexDirection: "row" }}>
      <Grid container sx={{ flex: 1, flexDirection: "column", gap: 2, px: 2.5, py: 1.5 }}>
        <FormControlLabel
          value={copyConditionValues}
          checked={copyConditionValues}
          control={<Checkbox />}
          label={locale.copy_condition_values}
          onChange={(_, value) => onCopyConditionValues(value)}
        ></FormControlLabel>
        <Typography color={"secondary"}>{locale.select_comanies}</Typography>
        <Grid container sx={{ alignItems: "center", gap: 1 }}>
          <Typography variant="subtitle1">Companies</Typography>
          <BadgeDetached color="secondary" badgeContent={validation.selectedCompanies.length} />
          <Button variant="text" startIcon={<AddRoundedIcon />} onClick={() => setShowSelectCompany(true)}>
            Select Companies
          </Button>
        </Grid>
        <CompaniesList
          validation={validation}
          activeCompany={activeCompany}
          activeCompanyReport={activeCompanyReport}
          availableCompanies={companyList}
          onSelectedCompanyReport={setActiveCompanyReport}
        />
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid container sx={{ flex: 0.6 }}>
        <ValidationDetailsContainer
          companyReport={activeCompanyReport}
          company={activeCompany}
          updateCompanyReport={validation.updateCompanyReport}
        />
      </Grid>
      <SelectCompaniesDialog
        open={showSelectCompany}
        availableCompanies={companyList}
        selectedCompanies={selectedCompanyCodes}
        handleClose={(companySelection) => {
          validation.updateCompanySelection(companySelection);
          setShowSelectCompany(false);
        }}
      />
    </Grid>
  );
}
