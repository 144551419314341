import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import FundraisingStatusUpdateIcon from "../FundraisingStatusUpdateIcon";

interface Props {
  onClose: () => void;
  onSaveConfirmed: () => void;
}

const ConfirmSaveFundraisingDialog = ({ onClose, onSaveConfirmed }: Props) => {
  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Save Changes</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <FundraisingStatusUpdateIcon status="Live" />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              Publish changes to Live
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              The content will be published on the portal and will be available to users.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSaveConfirmed}>
          Save & Publish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSaveFundraisingDialog;
