import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import SearchField from "../../../../shared/components/inputs/SearchField";
import { ClientInfo } from "../../../api/types/clientTypes";
import { useUserContext } from "../../../context/UserContext";
import { Searchable, applySearch } from "../../../utilities/searchHelper";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import ViewSelector, { ViewType } from "../../common/ViewSelector";
import { DataGridGroup } from "../../common/grid/gridTypes";
import {
  OrganizationCompaniesGroup,
  getSortedOrganizationCompanyGroups,
} from "../../leftSideMenu/client-selector/client-selector-helper";
import ClientsGridView from "./ClientsGridView";
import ClientsTableView from "./ClientsTableView";

const ClientsPage = () => {
  const { clients } = useUserContext();
  const [view, setView] = useState<ViewType>("Grid");

  const companies = useMemo(
    () =>
      clients
        .filter((c) => c.type === "FundManager")
        .map((client) => ({ ...client, searchTerms: [client.title.toLowerCase()] })),
    [clients]
  );
  const [filteredCompanies, setFilteredCompanies] = useState<Searchable<ClientInfo>[]>(companies);

  const handleSearch = (term: string) => {
    setFilteredCompanies(applySearch(term, companies, 2));
  };

  const refinedOrganizationCompanyGroups = useMemo(
    (): OrganizationCompaniesGroup[] =>
      getSortedOrganizationCompanyGroups(
        clients.filter((c) => c.type === "FundAdmin"),
        filteredCompanies
      ),
    [clients, filteredCompanies]
  );

  const refinedCompanies = useMemo(
    (): DataGridGroup<ClientInfo>[] =>
      refinedOrganizationCompanyGroups
        .map((group, i) => {
          return group.companies.map((company) => ({
            group: group.organization?.title || "",
            groupOrder: i,
            ...company,
            searchTerms: company.title.toLowerCase(),
          }));
        })
        .flat(),
    [refinedOrganizationCompanyGroups]
  );

  return (
    <>
      <GeneralPageHeader title="Companies">
        <Button disabled variant="contained" startIcon={<AddIcon />}>
          Add Company
        </Button>
      </GeneralPageHeader>
      <Container maxWidth={false} sx={{ m: 0, pb: 2.5, pt: 1.5, px: 3, height: "100%" }}>
        <Stack gap={2.5} height={"100%"}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
              {`${companies.length} ${companies.length === 1 ? "record" : "records"}`}
            </Typography>
            <SearchField onSearch={handleSearch} />
            <ViewSelector view={view} onChange={setView} />
          </Box>
          <Stack spacing={2} height={"100%"}>
            {view === "Grid" && <ClientsGridView groups={refinedOrganizationCompanyGroups} />}
            {view === "List" && <ClientsTableView companies={refinedCompanies} />}
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default ClientsPage;
