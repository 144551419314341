import { defined } from "../../../../../../shared/utilities/typeHelper";
import FullPageStatusProgress from "../../FullPageStatusProgress";
import { useImportCsvDataContext } from "../ImportCsvDataContext";
import ReviewImport from "./ReviewImport";

const ReviewImportStep = () => {
  const { pageState } = useImportCsvDataContext();

  if (pageState.applyImportStatus !== undefined) {
    return (
      <FullPageStatusProgress
        title="We are appyling the changes. This may take some time."
        operationStatus={pageState.applyImportStatus}
      />
    );
  }

  if (pageState.validateImportStatus !== undefined) {
    return (
      <FullPageStatusProgress
        title="We are validating the changes. This may take some time."
        operationStatus={pageState.validateImportStatus}
      />
    );
  }

  return (
    <ReviewImport
      dataImport={defined(pageState.dataImport)}
      dataImportMetadata={defined(pageState.dataImportMetadata)}
    />
  );
};

export default ReviewImportStep;
