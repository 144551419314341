import { MenuItem, Select, Stack, Typography } from "@mui/material";
import OperatorSelect from "../OperatorSelect";
import { BooleanFilter, BooleanFilterOperator, FilterValue } from "../filterTypes";
import { boolStringToValue, boolValueToString, booleanFilterOperatorOptions } from "../handlers/booleanFilter";

interface Props {
  filter: BooleanFilter;
  onUpdateValue: (newValue: FilterValue) => void;
}

const BooleanFilterEditor = ({ filter, onUpdateValue }: Props) => {
  const handleValueChange = (value: string) => {
    onUpdateValue({
      operator: "equals",
      operatorValue: boolStringToValue(value),
    });
  };

  return (
    <Stack spacing={2} p={2} sx={(t) => ({ width: t.spacing(47.5) })}>
      <OperatorSelect<BooleanFilterOperator>
        filterName={filter.name}
        options={booleanFilterOperatorOptions}
        value={filter.value.operator}
      />
      <Select
        value={boolValueToString(filter.value.operatorValue)}
        onChange={({ target }) => handleValueChange(target.value)}
      >
        <MenuItem value="Any">
          <Typography>Any</Typography>
        </MenuItem>
        <MenuItem value="Yes">
          <Typography>Yes</Typography>
        </MenuItem>
        <MenuItem value="No">
          <Typography>No</Typography>
        </MenuItem>
      </Select>
    </Stack>
  );
};

export default BooleanFilterEditor;
