import { Table, TableContainer } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  maxHeight: string;
}

const LogsTableWrapper = ({ maxHeight, children }: PropsWithChildren<Props>) => {
  return (
    <TableContainer sx={{ maxHeight: maxHeight }}>
      <Table
        stickyHeader
        sx={{
          ".MuiTableRow-root": {
            "&:hover": {
              ".MuiTableCell-root.LogsTable-actions": {
                button: { visibility: "visible" },
              },
            },
          },
          ".MuiTableCell-root.LogsTable-actions": {
            button: { visibility: "hidden" },
          },
        }}
      >
        {children}
      </Table>
    </TableContainer>
  );
};

export default LogsTableWrapper;
