import { Stack, Typography } from "@mui/material";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";
import PageViewsChart from "./PageViewsChart";

interface Props {
  row: DocumentViews;
}

const DocumentViewsGridRowDetail = ({ row }: Props) => {
  if (Object.keys(row.pageViews).length === 0) {
    return null;
  }

  return (
    <Stack px={4} pb={3} height="100%">
      <Typography variant="subtitle2" sx={{ pt: 2 }}>
        Time spent per page
      </Typography>
      <PageViewsChart pageViews={row.pageViews} />
    </Stack>
  );
};

export default DocumentViewsGridRowDetail;
