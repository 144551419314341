import { Stack, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import BadgeDetached from "../../../../../../shared/components/BadgeDetached";
import { InvoiceProcessingStatus } from "../../../../../api/adminApi";

interface Props {
  status: InvoiceProcessingStatus;
}

const InvoiceStatus = ({ status }: Props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="baseline">
      <BadgeDetached
        variant="dot"
        sx={{
          ".MuiBadge-badge": { bgcolor: getStatusColor(status) },
        }}
      />
      <Typography noWrap>{formatStatus(status)}</Typography>
    </Stack>
  );
};

export default InvoiceStatus;

const getStatusColor = (status: InvoiceProcessingStatus) => {
  switch (status) {
    case InvoiceProcessingStatus.Pending:
      return blueGrey[100];
    case InvoiceProcessingStatus.TransactionCreated:
      return "info.main";
    case InvoiceProcessingStatus.TransactionPosted:
      return "success.light";
    case InvoiceProcessingStatus.Rejected:
      return "error.main";
    default:
      return "";
  }
};

const formatStatus = (status: InvoiceProcessingStatus) => {
  switch (status) {
    case InvoiceProcessingStatus.Pending:
      return "Pending";
    case InvoiceProcessingStatus.TransactionCreated:
      return "Transaction created";
    case InvoiceProcessingStatus.TransactionPosted:
      return "Transaction posted";
    case InvoiceProcessingStatus.Rejected:
      return "Rejected";
    default:
      return "";
  }
};
