import { useState } from "react";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import adminApi from "../../../../../../api/adminApi";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import DeleteDocumentIcon from "../../../../../../../shared/icons/DeleteDocumentIcon";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";

interface Props {
  open: boolean;
  invoiceId: string;
  onCancel: () => void;
  onRejected: () => void;
}

const rejectInvoice = withErrorHandling(adminApi.rejectInvoice);

const RejectInvoiceDialog = ({ onCancel, open, invoiceId, onRejected }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const {
    invoices: { reject_invoice_dialog: locale },
  } = useLocalization();
  const [isSaving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);

    const [, error] = await rejectInvoice(invoiceId);

    if (error) {
      sendNotificationError(locale.rejecting_error);
      onCancel();
    } else {
      sendNotification(locale.rejected);
      onRejected();
    }
    setSaving(false);
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" noWrap>
            {locale.title}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Box textAlign="center" pt={3}>
          <Stack spacing={2} alignItems="center">
            <DeleteDocumentIcon />
            <Stack spacing={1}>
              <Typography variant="h6">{locale.description_primary}</Typography>
              <Typography color="secondary">{locale.description_secondary}</Typography>
            </Stack>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          {locale.cancel}
        </Button>
        <LoadingButton variant="outlined" color="error" loading={isSaving} onClick={handleSubmit}>
          {locale.submit}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RejectInvoiceDialog;
