import AddIcon from "@mui/icons-material/Add";
import { Button, FilledInputProps, Stack, TextField, Typography } from "@mui/material";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import {
  EntityFieldConfiguration,
  UserDefinedOptionsEntityFieldConfiguration,
} from "../../../../../api/types/objectTypes";
import { FieldConfigurationValidation } from "../objectFieldsState";

interface Props {
  fieldConfiguration: UserDefinedOptionsEntityFieldConfiguration;
  validation: FieldConfigurationValidation;
  isNewField: boolean;
  readOnly?: boolean;
  onChange: (fieldConfiguration: EntityFieldConfiguration) => void;
}

const UdfOptionsFieldConfigEditor = ({ fieldConfiguration, validation, isNewField, readOnly, onChange }: Props) => {
  const handleAddOption = () => {
    const userDefinedOptions = fieldConfiguration.userDefinedOptions.concat({
      value: "",
      label: "",
    });

    onChange({ ...fieldConfiguration, userDefinedOptions });
  };

  const handleEditOption =
    (index: number): FilledInputProps["onChange"] =>
    (e) => {
      const fieldValue = e.target.value;
      const userDefinedOptions = [...fieldConfiguration.userDefinedOptions];
      userDefinedOptions[index] = {
        label: fieldValue,
        value: isNewField ? fieldValue : userDefinedOptions[index]?.value ?? fieldValue,
      };

      onChange({ ...fieldConfiguration, userDefinedOptions });
    };

  const handleRemoveOption = (index: number) => () => {
    const userDefinedOptions = [...fieldConfiguration.userDefinedOptions];
    userDefinedOptions.splice(index, 1);
    onChange({ ...fieldConfiguration, userDefinedOptions });
  };

  return (
    <Stack spacing={1} pt={2} overflow="hidden">
      <Typography variant="subtitle2">Options</Typography>
      <Stack width="100%" spacing={1} overflow="auto" alignItems="flex-start">
        {fieldConfiguration.userDefinedOptions.map((option, index) => (
          <Stack key={index} width="100%" direction="row" spacing={1} alignItems="center">
            <TextField
              disabled={readOnly}
              value={option.label}
              fullWidth
              onChange={handleEditOption(index)}
              error={validation.optionsResults[index]?.isValid === false}
              helperText={validation.optionsResults[index]?.error}
            />
            {!readOnly && <CloseIconButton onClick={handleRemoveOption(index)} />}
          </Stack>
        ))}
        {!readOnly && validation.result.error && <Typography color="error">{validation.result.error}</Typography>}
        {!readOnly && (
          <Button variant="text" color="secondary" onClick={handleAddOption} startIcon={<AddIcon />}>
            Add Option
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default UdfOptionsFieldConfigEditor;
