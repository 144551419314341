import { FormControlLabel, Radio, SxProps, Theme } from "@mui/material";

interface Props<TValue> {
  value: TValue;
  disabled?: boolean;
  selectedValue: TValue | null;
  label: React.ReactNode;
  sx?: SxProps<Theme>;
}

const BorderedRadioLabel = <TValue,>({ value, disabled, selectedValue, label, sx }: Props<TValue>) => {
  return (
    <FormControlLabel
      value={value}
      disabled={disabled}
      control={<Radio />}
      label={label}
      labelPlacement="start"
      sx={{
        justifyContent: "space-between",
        ml: 0,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: selectedValue === value ? "primary.main" : "divider",
        borderRadius: "4px",
        mr: 0,
        ...sx,
      }}
    />
  );
};

export default BorderedRadioLabel;
