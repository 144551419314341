import { Chip, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { makeLighterBackgroundFromColor } from "../utilities/colorHelper";

interface Props {
  label: string;
  color: string | ((theme: Theme) => string);
  height?: "default" | "smaller";
  icon?: React.ReactElement;
  withDot?: boolean;
  tooltipText?: string;
}

const StatusChip = ({ label, color, height, icon, withDot, tooltipText }: Props) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <Chip
        icon={icon}
        label={
          withDot ? (
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="h3">•</Typography>
              <Typography variant="body2" fontWeight={500}>
                {label}
              </Typography>
            </Stack>
          ) : (
            <Typography variant="body2" fontWeight={500}>
              {label}
            </Typography>
          )
        }
        sx={(t) => {
          const resolvedColor = typeof color === "function" ? color(t) : color;
          return {
            fontWeight: 500,
            bgcolor: makeLighterBackgroundFromColor(resolvedColor),
            color: resolvedColor,
            height: height === "smaller" ? 20 : undefined,
            "& .MuiChip-icon": { color: "inherit" },
          };
        }}
      />
    </Tooltip>
  );
};

export default StatusChip;
