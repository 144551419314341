import { Stack, Typography } from "@mui/material";
import BadgeDetached from "../../../shared/components/BadgeDetached";

interface TabLabelWithCountProps {
  label: string;
  count: number;
  total: number;
  isActive?: boolean;
  hideCounter?: boolean;
}

const TabLabelWithCount = ({ label, count, total, isActive, hideCounter }: TabLabelWithCountProps) => {
  const badgeContent = count === total ? total : `${count} / ${total}`;
  return (
    <Stack direction={"row"} spacing={1} alignItems="center">
      <Typography variant="subtitle2">{label}</Typography>
      {!hideCounter && <BadgeDetached color={isActive ? "primary" : "secondary"} badgeContent={badgeContent} />}
    </Stack>
  );
};

export default TabLabelWithCount;
