import { SvgIconComponent } from "@mui/icons-material";
import { Box, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import BadgeDetached from "../../../shared/components/BadgeDetached";

interface Props {
  title: string;
  Icon?: SvgIconComponent;
  href: string;
  active?: boolean;
  subItem?: boolean;
  badgeCount?: number;
}

const MenuLinkItem = ({ href, title, Icon, active, subItem, badgeCount }: Props) => {
  const textColor = active ? "primary" : subItem ? "text.secondary" : "text.primary";

  return (
    <MenuItem
      component={RouterLink}
      to={href}
      sx={(t) => ({ py: 1, px: 2, bgcolor: active ? t.palette.action.selected : undefined })}
    >
      {Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primaryTypographyProps={{ color: textColor }}>{title}</ListItemText>
      {badgeCount !== undefined && (
        <Box>
          <BadgeDetached badgeContent={badgeCount} color="primary" />
        </Box>
      )}
    </MenuItem>
  );
};

export default MenuLinkItem;
