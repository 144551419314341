import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import CallMadeIcon from "@mui/icons-material/CallMadeRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import NumbersRoundedIcon from "@mui/icons-material/NumbersRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import TitleIcon from "@mui/icons-material/TitleRounded";
import { SvgIconProps } from "@mui/material";
import { EntityFieldType } from "../../../api/types/objectTypes";
import CheckboxFieldTypeIcon from "../../../icons/fieldTypes/CheckboxFieldTypeIcon";
import MoneyFieldTypeIcon from "../../../icons/fieldTypes/MoneyFieldTypeIcon";
import MultiSelectFieldTypeIcon from "../../../icons/fieldTypes/MultiSelectFieldTypeIcon";
import SelectFieldTypeIcon from "../../../icons/fieldTypes/SelectFieldTypeIcon";

interface Props extends SvgIconProps {
  fieldType: EntityFieldType;
}

const FieldIcon = ({ fieldType, ...iconProps }: Props) => {
  switch (fieldType) {
    case "Text": {
      return <TitleIcon {...iconProps} />;
    }
    case "Email": {
      return <MailOutlineRoundedIcon {...iconProps} />;
    }
    case "Phone": {
      return <LocalPhoneRoundedIcon {...iconProps} />;
    }
    case "Url": {
      return <LanguageRoundedIcon {...iconProps} />;
    }
    case "Number": {
      return <NumbersRoundedIcon {...iconProps} />;
    }
    case "Money": {
      return <MoneyFieldTypeIcon {...iconProps} />;
    }
    case "Percent": {
      return <PercentRoundedIcon {...iconProps} />;
    }
    case "Select":
    case "UserDefinedOptionsSelect": {
      return <SelectFieldTypeIcon {...iconProps} />;
    }
    case "MultiSelect":
    case "UserDefinedOptionsMultiSelect": {
      return <MultiSelectFieldTypeIcon {...iconProps} />;
    }
    case "Date": {
      return <CalendarTodayRoundedIcon {...iconProps} />;
    }
    case "Checkbox": {
      return <CheckboxFieldTypeIcon {...iconProps} />;
    }
    case "Lookup": {
      return <CallMadeIcon {...iconProps} />;
    }
    default: {
      return null;
    }
  }
};

export default FieldIcon;
