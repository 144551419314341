import TagIcon from "@mui/icons-material/LabelRounded";
import { FileTag, FilesViewDefinition } from "../../../api/types/fileTypes";
import FilesViewIcon from "../../common/files/FilesViewIcon";
import VerticalNavigationMenuItem from "../../common/navigation/VerticalNavigationMenuItem";
import VerticalNavigationSection from "../../common/navigation/VerticalNavigationSection";
import VerticalNavigationWrapper from "../../common/navigation/VerticalNavigationWrapper";

interface Props {
  predefinedViews: FilesViewDefinition[];
  selectedView: FilesViewDefinition | undefined;
  onSelectedViewChange: (viewName: string) => void;
  tags: FileTag[];
  selectedTag: FileTag | undefined;
  onSelectedTagChange: (tagId: string) => void;
}

const EntityCardFilesNavigation = ({
  predefinedViews,
  selectedView,
  onSelectedViewChange,
  tags,
  selectedTag,
  onSelectedTagChange,
}: Props) => {
  const handleViewItemClick = (viewName: string) => () => onSelectedViewChange(viewName);

  const handleTagItemClick = (tagId: string) => () => onSelectedTagChange(tagId);

  return (
    <VerticalNavigationWrapper>
      <VerticalNavigationSection title="Menu">
        {predefinedViews.map((view) => {
          const isSelected = selectedView?.name === view.name;
          return (
            <VerticalNavigationMenuItem
              key={view.name}
              text={view.title}
              Icon={<FilesViewIcon viewName={view.name} color={isSelected ? "primary" : "secondary"} />}
              selected={isSelected}
              onClick={handleViewItemClick(view.name)}
            />
          );
        })}
      </VerticalNavigationSection>

      {tags.length > 0 && (
        <VerticalNavigationSection title="Tags">
          {tags.map((tag) => {
            const isSelected = selectedTag?.catalogueId === tag.catalogueId;
            return (
              <VerticalNavigationMenuItem
                key={tag.catalogueId}
                text={tag.name}
                Icon={<TagIcon color={isSelected ? "primary" : "secondary"} />}
                selected={isSelected}
                onClick={handleTagItemClick(tag.catalogueId)}
              />
            );
          })}
        </VerticalNavigationSection>
      )}
    </VerticalNavigationWrapper>
  );
};

export default EntityCardFilesNavigation;
