import { createSvgIcon } from "@mui/material";

const ApiReportIcon = createSvgIcon(
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2912_4835)">
      <path
        d="M8 0H72C76.4 0 80 3.6 80 8V72C80 76.4 76.4 80 72 80H8C3.6 80 0 76.4 0 72V8C0 3.6 3.6 0 8 0Z"
        fill="white"
      />
      <path
        d="M64.9999 69.7001H16.8999V10.3H52.7999L65.0999 22.6V69.7001H64.9999ZM21.8999 64.7001H59.9999V24.6L50.6999 15.3H21.8999V64.7001Z"
        fill="#C7DBE5"
      />
      <path d="M62.5 27.8H48V12.8H53V22.8H62.5V27.8Z" fill="#C7DBE5" />
      <path d="M38.0711 36.5355L34.5356 33L24.3345 43.2011L27.87 46.7366L38.0711 36.5355Z" fill="#00A866" />
      <path d="M44.0001 36.5355L47.5356 33L57.7368 43.2011L54.2013 46.7366L44.0001 36.5355Z" fill="#00A866" />
      <path d="M37.9999 50.4642L27.5356 40L24.0001 43.5355L34.4644 53.9997L37.9999 50.4642Z" fill="#00A866" />
      <path d="M44.0714 50.4642L54.5356 40L58.0711 43.5355L47.6069 53.9997L44.0714 50.4642Z" fill="#00A866" />
      <rect x="48" y="57" width="30" height="20" rx="10" fill="#E0F5ED" />
      <path
        d="M58.0059 63.9697L55.9795 70H54.5586L57.2148 62.8906H58.123L58.0059 63.9697ZM59.7002 70L57.6641 63.9697L57.542 62.8906H58.4551L61.126 70H59.7002ZM59.6074 67.3584V68.418H55.7939V67.3584H59.6074ZM64.7328 67.4072H62.9017V66.3525H64.7328C65.0323 66.3525 65.2748 66.3037 65.4603 66.2061C65.6491 66.1051 65.7875 65.9684 65.8754 65.7959C65.9632 65.6201 66.0072 65.4199 66.0072 65.1953C66.0072 64.9772 65.9632 64.7738 65.8754 64.585C65.7875 64.3962 65.6491 64.2432 65.4603 64.126C65.2748 64.0088 65.0323 63.9502 64.7328 63.9502H63.3412V70H61.9984V62.8906H64.7328C65.2862 62.8906 65.7582 62.9899 66.1488 63.1885C66.5427 63.3838 66.8421 63.6556 67.0472 64.0039C67.2556 64.349 67.3597 64.7428 67.3597 65.1855C67.3597 65.6445 67.2556 66.04 67.0472 66.3721C66.8421 66.7041 66.5427 66.9596 66.1488 67.1387C65.7582 67.3177 65.2862 67.4072 64.7328 67.4072ZM69.9851 62.8906V70H68.6423V62.8906H69.9851Z"
        fill="#00A866"
      />
    </g>
    <defs>
      <clipPath id="clip0_2912_4835">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "ApiReportIcon"
);

export default ApiReportIcon;
