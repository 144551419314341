import { Box, MenuList, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  title: string;
}

const VerticalNavigationSection = ({ title, children }: PropsWithChildren<Props>) => (
  <Box pl={1}>
    <Typography variant="subtitle2">{title}</Typography>
    <MenuList>{children}</MenuList>
  </Box>
);

export default VerticalNavigationSection;
