import TemplatesMenu from "../../../../../shared/reporting/components/reportTemplates/TemplatesMenu";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import OrganizationTemplatesPageHeader from "./OrganizationTemplatesPageHeader";
import PageTabs from "../../../common/PageTabs";
import { useMemo, useState } from "react";
import { TabPanel } from "@mui/lab";
import { defined } from "../../../../../shared/utilities/typeHelper";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import OrganizationTemplatesGrid from "./grid/OrganizationTemplatesGrid";
import { useReportTemplatesContext } from "../../../../../shared/reporting/contexts/ReportTemplatesContext";
import { FundType, ReportTemplateDto, Status } from "../../../../../shared/reporting/api/biClient.types";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useGroupsContext } from "../../../../../shared/reporting/components/groups/contexts/GroupsContext";
import { useClientContext } from "../../../../context/ClientContext";
import NewTemplateDialog from "./dialogs/NewTemplateDialog";
import { useUserContext } from "../../../../context/UserContext";
import { getEditReportTemplateUrl } from "../reports/utilities/editReportUrl";
import { getAccessibleCompanies } from "../../../../../shared/reporting/components/reportTemplates/helpers/reportTemplateHelper";
import { useSharedReportingLocalization } from "../../../../../shared/reporting/hooks/useLocalization";

const tabs = ["all_status", "live", "draft"] as const;
type Tabs = (typeof tabs)[number];
const labels = ["All Status", "Live", "Draft"];
const tabPanelSx = { height: "100%", width: "100%", m: 0, px: 3, py: 2.5 };

export default function OrganizationTemplatesContainer() {
  const [selectedTab, setSelectedTab] = useState<Tabs>("all_status");
  const [selectedField, setSelectedFilter] = useState<{
    fieldValue?: string;
    fieldName?: string;
  }>();
  const [searchText, setSearchText] = useState("");
  const [createNewTemplate, setCreateNewTemplate] = useState(false);
  const { report_templates: locale } = useSharedReportingLocalization();
  const { clientCode } = useClientContext();
  const { reportTemplates, ui } = useReportTemplatesContext();
  const { groups } = useGroupsContext();
  const { clients, permissions } = useUserContext();
  const companies = useMemo(
    () => getAccessibleCompanies(clients, permissions, clientCode),
    [clients, permissions, clientCode]
  );
  const readonly = companies.length === 0;

  const refinedTemplates = useMemo(() => {
    return reportTemplates.map((r): ReportTemplateDto => {
      const rep = { ...r } as ReportTemplateDto;
      const group = defined(groups[clientCode]).find((rg) => rg.id === rep.groupId);
      rep.groupName = group?.caption || "";
      rep.groupOrder = group?.order || Number.MAX_VALUE;
      return rep;
    });
  }, [reportTemplates, groups, clientCode]);

  const templatesFilteredByStatus = useMemo(() => {
    switch (selectedTab) {
      case "live":
        return refinedTemplates.filter((r) => r.status === Status.Live);
      case "draft":
        return refinedTemplates.filter((r) => r.status === Status.Draft);
      default:
        return refinedTemplates;
    }
  }, [selectedTab, refinedTemplates]);

  const templatesByStatus = useMemo(() => {
    const all = refinedTemplates
      .filter(
        (rep) => rep.name.toLowerCase().indexOf(searchText) > -1 || rep.groupName.toLowerCase().indexOf(searchText) > -1
      )
      .filter((rep) => filterPredicate(rep, selectedField?.fieldName, selectedField?.fieldValue));
    const live = all.filter((r) => r.status === Status.Live);
    const draft = all.filter((r) => r.status === Status.Draft);
    return { all, live, draft } as const;
  }, [searchText, refinedTemplates, selectedField]);

  const totalRecordsLabel = useMemo(() => {
    let totalRecordsCount = 0;
    switch (selectedTab) {
      case "all_status":
        totalRecordsCount = templatesByStatus.all.length;
        break;
      case "live":
        totalRecordsCount = templatesByStatus.live.length;
        break;
      case "draft":
        totalRecordsCount = templatesByStatus.draft.length;
        break;
    }
    return `${totalRecordsCount} record${totalRecordsCount !== 1 ? "(s)" : ""}`;
  }, [selectedTab, templatesByStatus]);

  const TabPanels = useMemo(() => {
    const tabPanelData = [
      { value: tabs[0], templates: templatesByStatus.all },
      { value: tabs[1], templates: templatesByStatus.live },
      { value: tabs[2], templates: templatesByStatus.draft },
    ];
    return tabPanelData.map((tabPanel, i) => {
      return (
        <TabPanel key={i} value={tabPanel.value} sx={tabPanelSx}>
          <OrganizationTemplatesGrid templates={tabPanel.templates} companies={companies} readonly={readonly} />
        </TabPanel>
      );
    });
  }, [templatesByStatus, companies, readonly]);

  if (ui.loaded === false) {
    return (
      <Stack sx={{ height: "100%", flex: 1 }}>
        <InlineLoader text={locale.loading_label} />
      </Stack>
    );
  }

  return (
    <Grid container sx={{ height: "100%", flexWrap: "nowrap" }}>
      <Grid
        item
        id="side-menu"
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          py: 2.5,
          pr: 2.5,
          pl: 3,
          width: "100%",
          maxWidth: theme.spacing(35),
          gap: 2.5,
        })}
      >
        <Typography variant="subtitle2">Template menu</Typography>
        <TemplatesMenu
          displayingTemplates={templatesFilteredByStatus}
          allTemplatesList={refinedTemplates}
          onSelect={(item) => setSelectedFilter({ fieldValue: item?.value, fieldName: item?.fieldName })}
        />
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <OrganizationTemplatesPageHeader onAddTemplate={() => setCreateNewTemplate(true)} readonly={readonly} />
        <PageTabs tabs={tabs} labels={labels} value={selectedTab} onChange={setSelectedTab}>
          <Grid container sx={{ justifyContent: "space-between", pt: 2.5, px: 3, alignItems: "center" }}>
            <Typography variant="subtitle2">{totalRecordsLabel}</Typography>
            <SearchField onSearch={(text) => setSearchText(text.toLowerCase())} debounceTimeMs={300} />
          </Grid>
          {TabPanels}
        </PageTabs>
      </Grid>
      {createNewTemplate && (
        <NewTemplateDialog
          companies={companies}
          onClose={() => setCreateNewTemplate(false)}
          onCreate={(dataSource) => {
            setCreateNewTemplate(false);
            window.open(getEditReportTemplateUrl(clientCode, dataSource, "new"), "_blank", "noopener noreferrer");
          }}
        />
      )}
    </Grid>
  );
}

function filterPredicate(template: ReportTemplateDto, fieldName?: string, fieldValue?: string) {
  switch (fieldName) {
    case "Fund Types":
      return template.fundTypes.includes(fieldValue as FundType);
    case "Groups":
      return template.groupName === fieldValue;
    default:
      return true;
  }
}
