import { useCallback, useState } from "react";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import PromptDialog from "../../../../../../shared/components/dialog/PromptDialog";
import { useSharedReportingLocalization } from "../../../../../../shared/reporting/hooks/useLocalization";
import SaveReportForm from "../../../../../../shared/reporting/components/reports/SaveReportForm";
import { formatString } from "../../../../../../shared/utilities/stringFormatter";
import { useUiReportsContext } from "./contexts/ReportsContext";
import OpenGeneratedApiReport from "./OpenGeneratedApiReport";

interface Props {
  report: ReportInfo;
  generateReport: (report: ReportInfo, name: string) => Promise<ReportInfo | undefined>;
  onChanged: (reportId: string) => void;
  onClose: () => void;
}

const GenerateApiReport = ({ report, generateReport: generateReport, onChanged, onClose }: Props) => {
  const { report: locale } = useSharedReportingLocalization();
  const [generatedApiReport, setGeneratedApiReport] = useState<ReportInfo>();
  const {
    actions: { checkUniqueReportNameByUsage },
  } = useUiReportsContext();

  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const validateReportName = useCallback(
    async (name: string) => {
      setSaving(true);
      try {
        const isUnique = await checkUniqueReportNameByUsage(
          { reportId: undefined, name, clientCode: report.clientCode },
          "Api"
        );
        if (isUnique) {
          return true;
        } else {
          setError(formatString(locale.name_exist, name));
          return false;
        }
      } catch (e) {
        setError(locale.generating_api_report_error);
        return false;
      } finally {
        setSaving(false);
      }
    },
    [locale.name_exist, locale.generating_api_report_error, checkUniqueReportNameByUsage, report.clientCode]
  );

  const onSaveClicked = useCallback(
    async (name: string) => {
      if (await validateReportName(name)) {
        setSaving(true);

        generateReport(report, name)
          .then((response) => {
            onChanged(response?.reportId || "");
            setGeneratedApiReport(response);
          })
          .catch(() => {
            setError(locale.generating_api_report_error);
          })
          .finally(() => setSaving(false));
      }
    },
    [generateReport, report, onChanged, locale.generating_api_report_error, validateReportName]
  );

  return (
    <PromptDialog open={true}>
      {!generatedApiReport && (
        <SaveReportForm
          report={report}
          error={error}
          title={"Generate API Report"}
          saveBtnTitle={"Save"}
          saving={saving}
          showName
          onClose={onClose}
          onSave={onSaveClicked}
        />
      )}
      {generatedApiReport && <OpenGeneratedApiReport onClose={onClose} report={generatedApiReport} />}
    </PromptDialog>
  );
};

export default GenerateApiReport;
