import { Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

export function getItemSx(
  selectable: boolean | undefined,
  active: boolean | undefined,
  inProgress: boolean,
  theme: Theme
): SystemStyleObject<Theme> {
  return {
    borderColor: selectable && active ? theme.palette.primary.main : theme.palette.divider,
    cursor: selectable ? "pointer" : "default",
    "&:hover": {
      ...(selectable && !active ? { borderColor: "secondary.light" } : {}),
    },
    opacity: inProgress ? 0.7 : 1,
  };
}
