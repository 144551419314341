import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Contact } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import ContactCreateDialog from "../common/ContactCreateDialog";

interface Props {
  contacts: Contact[];
  isLoading: boolean;
}

const ContactsActionsToolbar = ({ contacts, isLoading }: Props) => {
  const { hasAnyPermission } = useClientContext();
  const navigate = useNavigate();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const hasAccessToManage = hasAnyPermission(["ManageFundStructure"]);

  return (
    <>
      {hasAccessToManage && (
        <Button
          sx={{ whiteSpace: "nowrap" }}
          onClick={() => setOpenCreateDialog(true)}
          variant="contained"
          startIcon={<AddIcon />}
          disabled={isLoading}
        >
          New Contact
        </Button>
      )}
      <ContactCreateDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        onNewContactCreated={(contactId) => {
          navigate(`./${contactId}/main`);
        }}
        contacts={contacts}
      />
    </>
  );
};

export default ContactsActionsToolbar;
