import deepEqual from "fast-deep-equal";
import {
  SortCompareFn,
  combineComparers,
  priorityComparer,
  stringComparerBy,
} from "../../../../../shared/utilities/arrayHelper";
import {
  ValidationResult,
  Validator,
  combineValidators,
  invalidResult,
  maxCharactersValidator,
  nonEmptyArrayValidator,
  regexValidator,
  requiredValidator,
  uniqueValidator,
  validResult,
} from "../../../../../shared/utilities/validators";
import {
  EntityFieldConfiguration,
  EntityFieldType,
  Field,
  LookupFieldConfiguration,
  ObjectClassDefinition,
  UserDefinedOptionsEntityFieldConfiguration,
} from "../../../../api/types/objectTypes";
import { getDefaultFieldConfigForUdf } from "./field-editor/userDefinedFieldConfigurations";

export interface ObjectFieldsState {
  allRows: FieldRow[];
  filteredRows: FieldRow[];
  searchValue: string;
  openDialog?: "edit_field" | "select_field_type" | "delete_field";
  editedField?: FieldRow;
  deletedField?: FieldRow;
  fieldEditForm: FieldEditForm;
}

export interface FieldRow extends Field {
  isHighlighted?: boolean;
}

export interface FieldEditForm {
  name: string;
  fieldType: EntityFieldType;
  fieldConfiguration: EntityFieldConfiguration | undefined;
  validation: FieldEditFormValidation;
}

export interface FieldEditFormValidation {
  name: ValidationResult;
  fieldConfiguration: FieldConfigurationValidation;
}

export interface FieldConfigurationValidation {
  result: ValidationResult;
  optionsResults: (ValidationResult | undefined)[];
}

type StateAction = (state: ObjectFieldsState) => ObjectFieldsState;

const sortComparer: SortCompareFn<FieldRow> = combineComparers(
  priorityComparer((r) => r.source !== "UserDefined"),
  priorityComparer((r) => r.attributes.includes("ObjectIdentifier")),
  stringComparerBy((r) => r.name.toLowerCase())
);

export const getInitialState = (object: ObjectClassDefinition, highlightedFieldId?: string): ObjectFieldsState => {
  const rows: FieldRow[] = object.fields
    .map((field) => ({
      ...field,
      isHighlighted: field.id === highlightedFieldId,
    }))
    .sort(sortComparer);

  return {
    allRows: rows,
    filteredRows: rows,
    searchValue: "",
    fieldEditForm: {
      name: "",
      fieldType: "Text",
      fieldConfiguration: undefined,
      validation: {
        name: invalidResult(""),
        fieldConfiguration: {
          result: validResult(),
          optionsResults: [],
        },
      },
    },
  };
};

// Validation

export const maxFieldNameLength = 50;

const maxOptionLength = 100;

const validateLookupFieldConfiguration = (
  fieldConfiguration: LookupFieldConfiguration
): FieldConfigurationValidation => {
  return { result: requiredValidator(fieldConfiguration.objectType), optionsResults: [] };
};

const validateOptionsFieldConfiguration = (
  fieldConfiguration: UserDefinedOptionsEntityFieldConfiguration
): FieldConfigurationValidation => {
  const fieldConfigurationResult: FieldConfigurationValidation = { result: validResult(), optionsResults: [] };

  fieldConfigurationResult.result = nonEmptyArrayValidator("At least one option is required")(
    fieldConfiguration.userDefinedOptions
  );

  if (fieldConfigurationResult.result.isValid) {
    fieldConfigurationResult.optionsResults = [];

    const existingSelectOptionLabels = fieldConfiguration.userDefinedOptions.map((o) => o.label);
    const existingSelectOptionValues = fieldConfiguration.userDefinedOptions.map((o) => o.value);

    fieldConfiguration.userDefinedOptions.forEach((option, index) => {
      const otherOptionLabels = existingSelectOptionLabels.filter((_, i) => i !== index);
      const otherOptionValues = existingSelectOptionValues.filter((_, i) => i !== index);

      const selectOptionValidator: Validator<string> = combineValidators(
        requiredValidator,
        maxCharactersValidator(maxOptionLength),
        regexValidator(/^[a-zA-Z0-9\s]+$/, "Option value can only contain letters, numbers and spaces"),
        uniqueValidator("Options must be unique", otherOptionLabels),
        uniqueValidator("Such underlying option value already exists", otherOptionValues)
      );

      fieldConfigurationResult.optionsResults[index] = selectOptionValidator(option.label);
    });
  }

  return fieldConfigurationResult;
};

const validateFieldConfiguration = (
  fieldConfiguration: EntityFieldConfiguration | undefined
): FieldConfigurationValidation => {
  switch (fieldConfiguration?.$type) {
    case "UserDefinedOptionsSelect":
      return validateOptionsFieldConfiguration(fieldConfiguration);
    case "Lookup":
      return validateLookupFieldConfiguration(fieldConfiguration);
    default:
      return { result: validResult(), optionsResults: [] };
  }
};

const validateForm = (
  form: FieldEditForm,
  allRows: FieldRow[],
  editedField: FieldRow | undefined
): FieldEditFormValidation => {
  const existingFieldNames = allRows.filter((f) => f.id !== editedField?.id).map((f) => f.name);

  const validateFieldName: Validator<string> = combineValidators(
    requiredValidator,
    uniqueValidator("A field with this name already exists", existingFieldNames),
    regexValidator(/^[a-zA-Z0-9\s]+$/, "Name can only contain letters, numbers and spaces"),
    maxCharactersValidator(maxFieldNameLength)
  );

  return {
    name: validateFieldName(form.name),
    fieldConfiguration: validateFieldConfiguration(form.fieldConfiguration),
  };
};

// Selectors

export const getSanitizedForm = (form: FieldEditForm, sortOptions?: boolean): FieldEditForm => {
  const fieldConfiguration = form.fieldConfiguration;

  if (fieldConfiguration?.$type === "UserDefinedOptionsSelect") {
    fieldConfiguration.userDefinedOptions = fieldConfiguration.userDefinedOptions.filter(({ value, label }) => ({
      value: value.trim(),
      label: label.trim(),
    }));

    if (sortOptions) {
      fieldConfiguration.userDefinedOptions.sort(stringComparerBy((o) => o.label.toLowerCase()));
    }
  }

  return {
    ...form,
    name: form.name.trim(),
    fieldConfiguration,
  };
};

export const isEditFormDirty = (form: FieldEditForm, editedField: FieldRow): boolean => {
  const sanitizedForm = getSanitizedForm(form);
  return (
    sanitizedForm.name.trim() !== editedField.name.trim() ||
    !deepEqual(sanitizedForm.fieldConfiguration, editedField.configuration)
  );
};

export const isEditFormValid = (form: FieldEditForm): boolean =>
  form.validation.name.isValid &&
  form.validation.fieldConfiguration.result.isValid &&
  form.validation.fieldConfiguration.optionsResults.every((r) => r?.isValid !== false);

// Actions

export const closeDialogAction = (): StateAction => (state) => ({
  ...state,
  openDialog: undefined,
  editedField: undefined,
  deletedField: undefined,
});

export const searchAction =
  (searchValue: string): StateAction =>
  (state) => {
    const searchTerm = searchValue.trim();
    const filteredRows = searchTerm
      ? state.allRows.filter((field) => field.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : [...state.allRows];
    return {
      ...state,
      searchValue,
      filteredRows,
    };
  };

export const openFieldTypeSelectorAction = (): StateAction => (state) => ({
  ...state,
  openDialog: "select_field_type",
  editedField: undefined,
  deletedField: undefined,
});

export const createFieldAction =
  (fieldType: EntityFieldType): StateAction =>
  (state) => ({
    ...state,
    openDialog: "edit_field",
    editedField: undefined,
    deletedField: undefined,
    fieldEditForm: {
      name: "",
      fieldType,
      fieldConfiguration: getDefaultFieldConfigForUdf(fieldType),
      validation: {
        name: invalidResult(""),
        fieldConfiguration: {
          result:
            fieldType === "UserDefinedOptionsSelect" || fieldType === "UserDefinedOptionsMultiSelect"
              ? invalidResult("")
              : validResult(),
          optionsResults: [],
        },
      },
    },
  });

export const editFieldAction =
  (row: FieldRow): StateAction =>
  (state) => ({
    ...state,
    openDialog: "edit_field",
    editedField: row,
    deletedField: undefined,
    fieldEditForm: {
      name: row.name,
      fieldType: row.type,
      fieldConfiguration: row.configuration ? { ...row.configuration } : undefined,
      validation: {
        name: validResult(),
        fieldConfiguration: {
          result: validResult(),
          optionsResults: [],
        },
      },
    },
  });

export const deleteFieldAction =
  (row: FieldRow): StateAction =>
  (state) => ({
    ...state,
    openDialog: "delete_field",
    deletedField: row,
    editedField: undefined,
  });

export const updateEditFormAction =
  (formUpdate: Partial<FieldEditForm>): StateAction =>
  (state) => {
    const fieldEditForm = {
      ...state.fieldEditForm,
      ...formUpdate,
    };

    fieldEditForm.validation = validateForm(fieldEditForm, state.allRows, state.editedField);

    return {
      ...state,
      fieldEditForm,
    };
  };

export const updateFieldAction =
  (fieldId: string, object: ObjectClassDefinition): StateAction =>
  () =>
    getInitialState(object, fieldId);
