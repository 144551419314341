import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";

interface Props {
  entityLevelAccessEnabled: boolean;
  isLoading: boolean;
  disabled?: boolean;
  onChange: (enabled: boolean) => void;
}

const EntityLevelAccess = ({ entityLevelAccessEnabled, onChange, isLoading, disabled }: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Entity level access</Typography>
      <Typography>
        Entity level access allows restricting user access to specific funds’ information in the system.{" "}
      </Typography>
      <FormControlLabel
        control={
          <Switch
            disabled={isLoading || disabled}
            checked={entityLevelAccessEnabled}
            onChange={(e) => onChange(e.target.checked)}
          />
        }
        label={<Typography>Enable entity level access</Typography>}
      />
    </Stack>
  );
};

export default EntityLevelAccess;
