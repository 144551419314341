import {
  BooleanFieldValue,
  BooleanFilterDefinition,
  BooleanFilterOperator,
  BooleanFilterValue,
  FilterHandler,
  OperatorOption,
} from "../filterTypes";

export const boolValueToString = (value: boolean | undefined) => (value === undefined ? "Any" : value ? "Yes" : "No");
export const boolStringToValue = (str: string) => (str === "Yes" ? true : str === "No" ? false : undefined);

export const booleanFilterOperatorOptions: readonly OperatorOption<BooleanFilterOperator>[] = [
  { label: "is", value: "equals" },
] as const;

export const booleanFilterHandler: FilterHandler<BooleanFilterValue, BooleanFieldValue, BooleanFilterDefinition> = {
  getDefaultValue: () => ({
    operator: "equals",
    operatorValue: undefined,
  }),

  isActive: ({ operatorValue }: BooleanFilterValue) => operatorValue !== undefined,

  formatValue: ({ operatorValue }: BooleanFilterValue) => `is ${boolValueToString(operatorValue)}`,

  matchesFieldValue: ({ operatorValue }: BooleanFilterValue, fieldValue: BooleanFieldValue) =>
    operatorValue === undefined || operatorValue === fieldValue,
};
