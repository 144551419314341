import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../shared/api/axiosHelper";
import HtmlEditor from "../../../shared/components/htmlEditor/HtmlEditor";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import adminApi from "../../api/adminApi";
import { Note } from "../../api/types/notesTypes";
import { useNotesContext } from "./NotesContext";

interface Props {
  id?: string;
  initialContent: string;
  onSaved: (id: string | undefined, note: Note) => void;
  onCancel: () => void;
}

const createNote = withErrorHandling(adminApi.createNote);
const updateNote = withErrorHandling(adminApi.updateNote);

const htmlEditorToolbar = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  ["clean"],
];

const NoteEditor = ({ id, initialContent, onSaved, onCancel }: Props) => {
  const { entityType, entityId, objectType } = useNotesContext();
  const { sendNotificationError } = useNotificationContext();
  const [htmlContent, setHtmlContent] = useState<string>(initialContent);
  const [saving, setSaving] = useState(false);

  const handleSaveClick = async () => {
    setSaving(true);

    const isNewNote = !id;
    const [savedNote, error] = isNewNote
      ? await createNote(entityType, entityId, { htmlContent }, objectType)
      : await updateNote(entityType, entityId, id, { htmlContent }, objectType);

    setSaving(false);

    if (error) {
      sendNotificationError("Failed to save the note");
    } else {
      onSaved(id, savedNote);
    }
  };

  const handleCancelClick = () => onCancel();

  const isSaveDisabled = initialContent.trim() === htmlContent.trim();

  return (
    <Box py={1}>
      <HtmlEditor
        initialHtmlContent={initialContent}
        onChange={(contentValue) => setHtmlContent(contentValue)}
        styles={{ editor: { minHeight: "7rem" } }}
        toolbar={htmlEditorToolbar}
      />
      <Stack direction="row" spacing={2} mt={2}>
        <LoadingButton loading={saving} disabled={isSaveDisabled} variant="contained" onClick={handleSaveClick}>
          Save
        </LoadingButton>
        <Button variant="text" color="secondary" onClick={handleCancelClick}>
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};

export default NoteEditor;
