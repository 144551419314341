import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { parseSearchParamWithFallback } from "../../../../shared/utilities/searchParamsHelper";
import { approvalTypes } from "../../../api/mock/approvals/approvalsTypes";
import ApprovalsNavigation from "./ApprovalsNavigation";
import ApprovalsView from "./ApprovalsView";
import { ApprovalsFilter, NavigationView, approvalsFilters } from "./approvalsPageHelper";

const ApprovalsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedView, setSelectedView] = useState<NavigationView>(
    parseSearchParamWithFallback(searchParams, "view", approvalTypes, "All")
  );
  const [selectedTab, setSelectedTab] = useState<ApprovalsFilter>(
    parseSearchParamWithFallback(searchParams, "tab", approvalsFilters, "my")
  );

  const handleViewChange = (view: NavigationView) => {
    setSelectedView(view);
    setSearchParams({ view, tab: selectedTab });
  };

  const handleTabChange = (tab: ApprovalsFilter) => {
    setSelectedTab(tab);
    setSearchParams({ view: selectedView, tab });
  };

  return (
    <Box display="flex" overflow="hidden" height="100%">
      <ApprovalsNavigation selectedView={selectedView} onSelectedViewChange={handleViewChange} />
      <Divider flexItem orientation="vertical" />
      <ApprovalsView view={selectedView} selectedTab={selectedTab} onTabChange={handleTabChange} />
    </Box>
  );
};

export default ApprovalsPage;
