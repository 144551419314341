import { SvgIcon, SvgIconProps } from "@mui/material";

const MultiSelectFieldTypeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M11.6668 7.50009C11.4307 7.50009 11.2328 7.42023 11.0731 7.26051C10.9134 7.10079 10.8335 6.90287 10.8335 6.66676C10.8335 6.43065 10.9134 6.23273 11.0731 6.07301C11.2328 5.91329 11.4307 5.83342 11.6668 5.83342H17.5002C17.7363 5.83342 17.9342 5.91329 18.0939 6.07301C18.2536 6.23273 18.3335 6.43065 18.3335 6.66676C18.3335 6.90287 18.2536 7.10079 18.0939 7.26051C17.9342 7.42023 17.7363 7.50009 17.5002 7.50009H11.6668ZM11.6668 14.1668C11.4307 14.1668 11.2328 14.0869 11.0731 13.9272C10.9134 13.7675 10.8335 13.5695 10.8335 13.3334C10.8335 13.0973 10.9134 12.8994 11.0731 12.7397C11.2328 12.58 11.4307 12.5001 11.6668 12.5001H17.5002C17.7363 12.5001 17.9342 12.58 18.0939 12.7397C18.2536 12.8994 18.3335 13.0973 18.3335 13.3334C18.3335 13.5695 18.2536 13.7675 18.0939 13.9272C17.9342 14.0869 17.7363 14.1668 17.5002 14.1668H11.6668ZM4.04183 8.58342L2.25016 6.79176C2.09738 6.63898 2.021 6.44454 2.021 6.20842C2.021 5.97231 2.09738 5.77787 2.25016 5.62509C2.40294 5.47231 2.59738 5.39592 2.8335 5.39592C3.06961 5.39592 3.26405 5.47231 3.41683 5.62509L4.60433 6.81259L7.56266 3.85426C7.72933 3.68759 7.92377 3.60773 8.146 3.61467C8.36822 3.62162 8.56266 3.70842 8.72933 3.87509C8.88211 4.04176 8.9585 4.2362 8.9585 4.45842C8.9585 4.68065 8.88211 4.87509 8.72933 5.04176L5.2085 8.58342C5.04183 8.75009 4.84738 8.83342 4.62516 8.83342C4.40294 8.83342 4.2085 8.75009 4.04183 8.58342ZM4.04183 15.2501L2.25016 13.4584C2.09738 13.3056 2.021 13.1112 2.021 12.8751C2.021 12.639 2.09738 12.4445 2.25016 12.2918C2.40294 12.139 2.59738 12.0626 2.8335 12.0626C3.06961 12.0626 3.26405 12.139 3.41683 12.2918L4.60433 13.4793L7.56266 10.5209C7.72933 10.3543 7.92377 10.2744 8.146 10.2813C8.36822 10.2883 8.56266 10.3751 8.72933 10.5418C8.88211 10.7084 8.9585 10.9029 8.9585 11.1251C8.9585 11.3473 8.88211 11.5418 8.72933 11.7084L5.2085 15.2501C5.04183 15.4168 4.84738 15.5001 4.62516 15.5001C4.40294 15.5001 4.2085 15.4168 4.04183 15.2501Z"
      fill="#8E9BA9"
    />
  </SvgIcon>
);

export default MultiSelectFieldTypeIcon;
