import { Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Validator } from "../../utilities/validators";
import CopyToBufferButton from "./CopyToBufferButton";

interface Props {
  beginDisabledText?: string;
  text: string;
  onChange?: (text: string) => void;
  description?: string | JSX.Element;
  disabled?: boolean;
  addOpenButtonForUrl?: boolean;
  inputValidator?: Validator<string>;
  disableCopyIfNotValid?: boolean;
  label?: string;
}

const CopyToBufferInput = ({
  beginDisabledText,
  addOpenButtonForUrl,
  disabled,
  text,
  onChange,
  description,
  inputValidator,
  disableCopyIfNotValid,
  label,
}: Props) => {
  const fullText = `${beginDisabledText || ""}${text}`;

  const handleOpenClick = () => {
    window.open(fullText, "_blank");
  };

  const isValid = !inputValidator || inputValidator(text).isValid;

  const InputProps = {
    startAdornment: (
      <InputAdornment position="start" sx={{ mr: "-.01em" }}>
        {beginDisabledText && (
          <Typography noWrap color="secondary">
            {beginDisabledText}
          </Typography>
        )}
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        {addOpenButtonForUrl && (
          <Button variant="text" sx={{ mr: 1 }} onClick={handleOpenClick}>
            Open
          </Button>
        )}

        {disableCopyIfNotValid && !isValid ? null : <CopyToBufferButton textToCopy={fullText} />}
      </InputAdornment>
    ),
  };

  return (
    <Stack width="100%">
      <TextField
        variant="outlined"
        color={isValid ? "primary" : "error"}
        fullWidth
        disabled={disabled}
        label={label}
        value={text}
        InputProps={InputProps}
        onChange={(e) => onChange?.(e.target.value)}
        sx={{ fontWeight: 500 }}
      />
      {typeof description === "string" && (
        <Typography color="secondary" variant="caption" mt={0.5}>
          {description}
        </Typography>
      )}
      {typeof description !== "string" && description}
    </Stack>
  );
};

export default CopyToBufferInput;
