import React, { Dispatch, PropsWithChildren, SetStateAction, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";

const DocumentCollectionContext = React.createContext<ContextValue | undefined>(undefined);

interface ContextValue {
  sentInvites: Record<string, Date>;
  setSentInvites: Dispatch<SetStateAction<Record<string, Date>>>;
}

interface Props {
  value: ContextValue;
}

export const DocumentCollectionContextProvider = ({ value, children }: PropsWithChildren<Props>) => (
  <DocumentCollectionContext.Provider value={value}>{children}</DocumentCollectionContext.Provider>
);

export const useDocumentCollectionContext = () => {
  const value = useContext(DocumentCollectionContext);
  return defined(value);
};
