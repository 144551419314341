import { createSvgIcon } from "@mui/material";

const NoteIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor">
    <path d="M4.16667 15.8333H11.6667V12.5C11.6667 12.2639 11.7465 12.066 11.9062 11.9062C12.066 11.7465 12.2639 11.6667 12.5 11.6667H15.8333V4.16667H4.16667V15.8333ZM4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V11.8125C17.5 12.0347 17.4583 12.2465 17.375 12.4479C17.2917 12.6493 17.1736 12.8264 17.0208 12.9792L12.9792 17.0208C12.8264 17.1736 12.6493 17.2917 12.4479 17.375C12.2465 17.4583 12.0347 17.5 11.8125 17.5H4.16667ZM9.16667 11.6667H6.66667C6.43056 11.6667 6.23264 11.5868 6.07292 11.4271C5.91319 11.2674 5.83333 11.0694 5.83333 10.8333C5.83333 10.5972 5.91319 10.3993 6.07292 10.2396C6.23264 10.0799 6.43056 10 6.66667 10H9.16667C9.40278 10 9.60069 10.0799 9.76042 10.2396C9.92014 10.3993 10 10.5972 10 10.8333C10 11.0694 9.92014 11.2674 9.76042 11.4271C9.60069 11.5868 9.40278 11.6667 9.16667 11.6667ZM13.3333 8.33333H6.66667C6.43056 8.33333 6.23264 8.25347 6.07292 8.09375C5.91319 7.93403 5.83333 7.73611 5.83333 7.5C5.83333 7.26389 5.91319 7.06597 6.07292 6.90625C6.23264 6.74653 6.43056 6.66667 6.66667 6.66667H13.3333C13.5694 6.66667 13.7674 6.74653 13.9271 6.90625C14.0868 7.06597 14.1667 7.26389 14.1667 7.5C14.1667 7.73611 14.0868 7.93403 13.9271 8.09375C13.7674 8.25347 13.5694 8.33333 13.3333 8.33333Z" />
  </svg>,
  "NoteIcon"
);

export default NoteIcon;
