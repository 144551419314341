import { pageRoutes } from "../../../../routes";
import ObjectsListPage from "../../../entityFields/grid/ObjectsListPage";

const FundInvestmentsPage = () => {
  return (
    <ObjectsListPage
      objectType="FundInvestment"
      objectDetailsPathPattern={`/:clientCode/${pageRoutes.portfolio}/${pageRoutes.fundInvestments}/:id`}
      objectsTitle="Fund Investments"
    />
  );
};

export default FundInvestmentsPage;
