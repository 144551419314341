import { MenuItem, MenuItemProps } from "@mui/material";

const MenuItemWithoutHighlight = (props: MenuItemProps) => (
  <MenuItem
    {...props}
    sx={{
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-selected": {
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    }}
  />
);

export default MenuItemWithoutHighlight;
