import { Link } from "@mui/material";
import { GridRenderCellParams, GridRowId } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { OrganizationReportInfo } from "../../../../../api/biApi";
import { useUserContext } from "../../../../../context/UserContext";
import GroupNameCore from "./GroupNameCore";

interface Props {
  params: GridRenderCellParams<OrganizationReportInfo, string, unknown>;
  getViewReportUrl: (clientCode: string, reportId: string) => string;
  onExpandToggle: (expanded: boolean, rowId: GridRowId) => void;
}
const GroupCell = ({ params, getViewReportUrl, onExpandToggle }: Props) => {
  const { clients } = useUserContext();

  const getIconSrc = useCallback(
    (clientCode: string) => {
      return clients.find((c) => c.clientCode === clientCode)?.branding.logoMarkUrl;
    },
    [clients]
  );

  const level = params.rowNode.depth * 2;
  if (params.rowNode.type === "group") {
    const expanded = params.rowNode.childrenExpanded === true;
    return (
      <GroupNameCore expanded={expanded} params={params} onExpandToggle={onExpandToggle}>
        {params.rowNode.depth === 0 ? <img src={getIconSrc(params.value || "")} alt="" width={20} height={20} /> : null}
      </GroupNameCore>
    );
  }

  return (
    <Link
      href={getViewReportUrl(params.row.clientCode, params.row.reportId)}
      target="_blank"
      noWrap
      underline="none"
      sx={{ color: "text.primary", pl: level + 5.5 }}
      className="highlighted-action"
    >
      <TypographyTooltipEllipsis text={params.row.name} />
    </Link>
  );
};

export default GroupCell;
