import ConsentEdit from "./ConsentEdit";
import { useConsentsContext } from "./ConsentsContext";
import ConsentsList from "./ConsentsList";

const ConsentsContainer = () => {
  const { editableConsent } = useConsentsContext();

  if (editableConsent) {
    return <ConsentEdit />;
  }

  return <ConsentsList />;
};

export default ConsentsContainer;
