import { Box, Button, Stack } from "@mui/material";
import AddFilterButton from "./AddFilterButton";
import FilterButton from "./FilterButton";
import { useFilterContext } from "./FilterContext";

interface Props {
  isLoading?: boolean;
}

const Filters = ({ isLoading }: Props) => {
  const { filterState, dispatchFilters } = useFilterContext();

  const handleResetClick = () => dispatchFilters({ type: "reset_filters" });

  return (
    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
      <Stack direction="row" spacing={1} alignItems="center" rowGap={1} flexWrap="wrap">
        {filterState.visibleFilters.map((filter) => (
          <FilterButton key={filter.id} filter={filter} disabled={isLoading} />
        ))}
        <AddFilterButton />
      </Stack>
      <Button
        color="secondary"
        variant="text"
        onClick={handleResetClick}
        sx={{ minWidth: "auto", whiteSpace: "nowrap" }}
      >
        Reset Filters
      </Button>
    </Box>
  );
};

export default Filters;
