import { Box, Stack, Typography } from "@mui/material";
import { useReducer } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataCollectionSubmissionInfo } from "../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { createReducer, filterRecords, getInitialState } from "../../../common/filters/filterState";
import { dataCollectionsNavigationViewsCaptionMap } from "../dataCollectionsPageTypes";
import DataSubmissionsGrid from "./DataSubmissionsGrid";
import { getFilterDefinitions, getSearchDefinition } from "./dataSubmissionsGridFilters";

const DataSubmissionsList = () => {
  const { clientCode } = useClientContext();

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DataCollectionSubmissionInfo>(),
    getInitialState(`${clientCode}_DataSubmissions_filter_v1`, getFilterDefinitions(), getSearchDefinition(), [])
  );

  const [submissions, fetchError, { isFetching }] = useFetch(adminApi.getDataSubmissions);

  if (fetchError) {
    logError(fetchError, "[DataSubmissionsList] getDataSubmissions");
    return <DataLoadingFailed title="Could not load data submissions" />;
  }

  const isLoading = isFetching || submissions === undefined;

  const allSubmissions = submissions ?? [];
  const filteredSubmissions = filterRecords(filterState, allSubmissions);

  return (
    <FilterContextProvider
      filterState={filterState}
      dispatchFilters={dispatchFilters}
      allRowsForSelectOptions={allSubmissions}
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Box py={2} px={3} borderBottom={1} borderColor="divider">
          <Typography variant="h6">{dataCollectionsNavigationViewsCaptionMap["data_submissions"]}</Typography>
        </Box>

        <Stack width="100%" spacing={2} pt={2.5} px={3} flex={1}>
          <FiltersPanel
            totalCount={allSubmissions.length}
            recordCount={filteredSubmissions.length}
            isLoading={isLoading}
          />

          <DataSubmissionsGrid submissions={filteredSubmissions} isLoading={isFetching} />
        </Stack>
      </Box>
    </FilterContextProvider>
  );
};

export default DataSubmissionsList;
