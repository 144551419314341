import { Stack, Typography } from "@mui/material";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { InvestorChangeRequestDetails } from "../../../../api/types/changeRequestTypes";
import WorkflowStatusTag from "../WorkflowStatusTag";
import { formatWorkflowType } from "../changeRequestsFormatters";

interface Props {
  changeRequestDetails: InvestorChangeRequestDetails;
}

const ChangeRequestDetailsTitle = ({ changeRequestDetails }: Props) => {
  return (
    <Stack py={1.5} spacing={0.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        {changeRequestDetails.referenceCode && (
          <Typography variant="h6">{"# " + changeRequestDetails.referenceCode}</Typography>
        )}
        {changeRequestDetails.referenceCode && <Typography variant="h6">•</Typography>}
        <Typography variant="h6">{changeRequestDetails.investorName}</Typography>
        <WorkflowStatusTag workflowStatus={changeRequestDetails.workflowStatus} />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="caption">{formatWorkflowType(changeRequestDetails.workflowType)}</Typography>
        <Typography variant="caption">•</Typography>
        <Typography variant="caption">{convertISODateTime(changeRequestDetails.requestedAt)}</Typography>
      </Stack>
    </Stack>
  );
};

export default ChangeRequestDetailsTitle;
