import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import deepEqual from "fast-deep-equal";
import { useCallback, useState } from "react";
import ImageUploaderWithPreview from "../../../../../shared/components/imageUploader/ImageUploaderWithPreview";
import { convertToFormData } from "../../../../../shared/utilities/formDataHelper";
import { wait } from "../../../../../shared/utilities/promiseHelper";
import adminApi from "../../../../api/adminApi";
import { RelativeLogoSize } from "../../../../api/types/investorPortalTypes";
import { useClientContext } from "../../../../context/ClientContext";
import ColorEditorSection from "./ColorEditorSection";
import LogosEditor from "./LogosEditor";
import UpdateNotificationBar from "./UpdateNotificationBar";

interface PortalDesignSettings {
  appBarColor: string;
  appBarTextColor: string;
  mainColor: string;
  bannerUrl: string;
  bannerFontColor: string;
  logoTypeUrl: string;
  logoTypeSize: RelativeLogoSize;
  logoMarkUrl: string;
  faviconUrl: string;
}

interface Props {
  initialSettings: PortalDesignSettings;
}

const PortalDesignEditor = ({ initialSettings }: Props) => {
  const { clientTitle, hasPermissions } = useClientContext();
  const [settings, setSettings] = useState(initialSettings);
  const [savedSettings, setSavedSettings] = useState(initialSettings);
  const [isSaving, setSaving] = useState(false);
  const [isJustSaved, setJustSaved] = useState(false);
  const [images, setImages] = useState<Record<string, File | null | undefined>>({});
  const [error, setError] = useState<string | null>(null);
  const canEditSettings = hasPermissions(["ManageInvestorPortalSettings"]);

  const save = useCallback(
    async (currentSettings: PortalDesignSettings) => {
      setSaving(true);

      const formData = convertToFormData(currentSettings);
      Object.entries(images).forEach(([key, value]) => {
        if (value) {
          formData.append(`${key}Image`, value);
        }
      });

      try {
        await adminApi.updateInvestorPortalSettings(formData);
        setSavedSettings(currentSettings);
      } catch (e) {
        if (axios.isAxiosError(e) && e.message) {
          setError(`Save failed. ${e.message}`);
        } else {
          setError("Save failed. Please try again later.");
        }
      } finally {
        setSaving(false);
        setJustSaved(true);
        await wait(5 * 1000);
        setJustSaved(false);
        setError(null);
      }
    },
    [images]
  );

  const handleSaveClick = async () => await save(settings);

  const handleResetClick = () => {
    setSettings(savedSettings);
    setImages({});
  };

  const handleChange = (key: keyof PortalDesignSettings) => (value: string) =>
    setSettings({ ...settings, [key]: value });

  const handleImageChange = (key: keyof PortalDesignSettings, file: File | undefined, url: string) => {
    handleChange(key)(url);
    setImages({ ...images, [key]: file });
  };

  const handleLogotypeSizeChange = (logoTypeSize: RelativeLogoSize) => setSettings({ ...settings, logoTypeSize });

  const disableSave = !canEditSettings || deepEqual(savedSettings, settings);

  return (
    <Stack spacing={3}>
      <Stack>
        <LogosEditor
          disabled={!canEditSettings}
          isSaving={isSaving}
          settings={settings}
          originalSettings={savedSettings}
          onImageChange={handleImageChange}
          onLogotypeSizeChange={handleLogotypeSizeChange}
        />
      </Stack>
      <Stack spacing={1} pt={3}>
        <Typography variant="subtitle1">Navigation colors</Typography>
        <Typography color="text.secondary">Choose a color scheme for the main horizontal bar.</Typography>
      </Stack>
      <ColorEditorSection
        disabled={!canEditSettings}
        title="Navigation bar color"
        subtitle="Background color of the main navigation bar."
        color={settings.appBarColor}
        onChange={handleChange("appBarColor")}
      />
      <ColorEditorSection
        disabled={!canEditSettings}
        title="Navigation text color"
        subtitle="Text color of the main navigation bar."
        color={settings.appBarTextColor}
        onChange={handleChange("appBarTextColor")}
      />
      <ColorEditorSection
        disabled={!canEditSettings}
        title="Highlights"
        subtitle="Color of buttons and highlight color for the selected navigation item."
        color={settings.mainColor}
        onChange={handleChange("mainColor")}
      />
      <Stack sx={(theme) => ({ maxWidth: theme.spacing(89) })}>
        <Typography variant="subtitle1">Banner</Typography>
        <Typography color="text.secondary">PNG, SVG or JPG. Recommended size: 2560x120 pixels.</Typography>
        <Box mt={2}>
          <ImageUploaderWithPreview
            disabled={!canEditSettings}
            width="auto"
            height={120}
            allowedImageTypes="image/jpg, image/png, image/svg+xml"
            loading={isSaving}
            originalImageSrc={savedSettings.bannerUrl}
            imageSrc={settings.bannerUrl}
            onImageSelected={(file, url) => handleImageChange("bannerUrl", file, url)}
            uploadButtonName="Upload Banner"
            backgroundSize="cover"
            maxFileSizeBytes={1 * 1024 * 1024}
            buttonsPosition="bottom"
            showPlaceholderOverImage
            Placeholder={
              <Stack p="2rem">
                <Typography variant="h5" color={settings.bannerFontColor}>
                  {"{{Contact Name}}"}
                </Typography>
                <Typography color={settings.bannerFontColor}>Welcome to the {clientTitle} Investor Portal</Typography>
              </Stack>
            }
          />
        </Box>
      </Stack>
      <ColorEditorSection
        disabled={!canEditSettings}
        title="Banner text color"
        subtitle="Text color on the banner."
        color={settings.bannerFontColor}
        onChange={handleChange("bannerFontColor")}
      />
      <Stack direction="row" spacing={2}>
        <LoadingButton
          variant="contained"
          size="medium"
          sx={(theme) => ({ width: theme.spacing(10) })}
          disabled={disableSave}
          loading={isSaving}
          onClick={handleSaveClick}
        >
          Save
        </LoadingButton>
        <Button size="medium" variant="text" onClick={handleResetClick} disabled={!canEditSettings}>
          Reset
        </Button>
      </Stack>
      <UpdateNotificationBar variant={error ? "error" : undefined} open={isJustSaved} message={error} />
    </Stack>
  );
};

export default PortalDesignEditor;
