import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";

interface Props {
  onApproveClick: (ids: string[]) => void;
  onRejectClick: (ids: string[]) => void;
}

const ApprovalsGridActionsContext = createContext<Props | undefined>(undefined);

export const ApprovalsGridActionsContextProvider = ({
  onApproveClick,
  onRejectClick,
  children,
}: PropsWithChildren<Props>) => (
  <ApprovalsGridActionsContext.Provider value={{ onApproveClick, onRejectClick }}>
    {children}
  </ApprovalsGridActionsContext.Provider>
);

export const useApprovalsGridActionsContext = () =>
  defined(useContext(ApprovalsGridActionsContext), "Attempt to use ApprovalsGridActionsContext without provider");
