import { SvgIcon, SvgIconProps } from "@mui/material";

const DefaultFileIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" fill="#F5F5F5" stroke="#E5E6E9" />
    <g>
      <path
        d="M10.0003 15.9997H14.0003C14.1892 15.9997 14.3475 15.9358 14.4753 15.808C14.6031 15.6802 14.667 15.5219 14.667 15.333C14.667 15.1441 14.6031 14.9858 14.4753 14.858C14.3475 14.7302 14.1892 14.6663 14.0003 14.6663H10.0003C9.81144 14.6663 9.6531 14.7302 9.52533 14.858C9.39755 14.9858 9.33366 15.1441 9.33366 15.333C9.33366 15.5219 9.39755 15.6802 9.52533 15.808C9.6531 15.9358 9.81144 15.9997 10.0003 15.9997ZM10.0003 13.333H14.0003C14.1892 13.333 14.3475 13.2691 14.4753 13.1413C14.6031 13.0136 14.667 12.8552 14.667 12.6663C14.667 12.4775 14.6031 12.3191 14.4753 12.1913C14.3475 12.0636 14.1892 11.9997 14.0003 11.9997H10.0003C9.81144 11.9997 9.6531 12.0636 9.52533 12.1913C9.39755 12.3191 9.33366 12.4775 9.33366 12.6663C9.33366 12.8552 9.39755 13.0136 9.52533 13.1413C9.6531 13.2691 9.81144 13.333 10.0003 13.333ZM8.00033 18.6663C7.63366 18.6663 7.31977 18.5358 7.05866 18.2747C6.79755 18.0136 6.66699 17.6997 6.66699 17.333V6.66634C6.66699 6.29967 6.79755 5.98579 7.05866 5.72467C7.31977 5.46356 7.63366 5.33301 8.00033 5.33301H12.7837C12.9614 5.33301 13.1309 5.36634 13.292 5.43301C13.4531 5.49967 13.5948 5.59412 13.717 5.71634L16.9503 8.94967C17.0725 9.0719 17.167 9.21356 17.2337 9.37467C17.3003 9.53579 17.3337 9.70523 17.3337 9.88301V17.333C17.3337 17.6997 17.2031 18.0136 16.942 18.2747C16.6809 18.5358 16.367 18.6663 16.0003 18.6663H8.00033ZM12.667 9.33301V6.66634H8.00033V17.333H16.0003V9.99967H13.3337C13.1448 9.99967 12.9864 9.93579 12.8587 9.80801C12.7309 9.68023 12.667 9.5219 12.667 9.33301Z"
        fill="#8E9BA9"
      />
    </g>
  </SvgIcon>
);

export default DefaultFileIcon;
