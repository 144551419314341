import { Grid, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { ConditionValidationResult, DimensionValidationResult } from "../../../../../../api/biApi";
import { DimensionValidation } from "./DimensionValidation";

interface FieldsValidationProps {
  caption: string;
  fields?: DimensionValidationResult[] | null;
}
export function FieldsValidation({ caption, fields }: FieldsValidationProps) {
  if (!fields) return null;
  return (
    <FieldsContainer caption={caption}>
      {fields.map((f) => (
        <DimensionValidation key={f.name} field={f} sx={{ py: 0.5 }} />
      ))}
    </FieldsContainer>
  );
}
export function ConditionsValidation({ conditions }: { conditions?: ConditionValidationResult[] | null }) {
  if (!conditions) return null;
  return (
    <FieldsContainer caption="Conditions">
      {conditions.map((c) => (
        <DimensionValidation key={c.name} field={c} sx={{ py: 0.5 }} />
      ))}
    </FieldsContainer>
  );
}
export function FieldsContainer({ caption, children }: PropsWithChildren<{ caption: string }>) {
  return (
    <Grid container sx={{ flexDirection: "column", gap: 1 }}>
      <Typography variant="subtitle2">{caption}</Typography>
      {children}
    </Grid>
  );
}
