import { Typography } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { SentEmail } from "../../../../api/types/emailsTypes";
import { getColumns } from "./emailsTableDataProvider";

interface Props {
  apiRef?: React.MutableRefObject<GridApiPremium>;
  isLoading?: boolean;
  emails: SentEmail[];
  onRowScrollEnd?: () => void;
}

const EmailsTable = ({ apiRef, isLoading, emails, onRowScrollEnd }: Props) => {
  return (
    <DataGrid<SentEmail>
      apiRef={apiRef}
      loading={isLoading}
      columns={getColumns()}
      rows={emails}
      rowHeight={56}
      disableRowSelectionOnClick
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      onRowsScrollEnd={onRowScrollEnd}
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No emails yet</Typography>
          </CenteredWrapper>
        ),
      }}
    />
  );
};

export default EmailsTable;
