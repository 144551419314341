import { Stack, Typography } from "@mui/material";
import { MemberRole } from "../../../../../api/types/userManagementTypes";
import { entriliaProductCaptionMap } from "../../../../../utilities/enumCaptions";
import EntriliaProductIcon from "../../../../common/domain-icons/EntriliaProductIcon";
import { CompanyProductWithRoles, roleNamesMap } from "../definitions";

interface ProductDetailsProps {
  product: CompanyProductWithRoles;
  grantedRoles: MemberRole[];
}

const CompanyProductDetails = ({ product, grantedRoles }: ProductDetailsProps) => {
  const roleNames = product.roles.filter((role) => grantedRoles.includes(role)).map((role) => roleNamesMap[role]);

  return (
    <Stack direction="row" justifyItems="center" alignItems="center" spacing={1}>
      <EntriliaProductIcon product={product.key} color="primary" fontSize="small" />
      <Typography>{entriliaProductCaptionMap[product.key]}</Typography>
      <Typography color="text.secondary">{roleNames.join(", ")}</Typography>
    </Stack>
  );
};

export default CompanyProductDetails;
