import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ValidationResult, Validator, invalidResult, validResult } from "../../../../shared/utilities/validators";
import { FieldAttribute } from "../../../api/types/objectTypes";
import { useFieldValuesContext } from "../FieldValuesContext";
import { findNextEntityInput, findPreviousEntityInput } from "../entityFieldNavigator";
import FieldValueWrapper from "./FieldValueWrapper";

interface Props {
  fieldId: string;
  value: string;
  onChange: (value: string, validationResult: ValidationResult) => void;
  validator?: Validator<string>;
  transformOnChangeFunc?: (value: string) => string;
  prefix?: string;
  postfix?: string;
  defaultHelperText?: string;
  displayValueComponent?: JSX.Element | JSX.Element[] | null;
  displayHoverComponent?: JSX.Element | JSX.Element[] | null;
  maxLength?: number | null;
  fieldAttributes: FieldAttribute[];
  textPostfix?: string;
  multiline?: boolean;
  fieldInputId?: string;
}

const TextValueEdit = ({
  fieldId,
  value,
  onChange,
  validator,
  transformOnChangeFunc,
  prefix,
  postfix,
  defaultHelperText,
  displayValueComponent,
  maxLength,
  fieldAttributes,
  textPostfix,
  displayHoverComponent,
  multiline,
  fieldInputId,
}: Props) => {
  const { validateUniqueFieldValue } = useFieldValuesContext();

  const [valueState, setValueState] = useState(value);
  const [validationState, setValidationState] = useState<ValidationResult>({
    isValid: true,
    error: defaultHelperText || "",
  });

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (value !== valueState) {
      setValueState(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const inputRef = useRef<HTMLInputElement>(null);

  const validate = async (): Promise<ValidationResult> => {
    const newValue = valueState;
    if (fieldAttributes.includes("Required") && !newValue) {
      return invalidResult("Required field. Please enter the necessary information in the input field.");
    }

    const validationResult = validator ? validator(newValue) : validResult();
    if (!validationResult.isValid) {
      return validationResult;
    }

    if (fieldAttributes.includes("ObjectIdentifier")) {
      const uniqueValidationResult = await validateUniqueFieldValue(fieldId, newValue);
      if (!uniqueValidationResult.isValid) {
        return uniqueValidationResult;
      }
    }

    return validResult();
  };

  const handleValueOnLeave = async () => {
    const validationResult = await validate();
    setValidationState(validationResult);
    onChange(valueState, validationResult);
    return validationResult.isValid;
  };

  const handleValueOnChange = (newValue: string) => {
    if (transformOnChangeFunc) {
      newValue = transformOnChangeFunc(newValue);
      setValueState(newValue);
    } else {
      setValueState(newValue);
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (await handleValueOnLeave()) {
        setIsEdit(false);
      }
      return;
    }

    if (e.key === "Tab" && fieldInputId) {
      const nextElement = e.shiftKey ? findPreviousEntityInput(fieldInputId) : findNextEntityInput(fieldInputId);
      if (nextElement) {
        e.preventDefault();
        nextElement.click();
      }
      return;
    }
  };

  const InputProps = {
    ref: inputRef,
    startAdornment: prefix && (
      <InputAdornment position="start" sx={{ mr: ".125em" }}>
        <Typography noWrap color="secondary">
          {prefix}
        </Typography>
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        <>
          {maxLength && (
            <Box display="flex" justifyContent="flex-end">
              <Typography color="textSecondary" variant="caption">
                {maxLength - valueState.length}
              </Typography>
            </Box>
          )}
          {postfix && (
            <Typography noWrap color="secondary">
              {postfix}
            </Typography>
          )}
          {textPostfix && (
            <Typography
              noWrap
              color="secondary"
              sx={{
                position: "absolute",
                right: `calc(100% - ${prefix?.length || 0 + 1 + textPostfix?.length || 0}em - (${
                  valueState.length
                } * 0.57em))`,
              }}
            >
              {textPostfix}
            </Typography>
          )}
        </>
      </InputAdornment>
    ),
  };

  return (
    <FieldValueWrapper
      id={fieldInputId}
      isReadonly={fieldAttributes.includes("ReadOnly")}
      displayValue={valueState}
      displayValueComponent={displayValueComponent}
      onLeaveEditMode={handleValueOnLeave}
      isEdit={isEdit}
      onEditChange={setIsEdit}
      displayHoverComponent={displayHoverComponent}
    >
      <TextField
        onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
        autoFocus
        multiline={multiline}
        maxRows={3}
        spellCheck={true}
        InputProps={InputProps}
        error={!validationState.isValid}
        helperText={validationState.error}
        fullWidth
        value={valueState}
        onChange={(e) => handleValueOnChange(e.target.value)}
        inputProps={{ maxLength }}
        onKeyDown={handleKeyDown}
      />
    </FieldValueWrapper>
  );
};

export default TextValueEdit;
