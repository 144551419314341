import { Stack, Typography } from "@mui/material";
import UserAvatar from "../../../shared/components/UserAvatar";

interface Props {
  userName: string;
  avatarImage?: string;
  email?: string;
}

const UserInfoMenuItem = ({ userName, avatarImage, email }: Props) => (
  <Stack m={2} spacing={0.5} direction="row">
    <UserAvatar size="large" userName={userName} avatarImage={avatarImage} />
    <Stack>
      <Typography variant="subtitle2" color="primary">
        {userName}
      </Typography>
      {email && <Typography color="secondary">{email}</Typography>}
    </Stack>
  </Stack>
);

export default UserInfoMenuItem;
