import { Stack, Typography } from "@mui/material";
import { WorkflowStatus } from "../../../../../shared/api/changeRequestTypes";
import FieldsDiffTable from "../../../../../shared/components/FieldsDiffTable";
import ShowMoreLessButton from "../../../../../shared/components/ShowMoreLessButton";
import useToggleState from "../../../../../shared/hooks/useToggleState";
import { ChangeRequestChangeSet } from "../../../../api/types/changeRequestTypes";
import { formatChangeSetType, isChangeTypeWithNewValues } from "../changeRequestsFormatters";

interface Props {
  changeSet: ChangeRequestChangeSet;
  workflowStatus: WorkflowStatus;
}

const ChangeRequestDiffsSection = ({ changeSet, workflowStatus }: Props) => {
  const [expanded, toggleExpanded] = useToggleState(false);

  const oldValueColumnLabel = isChangeTypeWithNewValues(changeSet.changeSetType)
    ? undefined
    : workflowStatus === "Completed"
      ? "Old"
      : "Current";

  const newValueColumnLabel = isChangeTypeWithNewValues(changeSet.changeSetType) ? "New" : "Change";

  const isExpandable = changeSet.changeSetType === "FundInvestorBankAccountAssignment";

  const title = changeSet.displayName ? changeSet.displayName : formatChangeSetType(changeSet.changeSetType);
  const subtitle = changeSet.displayName ? formatChangeSetType(changeSet.changeSetType) : "";

  return (
    <Stack spacing={1} alignItems="flex-start">
      <Stack spacing={2} width="47.5rem">
        <Stack spacing={0.5}>
          <Typography variant="subtitle1">{title}</Typography>
          {subtitle && (
            <Typography variant="caption" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Stack>
        <FieldsDiffTable
          fieldChanges={!isExpandable || expanded ? changeSet.fieldChanges : changeSet.fieldChanges.slice(0, 1)}
          oldValueColumnLabel={oldValueColumnLabel}
          newValueColumnLabel={newValueColumnLabel}
        />
      </Stack>
      {isExpandable && <ShowMoreLessButton expanded={expanded} onClick={() => toggleExpanded()} />}
    </Stack>
  );
};

export default ChangeRequestDiffsSection;
