import adminApi from "../../../../api/adminApi";
import { PassthroughApplicationLogs } from "../../../../api/types/passthroughTypes";
import LogsWrapper from "../../../common/syncLogs/LogsWrapper";
import PassthroughLogsTable from "./PassthroughLogsTable";

const PassthroughLogsPage = () => {
  const hideSeverity = true;
  return (
    <LogsWrapper<PassthroughApplicationLogs>
      fetchLogsFromApi={(params) => adminApi.getPassthroughLogs(params)}
      renderTable={(logItems) => <PassthroughLogsTable logItems={logItems} hideSeverityColumn={hideSeverity} />}
      hideSeverityFilter={hideSeverity}
    />
  );
};

export default PassthroughLogsPage;
