import { getFileExtension } from "./fileHelper";

export const ensureSvgFileHasSize = async (
  file: File,
  targetHeight: number | string,
  targetWidth: number | string
): Promise<File> => {
  if (getFileExtension(file.name) !== ".svg" && file.type !== "image/svg+xml") {
    return file;
  }

  const tmpElement = document.createElement("div");
  tmpElement.style.display = "none";

  try {
    tmpElement.innerHTML = await file.text();

    const svgElement = tmpElement.querySelector("svg");
    if (!svgElement) {
      return file;
    }

    const heightAttr = svgElement.attributes.getNamedItem("height");
    const widthAttr = svgElement.attributes.getNamedItem("width");
    if (heightAttr || widthAttr) {
      return file;
    }

    svgElement.setAttribute("height", targetHeight.toString());
    svgElement.setAttribute("width", targetWidth.toString());

    return new File([tmpElement.innerHTML], file.name, { type: file.type });
  } finally {
    tmpElement.remove();
  }
};
