import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import PassthroughConfigurationContainer from "./configuration/PassthroughConfigurationContainer";
import { PassthroughPageState } from "./passthroughPageState";
import { PassthroughConfiguration } from "../../../api/types/passthroughTypes";
import PassthroughLogsPage from "./logs/PassthroughLogsPage";

type Tabs = "configuration" | "logs";

interface Props {
  state: PassthroughPageState;
  disabled: boolean;
  onConfigurationChange: (data: Partial<PassthroughConfiguration>) => void;
  onConfigurationSave: () => void;
}

const PassthroughTabs = ({ state, disabled, onConfigurationChange, onConfigurationSave }: Props) => {
  const [selectedTab, setSelectedTab] = useState<Tabs>("configuration");
  const handleTabChange = (_: SyntheticEvent, value: Tabs) => setSelectedTab(value);

  return (
    <TabContext value={selectedTab}>
      <TabList onChange={handleTabChange}>
        <Tab label="Configuration" value="configuration" />
        <Tab label="Logs" value="logs" />
      </TabList>
      <TabPanel value="configuration" sx={{ p: 0, pb: 2 }}>
        <PassthroughConfigurationContainer
          state={state}
          disabled={disabled}
          onConfigurationChange={onConfigurationChange}
          onConfigurationSave={onConfigurationSave}
        />
      </TabPanel>
      <TabPanel value="logs" sx={{ p: 0, pb: 2 }}>
        <PassthroughLogsPage />
      </TabPanel>
    </TabContext>
  );
};

export default PassthroughTabs;
