import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { Box, Button, MenuItem, MenuProps, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { parseISO } from "date-fns";
import { useState } from "react";
import { makeLighterBackgroundFromColor } from "../utilities/colorHelper";
import { formatDate } from "../utilities/dateUtils";

interface Props {
  initialDate: Date | string | undefined | null;
  optionDates: Date[];
  disabled?: boolean;
  onChange: (date: Date) => void;
  menuProps?: Partial<MenuProps> | undefined;
  isMobile?: boolean;
  placeholder?: string;
}

const DateSelector = ({ optionDates, onChange, initialDate, disabled, menuProps, isMobile, placeholder }: Props) => {
  const theme = useTheme();
  const date = typeof initialDate === "string" ? parseISO(initialDate) : initialDate;
  const initialDateIndex = date ? optionDates.findIndex((d) => d.toISOString() === date.toISOString()) : 0;
  const initialIndex = initialDateIndex > -1 ? initialDateIndex : 0;
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(initialIndex);
  const [open, setOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedDateIndex = event.target.value as number;
    const selectedDate = optionDates[selectedDateIndex];
    setSelectedDateIndex(selectedDateIndex);
    onChange(selectedDate as Date);
  };

  const selectedIndex = initialDateIndex > -1 ? initialIndex : selectedDateIndex;

  const selectedStyles =
    selectedDateIndex > 0
      ? {
          backgroundColor: makeLighterBackgroundFromColor(theme.palette.primary.main),
          fieldset: {
            color: theme.palette.primary.main,
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
          },
        }
      : {};

  return (
    <Select
      sx={() => ({ px: 1, minWidth: 160, ...selectedStyles })}
      startAdornment={<CalendarTodayOutlinedIcon sx={{ mr: 1 }} color="secondary" />}
      open={open}
      disabled={disabled}
      autoWidth
      value={selectedIndex}
      onChange={handleChange}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      placeholder={placeholder}
      MenuProps={menuProps}
    >
      {isMobile && placeholder && (
        <Typography variant="h5" sx={{ px: 1.5, pt: 0.5, pb: 1, textAlign: "center" }}>
          {placeholder}
        </Typography>
      )}

      {optionDates.map((date, i) => {
        return (
          <MenuItem key={+i} value={i} sx={{ pl: isMobile ? 4 : 2 }}>
            <Typography>{formatDate(date)}</Typography>
          </MenuItem>
        );
      })}

      {isMobile && (
        <Box display="flex" flexGrow={1} justifyContent="center">
          <Button variant="text" size="large" color="secondary" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Box>
      )}
    </Select>
  );
};

export default DateSelector;
