import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";

export interface ReportInfoExt extends ReportInfo {
  group: string;
  groupOrder: number;
}

export enum ReportTypesEnum {
  All = "All",
  MyReports = "MyReports",
  SharedWithMe = "SharedWithMe",
}
