import { logError } from "../../../../../shared/logging";
import { AnyRecord } from "../../../../../shared/types";
import { SearchFilter, SearchFilterDefinition, TableFilter, TableFilterDefinition } from "../filterTypes";
import { booleanFilterHandler } from "./booleanFilter";
import { dateFilterHandler } from "./dateFilter";
import { multiSelectFilterHandler } from "./multiSelectFilter";
import { numberFilterHandler } from "./numberFilter";
import { searchMatchesRow } from "./searchFilter";
import { textFilterHandler } from "./textFilter";

export const initializeSearchFilter = <R extends AnyRecord>({
  getFieldValues,
}: SearchFilterDefinition<R>): SearchFilter<R> => ({
  value: "",
  getFieldValues,
});

export const emptySearchFilterDefinition = <R extends AnyRecord>(): SearchFilterDefinition<R> => ({
  getFieldValues: () => [],
});

export const getFilterDefinition = <R extends AnyRecord>(filter: TableFilter<R>): TableFilterDefinition<R> => {
  const { value, ...definition } = filter;
  return definition;
};

export const initializeFilter = <R extends AnyRecord>(filterDefinition: TableFilterDefinition<R>): TableFilter<R> => {
  switch (filterDefinition.type) {
    case "text":
      return { ...filterDefinition, value: textFilterHandler.getDefaultValue(filterDefinition) };
    case "multi_select":
      return { ...filterDefinition, value: multiSelectFilterHandler.getDefaultValue(filterDefinition) };
    case "number":
      return { ...filterDefinition, value: numberFilterHandler.getDefaultValue(filterDefinition) };
    case "date":
      return { ...filterDefinition, value: dateFilterHandler.getDefaultValue(filterDefinition) };
    case "boolean":
      return { ...filterDefinition, value: booleanFilterHandler.getDefaultValue(filterDefinition) };
  }
};

export const getDefaultFilterValue = (filter: TableFilter) => {
  switch (filter.type) {
    case "text":
      return textFilterHandler.getDefaultValue(filter);
    case "multi_select":
      return multiSelectFilterHandler.getDefaultValue(filter);
    case "number":
      return numberFilterHandler.getDefaultValue(filter);
    case "date":
      return dateFilterHandler.getDefaultValue(filter);
    case "boolean":
      return booleanFilterHandler.getDefaultValue(filter);
  }
};

export const isFilterActive = (filter: TableFilter) => {
  switch (filter.type) {
    case "text":
      return textFilterHandler.isActive(filter.value);
    case "multi_select":
      return multiSelectFilterHandler.isActive(filter.value);
    case "number":
      return numberFilterHandler.isActive(filter.value);
    case "date":
      return dateFilterHandler.isActive(filter.value);
    case "boolean":
      return booleanFilterHandler.isActive(filter.value);
  }
};

export const formatFilterValue = (filter: TableFilter) => {
  if (!isFilterActive(filter)) {
    return filter.name;
  }

  switch (filter.type) {
    case "text":
      return `${filter.name} ${textFilterHandler.formatValue(filter.value)}`;
    case "multi_select":
      return `${filter.name} ${multiSelectFilterHandler.formatValue(filter.value)}`;
    case "number":
      return `${filter.name} ${numberFilterHandler.formatValue(filter.value)}`;
    case "date":
      return `${filter.name} ${dateFilterHandler.formatValue(filter.value)}`;
    case "boolean":
      return `${filter.name} ${booleanFilterHandler.formatValue(filter.value)}`;
  }
};

const filterMatchesRow = <R extends AnyRecord>(filter: TableFilter<R>, row: R) => {
  if (!isFilterActive(filter)) {
    return true;
  }

  if (filter.getFieldValue === undefined) {
    logError("getFieldValue must be set for filter to match grid rows", `Filter: '${filter.id}'`);
    return false;
  }

  switch (filter.type) {
    case "text":
      return textFilterHandler.matchesFieldValue(filter.value, filter.getFieldValue(row));
    case "multi_select":
      return multiSelectFilterHandler.matchesFieldValue(filter.value, filter.getFieldValue(row));
    case "number":
      return numberFilterHandler.matchesFieldValue(filter.value, filter.getFieldValue(row));
    case "date":
      return dateFilterHandler.matchesFieldValue(filter.value, filter.getFieldValue(row));
    case "boolean":
      return booleanFilterHandler.matchesFieldValue(filter.value, filter.getFieldValue(row));
  }
};

export const filterTableRows = <R extends AnyRecord>(rows: R[], filters: TableFilter<R>[], search: SearchFilter<R>) =>
  rows.filter((row) => filters.every((filter) => filterMatchesRow(filter, row)) && searchMatchesRow(search, row));
