import { Box } from "@mui/material";
import { ContactDetails } from "../../../../../api/adminApi";
import { ObjectClassDefinition, ObjectPermissions } from "../../../../../api/types/objectTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import ScrollableColumns from "../../../../common/layout/ScrollableColumns";
import CreatedAndModifiedTimestamps from "../../../../entityFields/CreatedAndModifiedTimestamps";
import EntityNotesSection from "../../../../entityFields/EntityNotesSection";
import { getNotesEntityType } from "../../../../entityFields/entityTypesHelper";
import { NotesContextProvider } from "../../../../notes/NotesContext";
import ContactAccessSection from "./ContactAccessSection";
import ContactCard from "./ContactCard";

interface Props {
  contactDetails: ContactDetails;
  objectDefinition: ObjectClassDefinition;
  objectPermissions: ObjectPermissions;
  onContactDetailsChange: (details: Partial<ContactDetails>) => void;
}

const ContactDetailsMainTab = ({
  contactDetails,
  objectDefinition,
  objectPermissions,
  onContactDetailsChange,
}: Props) => {
  const { hasAnyPermission } = useClientContext();

  const hasPermissionsToManageFields = hasAnyPermission(objectPermissions.objectDataWritePermissions);
  const hasPermissionsToViewNotes = hasAnyPermission(objectPermissions.notesReadPermissions);
  const hasPermissionsToManageNotes = hasAnyPermission(objectPermissions.notesWritePermissions);

  return (
    <ScrollableColumns gridProps={{ columnSpacing: 2.5 }}>
      <>
        <ContactCard
          contact={contactDetails.contact}
          objectDeinition={objectDefinition}
          onContactDetailsChange={onContactDetailsChange}
          hasPermissionsToManageFields={hasPermissionsToManageFields}
        />
        <Box mt={2.5}>
          <ContactAccessSection contactDetails={contactDetails} />
        </Box>
        <CreatedAndModifiedTimestamps
          stackProps={{ mt: 2.5 }}
          createdAt={contactDetails.createdAt}
          modifiedAt={contactDetails.updateAt}
        />
      </>
      {hasPermissionsToViewNotes && (
        <NotesContextProvider
          entityType={getNotesEntityType(objectDefinition.objectType)}
          entityId={contactDetails.contact.id}
          hasPermissionsToManageNotes={hasPermissionsToManageNotes}
        >
          <EntityNotesSection />
        </NotesContextProvider>
      )}
    </ScrollableColumns>
  );
};

export default ContactDetailsMainTab;
