import { LoadingButton } from "@mui/lab";
import {
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import DashedArrowIconLeft from "../../../icons/DashedArrowIconLeft";
import DashedArrowIconRight from "../../../icons/DashedArrowIconRight";
import DwhShareIcon from "../../../icons/DwhShareIcon";
import SnowflakeLogo from "../../../images/snowflake.png";

export interface StartResyncResult {
  severity: AlertColor;
  message: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onStart: (result: StartResyncResult) => void;
}

const StartResyncDialog = ({ open, onClose, onStart }: Props) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleResyncClick = async () => {
    setLoading(true);
    try {
      const resp = await adminApi.startDwhFullSync();
      if (resp.data) {
        if (resp.data.previousSyncInProgress) {
          onStart({
            severity: "error",
            message: "Re-synchronization is not possible, as the database update process is underway.",
          });
        } else {
          onStart({
            severity: "info",
            message: "Re-synchronization started successfully. Track the progress in the Logs tab.",
          });
        }
      } else {
        logError(resp.error, "ShowflakeResync");
        onStart({
          severity: "error",
          message: "Re-synchronization failed. Please try checking the Logs tab for details.",
        });
      }
    } catch (error) {
      logError(error, "ShowflakeResync");
      onStart({
        severity: "error",
        message: "Re-synchronization failed. Please try checking the Logs tab for details.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ ".MuiPaper-root": { maxWidth: "28rem" } }}>
      <DialogTitle>Re-Synchronization</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent sx={(theme) => ({ px: theme.spacing(4) })}>
        <Stack alignItems="center" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={2.5} my={2}>
            <DwhShareIcon sx={{ width: 64, height: 64 }} />
            <Stack spacing={1}>
              <DashedArrowIconRight sx={{ width: 76, height: 18, color: theme.palette.success.main }} />
              <DashedArrowIconLeft sx={(t) => ({ width: 76, height: 18, color: t.palette.dialogIcon })} />
            </Stack>
            <img src={SnowflakeLogo} alt="Snowflake Logo" width="64x" />
          </Stack>
          <Typography variant="subtitle2">Re-Synchronization Snowflake</Typography>
          <Typography color="text.secondary" sx={{ textAlign: "center" }}>
            You are about to begin a full data warehouse resynchronization. While this process is underway, you may
            experience temporary data gaps or inconsistencies in Snowflake. The synchronization is expected to take
            several minutes, and you can track its progress in the Logs tab.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" color="primary" loading={loading} onClick={handleResyncClick}>
          Start
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StartResyncDialog;
