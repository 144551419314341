import { logError } from "../../../../shared/logging";
import { EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError } from "../helpers";
import EntityTextFieldComponent from "./EntityTextFieldComponent";

const EntityTextField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityTextField]");
    return null;
  }

  return (
    <EntityTextFieldComponent
      multiline
      value={value || ""}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={onChange}
      fieldInputId={fieldInputId}
    />
  );
};

export default EntityTextField;
