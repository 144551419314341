import { Box, Stack, Typography } from "@mui/material";
import ImageUploaderWithPreview from "../../../../../shared/components/imageUploader/ImageUploaderWithPreview";
import ImagePlaceholderIcon from "../../../../../shared/icons/ImagePlaceholderIcon";

interface Props {
  url: string | undefined;
  defaultUrl: string | undefined;
  isLoading: boolean;
  onLogomarkChange: (file: File | undefined, url: string) => void;
  disabled?: boolean;
}

const CompanyLogomarkSettings = ({ url, defaultUrl, isLoading, disabled, onLogomarkChange }: Props) => {
  return (
    <Stack>
      <Typography variant="subtitle1">Company logomark</Typography>
      <Typography color="text.secondary">PNG or SVG. Recommended size: 56x56 pixels.</Typography>
      <Box mt={2}>
        <ImageUploaderWithPreview
          disabled={isLoading || disabled}
          width={56}
          height={56}
          allowedImageTypes="image/png, image/svg+xml"
          showUploadButtonIcon
          originalImageSrc={defaultUrl}
          imageSrc={url}
          onImageSelected={onLogomarkChange}
          uploadButtonName="Upload Logomark"
          resetButtonName="Remove"
          maxFileSizeBytes={0.5 * 1024 * 1024}
          buttonsPosition="right"
          border="dashed"
          Placeholder={
            <Stack p={2}>
              <ImagePlaceholderIcon />
            </Stack>
          }
        />
      </Box>
    </Stack>
  );
};

export default CompanyLogomarkSettings;
