import { createContext, PropsWithChildren, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";

interface Props {
  showFilePreview: (documentView: DocumentViews) => void;
}

type ContextValue = Props;

const DocumentViewsGridContext = createContext<ContextValue | undefined>(undefined);

export const DocumentViewsGridContextProvider = ({ showFilePreview, children }: PropsWithChildren<Props>) => {
  return <DocumentViewsGridContext.Provider value={{ showFilePreview }}>{children}</DocumentViewsGridContext.Provider>;
};

export const useDocumentViewsGridContext = () =>
  defined(useContext(DocumentViewsGridContext), "Attempt to use DocumentViewsGridContext without provider");
