import { Alert, AlertTitle, Container, Stack, Typography } from "@mui/material";
import { useImportCsvDataContext } from "../ImportCsvDataContext";
import { removeImportCreationErrorAction } from "../importCsvDataPageState";

const ImportCsvCreationError = () => {
  const { pageState, setPageState } = useImportCsvDataContext();

  return (
    <Container sx={{ height: "100%" }}>
      <Stack alignItems="center" pt={2.5}>
        <Alert severity="error" sx={{ width: "50%" }} onClose={() => setPageState(removeImportCreationErrorAction())}>
          <AlertTitle>Could not create Data Import</AlertTitle>
          <Typography variant="caption">{pageState.importCreationError || ""}</Typography>
        </Alert>
      </Stack>
    </Container>
  );
};

export default ImportCsvCreationError;
