import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import { useConfigurationsGridActionsContext } from "./ConfigurationsGridActionsContext";
import { getInitialEditConfigurationState, isFormDirty, updateNameAction } from "./editConfigurationState";

interface Props {
  onClose: () => void;
  onCreated: (newConfiguration: DataModelConfiguration) => void;
}

const createConfiguration = withErrorHandling(adminApi.createDataModelConfiguration);

const CreateConfigurationDialog = ({ onClose, onCreated }: Props) => {
  const { configurations } = useConfigurationsGridActionsContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(getInitialEditConfigurationState(configurations));

  const handleCreateClick = async () => {
    setLoading(true);
    const [newConfiguration, error] = await createConfiguration({ name: formState.name.trim() });
    setLoading(false);

    if (error) {
      logError(error, "[CreateConfigurationDialog] handleCreateClick");
      sendNotificationError("Failed to create the configuration");
      return;
    }

    sendNotification("Configuration created successfully");
    onCreated(newConfiguration);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Configuration</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <TextField
          label="Name"
          value={formState.name}
          onChange={(e) => setFormState(updateNameAction(e.target.value))}
          error={!formState.validationResult.isValid}
          helperText={formState.validationResult.error}
          fullWidth
          autoFocus
        />
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleCreateClick}
          disabled={!formState.validationResult.isValid || !isFormDirty(formState)}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateConfigurationDialog;
