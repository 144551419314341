import { TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import RoundedTable from "../../../../../shared/components/RoundedTable";
import { Field } from "../../../../api/types/objectTypes";
import FieldSourceBadge from "../../../entityFields/FieldSourceBadge";
import ObjectFieldName from "../object-fields/ObjectFieldName";

interface Props {
  allFields: Field[];
}

const ObjectIdentifierFieldsSection = ({ allFields }: Props) => {
  const objectIdentifierFields = allFields.filter((field) => field.attributes.includes("ObjectIdentifier"));

  if (objectIdentifierFields.length === 0) {
    return <Typography>No object identifier fields defined.</Typography>;
  }

  return (
    <RoundedTable>
      <TableHead>
        <TableRow>
          <TableCell>Field Name</TableCell>
          <TableCell>Source</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {objectIdentifierFields.map((field) => (
          <TableRow key={field.id}>
            <TableCell>
              <ObjectFieldName field={field} />
            </TableCell>
            <TableCell>
              <FieldSourceBadge fieldSource={field.source} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </RoundedTable>
  );
};

export default ObjectIdentifierFieldsSection;
