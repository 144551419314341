import { Box, Card, CardContent, CardMedia, Link, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UserAvatar from "../../../../../shared/components/UserAvatar";
import { useUserContext } from "../../../../context/UserContext";
import AssistantAvatar from "../AssistantAvatar";
import { ChatItem } from "../chatState";

interface Props {
  item: ChatItem;
  onShowAsDialog: (imageUrl: string) => void;
}

const useStyles = makeStyles(() => ({
  animatedItem: {
    "&::after": {
      content: '""',
      width: "8px",
      height: "16px",
      top: "5px",
      ml: "4px",
      background: "black",
      display: "inline-block",
      verticalAlign: "text-bottom",
      animation: "$cursor-blink 1.5s steps(2) infinite",
    },
  },
  "@keyframes cursor-blink": { "0%": { opacity: 0 } },
}));

const ChatMessage = ({ item, onShowAsDialog }: Props) => {
  const classes = useStyles();
  const { name: userName, avatarImage: userAvatarImage } = useUserContext();

  const direction = item.source === "assistant" ? "row" : "row-reverse";
  const alignSelf = item.source === "assistant" ? "start" : "end";
  const borderRadius = item.source === "assistant" ? "8px 8px 8px 0" : "8px 8px 0 8px";
  const bgcolor = item.source === "assistant" ? "common.white" : "action.selected";

  const messageTextSplitByNewLine = (item.messageText ?? "").split("\n");

  const handleImageClick = () => {
    if (item.imageUrl) {
      onShowAsDialog(item.imageUrl);
    }
  };

  return (
    <Stack gap={1.5} direction={direction} alignSelf={alignSelf} justifyContent="end" alignItems="end" maxWidth={600}>
      {item.source === "assistant" ? (
        <AssistantAvatar />
      ) : (
        <UserAvatar size="large" userName={userName} avatarImage={userAvatarImage} />
      )}
      <Card variant="outlined" sx={{ borderRadius, bgcolor, "& .MuiCardContent-root": { p: 1 } }}>
        {item.imageUrl && (
          <CardMedia
            sx={{ width: 240, height: 240, cursor: "pointer" }}
            image={item.imageUrl}
            onClick={handleImageClick}
          />
        )}
        <CardContent>
          {
            <Box className={item.isPending ? classes.animatedItem : undefined}>
              {item.webUrl ? (
                <Link href={item.webUrl} target="_blank" rel="noopener noreferrer">
                  {messageTextSplitByNewLine.map((line, index) => (
                    <Typography key={index}>{line}</Typography>
                  ))}
                </Link>
              ) : (
                messageTextSplitByNewLine.map((line, index) => <Typography key={index}>{line}</Typography>)
              )}
            </Box>
          }
        </CardContent>
      </Card>
    </Stack>
  );
};

export default ChatMessage;
