import { Alert, Box } from "@mui/material";

interface Props {
  errorMessages: string[];
  onClose: () => void;
}

const MappingValidationErrors = ({ errorMessages, onClose }: Props) => {
  if (errorMessages.length === 0) {
    return null;
  }

  return (
    <Alert severity="error" onClose={onClose} sx={{ mt: 2.5 }}>
      {errorMessages.map((msg, i) => (
        <Box key={i}>{msg}</Box>
      ))}
    </Alert>
  );
};

export default MappingValidationErrors;
