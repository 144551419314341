import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { setUpAxios } from "../shared/api/axiosHelperAdvanced";
import Error from "../shared/components/Error";
import { Licenses } from "../shared/licensing";
import { initAppInsights } from "../shared/logging";
import { handleRedirectUrlState } from "../shared/utilities/handleRedirectUrlState";
import { PortalVariablesTarget, setPortalTarget } from "../shared/variables";
import App from "./App";
import LogoutContainer from "./auth/LogoutContainer";
import { lightTheme } from "./themes";

LicenseInfo.setLicenseKey(Licenses.MUI_X_PREMIUM_KEY);

const redirected = handleRedirectUrlState();

if (!redirected) {
  initAppInsights({ appId: "AdminPortal" });
  setPortalTarget(PortalVariablesTarget.AdminPortal);
  setUpAxios();
}

const Entry = () => {
  const { search } = useLocation();
  if (redirected) return null;

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Routes>
        <Route path="/:client/*" element={<Outlet />}>
          <Route path="logout" element={<LogoutContainer />} />
          <Route path="*" element={<App />} />
        </Route>
        <Route path="/error" element={<Error />} />
        <Route path="" element={<Navigate to={`/entrilia${search}`} />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Entry;
