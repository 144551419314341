import { Box, Button, DialogActions } from "@mui/material";
import DialogHeader from "../../../../components/dialog/DialogHeader";
import PromptDialog from "../../../../components/dialog/PromptDialog";
import DataSource from "../DataSources";
import { useMemo, useState } from "react";
import { ClientInfo, ReportTemplate } from "../../../api/biClient.types";
import { useSharedReportingLocalization } from "../../../hooks/useLocalization";

interface Props {
  template: ReportTemplate;
  companies: ClientInfo[];
  onClose: () => void;
  onRun: () => void;
  getEditReportTemplateUrl: (organization: string, dataSource: string, templateId: string) => string;
}

export default function RunWithCompanyDialog({ onClose, getEditReportTemplateUrl, template, onRun, companies }: Props) {
  const { report_templates: locale } = useSharedReportingLocalization();
  const [selectedDataSource, setDataSource] = useState<string>(() => companies.at(0)?.clientCode || "");

  const templateUrl = useMemo(() => {
    if (!selectedDataSource) return "";
    return getEditReportTemplateUrl(template.organization, selectedDataSource, template.reportId);
  }, [template.organization, getEditReportTemplateUrl, selectedDataSource, template.reportId]);

  return (
    <PromptDialog open={true} sx={{ width: 517, minHeight: 215 }}>
      <DialogHeader text="Select Data Source" onClose={onClose} />
      <Box sx={{ pt: 1, px: 3, pb: 2.5 }}>
        <DataSource
          title={undefined}
          disclaimer={locale.datasource_disclaimer}
          dataSource={selectedDataSource}
          companies={companies}
          onChange={(ds) => setDataSource(ds)}
        />
      </Box>
      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button href={templateUrl} target="_blank" variant="contained" disabled={!templateUrl} onClick={onRun}>
          Run
        </Button>
      </DialogActions>
    </PromptDialog>
  );
}
