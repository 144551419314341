import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { PropsWithChildren } from "react";

interface Props {
  title: string;
  Actions: React.ReactNode;
}

const ReportsPageContainer = ({ Actions, children, title }: PropsWithChildren<Props>) => {
  return (
    <Stack
      spacing={2.5}
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          pt: 0.5,
          pl: 3,
          pr: 3,
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Box>
          <Typography sx={(theme) => ({ height: theme.spacing(6.5), pt: 2.2 })} variant="h6">
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>{Actions}</Box>
      </Box>
      {children}
    </Stack>
  );
};

export default ReportsPageContainer;
