import { createSvgIcon } from "@mui/material";

const SparklesIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" stroke="currentColor">
    <path
      d="M10 2.5L8.40667 7.34417C8.32512 7.59209 8.1865 7.8174 8.00195 8.00195C7.8174 8.1865 7.59209 8.32512 7.34417 8.40667L2.5 10L7.34417 11.5933C7.59209 11.6749 7.8174 11.8135 8.00195 11.9981C8.1865 12.1826 8.32512 12.4079 8.40667 12.6558L10 17.5L11.5933 12.6558C11.6749 12.4079 11.8135 12.1826 11.9981 11.9981C12.1826 11.8135 12.4079 11.6749 12.6558 11.5933L17.5 10L12.6558 8.40667C12.4079 8.32512 12.1826 8.1865 11.9981 8.00195C11.8135 7.8174 11.6749 7.59209 11.5933 7.34417L10 2.5Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.16675 2.5V5.83333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.8333 14.1667V17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 4.16666H5.83333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1667 15.8333H17.5001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  "SparklesIcon"
);

export default SparklesIcon;
