import { Stack } from "@mui/material";
import saveAs from "file-saver";
import objectHash from "object-hash";
import { useCallback, useEffect, useReducer, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";
import { useClientContext } from "../../../../context/ClientContext";
import {
  firstPageAction,
  getInitialPaginatedItemsState,
  getPagingParams,
  loadItemsAction,
  nextPageAction,
} from "../../../../state/paginatedState";
import storage from "../../../../storage/storage";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { createReducer, getInitialState } from "../../../common/filters/filterState";
import { emptySearchFilterDefinition } from "../../../common/filters/handlers/filterHandlers";
import DocumentViewsGrid from "./DocumentViewsGrid";
import { getCsvToExport } from "./csvFormatter";
import { filterDefinitions, getRequestFilters } from "./documentViewsFilterDefinitions";
import { getSelectOptionsForDocumentViews } from "./documentViewsSelectOptionsProvider";

const DocumentActivityPage = () => {
  const { clientCode, clientTitle } = useClientContext();

  const [pageState, setPageState] = useState(getInitialPaginatedItemsState<DocumentViews>());

  const [filterState, dispatchFilters] = useReducer(
    createReducer<DocumentViews>(),
    getInitialState(`${clientCode}_document_activity_filter_v1`, filterDefinitions, emptySearchFilterDefinition(), [])
  );

  const [updateTrigger, setUpdateTrigger] = useState(false);

  const resetPageOnFiltersChange = useCallback(() => {
    if (pageState.page !== 0) {
      setPageState(firstPageAction());
    } else {
      setUpdateTrigger((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectHash(getRequestFilters(filterState))]);

  useEffect(() => {
    resetPageOnFiltersChange();
  }, [resetPageOnFiltersChange]);

  const getDocumentViews = useCallback(
    () =>
      adminApi.searchDocumentViews({
        paging: getPagingParams(pageState.page),
        ...getRequestFilters(filterState),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageState.page, updateTrigger]
  );

  const [, fetchError, { isFetching, fetch: fetchDocumentViews }] = useFetch(getDocumentViews, (resp) => {
    setPageState(loadItemsAction(resp));
  });

  if (fetchError) {
    logError(fetchError, "[DocumentActivityPage]");
    storage.clearFilterState(`${clientCode}_document_activity_filter_v1`);
    return <DataLoadingFailed title="Could not load document activity" />;
  }

  const handleRefresh = () => {
    if (pageState.page === 0) {
      fetchDocumentViews();
    } else {
      setPageState(firstPageAction());
    }
  };

  const handleExportToCsv = () => {
    const csv = getCsvToExport(pageState.items);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const fileName = `${clientTitle} Document Activity.csv`;
    saveAs(blob, fileName);
  };

  const handleRowsScrollEnd = () => {
    setTimeout(() => setPageState(nextPageAction()), 100);
  };

  return (
    <>
      <GeneralPageHeader title="Document Activity" />
      <Stack spacing={2.5} py={2.5} px={3} height="100%">
        <FilterContextProvider
          filterState={filterState}
          dispatchFilters={dispatchFilters}
          getSelectOptions={getSelectOptionsForDocumentViews}
        >
          <FiltersPanel
            totalCount={pageState.totalRecords}
            recordCount={pageState.items.length}
            isLoading={isFetching}
            onRefresh={handleRefresh}
            onExportToCsv={handleExportToCsv}
            withoutSearch
          />
        </FilterContextProvider>
        <DocumentViewsGrid isLoading={isFetching} rows={pageState.items} onRowsScrollEnd={handleRowsScrollEnd} />
      </Stack>
    </>
  );
};

export default DocumentActivityPage;
