import VerticalNavigationMenuItem from "../../common/navigation/VerticalNavigationMenuItem";
import VerticalNavigationSection from "../../common/navigation/VerticalNavigationSection";
import VerticalNavigationWrapper from "../../common/navigation/VerticalNavigationWrapper";
import {
  DataCollectionsNavigationView,
  dataCollectionsNavigationViews,
  dataCollectionsNavigationViewsCaptionMap,
} from "./dataCollectionsPageTypes";

interface Props {
  selectedView: DataCollectionsNavigationView;
  onSelectedViewChange: (view: DataCollectionsNavigationView) => void;
}

const DataCollectionsNavigation = ({ selectedView, onSelectedViewChange }: Props) => {
  return (
    <VerticalNavigationWrapper>
      <VerticalNavigationSection title="Menu">
        {dataCollectionsNavigationViews.map((view) => (
          <VerticalNavigationMenuItem
            key={view}
            text={dataCollectionsNavigationViewsCaptionMap[view]}
            selected={selectedView === view}
            onClick={() => onSelectedViewChange(view)}
          />
        ))}
      </VerticalNavigationSection>
    </VerticalNavigationWrapper>
  );
};

export default DataCollectionsNavigation;
