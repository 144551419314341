import { Stack, Tooltip } from "@mui/material";
import TypographyTooltipEllipsis from "../../../../shared/components/TypographyTooltipEllipsis";
import { MetricDataType } from "../../../api/types/portfolioMonitoringTypes";
import MetricDataTypeIcon from "./MetricDataTypeIcon";

interface Props {
  name: string;
  dataType: MetricDataType;
  description?: string;
}

const MetricName = ({ name, dataType, description }: Props) => {
  return (
    <Tooltip arrow title={description} enterDelay={500} enterNextDelay={500}>
      <Stack direction="row" spacing={1} width="100%">
        <MetricDataTypeIcon dataType={dataType} color="action" />
        <TypographyTooltipEllipsis text={name} typographyProps={{ sx: { pl: 0.5 } }} />
      </Stack>
    </Tooltip>
  );
};

export default MetricName;
