import { Box } from "@mui/material";
import Quill, { EmitterSource, Range } from "quill";
import "quill/dist/quill.snow.css";
import { useCallback, useEffect, useState } from "react";
import { Primitive } from "../../types";
import { getHtmlEditorStyles, HtmlEditorStyles } from "./htmlEditorStyles";

type ToolbarElement = string | Record<string, Primitive | Primitive[]>;

interface Props {
  initialHtmlContent: string;
  onChange: (content: string, souce: "user" | "api" | "silent") => void;
  disabled?: boolean;
  styles?: HtmlEditorStyles;
  toolbar: ToolbarElement[][];
  formats?: string[];
}

const defaultHtmlEditorModules = {
  history: {
    userOnly: true,
  },
};

const defaultFormats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
];

const sanitizeSemanticHtml = (html: string) => html.replace(/<p>\s*<\/p>/g, "<p><br></p>"); // Line breaks are converted into <p></p> in semantic HTML result; need to keep them

const HtmlEditor = ({ initialHtmlContent, onChange, disabled, styles, toolbar, formats }: Props) => {
  const [editor, setEditor] = useState<Quill>();

  const initEditor = (editorElement: HTMLDivElement | null) => {
    if (!editor && editorElement) {
      const quill = new Quill(editorElement, {
        theme: "snow",
        modules: { ...defaultHtmlEditorModules, toolbar },
        readOnly: disabled,
        formats: formats ?? defaultFormats,
      });

      setEditor(quill);
    }
  };

  const [selectionBeforeBlur, setSelectionBeforeBlur] = useState<Range>();

  const handleTextChange = useCallback(
    (_range: Range | null, _oldRange: Range | null, source: EmitterSource) => {
      if (!editor) {
        return;
      }

      const html = sanitizeSemanticHtml(editor.getSemanticHTML());
      onChange(html, source);
    },
    [editor, onChange]
  );

  const handleSelectionChange = useCallback(
    (range: Range | null, oldRange: Range | null, source: EmitterSource) => {
      if (!editor) {
        return;
      }

      const hasGainedFocus = !oldRange && range;
      const hasLostFocus = oldRange && !range;

      if (hasLostFocus && source === "user") {
        const isLinkTooltipOpen = Boolean(document.querySelector('.ql-tooltip[data-mode="link"]:not(.ql-hidden)'));
        if (isLinkTooltipOpen) {
          // Preserve selection when link tooltip opens
          editor.formatText(oldRange, "background", "Highlight");
          setSelectionBeforeBlur(oldRange);
        }
      } else if (hasGainedFocus && selectionBeforeBlur) {
        // Restore selection after link tooltip closes
        setSelectionBeforeBlur(undefined);
        setTimeout(() => editor.formatText(selectionBeforeBlur, "background", "transparent"), 250);
      }
    },
    [editor, selectionBeforeBlur]
  );

  useEffect(() => {
    if (!editor) {
      return;
    }

    if (disabled) {
      editor.disable();
    } else {
      editor.enable();
    }
  }, [disabled, editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.on("text-change", handleTextChange);
    editor.on("selection-change", handleSelectionChange);

    return () => {
      editor.off("text-change");
      editor.off("selection-change");
    };
  }, [editor, handleSelectionChange, handleTextChange]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    const currentSelection = editor.getSelection();
    editor.setContents(editor.clipboard.convert({ html: initialHtmlContent }));
    if (currentSelection) {
      Promise.resolve().then(() => editor.setSelection(currentSelection));
    }
  }, [editor, initialHtmlContent]);

  return (
    <Box sx={getHtmlEditorStyles(styles)} className="quill">
      <Box ref={initEditor} whiteSpace="pre" />
    </Box>
  );
};

export default HtmlEditor;
