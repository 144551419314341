import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { isAutoGeneratedRowNode } from "../../../../../../shared/components/grid/gridHelper";
import { convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import { nameof } from "../../../../../../shared/utilities/typeHelper";
import { OrganizationReportInfo } from "../../../../../api/biApi";
import { PrivateChip, SharedChip } from "../../../reporting/reports/grid/AccessChips";

export const accessColumn: GridColDef<OrganizationReportInfo> = {
  field: nameof<OrganizationReportInfo>("authorization"),
  headerName: "Access",
  flex: 0.3,
  valueGetter: (_, row) => row.authorization?.private ?? false,
  renderCell: (params: GridRenderCellParams) => {
    if (params.rowNode.type === "group") {
      return null;
    }

    if (params.row.authorization.private) {
      return <PrivateChip />;
    }

    return <SharedChip />;
  },
};

export const reportTypeColumn = {
  field: nameof<OrganizationReportInfo>("reportType"),
  headerName: "Report Type",
  flex: 0.3,
};

export const createdByColumn = {
  field: nameof<OrganizationReportInfo>("createdBy"),
  headerName: "Created by",
  flex: 0.5,
};

export const modifiedByColumn = {
  field: nameof<OrganizationReportInfo>("modifiedBy"),
  headerName: "Modified by",
  flex: 0.5,
};

export const getModifiedAtColumn = (apiRef: React.MutableRefObject<GridApiPremium>): GridColDef => ({
  field: nameof<OrganizationReportInfo>("updateAt"),
  headerName: "Modified at",
  flex: 0.4,
  renderCell: ({ id, value }) => {
    const treeNode = apiRef.current.getRowNode(id || "");
    if (treeNode && isAutoGeneratedRowNode(treeNode)) {
      return null;
    }

    return <Typography color="secondary">{convertISODateTime(value)}</Typography>;
  },
});
