import {
  Autocomplete,
  Button,
  Checkbox,
  ClickAwayListener,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useEffect, useRef, useState } from "react";
import ItemsListPopover from "../../../../shared/components/ItemsListPopover";
import TypographyTooltipEllipsis from "../../../../shared/components/TypographyTooltipEllipsis";
import { ValidationResult, invalidResult, validResult } from "../../../../shared/utilities/validators";
import { FieldAttribute, SelectOption } from "../../../api/types/objectTypes";
import { findNextEntityInput, findPreviousEntityInput } from "../entityFieldNavigator";
import FieldValueWrapper from "./FieldValueWrapper";

interface EntityFieldMultiSelectComponentProps {
  values?: string[] | null;
  options: SelectOption[];
  fieldAttributes: FieldAttribute[];
  onChange: (values: string[], validationResult: ValidationResult) => void;
  fieldInputId?: string;
}

interface MultiselectState {
  isEdit: boolean;
  validationResult: ValidationResult;
  selectedValues: string[];
}

const EntityFieldMultiSelectComponent = ({
  values,
  fieldAttributes,
  options,
  onChange,
  fieldInputId,
}: EntityFieldMultiSelectComponentProps) => {
  const [state, setState] = useState<MultiselectState>({
    isEdit: false,
    validationResult: validResult(),
    selectedValues: values || [],
  });
  const [popUpAnchorEl, setPopUpAnchorEl] = useState<HTMLElement | undefined>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!deepEqual(values, state.selectedValues)) {
      setState((current) => ({ ...current, selectedValues: values || [] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleValueOnLeave = () => {
    const validationResult =
      fieldAttributes.includes("Required") && state.selectedValues.length === 0
        ? invalidResult("Required field. Please select the necessary information in the select field.")
        : validResult();

    setState((current) => ({ ...current, isEdit: !validationResult.isValid, validationResult: validationResult }));
    onChange(state.selectedValues, validationResult);
  };

  const handleShowAllSelected = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPopUpAnchorEl(popUpAnchorEl ? undefined : event.currentTarget);
  };

  const handleEditChange = (edit: boolean) => {
    setState({ ...state, isEdit: edit });
    if (edit) {
      setTimeout(() => ref.current?.click());
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab" && fieldInputId) {
      const nextElement = e.shiftKey ? findPreviousEntityInput(fieldInputId) : findNextEntityInput(fieldInputId);
      if (nextElement) {
        e.preventDefault();
        nextElement.click();
      }
      return;
    }
  };

  const displayValues = state.selectedValues.map((value) => options.find((o) => o.value === value)?.label || value);

  return (
    <>
      {!state.isEdit && (
        <FieldValueWrapper
          id={fieldInputId}
          isReadonly={fieldAttributes.includes("ReadOnly")}
          displayValue={displayValues.join(", ") || ""}
          displayHoverComponent={
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              {state.selectedValues.length > 0 && (
                <Button onClick={handleShowAllSelected} color="primary">
                  Show All
                </Button>
              )}
              <ListItemsPopover
                items={state.selectedValues}
                title="Selected Values"
                popUpAnchorEl={popUpAnchorEl}
                onClose={() => {
                  setPopUpAnchorEl(undefined);
                }}
              />
            </Stack>
          }
          isEdit={!state.validationResult.isValid}
          onEditChange={handleEditChange}
        />
      )}
      {state.isEdit && (
        <ClickAwayListener onClickAway={handleValueOnLeave}>
          <Autocomplete
            ref={ref}
            openOnFocus
            multiple
            fullWidth
            sx={(t) => ({
              ".MuiInputBase-input": { color: t.palette.secondary.main },
            })}
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={state.selectedValues.map((value) => ({
              label: options.find((o) => o.value === value)?.label || value,
              value,
            }))}
            onChange={(event, options) => {
              event.stopPropagation();
              setState((current) => ({ ...current, selectedValues: options.map((v) => v.value) }));
            }}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} key={option.value} value={option.value} dense>
                <Checkbox checked={selected} />
                <ListItemText primary={<TypographyTooltipEllipsis text={option.label} />} />
              </MenuItem>
            )}
            renderTags={(values) => values.map((v) => v.label).join(", ")}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!state.validationResult.isValid}
                helperText={state.validationResult.error}
                onKeyDown={handleKeyDown}
              />
            )}
          />
        </ClickAwayListener>
      )}
    </>
  );
};

interface ListItemsPopoverProps {
  popUpAnchorEl: HTMLElement | undefined;
  items: string[];
  title: string;
  onClose: () => void;
}

const ListItemsPopover = ({ popUpAnchorEl, title, items, onClose }: ListItemsPopoverProps) => {
  return (
    <ItemsListPopover anchorEl={popUpAnchorEl} onClose={onClose}>
      <Typography color="textSecondary" variant="subtitle2">
        {title}
      </Typography>
      <Stack sx={{ maxHeight: 400, overflow: "auto" }} px={2} py={1} spacing={1}>
        {items.map((value, index) => (
          <TypographyTooltipEllipsis key={`msi-${index}`} text={value} />
        ))}
      </Stack>
    </ItemsListPopover>
  );
};

export default EntityFieldMultiSelectComponent;
