import { SvgIconComponent } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

interface Props {
  Icon: SvgIconComponent;
  label: string;
  value: string | React.ReactNode;
}

const ChangeRequestAttribute = ({ Icon, label, value }: Props) => {
  return (
    <Stack spacing={1.5} direction="row" alignItems="center" py={1}>
      <Stack spacing={1.5} direction="row" alignItems="center" width="8.5rem">
        <Icon color="action" />
        <Typography color="text.secondary">{label}</Typography>
      </Stack>
      {typeof value === "string" ? <Typography>{value}</Typography> : value}
    </Stack>
  );
};

export default ChangeRequestAttribute;
