import saveAs from "file-saver";
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import { ApiError } from "../../../../../shared/api/types";
import { ListItemData } from "../../../../../shared/components/inputs/CheckItemsList";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { formatDate } from "../../../../../shared/utilities/dateUtils";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../api/adminApi";
import {
  AccessLevelEntity,
  CompanyWithData,
  OrganizationDeactivatedMember,
  OrganizationMember,
  OrganizationMemberInvitation,
} from "../../../../api/types/userManagementTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { useUserContext } from "../../../../context/UserContext";
import ConfirmOperationDialog, { ConfirmDialogData } from "../../../common/ConfirmOperationDialog";
import MemberDialogIcon from "../../../common/MemberDialogIcon";
import OperationConfirmationPopup, { ConfirmationPopupData } from "../../../common/OperationConfirmationPopup";
import { MemberFilters } from "./definitions";
import { getCsvToExport } from "./members-list/exportMembers";
import {
  getFilteredDeactivatedMembers,
  getFilteredMembers,
  getFilteredPendingInvites,
} from "./members-list/filters/filtering";

interface ContextValue {
  clientTitle: string;
  hasAccessToManageOrganizationUsers: boolean;
  hasAccessToManageOrganizationOwners: boolean;
  companies: CompanyWithData[];
  companiesList: ListItemData[];
  filters: MemberFilters;
  setFilters: (filters: MemberFilters) => void;
  handleMembersSearch: (search: string) => void;
  members: OrganizationMember[];
  filteredMembers: OrganizationMember[];
  deactivatedMembers: OrganizationDeactivatedMember[];
  filteredDeactivatedMembers: OrganizationDeactivatedMember[];
  pendingInvites: OrganizationMemberInvitation[];
  filteredPendingInvites: OrganizationMemberInvitation[];
  updateMembers: () => void;
  updatePendingInvites: () => void;
  isLoading: boolean;
  fetchError: ApiError | undefined;
  activeToolbarTab: MembersTab;
  switchActiveToolbarTab: (tab: MembersTab) => void;
  selectedMemberIds: string[] | undefined;
  setSelectedMembersIds: (member: string[] | undefined) => void;
  deleteMembers: (userIds: string[]) => void;
  clearFilters: () => void;
  deleteInvite: (invite: OrganizationMemberInvitation) => void;
  isSending: boolean;
  setIsSending: (isSending: boolean) => void;
  confirmationPopupData: ConfirmationPopupData | undefined;
  setConfirmationPopupData: (confirmationPopupData: ConfirmationPopupData | undefined) => void;
  resendInvite: (invite: OrganizationMemberInvitation) => void;
  showInviteMembersDialog: boolean;
  inviteMembersDialogData: MemberInvitationDialogData | undefined;
  setInviteMembersDialogData: (inviteMembersDialogData: MemberInvitationDialogData | undefined) => void;
  openInviteMembersDialog: () => void;
  duplicateNewMembersFrom(member: OrganizationMember): void;
  manageAccess(member: OrganizationMember): void;
  exportToCsv: () => void;
  getAccessLevelEntities: (clientCode: string) => AccessLevelEntity[];
  isCurrentUser: (idOrEmail: string) => boolean;
  currentUserData: [string, string];
}

interface MemberInvitationDialogData {
  emails?: string[];
  member?: OrganizationMember;
  action: "invite" | "manage";
}

interface MembersContextProviderProps {
  hasAccessToManageOrganizationUsers: boolean;
  hasAccessToManageOrganizationOwners: boolean;
}

export type MembersTab = "active" | "deactivated" | "pending";

const MembersContext = createContext<ContextValue | undefined>(undefined);

export const MembersContextProvider = ({
  hasAccessToManageOrganizationUsers,
  hasAccessToManageOrganizationOwners,
  children,
}: PropsWithChildren<MembersContextProviderProps>) => {
  const { sendNotificationError } = useNotificationContext();
  const [isSending, setIsSending] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [confirmationPopupData, setConfirmationPopupData] = useState<ConfirmationPopupData | undefined>();
  const [filters, setFilters] = useState<MemberFilters>({
    search: "",
    types: [],
    role: undefined,
    companies: [],
  });
  const [activeToolbarTab, setActiveToolbarTab] = useState<MembersTab>("active");
  const [selectedMemberIds, setSelectedMembersIds] = useState<string[] | undefined>();
  const [confirmMembersDialogData, setConfirmMembersDialogData] = useState<ConfirmDialogData<string> | undefined>();
  const [inviteMembersDialogData, setInviteMembersDialogData] = useState<MemberInvitationDialogData | undefined>();

  const { clientTitle } = useClientContext();

  const { email: currentUserEmail, id: currentUserId } = useUserContext();

  const [companiesResp, companiesFetchError, { isFetching: isFetchingCompanies }] = useFetch(
    adminApi.getOrganizationManagedCompanies
  );

  const [membersResp, membersFetchError, { isFetching: isFetchingMembers, setData: setMembers, fetch: updateMembers }] =
    useFetch(adminApi.getOrganizationMembers);

  const [deactivatedMembersResp, deactivatedMembersFetchError, { isFetching: isFetchingDeactivatedMembers }] = useFetch(
    adminApi.getOrganizationDeactivatedMembers
  );

  const [
    pendingInvitesResp,
    pendingInvitesFetchError,
    { isFetching: isFetchingPendingInvites, setData: setPendingInvites, fetch: updatePendingInvites },
  ] = useFetch(adminApi.getOrganizationMembersInvitations);

  const deleteMembers = useCallback(
    async (memberIds: string[]) => {
      try {
        const results = await Promise.allSettled(
          memberIds.map((memberId) => adminApi.deleteOrganizationMember(memberId))
        );
        const failed = results.filter((result) => result.status === "rejected");
        if (failed.length > 0) {
          sendNotificationError(
            `Failed to delete ${failed.length} members. Please try again later or contact support.`
          );
        } else {
          setConfirmationPopupData({
            title: "Members Deleted",
            description: `${memberIds.length} member${memberIds.length > 1 ? "s" : ""} successfully deleted.`,
            onClose: () => setConfirmationPopupData(undefined),
            icon: <MemberDialogIcon />,
          });
          setMembers({ users: (membersResp?.users ?? []).filter((member) => !memberIds.includes(member.userId)) });
        }
      } catch (error) {
        sendNotificationError("Failed to delete members. Please try again later or contact support.");
      }
    },
    [membersResp?.users, sendNotificationError, setMembers]
  );

  const companiesList = useMemo(() => {
    return (companiesResp?.companies ?? []).map((client) => ({
      label: client.title,
      value: client.clientCode,
      iconSrc: client.branding.logoMarkUrl,
    }));
  }, [companiesResp?.companies]);

  const switchActiveToolbarTab = (tab: MembersTab) => {
    setActiveToolbarTab(tab);
    clearFilters();
    setSelectedMembersIds(undefined);
  };

  const clearFilters = () => {
    setFilters({ search: filters.search, types: [], role: undefined, companies: [] });
  };

  const handleDeleteMembers = (userIds: string[]) => {
    if (userIds.length === 0) {
      return;
    }

    if (userIds.length === 1) {
      const selectedMember = members.find((member) => member.userId === userIds[0]);
      setConfirmMembersDialogData({
        title: "Remove this member?",
        subtitle: selectedMember?.name,
        description: selectedMember?.email,
        ids: userIds,
        confirmCallback: () => handleConfirmDeleteMembers(userIds),
        confirmButtonColor: "error",
        confirmButtonLabel: "Remove",
        icon: <MemberDialogIcon />,
      });
      return;
    }

    setConfirmMembersDialogData({
      title: "Remove these members?",
      description: `${userIds.length} members selected`,
      ids: userIds,
      confirmCallback: () => handleConfirmDeleteMembers(userIds),
      confirmButtonColor: "error",
      confirmButtonLabel: "Remove",
      icon: <MemberDialogIcon />,
    });
  };

  const handleConfirmDeleteMembers = useCallback(
    async (ids: string[]) => {
      if (ids) {
        setUpdating(true);
        await deleteMembers(ids);
        setUpdating(false);
      }
      setConfirmMembersDialogData(undefined);
    },
    [deleteMembers]
  );

  const handleConfirmResendInvite = useCallback(
    async (invite: OrganizationMemberInvitation) => {
      setUpdating(true);
      try {
        const response = await adminApi.resendOrganizationUserInvite(invite.invitationId);
        setUpdating(false);
        if (response.success) {
          setConfirmationPopupData({
            title: "Invite resent",
            description: `Invite to ${invite.email} was successfully resent.`,
            onClose: () => setConfirmationPopupData(undefined),
            icon: <MemberDialogIcon />,
          });
          await updatePendingInvites();
        } else {
          sendNotificationError("Failed to resend invite. Please try again later or contact support.");
        }
      } catch (error) {
        logError(error, "MembersContext.handleConfirmResendInvite");
        sendNotificationError("Failed to resend invite. Please try again later or contact support.");
      }
      setUpdating(false);
      setConfirmMembersDialogData(undefined);
    },
    [sendNotificationError, updatePendingInvites]
  );

  const handleResendInvite = (invite: OrganizationMemberInvitation) => {
    setConfirmMembersDialogData({
      title: "Resend this invite",
      subtitle: invite.email,
      description: "Are you sure you want to resend this invite?",
      ids: [invite.invitationId],
      confirmCallback: () => handleConfirmResendInvite(invite),
      confirmButtonColor: "primary",
      confirmButtonLabel: "Resend",
      icon: <MemberDialogIcon />,
    });
  };

  const handleConfirmDeleteInvite = useCallback(
    async (invite: OrganizationMemberInvitation) => {
      setUpdating(true);
      try {
        const response = await adminApi.deleteOrganizationUserInvite(invite.invitationId);
        setUpdating(false);
        if (response.success) {
          setConfirmationPopupData({
            title: "Invite invalidated",
            description: `${invite.email} invite successfully invalidated.`,
            onClose: () => setConfirmationPopupData(undefined),
            icon: <MemberDialogIcon />,
          });
          setPendingInvites({
            invitations: (pendingInvitesResp?.invitations ?? []).filter(
              (inv) => inv.invitationId !== invite.invitationId
            ),
          });
        } else {
          sendNotificationError("Failed to invalidate invite. Please try again later or contact support.");
        }
      } catch (error) {
        logError(error, "MembersContext.handleConfirmDeleteInvite");
        sendNotificationError("Failed to invalidate invite. Please try again later or contact support.");
      }
      setUpdating(false);
      setConfirmMembersDialogData(undefined);
    },
    [pendingInvitesResp?.invitations, sendNotificationError, setPendingInvites]
  );

  const handleDeleteInvite = (invite: OrganizationMemberInvitation) => {
    setConfirmMembersDialogData({
      title: "Invalidate this invite",
      subtitle: invite.email,
      description: "Are you sure you want to invalidate this invite?",
      ids: [invite.invitationId],
      confirmCallback: () => handleConfirmDeleteInvite(invite),
      confirmButtonColor: "error",
      confirmButtonLabel: "Invalidate",
      icon: <MemberDialogIcon />,
    });
  };

  const openInvitationDialogAction = (action: "invite" | "manage", member?: OrganizationMember) =>
    setInviteMembersDialogData({
      action,
      member,
      emails: (membersResp?.users ?? [])
        .filter((member) => selectedMemberIds?.includes(member.userId))
        .map((member) => member.email),
    });

  const exportToCsv = () => {
    const csv = getCsvToExport(members, deactivatedMembers, companies);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const fileName = `${clientTitle} Members as of ${formatDate(new Date())}.csv`;
    saveAs(blob, fileName);
  };

  const companies = companiesResp?.companies ?? [];
  const members = membersResp?.users ?? [];
  const deactivatedMembers = deactivatedMembersResp?.deactivatedUsers ?? [];
  const pendingInvites = pendingInvitesResp?.invitations ?? [];

  const filteredMembers = activeToolbarTab === "active" ? getFilteredMembers(members, filters) : members;

  const filteredDeactivatedMembers =
    activeToolbarTab === "deactivated"
      ? getFilteredDeactivatedMembers(deactivatedMembers, filters)
      : deactivatedMembers;

  const filteredPendingInvites =
    activeToolbarTab === "pending" ? getFilteredPendingInvites(pendingInvites, filters) : pendingInvites;

  const isLoading =
    isFetchingCompanies || isFetchingMembers || isFetchingDeactivatedMembers || isFetchingPendingInvites || isUpdating;

  const fetchError =
    companiesFetchError || membersFetchError || deactivatedMembersFetchError || pendingInvitesFetchError;

  return (
    <MembersContext.Provider
      value={{
        clientTitle,
        hasAccessToManageOrganizationUsers,
        handleMembersSearch: (search) => setFilters({ ...filters, search }),
        members,
        filteredMembers,
        deactivatedMembers,
        filteredDeactivatedMembers,
        pendingInvites,
        filteredPendingInvites,
        updateMembers,
        updatePendingInvites,
        isLoading,
        fetchError,
        activeToolbarTab,
        switchActiveToolbarTab,
        selectedMemberIds,
        setSelectedMembersIds,
        deleteMembers: handleDeleteMembers,
        filters,
        setFilters,
        clearFilters,
        companies,
        companiesList,
        deleteInvite: handleDeleteInvite,
        isSending,
        setIsSending,
        confirmationPopupData,
        setConfirmationPopupData,
        resendInvite: handleResendInvite,
        inviteMembersDialogData,
        showInviteMembersDialog: inviteMembersDialogData !== undefined,
        setInviteMembersDialogData,
        manageAccess: (member: OrganizationMember) => openInvitationDialogAction("manage", member),
        duplicateNewMembersFrom: (member: OrganizationMember) => openInvitationDialogAction("invite", member),
        openInviteMembersDialog: () => openInvitationDialogAction("invite"),
        exportToCsv,
        getAccessLevelEntities: (clientCode: string) =>
          companies.find((company) => company.clientCode === clientCode)?.entities || [],
        isCurrentUser: (idOrEmail: string) =>
          idOrEmail.toLowerCase() === currentUserEmail.toLowerCase() ||
          idOrEmail.toLowerCase() === currentUserId.toLowerCase(),
        currentUserData: [currentUserId, currentUserEmail],
        hasAccessToManageOrganizationOwners,
      }}
    >
      {children}
      <ConfirmOperationDialog
        open={confirmMembersDialogData !== undefined}
        onClose={() => setConfirmMembersDialogData(undefined)}
        onConfirm={() => {
          if (confirmMembersDialogData && confirmMembersDialogData.confirmCallback) {
            confirmMembersDialogData.confirmCallback([...confirmMembersDialogData.ids]);
          }
        }}
        data={confirmMembersDialogData}
        isLoading={isLoading}
      />
      <OperationConfirmationPopup isLoading={isLoading} data={confirmationPopupData} />
    </MembersContext.Provider>
  );
};

export const useMembersContext = () => {
  const membersContext = useContext(MembersContext);
  return defined(membersContext);
};
