import { useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import useDebounce from "../../../shared/hooks/useDebounce";
import "./phoneField.css";

interface Props {
  name: string;
  containerClassName: string;
  InputProps: PhoneInputProps;
  onValueChanged: (value: string) => void;
  onValidated: (value: string, valid: boolean) => void;
  skipValidation?: boolean;
  disabled?: boolean;
}

const PhoneFieldExt = ({
  name,
  containerClassName,
  InputProps,
  onValueChanged,
  onValidated,
  skipValidation,
  disabled,
}: Props) => {
  const theme = useTheme();
  const [valid, setValid] = useState(true);
  const [focused, setFocused] = useState(false);

  const debounceFilterText = useDebounce((value: string) => {
    const phoneNumber = value.trim().substring(value.length > 0 && value[0] === "+" ? 1 : 0);
    onValueChanged?.call(this, phoneNumber);
    const isValid = doValidate(phoneNumber);
    onValidated(name, isValid);
    setValid(isValid);
  }, 200);

  useEffect(() => {
    const labelEl = document.querySelector<HTMLElement>(`.${containerClassName} > .special-label`);
    if (labelEl) {
      const color = focused
        ? valid
          ? theme.palette.primary.main
          : theme.palette.error.main
        : theme.palette.secondary.main;
      labelEl.style.left = "5px";
      labelEl.style.top = "-11px";
      labelEl.style.fontSize = "13px";
      labelEl.style.transform = "scale(0.75)";
      labelEl.style.color = disabled ? "rgba(0, 0, 0, 0.38)" : color;
    }

    const flagDropDown = document.querySelector<HTMLElement>(".flag");
    if (flagDropDown && flagDropDown.classList.contains("0")) {
      flagDropDown.classList.add("empty-flag");
    } else if (flagDropDown && flagDropDown.classList.contains("empty-flag") && !flagDropDown.classList.contains("0")) {
      flagDropDown.classList.remove("empty-flag");
    }
  }, [
    containerClassName,
    focused,
    valid,
    theme.palette.error.main,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    disabled,
  ]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    debounceFilterText(e.target.value);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const doValidate = (value: string) => {
    if (skipValidation) {
      return true;
    }

    value = value.trim();

    let isValid = true;
    if (!value || value.length < 10) {
      isValid = false;
    }

    return isValid;
  };

  const inputStyle: React.CSSProperties = {
    padding: "7.5px 12px 7.5px 54px",
    fontSize: "13px",
    color: theme.palette.text.primary,
    width: "100%",
  };

  if (focused) {
    const borderColor = valid ? theme.palette.primary.main : theme.palette.error.main;
    inputStyle.borderColor = borderColor;
    inputStyle.boxShadow = `0 0 0 1px ${borderColor}`;
    inputStyle.width = "100%";
  }

  if (disabled) {
    inputStyle.border = "1px solid #ccc";
    inputStyle.color = "rgba(0, 0, 0, 0.38)";
  }

  return (
    <PhoneInput
      disabled={disabled}
      {...InputProps}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={debounceFilterText}
      isValid={valid}
      containerClass={containerClassName}
      inputStyle={inputStyle}
    />
  );
};

export default PhoneFieldExt;
