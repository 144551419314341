import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useMemo } from "react";
import BadgeDetached from "../../../../../../../shared/components/BadgeDetached";
import { CopyCompany } from "../hooks/CopyCompany";

interface Props {
  selectedTab: TabNames;
  copying: CopyCompany[];
  setSelectedTab: (value: TabNames) => void;
}
export function ResultButtongroup({ selectedTab, copying, setSelectedTab }: Props) {
  const totalReportsCount = useMemo(() => copying.flatMap((c) => c.reports).length, [copying]);
  const copiedCount = useMemo(() => {
    return copying.flatMap((c) => c.reports).filter((r) => r.copied).length;
  }, [copying]);

  const failedCount = useMemo(() => {
    return copying.flatMap((c) => c.reports).filter((r) => r.error).length;
  }, [copying]);

  return (
    <ToggleButtonGroup
      fullWidth
      sx={{
        height: 30,
        ".MuiToggleButton-root.Mui-selected": {
          backgroundColor: "rgba(35, 52, 59, 0.04)",
        },
      }}
      value={selectedTab}
      exclusive
      onChange={(_, value) => value && setSelectedTab(value)}
    >
      <ToggleButton value="all" sx={{ gap: 1 }}>
        All <BadgeDetached color="secondary" badgeContent={totalReportsCount.toLocaleString()} />
      </ToggleButton>
      <ToggleButton value="copied" sx={{ gap: 1 }}>
        Copied <BadgeDetached color="secondary" badgeContent={copiedCount.toLocaleString()} />
      </ToggleButton>
      <ToggleButton value="failed" sx={{ gap: 1 }}>
        Failed{" "}
        <BadgeDetached color={failedCount === 0 ? "secondary" : "error"} badgeContent={failedCount.toLocaleString()} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export type TabNames = "all" | "copied" | "failed";
