import { AnyRecord } from "../../../../../shared/types";
import { SearchFilter } from "../filterTypes";

export const searchMatchesRow = <R extends AnyRecord>(search: SearchFilter<R>, row: R) => {
  const searchValue = search.value.trim();
  if (!searchValue) {
    return true;
  }

  return search
    .getFieldValues(row)
    .some((fieldValue) => !!fieldValue && fieldValue.toString().toLowerCase().includes(searchValue.toLowerCase()));
};
