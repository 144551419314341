import { SvgIcon, SvgIconProps } from "@mui/material";

const ImageFileIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" fill="#FCE4EC" stroke="#F8BBD0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8868 8C16.9914 8 17.8868 7.10457 17.8868 6C17.8868 4.89543 16.9914 4 15.8868 4C14.7822 4 13.8868 4.89543 13.8868 6C13.8868 7.10457 14.7822 8 15.8868 8ZM19.0564 20C19.4331 20 19.6745 19.5991 19.4982 19.2661L16.0042 12.6661C15.8368 12.3499 15.4011 12.3068 15.1749 12.5839L12.8872 15.387C12.6549 15.6716 12.2053 15.6169 12.048 15.2849L8.66013 8.13544C8.46649 7.72678 7.87297 7.76469 7.73289 8.19466L4.10016 19.3451C3.99489 19.6682 4.23572 20 4.57557 20H19.0564Z"
      fill="#F06292"
    />
  </SvgIcon>
);

export default ImageFileIcon;
