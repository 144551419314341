import { Divider, Grid } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { useLocalization } from "../../../hooks/useLocalization";
import { useGroupsContext } from "../../../../shared/reporting/components/groups/contexts/GroupsContext";
import { ReportGroup } from "../../../../shared/reporting/api/biClient.types";
import { useClientContext } from "../../../context/ClientContext";
import { defined } from "../../../../shared/utilities/typeHelper";
import GroupItem from "./GroupItem";
import GroupActions from "./actions/GroupActions";
import CustomDragLayer from "./CustomDragLayer";
import { AccessPermission } from "../../../../shared/api/types";
import { GroupType } from "./GroupType";

interface Props {
  permissions: AccessPermission[];
  type: GroupType;
}
export const GroupsGrid = ({ permissions, type }: Props) => {
  const { clientCode } = useClientContext();
  const { groups, actions } = useGroupsContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { groups: locale } = useLocalization();

  const [currentGroupId, setCurrentGroupId] = useState<string>();
  const [actionMenuRef, setActionMenuRef] = useState<HTMLElement | null>(null);
  const [sortedGroups, setSortedGroups] = useState<ReportGroup[]>([]);

  useEffect(() => {
    setSortedGroups([...defined(groups[clientCode])].sort((l, r) => Number(l.order - r.order)));
  }, [clientCode, groups]);

  const sortedGroupsRef = useRef(sortedGroups);
  sortedGroupsRef.current = sortedGroups;

  const onDragMove = useCallback((item: ReportGroup, replaceItem: ReportGroup) => {
    const values = [...sortedGroupsRef.current];
    const fieldIndex = values.findIndex((v) => v.id === item.id);
    const toIndex = values.findIndex((v) => v.id === replaceItem.id);
    if (fieldIndex > -1) {
      values.splice(fieldIndex, 1);
      values.splice(toIndex, 0, item);
      setSortedGroups(values);
    }
  }, []);

  const onEndMoveItem = useCallback(() => {
    const sorted = sortedGroupsRef.current.map((value, index) => {
      return { ...value, order: index + 1 } as ReportGroup;
    });
    actions
      .reorder(clientCode, sorted)
      .then(() => sendNotification(locale.reordered))
      .catch(() => sendNotificationError(locale.reorder_error));
  }, [actions, clientCode, locale.reorder_error, locale.reordered, sendNotification, sendNotificationError]);

  const onShowMenu = useCallback((anchorEl: HTMLElement | null, groupId: string) => {
    setCurrentGroupId(groupId);
    setActionMenuRef(anchorEl);
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Divider />
        {sortedGroups.map((rg) => {
          return (
            <GroupItem
              key={rg.id}
              level={rg}
              onShowMenu={onShowMenu}
              onDragMove={onDragMove}
              onEndMoveItem={onEndMoveItem}
              permissions={permissions}
            />
          );
        })}
      </Grid>
      {actionMenuRef && currentGroupId && (
        <GroupActions
          anchorEl={actionMenuRef}
          currentGroupId={currentGroupId}
          showRename
          showDelete
          onClose={() => setActionMenuRef(null)}
          type={type}
        />
      )}
      <CustomDragLayer />
    </DndProvider>
  );
};

export default GroupsGrid;
