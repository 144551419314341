import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Container, Stack } from "@mui/material";
import { useConsentsContext } from "./ConsentsContext";
import ConsentsGrid from "./ConsentsGrid";

const ConsentsList = () => {
  const { isLoading, canEdit, handleNewConsent } = useConsentsContext();

  return (
    <Container maxWidth={false} disableGutters sx={{ height: "100%" }}>
      <Box display="flex" justifyContent="flex-end">
        {canEdit && (
          <Button
            sx={{ whiteSpace: "nowrap" }}
            disabled={isLoading}
            onClick={handleNewConsent}
            variant="contained"
            startIcon={<AddIcon />}
          >
            New Consent
          </Button>
        )}
      </Box>
      <Stack spacing={2} sx={{ height: 1, width: 1, px: 3, mt: 2 }}>
        <ConsentsGrid />
      </Stack>
    </Container>
  );
};

export default ConsentsList;
