import { Field, FieldGroup, FieldIdToValueMap } from "../../api/types/objectTypes";
import { FieldGroupModel } from "./entityFieldTypes";

export const getVisibleFieldGroupModels = (
  fieldGroups: FieldGroup[],
  forceReadOnly: boolean,
  objectDefinitionFields: Field[]
): FieldGroupModel[] => {
  return fieldGroups
    .filter((g) => g.fields.some((f) => f.isVisible))
    .map((group) => {
      const fields: Field[] = [];

      group.fields
        .filter((f) => f.isVisible)
        .forEach((fieldRef) => {
          const field = objectDefinitionFields.find((f) => f.id === fieldRef.fieldId);
          if (field) {
            const fieldModel = { ...field };
            if (forceReadOnly) {
              fieldModel.attributes.push("ReadOnly");
            }
            fields.push(fieldModel);
          }
        });

      return {
        name: group.name,
        fields,
      };
    });
};

export const updateFieldValues = (
  fieldValues: FieldIdToValueMap,
  value: unknown,
  fieldId: string
): FieldIdToValueMap => {
  if (value === undefined) {
    const existingFieldValue = fieldValues[fieldId];
    if (existingFieldValue) {
      fieldValues[fieldId] = null;
    }
  } else {
    fieldValues[fieldId] = value;
  }

  return fieldValues;
};

export const removeDecimalNotSupportedCharacters = (input: string): string => input.replace(/[^\d.,]/g, "");

const isValueEmpty = (v: unknown) => v === undefined || v === null || v === "" || (Array.isArray(v) && !v.length);

export const isAnyRequiredFieldEmpty = (fields: Field[], fieldValues: FieldIdToValueMap): boolean =>
  fields.filter((f) => f.attributes.includes("Required")).some((f) => isValueEmpty(fieldValues[f.id]));
