import { useCallback } from "react";
import DataLoadingFailed from "../../../shared/components/DataLoadingFailed";
import useFetch from "../../../shared/hooks/useFetch";
import { logError } from "../../../shared/logging";
import adminApi from "../../api/adminApi";
import { useNotesContext } from "../notes/NotesContext";
import NotesList from "../notes/NotesList";
import EntitySection from "./EntitySection";
import EntitySectionHeader from "./EntitySectionHeader";

const EntityNotesSection = () => {
  const { entityType, entityId, objectType } = useNotesContext();

  const getNotes = useCallback(
    async () => adminApi.getNotes(entityType, entityId, objectType),
    [entityId, entityType, objectType]
  );

  const [notes, fetchError] = useFetch(getNotes);

  if (fetchError) {
    logError(fetchError, "[NotesSection]");
    return <DataLoadingFailed title="Failed to load notes" />;
  }

  return (
    <EntitySection isLoading={notes === undefined}>
      <EntitySectionHeader title="Notes" />
      {notes && <NotesList notes={notes} />}
    </EntitySection>
  );
};

export default EntityNotesSection;
