import { useCallback } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { FundDetails } from "../../../../../api/adminApi";
import { FieldIdToValueMap, ObjectClassDefinition, ObjectPermissions } from "../../../../../api/types/objectTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import ScrollableColumns from "../../../../common/layout/ScrollableColumns";
import CreatedAndModifiedTimestamps from "../../../../entityFields/CreatedAndModifiedTimestamps";
import EntityNotesSection from "../../../../entityFields/EntityNotesSection";
import EntitySection from "../../../../entityFields/EntitySection";
import FieldValuesManager from "../../../../entityFields/FieldValuesManager";
import { getNotesEntityType } from "../../../../entityFields/entityTypesHelper";
import { NotesContextProvider } from "../../../../notes/NotesContext";

interface Props {
  fundDetails: FundDetails;
  objectDefinition: ObjectClassDefinition;
  objectPermissions: ObjectPermissions;
  onFundDetailsChange: (details: Partial<FundDetails>) => void;
}

const updateFieldValues = withErrorHandling(adminApi.updateFundFieldValues);

const FundMainInfoTab = ({ fundDetails, objectDefinition, objectPermissions, onFundDetailsChange }: Props) => {
  const { id, createdAt, updateAt } = fundDetails;

  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { hasAnyPermission } = useClientContext();

  const getFieldValues = useCallback(() => adminApi.getFundFieldValues(id), [id]);
  const [fieldValuesResp, fieldValuesError, { setData: setFieldValues, isFetching }] = useFetch(getFieldValues);

  const hasPermissionsToViewNotes = hasAnyPermission(objectPermissions.notesReadPermissions);
  const hasPermissionsToManageNotes = hasAnyPermission(objectPermissions.notesWritePermissions);
  const hasPermissionsToManageFields = hasAnyPermission(objectPermissions.objectDataWritePermissions);

  const saveFieldValues = async (fieldValues: FieldIdToValueMap) => {
    const values = Object.entries(fieldValues).map(([fieldId, value]) => ({ fieldId, value }));
    const [resp, error] = await updateFieldValues(id, { values });
    if (error) {
      sendNotificationError(getErrorMessage(error) || "Failed to save changes");
      logError(error, `[FundDetailsMainTab] saveFieldValues`);
      return false;
    }

    sendNotification("Changes saved");
    setFieldValues(resp);

    const nameFieldValue = resp.values.find(({ fieldId }) => fieldId.toLowerCase() === "name");
    const name = nameFieldValue?.value?.toString() || fundDetails.name;
    onFundDetailsChange({ name, updateAt: new Date().toISOString() });

    return true;
  };

  if (fieldValuesError) {
    logError(fieldValuesError, "[FundDetailsMainTab]");
    return <DataLoadingFailed bgColor="none" title="Loading fund fields" />;
  }

  const fieldValues = fieldValuesResp
    ? Object.fromEntries(fieldValuesResp.values.map(({ fieldId, value }) => [fieldId, value]))
    : {};

  return (
    <ScrollableColumns gridProps={{ columnSpacing: 2.5 }}>
      <>
        <EntitySection isLoading={isFetching}>
          {fieldValuesResp && (
            <FieldValuesManager
              objectDefinition={objectDefinition}
              fieldValues={fieldValues}
              hasPermissionsToManageFields={hasPermissionsToManageFields}
              saveFieldValues={saveFieldValues}
            />
          )}
        </EntitySection>
        <CreatedAndModifiedTimestamps stackProps={{ mt: 2.5 }} createdAt={createdAt} modifiedAt={updateAt} />
      </>
      {hasPermissionsToViewNotes && (
        <NotesContextProvider
          entityType={getNotesEntityType(objectDefinition.objectType)}
          entityId={id}
          hasPermissionsToManageNotes={hasPermissionsToManageNotes}
        >
          <EntityNotesSection />
        </NotesContextProvider>
      )}
    </ScrollableColumns>
  );
};

export default FundMainInfoTab;
