import { Box, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { MemberRole } from "../../../../../api/types/userManagementTypes";

interface Option {
  value: MemberRole;
  label: string;
}

interface Props {
  selectedValue?: MemberRole;
  options: Option[];
  onChange: (value: MemberRole) => void;
}

const PermissionSelect = ({ options, selectedValue, onChange }: Props) => {
  if (options.length === 0) {
    return null;
  }

  if (options.length === 1) {
    return (
      <Box pr={4}>
        <Typography>{options[0]?.label}</Typography>
      </Box>
    );
  }

  return (
    <Select
      sx={{ boxShadow: "none", ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
      value={selectedValue}
      onChange={(e) => onChange(e.target.value as MemberRole)}
    >
      {options.map((option) => (
        <MenuItem selected={option.value === selectedValue} key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PermissionSelect;
