import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMemo } from "react";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import BackdropLoader from "../../../../../../shared/components/fullScreenLoader/BackdropLoader";
import ChipsMultilineInput from "../../../../../../shared/components/inputs/ChipsMultilineInput";
import { emailValidator } from "../../../../../../shared/utilities/validators";
import { useMembersContext } from "../MembersContext";
import AccessSection from "./AccessSection";
import CompaniesManageSection from "./CompaniesManageSection";
import DeveloperPortal from "./DeveloperPortal";
import { useInviteMembersContext } from "./InviteMembersContext";
import ManagedMemberDetails from "./ManagedMemberDetails";
import OrganizationRoleSelector from "./OrganizationRoleSelector";
import PermissionsBlankIcon from "./PermissionsBlankIcon";

const InviteMembersContainer = () => {
  const theme = useTheme();
  const borderLineStyle = `1px ${theme.palette.divider} solid`;

  const { isSending, showInviteMembersDialog, members, companiesList } = useMembersContext();

  const {
    emails,
    updateEmails,
    selectedCompanyAccess,
    isValidConfiguration,
    handleClose,
    sendInvites,
    getDialogTitle,
    managedMember,
    applyMemberChanges,
    isCurrentUser,
  } = useInviteMembersContext();

  const devPortalAccessibleCompanies = useMemo(() => {
    const accessibleCompanies = members.find((m) => m.userId === managedMember?.userId)?.accessToDeveloperPortal || [];
    return accessibleCompanies.map((companyCode) => {
      const companyData = companiesList.find((c) => c.value === companyCode);
      return { title: companyData?.label || "", icon: companyData?.iconSrc, code: companyData?.value || "" };
    });
  }, [members, managedMember, companiesList]);

  return (
    <Dialog
      open={showInviteMembersDialog}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: grey[50],
          height: "88vh",
          top: 12,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, px: 3, py: 1, borderBottom: borderLineStyle }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{getDialogTitle()}</Typography>
          <CloseIconButton aria-label="close" onClick={handleClose} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Grid sx={{ height: "100%" }} container>
          <Grid
            item
            xs={7}
            md={8}
            lg={9}
            xl={9.3}
            alignItems="start"
            sx={{ borderRight: borderLineStyle, p: 3, height: "100%", maxHeight: "100%", overflowY: "auto" }}
          >
            <Stack gap={1.5}>
              <Typography variant="subtitle1">{managedMember ? "User" : "Send invite to users"}</Typography>
              {!managedMember && (
                <ChipsMultilineInput
                  values={emails}
                  placeholder="Enter one or multiple emails, comma or space separated"
                  onChange={updateEmails}
                  inputValidator={emailValidator}
                  freeSolo
                  removeDuplicatesOnPaste={false}
                  restrictedItems={members.map((member) => member.email)}
                  restrictedItemsHelperText="User with such email is already registered"
                />
              )}
              {managedMember && (
                <ManagedMemberDetails
                  name={managedMember.name}
                  email={managedMember.email}
                  isCurrentUser={isCurrentUser(managedMember.userId)}
                />
              )}
              <Typography sx={{ mt: 1 }} variant="subtitle1">
                Organization Role
              </Typography>
              <OrganizationRoleSelector />
              {devPortalAccessibleCompanies.length > 0 && (
                <>
                  <Typography sx={{ mt: 2.5, mb: 0.5 }} variant="subtitle2">
                    Developer portal
                  </Typography>
                  <DeveloperPortal accessibleCompanies={devPortalAccessibleCompanies} />
                </>
              )}
              <CompaniesManageSection />
            </Stack>
          </Grid>
          <Grid
            item
            xs={5}
            md={4}
            lg={3}
            xl={2.7}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {!selectedCompanyAccess && <PermissionsBlankIcon />}
            {selectedCompanyAccess && <AccessSection companyAccess={selectedCompanyAccess} />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: borderLineStyle, px: 3, py: 1.5 }}>
        {!managedMember && (
          <LoadingButton
            variant="outlined"
            color="primary"
            disabled={!isValidConfiguration}
            loading={isSending}
            onClick={sendInvites}
            endIcon={<ArrowForwardRoundedIcon />}
          >
            Send Invite
          </LoadingButton>
        )}
        {managedMember && (
          <LoadingButton
            variant="outlined"
            color="primary"
            disabled={!isValidConfiguration}
            loading={isSending}
            onClick={applyMemberChanges}
          >
            Apply
          </LoadingButton>
        )}
      </DialogActions>
      <BackdropLoader open={isSending} />
    </Dialog>
  );
};

export default InviteMembersContainer;
