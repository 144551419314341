import { amber } from "@mui/material/colors";

export const getLowConfidenceStyles = () => ({
  "& label.Mui-error": {
    color: amber[500],
  },
  "& .MuiInputBase-root.Mui-error": {
    "& fieldset": {
      borderColor: amber[500],
    },
  },
});
