import adminApi from "../../../../api/adminApi";
import { DwhSyncLogs } from "../../../../api/types/dwhTypes";
import LogsWrapper from "../../../common/syncLogs/LogsWrapper";
import LogsTable from "./LogsTable";

const Logs = () => {
  return (
    <LogsWrapper<DwhSyncLogs>
      fetchLogsFromApi={(params) => adminApi.getDwhLogs(params)}
      renderTable={(logItems) => <LogsTable logItems={logItems} />}
    />
  );
};

export default Logs;
