import { createSvgIcon } from "@mui/material";

const BinIcon = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3281 7L31.8005 7.37019L51.3635 10.2993L53.836 10.6695L53.4658 13.1419L52.5773 19.0756L67.1341 21.2552L66.3937 26.2L63.6579 25.7904L63.3787 30.307L60.6882 73.8397H17.6882L14.9969 30.307L14.6878 25.307H19.6973H58.6782H60.429L12 18.0559L12.7404 13.111L28.0694 15.4062L28.9579 9.47244L29.3281 7ZM48.1507 14.874L47.6325 18.3353L33.0143 16.1465L33.5326 12.6853L48.1507 14.874ZM20.0064 30.307L22.3887 68.8397H55.9877L58.3692 30.307H20.0064ZM25.6883 62.4488L25.6883 36.5646H30.6883L30.6882 62.4488H25.6883ZM36.6883 36.5646L36.6882 62.4488H41.6882L41.6883 36.5646H36.6883ZM47.6883 62.4488V36.5646H52.6883V62.4488H47.6883Z"
    />
  </svg>,
  "BinIcon"
);

export default BinIcon;
