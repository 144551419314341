import { Stack, Typography } from "@mui/material";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import SupportInfoEditor from "./SupportInfoEditor";

const PortalSupportInfo = () => {
  const [portalSettingsResponse, error] = useFetch(adminApi.getInvestorPortalSettings);

  if (error) {
    logError(error, "getInvestorPortalSettings");
    return <DataLoadingFailed title="Failed to load settings" />;
  }

  if (!portalSettingsResponse || !portalSettingsResponse.settings) {
    return <InlineLoader />;
  }

  return (
    <Stack px={1} spacing={2}>
      <Typography>
        Information where investors can contact in case of questions or problems with the use of the portal{" "}
      </Typography>
      <SupportInfoEditor settings={portalSettingsResponse.settings} />
    </Stack>
  );
};

export default PortalSupportInfo;
