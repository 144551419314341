import { Avatar, Stack, Typography } from "@mui/material";
import aws from "../../../../images/aws.png";

const DataBackupConfigurationHeader = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">Target</Typography>
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Avatar src={aws} variant="rounded" alt="logo" sx={{ width: 24, height: 24 }} />
        <Typography variant="subtitle2">AWS S3 bucket</Typography>
      </Stack>
    </Stack>
  );
};

export default DataBackupConfigurationHeader;
