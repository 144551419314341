import { AccessPermission, EntriliaProduct } from "../../../../shared/api/types";
import { DataImportState } from "../../../api/types/dataImportTypes";

export const isImportEditable = (dataImportState: DataImportState): boolean =>
  ["Created", "Parsed"].includes(dataImportState);

export const isImportApplicationAllowed = (dataImportState: DataImportState): boolean => dataImportState === "Parsed";

const productToAccessPermissionMap: Partial<Record<EntriliaProduct, AccessPermission>> = {
  InvestorRelationsManagement: "ManageInvestorRelationsDataImports",
  PortfolioMonitoring: "ManagePortfolioMonitoringDataImports",
  ExpenseManagement: "ManageExpenseManagementDataImports",
};

export const getPermissionsToManageImport = (productAreas: EntriliaProduct[]): AccessPermission[] =>
  productAreas.map((scope) => productToAccessPermissionMap[scope]).filter(Boolean) as AccessPermission[];
