import BugIcon from "@mui/icons-material/BugReportOutlined";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import MoveDownIcon from "@mui/icons-material/MoveDownRounded";
import MoveUpIcon from "@mui/icons-material/MoveUpRounded";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { CircularProgress, Select, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import FileIcon from "../../../../../../../shared/components/FileIcon";
import MenuItemWithoutHighlight from "../../../../../../../shared/components/MenuItemWithoutHighlight";
import { convertISODateTime } from "../../../../../../../shared/utilities/dateUtils";
import { formatFileSize, getFileExtension } from "../../../../../../../shared/utilities/fileHelper";
import ActionsMenuButton from "../../../../../common/ActionsMenuButton";
import CategoryTag from "../../../../../common/CategoryTag";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import {
  deleteDocumentsAction,
  FundraisingDocument,
  moveDocumentDownAction,
  moveDocumentUpAction,
  updateDocumentsCategoryAction,
} from "./fundraisingDocumentsState";

const FileStatusOrIcon = ({ row }: { row: FundraisingDocument }) => {
  if (row.uploadStatus === "uploading") {
    return <CircularProgress size={20} color="secondary" />;
  }

  if (row.uploadStatus === "upload_completed") {
    return <CheckIcon color="success" />;
  }

  if (row.uploadStatus === "error") {
    if (row.validationError) {
      return (
        <Tooltip title={row.validationError} arrow>
          <ErrorIcon color="error" />
        </Tooltip>
      );
    }

    if (row.uploadError?.type === "MalwareDetected") {
      return (
        <Tooltip title="Upload failed: malware detected" arrow>
          <BugIcon color="error" />
        </Tooltip>
      );
    }

    return (
      <Tooltip title="Upload failed" arrow>
        <ErrorIcon color="error" />
      </Tooltip>
    );
  }

  return <FileIcon fileExtension={getFileExtension(row.name)} />;
};

const FileNameCell = ({ row }: { row: FundraisingDocument }) => {
  const { onPreviewFile } = useFundraisingDocumentsContext();

  const handleClick = () => {
    if (row.uploadStatus === undefined) {
      onPreviewFile(row.fileId);
    }
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <FileStatusOrIcon row={row} />
      <Typography
        noWrap
        onClick={handleClick}
        color={row.uploadStatus === undefined ? "inherit" : "text.secondary"}
        sx={(t) =>
          row.uploadStatus === undefined ? { cursor: "pointer", "&:hover": { color: t.palette.primary.main } } : {}
        }
      >
        {row.name}
      </Typography>
    </Stack>
  );
};

const CategorySelect = ({ row }: { row: FundraisingDocument }) => {
  const { fundraisingCategories } = useFundraisingDetailsPageContext();
  const { setDocumentsState } = useFundraisingDocumentsContext();

  const handleChange = (e: SelectChangeEvent) => {
    const section = fundraisingCategories.find((c) => c.externalId === e.target.value)?.name;
    setDocumentsState(updateDocumentsCategoryAction([row.fileId], fundraisingCategories, e.target.value, section));
  };

  return (
    <Select
      variant="standard"
      disableUnderline
      value={row.externalCategoryId}
      onChange={handleChange}
      sx={{
        ".MuiInputBase-input": {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      {fundraisingCategories.map((category) => (
        <MenuItemWithoutHighlight key={category.externalId} value={category.externalId}>
          <CategoryTag categoryName={category.name} />
        </MenuItemWithoutHighlight>
      ))}
    </Select>
  );
};

const ActionsCell = ({ row }: { row: FundraisingDocument }) => {
  const { isContentEditable } = useFundraisingDetailsPageContext();
  const { documentsState, setDocumentsState, onDownloadFile } = useFundraisingDocumentsContext();

  if (!isContentEditable || documentsState.isSaving) {
    return null;
  }

  const currentDocumentIndex = documentsState.documents.findIndex((d) => d.fileId === row.fileId);
  const previousDocument = documentsState.documents[currentDocumentIndex - 1];
  const nextDocument = documentsState.documents[currentDocumentIndex + 1];

  const canDownload = row.uploadStatus === undefined;

  const canMoveUp =
    (row.uploadStatus === undefined || row.uploadStatus === "ready_for_upload") &&
    previousDocument !== undefined &&
    row.externalCategoryId === previousDocument.externalCategoryId &&
    previousDocument.uploadStatus !== "error";

  const canMoveDown =
    (row.uploadStatus === undefined || row.uploadStatus === "ready_for_upload") &&
    nextDocument !== undefined &&
    row.externalCategoryId === nextDocument.externalCategoryId &&
    nextDocument.uploadStatus !== "error";

  return (
    <ActionsMenuButton
      items={[
        {
          label: "Download",
          icon: <DownloadIcon color="secondary" />,
          onClick: () => onDownloadFile(row.fileId),
          disabled: !canDownload,
        },
        {
          label: "Move up",
          icon: <MoveUpIcon color="secondary" />,
          onClick: () => setDocumentsState(moveDocumentUpAction(row.fileId)),
          disabled: !canMoveUp,
        },
        {
          label: "Move down",
          icon: <MoveDownIcon color="secondary" />,
          onClick: () => setDocumentsState(moveDocumentDownAction(row.fileId)),
          disabled: !canMoveDown,
        },
        {
          label: "Delete",
          icon: <DeleteIcon color="error" />,
          onClick: () => setDocumentsState(deleteDocumentsAction([row.fileId])),
        },
      ]}
    />
  );
};

export const columnDefinitions: GridColDef<FundraisingDocument>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    cellClassName: "highlighted-action",
    renderCell: ({ row }) => <FileNameCell row={row} />,
  },
  {
    field: "externalCategoryId",
    headerName: "Category",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) => <CategorySelect row={row} />,
  },
  {
    field: "size",
    headerName: "Size",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) => (
      <Typography color={row.uploadStatus === undefined ? "text.primary" : "text.secondary"}>
        {formatFileSize(row.size)}
      </Typography>
    ),
  },
  {
    field: "uploadedAt",
    headerName: "Uploaded at",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) =>
      row.uploadedAt ? (
        <Typography color={row.uploadStatus === undefined ? "text.primary" : "text.secondary"}>
          {convertISODateTime(row.uploadedAt)}
        </Typography>
      ) : null,
  },
  {
    field: "actions",
    headerName: "",
    width: 144,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: ({ row }) => <ActionsCell row={row} />,
  },
];
