import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Stack, Typography } from "@mui/material";
import { GridBasicGroupNode, GridRowId, useGridApiContext } from "@mui/x-data-grid-premium";
import ExpandCollapseButton from "../../../../../../shared/components/ExpandCollapseButton";

interface Props {
  id: GridRowId;
  rowNode: GridBasicGroupNode;
  text: string;
}

const FundLevelGroupName = ({ id, text, rowNode }: Props) => {
  const apiRef = useGridApiContext();

  const handleToggle = () => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" maxWidth="100%">
      <ExpandCollapseButton
        expanded={rowNode.childrenExpanded === true}
        onToggle={handleToggle}
        tabIndex={-1}
        ExpandIcon={ArrowRightRoundedIcon}
        CollapseIcon={ArrowDropDownRoundedIcon}
      />
      <Typography variant="subtitle2" noWrap>
        {text}
      </Typography>
    </Stack>
  );
};

export default FundLevelGroupName;
