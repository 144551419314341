import { SvgIcon, SvgIconProps } from "@mui/material";

const DataIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 61, height: 56 }} viewBox="0 0 61 56" {...props}>
      <path d="M33.037 14.2734H27.8965V28.4614H33.037V14.2734Z" fill="#C7DBE5" />
      <path
        d="M54.2159 24.1445H6.61426V43.1646H27.999V47.2771V53.5485H33.1395V47.2771V43.1646H54.3187V24.1445H54.2159ZM49.0753 38.024H11.7548V29.2851H49.0753V38.024Z"
        fill="#C7DBE5"
      />
      <path
        d="M44.8944 34.9061C45.5411 33.7066 45.0929 32.21 43.8934 31.5634C42.6939 30.9168 41.1973 31.3649 40.5507 32.5644C39.9041 33.7639 40.3523 35.2605 41.5518 35.9071C42.7512 36.5538 44.2478 36.1056 44.8944 34.9061Z"
        fill="#C7DBE5"
      />
      <path
        d="M54.2158 19.4146H6.61426V0.394531H54.2158V19.4146ZM11.7548 14.274H49.0753V5.53509H11.7548V14.274Z"
        fill="#C7DBE5"
      />
      <path
        d="M44.9069 11.1522C45.5535 9.95267 45.1053 8.45611 43.9058 7.80949C42.7063 7.16286 41.2098 7.61104 40.5632 8.81053C39.9165 10.01 40.3647 11.5066 41.5642 12.1532C42.7637 12.7999 44.2603 12.3517 44.9069 11.1522Z"
        fill="#C7DBE5"
      />
      <path d="M60.6933 50.4629H0.240234V55.6035H60.6933V50.4629Z" fill="#C7DBE5" />
    </SvgIcon>
  );
};

export default DataIcon;
