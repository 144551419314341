import { Theme, useTheme } from "@mui/material";
import ColorTag from "../../../shared/components/ColorTag";
import { makeLighterBackgroundFromColor } from "../../../shared/utilities/colorHelper";
import { IntegrationAppStatus } from "../../api/types/clientTypes";

interface Props {
  appStatus: IntegrationAppStatus | undefined;
}

const getColorTagProps = (appStatus: IntegrationAppStatus | undefined, theme: Theme) => {
  switch (appStatus) {
    case "Published": {
      return {
        statusText: "Connected",
        statusColor: makeLighterBackgroundFromColor(theme.palette.success.main),
        textColor: "text.primary",
      };
    }
    case "NotConfigured": {
      return {
        statusText: "Not configured",
        statusColor: makeLighterBackgroundFromColor(theme.palette.warning.main),
        textColor: "text.primary",
      };
    }
    case "Error": {
      return {
        statusText: "Error",
        statusColor: makeLighterBackgroundFromColor(theme.palette.error.main),
        textColor: "text.primary",
      };
    }
    case "Announced": {
      return {
        statusText: "Coming soon",
        statusColor: theme.palette.background.grey,
        textColor: "text.secondary",
      };
    }
    case "Paused": {
      return {
        statusText: "Paused",
        statusColor: makeLighterBackgroundFromColor(theme.palette.warning.main),
        textColor: "text.primary",
      };
    }
    default: {
      return {
        statusText: "Not connected",
        statusColor: theme.palette.background.grey,
        textColor: "text.secondary",
      };
    }
  }
};

const ApplicationStatusTag = ({ appStatus }: Props) => {
  const theme = useTheme();

  if (appStatus === "Hidden") {
    return null;
  }

  const { statusText, statusColor, textColor } = getColorTagProps(appStatus, theme);
  return <ColorTag text={statusText} color={statusColor} textColor={textColor} />;
};

export default ApplicationStatusTag;
