import { createSvgIcon } from "@mui/material";

const CardAccountDetailsIcon = createSvgIcon(
  <svg viewBox="0 0 20 21" fill="currentColor">
    <path d="M18.3333 3H1.66667C0.758333 3.03333 0.0333333 3.75833 0 4.66667V16.3333C0.0333333 17.2417 0.758333 17.9667 1.66667 18H18.3333C19.2417 17.9667 19.9667 17.2417 20 16.3333V4.66667C19.9667 3.75833 19.2417 3.03333 18.3333 3ZM18.3333 16.3333H1.66667V4.66667H18.3333V16.3333ZM11.6667 14.6667V13.625C11.6667 12.2417 8.88333 11.5417 7.5 11.5417C6.11667 11.5417 3.33333 12.2417 3.33333 13.625V14.6667H11.6667ZM7.5 6.33333C6.94747 6.33333 6.41756 6.55283 6.02686 6.94353C5.63616 7.33423 5.41667 7.86413 5.41667 8.41667C5.41667 8.69025 5.47055 8.96116 5.57525 9.21392C5.67995 9.46669 5.83341 9.69635 6.02686 9.88981C6.41756 10.2805 6.94747 10.5 7.5 10.5C7.77359 10.5 8.0445 10.4461 8.29726 10.3414C8.55002 10.2367 8.77968 10.0833 8.97314 9.88981C9.16659 9.69635 9.32005 9.46669 9.42475 9.21392C9.52945 8.96116 9.58333 8.69025 9.58333 8.41667C9.58333 8.14308 9.52945 7.87217 9.42475 7.61941C9.32005 7.36665 9.16659 7.13698 8.97314 6.94353C8.77968 6.75007 8.55002 6.59661 8.29726 6.49192C8.0445 6.38722 7.77359 6.33333 7.5 6.33333ZM11.6667 6.33333V7.16667H16.6667V6.33333H11.6667ZM11.6667 8V8.83333H16.6667V8H11.6667ZM11.6667 9.66667V10.5H15V9.66667H11.6667Z" />
  </svg>,
  "CardAccountDetailsIcon"
);

export default CardAccountDetailsIcon;
