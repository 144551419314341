import { Dispatch, PropsWithChildren, createContext, useCallback, useContext, useRef } from "react";
import { AnyRecord } from "../../../../shared/types";
import { defined } from "../../../../shared/utilities/typeHelper";
import { FilterAction, FilterState } from "./filterState";
import { SelectOption } from "./filterTypes";

interface Props<R extends AnyRecord> {
  filterState: FilterState<R>;
  dispatchFilters: Dispatch<FilterAction<R>>;
  allRowsForSelectOptions?: R[];
  getSelectOptions?: (filterId: string) => Promise<SelectOption[]>;
}

interface ContextValue<R extends AnyRecord> extends Props<R> {
  getAsyncSelectOptions: (filterId: string) => Promise<SelectOption[]>;
}

const FilterContext = createContext<ContextValue<AnyRecord> | undefined>(undefined);

export const FilterContextProvider = <R extends AnyRecord>({
  filterState,
  dispatchFilters,
  allRowsForSelectOptions,
  getSelectOptions,
  children,
}: PropsWithChildren<Props<R>>) => {
  const selectOptionsCache = useRef<Map<string, SelectOption[]>>(new Map<string, SelectOption[]>());

  const getAsyncSelectOptions = useCallback(
    async (filterId: string) => {
      const cachedOptions = selectOptionsCache.current.get(filterId);
      if (cachedOptions !== undefined) {
        return cachedOptions;
      }

      if (getSelectOptions === undefined) {
        return [];
      }

      const options = await getSelectOptions(filterId);
      selectOptionsCache.current.set(filterId, options);
      return options;
    },
    [getSelectOptions]
  );

  return (
    <FilterContext.Provider
      value={{
        filterState,
        dispatchFilters,
        allRowsForSelectOptions,
        getAsyncSelectOptions,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = <R extends AnyRecord>() =>
  defined(
    useContext<ContextValue<R>>(FilterContext as React.Context<ContextValue<R>>),
    "Attempt to use FilterContext without provider"
  );
