import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { Investor, InvestorWithFieldValues } from "../../../../api/adminApi";
import { Field } from "../../../../api/types/objectTypes";
import ActionsMenuButton from "../../../common/ActionsMenuButton";
import { useFilterContext } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { filterRecords } from "../../../common/filters/filterState";
import { getInvestorsGridColumns } from "./investorsGridDefinitions";

interface Props {
  investors: InvestorWithFieldValues[];
  fields: Field[];
  isLoading: boolean;
  hasAccessToManage: boolean;
  onNavigateToInvestorDetails: (investorId: string) => void;
  onDeleteInvestor: (investor: Investor) => void;
}

const InvestorsGrid = ({
  investors,
  fields,
  isLoading,
  hasAccessToManage,
  onDeleteInvestor,
  onNavigateToInvestorDetails,
}: Props) => {
  const { filterState } = useFilterContext<InvestorWithFieldValues>();

  const columns = getInvestorsGridColumns(fields, filterState, onNavigateToInvestorDetails);

  const allowInvestorDeletion = false; // #6829 not allowed until allowed on the backend

  if (hasAccessToManage && allowInvestorDeletion) {
    columns.push({
      field: "actions",
      sortable: false,
      headerName: "",
      width: 30,
      cellClassName: "grid-row-actions",
      align: "right",
      renderCell: ({ row }) => (
        <ActionsMenuButton
          items={[
            {
              icon: <DeleteOutlineIcon color="error" />,
              label: "Delete",
              onClick: () => onDeleteInvestor(row),
              disabled: !hasAccessToManage,
            },
          ]}
        />
      ),
    });
  }

  const filteredInvestors = filterRecords(filterState, investors).sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      <Stack spacing={2} height="100%" px={3} pb={1} pt={2.5}>
        <FiltersPanel
          totalCount={investors.length}
          recordCount={filteredInvestors.length}
          isLoading={isLoading}
          hasColumnSelection
        />
        <DataGrid<InvestorWithFieldValues>
          getRowId={(row) => row.id}
          slots={{
            loadingOverlay: () => <InlineLoader />,
            noRowsOverlay: () => (
              <CenteredWrapper>
                <Typography color="textSecondary">No investors</Typography>
              </CenteredWrapper>
            ),
          }}
          loading={isLoading}
          columns={columns}
          rowHeight={40}
          rows={filteredInvestors}
          disableRowSelectionOnClick
          hideFooter
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableColumnReorder
        />
      </Stack>
    </>
  );
};

export default InvestorsGrid;
