import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../shared/icons/BinIcon";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { DataImportInfo } from "../../../api/types/dataImportTypes";

const discardDataImport = withErrorHandling(adminApi.discardDataImport);

interface Props {
  deletingImport: DataImportInfo | undefined;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteDataImportDialog = ({ deletingImport, open, onClose, onConfirm }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  if (!deletingImport) {
    return null;
  }

  const handleSubmit = async () => {
    setLoading(true);
    const [result, error] = await discardDataImport(deletingImport.id);
    setLoading(false);

    if (error || !result) {
      logError(error, "[DeleteDataImportDialog]");
      sendNotificationError("Could not delete data import");
      return;
    }

    sendNotification("Data import deleted successfully");
    onConfirm();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Delete Data Import</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Delete data import?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You're about to delete <strong>{deletingImport.description || deletingImport.sourceDescription}</strong>.
              This action is permanent and can't be undone.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={loading} onClick={handleSubmit}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDataImportDialog;
