import { DependencyList, useLayoutEffect, useRef, useState } from "react";

const useVerticalOverflowDetection = (deps: DependencyList) => {
  const containerRef = useRef<HTMLElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  const updateOverflow = () => {
    if (containerRef.current) {
      setHasOverflow(containerRef.current.scrollHeight > containerRef.current.offsetHeight);
    }
  };

  useLayoutEffect(() => {
    updateOverflow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  useLayoutEffect(() => {
    window.addEventListener("resize", updateOverflow);
    return () => window.removeEventListener("resize", updateOverflow);
  }, []);

  return { hasOverflow, containerRef };
};

export default useVerticalOverflowDetection;
