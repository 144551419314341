import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import { useConfigurationsGridActionsContext } from "./ConfigurationsGridActionsContext";

interface Props {
  clientCode: string;
  clientTitle: string;
  currentConfigurationId: string;
}

const AssignClientMenuButton = ({ clientCode, clientTitle, currentConfigurationId }: Props) => {
  const { configurations, onAssignClient } = useConfigurationsGridActionsContext();

  const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(null);

  const handleNewConfigurationSelected = (toConfiguration: DataModelConfiguration) => {
    setContextMenuAnchor(null);

    if (toConfiguration.id !== currentConfigurationId) {
      const fromConfiguration = configurations.find((c) => c.id === currentConfigurationId);
      if (fromConfiguration) {
        onAssignClient({
          clientCode,
          clientTitle,
          fromConfiguration,
          toConfiguration,
        });
      }
    }
  };

  return (
    <>
      <Button variant="text" color="secondary" onClick={(e) => setContextMenuAnchor(e.currentTarget)}>
        Change
      </Button>
      <Menu
        anchorEl={contextMenuAnchor}
        open={Boolean(contextMenuAnchor)}
        onClose={() => setContextMenuAnchor(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {configurations.map((config) => (
          <MenuItem
            key={config.id}
            onClick={() => handleNewConfigurationSelected(config)}
            selected={config.id === currentConfigurationId}
            sx={{ minWidth: "18rem" }}
          >
            <Stack>
              <Typography color={config.id === currentConfigurationId ? "primary.main" : "text.primary"}>
                {config.name}
              </Typography>
              <Typography color="text.secondary">
                {config.clientCodes.length > 0 ? `${config.clientCodes.length} companies` : "None"}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AssignClientMenuButton;
