import { parseISO } from "date-fns";
import { InvestorWithFieldValues } from "../../../../api/adminApi";
import { SearchFilterDefinition, TableFilterDefinition } from "../../../common/filters/filterTypes";

export const getPredefinedFilterDefinitions = (): TableFilterDefinition<InvestorWithFieldValues>[] => [
  {
    type: "multi_select",
    id: "funds",
    name: "Fund",
    getFieldValue: (row) => row.funds.map((fund) => fund.fundName),
  },
  {
    type: "multi_select",
    id: "contacts",
    name: "Contact",
    getFieldValue: (row) => row.contacts.map((contact) => contact.name),
  },
  {
    type: "date",
    id: "createdAt",
    name: "Created at",
    getFieldValue: (row) => parseISO(row.createdAt),
  },
  {
    type: "date",
    id: "updateAt",
    name: "Modified at",
    getFieldValue: (row) => parseISO(row.updateAt),
  },
];

export const getSearchDefinition = (): SearchFilterDefinition<InvestorWithFieldValues> => ({
  getFieldValues: (row) => [
    row.title,
    ...row.funds.map((fund) => fund.fundName),
    ...row.contacts.map((contact) => contact.name),
  ],
});
