import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { useState } from "react";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import { useMembersContext } from "../MembersContext";
import { CompanyWithAccess } from "../definitions";
import CompaniesSelectDialog from "./CompaniesSelectDialog";
import CompanyWithAccessPermissions from "./CompanyWithAccessPermissions";
import { useInviteMembersContext } from "./InviteMembersContext";

const CompaniesManageSection = () => {
  const { companiesList } = useMembersContext();
  const { selectedCompanies, updateSelectedCompanies, onCompanyDelete } = useInviteMembersContext();

  const [showDialog, setShowDialog] = useState(false);
  const [search, setSearch] = useState<string>("");

  const handleCompanyAdd = () => {
    setShowDialog(true);
  };

  const handleCloseAddCompaniesDialog = (companies?: CompanyWithAccess[]) => {
    setShowDialog(false);
    if (companies) {
      updateSelectedCompanies(companies);
    }
  };

  const handleSearch = useDebounce((search: string) => {
    setSearch(search);
  }, 300);

  const filteredCompaniesList = selectedCompanies.filter((company) =>
    companiesList.some((c) => c.value === company.clientCode && c.label.toLowerCase().includes(search.toLowerCase()))
  );

  return (
    <Stack mt={1} spacing={1} alignItems="flex-start">
      <Stack direction={"row"} alignItems="center" gap={1} sx={{ width: "100%", mb: 1 }}>
        <Typography variant="subtitle1">Companies</Typography>
        {selectedCompanies.length > 0 && <Chip label={filteredCompaniesList.length} />}
        <Button variant="text" startIcon={<AddIcon />} onClick={handleCompanyAdd}>
          Add Company
        </Button>
        {selectedCompanies.length > 3 && (
          <Box sx={{ marginLeft: "auto" }}>
            <SearchField onSearch={handleSearch} />
          </Box>
        )}
      </Stack>
      {filteredCompaniesList.length > 0 && (
        <Stack spacing={2} width="100%">
          {filteredCompaniesList.map((company) => (
            <CompanyWithAccessPermissions key={company.clientCode} company={company} onRemove={onCompanyDelete} />
          ))}
        </Stack>
      )}
      <CompaniesSelectDialog
        open={showDialog}
        availableCompanies={companiesList}
        onClose={handleCloseAddCompaniesDialog}
        selectedCompanies={selectedCompanies}
      />
    </Stack>
  );
};

export default CompaniesManageSection;
