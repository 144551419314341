import {
  combineValidators,
  requiredValidator,
  uniqueValidator,
} from "../../../../../../../../shared/utilities/validators";

interface VendorState {
  name: string;
  currencyCode: string;
  isValid: boolean;
  errors: {
    name?: string;
    currencyCode?: string;
  };
}

export const getInitialState = (): VendorState => {
  return {
    name: "",
    currencyCode: "",
    isValid: false,
    errors: {},
  };
};

export const updateState = (state: VendorState, existingVendorNames: string[] = []): VendorState => {
  const validateName = combineValidators(
    requiredValidator,
    uniqueValidator("Such a name already exists", existingVendorNames)
  );

  const validateNameResult = validateName(state.name.toLocaleLowerCase());
  const validateCurrencyCodeResult = requiredValidator(state.currencyCode);

  const isValid = validateNameResult.isValid && validateCurrencyCodeResult.isValid;

  return {
    ...state,
    isValid,
    errors: {
      name: validateNameResult.error,
      currencyCode: validateCurrencyCodeResult.error,
    },
  };
};
