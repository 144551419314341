import { PropsWithChildren, createContext, useContext } from "react";
import { distinct } from "../../../shared/utilities/arrayHelper";
import { defined } from "../../../shared/utilities/typeHelper";
import storage from "../../storage/storage";

interface ContextValue {
  persistedClosedItems: string[];
  addClosedItem: (item: string) => void;
  removeClosedItem: (item: string) => void;
}

const MainMenuContext = createContext<ContextValue | undefined>(undefined);

export const MainMenuContextProvider = ({ children }: PropsWithChildren) => {
  const persistedClosedItems = storage.getClosedMainMenuItems();

  const addClosedItem = (item: string) => {
    storage.saveClosedMainMenuItems(distinct([...storage.getClosedMainMenuItems(), item]));
  };

  const removeClosedItem = (item: string) => {
    storage.saveClosedMainMenuItems(storage.getClosedMainMenuItems().filter((i) => i !== item));
  };

  return (
    <MainMenuContext.Provider value={{ persistedClosedItems, addClosedItem, removeClosedItem }}>
      {children}
    </MainMenuContext.Provider>
  );
};

export const useMainMenuContext = () =>
  defined(useContext(MainMenuContext), "Attempted to use MainMenuContext outside of its provider");
