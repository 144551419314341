import { Button, FormControlLabel, Stack, Switch } from "@mui/material";

interface Props {
  onTestConnection: () => void;
  onBackupNow?: () => void;
  onToggleDailyBackup: (enabled: boolean) => void;
  testConnectionProcessing?: boolean;
  toggleDailyBackupProcessing?: boolean;
  dailyBackupEnabled: boolean;
  readonly: boolean;
  disabled: boolean;
}

const DataBackupSyncActions = ({
  onBackupNow,
  onTestConnection,
  onToggleDailyBackup,
  testConnectionProcessing,
  dailyBackupEnabled,
  toggleDailyBackupProcessing,
  readonly,
  disabled,
}: Props) => {
  return (
    <Stack direction={"row"} spacing={1}>
      <Button
        variant="outlined"
        color="secondary"
        disabled={testConnectionProcessing || readonly || disabled}
        onClick={onTestConnection}
      >
        Test Connection
      </Button>
      <Button variant="outlined" color="secondary" onClick={onBackupNow} disabled={readonly || disabled}>
        Backup Now
      </Button>
      <FormControlLabel
        disabled={readonly || disabled}
        control={
          <Switch
            disabled={toggleDailyBackupProcessing}
            checked={dailyBackupEnabled}
            onChange={(_, checked) => onToggleDailyBackup(checked)}
          />
        }
        label="Daily Backup Enabled"
      />
    </Stack>
  );
};

export default DataBackupSyncActions;
