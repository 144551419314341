import { useCallback, useState } from "react";
import { useLocalization } from "../../../../hooks/useLocalization";
import { useGroupsContext } from "../../../../../shared/reporting/components/groups/contexts/GroupsContext";
import { ReportGroup } from "../../../../../shared/reporting/api/biClient.types";
import ReportGroupForm from "../../../../../shared/reporting/components/reportGroups/ReportGroupForm";
import { useClientContext } from "../../../../context/ClientContext";
import { defined } from "../../../../../shared/utilities/typeHelper";
import PromptDialog from "../../../../../shared/components/dialog/PromptDialog";

interface Props {
  group: ReportGroup;
  onChanged: () => void;
  onClose: () => void;
}

export const RenameGroupDialog = (props: Props) => {
  const { group, onChanged, onClose } = props;

  const { clientCode } = useClientContext();
  const { actions, groups: reportGroups } = useGroupsContext();
  const { groups: locale } = useLocalization();

  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);
  const groups = defined(reportGroups[clientCode]);

  const onSave = useCallback(
    (caption: string) => {
      setSaving(true);
      let maxOrder = 0;
      groups.forEach((rg) => {
        maxOrder = Math.max(maxOrder, rg.order);
      });
      const groupToSave = { ...group, caption } as ReportGroup;
      actions
        .update(clientCode, groupToSave)
        .then(() => {
          onChanged();
          onClose();
        })
        .catch(() => {
          setError(locale.rename_error);
          setSaving(false);
        });
    },

    [groups, group, actions, clientCode, onChanged, onClose, locale.rename_error]
  );

  return (
    <PromptDialog open={true}>
      <ReportGroupForm
        group={group}
        title="Rename Group"
        groups={groups}
        saving={saving}
        onCancel={onClose}
        error={error}
        onSave={onSave}
      />
    </PromptDialog>
  );
};

export default RenameGroupDialog;
