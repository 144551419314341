import { createSvgIcon } from "@mui/material";

const SkipErrorsIcon = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="none">
    <rect x="9.5" y="15.5" width="61" height="47" stroke="#C7DBE5" strokeWidth="5" />
    <path d="M61 23H19V28H61V23Z" fill="#C7DBE5" />
    <path d="M61 32H19V37H61V32Z" fill="#F44336" />
    <path d="M61 41H19V46H61V41Z" fill="#C7DBE5" />
    <path d="M61 50H19V55H61V50Z" fill="#C7DBE5" />
    <path
      d="M67.5 78.5C75.232 78.5 81.5 72.232 81.5 64.5C81.5 56.768 75.232 50.5 67.5 50.5C59.768 50.5 53.5 56.768 53.5 64.5C53.5 72.232 59.768 78.5 67.5 78.5Z"
      fill="#00A866"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M74.8529 64.4999L73.5569 63.204L68.419 68.3326V57.147H66.5808V68.3326L61.4521 63.1948L60.147 64.4999L67.4999 71.8529L74.8529 64.4999Z"
      fill="white"
    />
  </svg>,
  "SkipErrorsIcon"
);

export default SkipErrorsIcon;
