import { SvgIcon, SvgIconProps } from "@mui/material";

const PowerPointIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" fill="#FFF3E0" stroke="#FFE0B2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3 4C12.1343 4 12 4.13431 12 4.3V11.7C12 11.8657 12.1343 12 12.3 12H19.7C19.8657 12 20 11.8657 20 11.7V4.3C20 4.13431 19.8657 4 19.7 4H12.3ZM14.9888 17.3334C15.6481 16.3467 16 15.1867 16 14H10V8C8.81331 8 7.65328 8.35189 6.66658 9.01118C5.67989 9.67047 4.91085 10.6075 4.45673 11.7039C4.0026 12.8003 3.88378 14.0067 4.11529 15.1705C4.3468 16.3344 4.91825 17.4035 5.75736 18.2426C6.59648 19.0818 7.66557 19.6532 8.82946 19.8847C9.99335 20.1162 11.1997 19.9974 12.2961 19.5433C13.3925 19.0892 14.3295 18.3201 14.9888 17.3334Z"
      fill="#FFB74D"
    />
  </SvgIcon>
);

export default PowerPointIcon;
