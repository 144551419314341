import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { distinctBy } from "../../../../../../shared/utilities/arrayHelper";
import adminApi, { ContactPermissions, FundraisingDocument } from "../../../../../api/adminApi";
import { Category } from "../../../../../api/types/accessTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import FundraisingDocumentFilters from "../../common/FundraisingDocumentFilters";
import {
  filterFundraisingDocuments,
  FundraisingDocumentsFilterValue,
  getInitialFilters,
} from "../../common/fundraisingDocumentsFiltering";
import FundraisingDocumentsGrid from "../../common/FundraisingDocumentsGrid";

interface Props {
  contactId: string;
  categories: Category[];
  contactPermissions: ContactPermissions[];
}

const ContactFundraisingDocumentsTab = ({ contactId, categories, contactPermissions }: Props) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<FundraisingDocumentsFilterValue>(getInitialFilters());

  const fetchDocuments = useCallback(() => adminApi.getContactFundraisingDocuments(contactId), [contactId]);

  const [allDocuments, fetchError, { isFetching }] = useFetch(fetchDocuments);

  if (fetchError) {
    logError(fetchError, "[ContactDetailsDocumentsTab]");
    return <DataLoadingFailed bgColor="none" title="Loading contact documents failed" />;
  }

  const handleFiltersChange = (filterValue: FundraisingDocumentsFilterValue) => {
    setFilters(filterValue);
  };

  const handleClearFilters = () => {
    setFilters({ ...getInitialFilters(), searchTerm: filters.searchTerm });
  };

  const handleNavigateToDocumentActivity = (document: FundraisingDocument) => {
    navigate(
      `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.contacts}/${contactId}/${pageRoutes.fundraisings}/${document.fundraisingId}/${pageRoutes.documents}/${document.id}/${pageRoutes.activity}`
    );
  };

  const categoryOptions = categories
    .filter((c) => c.type === "Fundraising")
    .map(({ externalId, name }) => ({ value: externalId, label: name }));

  const investorOptions = distinctBy(
    contactPermissions.map(({ investorId, investorName }) => ({
      value: investorId,
      label: investorName,
    })),
    ({ value }) => value
  );

  const fundOptions = distinctBy(
    contactPermissions.map(({ fundId, fundName }) => ({ value: fundId, label: fundName })),
    ({ value }) => value
  );

  const documents = filterFundraisingDocuments(allDocuments ?? [], filters);

  return (
    <Stack spacing={2.5} sx={{ height: "100%" }}>
      <FundraisingDocumentFilters
        categoryOptions={categoryOptions}
        investorOptions={investorOptions}
        fundOptions={fundOptions}
        filters={filters}
        onChange={handleFiltersChange}
        onClear={handleClearFilters}
        isLoading={isFetching}
        recordsCount={documents.length}
        totalRecordsCount={allDocuments?.length ?? 0}
      />
      <FundraisingDocumentsGrid
        documents={documents}
        categories={categories}
        isLoading={isFetching}
        onNavigateToDocumentActivity={handleNavigateToDocumentActivity}
      />
    </Stack>
  );
};

export default ContactFundraisingDocumentsTab;
