import { TabPanel } from "@mui/lab";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { parseSearchParamWithFallback } from "../../../../shared/utilities/searchParamsHelper";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import PageTabs from "../../common/PageTabs";
import ConfigurationsList from "./configurations/ConfigurationsList";
import ObjectsList from "./objects/ObjectsList";

const tabValues = ["objects", "configurations"] as const;
type TabValue = (typeof tabValues)[number];

const DataModelPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<TabValue>(
    parseSearchParamWithFallback(searchParams, "tab", tabValues)
  );

  const handleTabChange = (tab: TabValue) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  return (
    <>
      <GeneralPageHeader title="Data Model" hideBorderLine />
      <PageTabs tabs={tabValues} labels={["Objects", "Configurations"]} value={selectedTab} onChange={handleTabChange}>
        <TabPanel value="objects" sx={{ height: "100%" }}>
          <ObjectsList />
        </TabPanel>
        <TabPanel value="configurations" sx={{ height: "100%" }}>
          <ConfigurationsList />
        </TabPanel>
      </PageTabs>
    </>
  );
};

export default DataModelPage;
