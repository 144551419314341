import { useReducer, useState } from "react";
import ConsentAgreement from "../../../shared/components/consentAgreement/ConsentAgreement";
import { reducer, getInitialState } from "../../../shared/components/consentAgreement/reducer";
import { useLocalization } from "../../hooks/useLocalization";
import { IConsentAgreement } from "../../../shared/components/consentAgreement/types";
import adminApi from "../../api/adminApi";

interface Props {
  consentAgreements: IConsentAgreement[];
}

const ConsentAgreementContainer = ({ consentAgreements }: Props) => {
  const [saving, setSaving] = useState(false);

  const { consent_agreement: pageLocale } = useLocalization();
  const [state, dispatch] = useReducer(reducer, getInitialState(consentAgreements));

  const saveConsentsAndReload = async (acceptedConsentAgreements: IConsentAgreement[]) => {
    setSaving(true);
    try {
      const resp = await adminApi.updateUserConsents(acceptedConsentAgreements);
      if (resp?.success) {
        window.location.reload();
      }
    } finally {
      setSaving(false);
    }
  };

  const handleAgreementAccept = async (acceptedAgreement: IConsentAgreement, agreeToNews?: boolean) => {
    if (state.isLast) {
      await saveConsentsAndReload([...state.acceptedAgreements, acceptedAgreement]);
    } else {
      dispatch({ type: "AGREEMENT_ACCEPTED", payload: { agreeToNews } });
    }
  };

  if (!state.nextAgreementToAccept) {
    return null;
  }

  return (
    <ConsentAgreement
      saving={saving}
      open={true}
      consentAgreement={state.nextAgreementToAccept}
      showNewsConsent={false}
      buttonLabel={pageLocale.continue_button}
      onAccept={handleAgreementAccept}
      portalTitle="Admin Portal"
    />
  );
};

export default ConsentAgreementContainer;
