import { Grid, GridProps, GridSize } from "@mui/material";
import { PropsWithChildren } from "react";

interface LayoutItem {
  width?: number | string;
  xs?: boolean | GridSize;
}

interface Props {
  gridProps?: GridProps;
  layout?: LayoutItem[];
}

const ScrollableColumns = ({ gridProps, layout, children }: PropsWithChildren<Props>) => {
  const columns = children as React.ReactElement[];
  const layoutItems = layout ?? [];

  return (
    <Grid columns={columns.length} container height="100%" sx={{ overflow: "hidden" }} {...gridProps}>
      {columns.map((column, index) => {
        const { xs, width } = layoutItems[index] ?? {};
        return (
          <Grid key={index} item xs={width ? false : xs ?? 1} height="100%" width={width} sx={{ overflowY: "auto" }}>
            {column}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ScrollableColumns;
