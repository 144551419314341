import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { Category } from "../../../../../../api/types/accessTypes";
import { FundraisingDocument, FundraisingDocumentsState } from "./fundraisingDocumentsState";

interface Props {
  documentsState: FundraisingDocumentsState;
  setDocumentsState: Dispatch<SetStateAction<FundraisingDocumentsState>>;
  filteredDocuments: FundraisingDocument[];
  defaultCategory: Category;
  onPreviewFile: (fileId: string) => void;
  onDownloadFile: (fileId: string) => void;
  onDownloadMultipleFiles: (fileIds: string[]) => void;
}

const FundraisingDocumentsContext = createContext<Props | undefined>(undefined);

export const FundraisingDocumentsContextProvider = ({
  documentsState,
  setDocumentsState,
  filteredDocuments,
  defaultCategory,
  onPreviewFile,
  onDownloadFile,
  onDownloadMultipleFiles,
  children,
}: PropsWithChildren<Props>) => (
  <FundraisingDocumentsContext.Provider
    value={{
      documentsState,
      setDocumentsState,
      filteredDocuments,
      defaultCategory,
      onPreviewFile,
      onDownloadFile,
      onDownloadMultipleFiles,
    }}
  >
    {children}
  </FundraisingDocumentsContext.Provider>
);

export const useFundraisingDocumentsContext = () =>
  defined(useContext(FundraisingDocumentsContext), "Attempt to use FundraisingDocumentsContext without provider");
