import { Stack, Typography } from "@mui/material";
import { CompanyWithAccess } from "../definitions";
import CompanyProductDetails from "./CompanyProductDetails";
import { useInviteMembersContext } from "./InviteMembersContext";

interface Props {
  company: CompanyWithAccess;
}

const CompanyProducts = ({ company }: Props) => {
  const { getAvailableProductsWithRoles } = useInviteMembersContext();

  const productsWithRoles = getAvailableProductsWithRoles(company.clientCode);

  return (
    <Stack direction="row" justifyItems="center" alignItems="center" spacing={4}>
      <Typography sx={{ width: "3rem" }} variant="subtitle2">
        Products
      </Typography>
      {!company.roles.length && <Typography color="error.light">Set product access</Typography>}
      <Stack direction="row" spacing={3} justifyItems="center" alignItems="center" flexWrap="wrap" useFlexGap>
        {productsWithRoles
          .filter((accessPermission) => company.roles.some((role) => accessPermission.roles.includes(role)))
          .map((accessPermission) => (
            <CompanyProductDetails key={accessPermission.key} product={accessPermission} grantedRoles={company.roles} />
          ))}
      </Stack>
    </Stack>
  );
};

export default CompanyProducts;
