import { SvgIconComponent } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

// Copied from ChangeRequestAttribute. Should be extracted to a shared component.

interface Props {
  Icon: SvgIconComponent;
  label: string;
  value: string | React.ReactNode;
}

const ApprovalAttribute = ({ Icon, label, value }: Props) => {
  return (
    <Stack spacing={1.5} direction="row" alignItems="center" py={1} width="20rem">
      <Stack spacing={1.5} direction="row" alignItems="center" flexBasis="50%">
        <Icon color="action" />
        <Typography color="text.secondary">{label}</Typography>
      </Stack>
      <Box flexBasis="50%">{typeof value === "string" ? <Typography>{value}</Typography> : value}</Box>
    </Stack>
  );
};

export default ApprovalAttribute;
