import { useCallback } from "react";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import ContactEmailsTable from "./ContactEmailsTable";

const ContactDetailsEmailsTab = ({ contactId }: { contactId: string }) => {
  const fetchEmails = useCallback(() => adminApi.getContactEmails(contactId), [contactId]);

  const [emailsResp, error] = useFetch(fetchEmails);

  if (error) {
    logError(error.message, "ContactDetailsEmailsPage");
    return <DataLoadingFailed bgColor="none" title="Loading emails failed" />;
  }

  if (emailsResp === undefined) {
    return <InlineLoader />;
  }

  return <ContactEmailsTable emails={emailsResp} />;
};

export default ContactDetailsEmailsTab;
