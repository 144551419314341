import { parseISO } from "date-fns";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { DataCollectionSubmissionInfo } from "../../../../api/types/dataCollectionTypes";
import { SearchFilterDefinition, TableFilterDefinition } from "../../../common/filters/filterTypes";

export const getFilterDefinitions = (): TableFilterDefinition<DataCollectionSubmissionInfo>[] => [
  {
    id: "recipientName",
    name: "Portfolio Company",
    type: "text",
    getFieldValue: (row) => row.recipientName,
  },
  {
    id: "configurationName",
    name: "Template",
    type: "multi_select",
    getFieldValue: (row) => row.configurationName,
  },
  {
    id: "status",
    name: "Status",
    type: "multi_select",
    getFieldValue: (row) => autoFormatCamelCase(row.status),
  },
  {
    id: "dueDate",
    name: "Due Date",
    type: "date",
    getFieldValue: (row) => parseISO(row.dueDate),
  },
  {
    id: "submittedByUserName",
    name: "Submitted by",
    type: "text",
    getFieldValue: (row) => row.submittedBy,
  },
  {
    id: "submittedAt",
    name: "Submitted at",
    type: "date",
    getFieldValue: (row) => (row.submittedAt ? parseISO(row.submittedAt) : undefined),
  },
];

export const getSearchDefinition = (): SearchFilterDefinition<DataCollectionSubmissionInfo> => ({
  getFieldValues: (row) => [row.recipientName, row.configurationName, row.submittedBy],
});
