import { ListItemText, MenuItem, Select, SxProps, Theme } from "@mui/material";
import { majorCurrencyCodes } from "../../../../../../shared/utilities/currenciesProvider";

// Using static majorCurrencyCodes until we have a way to define a dictionary for the organization

interface Props {
  code: string;
  disabled?: boolean;
  onChange: (code: string) => void;
  sx?: SxProps<Theme>;
}

const CurrencySelect = ({ code, disabled, onChange, sx }: Props) => {
  return (
    <Select
      disabled={disabled}
      value={code}
      onChange={(e) => onChange(e.target.value)}
      renderValue={(value) => value}
      sx={sx}
    >
      {majorCurrencyCodes.map((code) => (
        <MenuItem key={code} value={code}>
          <ListItemText primary={code} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencySelect;
