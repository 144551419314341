import CalendarIcon from "@mui/icons-material/CalendarTodayRounded";
import NumbersIcon from "@mui/icons-material/NumbersRounded";
import PersonIcon from "@mui/icons-material/PersonOutlineRounded";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import UserAvatar from "../../../../../shared/components/UserAvatar";
import { convertISODate, convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { formatNumber } from "../../../../../shared/utilities/formatters";
import { commaSeparated } from "../../../../../shared/utilities/stringHelper";
import { ApprovalRequest } from "../../../../api/mock/approvals/approvalsTypes";
import CubeIcon from "../../../../icons/CubeIcon";
import SelectIcon from "../../../../icons/SelectIcon";
import ApprovalStatusTag from "../ApprovalStatusTag";
import ApprovalAttribute from "./ApprovalAttribute";
import ApprovalPaymentsSection from "./ApprovalPaymentsSection";
import ApprovalProgressSection from "./ApprovalProgressSection";

interface Props {
  approvalRequest: ApprovalRequest;
}

const AttributeItemsContainer = ({ children }: PropsWithChildren) => (
  <Grid container spacing={2}>
    {children}
  </Grid>
);

const AttributeItem = ({ children }: PropsWithChildren) => (
  <Grid item xs={12} xl={6}>
    {children}
  </Grid>
);

const AttributesSection = ({ title, children }: PropsWithChildren<{ title: string }>) => (
  <Box maxWidth="55rem">
    <Typography variant="subtitle1" gutterBottom>
      {title}
    </Typography>
    {children}
  </Box>
);

const ApprovalDetailsSection = ({ approvalRequest }: Props) => {
  return (
    <Stack spacing={4} pt={2.5} px={3}>
      <AttributesSection title="Request Info">
        <AttributeItemsContainer>
          <AttributeItem>
            <ApprovalAttribute
              Icon={PersonIcon}
              label="Requested by"
              value={
                <Stack spacing={1.25} direction="row" alignItems="center">
                  <UserAvatar size="small" userName={approvalRequest.requestedByUserName} />
                  <Typography>{approvalRequest.requestedByUserName}</Typography>
                </Stack>
              }
            />
          </AttributeItem>
          <AttributeItem>
            <ApprovalAttribute
              Icon={CalendarIcon}
              label="Requested at"
              value={convertISODateTime(approvalRequest.requestedAt)}
            />
          </AttributeItem>
        </AttributeItemsContainer>

        <AttributeItemsContainer>
          <AttributeItem>
            <ApprovalAttribute Icon={CubeIcon} label="Request type" value={approvalRequest.type} />
          </AttributeItem>
          <AttributeItem>
            <ApprovalAttribute
              Icon={NumbersIcon}
              label="Status"
              value={
                <ApprovalStatusTag
                  status={approvalRequest.status}
                  approvedStepsCount={approvalRequest.approvedStepsCount}
                  totalStepsCount={approvalRequest.totalStepsCount}
                />
              }
            />
          </AttributeItem>
        </AttributeItemsContainer>
      </AttributesSection>

      <ApprovalProgressSection approvalRequest={approvalRequest} />

      <AttributesSection title="Payment Details">
        <AttributeItemsContainer>
          <AttributeItem>
            <ApprovalAttribute
              Icon={SelectIcon}
              label="Entity"
              value={commaSeparated(...approvalRequest.entityNames)}
            />
          </AttributeItem>
          <AttributeItem>
            <ApprovalAttribute Icon={CalendarIcon} label="Due Date" value={convertISODate(approvalRequest.dueDate)} />
          </AttributeItem>
        </AttributeItemsContainer>

        <AttributeItemsContainer>
          <AttributeItem>
            <ApprovalAttribute Icon={SelectIcon} label="Currency" value={approvalRequest.currencyCode} />
          </AttributeItem>
          <AttributeItem>
            <ApprovalAttribute Icon={NumbersIcon} label="Amount" value={formatNumber(approvalRequest.totalAmount)} />
          </AttributeItem>
        </AttributeItemsContainer>

        <AttributeItemsContainer>
          <AttributeItem>
            <ApprovalAttribute Icon={SelectIcon} label="Transaction Type" value={approvalRequest.transactionType} />
          </AttributeItem>
          <AttributeItem>
            <ApprovalAttribute Icon={SelectIcon} label="Transaction" value={approvalRequest.transactionNo} />
          </AttributeItem>
        </AttributeItemsContainer>
      </AttributesSection>

      <ApprovalPaymentsSection approvalRequest={approvalRequest} />
    </Stack>
  );
};

export default ApprovalDetailsSection;
