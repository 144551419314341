import { Stack, Typography } from "@mui/material";
import MemberDialogIcon from "./MemberDialogIcon";

interface EmptyMemberInviteIconProps {
  title?: string;
  description?: string;
}

const EmptyMemberIcon = ({ title, description }: EmptyMemberInviteIconProps) => {
  return (
    <Stack spacing={1} alignItems="center" justifyContent={"center"} sx={{ height: "100%" }}>
      <MemberDialogIcon />
      <Typography variant="h6">{title}</Typography>
      <Typography color="textSecondary">{description}</Typography>
    </Stack>
  );
};

export default EmptyMemberIcon;
