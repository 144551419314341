import { Button, DialogActions, Link, Stack, Typography } from "@mui/material";
import DialogHeader from "../../../../../../shared/components/dialog/DialogHeader";
import { getEditApiReportUrl } from "../utilities/editReportUrl";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import ApiReportIcon from "../../../../../icons/ApiReportIcon";

interface Props {
  report: ReportInfo;
  onClose: () => void;
}

const OpenGeneratedApiReport = ({ onClose, report }: Props) => {
  const navigateToReport = () => {
    onClose();
  };

  return (
    <>
      <DialogHeader text={"Generate API Report"} onClose={onClose} />
      <Stack spacing={1} sx={{ px: 2.5, py: 1, flex: 1, alignItems: "center", textAlign: "center" }}>
        <ApiReportIcon sx={{ width: 80, height: 80 }} />
        <Typography variant="h6">The report has been generated successfully.</Typography>
        <Typography>You can now open the report in a new tab.</Typography>
      </Stack>

      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="contained"
          onClick={navigateToReport}
          component={Link}
          href={getEditApiReportUrl(report.clientCode, report.reportId)}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textTransform: "none" }}
        >
          Open the Report
        </Button>
      </DialogActions>
    </>
  );
};

export default OpenGeneratedApiReport;
