import { useCallback, useState } from "react";
import { useSharedReportingLocalization } from "../../../../../../shared/reporting/hooks/useLocalization";
import PromptDialog from "../../../../../../shared/components/dialog/PromptDialog";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import SaveReportFormWrapper from "../../../../../../shared/reporting/components/reports/SaveReportFormWrapper";

interface Props {
  report: ReportInfo;
  duplicateReport: (report: ReportInfo, name: string, groupId: string | undefined) => Promise<ReportInfo | undefined>;
  onChanged: (reportId: string) => void;
  onClose: () => void;
}

const DuplicateReport = ({ report, duplicateReport, onChanged, onClose }: Props) => {
  const { report: locale } = useSharedReportingLocalization();

  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const onSaveClicked = useCallback(
    (name: string, groupId: string | undefined) => {
      setSaving(true);

      duplicateReport(report, name, groupId)
        .then((response) => {
          setSaving(false);
          onChanged(response?.reportId || "");
          onClose();
        })
        .catch(() => {
          setError(locale.duplicating_error);
          setSaving(false);
        });
    },
    [duplicateReport, report, onChanged, onClose, locale.duplicating_error]
  );

  return (
    <PromptDialog open={true}>
      <SaveReportFormWrapper
        report={report}
        error={error}
        title={"Duplicate Report"}
        saveBtnTitle={"Save"}
        saving={saving}
        showName
        newReport
        onClose={onClose}
        onSave={onSaveClicked}
      />
    </PromptDialog>
  );
};

export default DuplicateReport;
