import { List, ListProps } from "@mui/material";
import useLazyRenderList from "../hooks/useLazyRenderList";

const ListWithLazyRender = (props: ListProps) => {
  const { children, ...other } = props;
  const { loadMore, loadedItems } = useLazyRenderList({ children });

  return (
    <List role="listbox" onScroll={loadMore} {...other}>
      {loadedItems}
    </List>
  );
};

export default ListWithLazyRender;
