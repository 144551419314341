import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

const StretchedScrollableContent = ({ children }: PropsWithChildren) => (
  <Box
    sx={{
      justifyContent: "stretch",
      overflow: "auto",
      height: "100%",
    }}
  >
    {children}
  </Box>
);

export default StretchedScrollableContent;
