import FilterIcon from "@mui/icons-material/FilterListRounded";
import { Button, Stack } from "@mui/material";
import BadgeDetached from "../../../../shared/components/BadgeDetached";
import { useFilterContext } from "./FilterContext";
import RecordCounter from "./RecordCounter";
import { isFilterActive } from "./handlers/filterHandlers";

interface Props {
  recordCount: number;
  totalCount: number;
  hideRecordCount?: boolean;
}

const FilterControl = ({ recordCount, totalCount, hideRecordCount }: Props) => {
  const { filterState, dispatchFilters } = useFilterContext();

  const handleFiltersButtonClick = () => dispatchFilters({ type: "toggle_visibility" });

  const activeFiltersCount = filterState.visibleFilters.filter(isFilterActive).length;
  const buttonText = filterState.showFilters ? "Hide Filter" : activeFiltersCount > 0 ? "Filters" : "Filter";

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {filterState.visibleFilters.length + filterState.availableFilters.length > 0 && (
        <Button color="secondary" variant="outlined" startIcon={<FilterIcon />} onClick={handleFiltersButtonClick}>
          {buttonText}
          {!filterState.showFilters && activeFiltersCount > 0 && (
            <BadgeDetached badgeContent={activeFiltersCount} color="primary" sx={{ ml: 1 }} />
          )}
        </Button>
      )}
      <RecordCounter records={recordCount} total={totalCount} hide={hideRecordCount} />
    </Stack>
  );
};

export default FilterControl;
