import { parseISO } from "date-fns";
import { numberComparerDescBy } from "../../../../shared/utilities/arrayHelper";
import { ApiGatewaySubscription, ApiGatewaySubscriptionState } from "../../../api/adminApi";

export interface PageState {
  subscriptions: ApiGatewaySubscription[];
  updatingSubscriptionIds: string[];
}

type Action =
  | {
      type: "LoadSubscriptions";
      subscriptions: ApiGatewaySubscription[];
    }
  | {
      type: "AddSubscription";
      subscription: ApiGatewaySubscription;
    }
  | {
      type: "UpdateSubscription";
      subscription: ApiGatewaySubscription;
    }
  | {
      type: "ToggleSubscriptionState";
      subscriptionId: string;
      subscriptionState: ApiGatewaySubscriptionState;
    }
  | {
      type: "ToggleSubscriptionStateFinished";
      subscriptionId: string;
    }
  | {
      type: "DeleteSubscription";
      subscriptionId: string;
    };

export const getInitialState = (): PageState => ({
  subscriptions: [],
  updatingSubscriptionIds: [],
});

const sortSubscriptions = (subscriptions: ApiGatewaySubscription[]) =>
  [...subscriptions].sort(numberComparerDescBy((sub) => parseISO(sub.createdAt).getTime()));

export const reducer = (state: PageState, action: Action): PageState => {
  switch (action.type) {
    case "LoadSubscriptions": {
      return {
        ...state,
        subscriptions: sortSubscriptions(action.subscriptions),
      };
    }
    case "AddSubscription": {
      return {
        ...state,
        subscriptions: sortSubscriptions([...state.subscriptions, action.subscription]),
      };
    }
    case "UpdateSubscription": {
      return {
        ...state,
        subscriptions: state.subscriptions.map((sub) =>
          sub.id === action.subscription.id ? action.subscription : sub
        ),
      };
    }
    case "ToggleSubscriptionState": {
      return {
        ...state,
        subscriptions: state.subscriptions.map((sub) =>
          sub.id === action.subscriptionId ? { ...sub, state: action.subscriptionState } : sub
        ),
        updatingSubscriptionIds: [...state.updatingSubscriptionIds, action.subscriptionId],
      };
    }
    case "ToggleSubscriptionStateFinished": {
      return {
        ...state,
        updatingSubscriptionIds: state.updatingSubscriptionIds.filter((id) => id !== action.subscriptionId),
      };
    }
    case "DeleteSubscription": {
      return {
        ...state,
        subscriptions: sortSubscriptions(state.subscriptions.filter((sub) => sub.id !== action.subscriptionId)),
      };
    }
    default: {
      return state;
    }
  }
};
