import { areArraysEqual } from "@mui/base";
import { distinctBy } from "../../../../../shared/utilities/arrayHelper";
import { separatedString } from "../../../../../shared/utilities/stringHelper";
import { Contact, ContactDetails, FundPermissions, Investor, InvestorContact } from "../../../../api/adminApi";
import { Category, PortalRole } from "../../../../api/types/accessTypes";
import {
  BulkPermissionsState,
  CommunicationMatrixModel,
  ContactPermissionsChange,
  EmptyId,
  InvestorBase,
  getModelId,
  isEmptyInvestorContact,
} from "./matrixColumnDefinitions";

const includeExistingContactForSending = (contact: Contact) => !contact.invitationSentAt && Boolean(contact.email);

export const getContactChangesWhereInvitationWouldBeSent = (
  contacts: Contact[],
  originalPermissionModels: CommunicationMatrixModel[],
  modifiedPermissionModels: CommunicationMatrixModel[]
): ContactPermissionsChange[] => {
  const contactsMap = new Map(contacts.map((contact) => [contact.id, contact]));
  const toBeSent: ContactPermissionsChange[] = [];

  modifiedPermissionModels.forEach((model) => {
    const originalModel = originalPermissionModels.find(
      (originalModel) =>
        originalModel.fundInvestorId === model.fundInvestorId && originalModel.contactId === model.contactId
    );
    const contact = contactsMap.get(model.contactId);
    const isPortalRoleSet =
      model.roles.length > 0 &&
      (!originalModel || model.roles.some((role) => originalModel?.roles.indexOf(role) === -1));
    if (contact && includeExistingContactForSending(contact) && isPortalRoleSet) {
      toBeSent.push({ contact, original: originalModel, modified: model });
    }
  });
  return toBeSent;
};

export const createPermissionModelsForContacts = (investor: Investor, contacts: InvestorContact[]) => {
  const newModels: CommunicationMatrixModel[] = [];
  investor.funds.forEach((fundInvestor) => {
    contacts.forEach((contact) => {
      newModels.push({
        investorName: investor.title,
        investorId: investor.id,
        fundInvestorId: fundInvestor.fundInvestorId,
        fundId: fundInvestor.fundId,
        fundName: fundInvestor.fundName,
        contactId: contact.contactId,
        contactName: contact.name,
        contactEmail: contact.email,
        isPrimary: false,
        emailNotification: false,
        roles: [],
        categories: [],
      });
    });
  });
  return newModels;
};

export const createContactModelsForAddedInvestors = (
  investors: Investor[],
  contactDetails: ContactDetails
): CommunicationMatrixModel[] => {
  const newModels: CommunicationMatrixModel[] = [];
  const { contact, permissions } = contactDetails;
  investors.forEach((investor) => {
    const fundModels: CommunicationMatrixModel[] = [];
    investor.funds.forEach((fundInvestor) => {
      const existing = permissions.find((permission) => permission.fundInvestorId === fundInvestor.fundInvestorId);
      fundModels.push({
        investorName: investor.title,
        investorId: investor.id,
        fundInvestorId: fundInvestor.fundInvestorId,
        fundId: fundInvestor.fundId,
        fundName: fundInvestor.fundName,
        contactId: contact.id,
        contactName: contact.name,
        contactEmail: contact.email,
        isPrimary: existing?.isPrimary ?? false,
        emailNotification: existing?.emailNotification ?? false,
        roles: existing?.roles ?? [],
        categories: existing?.categories ?? [],
      });
    });

    newModels.push(...fundModels);
  });
  return newModels;
};

export const createFundModels = (permissions: FundPermissions[], fundId: string, fundName: string) => {
  const models: CommunicationMatrixModel[] = [];
  permissions.forEach((p) => {
    if (p.contacts.length > 0) {
      p.contacts.forEach((c) => {
        models.push({
          investorName: p.investorName,
          investorId: p.investorId,
          fundId,
          fundName,
          fundInvestorId: p.fundInvestorId,
          contactId: c.contactId,
          contactName: c.name,
          contactEmail: c.email,
          roles: c.roles,
          categories: c.categories,
          isPrimary: c.isPrimary,
          emailNotification: c.emailNotification,
        });
      });
    } else {
      models.push({
        investorName: p.investorName,
        investorId: p.investorId,
        fundInvestorId: p.fundInvestorId,
        fundId,
        fundName,
        contactId: EmptyId,
        contactName: "",
        contactEmail: "",
        isPrimary: false,
        emailNotification: false,
        roles: [],
        categories: [],
      });
    }
  });
  return models;
};

export const createFundModelsForEmptyInvestorsContacts = (
  investors: InvestorBase[],
  fundName: string
): CommunicationMatrixModel[] => {
  const newModels: CommunicationMatrixModel[] = [];
  investors.forEach((inv) => {
    //add empty to display in treedata as fund investor is not created yet at that moment (Investor => [FundInvestors] -> Contacts)
    newModels.push({
      investorId: inv.id,
      investorName: inv.name,
      fundInvestorId: EmptyId,
      fundId: EmptyId,
      fundName: fundName,
      contactId: EmptyId,
      contactName: "",
      contactEmail: "",
      isPrimary: false,
      emailNotification: false,
      roles: [],
      categories: [],
    });
  });
  return newModels;
};

export const createFundPermissionModelsAddedContacts = (
  baseFundModel: CommunicationMatrixModel,
  contacts: InvestorContact[]
) =>
  contacts.map((contact) => ({
    ...baseFundModel,
    contactId: contact.contactId,
    contactName: contact.name,
    contactEmail: contact.email,
    isPrimary: false,
    emailNotification: false,
    roles: [],
    categories: [],
  }));

export const updateSelectedModelsWithPermissions = (
  models: CommunicationMatrixModel[],
  selectedIds: string[],
  permissionsState: BulkPermissionsState
) => {
  const selectedModels = models.filter((m) => selectedIds.includes(getModelId(m)));
  const updatedModels = selectedModels
    .filter((m) => m.contactEmail !== undefined)
    .map((m) => ({
      ...m,
      roles: permissionsState.roles !== undefined ? permissionsState.roles : m.roles,
      categories: updateCategoriesByPermissionState(m.categories, permissionsState.categories),
      isPrimary: permissionsState.isPrimary !== undefined ? permissionsState.isPrimary : m.isPrimary,
      emailNotification:
        permissionsState.emailNotification !== undefined ? permissionsState.emailNotification : m.emailNotification,
    }));
  const modelsWithoutUpdated = models.filter(
    (m) => !selectedIds.includes(getModelId(m)) || m.contactEmail === undefined
  );
  return [...modelsWithoutUpdated, ...updatedModels];
};

const updateCategoriesByPermissionState = (
  existing: string[],
  state: Record<string, boolean | undefined>
): string[] => {
  const categoryEntries = Object.entries(state);

  if (categoryEntries.length === 0) {
    return existing;
  }

  categoryEntries.forEach(([key, value]) => {
    if (value) {
      //check if category already exists
      if (!existing.includes(key)) {
        existing.push(key);
      }
    } else if (value === false) {
      //check if category exists
      if (existing.includes(key)) {
        existing.splice(existing.indexOf(key), 1);
      }
    }
  });
  return [...existing];
};

export const getBulkPermissionsState = (
  models: CommunicationMatrixModel[],
  categories: Category[]
): BulkPermissionsState => {
  return {
    roles: getIndeterminatePortalRolePermission(models),
    categories: getCategoriesIndeterminateMap(models, categories),
    emailNotification: getIndeterminateBooleanPermission(models, "emailNotification"),
    isPrimary: getIndeterminateBooleanPermission(models, "isPrimary"),
  };
};

export const getCategoriesIndeterminateMap = (
  models: CommunicationMatrixModel[],
  categories: Category[]
): Record<string, boolean | undefined> => {
  const categoriesIndeterminateMap: Record<string, boolean | undefined> = {};
  categories.forEach((c) => {
    const modelsWithCategory = models.filter((m) => m.categories.includes(c.externalId));
    const modelsWithoutCategory = models.filter((m) => !m.categories.includes(c.externalId));
    if (modelsWithCategory.length === models.length && modelsWithoutCategory.length === 0) {
      //all
      categoriesIndeterminateMap[c.externalId] = true;
    } else if (modelsWithCategory.length === 0 && modelsWithoutCategory.length === models.length) {
      //none
      categoriesIndeterminateMap[c.externalId] = false;
    } else {
      categoriesIndeterminateMap[c.externalId] = undefined;
    }
  });
  return categoriesIndeterminateMap;
};

export const getIndeterminateBooleanPermission = (
  models: CommunicationMatrixModel[],
  key: "emailNotification" | "isPrimary"
): boolean | undefined => {
  const distinctByKey = distinctBy(models, (m) => m[key]);
  if (distinctByKey.length === 1) {
    const [model] = distinctByKey;
    return model ? model[key] : undefined;
  }
  return undefined;
};

export const getIndeterminatePortalRolePermission = (models: CommunicationMatrixModel[]): PortalRole[] | undefined => {
  if (models.length === 0) {
    return undefined;
  }
  const [first, ...restRoles] = models.map((m) => m.roles);
  const allEqual = restRoles.every((roles) => first && areArraysEqual(first, roles));
  return allEqual ? first : undefined;
};

export const getFundMatrixTreeDataPath = (model: CommunicationMatrixModel) =>
  isEmptyInvestorContact(model) ? [model.investorName] : [model.investorName, model.contactId];

export const cloneFundModelWithoutChildren = (model: CommunicationMatrixModel) => {
  const result = { ...model };
  result.roles = [];
  result.categories = [];
  result.contactId = EmptyId;
  result.contactName = "";
  result.contactEmail = "";
  return result;
};

export const investorGroupingKeySeparator = "╡";

export const getInvestorMatrixTreeDataPath = (model: CommunicationMatrixModel) => [
  separatedString(investorGroupingKeySeparator)(model.contactName, model.contactId, model.contactEmail),
  model.fundName + model.fundInvestorId,
];

export const cloneInvestorModelWithoutChildren = (model: CommunicationMatrixModel) => {
  const result = { ...model };
  result.roles = [];
  result.categories = [];
  result.fundInvestorId = EmptyId;
  result.fundName = "";
  return result;
};

export const getContactMatrixTreeDataPath = (model: CommunicationMatrixModel) => [
  model.investorName,
  model.fundName + model.fundInvestorId,
];

export const cloneContactModelWithoutChildren = (model: CommunicationMatrixModel) => {
  const result = { ...model };
  result.roles = [];
  result.categories = [];
  result.fundInvestorId = EmptyId;
  result.fundName = "";
  return result;
};
