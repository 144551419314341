import { Container, Typography } from "@mui/material";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import AppsDashboard from "./AppsDashboard";
import IntegrationsDashboard from "./IntegrationsDashboard";

const IntegrationsPage = () => {
  return (
    <>
      <GeneralPageHeader title="Apps & Integrations" />
      <Container maxWidth={false} sx={{ py: 2.5 }}>
        <Typography variant="h6" mb={2}>
          Entrilia Environment
        </Typography>
        <AppsDashboard />
        <Typography variant="h6" mt={4} mb={2}>
          Integrations
        </Typography>
        <IntegrationsDashboard />
      </Container>
    </>
  );
};

export default IntegrationsPage;
