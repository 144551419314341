import { Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Link as RouterLink } from "react-router-dom";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import { useObjectDefinitionContext } from "../ObjectDefinitionContext";

const EditLayoutLink = ({ configurationId }: { configurationId: string }) => {
  const { clientCode } = useClientContext();
  const { hasEditPermissions, objectDefinition } = useObjectDefinitionContext();

  if (!hasEditPermissions) {
    return null;
  }

  const editLayoutPath = `/${clientCode}/${pageRoutes.settings}/${pageRoutes.dataModel}/${pageRoutes.orgObjects}/${objectDefinition.objectType}/${pageRoutes.layout}/${configurationId}`;

  return (
    <Button component={RouterLink} to={editLayoutPath} color="secondary">
      Edit Layout
    </Button>
  );
};

export const getColumnDefinitions = (): GridColDef<DataModelConfiguration>[] => {
  return [
    {
      field: "name",
      headerName: "Configuration Name",
      sortable: false,
      flex: 2,
    },
    {
      field: "clientCodes",
      headerName: "Companies",
      sortable: false,
      flex: 1,
      valueGetter: (_, row) => row.clientCodes.length || "None",
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 150,
      cellClassName: "grid-row-actions",
      align: "right",
      renderCell: ({ row }) => <EditLayoutLink configurationId={row.id} />,
    },
  ];
};
