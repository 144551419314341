import { GridRowId } from "@mui/x-data-grid-premium";
import { localStorageService } from "../../../../../shared/services/storage";

export const REPORTS_GRID_STORAGE_KEY = "reports_grid";

export type GridKey =
  | "COMPANY_UI_REPORTS_GRID"
  | "COMPANY_API_REPORTS_GRID"
  | "ORGANIZATION_UI_REPORTS_GRID"
  | "ORGANIZATION_API_REPORTS_GRID"
  | "ORGANIZATION_REPORT_TEMPLATES_GRID";
type ClientCode = string;
interface GridState extends Partial<Record<GridKey, GroupingState>> {}
interface GroupingState extends Record<GridRowId, boolean> {}
export interface ReportsGridExpandState extends Record<ClientCode, GridState> {}

const getReportsGridExpandState = () =>
  localStorageService.getItemWithFallback<ReportsGridExpandState>(REPORTS_GRID_STORAGE_KEY, {});

const toggleSingleGridRow = (gridKey: GridKey, clientCode: ClientCode, rowId: GridRowId, expanded: boolean) => {
  const reportsGridExpandState = getReportsGridExpandState();

  const clientState = reportsGridExpandState[clientCode];
  if (!clientState) {
    reportsGridExpandState[clientCode] = {
      [gridKey]: {
        [rowId]: expanded,
      },
    };
  } else {
    const gridState = clientState[gridKey];
    if (!gridState) {
      clientState[gridKey] = {
        [rowId]: expanded,
      };
    } else {
      gridState[rowId] = expanded;
    }
  }

  localStorageService.setItem(REPORTS_GRID_STORAGE_KEY, reportsGridExpandState);

  return reportsGridExpandState;
};

const toggleAllGridRows = (gridKey: GridKey, clientCode: ClientCode, expanded: boolean, gridRowIds: GridRowId[]) => {
  const reportsGridExpandState = getReportsGridExpandState();

  const clientState = reportsGridExpandState[clientCode];
  if (!clientState) {
    reportsGridExpandState[clientCode] = {
      [gridKey]: gridRowIds.reduce((acc, rowId) => {
        acc[rowId] = expanded;
        return acc;
      }, {} as GroupingState),
    };
  } else {
    const gridState = clientState[gridKey];
    if (!gridState) {
      clientState[gridKey] = gridRowIds.reduce((acc, rowId) => {
        acc[rowId] = expanded;
        return acc;
      }, {} as GroupingState);
    } else {
      gridRowIds.forEach((rowId) => {
        gridState[rowId] = expanded;
      });
    }
  }

  localStorageService.setItem(REPORTS_GRID_STORAGE_KEY, reportsGridExpandState);

  return reportsGridExpandState;
};

export const reportsGridExpandStorage = {
  getReportsGridExpandState,
  toggleSingleGridRow,
  toggleAllGridRows,
};
