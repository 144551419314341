import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { UserConsent } from "../../../../api/adminApi";
import ActionsMenuButton from "../../../common/ActionsMenuButton";
import { useConsentsContext } from "./ConsentsContext";
import { getConsentsGridColumns } from "./consentsDataProvider";

const ConsentsGrid = () => {
  const { isLoading, consents, canEdit, handleEditConsent, handleConsentDelete } = useConsentsContext();

  const columns = getConsentsGridColumns();

  if (canEdit) {
    columns.push({
      field: "actions",
      headerName: "",
      width: 30,
      cellClassName: "grid-row-actions",
      align: "right",
      renderCell: ({ row }) => (
        <ActionsMenuButton
          items={[
            {
              icon: <DeleteOutlineIcon color="error" />,
              label: "Remove",
              onClick: () => handleConsentDelete(row),
            },
          ]}
        />
      ),
    });
  }

  return (
    <DataGrid<UserConsent>
      getRowId={(row) => row.name}
      columns={columns}
      rows={consents}
      loading={isLoading}
      slots={{
        loadingOverlay: () => <InlineLoader />,
      }}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableRowSelectionOnClick
      onRowClick={(params) => handleEditConsent(params.row)}
      selectableRows
    />
  );
};

export default ConsentsGrid;
