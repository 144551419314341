import { useState } from "react";

type ToggleStateReturnType = [boolean, () => void];

const useToggleState = (initialValue: boolean): ToggleStateReturnType => {
  const [value, setValue] = useState<boolean>(initialValue);
  const toggleValue = () => setValue((currentValue) => !currentValue);
  return [value, toggleValue];
};

export default useToggleState;
