import { Box, Container, Typography } from "@mui/material";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import { useObjectDefinitionContext } from "../ObjectDefinitionContext";
import { getColumnDefinitions } from "./objectLayoutsGridDataProvider";

const ObjectLayouts = () => {
  const { configurations } = useObjectDefinitionContext();

  return (
    <Container maxWidth="md" disableGutters sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2.5}>
        <Typography color="text.secondary">Customize layouts for different companies in the organization.</Typography>
      </Box>
      <DataGrid<DataModelConfiguration>
        columns={getColumnDefinitions()}
        rows={configurations}
        rowHeight={40}
        slots={{
          loadingOverlay: () => <InlineLoader />,
          noRowsOverlay: () => (
            <CenteredWrapper>
              <Typography color="text.secondary">No configurations yet</Typography>
            </CenteredWrapper>
          ),
        }}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
        disableMultipleRowSelection
      />
    </Container>
  );
};

export default ObjectLayouts;
