export const parseSearchParamWithFallback = <T extends string>(
  searchParams: URLSearchParams,
  key: string,
  validValues: T[] | readonly T[],
  defaultValue?: T
): T => {
  const paramValue = searchParams.get(key);
  return !!paramValue && validValues.some((value) => value.toLowerCase() === paramValue.toLowerCase())
    ? (paramValue as T)
    : ((defaultValue ?? validValues[0]) as T);
};
