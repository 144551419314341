import { Container, Stack, Typography } from "@mui/material";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { OperationStatus } from "./importDataPagesTypes";

interface Props {
  title: string;
  operationStatus: OperationStatus;
}

const FullPageStatusProgress = ({ title, operationStatus }: Props) => {
  const { statusText, details, error } = operationStatus;

  return (
    <Container sx={{ height: "100%" }}>
      <Stack justifyContent="center" alignItems="center" height="100%">
        <Stack alignItems="center" spacing={2}>
          <InlineLoader />
          <Typography color="text.secondary" textAlign="center">
            {title}
          </Typography>
          <Stack spacing={1} alignItems="flex-start" height="12rem" mt={2}>
            {error ? (
              <Typography variant="subtitle2" color="error">
                {error}
              </Typography>
            ) : (
              <Typography variant="subtitle2">{statusText}</Typography>
            )}
            {details.map(([key, value]) => (
              <Stack key={key} direction="row" spacing={1} width="100%">
                <Typography color="text.secondary" width="8rem">
                  {key}
                </Typography>
                <Typography>{value}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default FullPageStatusProgress;
