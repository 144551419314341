import StatusChip from "../../../../shared/components/StatusChip";
import { DataCollectionSubmissionStatus } from "../../../api/types/dataCollectionTypes";

interface Props {
  status: DataCollectionSubmissionStatus;
}

const DataSubmissionStatusTag = ({ status }: Props) => {
  switch (status) {
    case "Pending": {
      return <StatusChip label="Pending" withDot color={(t) => t.palette.text.secondary} />;
    }
    case "PendingApproval":
      return <StatusChip label="Pending Approval" withDot color={(t) => t.palette.warning.main} />;
    case "Completed":
      return <StatusChip label="Completed" withDot color={(t) => t.palette.success.main} />;
    default:
      return null;
  }
};

export default DataSubmissionStatusTag;
