import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { DwhShare } from "../../../api/types/dwhTypes";
import {
  UpdateShareForm,
  UpdateShareFormFields,
  formatDwhShareType,
  initialUpdateShareForm,
  isFormEdited,
  mapUpdateShareFormToRequest,
} from "./updateShareForm";

interface Props {
  share: DwhShare | undefined;
  onCancel: () => void;
  onShareUpdated: (accountCode: string) => void;
}

const updateDwhShare = withErrorHandling(adminApi.patchDwhShare);

const UpdateShareDialog = ({ share, onCancel, onShareUpdated }: Props) => {
  const [form, setForm] = useState<UpdateShareForm>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setForm(initialUpdateShareForm(share));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [share?.accountCode]);

  const handleEdit =
    (field: UpdateShareFormFields): React.ChangeEventHandler<HTMLInputElement> =>
    ({ target }) =>
      setForm((currentForm) => ({
        ...currentForm,
        [field]: target.value,
      }));

  const handleCancel = () => {
    onCancel();
    setSaving(false);
  };

  const handleSave = async () => {
    if (!share || !form) {
      return;
    }

    setSaving(true);
    const [, error] = await updateDwhShare(share.id, mapUpdateShareFormToRequest(form));
    setSaving(false);

    if (error) {
      logError(error, "[UpdateShareDialog] handleSave");
      setErrorMessage(getErrorMessage(error) || "Server error");
      return;
    }

    onShareUpdated(share.accountCode.toUpperCase());
  };

  return (
    <Dialog open={!!share} onClose={handleCancel} maxWidth="xs" fullWidth>
      <DialogTitle>Edit Sharing Description</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <DialogContent sx={(theme) => ({ px: theme.spacing(4) })}>
        <Typography variant="subtitle2" mb={1}>
          Type
        </Typography>
        <TextField disabled fullWidth variant="outlined" value={formatDwhShareType(share?.type || "AllTables")} />
        <Typography variant="subtitle2" mb={1} mt={3}>
          Account Code
        </Typography>
        <TextField disabled fullWidth variant="outlined" value={share?.accountCode || ""} />
        <Stack direction="row" spacing={1} mt={3} mb={1}>
          <Typography variant="subtitle2">Description</Typography>
          <Typography color="text.secondary">(optional)</Typography>
        </Stack>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Add text..."
          value={form?.description || ""}
          onChange={handleEdit("description")}
          error={!!errorMessage}
          helperText={errorMessage}
          spellCheck
        />
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={saving}
          onClick={handleSave}
          disabled={!share || !form || !isFormEdited(form, share)}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateShareDialog;
