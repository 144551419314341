import { getHours } from "date-fns";
import { BackupMasterConfiguration, BackupMasterConnectionState } from "../../../api/adminApi";
import { definedValidator } from "../../../../shared/utilities/validators";

type CurrentProcess = "testingConnection" | "togglingDailySync" | "updatingConfiguration";

export interface DataBackupPageState {
  enabled: boolean;
  lastChangedBy?: string;
  lastChangedOn?: string;
  lastBackupDate?: string;
  isLatestBackupSuccessful?: boolean;
  configuration: BackupMasterConfiguration;
  configurationOptions?: Record<string, string>;
  alertMessage?: DataBackupPageAlert;
  activeProcess?: CurrentProcess;
  isConfigurationDirty: boolean;
  isValid: boolean;
  errors: {
    hourError?: string;
  };
}

interface DataBackupPageAlert {
  severity: "info" | "error";
  message: string;
}

type Action =
  | {
      type: "SetConnectionState";
      payload: BackupMasterConnectionState;
    }
  | {
      type: "SetConfiguration";
      payload: BackupMasterConfiguration;
    }
  | {
      type: "SetAlert";
      payload?: DataBackupPageAlert;
    }
  | {
      type: "UpdateConfiguration";
      payload: Partial<BackupMasterConfiguration>;
    }
  | {
      type: "SetConfigurationOptions";
      payload: Record<string, string>;
    }
  | {
      type: "SetActiveProcess";
      payload: CurrentProcess;
    }
  | {
      type: "ResetActiveProcess";
    }
  | {
      type: "ResetConfigurationDirty";
    };

export const getInitialState = (): DataBackupPageState => ({
  enabled: false,
  configuration: {
    awsBackupConfiguration: {
      accessKey: "",
      secretKey: "",
      region: "",
      bucket: "",
      path: "",
    },
    notifiedUserEmails: [],
    hour: 0,
  },
  isConfigurationDirty: false,
  errors: {},
  isValid: true,
});

export const reducer = (state: DataBackupPageState, action: Action): DataBackupPageState => {
  switch (action.type) {
    case "SetAlert": {
      return {
        ...state,
        alertMessage: action.payload,
      };
    }
    case "SetConnectionState": {
      return {
        ...state,
        enabled: action.payload.enabled,
        lastChangedBy: action.payload.lastChangedBy,
        lastChangedOn: action.payload.lastChangedOn,
        lastBackupDate: action.payload.lastBackupDate,
        isLatestBackupSuccessful: action.payload.isLatestBackupSuccessful,
      };
    }
    case "SetConfiguration": {
      return {
        ...state,
        configuration: {
          ...action.payload,
          hour: getHours(new Date(Date.UTC(0, 0, 0, action.payload.hour || 0))), //Incoming hour is in UTC
        },
      };
    }
    case "UpdateConfiguration": {
      return validateConfiguration({
        ...state,
        configuration: { ...state.configuration, ...action.payload },
        isConfigurationDirty: true,
      });
    }
    case "SetConfigurationOptions": {
      return {
        ...state,
        configurationOptions: action.payload,
      };
    }
    case "SetActiveProcess": {
      return {
        ...state,
        activeProcess: action.payload,
      };
    }
    case "ResetActiveProcess": {
      return {
        ...state,
        activeProcess: undefined,
      };
    }
    case "ResetConfigurationDirty": {
      return {
        ...state,
        isConfigurationDirty: false,
      };
    }
    default:
      return state;
  }
};

const validateConfiguration = (state: DataBackupPageState): DataBackupPageState => {
  const validateHour = definedValidator<number>("Hour must be defined");
  const hourValidationResult = validateHour(state.configuration.hour);

  const isValid = hourValidationResult.isValid;
  return {
    ...state,
    isValid,
    errors: {
      hourError: hourValidationResult.error,
    },
  };
};
