import { Button, FormControlLabel, Stack, Switch } from "@mui/material";

interface Props {
  integrationEnabled: boolean;
  testConnectionProcessing?: boolean;
  toggleIntegrationProcessing?: boolean;
  disabled: boolean;
  onTestConnection: () => void;
  onToggleIntegration: (enabled: boolean) => void;
}

const PassthroughConnectionActions = ({
  integrationEnabled,
  onTestConnection,
  onToggleIntegration,
  testConnectionProcessing,
  toggleIntegrationProcessing,
  disabled,
}: Props) => {
  return (
    <Stack direction={"row"} spacing={1}>
      <Button
        variant="outlined"
        color="secondary"
        disabled={testConnectionProcessing || disabled}
        onClick={onTestConnection}
      >
        Test Connection
      </Button>
      <FormControlLabel
        disabled={disabled}
        control={
          <Switch
            disabled={toggleIntegrationProcessing}
            checked={integrationEnabled}
            onChange={(_, checked) => onToggleIntegration(checked)}
          />
        }
        label="Enable Integration"
      />
    </Stack>
  );
};

export default PassthroughConnectionActions;
