import { useState, SyntheticEvent } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import ConnectionManagement from "./connection-management/ConnectionManagement";
import Logs from "./logs/Logs";

type Tabs = "connection_management" | "logs";

const CrmManagement = () => {
  const [selectedTab, setSelectedTab] = useState<Tabs>("connection_management");

  const handleTabChange = (_: SyntheticEvent, value: Tabs) => setSelectedTab(value);

  return (
    <Box px={3}>
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChange}>
          <Tab label="Connection Management" value="connection_management" />
          <Tab label="Logs" value="logs" />
        </TabList>
        <TabPanel value="connection_management">
          <ConnectionManagement />
        </TabPanel>
        <TabPanel value="logs">
          <Logs />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default CrmManagement;
