import CheckedIcon from "@mui/icons-material/CheckCircleRounded";
import UncheckedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  checked: boolean;
  title: string;
  actionLabel: string;
  onActionClick: () => void;
}

const FundraisingConfigurationCard = ({
  checked,
  title,
  actionLabel,
  onActionClick,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction="row" spacing={1.5} alignItems="flex-start">
          {checked ? <CheckedIcon color="primary" /> : <UncheckedIcon color="disabled" />}
          <Stack spacing={0.5} pr={0.5} width="100%">
            <Typography variant="subtitle2">{title}</Typography>
            {children}
          </Stack>
          <Button variant="outlined" color="secondary" onClick={onActionClick}>
            {actionLabel}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FundraisingConfigurationCard;
