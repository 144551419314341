import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { Category } from "../../../../../../api/types/accessTypes";

type CategoryOrderUpdate = [string, number];

// When categories are swapped, we can't update at once, since target category order will collide with the existing order.
export const getCategoryOrderUpdates = (
  categories: Category[],
  maxCategorySortOrder: number
): CategoryOrderUpdate[] => {
  const transitions: [string, number, number][] = [];
  const resultUpdates: CategoryOrderUpdate[] = [];
  const residualUpdates: CategoryOrderUpdate[] = [];

  const sourceOrders = categories.map((c) => c.sortOrder);
  const targetOrders = sourceOrders.slice().sort((x, y) => x - y);

  targetOrders.forEach((targetOrder, i) => {
    const category = defined(categories[i]);
    if (category.sortOrder !== targetOrder) {
      transitions.push([category.id, category.sortOrder, targetOrder]);
    }
  });

  const currentCategories = Object.fromEntries(categories.map((c) => [c.id, c.sortOrder]));

  let temporaryOrder = maxCategorySortOrder + 100;

  transitions.forEach(([id, , targetOrder]) => {
    if (Object.values(currentCategories).some((order) => order === targetOrder)) {
      temporaryOrder += 1;
      resultUpdates.push([id, temporaryOrder]);
      residualUpdates.push([id, targetOrder]);
      currentCategories[id] = temporaryOrder;
    } else {
      resultUpdates.push([id, targetOrder]);
      currentCategories[id] = targetOrder;
    }
  });

  return [...resultUpdates, ...residualUpdates];
};
