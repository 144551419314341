import { formatFileSize } from "../../../../shared/utilities/fileHelper";

export const acceptedFileExtensions = [
  ".pdf",
  ".docx",
  ".doc",
  ".xlsx",
  ".xlsm",
  ".xls",
  ".pptx",
  ".ppt",
  ".jpg",
  ".jpeg",
  ".png",
  ".webp",
];

export const maxFileSize = 25 * 1024 * 1024;

export const dropAreaSubtitle = `Maximum file size: ${formatFileSize(
  maxFileSize
)}. Supported formats: pdf, doc(x), xls(x)(m), ppt(x), jpg, jpeg, png, webp.`;
