import { createContext, PropsWithChildren, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";
import { Metric } from "../../../api/types/portfolioMonitoringTypes";

interface Props {
  hasEditPermissions: boolean;
  metrics: Metric[];
  onEditMetric: (metric: Metric) => void;
  onArchiveMetric: (metric: Metric) => void;
}

const MetricsPageContext = createContext<Props | undefined>(undefined);

export const MetricsPageContextProvider = ({ children, ...props }: PropsWithChildren<Props>) => (
  <MetricsPageContext.Provider value={props}>{children}</MetricsPageContext.Provider>
);

export const useMetricsPageContext = () =>
  defined(useContext(MetricsPageContext), "Attempt to use MetricsPageContext without provider");
