import AddIcon from "@mui/icons-material/Add";
import { Button, Popover } from "@mui/material";
import { useRef, useState } from "react";
import AddFilterSelector from "./AddFilterSelector";
import { useFilterContext } from "./FilterContext";

const AddFilterButton = () => {
  const { filterState, dispatchFilters } = useFilterContext();
  const [adding, setAdding] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  if (filterState.availableFilters.length === 0) {
    return null;
  }

  const handleFilterSelected = (filterId: string) => {
    setAdding(false);
    dispatchFilters({ type: "add_filter", filterId });
  };

  return (
    <>
      <Button variant="text" ref={buttonRef} onClick={() => setAdding(!adding)} startIcon={<AddIcon />}>
        Add Filter
      </Button>
      <Popover
        open={adding}
        onClose={() => setAdding(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <AddFilterSelector onSelected={handleFilterSelected} />
      </Popover>
    </>
  );
};

export default AddFilterButton;
