import adminApi, { ApiGatewaySyncLogs } from "../../../../api/adminApi";
import LogsWrapper from "../../../common/syncLogs/LogsWrapper";
import LogsTable from "./LogsTable";

const Logs = () => {
  return (
    <LogsWrapper<ApiGatewaySyncLogs>
      fetchLogsFromApi={(params) => adminApi.getApiGatewayLogs(params)}
      renderTable={(logItems) => <LogsTable logItems={logItems} />}
    />
  );
};

export default Logs;
