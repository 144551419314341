import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Checkbox, InputAdornment, ListItemText, MenuItem, Select } from "@mui/material";
import { ClientUserType, allClientUserTypes } from "../../../../../../api/types/userManagementTypes";
import { formatClientUserType, getSelectedSx } from "../../definitions";

interface Props {
  selectedValues: ClientUserType[];
  onChange: (values: ClientUserType[]) => void;
  showSupportValue: boolean;
  disabled?: boolean;
}

const MemberTypeFilter = ({ selectedValues, onChange, showSupportValue, disabled }: Props) => {
  return (
    <Select
      disabled={disabled}
      onKeyDownCapture={(e) => {
        e.stopPropagation();
      }}
      sx={(theme) => ({
        ...getSelectedSx(theme, selectedValues.length > 0),
        width: theme.spacing(20),
        ".MuiInputBase-input": { color: theme.palette.secondary.main },
      })}
      displayEmpty
      multiple
      value={selectedValues}
      onChange={(e) => onChange(e.target.value as ClientUserType[])}
      startAdornment={
        <InputAdornment position="start">
          <SupervisorAccountIcon color={disabled ? "disabled" : "secondary"} />
        </InputAdornment>
      }
      renderValue={(value) =>
        value.length > 0 ? value.map((userType) => formatClientUserType(userType)).join(", ") : "Type"
      }
    >
      {allClientUserTypes.map(
        (userType) =>
          (userType !== "Support" || showSupportValue) && (
            <MenuItem key={userType} value={userType} dense>
              <Checkbox checked={selectedValues.includes(userType)} />
              <ListItemText primary={formatClientUserType(userType)} />
            </MenuItem>
          )
      )}
    </Select>
  );
};

export default MemberTypeFilter;
