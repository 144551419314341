import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { ReportActionsContextType, ReportContextType } from "../../../contexts/reportContext.types";
import { ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";

type ApiReportContextType<T extends ReportInfo> = ReportContextType<T> & {
  actions: ApiReportActionsContextType<T>;
};

const ApiReportsContext = createContext<ApiReportContextType<ReportInfo> | undefined>(undefined);

interface ApiReportActionsContextType<T extends ReportInfo> extends ReportActionsContextType<T> {}

export const ApiReportsContextProvider = ({
  children,
  ...props
}: PropsWithChildren<ApiReportContextType<ReportInfo>>) => {
  return <ApiReportsContext.Provider value={props}>{children}</ApiReportsContext.Provider>;
};

export function useApiReportsContext<T extends ReportInfo>() {
  const context = useContext(ApiReportsContext);
  return defined(context as unknown as ApiReportContextType<T>);
}
