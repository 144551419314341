import { ApiResponse } from "../../../../../shared/api/types";
import { CrmSyncSettingsValidationResult } from "../../../../api/crmConnectorsApi";

export interface ValidationState {
  errorMessages: string[];
  errorFields: string[];
}

type Action =
  | {
      type: "VALIDATE";
      payload: ApiResponse<CrmSyncSettingsValidationResult>;
    }
  | {
      type: "HIDE_ERRORS";
      payload?: never;
    };

export const getInitialState = (): ValidationState => ({
  errorMessages: [],
  errorFields: [],
});

export const reducer = (state: ValidationState, { type, payload }: Action) => {
  switch (type) {
    case "VALIDATE": {
      if (!payload.success) {
        return {
          errorMessages: payload.error?.message ? [payload.error.message] : [],
          errorFields: [],
        };
      }

      const { errors } = payload.data;

      if (errors.length > 0) {
        return {
          errorMessages: errors.map((e) => e.error),
          errorFields: errors.map((e) => e.errorFieldName).filter((f) => !!f),
        };
      }

      return {
        errorMessages: [],
        errorFields: [],
      };
    }
    case "HIDE_ERRORS": {
      return {
        ...state,
        errorMessages: [],
      };
    }
    default: {
      return state;
    }
  }
};
