import { Alert, Snackbar } from "@mui/material";

interface Props {
  open: boolean;
  message?: string | null | undefined;
  variant?: "success" | "error" | "warning" | "info" | undefined;
}

const UpdateNotificationBar = ({ open, message, variant }: Props) => {
  const msg = message || "The update will be applied in a few minutes";

  return (
    <Snackbar
      children={
        variant && (
          <Alert variant="filled" severity={variant}>
            {msg}
          </Alert>
        )
      }
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      message={msg}
    />
  );
};

export default UpdateNotificationBar;
