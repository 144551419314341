import { Box, Drawer, IconButton, IconButtonProps, Stack, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import useToggleState from "../../../shared/hooks/useToggleState";
import { useClientContext } from "../../context/ClientContext";
import { useUserContext } from "../../context/UserContext";
import EntriliaLogo from "../../images/logo.png";
import EntriliaBetaLogo from "../../images/logobeta.png";
import { lightTheme, darkTheme as theme } from "../../themes";
import BottomMenu from "./BottomMenu";
import MainMenu from "./MainMenu";
import { MainMenuContextProvider } from "./MainMenuContext";
import ClientSelector from "./client-selector/ClientSelector";
import DockPanelIcon from "./icons/DockPanelIcon";
import HidePanelIcon from "./icons/HidePanelIcon";
import ShowPanelIcon from "./icons/ShowPanelIcon";

const HideMenuIconButton = (props: IconButtonProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <IconButton onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} {...props}>
      {hovered ? <HidePanelIcon /> : <DockPanelIcon />}
    </IconButton>
  );
};

const ShowMenuIconButton = (props: IconButtonProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <IconButton onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} {...props}>
      {hovered ? <ShowPanelIcon /> : <DockPanelIcon />}
    </IconButton>
  );
};

const LeftSideRootMenu = () => {
  const [showSideMenu, toggleSideMenu] = useToggleState(true);

  const { clients, isBetaAccessEnabled } = useUserContext();
  const { clientCode } = useClientContext();

  const fundAdminClientCode =
    clients.find((c) => c.type === "FundAdmin")?.clientCode || clients[0]?.clientCode || clientCode;

  const menuWidth = theme.spacing(34);

  const logo = isBetaAccessEnabled ? EntriliaBetaLogo : EntriliaLogo;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: "100%",
          width: showSideMenu ? menuWidth : 0,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(showSideMenu && {
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        {!showSideMenu && (
          <ShowMenuIconButton sx={{ position: "absolute", top: theme.spacing(2) }} onClick={toggleSideMenu} />
        )}
        <Drawer
          sx={{
            flexShrink: 0,
            width: menuWidth,
            "& .MuiDrawer-paper": {
              padding: 0,
              width: menuWidth,
            },
          }}
          variant="persistent"
          anchor="left"
          open={showSideMenu}
        >
          <Stack height="100%" display="flex" spacing={1} p={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" p={0.5}>
              <Link to={`/${fundAdminClientCode}/home`}>
                <img src={logo} alt="Entrilia logo" style={{ height: 20 }} />
              </Link>
              <HideMenuIconButton onClick={toggleSideMenu} />
            </Stack>
            <ThemeProvider theme={lightTheme}>
              <ClientSelector
                selectHoveredBgColor={theme.palette.action.hover}
                selectBgColor={theme.palette.action.selected}
              />
            </ThemeProvider>
            <MainMenuContextProvider>
              <Box sx={{ flex: 1, overflow: "auto" }}>
                <MainMenu />
              </Box>
              <BottomMenu />
            </MainMenuContextProvider>
          </Stack>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
};

export default LeftSideRootMenu;
