import { LoadingButton } from "@mui/lab";
import { Button, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import axios from "axios";
import deepEqual from "fast-deep-equal";
import { useCallback, useEffect, useState } from "react";
import ColorEdit from "../../../../../shared/components/ColorEdit";
import ImageUploaderWithPreview from "../../../../../shared/components/imageUploader/ImageUploaderWithPreview";
import { convertToFormData } from "../../../../../shared/utilities/formDataHelper";
import { wait } from "../../../../../shared/utilities/promiseHelper";
import adminApi, { SignOnSettings } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import ColorEditorSection from "./ColorEditorSection";
import UpdateNotificationBar from "./UpdateNotificationBar";

interface Props {
  currentSettings: SignOnSettings;
  mainColor: string;
}

const SignInPageEditor = ({ currentSettings, mainColor }: Props) => {
  const [settings, setSettings] = useState<SignOnSettings>(currentSettings);
  const [defaultSettings, setDefaultSettings] = useState<SignOnSettings>(currentSettings);
  const [backgroundImage, setBackgroundImage] = useState<File>();
  const [logoImage, setLogoImage] = useState<File>();
  const [isSaving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isJustSaved, setJustSaved] = useState<boolean>(false);
  const { hasPermissions } = useClientContext();
  const canEditSettings = hasPermissions(["ManageInvestorPortalSettings"]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const navToParam = searchParams.get("navTo");
    if (!navToParam) {
      return;
    }

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });

    searchParams.delete("navTo");
    const queryString = searchParams.toString();

    window.history.replaceState(
      {},
      "",
      queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname
    );
  }, []);

  const save = useCallback(async () => {
    setSaving(true);
    try {
      const signOnFormData = convertToFormData(settings);

      if (backgroundImage) {
        signOnFormData.append("BackgroundImage", backgroundImage);
      }

      if (logoImage) {
        signOnFormData.append("ClientLogo", logoImage);
      }

      await adminApi.updateSignOnSettings(signOnFormData);
      setDefaultSettings(settings);
    } catch (e) {
      if (axios.isAxiosError(e) && e.message) {
        setError(`Save failed. ${e.message}`);
      } else {
        setError("Save failed. Please try again later.");
      }
    } finally {
      setSaving(false);
      setJustSaved(true);
      await wait(5 * 1000);
      setJustSaved(false);
      setError(null);
    }
  }, [settings, backgroundImage, logoImage]);

  const handleSaveClick = async () => {
    await save();
  };

  const handleResetClick = () => {
    setSettings(defaultSettings);
    setBackgroundImage(undefined);
    setLogoImage(undefined);
  };

  const handleChange = (key: keyof SignOnSettings) => (value: string | boolean) =>
    setSettings({ ...settings, [key]: value });

  const handleBackgroundImageChange = (file: File | undefined, url: string) => {
    handleChange("backgroundImageUrl")(url);
    setBackgroundImage(file);
  };

  const handleLogoImageChange = (file: File | undefined, url: string) => {
    handleChange("clientLogoUrl")(url);
    setLogoImage(file);
  };

  const disableSave = !canEditSettings || deepEqual(defaultSettings, settings);
  const backgroundColor = settings?.backgroundColor || "#ffffff";

  const backgroundColorRadioLabel = (
    <Stack>
      <Typography variant="subtitle1">Background Color</Typography>
      <Typography color="text.secondary">The background color for the sign in page.</Typography>
    </Stack>
  );
  const backgroundImageRadioLabel = (
    <Stack>
      <Typography variant="subtitle1">Image</Typography>
      <Typography color="text.secondary">
        Set an image as a background for the sign in page. PNG or JPG. Recommended size: 2560x1600 pixels.
      </Typography>
    </Stack>
  );

  return (
    <Stack spacing={3} sx={(theme) => ({ maxWidth: theme.spacing(89) })}>
      <Stack spacing={1}>
        <Typography variant="h6">Sign in page</Typography>
        <Typography color="text.secondary">
          Choose how sign-in page looks. Set either color or image as the background.
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle1">Logotype on Sign In page </Typography>
        <Typography color="text.secondary">PNG or JPG. Recommended size: 280x56 pixels.</Typography>
      </Stack>
      <Stack spacing={1} mt={1}>
        <Typography variant="subtitle1">Light Background </Typography>
        <ImageUploaderWithPreview
          disabled={!canEditSettings}
          width={280}
          height={56}
          allowedImageTypes="image/png, image/jpeg"
          loading={isSaving}
          originalImageSrc={defaultSettings?.clientLogoUrl}
          imageSrc={settings?.clientLogoUrl}
          onImageSelected={handleLogoImageChange}
          uploadButtonName="Upload Logotype"
          maxFileSizeBytes={1 * 1024 * 1024}
          border="dashed"
          buttonsPosition="right"
          Placeholder={
            <Stack p="1rem">
              <Typography variant="h5" color="#C7CED4">
                Logotype
              </Typography>
            </Stack>
          }
        />
      </Stack>

      <ColorEditorSection
        disabled={!canEditSettings}
        title="Theme color"
        subtitle="The color of buttons and links on login pages."
        color={settings?.primaryThemeColor || mainColor}
        onChange={handleChange("primaryThemeColor")}
      />

      <Stack mt={1} spacing={2}>
        <Typography variant="subtitle1">Background </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} useFlexGap>
          <FormControlLabel
            control={
              <Radio
                disabled={!canEditSettings}
                checked={!settings?.useBackgroundImage}
                onChange={() => handleChange("useBackgroundImage")(false)}
                value={false}
              />
            }
            label={backgroundColorRadioLabel}
          />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography>{backgroundColor.toUpperCase()}</Typography>
            <ColorEdit
              disabled={!canEditSettings}
              color={backgroundColor}
              buttonSize={24}
              onColorChange={handleChange("backgroundColor")}
              popover={{
                anchorOrigin: { vertical: "center", horizontal: "right" },
                transformOrigin: { vertical: "center", horizontal: "left" },
              }}
              showAchor="leftCenter"
            />
          </Stack>
        </Stack>
        <Stack>
          <FormControlLabel
            control={
              <Radio
                disabled={!canEditSettings}
                checked={!!settings?.useBackgroundImage}
                onChange={() => handleChange("useBackgroundImage")(true)}
                value={true}
              />
            }
            label={backgroundImageRadioLabel}
          />
          <ImageUploaderWithPreview
            disabled={!canEditSettings}
            width={180}
            height={140}
            allowedImageTypes="image/png, image/jpeg"
            loading={isSaving}
            originalImageSrc={defaultSettings?.backgroundImageUrl}
            imageSrc={settings?.backgroundImageUrl}
            onImageSelected={handleBackgroundImageChange}
            uploadButtonName="Upload File"
            maxFileSizeBytes={2 * 1024 * 1024}
            buttonsPosition="bottom"
          />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} mt={2}>
        <LoadingButton
          variant="contained"
          size="medium"
          sx={(theme) => ({ width: theme.spacing(10) })}
          disabled={disableSave}
          loading={isSaving}
          onClick={handleSaveClick}
        >
          Save
        </LoadingButton>
        <Button size="medium" variant="text" onClick={handleResetClick} disabled={!canEditSettings}>
          Reset
        </Button>
      </Stack>
      <UpdateNotificationBar variant={error ? "error" : undefined} open={isJustSaved} message={error} />
    </Stack>
  );
};

export default SignInPageEditor;
