import {
  OrganizationDeactivatedMember,
  OrganizationMember,
  OrganizationMemberInvitation,
} from "../../../../../../api/types/userManagementTypes";
import { MemberFilters, filterOrganizationRoles, organizationRolesDataMap } from "../../definitions";

export const getFilteredMembers = (members: OrganizationMember[], filters: MemberFilters): OrganizationMember[] => {
  return members.filter((member) => {
    const searchTerm = filters.search.trim().toLowerCase();

    if (filters.types.length > 0 && !filters.types.includes(member.userType)) {
      return false;
    }

    if (filters.role && !member.organizationPermissions.roles.includes(filters.role)) {
      return false;
    }

    if (
      filters.companies.length > 0 &&
      !filters.companies.some((company) => member.clientsAccess.some((client) => client.clientCode === company.value))
    ) {
      return false;
    }

    return (
      member.email.toLowerCase().includes(searchTerm) ||
      member.name.toLowerCase().includes(searchTerm) ||
      member.clientsAccess.some((client) => client.clientName.toLowerCase().includes(searchTerm)) ||
      filterOrganizationRoles(member.organizationPermissions.roles).some((role) =>
        organizationRolesDataMap[role].label.toLowerCase().includes(searchTerm)
      )
    );
  });
};

export const getFilteredDeactivatedMembers = (
  deactivatedMembers: OrganizationDeactivatedMember[],
  filters: MemberFilters
): OrganizationDeactivatedMember[] => {
  return deactivatedMembers.filter((member) => {
    const searchTerm = filters.search.trim().toLowerCase();
    return member.name.toLowerCase().includes(searchTerm) || member.email.toLowerCase().includes(searchTerm);
  });
};

export const getFilteredPendingInvites = (
  pendingInvites: OrganizationMemberInvitation[],
  filters: MemberFilters
): OrganizationMemberInvitation[] => {
  return pendingInvites.filter((invite) => {
    const searchTerm = filters.search.trim().toLowerCase();

    if (filters.types.length > 0) {
      if (!filters.types.includes("External") && invite.isGuest) {
        return false;
      }
      if (!filters.types.includes("Internal") && !invite.isGuest) {
        return false;
      }
    }

    if (filters.role) {
      if (invite.organizationRole !== filters.role) {
        return false;
      }
    }

    return (
      invite.email.toLowerCase().includes(searchTerm) ||
      filterOrganizationRoles([invite.organizationRole]).some((role) =>
        organizationRolesDataMap[role].label.toLowerCase().includes(searchTerm)
      )
    );
  });
};
