import HomeIcon from "@mui/icons-material/HomeOutlined";
import { ClientType } from "../../../../shared/api/clientTypes";
import ClientLogo from "../../pages/home/ClientLogo";

interface ClientIconProps {
  clientType: ClientType;
  logoUrl?: string;
  color?: string;
}

const ClientIcon = ({ clientType, logoUrl, color }: ClientIconProps) => {
  if (clientType === "FundAdmin") {
    return (
      <ClientLogo size="small" sx={{ bgcolor: "transparent" }}>
        <HomeIcon htmlColor={color} />
      </ClientLogo>
    );
  }

  return <ClientLogo size="small" logoSrc={logoUrl} sx={{ bgcolor: color }} />;
};

export default ClientIcon;
