import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { Button, TextField, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import RequestExamples from "./RequestExamples";

interface Props {
  disabled: boolean;
  showRequestExamplesTrigger: string;
  onSubmit: (prompt: string) => void;
}

const InputPrompt = ({ disabled, showRequestExamplesTrigger, onSubmit }: Props) => {
  const [prompt, setPrompt] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showExamples, setShowExamples] = useState(false);

  const isPromptDisabled = disabled || prompt.trim() === "";

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !event.shiftKey && !isPromptDisabled) {
      event.preventDefault();
      onSubmit(prompt.trim());
      setPrompt("");
    }
  };

  useEffect(() => {
    if (showRequestExamplesTrigger !== "") {
      setShowExamples(true);
    }
  }, [showRequestExamplesTrigger]);

  return (
    <>
      <TextField
        ref={setAnchorEl}
        placeholder="Type your request..."
        maxRows={4}
        multiline
        data-gramm="false"
        spellCheck={false}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={(theme) => ({
          width: "100%",
          m: 0,
          bgcolor: theme.palette.background.paper,
          borderRadius: theme.spacing(0.5),
          boxShadow: "0px 4px 4px 0px rgba(155, 158, 171, 0.25)",
          fieldset: {
            borderColor: theme.palette.divider,
            "&:hover": {
              borderColor: "red",
            },
          },
          ".MuiOutlinedInput-root": {
            pr: 0,
          },
        })}
        InputProps={{
          endAdornment: (
            <>
              <Tooltip title="Examples of requests">
                <Button
                  variant="text"
                  color="secondary"
                  sx={(t) => ({
                    height: t.spacing(4),
                    width: t.spacing(4),
                    minWidth: "unset",
                    mr: 1,
                    "&:hover": {
                      color: t.palette.primary.main,
                    },
                  })}
                  onClick={() => setShowExamples((oldValue) => !oldValue)}
                >
                  <LightModeRoundedIcon />
                </Button>
              </Tooltip>
              <Button
                disabled={isPromptDisabled}
                variant="contained"
                sx={(t) => ({
                  height: t.spacing(4),
                  width: t.spacing(4),
                  minWidth: "unset",
                  mr: 2,
                  ":disabled": {
                    bgcolor: grey[50],
                  },
                })}
                onClick={() => {
                  onSubmit(prompt);
                  setPrompt("");
                }}
              >
                <SendRoundedIcon
                  sx={(t) => ({ color: isPromptDisabled ? t.palette.text.disabled : t.palette.common.white })}
                />
              </Button>
            </>
          ),
        }}
      />
      {showExamples && (
        <RequestExamples anchorEl={anchorEl} setPrompt={setPrompt} onClose={() => setShowExamples(false)} />
      )}
    </>
  );
};

export default InputPrompt;
