import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { IconButton, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import FileIcon from "../../../../../shared/components/FileIcon";
import FundraisingStatusTag from "../../../../../shared/components/FundraisingStatusTag";
import InlineItemsList from "../../../../../shared/components/InlineItemsList";
import StaticCircularProgress from "../../../../../shared/components/StaticCircularProgress";
import { distinctBy } from "../../../../../shared/utilities/arrayHelper";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { getFileExtension } from "../../../../../shared/utilities/fileHelper";
import { formatDurationInSeconds } from "../../../../../shared/utilities/formatters";
import { DocumentFundInvestor, FundraisingDocument } from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import { useClientContext } from "../../../../context/ClientContext";
import AnalyticsIcon from "../../../../icons/AnalyticsIcon";
import CategoryTag from "../../../common/CategoryTag";
import TextHoverAction from "../../../common/TextHoverAction";

interface ActionsCellProps {
  row: FundraisingDocument;
  onNavigateToDocumentActivity?: (row: FundraisingDocument) => void;
  onDownloadClick: (row: FundraisingDocument) => void;
}

const ActionsCell = ({ row, onNavigateToDocumentActivity, onDownloadClick }: ActionsCellProps) => {
  const { hasPermissions } = useClientContext();

  const hasAccessToDocumentActivity = hasPermissions(["ViewInvestorPortalDocumentViewsAnalytics"]);

  return (
    <Stack direction="row" spacing={0.5} width="100%" justifyContent="flex-end">
      {hasAccessToDocumentActivity && onNavigateToDocumentActivity && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onNavigateToDocumentActivity(row);
          }}
        >
          <AnalyticsIcon />
        </IconButton>
      )}
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onDownloadClick(row);
        }}
      >
        <DownloadIcon />
      </IconButton>
    </Stack>
  );
};

export default ActionsCell;

export const getColumnDefinitions = (
  excludeColumns: string[],
  categories: Category[],
  onDocumentPreview: (doc: FundraisingDocument) => void,
  onDocumentDownload: (doc: FundraisingDocument) => void,
  onNavigateToFundraising: (doc: FundraisingDocument) => void,
  onNavigateToDocumentActivity?: (doc: FundraisingDocument) => void
): GridColDef<FundraisingDocument>[] => {
  const columnDefs: GridColDef<FundraisingDocument>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 160,
      cellClassName: "highlighted-action",
      renderCell: ({ row }) => (
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <FileIcon fileExtension={getFileExtension(row.name)} />
          <TextHoverAction onClick={() => onDocumentPreview(row)}>{row.name}</TextHoverAction>
        </Stack>
      ),
    },
    {
      field: "fundInvestors.investorName",
      headerName: "Investor",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) =>
        row.fundInvestors.length === 0 ? (
          "-"
        ) : (
          <InlineItemsList<DocumentFundInvestor>
            displayCount={1}
            items={distinctBy(row.fundInvestors, (fi) => fi.investorId)}
            propGetter={(prop) => prop.investorName}
            justify="flex-start"
          />
        ),
    },
    {
      field: "fundInvestors.fundName",
      headerName: "Fund",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) =>
        row.fundInvestors.length === 0 ? (
          "-"
        ) : (
          <InlineItemsList<DocumentFundInvestor>
            displayCount={1}
            items={distinctBy(row.fundInvestors, (fi) => fi.fundId)}
            propGetter={(prop) => prop.fundName}
            justify="flex-start"
          />
        ),
    },
    {
      field: "categoryId",
      headerName: "Category",
      width: 250,
      renderCell: ({ row }) => {
        const category = categories.find((c) => c.externalId === row.categoryId);
        return category ? <CategoryTag categoryName={category.name} /> : null;
      },
    },
    {
      field: "fundraisingName",
      headerName: "Fundraising",
      flex: 1,
      minWidth: 160,
      cellClassName: "highlighted-action",
      renderCell: ({ row }) => (
        <TextHoverAction onClick={() => onNavigateToFundraising(row)}>{row.fundraisingName}</TextHoverAction>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: ({ row }) => <FundraisingStatusTag status={row.status} />,
    },
    {
      field: "sentAt",
      headerName: "Published Live",
      width: 160,
      valueFormatter: (value: string) => convertISODateTime(value),
    },
    {
      field: "views",
      headerName: "Views",
      type: "number",
      width: 100,
      valueGetter: (_, row) => row.views?.totalViews || null,
    },
    {
      field: "downloads",
      headerName: "Downloads",
      type: "number",
      width: 100,
      valueGetter: (_, row) => row.views?.totalDownloads || null,
    },
    {
      field: "totalViewTimeSeconds",
      headerName: "Total Time Spent",
      headerAlign: "right",
      align: "right",
      width: 140,
      valueGetter: (_, row) => {
        const totalViewTimeSeconds = row.views?.totalViewTimeSeconds;
        return totalViewTimeSeconds ? formatDurationInSeconds(totalViewTimeSeconds) : null;
      },
    },
    {
      field: "totalCompletionPercentage",
      headerName: "Total Completion",
      type: "number",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        const completionPercentage = row.views?.totalCompletionPercentage;
        if (!completionPercentage) {
          return null;
        }

        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography>{completionPercentage + "%"}</Typography>
            <StaticCircularProgress size={20} value={completionPercentage} />
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 90,
      cellClassName: "grid-row-actions",
      align: "right",
      renderCell: ({ row }) => (
        <ActionsCell
          row={row}
          onNavigateToDocumentActivity={onNavigateToDocumentActivity}
          onDownloadClick={onDocumentDownload}
        />
      ),
    },
  ];

  return columnDefs.filter((c) => !excludeColumns.includes(c.field));
};
