import { Link, Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  value: string;
  link?: boolean;
  icon?: React.ReactNode;
}

const ensureAbsoluteUrl = (url: string) => (url.startsWith("https://") ? url : `https://${url}`);

const KeyValueColumn = ({ title, value, link, icon }: Props) => (
  <Stack spacing={0.5}>
    <Typography variant="caption" color="text.secondary" gutterBottom>
      {title}
    </Typography>
    <Stack direction={"row"} spacing={0.5}>
      {icon}
      {link ? (
        <Link href={ensureAbsoluteUrl(value)} target="_blank" rel="noopener noreferrer" underline="none">
          {value}
        </Link>
      ) : (
        <Typography>{value}</Typography>
      )}
    </Stack>
  </Stack>
);

export default KeyValueColumn;
