import { Avatar, Checkbox, Paper, Stack, Typography } from "@mui/material";
import { makeLighterBackgroundFromColor } from "../../../../../../shared/utilities/colorHelper";
import { DataImportTarget } from "../../../../../api/types/dataImportTypes";
import { ImportCsvTargetOption } from "../importCsvDataPageState";
import ImportCsvTargetIcon from "./ImportCsvTargetIcon";

interface Props {
  targetOption: ImportCsvTargetOption;
  onToggleSelected: (newSelected: boolean) => void;
}

const dataImportTargetDescriptions: Record<DataImportTarget, string> = {
  PlatformObjects: "Create and update platform objects",
  PortfolioMonitoringMetrics: "Create and update metrics for Portfolio Monitoring",
  PortfolioMonitoringMetricValues: "Create metric values for Portfolio Monitoring",
};

const ImportCsvTargetCard = ({ targetOption, onToggleSelected }: Props) => {
  return (
    <Paper variant="outlined" sx={{ p: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Avatar
            variant="rounded"
            sx={(t) => ({
              width: 32,
              height: 32,
              color: targetOption.selected ? t.palette.primary.main : t.palette.secondary.main,
              bgcolor: makeLighterBackgroundFromColor(
                targetOption.selected ? t.palette.primary.main : t.palette.secondary.main
              ),
            })}
            color={targetOption.selected ? "primary" : "secondary"}
          >
            <ImportCsvTargetIcon target={targetOption.target} />
          </Avatar>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">{targetOption.label}</Typography>
            <Typography color="text.secondary">{dataImportTargetDescriptions[targetOption.target]}</Typography>
          </Stack>
        </Stack>
        <Checkbox checked={targetOption.selected} onChange={(_, checked) => onToggleSelected(checked)} />
      </Stack>
    </Paper>
  );
};

export default ImportCsvTargetCard;
