import { Box, Card, CardMedia, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  src: string;
  title: string;
  onClose: () => void;
}

const PreviewImageDialog = ({ src, title, onClose }: Props) => (
  <Dialog
    open={Boolean(src)}
    onClose={onClose}
    maxWidth="lg"
    PaperProps={{ sx: { minWidth: "20rem", minHeight: "20rem" } }}
  >
    <DialogTitle sx={{ px: 2, py: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
    <DialogContent sx={{ p: 0 }}>
      <Box display="flex" flexDirection="column" height="100%">
        <Card sx={{ flexGrow: 1 }} variant="outlined">
          <CardMedia
            component="img"
            src={src}
            sx={{ objectFit: "contain", border: 0, height: "100%", width: "100%" }}
          />
        </Card>
      </Box>
    </DialogContent>
  </Dialog>
);

export default PreviewImageDialog;
