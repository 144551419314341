import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";

interface ReportTemplateContextType {
  deleteTemplates: (ids: string[]) => void;
}
const ReportTemplateUiManagerContext = createContext<ReportTemplateContextType | undefined>(undefined);

export const ReportTemplateUiManagerContextProvider = ({
  children,
  ...props
}: PropsWithChildren<ReportTemplateContextType>) => {
  return <ReportTemplateUiManagerContext.Provider value={props}>{children}</ReportTemplateUiManagerContext.Provider>;
};

export const useReportTemplateUiManagerContext = () => {
  const context = useContext(ReportTemplateUiManagerContext);
  return defined(context);
};
