import { SxProps, TextField, Theme } from "@mui/material";
import { useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import {
  ValidationResult,
  combineValidators,
  emailValidator,
  requiredValidator,
  validResult,
} from "../../utilities/validators";

interface EmailTextFieldProps {
  fieldName?: string;
  value?: string;
  sxProps?: SxProps<Theme> | undefined;
  onBlur?: (value: string, isValid: boolean) => void;
  onValueChanged?: (value: string) => void;
  required?: boolean;
  validateOnChange?: boolean;
  disabled?: boolean;
}

const EmailTextField = ({
  sxProps,
  value,
  fieldName,
  onValueChanged,
  onBlur,
  required,
  validateOnChange,
  disabled,
}: EmailTextFieldProps) => {
  const [email, setEmail] = useState(value);
  const [validationResult, setValidationResult] = useState<ValidationResult>(validResult());

  const validate = required ? combineValidators(requiredValidator, emailValidator) : emailValidator;

  const onBlurValue = (value: string) => {
    if (onBlur) {
      const validationResult = validate(value);
      setValidationResult(validationResult);
      onBlur(value, validationResult.isValid);
    }
  };

  const handleChangeDebounced = useDebounce((value: string) => {
    if (validateOnChange) {
      setValidationResult(validate(value));
    }

    setEmail(value);

    if (onValueChanged) {
      onValueChanged(value);
    }
  }, 200);

  return (
    <TextField
      disabled={disabled}
      label={fieldName}
      sx={{ ...sxProps }}
      defaultValue={email}
      onChange={({ target }) => handleChangeDebounced(target.value)}
      onBlur={({ target }) => onBlurValue(target.value)}
      error={!validationResult.isValid}
      helperText={validationResult.error}
    />
  );
};

export default EmailTextField;
