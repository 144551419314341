import { Stack, Typography } from "@mui/material";

interface Props {
  snippets: EmailSnippet[];
  onSelected: (value: string) => void;
}

export interface EmailSnippet {
  value: string;
  displayName: string;
}

const EmailSnippets = ({ snippets, onSelected }: Props) => {
  const handleClick = (value: string) => () => onSelected(value);

  return (
    <Stack spacing={2} p={2}>
      {snippets.map(({ value, displayName }) => (
        <Stack key={value} direction="row" onClick={handleClick(value)} sx={{ cursor: "pointer" }}>
          <Typography fontWeight={700} width="10rem">
            {value}
          </Typography>
          <Typography width="10rem">{displayName}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
export default EmailSnippets;
