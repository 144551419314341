import { Box, Checkbox } from "@mui/material";
import { logError } from "../../../../shared/logging";
import { validResult } from "../../../../shared/utilities/validators";
import { EntityFieldComponentProps, EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError } from "../helpers";
import EntityFieldTypeWrapper from "./EntityFieldTypeWrapper";

const EntityCheckboxField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "boolean" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityCheckboxField]");
    return null;
  }

  return (
    <EntityFieldTypeWrapper
      value={value}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={onChange}
      ComponentToRender={EntityCheckboxFieldComponent}
    />
  );
};

const EntityCheckboxFieldComponent = ({
  value,
  onChange,
  fieldId,
  fieldAttributes,
}: EntityFieldComponentProps<boolean>) => {
  return (
    <Box>
      <Checkbox
        disabled={fieldAttributes.includes("ReadOnly")}
        sx={{ px: 1, py: 1 }}
        checked={!!value}
        onChange={() => onChange(!value, fieldId, validResult())}
      />
    </Box>
  );
};

export default EntityCheckboxField;
