import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import RenameIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Button, Stack, Typography } from "@mui/material";
import FundraisingStatusTag from "../../../../../../shared/components/FundraisingStatusTag";
import ActionsMenuButton from "../../../../common/ActionsMenuButton";
import GeneralPageHeader from "../../../../common/GeneralPageHeader";
import InvestorPortalImpersonateButton from "../../InvestorPortalImpersonateButton";
import { getAvailableStatusTransitions } from "../fundraisingStatusHelper";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const PageTitle = () => {
  const { fundraising } = useFundraisingDetailsPageContext();

  return (
    <Stack spacing={0.5} py={1.5}>
      <Stack direction="row" spacing={1}>
        <Typography variant="h6">{fundraising.name}</Typography>
        <FundraisingStatusTag status={fundraising.status} />
      </Stack>
      <Typography variant="caption" color="text.secondary">
        Fundraising
      </Typography>
    </Stack>
  );
};

const FundraisingDetailsPageHeader = () => {
  const { fundraising, hasEditPermissions, onStatusChange, onRename, onDelete } = useFundraisingDetailsPageContext();

  return (
    <GeneralPageHeader hideBorderLine showDefaultBackButtonTo=".." TitleComponent={<PageTitle />}>
      {["Live", "Preview"].includes(fundraising.status) && (
        <InvestorPortalImpersonateButton
          variant="outlined"
          color="secondary"
          portalUrlSubPath="/fundraising"
          fundraisingId={fundraising.id}
        />
      )}

      {hasEditPermissions && (
        <>
          {getAvailableStatusTransitions(fundraising.status).map((transition) => (
            <Button
              key={`${transition.from}-${transition.to}`}
              variant="outlined"
              disabled={(transition.to === "Preview" || transition.to === "Live") && !fundraising.isReadyForPublishing}
              startIcon={<transition.Icon />}
              onClick={() => onStatusChange(transition)}
            >
              {transition.label}
            </Button>
          ))}
          <ActionsMenuButton
            items={[
              {
                label: "Rename",
                icon: <RenameIcon />,
                onClick: () => onRename(),
              },
              {
                label: "Delete",
                icon: <DeleteIcon color="error" />,
                onClick: () => onDelete(),
                disabled: fundraising.status === "Live",
              },
            ]}
          />
        </>
      )}
    </GeneralPageHeader>
  );
};

export default FundraisingDetailsPageHeader;
