import { Menu, MenuItem } from "@mui/material";
import { allMetricDataTypes, MetricDataType } from "../../../../api/types/portfolioMonitoringTypes";
import MetricDataTypeItem from "../MetricDataTypeItem";

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  onDataTypeSelect: (dataType: MetricDataType) => void;
  onClose: () => void;
}

const MetricDataTypeMenu = ({ anchorEl, open, onDataTypeSelect, onClose }: Props) => {
  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      slotProps={{ paper: { sx: { width: "14rem" } } }}
    >
      {allMetricDataTypes.map((dataType) => (
        <MenuItem key={dataType} onClick={() => onDataTypeSelect(dataType)}>
          <MetricDataTypeItem dataType={dataType} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default MetricDataTypeMenu;
