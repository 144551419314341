import { Select } from "@mui/material";
import { blue, green } from "@mui/material/colors";
import MenuItemWithoutHighlight from "../../../../../shared/components/MenuItemWithoutHighlight";
import StatusChip from "../../../../../shared/components/StatusChip";
import { ToggleValue } from "./state";

interface Props {
  value: ToggleValue;
  onChange: (newValue: ToggleValue) => void;
  disabled?: boolean;
}

const PortalPageStatusSelect = ({ value, onChange, disabled }: Props) => {
  return (
    <Select
      variant="standard"
      disableUnderline
      value={value}
      onChange={(e) => onChange(e.target.value as ToggleValue)}
      disabled={disabled}
      sx={{
        ".MuiInputBase-input": {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      <MenuItemWithoutHighlight value="off">
        <StatusChip label="Off" withDot color={(t) => t.palette.text.secondary} />
      </MenuItemWithoutHighlight>
      <MenuItemWithoutHighlight value="preview">
        <StatusChip label="Preview" withDot color={blue[700]} />
      </MenuItemWithoutHighlight>
      <MenuItemWithoutHighlight value="live">
        <StatusChip label="Live" withDot color={green[700]} />
      </MenuItemWithoutHighlight>
    </Select>
  );
};

export default PortalPageStatusSelect;
