import { Stack, Typography } from "@mui/material";
import UserAvatar from "../../../shared/components/UserAvatar";

interface PersonMainDetailsProps {
  name: string;
  email: string;
  doNotShowAvatar?: boolean;
  spacing?: number;
}

const PersonMainDetails = ({ name, email, doNotShowAvatar, spacing }: PersonMainDetailsProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      {!doNotShowAvatar && <UserAvatar size="medium" userName={name} />}
      <Stack spacing={spacing || 0.5}>
        <Typography variant="subtitle2">{name}</Typography>
        <Typography variant="caption">{email}</Typography>
      </Stack>
    </Stack>
  );
};

export default PersonMainDetails;
