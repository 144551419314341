import { Grid, Stack, Typography } from "@mui/material";

interface Props {
  title: string;
  attributes: [string, string][];
}

const FileAttributesSection = ({ title, attributes }: Props) => (
  <Stack spacing={2}>
    <Typography variant="subtitle2">{title}</Typography>
    {attributes.length === 0 ? (
      <Typography color="text.secondary">No properties yet</Typography>
    ) : (
      attributes
        .filter(([, value]) => Boolean(value))
        .map(([attribute, value]) => (
          <Grid container key={attribute} spacing={1}>
            <Grid item xs={4}>
              <Typography color="text.secondary">{attribute}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{value}</Typography>
            </Grid>
          </Grid>
        ))
    )}
  </Stack>
);

export default FileAttributesSection;
