import { Container, Stack } from "@mui/material";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { useClientContext } from "../../../context/ClientContext";
import SnowflakeLogo from "../../../images/snowflake.png";
import IntegrationHeader from "../integration/IntegrationHeader";
import DwhConnectorTabs from "./DwhConnectorTabs";
import ResyncSection from "./ResyncSection";

const promotionText =
  "A single, global platform that powers the Data Cloud. Snowflake is uniquely designed to connect businesses globally, across any type or scale of data and many different workloads, and unlock seamless data collaboration.";

const SnowflakeConnectorPage = () => {
  const { reportingPortalApp } = useClientContext();

  const [lastSyncResp, lastSyncFetchError] = useFetch(adminApi.getDwhLastSync);

  if (lastSyncFetchError) {
    logError(lastSyncFetchError.message || "getDwhLastSync failed", "SnowflakeConnectorPage");
    return <DataLoadingFailed title="Loading DWH sync info failed" />;
  }

  if (lastSyncResp === undefined) {
    return <InlineLoader />;
  }

  return (
    <>
      <IntegrationHeader
        title="Snowflake"
        logoSrc={SnowflakeLogo}
        text={promotionText}
        appStatus={reportingPortalApp?.status}
        maxWidth="lg"
      />
      <Container maxWidth="lg" disableGutters sx={{ mx: 0 }}>
        <Stack px={3} spacing={3} mt={3}>
          <ResyncSection lastSyncTime={lastSyncResp.endTime} />
          <DwhConnectorTabs />
        </Stack>
      </Container>
    </>
  );
};

export default SnowflakeConnectorPage;
