import CloseIcon from "@mui/icons-material/CancelRounded";
import { Button, Popover, Typography } from "@mui/material";
import { MouseEventHandler, useRef, useState } from "react";
import { useFilterContext } from "./FilterContext";
import FilterTypeIcon from "./FilterTypeIcon";
import FilterEditor from "./editors/FilterEditor";
import { TableFilter } from "./filterTypes";
import { formatFilterValue, isFilterActive } from "./handlers/filterHandlers";

interface Props {
  filter: TableFilter;
  disabled?: boolean;
}

const FilterButton = ({ filter, disabled }: Props) => {
  const { dispatchFilters } = useFilterContext();
  const [editing, setEditing] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleRemoveClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    dispatchFilters({ type: "remove_filter", filterId: filter.id });
  };

  const activeFilter = isFilterActive(filter);

  return (
    <>
      <Button
        variant="outlined"
        color={activeFilter ? "primary" : "secondary"}
        ref={buttonRef}
        disabled={disabled}
        onClick={() => setEditing(!editing)}
        startIcon={<FilterTypeIcon filterType={filter.type} />}
        sx={{ maxWidth: "21rem" }}
      >
        <Typography noWrap>{formatFilterValue(filter)}</Typography>
        <CloseIcon
          color={activeFilter ? "primary" : "secondary"}
          onClick={handleRemoveClick}
          sx={(t) => ({
            ml: 0.5,
            width: 16,
            height: 16,
            color: activeFilter ? t.palette.primary.light : t.palette.secondary.light,
            "&:hover": { color: activeFilter ? t.palette.primary.main : t.palette.secondary.main },
          })}
        />
      </Button>
      <Popover
        open={editing}
        onClose={() => setEditing(false)}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <FilterEditor filter={filter} />
      </Popover>
    </>
  );
};

export default FilterButton;
