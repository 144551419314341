import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, TextField, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import HtmlEditor from "../../../../../../shared/components/htmlEditor/HtmlEditor";
import HtmlPreview from "../../../../../../shared/components/htmlEditor/HtmlPreview";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { areSameStrings } from "../../../../../../shared/utilities/stringHelper";
import adminApi from "../../../../../api/adminApi";
import { Fundraising } from "../../../../../api/types/fundraisingTypes";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const updateFundraising = withErrorHandling(adminApi.updateFundraising);

const htmlEditorToolbar = [
  [{ header: [3, 4, 5, false] }],
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  ["clean"],
];

interface ContentState {
  title: string;
  htmlContent: string;
  initialHtmlContent: string;
  isHtmlContentEdited: boolean;
  isSaving: boolean;
  htmlEditorRenderKey: number;
}

const getInitialContentState = (fundraising: Fundraising) => ({
  title: fundraising.content?.title ?? "",
  htmlContent: fundraising.content?.htmlContent ?? "",
  initialHtmlContent: fundraising.content?.htmlContent ?? "",
  isHtmlContentEdited: false,
  isSaving: false,
  htmlEditorRenderKey: 1,
});

const FundraisingContentConfig = () => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { isContentEditable, fundraising, onUpdated, onConfirmSave } = useFundraisingDetailsPageContext();

  const [state, setState] = useState<ContentState>(getInitialContentState(fundraising));

  const save = async () => {
    setState((prev) => ({ ...prev, isSaving: true }));
    const { title, htmlContent } = state;
    const [updatedFundraising, error] = await updateFundraising(fundraising.id, { content: { title, htmlContent } });
    setState((prev) => ({ ...prev, isSaving: false }));

    if (error) {
      logError(error, "[FundraisingContentConfig]");
      sendNotificationError("Could not update fundraising");
    } else {
      setState((prev) => ({ ...prev, isHtmlContentEdited: false }));
      sendNotification(`Fundraising content updated successfully`);
      onUpdated(updatedFundraising);
    }
  };

  const handleSave = () => onConfirmSave(save);

  const handleReset = () => {
    setState({ ...getInitialContentState(fundraising), htmlEditorRenderKey: -state.htmlEditorRenderKey });
  };

  const handleHtmlContentChange = (value: string, source: "user" | "api" | "silent") => {
    setState((prev) => ({
      ...prev,
      htmlContent: value,
      isHtmlContentEdited: prev.isHtmlContentEdited || source === "user",
    }));
  };

  const isEdited = !areSameStrings(state.title, fundraising.content?.title) || state.isHtmlContentEdited;
  const isValid = fundraising.status === "Draft" || Boolean(state.title.trim());
  const showValidationError = isEdited && !isValid;

  if (!isContentEditable) {
    return (
      <Stack spacing={4} flex={1} pt={1.5}>
        <Typography variant="h2">{state.title}</Typography>
        <HtmlPreview htmlContent={fundraising.content?.htmlContent ?? ""} />
      </Stack>
    );
  }

  return (
    <Stack spacing={4} flex={1} pt={4}>
      <TextField
        fullWidth
        placeholder="Add title..."
        value={state.title}
        onChange={(e) => setState((prev) => ({ ...prev, title: e.target.value }))}
        inputProps={{ sx: (t: Theme) => ({ ...t.typography.h2 }) }}
        error={showValidationError}
        helperText={showValidationError ? "Title is required" : undefined}
      />
      <Box>
        <HtmlEditor
          key={state.htmlEditorRenderKey}
          initialHtmlContent={state.initialHtmlContent}
          onChange={handleHtmlContentChange}
          styles={{ editor: { minHeight: "12rem", maxHeight: "24rem" } }}
          toolbar={htmlEditorToolbar}
        />
      </Box>
      <Stack direction="row" spacing={1}>
        <LoadingButton
          variant="contained"
          loading={state.isSaving}
          onClick={handleSave}
          disabled={!isEdited || !isValid}
        >
          Save
        </LoadingButton>
        <Button variant="text" color="secondary" onClick={handleReset} disabled={!isEdited}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export default FundraisingContentConfig;
