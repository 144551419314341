import { useMemo } from "react";
import { useUserContext } from "../../../../../context/UserContext";

export function useCopyToEligibleCompanies() {
  const { clients, permissions } = useUserContext();

  const eligibleCompanies = useMemo(() => {
    return clients.filter((c) => {
      if (c.type !== "FundManager") {
        return false;
      }
      const permissionsForClient = permissions.find((p) => p.clientCode === c.clientCode);
      return permissionsForClient?.permissions.includes("ManageReports") === true;
    });
  }, [clients, permissions]);

  return eligibleCompanies;
}
