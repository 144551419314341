import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Menu } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import ActionMenuItem from "../../../../../../shared/components/ActionMenuItem";
import AuthorizedBox from "../../../../../../shared/components/AuthorizedBox";
import ReportAuthorizedBox from "../../../../../../shared/components/ReportAuthorizedBox";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import DuplicateReportIcon from "../../../../../../shared/icons/DuplicateReportIcon";
import EditReportIcon from "../../../../../../shared/icons/EditReportIcon";
import RenameReportIcon from "../../../../../../shared/icons/RenameReportIcon";
import { ReportAccessType, ReportInfo, ReportType } from "../../../../../../shared/reporting/api/biClient.types";
import ChangeReportGroup from "../../../../../../shared/reporting/components/reports/ChangeReportGroup";
import RenameReport from "../../../../../../shared/reporting/components/reports/RenameReport";
import { useLocalization } from "../../../../../hooks/useLocalization";
import ActionMenuItemLink from "../ActionMenuItemLink";
import { CopyToMenuItem } from "../copyto/CopyToMenuItem";
import { useUiReportsContext } from "./contexts/ReportsContext";
import { useUiReportsManagerContext } from "./contexts/ReportsUiManagerContext";

interface Props {
  anchorEl: HTMLElement | null;
  report: ReportInfo;
  open: boolean;
  onRun: (reportId: string) => string;
  onEdit: (reportId: string) => string;
  onClose: () => void;
}

export default function ReportActions(props: Props) {
  const { anchorEl, report, open, onRun, onEdit, onClose } = props;

  const { sendNotification } = useNotificationContext();
  const { actions } = useUiReportsContext();
  const { copyReports, deleteReports, duplicateReport, shareReport, generateApiReport } = useUiReportsManagerContext();
  const { report: locale } = useLocalization();

  const [openRenameDialog, setOpenRenameDialog] = useState<boolean>();
  const [openChangeLevelDialog, setOpenChangeLevelDialog] = useState<boolean>();

  const getRunReportHref = useMemo(() => {
    return onRun(report.reportId);
  }, [report.reportId, onRun]);

  const getEditReportHref = useMemo(() => {
    return onEdit(report.reportId);
  }, [report.reportId, onEdit]);

  const handleDeleteReport = useCallback(() => {
    onClose();
    deleteReports([report.reportId]);
  }, [deleteReports, onClose, report.reportId]);

  const handleGenerateApiReport = useCallback(() => {
    onClose();
    generateApiReport(report);
  }, [generateApiReport, onClose, report]);

  const handleRenameReport = useCallback(() => {
    onClose();
    setOpenRenameDialog(true);
  }, [onClose]);

  const handleChangeLevelReport = useCallback(() => {
    onClose();
    setOpenChangeLevelDialog(true);
  }, [onClose]);

  const handleCopyToReport = useCallback(() => {
    onClose();
    copyReports([report.reportId]);
  }, [copyReports, onClose, report.reportId]);

  const handleDuplicateReport = useCallback(() => {
    onClose();
    duplicateReport(report);
  }, [duplicateReport, onClose, report]);

  const handleShareReport = useCallback(() => {
    onClose();
    shareReport(report);
  }, [shareReport, onClose, report]);

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} slotProps={{ paper: { sx: { width: "200px" } } }}>
        <ActionMenuItemLink
          key={"run"}
          icon={<PlayArrowRoundedIcon />}
          title="Run Report"
          href={getRunReportHref}
          onClick={onClose}
        />
        <ReportAuthorizedBox
          clientCode={report.clientCode}
          permissions={["ManageReports"]}
          requiredAccess={ReportAccessType.Write}
          access={report.authorization.access}
        >
          <ActionMenuItemLink
            key={"edit"}
            icon={<EditReportIcon />}
            title="Edit Report"
            href={getEditReportHref}
            onClick={onClose}
          />
          <ActionMenuItem key={"rename"} icon={<RenameReportIcon />} title="Rename" onClick={handleRenameReport} />
          <ActionMenuItem
            key={"changeLevel"}
            icon={<DriveFileMoveOutlinedIcon />}
            title="Change Group"
            onClick={handleChangeLevelReport}
          />
        </ReportAuthorizedBox>
        <AuthorizedBox clientCode={report.clientCode} permissions={["ManageReports"]}>
          <CopyToMenuItem onClick={handleCopyToReport} />
          <ActionMenuItem
            key={"duplicate"}
            icon={<DuplicateReportIcon />}
            title="Duplicate"
            onClick={handleDuplicateReport}
          />
        </AuthorizedBox>
        <ReportAuthorizedBox
          clientCode={report.clientCode}
          access={report.authorization.access}
          permissions={["ShareReports"]}
          requiredAccess={ReportAccessType.FullAccess}
        >
          <ActionMenuItem key={"share"} icon={<ShareOutlinedIcon />} title="Share" onClick={handleShareReport} />
        </ReportAuthorizedBox>
        {report.reportUsage === "Ui" && report.reportType === ReportType.Tabular && (
          <ReportAuthorizedBox
            clientCode={report.clientCode}
            permissions={["ManageReports"]}
            access={report.authorization.access}
            requiredAccess={ReportAccessType.Write}
          >
            <ActionMenuItem
              key={"generateApi"}
              icon={<CodeRoundedIcon />}
              title="Generate API Report"
              onClick={handleGenerateApiReport}
            />
          </ReportAuthorizedBox>
        )}
        <ReportAuthorizedBox
          clientCode={report.clientCode}
          permissions={["ManageReports"]}
          access={report.authorization.access}
          requiredAccess={ReportAccessType.Owner}
        >
          <ActionMenuItem
            key={"delete"}
            icon={<DeleteOutlineOutlinedIcon />}
            title="Delete"
            sx={(theme) => ({ color: theme.palette.error.main, svg: { color: theme.palette.error.main } })}
            onClick={handleDeleteReport}
          />
        </ReportAuthorizedBox>
      </Menu>
      {openRenameDialog && report && (
        <RenameReport
          report={report}
          renameReport={actions.renameReport}
          onChanged={() => sendNotification(locale.renamed)}
          onClose={() => setOpenRenameDialog(false)}
        />
      )}
      {openChangeLevelDialog && report && (
        <ChangeReportGroup
          report={report}
          changeGroup={actions.changeReportGroup}
          onChanged={() => sendNotification(locale.level_changed)}
          onClose={() => setOpenChangeLevelDialog(false)}
        />
      )}
    </>
  );
}
