import { TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import RoundedTable from "../../../../../shared/components/RoundedTable";
import { Field } from "../../../../api/types/objectTypes";
import FieldSourceBadge from "../../../entityFields/FieldSourceBadge";
import ObjectFieldName from "../object-fields/ObjectFieldName";

interface Props {
  primaryDisplayFieldId: string;
  secondaryDisplayFieldId?: string;
  allFields: Field[];
}

const DisplayFieldsSection = ({ primaryDisplayFieldId, secondaryDisplayFieldId, allFields }: Props) => {
  const primaryDisplayField = allFields.find((field) => field.id === primaryDisplayFieldId);
  const secondaryDisplayField = allFields.find((field) => field.id === secondaryDisplayFieldId);

  if (!primaryDisplayField && !secondaryDisplayField) {
    return <Typography>No display fields defined.</Typography>;
  }

  return (
    <RoundedTable>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Field Name</TableCell>
          <TableCell>Source</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {primaryDisplayField && (
          <TableRow key={primaryDisplayField.id}>
            <TableCell>
              <Typography variant="subtitle2">Primary</Typography>
            </TableCell>
            <TableCell>
              <ObjectFieldName field={primaryDisplayField} />
            </TableCell>
            <TableCell>
              <FieldSourceBadge fieldSource={primaryDisplayField.source} />
            </TableCell>
          </TableRow>
        )}
        {secondaryDisplayField && (
          <TableRow key={secondaryDisplayField.id}>
            <TableCell>
              <Typography variant="subtitle2">Secondary</Typography>
            </TableCell>
            <TableCell>
              <ObjectFieldName field={secondaryDisplayField} />
            </TableCell>
            <TableCell>
              <FieldSourceBadge fieldSource={secondaryDisplayField.source} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </RoundedTable>
  );
};

export default DisplayFieldsSection;
