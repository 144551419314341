import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import InlineItemsList from "../../../../../shared/components/InlineItemsList";
import { combineComparers, distinctBy, priorityComparer } from "../../../../../shared/utilities/arrayHelper";
import { convertISODate, convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { ContactStatus, ContactWithFieldValues, FundName, InvestorName } from "../../../../api/adminApi";
import { FilterState, getAppliedMultiSelectFilterValues } from "../../../common/filters/filterState";
import ContactWarning from "./ContactWarning";

export const contactsTabs = ["all", "notInvited"] as const;
export type ContactsTab = (typeof contactsTabs)[number];

export const getStringContactStatus = (status: ContactStatus) =>
  status === ContactStatus.Registered ? "Registered" : "Not Registered";

export const getContactsGridColumns = (
  excludedColumns: string[],
  filterState: FilterState<ContactWithFieldValues>,
  handleContactNavigate: (data: ContactWithFieldValues, tab: "main" | "documents" | "emails") => void
): GridColDef<ContactWithFieldValues>[] => {
  const filterInvestorValues = getAppliedMultiSelectFilterValues("investor", filterState);
  const filterFundValues = getAppliedMultiSelectFilterValues("fund", filterState);

  const investorsSortComparer = combineComparers(
    priorityComparer<string>((value) => filterInvestorValues.includes(value)),
    (a, b) => a.localeCompare(b)
  );

  const fundsSortComparer = combineComparers(
    priorityComparer<string>((value) => filterFundValues.includes(value)),
    (a, b) => a.localeCompare(b)
  );

  const columns: GridColDef<ContactWithFieldValues>[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) => (
        <Stack spacing={0.5}>
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Typography
              onClick={() => handleContactNavigate(row, "main")}
              sx={{ ":hover": { cursor: "pointer" } }}
              className="highlighted-action"
              variant="subtitle2"
            >
              {row.name}
            </Typography>
            {row.warningsCodes.includes("LastEmailDeliveryFailed") && (
              <ContactWarning
                title="Email Delivery Failure"
                description="The last email sent to the contact has failed to be delivered successfully. Click the 'View' button to review the email delivery log for the contact."
                onNavigate={() => {
                  handleContactNavigate(row, "emails");
                }}
              />
            )}
          </Stack>
          {row.email && (
            <Typography color="textSecondary" variant="caption">
              {row.email}
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      field: "investors",
      headerName: "Investor(s)",
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) =>
        row.investors.length === 0 ? (
          "-"
        ) : (
          <InlineItemsList<InvestorName>
            displayCount={1}
            items={distinctBy(row.investors, (i) => i.name)}
            propGetter={({ name }) => name}
            justify="flex-start"
            sortComparer={investorsSortComparer}
          />
        ),
    },
    {
      field: "funds",
      headerName: "Fund(s)",
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) =>
        row.investors.length === 0 ? (
          "-"
        ) : (
          <InlineItemsList<FundName>
            displayCount={1}
            items={distinctBy(row.funds, (f) => f.name)}
            propGetter={({ name }) => name}
            justify="flex-start"
            sortComparer={fundsSortComparer}
          />
        ),
    },
    {
      field: "hasAccess",
      headerName: "Access to Portal",
      sortable: false,
      resizable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (row.hasAccess ? <CheckRoundedIcon color="primary" /> : <></>),
    },
    {
      field: "status",
      headerName: "Registration Status",
      sortable: false,
      resizable: false,
      flex: 1,
      minWidth: 130,
      renderCell: ({ row }) => <ContactPortalStatus status={row.status} registrationDate={row.registrationDate} />,
    },
    {
      field: "invitationSentAt",
      headerName: "Invited to Portal",
      resizable: false,
      sortable: false,
      width: 160,
      renderCell: ({ row }) => (row.invitationSentAt ? convertISODateTime(row.invitationSentAt) : "-"),
    },
    {
      field: "lastActivity",
      headerName: "Last Activity",
      resizable: false,
      sortable: false,
      width: 160,
      renderCell: ({ row }) => (row.lastActivity ? convertISODateTime(row.lastActivity) : "-"),
    },
    {
      field: "totalLiveMessages",
      headerName: "Live Documents",
      sortable: false,
      resizable: false,
      width: 120,
      renderCell: ({ row }) => (row.totalLiveMessages < 1 ? "-" : row.totalLiveMessages),
    },
  ];

  return columns.filter((col) => !excludedColumns.includes(col.field));
};

export const ContactPortalStatus = ({
  status,
  registrationDate,
}: {
  status: ContactStatus;
  registrationDate?: string;
}) => {
  return (
    <Stack>
      <Typography>{getStringContactStatus(status)}</Typography>
      {registrationDate && status === ContactStatus.Registered && (
        <Typography variant="caption" color="textSecondary">
          {convertISODate(registrationDate)}
        </Typography>
      )}
    </Stack>
  );
};
