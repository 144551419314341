import { SvgIconComponent } from "@mui/icons-material";
import { FundraisingStatus } from "../../../../../shared/api/fundraisingTypes";

export const fundraisingsFilters = ["in_progress", "completed"] as const;
export type FundraisingsFilter = (typeof fundraisingsFilters)[number];

export interface FundraisingStatusTransition {
  from: FundraisingStatus;
  to: FundraisingStatus;
  notificationsEnabled?: boolean;
  label: string;
  dialogActionLabel: string;
  detailLines: string[];
  Icon: SvgIconComponent;
}

export interface DialogState {
  openDialog?: "create" | "rename" | "change_status" | "delete" | "confirm_save";
  fundraisingId?: string;
  fundraisingName?: string;
  fundId?: string;
  areNotificationsSetUp?: boolean;
  statusTransition?: FundraisingStatusTransition;
  onSaveConfirmed?: () => void;
}

export const detailsPageTabs = [
  "overview",
  "categories",
  "access",
  "content",
  "documents",
  "activity",
  "notifications",
] as const;

export type DetailsPageTab = (typeof detailsPageTabs)[number];

export const detailsPageTabLabels = [
  "Overview",
  "Access Categories",
  "Access",
  "Content Layout",
  "Documents",
  "Activity",
  "Notifications",
] as const;
