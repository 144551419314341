import { Theme } from "@mui/material";
import { EntriliaProduct } from "../../../../../shared/api/types";
import { ListItemData } from "../../../../../shared/components/inputs/CheckItemsList";
import { makeLighterBackgroundFromColor } from "../../../../../shared/utilities/colorHelper";
import {
  AccessLevelEntity,
  AccessRole,
  ClientUserType,
  MemberRole,
  OrganizationRole,
  allOrganizationRoles,
} from "../../../../api/types/userManagementTypes";

export type CompanyAccessLevel = "Product" | "Entity";

export interface CompanyWithRoles {
  clientCode: string;
  roles: MemberRole[];
}

export interface CompanyWithAccess extends CompanyWithRoles {
  entities: AccessLevelEntity[];
  accessToAllEntities: boolean;
}

export interface CompanyProductWithRoles {
  key: EntriliaProduct;
  roles: MemberRole[];
}

enum RoleNames {
  User = "User",
  Admin = "Admin",
  Viewer = "Viewer",
  Maker = "Maker",
  ExternalUser = "External User",
}

export const getAllCompanyProductsWithRoles = (): CompanyProductWithRoles[] => [
  {
    key: "FundAccounting",
    roles: ["FundOps_Viewer", "FundOps_Member", "FundOps_Admin"],
  },
  {
    key: "InvestorRelationsManagement",
    roles: ["AP_Viewer", "AP_ExternalUser", "AP_Admin"],
  },
  { key: "ReportingPortal", roles: ["RP_Viewer", "RP_Maker", "RP_Admin"] },
  {
    key: "ExpenseManagement",
    roles: ["ExpenseMng_Member", "ExpenseMng_Admin"],
  },
  {
    key: "PortfolioMonitoring",
    roles: ["Portfolio_Monitoring_Member", "Portfolio_Monitoring_Admin"],
  },
  {
    key: "CompanyManagement",
    roles: ["Company_Settings_Viewer", "Company_Settings_Admin"],
  },
];

export const getCompanyProductsWithRolesForOrgRole = (orgRole: OrganizationRole): CompanyProductWithRoles[] => {
  if (orgRole === "Org_Owner" || orgRole === "Org_Member") {
    return [
      {
        key: "FundAccounting",
        roles: ["FundOps_Viewer", "FundOps_Member", "FundOps_Admin"],
      },
      {
        key: "InvestorRelationsManagement",
        roles: ["AP_Viewer", "AP_ExternalUser", "AP_Admin"],
      },
      {
        key: "ReportingPortal",
        roles: ["RP_Viewer", "RP_Maker", "RP_Admin"],
      },
      {
        key: "ExpenseManagement",
        roles: ["ExpenseMng_Member", "ExpenseMng_Admin"],
      },
      {
        key: "PortfolioMonitoring",
        roles: ["Portfolio_Monitoring_Member", "Portfolio_Monitoring_Admin"],
      },
      {
        key: "CompanyManagement",
        roles: ["Company_Settings_Viewer", "Company_Settings_Admin"],
      },
    ];
  }

  if (orgRole === "Org_ExternalUser") {
    return [
      {
        key: "FundAccounting",
        roles: ["FundOps_Viewer", "FundOps_Member"],
      },
      {
        key: "InvestorRelationsManagement",
        roles: ["AP_Viewer", "AP_ExternalUser"],
      },
      {
        key: "ReportingPortal",
        roles: ["RP_Viewer", "RP_Maker"],
      },
      {
        key: "ExpenseManagement",
        roles: ["ExpenseMng_Member"],
      },
      {
        key: "PortfolioMonitoring",
        roles: ["Portfolio_Monitoring_Member"],
      },
    ];
  }

  return [];
};

export const roleNamesMap: Record<MemberRole, string> = {
  AP_Viewer: RoleNames.Viewer,
  AP_Admin: RoleNames.Admin,
  AP_ExternalUser: RoleNames.ExternalUser,
  RP_Viewer: RoleNames.Viewer,
  RP_Admin: RoleNames.Admin,
  RP_Maker: RoleNames.Maker,
  FundOps_Member: RoleNames.User,
  FundOps_Admin: RoleNames.Admin,
  FundOps_Viewer: RoleNames.Viewer,
  Company_Settings_Viewer: RoleNames.Viewer,
  Company_Settings_Admin: RoleNames.Admin,
  ExpenseMng_Member: RoleNames.Viewer,
  ExpenseMng_Admin: RoleNames.Admin,
  Portfolio_Monitoring_Member: RoleNames.Viewer,
  Portfolio_Monitoring_Admin: RoleNames.Admin,
};

type OrganizationRoleData = {
  label: string;
  description: string;
};

export interface MemberFilters {
  search: string;
  types: ClientUserType[];
  role?: OrganizationRole;
  companies: ListItemData[];
}

export const organizationRolesDataMap: Record<OrganizationRole, OrganizationRoleData> = {
  Org_Owner: {
    label: "Owner",
    description: "Highest permission level. Can manage companies, users, billing.",
  },
  Org_Member: {
    label: "Member",
    description: "Regular permission level, restricted by companies given access to.",
  },
  Org_ExternalUser: {
    label: "External",
    description: "An external member with a different email domain than the organization.",
  },
};

export const filterOrganizationRoles = (roles: AccessRole[]): OrganizationRole[] =>
  roles.filter((role) => allOrganizationRoles.includes(role as OrganizationRole)) as OrganizationRole[];

export const formatClientUserType = (userType: ClientUserType) => {
  switch (userType) {
    case "Internal":
      return "Internal";
    case "External":
      return "External";
    case "Support":
      return "Entrilia";
    default:
      return userType;
  }
};

export const getSelectedSx = (theme: Theme, isSelected?: boolean) =>
  isSelected
    ? {
        backgroundColor: makeLighterBackgroundFromColor(theme.palette.primary.main),
        fieldset: {
          color: theme.palette.primary.main,
          borderWidth: "2px",
          borderColor: theme.palette.primary.main,
        },
      }
    : {};

export const getDefaultGridSx = (theme: Theme) => {
  return {
    ".member-row": {
      color: theme.palette.text.primary,
    },
    ".support-member-row": {
      color: theme.palette.success.main,
    },
    ".current-member-row": {
      ".MuiCheckbox-root .MuiSvgIcon-root": {
        color: theme.palette.info.main,
      },
    },
  };
};

const entriliaEmailDomains = ["entrilia.com", "entrilia51.dev", "aquileia.dev"];

export const isExternalUserEmail = (email: string, organizationEmailDomain: string) => {
  const emailDomain = email.split("@")[1]?.toLowerCase() ?? "";
  if (!emailDomain) {
    return true;
  }

  const internalEmailDomains = [...entriliaEmailDomains, organizationEmailDomain.toLowerCase()];
  return !internalEmailDomains.includes(emailDomain);
};

export const alignCompaniesAccessToOrgRole = (
  orgRole: OrganizationRole,
  companiesWithAccess: CompanyWithAccess[]
): CompanyWithAccess[] => {
  const result = [...companiesWithAccess];
  const allowedMemberRoles = getCompanyProductsWithRolesForOrgRole(orgRole).flatMap((p) => p.roles);
  for (const companyWithAccess of result) {
    companyWithAccess.roles = companyWithAccess.roles.filter((r) => allowedMemberRoles.includes(r));
  }

  return result;
};
