import { Stack } from "@mui/material";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import { Fund } from "../../../../api/adminApi";
import ViewSelector, { ViewType } from "../../../common/ViewSelector";
import RecordCounter from "../../../common/filters/RecordCounter";

interface Props {
  funds: Fund[];
  filteredFunds: Fund[];
  onFilter: (funds: Fund[]) => void;
  disabled?: boolean;
  viewType: ViewType;
  setViewType: (viewType: ViewType) => void;
}

const FundsFilterToolbar = ({ funds, filteredFunds, onFilter, disabled, viewType, setViewType }: Props) => {
  const handleSearch = useDebounce((value: string) => {
    onFilter(funds.filter((f) => f.name.toLowerCase().includes(value.toLowerCase())));
  }, 200);

  return (
    <Stack px={3} py={2.5} direction="row" justifyContent="space-between" alignItems="center">
      <RecordCounter records={filteredFunds.length} total={funds.length} />
      <Stack direction={"row"} spacing={1} alignContent={"center"}>
        <SearchField debounceTimeMs={200} onSearch={handleSearch} disabled={disabled} />
        <ViewSelector view={viewType} onChange={setViewType} />
      </Stack>
    </Stack>
  );
};

export default FundsFilterToolbar;
