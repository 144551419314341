import { Stack, Typography } from "@mui/material";
import { useObjectDefinitionContext } from "../ObjectDefinitionContext";
import ObjectSourceBadge from "../objects/ObjectSourceBadge";

const ObjectDetailsTitle = () => {
  const { objectDefinition } = useObjectDefinitionContext();

  return (
    <Stack py={1.5} spacing={0.5}>
      <Stack direction="row" spacing={1}>
        <Typography variant="h6">{objectDefinition.objectName}</Typography>
        <ObjectSourceBadge objectSource={objectDefinition.source} />
      </Stack>
      <Typography variant="caption" color="text.secondary">
        Object
      </Typography>
    </Stack>
  );
};

export default ObjectDetailsTitle;
