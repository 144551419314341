import { SvgIcon, SvgIconProps } from "@mui/material";

const ExcelIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="23" height="23" rx="1.5" fill="#E8F5E9" stroke="#C8E6C9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7C7 6.44772 6.55229 6 6 6C5.44772 6 5 6.44772 5 7V18C5 18.5523 5.44772 19 6 19C6.55228 19 7 18.5523 7 18L7 7ZM12 6C12.5523 6 13 6.44772 13 7L13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18L11 7C11 6.44772 11.4477 6 12 6ZM18 6C18.5523 6 19 6.44772 19 7V15C19 15.5523 18.5523 16 18 16C17.4477 16 17 15.5523 17 15V7C17 6.44772 17.4477 6 18 6Z"
      fill="#81C784"
    />
  </SvgIcon>
);

export default ExcelIcon;
