import { Button, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { PassthroughApplicationLogs } from "../../../../api/types/passthroughTypes";
import { LogsTableRowData, getRows } from "./passthroughLogsTableDataProvider";
import { useState } from "react";
import LogDetailsDialog from "../../../common/syncLogs/LogDetailsDialog";
import LogsTableWrapper from "../../../common/syncLogs/LogsTableWrapper";

interface Props {
  logItems: PassthroughApplicationLogs[];
  hideSeverityColumn?: boolean;
}

interface LogsTableRowProps extends LogsTableRowData {
  onView: (details: PassthroughApplicationLogs) => void;
}

const LogsTableRow = ({ date, description, details, onView }: LogsTableRowProps) => {
  const handleViewClick = () => onView(details);

  return (
    <TableRow hover>
      <TableCell>{date}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell align="right" className="LogsTable-actions" width="15%" padding="checkbox">
        <Button variant="text" color="primary" onClick={handleViewClick}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

const LogsTableHeadRow = () => (
  <TableRow>
    <TableCell>Date</TableCell>
    <TableCell>Event Type</TableCell>
    <TableCell></TableCell>
  </TableRow>
);

const PassthroughLogsTable = ({ logItems, hideSeverityColumn }: Props) => {
  const [logDetails, setLogDetails] = useState<PassthroughApplicationLogs>();
  const [showLogDetails, setShowLogDetails] = useState(false);

  const handleViewDetails = (logDetails: PassthroughApplicationLogs) => {
    setLogDetails(logDetails);
    setShowLogDetails(true);
  };

  const handleLogDetailsClose = () => setShowLogDetails(false);

  const rows = getRows(logItems);

  return (
    <>
      <LogsTableWrapper maxHeight="calc(100vh - 460px)">
        <TableHead>
          <LogsTableHeadRow />
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <LogsTableRow {...row} onView={handleViewDetails} key={row.key} />
          ))}
        </TableBody>
      </LogsTableWrapper>
      <LogDetailsDialog
        open={showLogDetails}
        onClose={handleLogDetailsClose}
        logDetails={logDetails}
        hideSeverityColumn={hideSeverityColumn}
      />
    </>
  );
};

export default PassthroughLogsTable;
