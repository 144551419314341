import { Typography } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid-premium";
import CenteredWrapper from "../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { Field, ObjectFieldValues } from "../../../api/types/objectTypes";
import { useFilterContext } from "../../common/filters/FilterContext";
import { getEntitiesGridColumns } from "./entitiesGridDataProvider";

interface Props {
  entities: ObjectFieldValues[];
  fields: Field[];
  showLogos?: boolean;
  isLoading?: boolean;
  onCellClick?: GridEventListener<"cellClick">;
}

const EntitiesGrid = ({ entities, fields, isLoading, onCellClick, showLogos }: Props) => {
  const { filterState } = useFilterContext<ObjectFieldValues>();

  const columns = getEntitiesGridColumns(fields, filterState.visibleColumns, { showLogos });

  return (
    <DataGrid<ObjectFieldValues>
      rows={entities}
      columns={columns}
      loading={isLoading}
      rowHeight={40}
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No rows</Typography>
          </CenteredWrapper>
        ),
      }}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      selectableRows
      onCellClick={onCellClick}
    />
  );
};

export default EntitiesGrid;
