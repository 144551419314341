import { createSvgIcon } from "@mui/material";

const CubeIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor">
    <path d="M9.16667 16.1873V10.479L4.16667 7.58317V13.2915L9.16667 16.1873ZM10.8333 16.1873L15.8333 13.2915V7.58317L10.8333 10.479V16.1873ZM10 9.0415L14.9375 6.18734L10 3.33317L5.0625 6.18734L10 9.0415ZM3.33333 14.7498C3.06944 14.5971 2.86458 14.3957 2.71875 14.1457C2.57292 13.8957 2.5 13.6179 2.5 13.3123V6.68734C2.5 6.38178 2.57292 6.104 2.71875 5.854C2.86458 5.604 3.06944 5.40262 3.33333 5.24984L9.16667 1.89567C9.43056 1.74289 9.70833 1.6665 10 1.6665C10.2917 1.6665 10.5694 1.74289 10.8333 1.89567L16.6667 5.24984C16.9306 5.40262 17.1354 5.604 17.2813 5.854C17.4271 6.104 17.5 6.38178 17.5 6.68734V13.3123C17.5 13.6179 17.4271 13.8957 17.2813 14.1457C17.1354 14.3957 16.9306 14.5971 16.6667 14.7498L10.8333 18.104C10.5694 18.2568 10.2917 18.3332 10 18.3332C9.70833 18.3332 9.43056 18.2568 9.16667 18.104L3.33333 14.7498Z" />
  </svg>,
  "CubeIcon"
);

export default CubeIcon;
