import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const CompaniesIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  const strokeColor = props.color === "primary" ? theme.palette.primary.main : theme.palette.secondary.light;

  return (
    <SvgIcon sx={{ width: 20, height: 20, fill: "none" }} {...props}>
      <path
        d="M6 22V4C6 3.73 6 3.45 6.07 3.18C6.13121 2.91063 6.26673 2.66382 6.46117 2.46761C6.65562 2.27139 6.90119 2.13364 7.17 2.07C7.46 2 8.73 2 9 2H16C16.27 2 16.55 2 16.82 2.07C17.0894 2.13121 17.3362 2.26673 17.5324 2.46117C17.7286 2.65562 17.8664 2.90119 17.93 3.17C18 3.45 18 3.73 18 4V22H6Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00027 14V20C2.00027 21.1 2.90027 22 4.00027 22H6.00027V12H4.00027C3.73027 12 3.45027 12 3.18027 12.07C2.91027 12.14 2.66027 12.27 2.46027 12.47C2.27027 12.66 2.14027 12.91 2.07027 13.18C2.01382 13.4495 1.99031 13.7248 2.00027 14Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.82 9.07027C20.5505 9.01382 20.2752 8.99031 20 9.00027H18V22.0003H20C20.5304 22.0003 21.0391 21.7896 21.4142 21.4145C21.7893 21.0394 22 20.5307 22 20.0003V11.0003C22 10.7203 22 10.4503 21.93 10.1803C21.86 9.91027 21.73 9.66027 21.53 9.46027C21.34 9.27027 21.09 9.14027 20.82 9.07027V9.07027Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 6H14" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 10H14" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 14H14" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 18H14" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default CompaniesIcon;
