import { GRID_ROOT_GROUP_ID, GridGroupNode, GridRowId } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useCallback, useRef, useState } from "react";
import { getAllRowIds } from "../../../../../../shared/utilities/dataGridApiHelper";
import { useClientContext } from "../../../../../context/ClientContext";
import { GridKey, ReportsGridExpandState, reportsGridExpandStorage } from "../../storage/reportsGridExpandStorage";

const FALLBACK_EXPAND_VALUE = true;

interface Props {
  apiRef: React.MutableRefObject<GridApiPremium>;
  gridKey: GridKey;
}

const useGridExpand = ({ apiRef, gridKey }: Props) => {
  const { clientCode } = useClientContext();
  const [expandState, setExpandState] = useState<ReportsGridExpandState>(() =>
    reportsGridExpandStorage.getReportsGridExpandState()
  );
  const expandStateRef = useRef<ReportsGridExpandState>();
  expandStateRef.current = expandState;

  const isGroupExpandedByDefault = useCallback(
    (node: GridGroupNode) => {
      const expanded = expandStateRef.current?.[clientCode]?.[gridKey]?.[node.id];
      return expanded !== undefined ? expanded : FALLBACK_EXPAND_VALUE;
    },
    [clientCode, gridKey]
  );

  const toggleSingleGridRow = useCallback(
    (expanded: boolean, rowId: GridRowId) =>
      setExpandState(reportsGridExpandStorage.toggleSingleGridRow(gridKey, clientCode, rowId, expanded)),
    [clientCode, gridKey]
  );

  const toggleAllGridRows = useCallback(
    (expanded: boolean) => {
      const groupRows: GridRowId[] = [];
      getAllRowIds(apiRef).forEach((rowId) => {
        const row = apiRef.current.getRowNode(rowId);
        if (row?.type === "group" && row?.id !== GRID_ROOT_GROUP_ID) {
          groupRows.push(rowId);
        }
      });
      setExpandState(reportsGridExpandStorage.toggleAllGridRows(gridKey, clientCode, expanded, groupRows));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientCode, gridKey]
  );

  return {
    isGroupExpandedByDefault,
    toggleSingleGridRow,
    toggleAllGridRows,
  };
};

export default useGridExpand;
