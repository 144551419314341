import { GridColDef } from "@mui/x-data-grid-premium";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { ObjectClassDefinitionInfo } from "../../../../api/types/objectTypes";
import ObjectSourceBadge from "./ObjectSourceBadge";

export const getColumnDefinitions = (): GridColDef<ObjectClassDefinitionInfo>[] => [
  {
    field: "objectName",
    headerName: "Object Type",
    sortable: false,
    flex: 1,
    minWidth: 160,
    cellClassName: "highlighted-action",
  },
  {
    field: "source",
    headerName: "Source",
    sortable: false,
    flex: 0.5,
    minWidth: 100,
    renderCell: ({ row }) => <ObjectSourceBadge objectSource={row.source} />,
  },
  {
    field: "updateAt",
    headerName: "Modified at",
    sortable: false,
    flex: 1,
    valueFormatter: (value) => (value ? convertISODateTime(value) : "-"),
  },
];
