import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { formatMoney } from "../../../../../../shared/utilities/formatters";
import { Invoice } from "../../../../../api/adminApi";
import { InvoicesTab } from "../InvoicesTab";
import InvoiceStatus from "../shared/InvoiceStatus";
import InvoicesGridActionsCell from "./InvoicesGridActionsCell";

const statusColumn: GridColDef<Invoice> = {
  field: "status",
  headerName: "Status",
  width: 180,
  hideable: true,
  sortable: false,
  renderCell: ({ row }) => <InvoiceStatus status={row.status} />,
};

const vendorColumn: GridColDef<Invoice> = {
  field: "vendor",
  headerName: "Vendor",
  flex: 1,
  sortable: false,
  renderCell: ({ row }) => <Typography noWrap>{row.vendor}</Typography>,
};

const entityColumn: GridColDef<Invoice> = {
  field: "entity",
  headerName: "Bill To",
  flex: 1,
  sortable: false,
  renderCell: ({ row }) => <Typography noWrap>{row.entity}</Typography>,
};

const getDateColumn = (field: string, headerName: string): GridColDef<Invoice> => ({
  field,
  headerName,
  flex: 1,
  sortable: false,
  valueFormatter: (value) => convertISODate(value),
  renderCell: ({ formattedValue }) => (
    <Typography noWrap sx={(theme) => ({ color: theme.palette.text.secondary })}>
      {formattedValue}
    </Typography>
  ),
});

const amountColumn: GridColDef<Invoice> = {
  field: "amount",
  headerName: "Amount",
  width: 120,
  align: "right",
  headerAlign: "right",
  sortable: false,
  valueFormatter: (value, row) => {
    if (!value) {
      return value;
    }

    const currencyCode = row.currencyCode || "USD";
    return formatMoney(value, currencyCode, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  },
  valueGetter: (_, row) => row.amount,
  renderCell: ({ formattedValue }) => <Typography noWrap>{formattedValue}</Typography>,
};

const actionsColumn: GridColDef<Invoice> = {
  field: "actions",
  headerName: "",
  sortable: false,
  width: 75,
  align: "right",
  cellClassName: "grid-row-actions",
  renderCell: ({ row }) => <InvoicesGridActionsCell row={row} />,
};

export const getInvoicesListColumns = (currentStatus: InvoicesTab): GridColDef<Invoice>[] => {
  switch (currentStatus) {
    case InvoicesTab.InProgress:
      return inprogressColumns;
    case InvoicesTab.TransactionCreated:
      return transactionCreatedColumns;
    case InvoicesTab.TransactionPosted:
      return transactionPostedColumns;
    case InvoicesTab.Rejected:
      return rejectedColumns;
    default:
      return allColumns;
  }
};

const inprogressColumns: GridColDef<Invoice>[] = [
  vendorColumn,
  entityColumn,
  getDateColumn("receivedDate", "Received Date"),
  getDateColumn("dueDate", "Due Date"),
  amountColumn,
  actionsColumn,
];

const transactionCreatedColumns: GridColDef<Invoice>[] = [
  vendorColumn,
  entityColumn,
  getDateColumn("receivedDate", "Received Date"),
  getDateColumn("dueDate", "Due Date"),
  getDateColumn("submittedToBusinessCentralAt", "Created Date"),
  amountColumn,
  actionsColumn,
];

const transactionPostedColumns: GridColDef<Invoice>[] = [
  vendorColumn,
  entityColumn,
  getDateColumn("receivedDate", "Received Date"),
  getDateColumn("dueDate", "Due Date"),
  getDateColumn("postedInBusinessCentralAt", "Posted Date"),
  amountColumn,
  actionsColumn,
];

const rejectedColumns: GridColDef<Invoice>[] = [
  vendorColumn,
  entityColumn,
  getDateColumn("receivedDate", "Received Date"),
  getDateColumn("dueDate", "Due Date"),
  getDateColumn("rejectedAt", "Rejected Date"),
  amountColumn,
  actionsColumn,
];

const allColumns: GridColDef<Invoice>[] = [
  vendorColumn,
  entityColumn,
  statusColumn,
  getDateColumn("receivedDate", "Received Date"),
  getDateColumn("dueDate", "Due Date"),
  amountColumn,
  actionsColumn,
];
