import { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import SearchField from "../../../../../../../shared/components/inputs/SearchField";
import { ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";
import ReportsGrid from "./ReportsGrid";

interface Props {
  reports: ReportInfo[];
}

const ReportsGridWrapper = ({ reports }: Props) => {
  const [searchText, setSearchText] = useState("");

  const filterReports = useMemo(() => {
    return reports.filter(
      (rep) =>
        rep.name.toLowerCase().indexOf(searchText) > -1 ||
        rep.createdBy.toLowerCase().indexOf(searchText) > -1 ||
        (rep.modifiedBy && rep.modifiedBy.toLowerCase().indexOf(searchText) > -1)
    );
  }, [searchText, reports]);

  return (
    <Grid container sx={{ display: "flex", flex: 1, px: 3, pb: 1, flexDirection: "column", gap: 2, bgcolor: "#FFF" }}>
      <Grid container justifyContent="end">
        <SearchField onSearch={(text) => setSearchText(text.toLowerCase())} debounceTimeMs={300} />
      </Grid>
      <Grid container flex={1} position="relative">
        <ReportsGrid reports={filterReports} />
      </Grid>
    </Grid>
  );
};

export default ReportsGridWrapper;
