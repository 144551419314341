import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import CheckItemsList, { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { AccessLevelEntity } from "../../../../../api/types/userManagementTypes";
import { useMembersContext } from "../MembersContext";

interface Props {
  clientCode: string;
  selectedEntities: AccessLevelEntity[];
  onSelectedChanged: (selectedEntities: AccessLevelEntity[], accessToAllEntities: boolean) => void;
  accessToAllEntities: boolean;
}

const EntitiesAccess = ({ selectedEntities, clientCode, onSelectedChanged, accessToAllEntities }: Props) => {
  const [search, setSearch] = useState<string>("");
  const { companies, getAccessLevelEntities } = useMembersContext();
  const company = companies.find((c) => c.clientCode === clientCode);

  const entities = useMemo(() => {
    return getAccessLevelEntities(clientCode);
  }, [clientCode, getAccessLevelEntities]);

  const entitiesList: ListItemData[] = entities
    .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
    .map((entity) => ({
      value: entity.id,
      label: entity.name || (entity.no ?? entity.id),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedEntitiesList: ListItemData[] = selectedEntities.map((entity) => ({
    value: entity.id,
    label: entity.name || (entity.no ?? entity.id),
  }));

  const handleSelectedEntitiesChanged = useCallback(
    (selectedEntities: ListItemData[]) => {
      const selectedEntitiesIds = selectedEntities.map((entity) => entity.value);
      const selectedEntitiesData = entities.filter((entity) => selectedEntitiesIds.includes(entity.id));
      onSelectedChanged(selectedEntitiesData, accessToAllEntities);
    },
    [accessToAllEntities, entities, onSelectedChanged]
  );

  const handleAccessToAllEntitiesChange = (checked: boolean) => {
    onSelectedChanged([], checked);
  };

  return (
    <Stack spacing={1} sx={{ maxHeight: "90%" }}>
      <Typography variant="subtitle1">Entities</Typography>
      <Typography color="textSecondary">{company?.title || clientCode}</Typography>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              sx={{ ml: 1.3 }}
              size="medium"
              onChange={(_e, checked) => handleAccessToAllEntitiesChange(checked)}
              checked={accessToAllEntities}
            />
          }
          label={<Typography>Access to all entities</Typography>}
        />
      </FormGroup>

      {!accessToAllEntities && <SearchField onSearch={setSearch} debounceTimeMs={300} />}

      {!accessToAllEntities && (
        <CheckItemsList
          disablePadding
          items={entitiesList}
          onSelectedChanged={handleSelectedEntitiesChanged}
          preselectedItems={selectedEntitiesList}
        />
      )}
    </Stack>
  );
};

export default EntitiesAccess;
