import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  onClick: () => void;
  actionDisabled?: boolean;
}

const TextHoverAction = ({ onClick, actionDisabled, children }: PropsWithChildren<Props>) => {
  if (actionDisabled) {
    return <Typography noWrap>{children}</Typography>;
  }

  return (
    <Typography
      noWrap
      onClick={onClick}
      sx={(t) => ({ cursor: "pointer", "&:hover": { color: t.palette.primary.main } })}
    >
      {children}
    </Typography>
  );
};

export default TextHoverAction;
