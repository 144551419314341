import { DateRange } from "@mui/x-date-pickers-pro";
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns";
import { formatDateShort } from "../../../../../shared/utilities/dateUtils";
import {
  DateFieldValue,
  DateFilterDefinition,
  DateFilterOperator,
  DateFilterValue,
  FilterHandler,
  OperatorOption,
} from "../filterTypes";

export interface DateFilterShortcut {
  label: string;
  getValue: () => DateRange<Date>;
}

export const dateFilterShortcuts: readonly DateFilterShortcut[] = [
  { label: "Last 7 Days", getValue: () => [endOfDay(subDays(Date.now(), 7)), endOfDay(Date.now())] },
  { label: "Last 30 Days", getValue: () => [endOfDay(subDays(Date.now(), 30)), endOfDay(Date.now())] },
  { label: "This Month", getValue: () => [startOfMonth(Date.now()), endOfMonth(Date.now())] },
  { label: "This Quarter", getValue: () => [startOfQuarter(Date.now()), endOfQuarter(Date.now())] },
  { label: "This Year", getValue: () => [startOfYear(Date.now()), endOfYear(Date.now())] },
  {
    label: "Last Month",
    getValue: () => [startOfMonth(subMonths(Date.now(), 1)), endOfMonth(subMonths(Date.now(), 1))],
  },
  {
    label: "Last Quarter",
    getValue: () => [startOfQuarter(subQuarters(Date.now(), 1)), endOfQuarter(subQuarters(Date.now(), 1))],
  },
  { label: "Last Year", getValue: () => [startOfYear(subYears(Date.now(), 1)), endOfYear(subYears(Date.now(), 1))] },
];

export const dateFilterOperatorOptions: readonly OperatorOption<DateFilterOperator>[] = [
  { label: "is", value: "range" },
  { label: "is empty", value: "empty" },
  { label: "is not empty", value: "not_empty" },
] as const;

const tryGetValidDatesFromRange = (range: [string, string] | undefined) => {
  if (!range || !range[0] || !range[1]) {
    return {};
  }
  const dateFrom = parseISO(range[0]);
  const dateTo = parseISO(range[1]);
  if (!isValid(dateFrom) || !isValid(dateTo)) {
    return {};
  }
  return { dateFrom, dateTo };
};

export const dateFilterHandler: FilterHandler<DateFilterValue, DateFieldValue, DateFilterDefinition> = {
  getDefaultValue: (definition: DateFilterDefinition) => ({
    operator: definition.operatorOptions?.[0] ?? "range",
  }),

  isActive: ({ operator, range }: DateFilterValue) => {
    switch (operator) {
      case "range": {
        return !!range && !!range[0] && !!range[1];
      }
      case "empty": {
        return true;
      }
      case "not_empty": {
        return true;
      }
    }
  },

  formatValue: ({ operator, range }: DateFilterValue) => {
    switch (operator) {
      case "range": {
        const { dateFrom, dateTo } = tryGetValidDatesFromRange(range);
        if (!dateFrom || !dateTo) {
          return "";
        }
        return isSameDay(dateFrom, dateTo)
          ? `is ${formatDateShort(dateFrom)}`
          : `is ${formatDateShort(dateFrom)} - ${formatDateShort(dateTo)}`;
      }
      case "empty": {
        return "is empty";
      }
      case "not_empty": {
        return "is not empty";
      }
    }
  },

  matchesFieldValue: ({ operator, range }: DateFilterValue, fieldValue: DateFieldValue) => {
    switch (operator) {
      case "range": {
        const { dateFrom, dateTo } = tryGetValidDatesFromRange(range);
        if (!dateFrom || !dateTo) {
          return true;
        }
        if (!fieldValue) {
          return false;
        }
        if (Array.isArray(fieldValue)) {
          return fieldValue.some(
            (value) => !!value && isValid(value) && !isBefore(value, dateFrom) && !isAfter(value, dateTo)
          );
        }
        return isValid(fieldValue) && !isBefore(fieldValue, dateFrom) && !isAfter(fieldValue, dateTo);
      }

      case "empty": {
        return !fieldValue || !isValid(fieldValue);
      }

      case "not_empty": {
        return !!fieldValue && isValid(fieldValue);
      }
    }
  },
};
