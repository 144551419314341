import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import DeleteTagIcon from "../../../../icons/DeleteTagIcon";
import { getEntityTitle } from "../../../common/files/fileDetailsHelper";
import { TagSettingsDialogState } from "./dialogState";

interface Props {
  dialogState: TagSettingsDialogState;
  onCancel: () => void;
  onSubmit: (catalogueId: string) => void;
}

const deleteFileTag = withErrorHandling(adminApi.deleteFileTag);

const DeleteTagDialog = ({ dialogState, onCancel, onSubmit }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    const { catalogueId } = dialogState.form;
    if (!catalogueId) {
      return;
    }

    setSaving(true);
    const [resp, error] = await deleteFileTag(catalogueId);
    if (error || !resp.result) {
      logError(error, "deleteFileTag");
      sendNotificationError("Could not delete file tag");
    } else {
      sendNotification(
        `Tag "${dialogState.form.name}" deleted successfully from entity ${
          dialogState.form.entityType ? getEntityTitle(dialogState.form.entityType) : ""
        }`
      );
      onSubmit(catalogueId);
    }
    setSaving(false);
  };

  return (
    <Dialog open={dialogState.openDialog === "delete"} onClose={onCancel} sx={{ ".MuiPaper-root": { width: "32rem" } }}>
      <DialogTitle>Delete Tag</DialogTitle>
      <DialogCloseButton onClick={onCancel} />
      <DialogContent sx={(t) => ({ px: t.spacing(4) })}>
        <Stack alignItems="center" spacing={2}>
          <DeleteTagIcon sx={{ width: 80, height: 80, my: 2 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">{`Delete "${dialogState.form.name}"`}</Typography>
            <Typography>Are you sure you want to delete the tag?</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={saving} onClick={handleSubmit}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTagDialog;
