import { Paper, Stack, Typography } from "@mui/material";
import { WatermarkItem } from "./WatermarkSettingsState";

interface Props {
  name: WatermarkItem;
  email: WatermarkItem;
  investorName: WatermarkItem;
  date: WatermarkItem;
  watermarkEnabled: boolean;
}

const WatermarkPreview = ({ watermarkEnabled, name, email, investorName, date }: Props) => {
  const items = [name, email, investorName, date];

  return (
    <Paper
      elevation={3}
      square
      sx={{
        height: "100%",
        aspectRatio: 0.7,
        m: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack spacing={0.5} sx={{ transform: "rotate(-30deg)" }}>
        {watermarkEnabled &&
          items.map((item) => (
            <Typography
              key={item.id}
              sx={{
                display: item.visibility ? "block" : "none",
                opacity: 0.3,
                letterSpacing: "0.42px",
                lineHeight: "150%",
                fontSize: "2vh",
                textAlign: "center",
                color: "#808080",
              }}
            >
              {item.value}
            </Typography>
          ))}
      </Stack>
    </Paper>
  );
};

export default WatermarkPreview;
