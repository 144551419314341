import { createSvgIcon } from "@mui/material";

const ArchiveIcon = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="none">
    <path d="M8 0H72C76.4 0 80 3.6 80 8V72C80 76.4 76.4 80 72 80H8C3.6 80 0 76.4 0 72V8C0 3.6 3.6 0 8 0Z" fill="none" />
    <path d="M52.9016 37.6616H27.1016V42.6616H52.9016V37.6616Z" fill="currentColor" />
    <rect x="17.5" y="26.9248" width="45" height="38.7698" stroke="currentColor" strokeWidth="5" />
    <rect x="8.46484" y="14.3052" width="63.0713" height="12.6136" stroke="currentColor" strokeWidth="5" />
  </svg>,
  "ArchiveIcon"
);

export default ArchiveIcon;
