import { blue, blueGrey, cyan, deepPurple } from "@mui/material/colors";
import ColorTag from "../../../shared/components/ColorTag";
import { CompanyType } from "../../api/types/clientTypes";

interface Props {
  type: CompanyType;
}
const CompanyTypeBadge = ({ type }: Props) => {
  if (type === "Training") {
    return <ColorTag textColor={deepPurple[500]} color={deepPurple[50]} text="Training" fontWeight={500} />;
  }
  if (type === "Demo") {
    return <ColorTag textColor={blue[700]} color={blue[50]} text="Demo" fontWeight={500} />;
  }
  if (type === "Template") {
    return <ColorTag textColor={blueGrey[500]} color={blueGrey[50]} text="Template" fontWeight={500} />;
  }
  if (type == "Sandbox") {
    return <ColorTag textColor={cyan[700]} color={cyan[50]} text="Sandbox" fontWeight={500} />;
  }
  return null;
};

export default CompanyTypeBadge;
