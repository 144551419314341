import { useFilterContext } from "../FilterContext";
import { FilterValue, MultiSelectFilter } from "../filterTypes";
import { getSortedDistinctOptions } from "../handlers/multiSelectFilter";
import MultiSelectFilterEditorCore from "./MultiSelectFilterEditorCore";

interface Props {
  filter: MultiSelectFilter;
  onUpdateValue: (newValue: FilterValue) => void;
}

const MultiSelectFilterEditor = ({ filter, onUpdateValue }: Props) => {
  const { allRowsForSelectOptions } = useFilterContext();
  const options = getSortedDistinctOptions(filter, allRowsForSelectOptions ?? []);
  return <MultiSelectFilterEditorCore options={options} filter={filter} onUpdateValue={onUpdateValue} />;
};

export default MultiSelectFilterEditor;
