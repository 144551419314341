import { Stack, Typography } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { FundraisingInfo } from "../../../../api/types/fundraisingTypes";
import RecordCounter from "../../../common/filters/RecordCounter";
import { columnDefinitions } from "./fundraisingsGridDataProvider";
import PageDeactivatedAlert from "./PageDeactivatedAlert";

interface Props {
  fundraisings: FundraisingInfo[];
  isLoading: boolean;
  showPageDeactivatedWarning: boolean;
}

const FundraisingsList = ({ fundraisings, isLoading, showPageDeactivatedWarning }: Props) => {
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row, field }) => {
    if (field !== "actions") {
      navigate(row.id);
    }
  };

  return (
    <Stack spacing={2} height="100%">
      {showPageDeactivatedWarning && <PageDeactivatedAlert />}
      <RecordCounter records={fundraisings.length} total={fundraisings.length} hide={isLoading} />
      <DataGrid<FundraisingInfo>
        rows={fundraisings}
        columns={columnDefinitions}
        loading={isLoading}
        rowHeight={40}
        selectableRows
        onCellClick={handleCellClick}
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableColumnReorder
        disableColumnSorting
        disableRowSelectionOnClick
        slots={{
          loadingOverlay: () => <InlineLoader />,
          noRowsOverlay: () => (
            <CenteredWrapper>
              <Typography color="text.secondary">No fundraisings yet</Typography>
            </CenteredWrapper>
          ),
        }}
      />
    </Stack>
  );
};

export default FundraisingsList;
