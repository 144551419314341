import { Stack, SxProps } from "@mui/material";
import InvoiceControlWarning from "./InvoiceControlWarning";
import { PropsWithChildren } from "react";

interface Props {
  validationError?: string;
  isLowConfident?: boolean;
  containerSx?: SxProps;
}

const InvoiceControlWithWarning = ({
  children,
  isLowConfident,
  validationError,
  containerSx,
}: PropsWithChildren<Props>) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" flex={1} sx={containerSx}>
      {children}
      <InvoiceControlWarning validationError={validationError} isLowConfident={isLowConfident} />
    </Stack>
  );
};

export default InvoiceControlWithWarning;
