import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Fund } from "../../../../api/adminApi";

export const getFundsListColumns = (onFundClick: (id: string) => void): GridColDef<Fund>[] => [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Box
        width="100%"
        className="highlighted-action"
        onClick={() => onFundClick(row.id)}
        sx={{ ":hover": { cursor: "pointer" } }}
      >
        {row.name}{" "}
      </Box>
    ),
  },
  {
    field: "investorsCount",
    headerName: "Investors",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => row.investorsCount,
  },
];
