import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { DataCatalogueEntityType, FileTag, FileTagSettingsResponse } from "../../../../api/types/fileTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { groupTagsByEntityType, orderedEntityTypesForTags } from "../../../common/files/fileDetailsHelper";
import AddTagDialog from "./AddTagDialog";
import DeleteTagDialog from "./DeleteTagDialog";
import EditTagDialog from "./EditTagDialog";
import FileTagGroup from "./FileTagGroup";
import { TagFormState, TagSettingsDialogState, getInitialState, validateForm } from "./dialogState";

interface Props {
  settings: FileTagSettingsResponse;
  onSettingsUpdate: (tagSettings: FileTagSettingsResponse) => void;
}

const FileTagSettings = ({ settings, onSettingsUpdate }: Props) => {
  const { hasPermissions } = useClientContext();
  const [dialogState, setDialogState] = useState<TagSettingsDialogState>(getInitialState());

  const canEdit = hasPermissions(["ManageAllCompanyFiles"]);

  const tagGroups = groupTagsByEntityType(settings.tags);

  const handleAddClick = (entityType: DataCatalogueEntityType | "") => {
    if (canEdit) {
      const initialState = getInitialState();
      setDialogState({ openDialog: "add", form: { ...initialState.form, entityType } });
    }
  };

  const handleEditClick = (tag: FileTag, entityType: DataCatalogueEntityType) => {
    if (canEdit) {
      setDialogState({
        openDialog: "edit",
        form: { catalogueId: tag.catalogueId, name: tag.name, entityType, isValid: false, errors: {} },
      });
    }
  };

  const handleDeleteClick = (tag: FileTag, entityType: DataCatalogueEntityType) => {
    if (canEdit) {
      const initialState = getInitialState();
      setDialogState({
        openDialog: "delete",
        form: { ...initialState.form, catalogueId: tag.catalogueId, name: tag.name, entityType },
      });
    }
  };

  const handleDialogClose = () => {
    setDialogState((current) => ({ ...current, openDialog: undefined }));
  };

  const handleFormChange = (newForm: TagFormState) => {
    setDialogState((current) => ({ ...current, form: validateForm(newForm, tagGroups) }));
  };

  const handleTagAdded = (newTag: FileTag) => {
    setDialogState((state) => ({ ...state, openDialog: undefined }));
    onSettingsUpdate({ tags: [...settings.tags, newTag] });
  };

  const handleTagUpdated = (catalogueId: string, updatedTag: FileTag) => {
    setDialogState((state) => ({ ...state, openDialog: undefined }));
    onSettingsUpdate({
      tags: [...settings.tags.filter((t) => t.catalogueId !== catalogueId), updatedTag],
    });
  };

  const handleTagDeleted = (catalogueId: string) => {
    setDialogState((state) => ({ ...state, openDialog: undefined }));
    onSettingsUpdate({
      tags: [...settings.tags.filter((t) => t.catalogueId !== catalogueId)],
    });
  };

  return (
    <>
      <Stack width="100%">
        <Box
          py={2}
          px={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={(t) => ({ borderBottom: `1px solid ${t.palette.divider}` })}
        >
          <Typography variant="subtitle1">Tag Settings</Typography>
          {canEdit && (
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleAddClick("")}>
              New Tag
            </Button>
          )}
        </Box>
        <Stack py={2.5} px={3} spacing={2} sx={(t) => ({ height: "100%", backgroundColor: t.palette.background.grey })}>
          {orderedEntityTypesForTags.map((entityType) => (
            <FileTagGroup
              key={entityType}
              readOnly={!canEdit}
              entityType={entityType}
              tags={tagGroups.get(entityType) ?? []}
              onAddClick={() => handleAddClick(entityType)}
              onEditClick={(tag) => handleEditClick(tag, entityType)}
              onDeleteClick={(tag) => handleDeleteClick(tag, entityType)}
            />
          ))}
        </Stack>
      </Stack>
      <AddTagDialog
        dialogState={dialogState}
        onFormChange={handleFormChange}
        onCancel={handleDialogClose}
        onSubmit={handleTagAdded}
      />
      <EditTagDialog
        dialogState={dialogState}
        onFormChange={handleFormChange}
        onCancel={handleDialogClose}
        onSubmit={handleTagUpdated}
      />
      <DeleteTagDialog dialogState={dialogState} onCancel={handleDialogClose} onSubmit={handleTagDeleted} />
    </>
  );
};

export default FileTagSettings;
