import { PropsWithChildren } from "react";
import { useCopyToEligibleCompanies } from "../hooks/useCopyToEligibleCompanies";

export function CopyToEligibility({ children }: PropsWithChildren) {
  const companies = useCopyToEligibleCompanies();

  const canCopyTo = companies.length > 1;

  if (canCopyTo === false) {
    return null;
  }

  return <>{children}</>;
}
