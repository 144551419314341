import DateFilterIcon from "@mui/icons-material/CalendarTodayRounded";
import BooleanFilterIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import MultiSelectFilterIcon from "@mui/icons-material/ChecklistRounded";
import NumberFilterIcon from "@mui/icons-material/TagRounded";
import TextFilterIcon from "@mui/icons-material/TitleRounded";
import { FilterType } from "./filterTypes";

interface Props {
  filterType: FilterType;
}

const FilterTypeIcon = ({ filterType }: Props) => {
  switch (filterType) {
    case "text":
      return <TextFilterIcon />;
    case "multi_select":
      return <MultiSelectFilterIcon />;
    case "number":
      return <NumberFilterIcon />;
    case "date":
      return <DateFilterIcon />;
    case "boolean":
      return <BooleanFilterIcon />;
  }
};

export default FilterTypeIcon;
