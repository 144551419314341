import { PaginatedList } from "../../../../../shared/api/types";
import { FundStructureDocument, SearchInvestorDocumentsRequest } from "../../../../api/adminApi";
import { MessagePublishStatus } from "../../../../api/types/documentCollectionTypes";

export interface FundStructureDocumentsFilterValue {
  searchTerm: string;
  statuses: MessagePublishStatus[];
  categoryIds: string[];
  fundIds: string[];
  fundInvestorIds: string[];
}

export interface FundStructureDocumentsState {
  documents: FundStructureDocument[];
  page: number;
  totalPages: number;
  totalRecords: number;
  filter: FundStructureDocumentsFilterValue;
}

type Action =
  | { type: "load_data"; data: PaginatedList<FundStructureDocument> }
  | { type: "next_page_requested" }
  | { type: "filter"; filterValue: FundStructureDocumentsFilterValue }
  | { type: "clear_filters" };

export const getInitialFilterState = (): FundStructureDocumentsFilterValue => ({
  searchTerm: "",
  statuses: [],
  categoryIds: [],
  fundIds: [],
  fundInvestorIds: [],
});

export const getInitialState = (): FundStructureDocumentsState => ({
  documents: [],
  page: 0,
  totalPages: 0,
  totalRecords: 0,
  filter: getInitialFilterState(),
});

export const reducer = (state: FundStructureDocumentsState, action: Action): FundStructureDocumentsState => {
  switch (action.type) {
    case "load_data": {
      const { items, total, totalPages } = action.data;
      const documents = state.page === 0 ? items : [...state.documents, ...items];
      return {
        ...state,
        documents,
        totalPages: totalPages ?? state.totalPages,
        totalRecords: total ?? state.totalRecords,
      };
    }
    case "next_page_requested": {
      const canLoadMore = state.totalPages > 0 && state.page < state.totalPages - 1;
      if (canLoadMore) {
        return {
          ...state,
          page: state.page + 1,
        };
      }

      return state;
    }
    case "filter": {
      return {
        ...state,
        page: 0,
        filter: action.filterValue,
      };
    }
    case "clear_filters": {
      return { ...state, page: 0, filter: { ...getInitialFilterState(), searchTerm: state.filter.searchTerm } };
    }
    default: {
      return state;
    }
  }
};

const pageSize = 50;

export const stateToRequest = (
  page: number,
  filter: FundStructureDocumentsFilterValue
): SearchInvestorDocumentsRequest => ({
  paging: {
    page,
    size: pageSize,
    totals: page === 0,
  },
  search: filter.searchTerm.trim().toLowerCase(),
  fundIds: filter.fundIds.length === 0 ? undefined : filter.fundIds,
  fundInvestorIds: filter.fundInvestorIds.length === 0 ? undefined : filter.fundInvestorIds,
  categoryIds: filter.categoryIds.length === 0 ? undefined : filter.categoryIds,
  publishingStatusFilter:
    filter.statuses.length === 1 && filter.statuses[0] === "Live"
      ? "Live"
      : filter.statuses.length === 1 && filter.statuses[0] === "Preview"
        ? "Preview"
        : "Any",
});
