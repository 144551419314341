import { useEffect, useState } from "react";

const DEFAULT_CHUNK_SIZE = 20;

interface Props {
  children: React.ReactNode;
  chunkSize?: number;
}

const useLazyRenderList = ({ children, chunkSize = DEFAULT_CHUNK_SIZE }: Props) => {
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const [loadedItems, setLoadedItems] = useState<React.ReactElement[]>(itemData.slice(0, chunkSize));

  useEffect(() => {
    setLoadedItems(itemData.slice(0, chunkSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const loadMore = () => {
    const loadedCount = loadedItems.length;
    if (itemData.length !== 0 && loadedCount < itemData.length) {
      const end = loadedCount + chunkSize;
      const newItems = itemData.slice(loadedCount, end >= itemData.length ? itemData.length : end);
      setLoadedItems([...loadedItems, ...newItems]);
    }
  };

  return {
    loadedItems,
    loadMore,
  };
};

export default useLazyRenderList;
