import React, { PropsWithChildren, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";
import {
  ConnectorSettings,
  CrmConnectionInfo,
  CrmId,
  CrmSyncSettings,
  ObjectDefinitions,
} from "../../../api/crmConnectorsApi";

interface CrmConnectorContextProps {
  crmName: string;
  crmApiName: CrmId;
  logoSrc: string;
  connectionInfo: CrmConnectionInfo;
  connectorSettings: ConnectorSettings;
  ourObjects: ObjectDefinitions;
  crmObjects: ObjectDefinitions;
  onSyncSettingsUpdate: (syncSettings: CrmSyncSettings) => void;
}

const CrmConnectorContext = React.createContext<CrmConnectorContextProps | undefined>(undefined);

export const CrmConnectorContextProvider = ({ children, ...val }: PropsWithChildren<CrmConnectorContextProps>) => (
  <CrmConnectorContext.Provider value={val}>{children}</CrmConnectorContext.Provider>
);

export const useCrmConnectorContext = () => {
  const contextValue = useContext(CrmConnectorContext);
  return defined(contextValue);
};
