import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Chip, Tooltip } from "@mui/material";
import { getRandomMuiColor } from "../../../../../shared/utilities/colorHelper";
import { useConsentsContext } from "./ConsentsContext";

const ConsentCategoryChip = ({ categoryId }: { categoryId?: string }) => {
  const { categories: accessCategories } = useConsentsContext();

  if (categoryId === undefined) {
    return <Chip sx={{ backgroundColor: "secondary" }} label="All Categories" />;
  }

  const category = accessCategories.find((category) => category.id === categoryId);

  if (category === undefined) {
    return (
      <>
        <Chip
          sx={(theme) => ({ mr: 1, backgroundColor: "transparent", borderColor: theme.palette.divider, border: 1 })}
          label="No Category"
        />
        <Tooltip title="Category not found or deleted" arrow>
          <HelpOutlineIcon fontSize="medium" color="secondary" />
        </Tooltip>
      </>
    );
  }

  return (
    <Chip
      sx={{ backgroundColor: getRandomMuiColor(["50", "100", "200"], category.code.length) }}
      label={category.name}
    />
  );
};

export default ConsentCategoryChip;
