import { SvgIcon, SvgIconProps } from "@mui/material";

const HidePanelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20, fill: "none" }} {...props}>
      <path
        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM8 19V5H5V19H8ZM10 19H19V5H10V19Z"
        fill="#8E9BA9"
      />
      <path
        d="M10.2344 12.7654C10.0784 12.6094 10 12.4037 10 12.199C10 11.9942 10.0784 11.7905 10.2344 11.6345L12.6348 9.23219C12.7888 9.0792 12.9912 8.99977 13.1992 8.99977C13.3022 8.99977 13.4069 9.01932 13.5059 9.06032C13.8059 9.18432 14 9.4756 14 9.7986V11.199H16C16.552 11.199 17 11.647 17 12.199C17 12.751 16.552 13.199 16 13.199H14V14.5994C14 14.9224 13.8049 15.2137 13.5059 15.3377C13.2059 15.4607 12.8638 15.3938 12.6348 15.1658L10.2344 12.7654Z"
        fill="#8E9BA9"
      />
    </SvgIcon>
  );
};

export default HidePanelIcon;
