import { Typography } from "@mui/material";
import HorizontalFill from "../../../../../../../shared/components/HorizontalFill";
import { InProgressIcon } from "./InProgressIcon";

interface Props {
  text: string;
}
export function ReportInProgress({ text }: Props) {
  return (
    <>
      <Typography color="secondary">{text}</Typography>
      <HorizontalFill />
      <InProgressIcon />
    </>
  );
}
