import { Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InvestorChangeRequestState } from "../../../../shared/api/changeRequestTypes";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import SearchField from "../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { InvestorChangeRequest } from "../../../api/types/changeRequestTypes";
import { useClientContext } from "../../../context/ClientContext";
import { pageRoutes } from "../../../routes";
import {
  PaginatedItemsState,
  getInitialPaginatedItemsState,
  getPagingParams,
  loadItemsAction,
  nextPageAction,
  searchItemsAction,
} from "../../../state/paginatedState";
import RecordCounter from "../../common/filters/RecordCounter";
import ChangeRequestsGrid from "./ChangeRequestsGrid";

interface Props {
  stateFilter?: InvestorChangeRequestState;
}

const ChangeRequestsList = ({ stateFilter }: Props) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const [itemsState, setItemsState] = useState<PaginatedItemsState<InvestorChangeRequest>>(
    getInitialPaginatedItemsState()
  );

  const searchChangeRequests = useCallback(
    () =>
      adminApi.searchInvestorChangeRequests({
        paging: getPagingParams(itemsState.page),
        state: stateFilter,
        referenceCode: itemsState.searchTerm || undefined,
      }),
    [itemsState.page, itemsState.searchTerm, stateFilter]
  );

  const [, error, { isFetching }] = useFetch(searchChangeRequests, (data) => {
    setItemsState(loadItemsAction(data));
  });

  if (error) {
    logError(error, "[ChangeRequestsList]");
    return <DataLoadingFailed title="Failed to load requests" />;
  }

  const handleSearch = (searchTerm: string) => {
    const referenceCode = searchTerm.toUpperCase().trim();
    if (referenceCode.length === 0 || referenceCode.length > 1) {
      apiRef.current.scrollToIndexes({ rowIndex: 0 });
      setItemsState(searchItemsAction(referenceCode));
    }
  };

  const handleRowSelect = (workflowId: string) => {
    navigate(`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.requests}/${workflowId}`, {
      state: { tab: stateFilter || "All" },
    });
  };

  const handleRowScrollEnd = () => {
    setTimeout(() => {
      setItemsState(nextPageAction());
    }, 100);
  };

  const columnVisibilityModel = stateFilter === undefined ? undefined : { workflowStatus: false };

  return (
    <Stack spacing={2} height="100%">
      <Stack px={1} spacing={2.5} direction="row" alignItems="center" justifyContent="space-between">
        <RecordCounter
          records={itemsState.items.length}
          total={itemsState.totalRecords}
          hide={isFetching || itemsState.totalRecords === 0}
        />
        <SearchField debounceTimeMs={300} onSearch={handleSearch} placeholder="Search by Reference" />
      </Stack>
      <ChangeRequestsGrid
        isLoading={isFetching}
        apiRef={apiRef}
        rows={itemsState.items}
        onRowSelect={handleRowSelect}
        onRowScrollEnd={handleRowScrollEnd}
        columnVisibilityModel={columnVisibilityModel}
      />
    </Stack>
  );
};

export default ChangeRequestsList;
