import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, Divider, FormControlLabel, Grid, Stack, Switch, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useLocalization } from "../../../../hooks/useLocalization";
import WatermarkPreview from "./WatermarkPreview";
import { getDetailsControlLabel } from "./watermarkSettingsHelper";
import { WatermarkSettingsState } from "./WatermarkSettingsState";

const updateWatermarkSettings = withErrorHandling(adminApi.updateWatermarkSettings);

const WatermarkSettingsPage = () => {
  const { watermark_settings: locale } = useLocalization();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [settingsState, setSettingsState] = useState<WatermarkSettingsState>();
  const [loading, setLoading] = useState(false);

  const [, error] = useFetch(adminApi.getWatermarkSettings, (data) => {
    setSettingsState({
      watermarkEnabled: data.isEnabled,
      contactName: {
        id: "ContactName",
        value: "John Doe",
        visibility: data.segments.includes("ContactName"),
      },
      contactEmail: {
        id: "ContactEmail",
        value: "john.doe@example.com",
        visibility: data.segments.includes("ContactEmail"),
      },
      investorName: {
        id: "InvestorName",
        value: "Investor ABC",
        visibility: data.segments.includes("InvestorName"),
      },
      dateAndTime: {
        id: "DateAndTime",
        value: "24/1/2024 10:25 AM",
        visibility: data.segments.includes("DateAndTime"),
      },
      isDirty: false,
    });
  });

  const handleWatermarkToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    settingsState && setSettingsState({ ...settingsState, watermarkEnabled: event.target.checked, isDirty: true });
  };

  const handleDetailsToggle = (value: Partial<WatermarkSettingsState>) => {
    settingsState && setSettingsState({ ...settingsState, ...value, isDirty: true });
  };

  const handleSave = async () => {
    if (!settingsState) {
      return;
    }

    setLoading(true);
    const selectedSegments = Object.values(settingsState)
      .filter((item) => item.visibility)
      .map((item) => item.id);

    const data = { isEnabled: settingsState.watermarkEnabled, segments: selectedSegments };

    const [, error] = await updateWatermarkSettings(data);
    if (error) {
      sendNotificationError(locale.saving_error);
    } else {
      sendNotification(locale.saved);
      setSettingsState({ ...settingsState, isDirty: false });
    }
    setLoading(false);
  };

  if (error) {
    logError(error, "WatermarkSettingsPage");
    return <DataLoadingFailed title={locale.loading_failed} sx={{ height: "100%" }} />;
  }

  if (!settingsState) {
    return <InlineLoader text={locale.loading} />;
  }

  return (
    <Grid container height={"100%"}>
      <Grid item xs={3} py={2.5} px={3} gap={3} display={"flex"} flexDirection={"column"}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="subtitle1">{locale.watermark_settings}</Typography>
            <Typography color="secondary">{locale.watermark_settings_description}</Typography>
          </Stack>
          <FormControlLabel
            control={<Switch checked={!!settingsState.watermarkEnabled} onChange={handleWatermarkToggle} />}
            label="Watermark PDF files"
          />
        </Stack>
        <Divider />
        <Stack spacing={0.5}>
          <Typography variant="subtitle1">Details</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.contactName.visibility}
                onChange={(_, checked) =>
                  handleDetailsToggle({
                    contactName: { ...settingsState.contactName, visibility: checked },
                  })
                }
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={getDetailsControlLabel(settingsState.contactName.id)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.contactEmail.visibility}
                onChange={(_, checked) =>
                  handleDetailsToggle({
                    contactEmail: { ...settingsState.contactEmail, visibility: checked },
                  })
                }
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={getDetailsControlLabel(settingsState.contactEmail.id)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.investorName.visibility}
                onChange={(_, checked) =>
                  handleDetailsToggle({
                    investorName: { ...settingsState.investorName, visibility: checked },
                  })
                }
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={getDetailsControlLabel(settingsState.investorName.id)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.dateAndTime.visibility}
                onChange={(_, checked) =>
                  handleDetailsToggle({
                    dateAndTime: { ...settingsState.dateAndTime, visibility: checked },
                  })
                }
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={getDetailsControlLabel(settingsState.dateAndTime.id)}
          />
        </Stack>
        <Divider />
        <Stack flexDirection={"row"}>
          <LoadingButton variant="contained" disabled={!settingsState.isDirty} loading={loading} onClick={handleSave}>
            Save
          </LoadingButton>
        </Stack>
      </Grid>
      <Grid
        item
        display={"flex"}
        flexDirection={"column"}
        xs={9}
        sx={(t) => ({ borderLeft: "1px solid", borderColor: t.palette.divider })}
      >
        <Stack>
          <Box py={2} px={2.5} sx={(t) => ({ borderBottom: "1px solid", borderColor: t.palette.divider })}>
            <Typography variant="subtitle1">Watermark preview</Typography>
          </Box>
        </Stack>
        <Stack height={"100%"} bgcolor={grey[100]} p={2}>
          {settingsState && (
            <WatermarkPreview
              name={settingsState.contactName}
              email={settingsState.contactEmail}
              investorName={settingsState.investorName}
              date={settingsState.dateAndTime}
              watermarkEnabled={settingsState.watermarkEnabled}
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default WatermarkSettingsPage;
