import { Box, Typography } from "@mui/material";

const NoNotes = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="7.5rem" width="100%">
    <Typography variant="caption" color="text.secondary">
      No notes yet
    </Typography>
  </Box>
);

export default NoNotes;
