import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { parseISO } from "date-fns";
import InlineItemsList from "../../../../../shared/components/InlineItemsList";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { combineComparers, distinctBy, priorityComparer } from "../../../../../shared/utilities/arrayHelper";
import { formatDateTime } from "../../../../../shared/utilities/dateUtils";
import { InvestorContact, InvestorFund, InvestorWithFieldValues } from "../../../../api/adminApi";
import { Field } from "../../../../api/types/objectTypes";
import { TableColumnDefinition } from "../../../common/columns/columnTypes";
import { FilterState, getAppliedMultiSelectFilterValues } from "../../../common/filters/filterState";
import { getGridColumnForField } from "../../../entityFields/fieldGridDefinitions";

export const getPredefinedColumnDefinitions = (): TableColumnDefinition[] => [
  {
    id: "funds",
    title: "Funds",
  },
  {
    id: "contacts",
    title: "Contacts",
  },
  {
    id: "createdAt",
    title: "Created at",
    defaultHidden: true,
  },
  {
    id: "updateAt",
    title: "Modified at",
    defaultHidden: true,
  },
];

const getSelectableGridColumns = (
  filterState: FilterState<InvestorWithFieldValues>
): GridColDef<InvestorWithFieldValues>[] => {
  const filterFundValues = getAppliedMultiSelectFilterValues("funds", filterState);
  const filterContactValues = getAppliedMultiSelectFilterValues("contacts", filterState);

  const fundsSortComparer = combineComparers(
    priorityComparer<string>((value) => filterFundValues.includes(value)),
    (a, b) => a.localeCompare(b)
  );

  const contactsSortComparer = combineComparers(
    priorityComparer<string>((value) => filterContactValues.includes(value)),
    (a, b) => a.localeCompare(b)
  );

  return [
    {
      field: "funds",
      headerName: "Funds",
      sortable: false,
      flex: 1,
      renderCell: ({ row }) =>
        row.funds.length === 0 ? (
          "-"
        ) : (
          <InlineItemsList<InvestorFund>
            displayCount={1}
            items={distinctBy(row.funds, (f) => f.fundName)}
            propGetter={(prop) => prop.fundName}
            justify="flex-start"
            sortComparer={fundsSortComparer}
          />
        ),
    },
    {
      field: "contacts",
      headerName: "Contacts",
      sortable: false,
      flex: 1,
      renderCell: ({ row }) =>
        row.contacts.length === 0 ? (
          "-"
        ) : (
          <InlineItemsList<InvestorContact>
            displayCount={1}
            items={distinctBy(row.contacts, (c) => c.name)}
            propGetter={(prop) => prop.name}
            justify="flex-start"
            sortComparer={contactsSortComparer}
          />
        ),
    },
    {
      field: "createdAt",
      headerName: "Created at",
      sortable: false,
      type: "dateTime",
      flex: 1,
      valueGetter: (_, row) => parseISO(row.createdAt),
      valueFormatter: (value) => formatDateTime(value),
    },
    {
      field: "updateAt",
      headerName: "Modified at",
      sortable: false,
      type: "dateTime",
      flex: 1,
      valueGetter: (_, row) => parseISO(row.updateAt),
      valueFormatter: (value) => formatDateTime(value),
    },
  ];
};

export const getInvestorsGridColumns = (
  fields: Field[],
  filterState: FilterState<InvestorWithFieldValues>,
  handleInvestorNavigate: (investorId: string) => void
): GridColDef<InvestorWithFieldValues>[] => {
  const selectableColumns = getSelectableGridColumns(filterState);

  const selectedColumns = filterState.visibleColumns.reduce<GridColDef<InvestorWithFieldValues>[]>((result, column) => {
    const gridColumn = selectableColumns.find((col) => col.field === column.id);
    if (gridColumn) {
      result.push(gridColumn);
    } else {
      const field = fields.find((field) => field.id === column.id);
      if (field) {
        result.push(getGridColumnForField(field));
      }
    }
    return result;
  }, []);

  return [
    {
      field: "title",
      headerName: "Name",
      sortable: false,
      flex: 1.5,
      renderCell: ({ row }) => (
        <Box
          width="100%"
          className="highlighted-action"
          onClick={() => handleInvestorNavigate(row.id)}
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          <TypographyTooltipEllipsis text={row.title} />
        </Box>
      ),
    },
    ...selectedColumns,
  ];
};
