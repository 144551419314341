import { createSvgIcon } from "@mui/material";

const ArchiveActionIcon = createSvgIcon(
  <svg viewBox="0 0 18 18" fill="none">
    <path
      d="M9 7.5C8.7875 7.5 8.60938 7.57188 8.46563 7.71562C8.32188 7.85938 8.25 8.0375 8.25 8.25V10.65L7.575 9.975C7.4375 9.8375 7.2625 9.76875 7.05 9.76875C6.8375 9.76875 6.6625 9.8375 6.525 9.975C6.3875 10.1125 6.31875 10.2875 6.31875 10.5C6.31875 10.7125 6.3875 10.8875 6.525 11.025L8.475 12.975C8.625 13.125 8.8 13.2 9 13.2C9.2 13.2 9.375 13.125 9.525 12.975L11.475 11.025C11.6125 10.8875 11.6812 10.7125 11.6812 10.5C11.6812 10.2875 11.6125 10.1125 11.475 9.975C11.3375 9.8375 11.1625 9.76875 10.95 9.76875C10.7375 9.76875 10.5625 9.8375 10.425 9.975L9.75 10.65V8.25C9.75 8.0375 9.67813 7.85938 9.53438 7.71562C9.39063 7.57188 9.2125 7.5 9 7.5ZM3.75 6V14.25H14.25V6H3.75ZM3.75 15.75C3.3375 15.75 2.98438 15.6031 2.69063 15.3094C2.39688 15.0156 2.25 14.6625 2.25 14.25V4.89375C2.25 4.71875 2.27813 4.55 2.33438 4.3875C2.39062 4.225 2.475 4.075 2.5875 3.9375L3.525 2.79375C3.6625 2.61875 3.83438 2.48438 4.04063 2.39062C4.24688 2.29688 4.4625 2.25 4.6875 2.25H13.3125C13.5375 2.25 13.7531 2.29688 13.9594 2.39062C14.1656 2.48438 14.3375 2.61875 14.475 2.79375L15.4125 3.9375C15.525 4.075 15.6094 4.225 15.6656 4.3875C15.7219 4.55 15.75 4.71875 15.75 4.89375V14.25C15.75 14.6625 15.6031 15.0156 15.3094 15.3094C15.0156 15.6031 14.6625 15.75 14.25 15.75H3.75ZM4.05 4.5H13.95L13.3125 3.75H4.6875L4.05 4.5Z"
      fill="currentColor"
    />
  </svg>,
  "ArchiveActionIcon"
);

export default ArchiveActionIcon;
