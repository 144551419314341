import { Stack, TextField } from "@mui/material";
import { useRef, useState } from "react";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import OperatorSelect from "../OperatorSelect";
import { FilterValue, TextFilter, TextFilterOperator } from "../filterTypes";
import { textFilterOperatorOptions } from "../handlers/textFilter";
import EmptyFilterBody from "./EmptyFilterBody";

interface Props {
  filter: TextFilter;
  onUpdateValue: (newValue: FilterValue) => void;
}

const isEmptyFilterBody = (operator: TextFilterOperator) => ["empty", "not_empty"].includes(operator);

const getOperatorOptions = (filter: TextFilter) =>
  filter.operatorOptions !== undefined
    ? textFilterOperatorOptions.filter((option) => filter.operatorOptions?.includes(option.value))
    : textFilterOperatorOptions;

const TextFilterEditor = ({ filter, onUpdateValue }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [textValue, setTextValue] = useState(filter.value.text ?? "");

  const handleOperatorChange = (operator: TextFilterOperator) => {
    if (isEmptyFilterBody(operator)) {
      setTextValue("");
      onUpdateValue({ operator, text: "" });
    } else {
      onUpdateValue({ operator, text: filter.value.text });
    }

    setTimeout(() => inputRef.current?.focus(), 100);
  };

  const updateTextValueInFilter = useDebounce((text: string) => {
    onUpdateValue({ operator: filter.value.operator, text });
  }, 500);

  const handleTextValueChange = (value: string) => {
    setTextValue(value);
    updateTextValueInFilter(value);
  };

  const emptyFilterBody = isEmptyFilterBody(filter.value.operator);
  const operatorOptions = getOperatorOptions(filter);

  return (
    <Stack spacing={2} p={2} sx={(t) => ({ width: t.spacing(47.5) })}>
      <OperatorSelect<TextFilterOperator>
        filterName={filter.name}
        options={operatorOptions}
        value={filter.value.operator}
        onChange={handleOperatorChange}
      />
      {emptyFilterBody && <EmptyFilterBody />}
      {!emptyFilterBody && (
        <TextField
          inputRef={inputRef}
          autoFocus
          fullWidth
          value={textValue}
          onChange={({ target }) => handleTextValueChange(target.value)}
          InputProps={{
            endAdornment: !!textValue && <CloseIconButton onClick={() => handleTextValueChange("")} sx={{ p: 0 }} />,
          }}
        />
      )}
    </Stack>
  );
};

export default TextFilterEditor;
