import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import NumbersRoundedIcon from "@mui/icons-material/NumbersRounded";
import TitleIcon from "@mui/icons-material/TitleRounded";
import { SvgIconProps } from "@mui/material";
import { MetricDataType } from "../../../api/types/portfolioMonitoringTypes";
import MoneyFieldTypeIcon from "../../../icons/fieldTypes/MoneyFieldTypeIcon";
import SelectFieldTypeIcon from "../../../icons/fieldTypes/SelectFieldTypeIcon";

interface Props extends SvgIconProps {
  dataType: MetricDataType;
}

const MetricDataTypeIcon = ({ dataType, ...iconProps }: Props) => {
  switch (dataType) {
    case "Text": {
      return <TitleIcon {...iconProps} />;
    }
    case "Number": {
      return <NumbersRoundedIcon {...iconProps} />;
    }
    case "Money": {
      return <MoneyFieldTypeIcon {...iconProps} />;
    }
    case "Date": {
      return <CalendarTodayRoundedIcon {...iconProps} />;
    }
    case "Select": {
      return <SelectFieldTypeIcon {...iconProps} />;
    }
    default: {
      return null;
    }
  }
};

export default MetricDataTypeIcon;
