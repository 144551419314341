import StatusChip from "../../../../shared/components/StatusChip";
import { DataImportState } from "../../../api/types/dataImportTypes";

interface Props {
  dataImportState: DataImportState;
}

const ImportDataStatusTag = ({ dataImportState }: Props) => {
  switch (dataImportState) {
    case "Created":
      return <StatusChip label="Draft" withDot color={(t) => t.palette.text.secondary} />;
    case "Parsed":
      return <StatusChip label="Parsed" withDot color={(t) => t.palette.info.main} />;
    case "InProgress":
      return <StatusChip label="In Progress" withDot color={(t) => t.palette.info.main} />;
    case "Applied":
      return <StatusChip label="Applied" withDot color={(t) => t.palette.success.main} />;
    case "AwaitingApplication":
      return <StatusChip label="Awaiting Application" withDot color={(t) => t.palette.success.main} />;
    case "RollbackCompleted":
      return <StatusChip label="Reverted" withDot color={(t) => t.palette.error.main} />;
    case "Discarded":
      return <StatusChip label="Discarded" withDot color={(t) => t.palette.error.main} />;
    case "RollbackFailed":
      return <StatusChip label="Rollback Failed" withDot color={(t) => t.palette.error.main} />;
    case "Failed":
      return <StatusChip label="Failed" withDot color={(t) => t.palette.error.main} />;
    default:
      return <StatusChip label="Unknown" withDot color={(t) => t.palette.text.secondary} />;
  }
};

export default ImportDataStatusTag;
