import { Button, Grid } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { useState } from "react";

interface Props {
  from: Date;
  to: Date;
  onApply: (range: [Date, Date]) => void;
  onCancel: () => void;
}

const DateRangeSelector = ({ from, to, onCancel, onApply }: Props) => {
  const [fromDate, setFromDate] = useState<Date>(from);
  const [toDate, setToDate] = useState<Date>(to);

  return (
    <Grid container display="grid" gridTemplateColumns="repeat(2,1fr)" px={1} py={2} gap={1} maxWidth="344px">
      <DesktopDatePicker
        value={fromDate}
        onChange={(date) => date && setFromDate(date)}
        shouldDisableDate={(date) => date > toDate}
        slotProps={{
          textField: { size: "small" },
        }}
      />
      <DesktopDatePicker
        value={toDate}
        onChange={(date) => date && setToDate(date)}
        shouldDisableDate={(date) => date < fromDate}
        slotProps={{
          textField: { size: "small" },
        }}
      />
      <Button variant="text" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="contained" onClick={() => onApply([fromDate, toDate])}>
        Apply
      </Button>
    </Grid>
  );
};

export default DateRangeSelector;
