import React, { PropsWithChildren, useContext } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";

interface ReportContextType {
  copyReports: (ids: string[]) => void;
  deleteReports: (ids: string[]) => void;
  duplicateReport: (report: ReportInfo) => void;
  shareReport: (report: ReportInfo) => void;
  generateApiReport: (report: ReportInfo) => void;
}

const UiReportsManagerContextContext = React.createContext<ReportContextType | undefined>(undefined);

export const UiReportsManagerContextProvider = ({ children, ...props }: PropsWithChildren<ReportContextType>) => {
  return <UiReportsManagerContextContext.Provider value={props}>{children}</UiReportsManagerContextContext.Provider>;
};

export const useUiReportsManagerContext = () => {
  const context = useContext(UiReportsManagerContextContext);
  return defined(context);
};
