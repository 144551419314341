import CloseIcon from "@mui/icons-material/CloseRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, IconButton, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem } from "@mui/material";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import HiddenIcon from "../../../../icons/HiddenIcon";
import VisibleIcon from "../../../../icons/VisibleIcon";
import FieldIcon from "../../../common/domain-icons/FieldIcon";
import FieldSourceBadge from "../../../entityFields/FieldSourceBadge";
import { useObjectLayoutContext } from "./ObjectLayoutContext";
import { LayoutField, removeFieldAction, toggleFieldVisibilityAction } from "./objectLayoutState";

interface Props {
  field: LayoutField;
  disableActions?: boolean;
}

const LayoutFieldItem = ({ field, disableActions }: Props) => {
  const { setLayoutState } = useObjectLayoutContext();
  const { sendNotification } = useNotificationContext();

  const handleToggleVisibility = () => {
    setLayoutState(toggleFieldVisibilityAction(field.id));
  };

  const handleRemove = () => {
    setLayoutState(removeFieldAction(field.id));
    sendNotification("Field removed from layout");
  };

  return (
    <MenuItem draggable sx={{ px: 0.8 }}>
      <ListItemIcon sx={{ pl: 0 }}>
        <DragIndicatorIcon sx={{ cursor: "grab", mr: 0.5 }} />
        <FieldIcon fieldType={field.type} color="action" />
      </ListItemIcon>
      <ListItemText sx={{ flexGrow: 1, ml: 1.5 }}>
        <TypographyTooltipEllipsis text={field.name} typographyProps={{ component: "span", pr: 4 }} />
      </ListItemText>
      <ListItemSecondaryAction>
        <Box pr={1} component="span">
          <FieldSourceBadge fieldSource={field.source} />
        </Box>
        <IconButton edge="end" sx={{ ml: 1 }} onClick={handleToggleVisibility} disabled={disableActions}>
          {field.isVisible ? <VisibleIcon /> : <HiddenIcon />}
        </IconButton>
        <IconButton edge="end" sx={{ ml: 1 }} onClick={handleRemove} disabled={disableActions}>
          <CloseIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </MenuItem>
  );
};

export default LayoutFieldItem;
