import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import ArchiveIcon from "../../../../../shared/icons/ArchiveIcon";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { Metric } from "../../../../api/types/portfolioMonitoringTypes";

interface Props {
  metric: Metric;
  onClose: () => void;
  onArchived: (metric: Metric) => void;
}

const deleteMetric = withErrorHandling(adminApi.deleteMetric);

const ArchiveMetricDialog = ({ metric, onClose, onArchived }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [saving, setSaving] = useState(false);

  const handleArchive = async () => {
    setSaving(true);
    const [, error] = await deleteMetric(metric.id);
    setSaving(false);

    if (error) {
      logError(error, "[ArchiveMetricDialog] deleteMetric");
      sendNotificationError("Could not archive metric");
      return;
    }

    sendNotification("Metric archived successfully");
    onArchived(metric);
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Archive Metric</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <ArchiveIcon sx={{ color: "dialogIcon", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              Move the metric to archive?
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              The metric will be archived and will no longer participate in the formation of requests for companies. All
              previous data will be preserved. If necessary, the metric can be restored from the archive for future use.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={saving} onClick={handleArchive}>
          Archive
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveMetricDialog;
