import { Grid, Stack, Typography } from "@mui/material";
import KeyValueColumn from "../../common/KeyValueColumn";
import { convertISODateTime } from "../../../../shared/utilities/dateUtils";

interface Props {
  lastChangedBy: string | undefined;
  lastChangedOn: string | undefined;
}

const PassthroughSetup = ({ lastChangedBy, lastChangedOn }: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Setup</Typography>
      <Grid container gap={5}>
        <Grid item>
          <KeyValueColumn title="Modified by:" value={lastChangedBy || "-"} />
        </Grid>
        <Grid item>
          <KeyValueColumn title="Modified at:" value={lastChangedOn ? convertISODateTime(lastChangedOn) : "-"} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PassthroughSetup;
