import { SvgIcon, SvgIconProps } from "@mui/material";

const DeleteFileIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 80 80" fill="none" {...props}>
    <path
      d="M8 0H72C76.4 0 80 3.6 80 8V72C80 76.4 76.4 80 72 80H8C3.6 80 0 76.4 0 72V8C0 3.6 3.6 0 8 0Z"
      fill="white"
    />
    <path d="M43 44H27V49H43V44Z" fill="#C7DBE5" />
    <path
      d="M63.6629 68.2251H15.4629V8.7251H51.3629L63.6629 21.0251V68.2251ZM20.4629 63.2251H58.5629V23.1251L49.2629 13.8251H20.4629V63.2251Z"
      fill="#C7DBE5"
    />
    <path d="M61.1625 26.3251H46.5625V11.2251H51.5625V21.3251H61.1625V26.3251Z" fill="#C7DBE5" />
    <path d="M63.0009 48.2037L45.4648 65.7397L49.0003 69.2752L66.5364 51.7392L63.0009 48.2037Z" fill="#F44336" />
    <path d="M49.0003 48.2035L45.4648 51.739L63.0009 69.2751L66.5364 65.7396L49.0003 48.2035Z" fill="#F44336" />
    <path d="M52.4621 34.125H26.6621V39.125H52.4621V34.125Z" fill="#C7DBE5" />
  </SvgIcon>
);

export default DeleteFileIcon;
