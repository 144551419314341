import { Stack, Typography } from "@mui/material";
import { CurrencyEntityFieldConfiguration, EntityFieldConfiguration } from "../../../../../api/types/objectTypes";
import NumberPrecisionSelect from "../../../../common/NumberPrecisionSelect";
import CurrencySelect from "./CurrencySelect";

interface Props {
  fieldConfiguration: CurrencyEntityFieldConfiguration;
  readOnly?: boolean;
  onChange: (fieldConfiguration: EntityFieldConfiguration) => void;
}

const MoneyFieldConfigEditor = ({ fieldConfiguration, readOnly, onChange }: Props) => {
  return (
    <Stack spacing={1} pt={2}>
      <Typography variant="subtitle2">Formatting</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography color="text.secondary">Currency</Typography>
        <CurrencySelect
          code={fieldConfiguration.currencyCode || "USD"}
          onChange={(currencyCode) => onChange({ ...fieldConfiguration, currencyCode })}
          sx={{ width: "12rem" }}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography color="text.secondary">Precision</Typography>
        <NumberPrecisionSelect
          maxPrecision={6}
          precision={fieldConfiguration.precision || 0}
          disabled={readOnly}
          onPrecisionChange={(precision) => onChange({ ...fieldConfiguration, precision })}
          sx={{ width: "12rem" }}
        />
      </Stack>
    </Stack>
  );
};

export default MoneyFieldConfigEditor;
