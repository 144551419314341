import { Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import CenteredWrapper from "../../../shared/components/CenteredWrapper";
import InlineLoader from "../../../shared/components/inlineLoader/InlineLoader";

interface Props {
  isLoading?: boolean;
}

const EntitySection = ({ isLoading, children }: PropsWithChildren<Props>) => (
  <Stack spacing={1.5} border={1} borderRadius="4px" borderColor="divider" bgcolor="background.default">
    {isLoading ? (
      <CenteredWrapper>
        <InlineLoader />
      </CenteredWrapper>
    ) : (
      children
    )}
  </Stack>
);

export default EntitySection;
