import { createSvgIcon } from "@mui/material";

const AnalyticsIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor">
    <path d="M4.16667 17.5C3.70833 17.5 3.31597 17.3368 2.98958 17.0104C2.66319 16.684 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66319 3.31597 2.98958 2.98958C3.31597 2.66319 3.70833 2.5 4.16667 2.5H15.8333C16.2917 2.5 16.684 2.66319 17.0104 2.98958C17.3368 3.31597 17.5 3.70833 17.5 4.16667V15.8333C17.5 16.2917 17.3368 16.684 17.0104 17.0104C16.684 17.3368 16.2917 17.5 15.8333 17.5H4.16667ZM4.16667 15.8333H15.8333V4.16667H4.16667V15.8333ZM6.66667 8.33333C6.43056 8.33333 6.23264 8.41319 6.07292 8.57292C5.91319 8.73264 5.83333 8.93056 5.83333 9.16667V13.3333C5.83333 13.5694 5.91319 13.7674 6.07292 13.9271C6.23264 14.0868 6.43056 14.1667 6.66667 14.1667C6.90278 14.1667 7.10069 14.0868 7.26042 13.9271C7.42014 13.7674 7.5 13.5694 7.5 13.3333V9.16667C7.5 8.93056 7.42014 8.73264 7.26042 8.57292C7.10069 8.41319 6.90278 8.33333 6.66667 8.33333ZM10 5.83333C9.76389 5.83333 9.56597 5.91319 9.40625 6.07292C9.24653 6.23264 9.16667 6.43056 9.16667 6.66667V13.3333C9.16667 13.5694 9.24653 13.7674 9.40625 13.9271C9.56597 14.0868 9.76389 14.1667 10 14.1667C10.2361 14.1667 10.434 14.0868 10.5938 13.9271C10.7535 13.7674 10.8333 13.5694 10.8333 13.3333V6.66667C10.8333 6.43056 10.7535 6.23264 10.5938 6.07292C10.434 5.91319 10.2361 5.83333 10 5.83333ZM13.3333 10.8333C13.0972 10.8333 12.8993 10.9132 12.7396 11.0729C12.5799 11.2326 12.5 11.4306 12.5 11.6667V13.3333C12.5 13.5694 12.5799 13.7674 12.7396 13.9271C12.8993 14.0868 13.0972 14.1667 13.3333 14.1667C13.5694 14.1667 13.7674 14.0868 13.9271 13.9271C14.0868 13.7674 14.1667 13.5694 14.1667 13.3333V11.6667C14.1667 11.4306 14.0868 11.2326 13.9271 11.0729C13.7674 10.9132 13.5694 10.8333 13.3333 10.8333Z" />
  </svg>,
  "AnalyticsIcon"
);

export default AnalyticsIcon;
