import { Box, Chip, Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Stack } from "@mui/system";
import {
  DateCalendar,
  DateField,
  DateRange,
  DateRangeCalendar,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { endOfDay, formatISO, isSameDay, isValid, parseISO } from "date-fns";
import { useState } from "react";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import OperatorSelect from "../OperatorSelect";
import { DateFilter, DateFilterOperator, FilterValue } from "../filterTypes";
import { DateFilterShortcut, dateFilterOperatorOptions, dateFilterShortcuts } from "../handlers/dateFilter";
import EmptyFilterBody from "./EmptyFilterBody";

interface Props {
  filter: DateFilter;
  onUpdateValue: (newValue: FilterValue) => void;
}

type ToggleValue = "exact_date" | "custom_range";

const isEmptyFilterBody = (operator: DateFilterOperator) => ["empty", "not_empty"].includes(operator);

const getOperatorOptions = (filter: DateFilter) =>
  filter.operatorOptions !== undefined
    ? dateFilterOperatorOptions.filter((option) => filter.operatorOptions?.includes(option.value))
    : dateFilterOperatorOptions;

const dateRangeToStringRange = ([dateFrom, dateTo]: DateRange<Date>): [string, string] => [
  dateFrom ? formatISO(dateFrom) : "",
  dateTo ? formatISO(dateTo) : "",
];

const stringRangeToDateRange = (range: [string, string] | undefined): DateRange<Date> => {
  if (!range) {
    return [null, null];
  }
  const [dateFromString, dateToString] = range;
  const dateFrom = parseISO(dateFromString);
  const dateTo = parseISO(dateToString);
  return [isValid(dateFrom) ? dateFrom : null, isValid(dateTo) ? dateTo : null];
};

const DateFilterEditor = ({ filter, onUpdateValue }: Props) => {
  const [selectedRange, setSelectedRange] = useState<DateRange<Date>>(stringRangeToDateRange(filter.value.range));

  const [toggleValue, setToggleValue] = useState<ToggleValue>(() => {
    const [dateFrom, dateTo] = stringRangeToDateRange(filter.value.range);
    if (!dateFrom || !dateTo || isSameDay(dateFrom, dateTo)) {
      return "exact_date";
    }
    return "custom_range";
  });

  const updateDateValuesInFilter = useDebounce((dateRange: DateRange<Date>) => {
    onUpdateValue({
      operator: filter.value.operator,
      range: dateRangeToStringRange(dateRange),
    });
  }, 500);

  const handleOperatorChange = (operator: DateFilterOperator) => {
    setSelectedRange([null, null]);
    onUpdateValue({ operator, range: ["", ""] });
  };

  const handleModeToggle = (_: React.MouseEvent, value: ToggleValue) => {
    setToggleValue(value);
    setSelectedRange([null, null]);
    onUpdateValue({ operator: "range", range: ["", ""] });
  };

  const handleDateChange = (value: Date | null) => {
    setSelectedRange([value, value]);
    updateDateValuesInFilter([value, value ? endOfDay(value) : null]);
  };

  const handleDateRangeChange = (value: DateRange<Date>) => {
    setSelectedRange(value);
    updateDateValuesInFilter([value[0], value[1] ? endOfDay(value[1]) : null]);
  };

  const handleShortcutClick = (shortcut: DateFilterShortcut) => () => handleDateRangeChange(shortcut.getValue());

  // DateRangeCalendar cannot handle invalid dates in the range
  const validSelectedRange = selectedRange.map((date) =>
    date !== null && isValid(date) ? date : null
  ) as DateRange<Date>;

  const emptyFilterBody = isEmptyFilterBody(filter.value.operator);
  const operatorOptions = getOperatorOptions(filter);

  return (
    <Stack spacing={2} p={2}>
      <OperatorSelect<DateFilterOperator>
        filterName={filter.name}
        options={operatorOptions}
        value={filter.value.operator}
        onChange={handleOperatorChange}
      />
      {emptyFilterBody && (
        <Box sx={(t) => ({ width: t.spacing(47.5) })}>
          <EmptyFilterBody />
        </Box>
      )}
      {!emptyFilterBody && (
        <Stack spacing={2}>
          <ToggleButtonGroup color="primary" fullWidth exclusive value={toggleValue} onChange={handleModeToggle}>
            <ToggleButton value="exact_date">Exact Date</ToggleButton>
            <ToggleButton value="custom_range">Custom Range</ToggleButton>
          </ToggleButtonGroup>
          <Divider />
          {toggleValue === "exact_date" && (
            <Stack spacing={1}>
              <DateField<Date> value={selectedRange[0]} onChange={handleDateChange} clearable />
              <DateCalendar<Date> value={validSelectedRange[0]} onChange={handleDateChange} />
            </Stack>
          )}
          {toggleValue === "custom_range" && (
            <Stack direction="row" spacing={2}>
              <Stack spacing={1.5}>
                {dateFilterShortcuts.map((shortcut) => (
                  <Chip key={shortcut.label} label={shortcut.label} onClick={handleShortcutClick(shortcut)} />
                ))}
              </Stack>
              <Divider flexItem orientation="vertical" />
              <Stack spacing={1}>
                <SingleInputDateRangeField<Date> value={selectedRange} onChange={handleDateRangeChange} clearable />
                <DateRangeCalendar<Date> value={validSelectedRange} onChange={handleDateRangeChange} calendars={1} />
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default DateFilterEditor;
