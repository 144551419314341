import ErrorIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import { LogSeverity } from "../../../api/types/logTypes";

const LogStatusIcon = ({ severity }: { severity: LogSeverity }) => {
  switch (severity) {
    case "Information":
      return <CheckIcon color="primary" />;
    case "Warning":
      return <WarningIcon color="warning" />;
    case "Error":
      return <ErrorIcon color="error" />;
    default:
      return null;
  }
};

export default LogStatusIcon;
