import { defined } from "../../../../../shared/utilities/typeHelper";
import {
  arrayValidator,
  combineValidators,
  distinctValuesValidator,
  maxCharactersValidator,
  nonEmptyArrayValidator,
  requiredValidator,
  uniqueValidator,
  validResult,
} from "../../../../../shared/utilities/validators";
import { EntityOptionFieldType } from "../../../../api/types/objectTypes";
import {
  allNumberDisplayStyles,
  CreateMetricRequest,
  Metric,
  MetricAggregationType,
  MetricDataType,
  MetricExtensionConfiguration,
  MetricFormatConfiguration,
  MetricValueSourceConfiguration,
  NumberDisplayStyle,
  UpdateMetricRequest,
} from "../../../../api/types/portfolioMonitoringTypes";
import { formatMetricMoneyValue, formatMetricNumberValue } from "../../../../utilities/formatters";

export interface MetricEditForm {
  dataType: MetricDataType;
  name: string;
  description: string;
  category: string;
  aggregationType: MetricAggregationType;
  valueSource: MetricValueSource;
  valuesGlobalDictionaryName: EntityOptionFieldType;
  allowedValuesMemo: string;
  numberDisplayStyle: NumberDisplayStyle | undefined;
  numberOfDecimals: number;
  isExtended: boolean;
  extensionName: string;
  extensionSource: MetricExtensionSource;
  extensionGlobalDictionaryName: EntityOptionFieldType;
  allowedExtensionValuesMemo: string;
}

export type MetricValueSource = "common_dictionary" | "static_values";
export type MetricExtensionSource = "common_dictionary" | "static_values" | "custom";

export interface MetricEditorFormState extends MetricEditForm {
  isFormValid: boolean;
  validationErrors: ValidationErrors;
  touchedFields: MetricFormField[];
}

interface ValidationErrors {
  name?: string;
  extensionName?: string;
  allowedValuesMemo?: string;
  allowedExtensionValuesMemo?: string;
}

export type MetricFormField = keyof MetricEditForm;

export const isValueSourceConfigurationEnabled = (dataType: MetricDataType): boolean => dataType === "Select";

export const isAggregationTypeEnabled = (dataType: MetricDataType): boolean => ["Number", "Money"].includes(dataType);

export const isFormatConfigurationEnabled = (dataType: MetricDataType): boolean =>
  ["Number", "Money"].includes(dataType);

export const getAllowedNumberStyles = (dataType: MetricDataType): readonly NumberDisplayStyle[] => {
  switch (dataType) {
    case "Number":
      return allNumberDisplayStyles;
    case "Money":
      return ["Thousands", "Millions", "Billions"];
    default:
      return [];
  }
};

export const valueSourceOptions: readonly { label: string; value: MetricValueSource }[] = [
  { label: "Common dictionary", value: "common_dictionary" },
  { label: "Static values", value: "static_values" },
];

export const extensionSourceOptions: readonly { label: string; value: MetricExtensionSource }[] = [
  { label: "Common dictionary", value: "common_dictionary" },
  { label: "Static values", value: "static_values" },
  { label: "Company-specific values", value: "custom" },
];

export const commonDictionaryOptions: readonly { label: string; value: EntityOptionFieldType }[] = [
  { label: "Countries", value: "Country" },
  { label: "Currencies", value: "Currency" },
  { label: "Geographies", value: "Geography" },
  { label: "Industries", value: "IndustryOrSector" },
  { label: "States", value: "State" },
];

const getAllowedValuesFromMemoValue = (memoValue: string) =>
  memoValue
    .split("\n")
    .map((value) => value.trim())
    .filter(Boolean);

const validateExtensionName = combineValidators(requiredValidator, maxCharactersValidator(50));

const validateAllowedValues = combineValidators(
  nonEmptyArrayValidator("Options cannot be empty"),
  distinctValuesValidator("Options must be unique"),
  arrayValidator(maxCharactersValidator(50), "Invalid option")
);

export const validateForm = (form: MetricEditorFormState, otherMetricNames: string[]): MetricEditorFormState => {
  const validateName = combineValidators(
    requiredValidator,
    uniqueValidator("A metric with this name already exists", otherMetricNames)
  );

  const nameResult = validateName(form.name);

  const allowedValuesResult =
    form.valueSource === "static_values"
      ? validateAllowedValues(getAllowedValuesFromMemoValue(form.allowedValuesMemo))
      : validResult();

  const extensionNameResult = form.isExtended ? validateExtensionName(form.extensionName) : validResult();

  const allowedExtensionValuesResult =
    form.isExtended && form.extensionSource === "static_values"
      ? validateAllowedValues(getAllowedValuesFromMemoValue(form.allowedExtensionValuesMemo))
      : validResult();

  const validationErrors: ValidationErrors = {
    name: form.touchedFields.includes("name") ? nameResult.error : undefined,
    extensionName: form.touchedFields.includes("extensionName") ? extensionNameResult.error : undefined,
    allowedValuesMemo: form.touchedFields.includes("allowedValuesMemo") ? allowedValuesResult.error : undefined,
    allowedExtensionValuesMemo: form.touchedFields.includes("allowedExtensionValuesMemo")
      ? allowedExtensionValuesResult.error
      : undefined,
  };

  return {
    ...form,
    validationErrors,
    isFormValid:
      nameResult.isValid &&
      allowedValuesResult.isValid &&
      extensionNameResult.isValid &&
      allowedExtensionValuesResult.isValid,
  };
};

const getValueSourceConfiguration = (form: MetricEditForm): MetricValueSourceConfiguration | undefined => {
  if (!isValueSourceConfigurationEnabled(form.dataType)) {
    return undefined;
  }

  switch (form.valueSource) {
    case "common_dictionary":
      return {
        globalDictionaryName: form.valuesGlobalDictionaryName,
      };
    case "static_values":
      return {
        allowedValues: getAllowedValuesFromMemoValue(form.allowedValuesMemo),
      };
    default:
      return undefined;
  }
};

const getDefaultFormatConfiguration = (form: MetricEditForm): MetricFormatConfiguration | undefined => {
  if (!isFormatConfigurationEnabled(form.dataType)) {
    return undefined;
  }

  return {
    numberDisplayStyle: form.numberDisplayStyle,
    numberOfDecimals: form.numberOfDecimals,
  };
};

const getExtensionConfiguration = (form: MetricEditForm): MetricExtensionConfiguration | undefined => {
  if (!form.isExtended) {
    return undefined;
  }

  switch (form.extensionSource) {
    case "common_dictionary":
      return {
        extensionName: form.extensionName,
        globalDictionaryName: form.extensionGlobalDictionaryName,
      };
    case "static_values":
      return {
        extensionName: form.extensionName,
        allowedValues: getAllowedValuesFromMemoValue(form.allowedExtensionValuesMemo),
      };
    case "custom": {
      return {
        extensionName: form.extensionName,
      };
    }
    default:
      return undefined;
  }
};

export const mapFormToCreateMetricRequest = (form: MetricEditForm): CreateMetricRequest => ({
  dataType: form.dataType,
  name: form.name.trim(),
  description: form.description.trim() || undefined,
  category: form.category.trim() || undefined,
  aggregationType: isAggregationTypeEnabled(form.dataType) ? form.aggregationType : "PointInTime",
  valueSource: getValueSourceConfiguration(form),
  defaultFormat: getDefaultFormatConfiguration(form),
  extensionConfiguration: getExtensionConfiguration(form),
});

export const mapFormToUpdateMetricRequest = (form: MetricEditForm): UpdateMetricRequest => ({
  name: form.name.trim(),
  description: form.description.trim() || undefined,
  category: form.category.trim() || undefined,
  aggregationType: isAggregationTypeEnabled(form.dataType) ? form.aggregationType : "PointInTime",
  valueSource: getValueSourceConfiguration(form),
  defaultFormat: getDefaultFormatConfiguration(form),
  extensionConfiguration: getExtensionConfiguration(form),
});

export const getInitialMetricEditorFormState = (dataType: MetricDataType): MetricEditorFormState => ({
  dataType,
  name: "",
  description: "",
  category: "",
  aggregationType: "PointInTime",
  valueSource: "common_dictionary",
  valuesGlobalDictionaryName: defined(commonDictionaryOptions[0]).value,
  allowedValuesMemo: "",
  numberDisplayStyle: undefined,
  numberOfDecimals: 2,
  isFormValid: false,
  validationErrors: {},
  touchedFields: [],
  isExtended: false,
  extensionName: "",
  extensionSource: "common_dictionary",
  extensionGlobalDictionaryName: defined(commonDictionaryOptions[0]).value,
  allowedExtensionValuesMemo: "",
});

export const getInitialMetricEditorFormStateFromMetric = (metric: Metric): MetricEditorFormState => ({
  dataType: metric.dataType,
  name: metric.name,
  description: metric.description ?? "",
  category: metric.category ?? "",
  aggregationType: metric.aggregationType,
  valueSource: metric.valueSource?.allowedValues?.length ? "static_values" : "common_dictionary",
  valuesGlobalDictionaryName: metric.valueSource?.globalDictionaryName ?? defined(commonDictionaryOptions[0]).value,
  allowedValuesMemo: metric.valueSource?.allowedValues?.join("\n") ?? "",
  numberDisplayStyle: metric.defaultFormat?.numberDisplayStyle,
  numberOfDecimals: metric.defaultFormat?.numberOfDecimals ?? 2,
  isFormValid: true,
  validationErrors: {},
  touchedFields: [],
  isExtended: Boolean(metric.extensionConfiguration?.extensionName),
  extensionName: metric.extensionConfiguration?.extensionName ?? "",
  extensionSource:
    !metric.extensionConfiguration?.extensionName || Boolean(metric.extensionConfiguration?.globalDictionaryName)
      ? "common_dictionary"
      : metric.extensionConfiguration?.allowedValues
        ? "static_values"
        : "custom",
  extensionGlobalDictionaryName:
    metric.extensionConfiguration?.globalDictionaryName ?? defined(commonDictionaryOptions[0]).value,
  allowedExtensionValuesMemo: (metric.extensionConfiguration?.allowedValues ?? []).join("\n"),
});

export const formatPreviewValue = (form: MetricEditForm): string => {
  const getSampleValue = () => {
    const sampleValue = 2345.678910234567;
    switch (form.numberDisplayStyle) {
      case "Percentage":
        return sampleValue / 10_000;
      case "Multiple":
        return sampleValue / 1000;
      case "Thousands":
        return sampleValue * 10;
      case "Millions":
        return sampleValue * 10_000;
      case "Billions":
        return sampleValue * 10_000_000;
      default:
        return sampleValue;
    }
  };

  const format: MetricFormatConfiguration = {
    numberDisplayStyle: form.numberDisplayStyle,
    numberOfDecimals: form.numberOfDecimals,
  };

  switch (form.dataType) {
    case "Number": {
      return formatMetricNumberValue(getSampleValue(), format);
    }
    case "Money": {
      return formatMetricMoneyValue(getSampleValue(), "USD", format);
    }
    default: {
      return "";
    }
  }
};
