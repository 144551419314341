import CollapseIcon from "@mui/icons-material/ArrowDropDownRounded";
import ExpandIcon from "@mui/icons-material/ArrowRightRounded";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { AggregatedDocumentViews } from "../../../../../api/types/documentActivityTypes";
import PageViewsChart from "../../../investor-portal/document-activity/PageViewsChart";

interface Props {
  aggregatedDocumentViews: AggregatedDocumentViews;
}

const TotalTimePerPageSection = ({ aggregatedDocumentViews }: Props) => {
  const [expanded, setExpanded] = useState(true);

  if (Object.keys(aggregatedDocumentViews.pageViews).length === 0) {
    return null;
  }

  return (
    <Box pt={4}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton color="secondary">
          {expanded ? (
            <CollapseIcon onClick={() => setExpanded(false)} />
          ) : (
            <ExpandIcon onClick={() => setExpanded(true)} />
          )}
        </IconButton>
        <Typography variant="subtitle2" gutterBottom={false}>
          Total time per page
        </Typography>
      </Stack>
      {expanded && (
        <Box display="flex" justifyContent="center" height="12rem" width="100%">
          <PageViewsChart pageViews={aggregatedDocumentViews.pageViews} />
        </Box>
      )}
    </Box>
  );
};

export default TotalTimePerPageSection;
