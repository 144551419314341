import { useState } from "react";
import { Dialog } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import ConsentDialogBody from "./ConsentDialogBody";
import { LoadingButton } from "@mui/lab";
import ConsentAgreementLabel from "./ConsentAgreementLabel";
import { useLocalization } from "../../hooks/useLocalization";
import ConsentDialogContent from "./ConsentDialogContent";
import { IConsentAgreement } from "./types";

interface Props {
  open: boolean;
  saving: boolean;
  logoUrl?: string | undefined;
  clientTitle?: string;
  buttonLabel: string;
  consentAgreement: IConsentAgreement;
  showNewsConsent: boolean;
  portalTitle: string;
  onAccept: (consentAgreement: IConsentAgreement, agreeToNews?: boolean) => void;
}

const ConsentAgreement = ({
  open,
  saving,
  logoUrl,
  clientTitle,
  buttonLabel,
  consentAgreement,
  showNewsConsent,
  portalTitle,
  onAccept,
}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { consent_agreement: pageLocale } = useLocalization();

  const [acceptAgreement, setAcceptAgreement] = useState(false);
  const [agreeToNews, setAgreeToNews] = useState<boolean | undefined>(showNewsConsent ? false : undefined);

  const handleAgreementAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptAgreement(event.target.checked);
  };

  const handleAgreeToNewsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeToNews(event.target.checked);
  };

  const handleAcceptClick = () => {
    onAccept?.(consentAgreement, agreeToNews);
    setAcceptAgreement(false);
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullScreen={fullScreen}
      sx={{
        "& .MuiBackdrop-root": { backdropFilter: "blur(2px)" },
      }}
      PaperProps={{
        sx: {
          maxWidth: theme.spacing(72.25),
        },
      }}
    >
      <ConsentDialogContent sx={{ minHeight: "50vh" }}>
        <ConsentDialogBody
          title={`${pageLocale.welcome_to} ${portalTitle}`}
          logoUrl={logoUrl}
          clientTitle={clientTitle}
          consentContent={consentAgreement.content}
          acceptFormGroup={
            <FormGroup sx={{ my: "1rem" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={saving}
                    color="primary"
                    checked={acceptAgreement || saving}
                    onChange={handleAgreementAccepted}
                  />
                }
                label={
                  <ConsentAgreementLabel
                    label={pageLocale.agree_to}
                    consentName={consentAgreement.name}
                    url={consentAgreement.url}
                  />
                }
              />
              {showNewsConsent && (
                <FormControlLabel
                  control={<Checkbox color="primary" checked={agreeToNews} onChange={handleAgreeToNewsChange} />}
                  label={<Typography>{pageLocale.agreeNewsUpdatesCheckbox}</Typography>}
                />
              )}
            </FormGroup>
          }
          saveButton={
            <LoadingButton
              disabled={!acceptAgreement || saving}
              fullWidth
              variant="contained"
              sx={{ height: "3rem" }}
              onClick={handleAcceptClick}
              loading={saving}
            >
              {buttonLabel}
            </LoadingButton>
          }
        />
      </ConsentDialogContent>
    </Dialog>
  );
};

export default ConsentAgreement;
