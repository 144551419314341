import CalendarIcon from "@mui/icons-material/CalendarTodayRounded";
import NumbersIcon from "@mui/icons-material/NumbersRounded";
import PersonIcon from "@mui/icons-material/PersonOutlineRounded";
import { Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import UserAvatar from "../../../../../shared/components/UserAvatar";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { InvestorChangeRequestDetails } from "../../../../api/types/changeRequestTypes";
import { useClientContext } from "../../../../context/ClientContext";
import CardAccountDetailsIcon from "../../../../icons/CardAccountDetailsIcon";
import CubeIcon from "../../../../icons/CubeIcon";
import { pageRoutes } from "../../../../routes";
import WorkflowStatusTag from "../WorkflowStatusTag";
import { formatWorkflowType, hasApprovalInfo } from "../changeRequestsFormatters";
import ChangeRequestAttribute from "./ChangeRequestAttribute";

interface Props {
  changeRequestDetails: InvestorChangeRequestDetails;
}

const ChangeRequestInfoSection = ({ changeRequestDetails }: Props) => {
  const { clientCode } = useClientContext();

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Request Info</Typography>
      <ChangeRequestAttribute Icon={NumbersIcon} label="Reference" value={changeRequestDetails.referenceCode} />
      <ChangeRequestAttribute
        Icon={CardAccountDetailsIcon}
        label="Investor"
        value={
          <Link
            component={RouterLink}
            to={`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investors}/${changeRequestDetails.investorId}/main`}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            {changeRequestDetails.investorName}
          </Link>
        }
      />
      <ChangeRequestAttribute
        Icon={PersonIcon}
        label="Requested by"
        value={
          <Stack spacing={1.25} direction="row" alignItems="center">
            <UserAvatar size="small" userName={changeRequestDetails.requestedByUserName} />
            <Typography>{changeRequestDetails.requestedByUserName}</Typography>
          </Stack>
        }
      />
      <ChangeRequestAttribute
        Icon={CalendarIcon}
        label="Requested at"
        value={convertISODateTime(changeRequestDetails.requestedAt)}
      />
      <ChangeRequestAttribute
        Icon={CubeIcon}
        label="Type"
        value={formatWorkflowType(changeRequestDetails.workflowType)}
      />
      <ChangeRequestAttribute
        Icon={NumbersIcon}
        label="Status"
        value={<WorkflowStatusTag workflowStatus={changeRequestDetails.workflowStatus} />}
      />
      <ChangeRequestAttribute
        Icon={PersonIcon}
        label="Reviewed by"
        value={
          hasApprovalInfo(changeRequestDetails.workflowStatus) &&
          changeRequestDetails.approvalStatusUpdatedByUserName && (
            <Stack spacing={1.25} direction="row" alignItems="center">
              <UserAvatar size="small" userName={changeRequestDetails.approvalStatusUpdatedByUserName} />
              <Typography>{changeRequestDetails.approvalStatusUpdatedByUserName}</Typography>
            </Stack>
          )
        }
      />
      <ChangeRequestAttribute
        Icon={CalendarIcon}
        label="Reviewed at"
        value={
          hasApprovalInfo(changeRequestDetails.workflowStatus) &&
          changeRequestDetails.approvalStatusUpdatedAt &&
          convertISODateTime(changeRequestDetails.approvalStatusUpdatedAt)
        }
      />
    </Stack>
  );
};

export default ChangeRequestInfoSection;
