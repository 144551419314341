import { Autocomplete, ListItem, ListItemSecondaryAction, TextField, Typography } from "@mui/material";
import CloseIconButton from "../../../../shared/components/CloseIconButton";
import { stringComparerBy } from "../../../../shared/utilities/arrayHelper";
import { TableColumnDefinition } from "./columnTypes";

interface Props {
  items: TableColumnDefinition[];
  onAdd: (columnId: string) => void;
  onCancel: () => void;
}

const AddColumnSelect = ({ items, onAdd, onCancel }: Props) => {
  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      e.preventDefault();
      onCancel();
    }
  };

  return (
    <ListItem>
      <Autocomplete
        fullWidth
        openOnFocus
        options={items.sort(stringComparerBy((i) => i.title))}
        getOptionLabel={({ title }) => title}
        onChange={(_, item) => item && onAdd(item.id)}
        renderInput={(inputProps) => (
          <TextField {...inputProps} autoFocus placeholder="Type column name..." onKeyDown={handleInputKeyDown} />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <Typography>{option.title}</Typography>
          </ListItem>
        )}
      />
      <ListItemSecondaryAction>
        <CloseIconButton onClick={onCancel} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AddColumnSelect;
