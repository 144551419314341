import AddIcon from "@mui/icons-material/AddRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { createApiResponse, withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import { JobProgress } from "../../../../../../../shared/api/types";
import AutocompleteCreatable from "../../../../../../../shared/components/AutocompleteCreatable";
import DataLoadingFailed from "../../../../../../../shared/components/DataLoadingFailed";
import SearchField from "../../../../../../../shared/components/inputs/SearchField";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../../shared/logging";
import { stringComparerBy } from "../../../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../../../api/adminApi";
import { Fundraising, FundraisingAccessItem } from "../../../../../../api/types/fundraisingTypes";
import AsyncOperationProgress from "../../../../../common/AsyncOperationProgress";
import { SelectOption } from "../../../../../common/filters/filterTypes";
import RecordCounter from "../../../../../common/filters/RecordCounter";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import AddFundraisingAccessDialog from "./AddFundraisingAccessDialog";
import CreateFundDialog from "./CreateFundDialog";
import EditFundraisingAccessDialog from "./EditFundraisingAccessDialog";
import FundraisingAccessGrid from "./FundraisingAccessGrid";
import { FundraisingAccessGridContextProvider } from "./FundraisingAccessGridContext";
import { applySearchFilter } from "./fundraisingAccessGridDataProvider";
import RemoveFundraisingAccessDialog from "./RemoveFundraisingAccessDialog";

interface DialogState {
  openDialog?: "create_fund" | "edit_access" | "add_access" | "remove_access";
  editedAccessItem?: FundraisingAccessItem;
}

interface AsyncOperationState {
  asyncOperationId: string;
  jobProgress: JobProgress | undefined;
}

const updateFundraising = withErrorHandling(adminApi.updateFundraising);
const getJobProgress = withErrorHandling(adminApi.getFundraisingJobProgress);

const createSelectedOption = (fundraising: Fundraising) =>
  fundraising.fund ? { value: fundraising.fund.id, label: fundraising.fund.name } : null;

const FundraisingAccessConfig = () => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const { fundraisingCategories, isContentEditable, fundraising, onUpdated, onConfirmSave, refreshFundraising } =
    useFundraisingDetailsPageContext();

  const [selectedFund, setSelectedFund] = useState<SelectOption | null>(createSelectedOption(fundraising));

  const [isSaving, setSaving] = useState(false);
  const [dialogState, setDialogState] = useState<DialogState>({});
  const [searchValue, setSearchValue] = useState("");
  const [asyncOperation, setAsyncOperation] = useState<AsyncOperationState>();

  const getAccessRows = useCallback(
    () =>
      fundraisingCategories.length > 0 && selectedFund
        ? adminApi.getFundraisingAccess(selectedFund.value)
        : Promise.resolve(createApiResponse([])),
    [fundraisingCategories, selectedFund]
  );

  const getFundraisings = useCallback(() => adminApi.getFundraisings(["Draft", "Preview", "Live"]), []);

  const getFunds = useCallback(
    async () => adminApi.searchFunds({ fieldIds: ["Stage"], includeInvestorCount: false }),
    []
  );

  const [rows, accessRowsError, { isFetching: isFetchingAccessRows, fetch: fetchAccessRows }] = useFetch(getAccessRows);

  const [fundraisings, fundraisingsError, { isFetching: isFetchingFundraisings }] = useFetch(getFundraisings);

  const [fundsResp, fundsError, { isFetching: isFetchingFunds, setData: setFunds }] = useFetch(getFunds);

  const filteredRows = useMemo(() => applySearchFilter(searchValue, rows ?? []), [searchValue, rows]);

  const usedFundIds = useMemo(
    () => (fundraisings ?? []).filter((f) => f.id !== fundraising.id).map((r) => r.fundId),
    [fundraising.id, fundraisings]
  );

  const fundOptions = useMemo(() => {
    if (!fundsResp?.items) {
      return [];
    }

    return (fundsResp?.items ?? [])
      .filter((f) => f.fieldValues["Stage"] === "Fundraising" && !usedFundIds.includes(f.id))
      .map((f) => ({ value: f.id, label: f.name }))
      .sort(stringComparerBy((f) => f.label));
  }, [fundsResp?.items, usedFundIds]);

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const save = async () => {
    setSaving(true);
    const [updatedFundraising, error] = await updateFundraising(fundraising.id, { fundId: selectedFund?.value });
    setSaving(false);

    if (error) {
      logError(error, "[FundraisingAccessConfig]");
      sendNotificationError("Could not update fundraising");
    } else {
      sendNotification("Fundraising updated successfully");
      onUpdated(updatedFundraising);
    }
  };

  const handleSave = () => onConfirmSave(save);

  const handleReset = () => {
    setSelectedFund(createSelectedOption(fundraising));
  };

  const handleSavedWithNewFund = (updatedFundraising: Fundraising) => {
    setFunds((prev) => {
      if (!prev) {
        return prev;
      }

      const updatedFund = updatedFundraising.fund;
      if (!updatedFund) {
        return prev;
      }

      return {
        ...prev,
        items: [...prev.items, { id: updatedFund.id, name: updatedFund.name, fieldValues: { Stage: "Fundraising" } }],
      };
    });

    setSelectedFund(createSelectedOption(updatedFundraising));

    onUpdated(updatedFundraising);
  };

  const handleAsyncOperationStart = (asyncOperationId: string) => {
    setAsyncOperation({ asyncOperationId, jobProgress: undefined });
  };

  const handleUpdateJobProgress = async () => {
    if (!asyncOperation) {
      return;
    }

    const [jobProgress, error] = await getJobProgress(asyncOperation.asyncOperationId);
    if (error) {
      logError(error, "[FundraisingAccessConfig]");
      sendNotificationError("Could not retrieve operation progress");
      setTimeout(() => setAsyncOperation(undefined));
      return;
    }

    setAsyncOperation((prev) => (prev ? { ...prev, jobProgress } : undefined));

    if (jobProgress.finished) {
      setTimeout(() => {
        setAsyncOperation(undefined);
        refreshFundraising();
        fetchAccessRows();
      }, 1000);
    }
  };

  const handleEditAccess = (editedAccessItem: FundraisingAccessItem) => {
    setDialogState({ openDialog: "edit_access", editedAccessItem });
  };

  const handleRemoveAccess = (editedAccessItem: FundraisingAccessItem) => {
    setDialogState({ openDialog: "remove_access", editedAccessItem });
  };

  const handleAddAccess = () => {
    setDialogState({ openDialog: "add_access" });
  };

  if (asyncOperation) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <AsyncOperationProgress
          title="Please wait, investor and contacts are being copied"
          showStepDetails
          jobProgress={asyncOperation.jobProgress}
          onUpdateProgress={handleUpdateJobProgress}
        />
      </Box>
    );
  }

  const isEdited = selectedFund?.value !== fundraising.fund?.id;
  const isValid = fundraising.status === "Draft" || Boolean(selectedFund);
  const showValidationError = isEdited && !isValid;

  const fetchError = accessRowsError || fundraisingsError || fundsError;
  const isFetching = isFetchingAccessRows || isFetchingFunds || isFetchingFundraisings;

  const isFundSelectionDisabled =
    !isContentEditable || fundraisingCategories.length === 0 || ["Live", "Completed"].includes(fundraising.status);

  return (
    <Stack spacing={2.5} flex={1} pt={4}>
      <Box>
        <AutocompleteCreatable
          withLazyRendering
          noItemsText="No funds found"
          onCreateOption={() => setDialogState({ openDialog: "create_fund" })}
          openOnFocus
          fullWidth
          disabled={isFundSelectionDisabled}
          options={fundOptions}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fund"
              error={showValidationError}
              helperText={showValidationError ? "Fund is required" : ""}
            />
          )}
          value={selectedFund}
          onChange={(_, newValue) => {
            if (newValue) {
              setSelectedFund(newValue);
            }
          }}
        />

        {isContentEditable && (
          <Stack direction="row" spacing={1} pt={2}>
            <LoadingButton variant="contained" loading={isSaving} onClick={handleSave} disabled={!isEdited || !isValid}>
              Save
            </LoadingButton>
            <Button variant="text" color="secondary" onClick={handleReset} disabled={!isEdited}>
              Cancel
            </Button>
          </Stack>
        )}
      </Box>

      <FundraisingAccessGridContextProvider onEditAccess={handleEditAccess} onRemoveAccess={handleRemoveAccess}>
        <Stack spacing={2} flex={1} pb={2}>
          <Stack pt={2} spacing={0.5}>
            <Typography variant="subtitle1">Investors/Contacts</Typography>
            <Typography color="text.secondary">
              The list of investor contacts who can access the fundraising is determined by the selected fund, portal
              role, and assigned access categories.
            </Typography>
          </Stack>

          <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
            <RecordCounter records={filteredRows.length} total={(rows ?? []).length} hide={isFetching} />
            <Stack direction="row" spacing={1} alignItems="center">
              <SearchField
                initialValue={searchValue}
                onSearch={handleSearch}
                debounceTimeMs={300}
                disabled={isFetching || rows?.length == 0}
              />
              {isContentEditable && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAddAccess}
                  disabled={isFetching || !selectedFund}
                >
                  Add New
                </Button>
              )}
            </Stack>
          </Box>
          {!fetchError && <FundraisingAccessGrid rows={filteredRows} isLoading={isFetching} />}
          {fetchError && <DataLoadingFailed bgColor="none" />}
        </Stack>
      </FundraisingAccessGridContextProvider>

      {dialogState.openDialog === "create_fund" && (
        <CreateFundDialog
          allFunds={fundsResp?.items ?? []}
          onSubmit={handleSavedWithNewFund}
          onAsyncOperationStart={handleAsyncOperationStart}
          onClose={() => setDialogState({})}
        />
      )}

      {dialogState.openDialog === "edit_access" && dialogState.editedAccessItem && selectedFund?.value && (
        <EditFundraisingAccessDialog
          editedAccessItem={dialogState.editedAccessItem}
          onSubmit={fetchAccessRows}
          onClose={() => setDialogState({})}
        />
      )}

      {dialogState.openDialog === "remove_access" && dialogState.editedAccessItem && selectedFund?.value && rows && (
        <RemoveFundraisingAccessDialog
          editedAccessItem={dialogState.editedAccessItem}
          fundInvestorsWithContactCount={
            rows.filter((r) => r.contactId === dialogState.editedAccessItem?.contactId).length ?? 0
          }
          onSubmit={fetchAccessRows}
          onClose={() => setDialogState({})}
        />
      )}

      {dialogState.openDialog === "add_access" && selectedFund?.value && (
        <AddFundraisingAccessDialog
          fundId={selectedFund.value}
          onSubmit={fetchAccessRows}
          onClose={() => setDialogState({})}
        />
      )}
    </Stack>
  );
};

export default FundraisingAccessConfig;
