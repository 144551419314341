import ImportDataIcon from "@mui/icons-material/ExitToAppRounded";
import { Avatar, Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { MouseEventHandler, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExcelIcon from "../../../../shared/icons/fileFormats/ExcelIcon";
import PassthroughLogo from "../../../images/passthrough.png";
import { pageRoutes } from "../../../routes";
import PassthroughDataProviderDialog from "./providers/passthrough/PassthroughDataProviderDialog";

interface Props {
  hasAccessToCsvImport: boolean;
  hasAccessToPassthroughImport: boolean;
}

const ImportDataPageActions = ({ hasAccessToCsvImport, hasAccessToPassthroughImport }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(null);
  const [showPassthroughDialog, setShowPassthroughDialog] = useState(false);

  const hasAccessToAnyImport = hasAccessToCsvImport || hasAccessToPassthroughImport;

  if (!hasAccessToAnyImport) {
    return null;
  }

  const handleImportDataButtonClick: MouseEventHandler<HTMLButtonElement | undefined> = (e) => {
    e.stopPropagation();
    setContextMenuAnchor(e.currentTarget);
  };

  const handleContextMenuClose = () => {
    setContextMenuAnchor(null);
  };

  const handleImportFromCsvClick = () => {
    handleContextMenuClose();
    navigate(`${pageRoutes.importCsvData}`, { state: { referrerPath: pathname } });
  };

  const handleImportFromPassthroughClick = () => {
    handleContextMenuClose();
    setShowPassthroughDialog(true);
  };

  return (
    <>
      <Button variant="contained" startIcon={<ImportDataIcon />} onClick={handleImportDataButtonClick}>
        Import Data
      </Button>
      <Menu
        anchorEl={contextMenuAnchor}
        open={Boolean(contextMenuAnchor)}
        onClose={handleContextMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        slotProps={{
          paper: {
            sx: { minWidth: "10rem" },
          },
        }}
      >
        {hasAccessToCsvImport && (
          <MenuItem onClick={handleImportFromCsvClick}>
            <ListItemIcon>
              <ExcelIcon />
            </ListItemIcon>
            <ListItemText primary="Import from CSV" />
          </MenuItem>
        )}
        {hasAccessToPassthroughImport && (
          <MenuItem onClick={handleImportFromPassthroughClick}>
            <ListItemIcon>
              <Avatar src={PassthroughLogo} variant="square" sx={{ width: 20, height: 20 }} />
            </ListItemIcon>
            <ListItemText primary="Import from Passthrough" />
          </MenuItem>
        )}
      </Menu>

      {showPassthroughDialog && <PassthroughDataProviderDialog onClose={() => setShowPassthroughDialog(false)} />}
    </>
  );
};

export default ImportDataPageActions;
