import { Pagination, Stack, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

interface FooterProps {
  page: number;
  totalItems?: number;
  totalPages?: number;
  onPageChange: (page: number) => void;
}

const LogsTableFooter = ({ page, totalItems, totalPages, onPageChange }: FooterProps) => {
  const [itemsCount, setItemsCount] = useState<number>();
  const [pagesCount, setPagesCount] = useState<number>();

  useEffect(() => {
    if (totalItems !== undefined) {
      setItemsCount(totalItems);
    }
    if (totalPages !== undefined) {
      setPagesCount(totalPages);
    }
  }, [totalItems, totalPages]);

  const handlePageChange = (_: ChangeEvent<unknown>, page: number) => onPageChange(page - 1);

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={4} mt={1}>
      {itemsCount !== undefined && (
        <Stack direction="row" spacing={1} mt={1} alignItems="center">
          <Typography>Total items:</Typography>
          <Typography color="text.primary">{itemsCount}</Typography>
        </Stack>
      )}
      {pagesCount !== undefined && (
        <Pagination color="primary" page={page + 1} count={pagesCount} onChange={handlePageChange} />
      )}
    </Stack>
  );
};

export default LogsTableFooter;
