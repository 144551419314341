import { WatermarkSegment } from "../../../../api/types/investorPortalTypes";

export const getDetailsControlLabel = (segment: WatermarkSegment) => {
  switch (segment) {
    case "ContactName":
      return "Contact Name";
    case "ContactEmail":
      return "Contact Email";
    case "InvestorName":
      return "Investor Name";
    case "DateAndTime":
      return "Date and Time";
    default:
      return "";
  }
};
