import SubjectIcon from "@mui/icons-material/SubjectRounded";
import { DataImportTarget } from "../../../../../api/types/dataImportTypes";
import QueryStatsIcon from "../../../../../icons/QueryStatsIcon";
import TimeSeriesIcon from "../../../../../icons/TimeSeriesIcon";

interface Props {
  target: DataImportTarget;
}

const ImportCsvTargetIcon = ({ target }: Props) => {
  switch (target) {
    case "PlatformObjects":
      return <SubjectIcon />;
    case "PortfolioMonitoringMetrics":
      return <QueryStatsIcon />;
    case "PortfolioMonitoringMetricValues":
      return <TimeSeriesIcon />;
    default:
      return null;
  }
};

export default ImportCsvTargetIcon;
