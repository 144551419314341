import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import { getErrorMessage } from "../../../../../shared/api/axiosHelper";
import EmailTextField from "../../../../../shared/components/inputs/EmailTextField";
import PhoneFieldExt from "../../../../../shared/components/inputs/PhoneFieldExt";
import { wait } from "../../../../../shared/utilities/promiseHelper";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import UpdateNotificationBar from "../design/UpdateNotificationBar";

interface SupportInfoSettings {
  supportEmail: string;
  supportPhone: string;
}

interface Props {
  settings: SupportInfoSettings;
}

const SupportInfoEditor = ({ settings: originalSettings }: Props) => {
  const theme = useTheme();
  const { hasPermissions } = useClientContext();
  const canEditSettings = hasPermissions(["ManageInvestorPortalSettings"]);

  const [settings, setSettings] = useState<SupportInfoSettings>(originalSettings);
  const [savedSettings, setSavedSettings] = useState<SupportInfoSettings>(originalSettings);
  const [isJustSaved, setJustSaved] = useState(false);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveClick = async () => {
    setIsLoading(true);
    try {
      const response = await adminApi.updateInvestorPortalSettings({
        supportEmail: settings.supportEmail,
        supportPhone: settings.supportPhone,
      });

      if (response.data) {
        setSettings(response.data.settings);
        setSavedSettings(response.data.settings);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setError(`Saving failed: ${errorMessage}`);
    } finally {
      setIsLoading(false);
      setJustSaved(true);
      await wait(5 * 1000);
      setJustSaved(false);
      setError(undefined);
    }
  };

  const handleEmailChange = (value: string, isValid: boolean) => {
    if (isValid) {
      setSettings({ ...settings, supportEmail: value });
      setError("");
    } else {
      setError("Invalid email");
    }
  };

  const handlePhoneChange = (value: string) => {
    setSettings({ ...settings, supportPhone: value });
  };

  const handleResetClick = () => {
    setSettings(savedSettings);
    setError("");
  };

  const disableSave = !canEditSettings || !!error || deepEqual(savedSettings, settings);

  return (
    <Stack sx={{ width: theme.spacing(50) }}>
      <Stack spacing={2}>
        <Typography>Please add mail for your Investor Support Center.</Typography>
        <EmailTextField
          required
          disabled={!canEditSettings}
          fieldName="Email"
          value={settings?.supportEmail}
          onBlur={handleEmailChange}
        />
      </Stack>
      <Stack spacing={2} mt={3}>
        <Typography>Please add phone number your Investor Call Center. (Optional)</Typography>
        <PhoneFieldExt
          name="phone"
          containerClassName="my-profile-phone-number"
          InputProps={{
            preferredCountries: ["us"],
            value: settings.supportPhone,
            placeholder: "",
          }}
          onValidated={(_, value) => setError(value ? "" : "Invalid phone number")}
          onValueChanged={handlePhoneChange}
          disabled={!canEditSettings}
          skipValidation={true}
        ></PhoneFieldExt>
      </Stack>
      <Stack direction="row" spacing={2} mt={3}>
        <LoadingButton
          variant="contained"
          size="medium"
          sx={{ width: theme.spacing(10) }}
          disabled={disableSave}
          loading={isLoading}
          onClick={handleSaveClick}
        >
          Save
        </LoadingButton>
        <Button size="medium" disabled={!canEditSettings} variant="text" onClick={handleResetClick}>
          Reset
        </Button>
      </Stack>
      <UpdateNotificationBar variant={error ? "error" : undefined} open={isJustSaved} message={error} />
    </Stack>
  );
};

export default SupportInfoEditor;
