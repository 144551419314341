import ZoomInIcon from "@mui/icons-material/AddRounded";
import LeftIcon from "@mui/icons-material/ChevronLeftRounded";
import RightIcon from "@mui/icons-material/ChevronRightRounded";
import SaveIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import ZoomOutIcon from "@mui/icons-material/RemoveRounded";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import saveAs from "file-saver";
import print from "print-js";
import { useState } from "react";
import documentEventsApi from "../../../api/clients/documentEventsApi";
import useDebounce from "../../../hooks/useDebounce";
import SidebarIcon from "../../../icons/SidebarIcon";
import { logError } from "../../../logging";
import { byteArrayToBase64 } from "../../../utilities/stringHelper";
import CloseIconButton from "../../CloseIconButton";
import HorizontalFill from "../../HorizontalFill";
import NumberTextField from "../../NumberTextField";
import { PdfViewerState } from "./pdfViewerState";

interface Props {
  fileName: string;
  viewerState: PdfViewerState;
  documentViewSessionId?: string;
  hideFileName?: boolean;
  hidePrintButton?: boolean;
  onNavigateToPage: (pageNumber: number) => void;
  onPageInputValueChange: (value: string) => void;
  onToggleThumbnails?: () => void;
  onZoomOut?: () => void;
  onZoomIn?: () => void;
  onClose?: () => void;
}

const sendDownloadEvent = async (documentViewSessionId: string) => {
  try {
    await documentEventsApi.ingestDocumentDownloadEvent({
      viewEventsData: [
        {
          sessionId: documentViewSessionId,
          eventData: { download: true },
        },
      ],
    });
  } catch (error) {
    logError(error, "[PdfViewerControls] sendDownloadEvent");
  }
};

const PdfViewerControls = ({
  fileName,
  viewerState,
  documentViewSessionId,
  hideFileName,
  hidePrintButton,
  onNavigateToPage,
  onPageInputValueChange,
  onToggleThumbnails,
  onZoomOut,
  onZoomIn,
  onClose,
}: Props) => {
  const [isOperationInProgress, setOperationInProgress] = useState(false);
  const { numPages, documentProxy, isDocumentLoaded } = viewerState;

  const onNavigateToPageDebounced = useDebounce((num: number | undefined) => {
    if (num !== undefined && num >= 1 && num <= numPages) {
      onNavigateToPage(num);
    }
  }, 500);

  const handlePageNumberChange = (num: number | undefined, value: string) => {
    onPageInputValueChange(value);
    onNavigateToPageDebounced(num);
  };

  const handlePreviousPage = () => {
    const pageNumber = viewerState.currentPageNumber - 1;
    onNavigateToPage(pageNumber);
  };

  const handleNextPage = () => {
    const pageNumber = viewerState.currentPageNumber + 1;
    onNavigateToPage(pageNumber);
  };

  const handleDownloadClick = async () => {
    if (!documentProxy) {
      return;
    }

    setOperationInProgress(true);
    try {
      const documentData = await documentProxy.getData();
      const blob = new Blob([documentData], { type: "application/pdf" });
      saveAs(blob, fileName);

      if (documentViewSessionId) {
        sendDownloadEvent(documentViewSessionId);
      }
    } catch (error) {
      logError(error, `[PdfViewerControls] Error downloading PDF ${fileName}`);
    } finally {
      setOperationInProgress(false);
    }
  };

  const handlePrintClick = async () => {
    if (!documentProxy) {
      return;
    }

    setOperationInProgress(true);
    try {
      const documentData = await documentProxy.getData();
      const base64Data = byteArrayToBase64(documentData);
      print({ printable: base64Data, type: "pdf", base64: true, documentTitle: fileName });
    } catch (error) {
      logError(error, `[PdfViewerControls] Error printing PDF ${fileName}`);
    } finally {
      setOperationInProgress(false);
    }
  };

  const actionsDisabled = !isDocumentLoaded || isOperationInProgress;

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
      {(onToggleThumbnails || !hideFileName) && (
        <Stack direction="row" spacing={2} alignItems="center" flex={1} maxWidth="40%">
          {onToggleThumbnails && (
            <IconButton
              onClick={onToggleThumbnails}
              disabled={actionsDisabled}
              color={viewerState.showThumbnails ? "primary" : "secondary"}
            >
              <SidebarIcon />
            </IconButton>
          )}

          {!hideFileName && (
            <Typography variant="subtitle2" noWrap>
              {fileName}
            </Typography>
          )}
        </Stack>
      )}

      <Stack direction="row" spacing={1} alignItems="center" flexGrow={0} flexShrink={1} flexBasis="auto">
        <IconButton onClick={handlePreviousPage} disabled={actionsDisabled || viewerState.currentPageNumber < 2}>
          <LeftIcon />
        </IconButton>

        <Stack direction="row" spacing={1} alignItems="center">
          <NumberTextField
            value={viewerState.currentPageInputValue}
            onNumberChange={handlePageNumberChange}
            disabled={actionsDisabled || numPages < 2}
            onFocus={(e) => e.target.select()}
            sx={{ width: "3rem", ".MuiInputBase-input": { py: 0.5, px: 1 } }}
          />
          {isDocumentLoaded && <Typography noWrap>{`of ${viewerState.numPages}`}</Typography>}
        </Stack>

        <IconButton onClick={handleNextPage} disabled={actionsDisabled || viewerState.currentPageNumber > numPages - 1}>
          <RightIcon />
        </IconButton>

        <Divider flexItem orientation="vertical" />

        {onZoomOut && (
          <IconButton onClick={onZoomOut} disabled={actionsDisabled}>
            <ZoomOutIcon />
          </IconButton>
        )}

        {onZoomIn && (
          <IconButton onClick={onZoomIn} disabled={actionsDisabled}>
            <ZoomInIcon />
          </IconButton>
        )}
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center" flex={1}>
        <HorizontalFill />
        <IconButton onClick={handleDownloadClick} disabled={actionsDisabled}>
          <SaveIcon />
        </IconButton>

        {!hidePrintButton && (
          <IconButton onClick={handlePrintClick} disabled={actionsDisabled}>
            <PrintIcon />
          </IconButton>
        )}

        {onClose && <CloseIconButton onClick={onClose} />}
      </Stack>
    </Stack>
  );
};

export default PdfViewerControls;
