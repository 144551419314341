import { SvgIcon, SvgIconProps } from "@mui/material";

export const MemberDialogIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 66, height: 66, fill: "none" }} viewBox="0 0 66 66" {...props}>
      <path
        d="M32.9996 37.4502C26.9996 37.4502 22.0996 31.6502 22.0996 24.4502C22.0996 17.2502 26.9996 11.4502 32.9996 11.4502C38.9996 11.4502 43.8996 17.2502 43.8996 24.4502C43.8996 31.6502 38.9996 37.4502 32.9996 37.4502ZM32.9996 16.3502C29.6996 16.3502 26.9996 19.9502 26.9996 24.4502C26.9996 28.9502 29.6996 32.5502 32.9996 32.5502C36.2996 32.5502 38.9996 28.9502 38.9996 24.4502C39.0996 20.0502 36.3996 16.3502 32.9996 16.3502Z"
        fill="#00A866"
      />
      <path
        d="M32.9998 38.9502C28.8998 38.9502 18.1998 49.8502 15.2998 59.5502H20.3998C23.2998 51.8502 30.7998 44.9502 32.9998 43.8502C35.1998 44.9502 42.6998 51.8502 45.5998 59.5502H50.6998C47.8998 49.7502 37.1998 38.9502 32.9998 38.9502Z"
        fill="#00A866"
      />
      <path
        d="M32.9996 65.4501C15.0996 65.4501 0.599609 50.85 0.599609 33.05C0.599609 15.25 15.0996 0.550049 32.9996 0.550049C50.8996 0.550049 65.3996 15.1501 65.3996 32.9501C65.3996 50.75 50.7996 65.4501 32.9996 65.4501ZM32.9996 5.55005C17.8996 5.55005 5.59961 17.85 5.59961 32.9501C5.59961 48.0501 17.8996 60.35 32.9996 60.35C48.0996 60.35 60.3996 48.1501 60.3996 32.9501C60.3996 17.7501 48.0996 5.55005 32.9996 5.55005Z"
        fill="#C7DBE5"
      />
    </SvgIcon>
  );
};

export default MemberDialogIcon;
