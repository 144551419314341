import { SvgIconComponent } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton, Link, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem } from "@mui/material";

interface Props {
  href: string;
  title: string;
  Icon?: SvgIconComponent;
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error" | undefined;
}

const OpenExternalLinkMenuItem = ({ href, title, Icon, color }: Props) => {
  const handleNav = () => {
    window.open(href, "_blank");
  };

  return (
    <MenuItem onClick={handleNav}>
      {!!Icon && (
        <ListItemIcon>
          <Icon color={color} />
        </ListItemIcon>
      )}
      <ListItemText>{title}</ListItemText>
      <ListItemSecondaryAction>
        <IconButton
          size="medium"
          onClick={(e) => e.stopPropagation()}
          component={Link}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          edge="end"
          aria-label="navigate"
        >
          <OpenInNewIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </MenuItem>
  );
};

export default OpenExternalLinkMenuItem;
