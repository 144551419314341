import { Stack, Typography } from "@mui/material";

const PermissionsBlankIcon = () => {
  return (
    <Stack spacing={1} alignItems="center" justifyContent={"center"}>
      <svg width="64" height="66" viewBox="0 0 64 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M57 10.4198V28.5798H52V13.9198L28.59 5.40984L5.21999 13.9198V33.0798C5.21999 53.9398 27.04 60.3198 28.59 60.7498C28.9 60.6698 29.49 60.4898 30.31 60.1998C31.13 59.9098 32.31 59.4598 33.61 58.8798C35.1207 58.2148 36.5871 57.4533 38 56.5998L40.59 60.8598C40.29 61.0498 39.97 61.2398 39.66 61.4098C38.4328 62.1429 37.1677 62.8105 35.87 63.4098C33.9342 64.3211 31.9276 65.0735 29.87 65.6599L28.67 65.9598L27.55 65.6698C27.32 65.6198 0.299988 58.8798 0.299988 33.1298V10.4198L28.59 0.0898438L57 10.4198Z"
          fill="#C7DBE5"
        />
        <path d="M42.57 50.05L35.43 43.35L38.85 39.7L42.38 43.02L51.97 33L55.58 36.46L42.57 50.05Z" fill="#00A866" />
        <path
          d="M44.14 61.9396C38.9258 61.9396 33.9252 59.8683 30.2383 56.1813C26.5513 52.4944 24.48 47.4938 24.48 42.2796C24.48 37.0655 26.5513 32.0649 30.2383 28.3779C33.9252 24.6909 38.9258 22.6196 44.14 22.6196C46.7205 22.6209 49.2754 23.1305 51.659 24.1192C54.0425 25.1079 56.208 26.5565 58.0317 28.3821C59.8554 30.2077 61.3018 32.3746 62.288 34.7591C63.2743 37.1437 63.7813 39.6992 63.78 42.2796C63.7773 47.4895 61.7079 52.4855 58.0259 56.1714C54.3438 59.8572 49.3499 61.9317 44.14 61.9396ZM44.14 27.6196C40.6221 27.6303 37.2254 28.9057 34.5698 31.2129C31.9142 33.5201 30.1768 36.7054 29.6747 40.1872C29.1726 43.6691 29.9393 47.2154 31.8349 50.1789C33.7304 53.1424 36.6285 55.3255 40 56.3296C41.3557 56.7376 42.7642 56.9432 44.18 56.9396C47.2513 56.9334 50.2432 55.9634 52.7339 54.1663C55.2246 52.3692 57.0884 49.8357 58.0627 46.923C59.0369 44.0102 59.0723 40.8652 58.164 37.9313C57.2557 34.9973 55.4495 32.4224 53 30.5696C50.4531 28.6355 47.3379 27.5983 44.14 27.6196Z"
          fill="#00A866"
        />
      </svg>
      <Typography>Select company to configure access</Typography>
    </Stack>
  );
};

export default PermissionsBlankIcon;
