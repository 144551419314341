import { definedValidator } from "../../../../../../shared/utilities/validators";
import { InternalEntity, PassthroughFund, PassthroughFundClosing } from "../../../../../api/types/passthroughTypes";

interface ImportPassthroughDataState {
  selectedFund?: PassthroughFund;
  selectedFundClosing?: PassthroughFundClosing;
  selectedInternalFund?: InternalEntity;
  fundClosings: PassthroughFundClosing[];
  funds: PassthroughFund[];
  isLoadingFundClosings: boolean;
  isImporting?: boolean;
  internalEntities: InternalEntity[];
  commitmentDate?: string;
  isFormValid: boolean;
  isImportInProgress?: boolean;
  errors: {
    fund?: string;
    fundClosing?: string;
    commitmentDate?: string;
    selectedInternalFund?: string;
  };
}

type Action =
  | { type: "SetFundClosings"; payload: PassthroughFundClosing[] }
  | { type: "SetFunds"; payload: PassthroughFund[] }
  | { type: "SetIsLoadingFundClosings"; payload: boolean }
  | { type: "SetInternalEntities"; payload: InternalEntity[] }
  | { type: "UpdateAndValidateForm"; payload: ImportPassthroughDataState }
  | { type: "SetIsImporting"; payload: boolean }
  | { type: "SetIsImportInProgress"; payload: boolean };

export const getInitialState = (
  funds: PassthroughFund[],
  internalEntities: InternalEntity[]
): ImportPassthroughDataState => ({
  selectedFund: undefined,
  selectedFundClosing: undefined,
  fundClosings: [],
  funds: funds,
  isLoadingFundClosings: false,
  internalEntities: internalEntities,
  isFormValid: false,
  errors: {},
});

export const reducer = (state: ImportPassthroughDataState, action: Action): ImportPassthroughDataState => {
  switch (action.type) {
    case "SetFundClosings":
      return { ...state, fundClosings: action.payload };
    case "SetFunds":
      return { ...state, funds: action.payload };
    case "SetIsLoadingFundClosings":
      return { ...state, isLoadingFundClosings: action.payload };
    case "SetInternalEntities":
      return { ...state, internalEntities: action.payload };
    case "UpdateAndValidateForm":
      return validateForm(action.payload);
    case "SetIsImporting":
      return { ...state, isImporting: action.payload };
    case "SetIsImportInProgress":
      return { ...state, isImportInProgress: action.payload };
    default:
      return state;
  }
};

const validateForm = (form: ImportPassthroughDataState): ImportPassthroughDataState => {
  const validateFund = definedValidator<PassthroughFund>("Fund must be selected");
  const validateFundClosing = definedValidator<PassthroughFundClosing>("Fund closing must be selected");
  const validateCommitmentDate = definedValidator<string>("Commitment date must be selected");
  const validateInternalFund = definedValidator<InternalEntity>("Entity must be selected");

  const validateFundResult = validateFund(form.selectedFund);
  const validateCommitmentDateResult = validateCommitmentDate(form.commitmentDate);
  const validateFundClosingResult = validateFundClosing(form.selectedFundClosing);
  const validateInternalFundResult = validateInternalFund(form.selectedInternalFund);

  const isValid =
    validateFundResult.isValid &&
    validateCommitmentDateResult.isValid &&
    validateFundClosingResult.isValid &&
    validateInternalFundResult.isValid;

  return {
    ...form,
    isFormValid: isValid,
    errors: {
      fund: validateFundResult.error,
      fundClosing: validateFundClosingResult.error,
      commitmentDate: validateCommitmentDateResult.error,
      selectedInternalFund: validateInternalFundResult.error,
    },
  };
};
