import { Alert, AlertTitle, Typography } from "@mui/material";

const ImportInProgressAlert = () => {
  return (
    <Alert severity={"warning"}>
      <AlertTitle>Import in progress</AlertTitle>
      <Typography>Import duration may vary with file size. This could take a few minutes.</Typography>
    </Alert>
  );
};

export default ImportInProgressAlert;
