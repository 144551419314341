import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellCheckboxRenderer,
  GridColDef,
  GridColumnHeaderParams,
  GridHeaderCheckbox,
  GridRenderCellParams,
  selectedIdsLookupSelector,
} from "@mui/x-data-grid-premium";
import { GridRenderCellParamsPremium } from "@mui/x-data-grid-premium/typeOverloads";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { isGroupOrPinnedRowNodeType } from "../../../../shared/components/grid/gridHelper";
import { checkSelection } from "../../../../shared/utilities/dataGridApiHelper";

interface Configuration {
  groupsSelectionOnly?: boolean;
}

const createCheckboxColumn = (apiRef: React.MutableRefObject<GridApiPremium>, config?: Configuration): GridColDef => {
  const { groupsSelectionOnly } = config || {};
  return {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderHeader: (params) => {
      const { children, indeterminate, checked } = checkSelection(apiRef);
      const data: GridColumnHeaderParams & {
        indeterminate?: boolean;
        checked?: boolean;
        disabled?: boolean;
        display?: string;
        onClick?: (e: MouseEvent) => void;
      } = {
        ...params,
        onClick: (e) => {
          apiRef.current.selectRows(children, indeterminate || !checked);
          e.preventDefault();
        },
        indeterminate,
        display: "hidden",
      };
      return <GridHeaderCheckbox {...data} />;
    },
    renderCell: (params) => {
      const { rowNode } = params;

      if (!isGroupOrPinnedRowNodeType(rowNode)) {
        return groupsSelectionOnly ? <></> : <GridCellCheckboxRenderer {...params} />;
      }

      const selectionLookup = selectedIdsLookupSelector(apiRef.current.state, apiRef.current.instanceId);
      const children = apiRef.current.getRowGroupChildren({
        groupId: rowNode.id,
        applyFiltering: true,
        applySorting: true,
      });

      const indeterminate =
        children?.some((child) => selectionLookup[child] === undefined) &&
        children?.some((child) => selectionLookup[child] !== undefined);

      const checked = children?.every((child) => selectionLookup[child] !== undefined);

      const extraData: GridRenderCellParams &
        GridRenderCellParamsPremium & {
          indeterminate?: boolean;
          checked?: boolean;
          disabled?: boolean;
          onClick?: (e: MouseEvent) => void;
        } = {
        ...params,
        disabled: false,
        onClick: (e) => {
          if (isGroupOrPinnedRowNodeType(rowNode)) {
            if (children) {
              apiRef.current.selectRows(children, indeterminate || !checked);
            }
            e.preventDefault();
          }
        },
        indeterminate,
        checked,
      };

      return <GridCellCheckboxRenderer {...extraData} />;
    },
  };
};

export default createCheckboxColumn;
