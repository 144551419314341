import { useEffect, useState } from "react";
import { ValidationResult } from "../../../../shared/utilities/validators";
import { EntityFieldComponentProps } from "../entityFieldTypes";

interface Props<T> extends EntityFieldComponentProps<T> {
  ComponentToRender: React.ComponentType<EntityFieldComponentProps<T>>;
}

const EntityFieldTypeWrapper = <T,>({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
  ComponentToRender,
}: Props<T>) => {
  const [valueState, setValueState] = useState(value);

  useEffect(() => {
    if (valueState !== value) {
      setValueState(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (newValue: T | undefined | null, fieldId: string, validationResult: ValidationResult) => {
    setValueState(newValue);
    onChange(newValue, fieldId, validationResult);
  };

  return (
    <ComponentToRender
      value={valueState}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={handleChange}
      fieldInputId={fieldInputId}
    />
  );
};

export default EntityFieldTypeWrapper;
