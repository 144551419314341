import { Box, Divider } from "@mui/material";
import { useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import {
  FileTag,
  FileTagSettingsResponse,
  FilesViewDefinition,
  GridColumnDefinition,
} from "../../../api/types/fileTypes";
import { useClientContext } from "../../../context/ClientContext";
import FilesNavigation from "../../common/files/FilesNavigation";
import FilesView from "../../common/files/FilesView";
import FileTagSettings from "./tagSettings/FileTagSettings";

interface ViewState {
  view: FilesViewDefinition | undefined;
  tag: FileTag | undefined;
  showSettings: boolean;
}

const CompanyFilesPage = () => {
  const { hasPermissions } = useClientContext();
  const [viewState, setViewState] = useState<ViewState>({ view: undefined, tag: undefined, showSettings: false });
  const [defaultColumnDefinitions, setDefaultColumnDefinitions] = useState<GridColumnDefinition[]>([]);

  const [filesViewsResp, viewsError] = useFetch(adminApi.getFilesViews, (data) => {
    setViewState({ view: data.predefinedViews[0], tag: undefined, showSettings: false });
    setDefaultColumnDefinitions(data.predefinedViews[0]?.columns ?? []);
  });

  const [tagSettingsResp, tagSettingsError, { setData: setTagSettings }] = useFetch(adminApi.getFileTagSettings);

  if (viewsError) {
    logError(viewsError, "[Files] getFilesViews");
    return <DataLoadingFailed title="Could not load files view structure" />;
  }

  if (tagSettingsError) {
    logError(tagSettingsError, "[Files] getFileTagSettings");
    return <DataLoadingFailed title="Could not load files tag settings" />;
  }

  if (!filesViewsResp || !tagSettingsResp) {
    return <InlineLoader />;
  }

  const { predefinedViews } = filesViewsResp;
  const { tags } = tagSettingsResp;

  const hasPermissionsToManageFiles = hasPermissions(["ManageAllCompanyFiles"]);

  const handleSelectedViewChange = (viewName: string) => {
    const view = predefinedViews.find(({ name }) => name === viewName);
    if (view) {
      setViewState({ view, tag: undefined, showSettings: false });
    }
  };

  const handleSelectedTagChange = (tagId: string) => {
    const tag = tags.find(({ catalogueId }) => catalogueId === tagId);
    if (tag) {
      setViewState({ view: undefined, tag, showSettings: false });
    }
  };

  const handleShowSettings = () => {
    setViewState({ view: undefined, tag: undefined, showSettings: true });
  };

  const handleTagSettingsUpdate = (newTagSettings: FileTagSettingsResponse) => setTagSettings(newTagSettings);

  return (
    <Box display="flex" overflow="hidden" height="100%">
      <FilesNavigation
        predefinedViews={predefinedViews}
        selectedView={viewState.view}
        onSelectedViewChange={handleSelectedViewChange}
        tags={tags}
        selectedTag={viewState.tag}
        onSelectedTagChange={handleSelectedTagChange}
        showSettings={viewState.showSettings}
        onShowSettings={handleShowSettings}
      />
      <Divider flexItem orientation="vertical" />
      {(viewState.view || viewState.tag) && (
        <FilesView
          key={viewState.view?.name ?? viewState.tag?.catalogueId}
          viewName={viewState.view?.name}
          tagId={viewState.tag?.catalogueId}
          viewTitle={viewState.view?.title ?? viewState.tag?.name ?? ""}
          columnDefinitions={viewState.view?.columns ?? defaultColumnDefinitions}
          hasPermissionsToManage={hasPermissionsToManageFiles}
        />
      )}
      {viewState.showSettings && (
        <FileTagSettings settings={tagSettingsResp} onSettingsUpdate={handleTagSettingsUpdate} />
      )}
    </Box>
  );
};

export default CompanyFilesPage;
