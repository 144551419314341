import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi, { Investor } from "../../../../api/adminApi";
import DeletePersonIcon from "../../../../icons/DeletePersonIcon";

interface Props {
  open: boolean;
  onClose: () => void;
  onDeleted: (investorId: string) => void;
  investor: Investor;
}

const deleteInvestor = withErrorHandling(adminApi.deleteInvestor);

const DeleteInvestorDialog = ({ open, onClose, onDeleted, investor }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isDeleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    const [resp, error] = await deleteInvestor(investor.id);
    setDeleting(false);

    if (error || !resp.isDeleted) {
      logError(error, "[DeleteInvestorDialog]");
      sendNotificationError(resp?.displayErrorMessage || "Failed to delete investor");
      return;
    }

    sendNotification(`Investor ${investor.title} deleted`);
    onDeleted(investor.id);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DeletePersonIcon />
          <Stack alignItems="center" spacing={0.5}>
            <Typography variant="h6">Delete investor?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You're about to delete <strong>{investor.title}</strong>. This action is permanent and can't be undone.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={isDeleting} onClick={handleDelete}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteInvestorDialog;
