import { MenuItemProps } from "@mui/base";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export interface MenuItemPropsWithIcon {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  props?: MenuItemProps;
}

interface Props {
  onOpen?: () => void;
  onClose?: () => void;
  items: MenuItemPropsWithIcon[];
  icon?: React.ReactNode;
  disabled?: boolean;
}

const ActionsMenuButton = ({ items, onOpen, onClose, icon, disabled }: Props) => {
  const [contextMenuAnchor, setContextMenuAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          setContextMenuAnchor(e.currentTarget);
          onOpen?.();
        }}
      >
        {icon ?? <MoreIcon />}
      </IconButton>
      <Menu
        anchorEl={contextMenuAnchor}
        open={Boolean(contextMenuAnchor)}
        onClose={() => {
          setContextMenuAnchor(null);
          onClose?.();
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        slotProps={{
          paper: {
            sx: { minWidth: "10rem" },
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              item.onClick();
              setContextMenuAnchor(null);
            }}
            disabled={item.disabled}
            {...item.props}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ActionsMenuButton;
