import { LoadingButton } from "@mui/lab";
import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { useReducer } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { convertToFormData } from "../../../../../shared/utilities/formDataHelper";
import adminApi from "../../../../api/adminApi";
import { CompanyType } from "../../../../api/types/clientTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { useUserContext } from "../../../../context/UserContext";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import CompanyLogomarkSettings from "./CompanyLogomarkSettings";
import CompanyTypeSelect from "./CompanyTypeSelect";
import EntityLevelAccess from "./EntityLevelAccess";
import { getInitialState, reducer } from "./generalCompanySettingsState";

const updateCompanySettings = withErrorHandling(adminApi.updateCompanySettings);

const GeneralCompanySettingsPage = () => {
  const { clientCode, clientTitle, branding, entityLevelAccess, hasPermissions, companyType } = useClientContext();
  const { updateClientData } = useUserContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const hasAccessToManageCompanySettings = hasPermissions(["ManageCompanySettings"]);
  const investorPortalUrl = `${window.location.origin}/${clientCode}`;

  const [state, dispatch] = useReducer(
    reducer,
    getInitialState({
      entityLevelAccess: entityLevelAccess || false,
      companyType: companyType,
      logomarkUrl: branding?.logoMarkUrl,
    })
  );

  const handleSave = async () => {
    dispatch({ type: "SetIsLoading", payload: true });

    const formData = convertToFormData(state.changes);
    const [resp, error] = await updateCompanySettings(formData);

    if (error || !resp) {
      logError(error, "GeneralCompanySettingsPage");
      sendNotificationError("Failed to save settings");
    } else {
      sendNotification("Settings saved");
      dispatch({ type: "SetSavedSettings", payload: { ...resp } });
      updateClientData(clientCode, { ...resp });
    }

    dispatch({ type: "SetIsLoading", payload: false });
  };

  const handleCompanyTypeChange = (value: CompanyType) => {
    dispatch({ type: "UpdateSettings", payload: { companyType: value } });
  };

  const handleLogoChange = (file: File | undefined, url: string) => {
    if (file === undefined) {
      dispatch({ type: "ResetLogo" });
    } else {
      dispatch({ type: "UpdateSettings", payload: { logomarkImage: file, logomarkUrl: url } });
    }
  };

  const handleEntityLevelAccessChange = (value: boolean) => {
    dispatch({ type: "UpdateSettings", payload: { entityLevelAccess: value } });
  };

  const handleCancel = () => {
    dispatch({ type: "Reset" });
  };

  return (
    <>
      <GeneralPageHeader title="General" />
      <Stack spacing={3} px={3} pb={1} pt={2}>
        <Stack spacing={2}>
          <Typography variant="subtitle1">General Info</Typography>
          <Stack spacing={2} sx={(theme) => ({ width: theme.spacing(69) })}>
            <TextField disabled label="Company name" defaultValue={clientTitle} />
            <CompanyTypeSelect
              value={state.currentSettings.companyType || "Production"}
              onChange={handleCompanyTypeChange}
              disabled={!hasAccessToManageCompanySettings}
            />
            <TextField disabled label="Company URL" defaultValue={investorPortalUrl} />
          </Stack>
        </Stack>
        <Divider />
        <CompanyLogomarkSettings
          url={state.currentSettings.logomarkUrl}
          defaultUrl={state.defaultLogomarkUrl}
          onLogomarkChange={handleLogoChange}
          isLoading={state.isLoading}
          disabled={!hasAccessToManageCompanySettings}
        />
        <Divider />
        <EntityLevelAccess
          entityLevelAccessEnabled={state.currentSettings.entityLevelAccess || false}
          onChange={handleEntityLevelAccessChange}
          isLoading={state.isLoading}
          disabled={!hasAccessToManageCompanySettings}
        />
        {hasAccessToManageCompanySettings && (
          <Stack flexDirection={"row"} useFlexGap gap={1}>
            <LoadingButton
              variant="contained"
              loading={state.isLoading}
              onClick={handleSave}
              disabled={!state.formHasChanges}
            >
              Save Changes
            </LoadingButton>
            <Button variant="text" color="secondary" onClick={handleCancel} disabled={!state.formHasChanges}>
              Cancel
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default GeneralCompanySettingsPage;
