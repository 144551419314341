import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

const DocumentPreviewWrapper = ({ children }: PropsWithChildren) => (
  <Box
    py={2}
    pl={1}
    width="100%"
    height="100%"
    sx={(t) => ({ borderLeft: "1px solid", borderColor: t.palette.divider })}
  >
    {children}
  </Box>
);

export default DocumentPreviewWrapper;
