import SyncIcon from "@mui/icons-material/SyncAltRounded";
import StatusChip from "../../../shared/components/StatusChip";
import { FieldSource } from "../../api/types/objectTypes";

interface Props {
  fieldSource: FieldSource;
}

const FieldSourceBadge = ({ fieldSource }: Props) => {
  switch (fieldSource) {
    case "UserDefined":
      return <StatusChip height="smaller" label="Custom" color={(t) => t.palette.info.main} />;
    case "Portal":
      return <StatusChip height="smaller" label="Standard" color={(t) => t.palette.text.secondary} />;
    case "BusinessCentral":
      return (
        <StatusChip height="smaller" label="Standard" color={(t) => t.palette.text.secondary} icon={<SyncIcon />} />
      );
    default:
      return null;
  }
};

export default FieldSourceBadge;
