import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import CheckItemsList, { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { CompanyWithAccess } from "../definitions";

interface Props {
  open: boolean;
  selectedCompanies: CompanyWithAccess[];
  availableCompanies: ListItemData[];
  onClose: (selectedCompanies?: CompanyWithAccess[]) => void;
}

const CompaniesSelectDialog = ({ open, onClose, availableCompanies, selectedCompanies }: Props) => {
  const theme = useTheme();
  const borderLineStyle = `1px ${theme.palette.divider} solid`;

  const [searchFilter, setSearchFilter] = useState("");
  const [companiesToAdd, setCompaniesToAdd] = useState<string[]>([]);

  const clientsCompaniesList: ListItemData[] = availableCompanies.filter((client) =>
    client.label.toLowerCase().includes(searchFilter.trim().toLowerCase())
  );
  const selectedCompaniesList = clientsCompaniesList.filter((company) =>
    selectedCompanies.map((c) => c.clientCode).includes(company.value)
  );

  const handleSelectedCompanies = useCallback(
    (companies: ListItemData[]) => {
      setCompaniesToAdd(
        companies
          .map((company) => company.value)
          .filter((value: string) => !selectedCompanies.map((c) => c.clientCode).includes(value))
      );
    },
    [selectedCompanies]
  );

  const handleClose = (selectedCompanies?: CompanyWithAccess[]) => {
    onClose(selectedCompanies);
    setTimeout(() => {
      setCompaniesToAdd([]);
      setSearchFilter("");
    }, 250);
  };

  const addSelectedCompanies = () => {
    const companiesAdded: CompanyWithAccess[] = companiesToAdd.map((clientCode) => ({
      clientCode,
      roles: [],
      entities: [],
      accessToAllEntities: false,
    }));

    handleClose([...(selectedCompanies || []), ...companiesAdded]);
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          height: "80vh",
          width: 480,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, pl: 2.5, pr: 1.875, py: 1.375, pb: 1 }}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6">Companies</Typography>
          <CloseIconButton onClick={() => handleClose()} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ m: 0, px: 2.5, pb: 2, pt: 0.375 }}>
          <SearchField onSearch={setSearchFilter} placeholder="Enter company name" fullWidth />
        </Box>
        <Divider />
        <CheckItemsList
          managed
          primaryTypographyVariant="subtitle1"
          items={clientsCompaniesList}
          onSelectedChanged={handleSelectedCompanies}
          preselectedItems={selectedCompaniesList}
          disabledItems={selectedCompaniesList}
        />
      </DialogContent>
      <DialogActions sx={{ borderTop: borderLineStyle, px: 2.5, py: 2 }}>
        <Button color="secondary" variant="text" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button disabled={companiesToAdd.length === 0} variant="contained" onClick={addSelectedCompanies}>
          {`Add${!companiesToAdd.length ? "" : " " + companiesToAdd.length} ${
            companiesToAdd.length === 1 ? "Company" : "Companies"
          }`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompaniesSelectDialog;
