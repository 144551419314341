import {
  ChartReportValidationResponse,
  PivotReportValidationResponse,
  ReportValidationResponse,
  TabularReportValidationResponse,
} from "../../../../../../api/biApi";

export function getInvalidFieldsCount(result: ReportValidationResponse | undefined) {
  switch (result?.reportType) {
    case "Pivot": {
      return getPivotInvalidFieldsCount(result);
    }
    case "Tabular": {
      return getTabularInvalidFieldsCount(result);
    }
    case "Chart": {
      return getChartInvalidFieldsCount(result);
    }
    default: {
      return 0;
    }
  }
}

function getPivotInvalidFieldsCount(result: PivotReportValidationResponse) {
  let invalidFields = (result.conditions?.filter((c) => c.valid === false) || []).length;
  invalidFields += (result.columns?.filter((c) => c.valid === false) || []).length;
  invalidFields += (result.rows?.filter((c) => c.valid === false) || []).length;
  invalidFields += (result.measures?.filter((c) => c.valid === false) || []).length;
  return invalidFields;
}

function getTabularInvalidFieldsCount(result: TabularReportValidationResponse) {
  let invalidFields = (result.conditions?.filter((c) => c.valid === false) || []).length;
  invalidFields += (result.fields?.filter((c) => c.dimension?.valid === false || c.measure?.valid === false) || [])
    .length;
  return invalidFields;
}
function getChartInvalidFieldsCount(result: ChartReportValidationResponse) {
  let invalidFields = (result.conditions?.filter((c) => c.valid === false) || []).length;
  invalidFields += (result.arguments?.filter((c) => c.valid === false) || []).length;
  invalidFields += (result.measures?.filter((c) => c.valid === false) || []).length;
  return invalidFields;
}
