import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { AsyncFunc } from "../types";

type Result<TData> = [
  TData | undefined,
  {
    setData: Dispatch<SetStateAction<TData | undefined>>;
    isFetching: boolean;
    fetch: (cancellationToken?: CancellationToken) => Promise<void>;
  },
];

interface CancellationToken {
  cancel: boolean;
}

const useAsyncCall = <TData>(asyncFn: AsyncFunc<TData>): Result<TData> => {
  const [data, setData] = useState<TData>();
  const [isFetching, setIsFetching] = useState(false);

  const fetch = useCallback(
    async (cancellationToken?: CancellationToken) => {
      setIsFetching(true);

      const result = await asyncFn();

      if (cancellationToken?.cancel) {
        return;
      }

      setData(result);
      setIsFetching(false);
    },
    [asyncFn]
  );

  useEffect(() => {
    const cancellationToken: CancellationToken = { cancel: false };
    fetch(cancellationToken);
    return () => {
      cancellationToken.cancel = true;
    };
  }, [fetch]);

  return [data, { setData, isFetching, fetch }];
};

export default useAsyncCall;
