import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import {
  ValidationResult,
  combineValidators,
  maxCharactersValidator,
  regexValidator,
  requiredValidator,
  uniqueValidator,
  validResult,
} from "../../../../../shared/utilities/validators";
import { useObjectLayoutContext } from "./ObjectLayoutContext";
import { addGroupAction, renameGroupAction } from "./objectLayoutState";

interface Props {
  onClose: () => void;
  editedGroupName?: string;
}

const EditGroupDialog = ({ onClose, editedGroupName }: Props) => {
  const { layoutState, setLayoutState } = useObjectLayoutContext();

  const [groupName, setGroupName] = useState(editedGroupName ?? "");
  const [validationResult, setValidationResult] = useState<ValidationResult>(validResult());

  const existingGroupNames = layoutState.fieldGroups.map((g) => g.name);

  const maxLength = 50;

  const nameValidator = combineValidators(
    requiredValidator,
    uniqueValidator("A group with this name already exists", existingGroupNames),
    regexValidator(/^[a-zA-Z0-9\s]+$/, "Name can only contain letters, numbers and spaces"),
    maxCharactersValidator(maxLength)
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setGroupName(value);
    setValidationResult(nameValidator(value));
  };

  const handleApply = () => {
    onClose();
    setLayoutState(editedGroupName ? renameGroupAction(editedGroupName, groupName) : addGroupAction(groupName));
  };

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{editedGroupName ? "Edit Group" : "Add Group"}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          label="Name"
          value={groupName}
          onChange={handleNameChange}
          error={!validationResult.isValid}
          helperText={validationResult.error}
          InputProps={{
            endAdornment: (
              <Typography color="text.secondary" className="counter-adornment" sx={{ visibility: "hidden" }}>
                {Math.max(0, maxLength - groupName.length)}
              </Typography>
            ),
          }}
          sx={{
            ".MuiInputBase-root": {
              "&.Mui-focused": {
                ".counter-adornment": { visibility: "visible" },
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApply}
          disabled={!validationResult.isValid || !groupName}
        >
          {editedGroupName ? "Apply" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroupDialog;
