import { Navigate, useParams } from "react-router-dom";
import ObjectDetailsPage from "../../../entityFields/ObjectDetailsPage";

const FundInvestmentDetailsPage = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to=".." />;
  }

  return <ObjectDetailsPage objectId={id} objectType="FundInvestment" showDefaultBackButtonTo=".." />;
};

export default FundInvestmentDetailsPage;
