import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React from "react";
import DialogCloseButton from "../../../shared/components/DialogeCloseButton";
import BackdropLoader from "../../../shared/components/fullScreenLoader/BackdropLoader";

export type ConfirmDialogData<T> = {
  title: string;
  subtitle?: string;
  description?: string | JSX.Element;
  ids: T | T[];
  confirmCallback?: (ids?: T | T[]) => void;
  confirmButtonLabel: string;
  confirmButtonColor: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  icon?: JSX.Element;
  Content?: React.ReactNode;
};

interface ConfirmDialogProps<T> {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  data?: ConfirmDialogData<T>;
  isLoading?: boolean;
}

const ConfirmOperationDialog = <T,>({ open, onClose, onConfirm, data, isLoading }: ConfirmDialogProps<T>) => {
  const { title, subtitle, description, confirmButtonColor, confirmButtonLabel, icon } = data || {};

  return (
    <Dialog open={open} scroll="paper" fullWidth maxWidth="sm">
      <DialogTitle>Confirm</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          {icon}
          <Typography variant="h6">{title}</Typography>
          <Stack alignItems="center" spacing={1}>
            {subtitle && <Typography variant="subtitle2">{subtitle}</Typography>}
            {typeof description === "string" && <Typography color="text.secondary">{description}</Typography>}
            {typeof description !== "string" && description}
          </Stack>
        </Stack>
        {data?.Content && data.Content}
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button color="secondary" variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color={confirmButtonColor} onClick={onConfirm}>
          {confirmButtonLabel}
        </Button>
      </DialogActions>
      <BackdropLoader open={!!isLoading} />
    </Dialog>
  );
};

export default ConfirmOperationDialog;
