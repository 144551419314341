import axios from "axios";
import qs from "qs";
import { createApiResponse, createErrorApiResponse } from "../../shared/api/axiosHelper";
import { getClientCode } from "../../shared/api/clientApiContext";
import { ClientType } from "../../shared/api/clientTypes";
import { FundraisingStatus } from "../../shared/api/fundraisingTypes";
import { AccessPermission, ApiResponse, JobProgress, PaginatedList, PagingParams } from "../../shared/api/types";
import { IConsentAgreement } from "../../shared/components/consentAgreement/types";
import { TabularDataCell, TabularFieldConfiguration } from "../../shared/reporting/api/biClient.types";
import { DescriptiveDate } from "../../shared/utilities/dateFilterHelper";
import { getBackendBaseUrl } from "../../shared/variables";
import { getDefaultHeaders } from "./headers";
import { Category, PortalRole } from "./types/accessTypes";
import { CreateThreadResponse, SendMessageRequest, SendMessageResponse } from "./types/assistantTypes";
import {
  ApproveInvestorChangeRequestRequest,
  InvestorChangeRequest,
  InvestorChangeRequestDetails,
  RejectInvestorChangeRequestRequest,
  SearchInvestorChangeRequestsRequest,
} from "./types/changeRequestTypes";
import {
  ClientData,
  ClientDictionaries,
  ClientSettings,
  ClientsResponse,
  CompanyInfo,
  FundAccountingAppsStatus,
} from "./types/clientTypes";
import {
  DataCollectionSubmissionDetails,
  DataCollectionSubmissionInfo,
  DataSubmissionConfigurationInfo,
} from "./types/dataCollectionTypes";
import {
  AddChangeRequest,
  ApplyDataImportRequest,
  CreateDataImportRequest,
  DataExportRequest,
  DataExportResponse,
  DataImportApplication,
  DataImportChangeRequest,
  DataImportChangesTotals,
  DataImportFileContentValidationRequest,
  DataImportFileContentValidationResult,
  DataImportInfo,
  DataImportMetadata,
  DataImportOption,
  DataImportRollback,
  DataImportTarget,
  DataImportValidation,
  GetDataImportChangesRequest,
  SearchImportsQuery,
  UpdateChangeRequest,
} from "./types/dataImportTypes";
import {
  AssignClientToConfigurationRequest,
  CreateDataModelConfigurationRequest,
  DataModelConfiguration,
  UpdateDataModelConfigurationRequest,
} from "./types/dataModelConfigurationTypes";
import {
  AttachmentAggregatedDocumentViews,
  DocumentPresentationInfo,
  DocumentViews,
  FundraisingAggregatedDocumentViews,
  SearchDocumentViewsRequest,
} from "./types/documentActivityTypes";
import {
  DocumentCollectionDetails,
  DocumentCollectionEmailsResponse,
  DocumentCollectionsResponse,
  DocumentIdentifier,
  MessagePublishStatus,
  MessagePublishStatusFilter,
} from "./types/documentCollectionTypes";
import {
  DwhSharesResponse,
  DwhSyncInfo,
  DwhSyncLogs,
  PatchDwhShareRequest,
  PostDwhShareRequest,
  StartDwhFullSyncResponse,
} from "./types/dwhTypes";
import { SearchSentEmailsRequest, SentEmail, SentEmailsCsvParams, SentEmailsResponse } from "./types/emailsTypes";
import {
  CreateFileTagRequest,
  DataCatalogueEntityType,
  DownloadTokenResponse,
  FileDetails,
  FileDownloadInfo,
  FileInfo,
  FilesFilterRequest,
  FilesViewsResponse,
  FileTag,
  FileTagSettingsResponse,
  UpdateFileTagRequest,
} from "./types/fileTypes";
import {
  CreateFundraisingCategoryRequest,
  CreateFundraisingRequest,
  Fundraising,
  FundraisingAccessItem,
  FundraisingDocumentIdentifier,
  FundraisingInfo,
  SendTestFundraisingEmailRequest,
  UpdateFundraisingCategoryRequest,
  UpdateFundraisingRequest,
  UpdateFundraisingWithNewFundRequest,
  UpdateFundraisingWithNewFundResponse,
  UploadFundraisingFileRequest,
  UploadFundraisingFileResponse,
} from "./types/fundraisingTypes";
import {
  InvestorPortalSettings,
  InvestorPortalSettingsResponse,
  InvestorPortalSyncStatus,
  InvestorPortalWatermarkSettings,
  SendTestInvitationEmailRequest,
} from "./types/investorPortalTypes";
import { ApplicationLogs, LogsQueryParams } from "./types/logTypes";
import { CreateNoteRequest, Note, NotesEntityType, UpdateNoteRequest } from "./types/notesTypes";
import {
  AddFieldToObjectClassDefinitionResponse,
  CreateFieldRequest,
  CreateObjectRequest,
  CreateOrganizationPageLayoutRequest,
  FieldValuesResponse,
  ObjectClassDefinition,
  ObjectClassDefinitionInfo,
  ObjectClassDefinitionInfoWithPermissions,
  ObjectClassDefinitionWithPermissions,
  ObjectFieldValues,
  ObjectTypeToLookupReferences,
  ObjectWithFieldValuesBase,
  PageLayout,
  SearchObjectsRequest,
  UpdateEntityFieldsRequest,
  UpdateFieldRequest,
  UpdateObjectRequest,
  ValidateUniqueFieldRequest,
} from "./types/objectTypes";
import {
  CreatePassthroughImportRequest,
  InternalEntitiesResponse,
  PassthroughApplicationLogs,
  PassthroughCheckConnectionResponse,
  PassthroughConfiguration,
  PassthroughConnectionState,
  PassthroughFundClosingResponse,
  PassthroughFundsResponse,
} from "./types/passthroughTypes";
import { CreateMetricRequest, Metric, UpdateMetricRequest } from "./types/portfolioMonitoringTypes";
import {
  CompaniesResponse,
  CreateInvitationRequest,
  OrganizationDeactivatedMembersData,
  OrganizationMember,
  OrganizationMembersData,
  OrganizationMembersInvitationData,
  UpdateOrganizationUserAccessRequest,
} from "./types/userManagementTypes";

export interface BooleanOperationResult {
  result: boolean;
}

interface StartJobResponse {
  asyncOperationId: string;
}

export interface SearchResponse<T> {
  items: T[];
}

export interface User {
  id: string;
  name: string;
  phone: string;
  email: string;
  signInEmail: string;
  avatarImage?: string;
  consentAgreements: IConsentAgreement[];
  betaAccess?: {
    available: boolean;
    enabledByDefault: boolean;
  };
}

interface UserInfo {
  userId: string;
  userName: string;
  permissions: UserPermissionSet[];
}

interface UserPermissionSet {
  clientCode: string;
  permissions: AccessPermission[];
}

interface LoadIdentityParams {
  loadLargeAvatarImage: boolean;
}

export interface MenuBadges {
  pendingInvoiceCount: number;
  pendingInvestorChangeRequestCount: number;
}

export interface BackupMasterConnectionState {
  enabled: boolean;
  lastChangedBy?: string;
  lastChangedOn?: string;
  lastBackupDate?: string;
  isLatestBackupSuccessful: boolean;
}

export interface RunBackupOperationResponse {
  previousSyncInProgress: boolean;
  success: boolean;
  connectionFailed: boolean;
}

export interface AwsBackupConfiguration {
  accessKey: string;
  secretKey: string;
  region: string;
  bucket: string;
  path: string;
}

export interface BackupMasterConfiguration {
  awsBackupConfiguration: AwsBackupConfiguration;
  notifiedUserEmails: string[];
  hour?: number;
}

export interface BackupMasterApplicationLogs extends ApplicationLogs {
  description: string;
}

export interface BackupMasterTestConnectionResponse {
  success: boolean;
  message: string;
}

export interface UserData extends User, UserInfo, ClientsResponse {}

export interface ExpenseManagementSettings {
  inboxEmail: string;
}

export interface SignOnSettings {
  backgroundColor?: string;
  useBackgroundImage?: boolean;
  backgroundImageUrl?: string;
  clientLogoUrl?: string;
  primaryThemeColor?: string;
  errorThemeColor?: string;
}

interface ResendContactInvitationRequest {
  messageRequestId: string;
  userId: string;
}

export interface UserConsent {
  name: string;
  content?: string;
  categoryId?: string;
  activeFrom?: string | null;
  lastModified?: string;
}

export interface UserConsentContract extends Omit<UserConsent, "content"> {
  contentEncoded?: string;
}

interface ConsentsData {
  consents: UserConsent[];
  categories: Category[];
  companyLogoUrl?: string;
}

export interface ConsentsDetails {
  name: string;
  content?: string;
}

export enum ContactStatus {
  NotRegistered = 1,
  Registered = 2,
}

export type ContactWarningCode = "LastEmailDeliveryFailed";

export interface Contact {
  id: string;
  name: string;
  email: string;
  status: ContactStatus;
  lastActivity?: string;
  invitationSentAt?: string;
  registrationDate?: string;
  hasAccess: boolean;
  totalLiveMessages: number;
  warningsCodes: ContactWarningCode[];
  createdAt: string;
  updateAt: string;
}

export interface ContactWithFieldValues extends Contact, ObjectWithFieldValuesBase {
  investors: InvestorName[];
  funds: FundName[];
}

export interface InvestorName {
  id: string;
  name: string;
}

export interface FundName {
  id: string;
  name: string;
}

interface SearchContactsRequest {
  fieldIds?: string[];
  includeFunds: boolean;
  includeInvestors: boolean;
  includeInboxData: boolean;
}

export interface FundStructurePermissions {
  roles: PortalRole[];
  categories: string[];
  isPrimary: boolean;
  emailNotification: boolean;
}

export interface ContactPermissions extends FundStructurePermissions {
  investorId: string;
  investorName: string;
  fundId: string;
  fundName: string;
  fundInvestorId: string;
}

export interface InvestorContactPermissions extends FundStructurePermissions {
  contactId: string;
  contactName: string;
  contactEmail?: string;
  fundName: string;
  fundInvestorId: string;
}
export interface FundContactPermissions extends FundStructurePermissions {
  contactId: string;
  name: string;
  email?: string;
}

export interface FundPermissions {
  investorName: string;
  investorId: string;
  fundInvestorId: string;
  contacts: FundContactPermissions[];
}

export interface InvestorCommunicationMatrixUpdate {
  fundInvestorId: string;
  contactId: string;
  isPrimary?: boolean;
  emailNotification?: boolean;
  roles?: string[];
  categories?: string[];
}

export interface InvestorCommunicationMatrixRemove {
  fundInvestorId: string;
  contactId: string;
}

export interface InvestorCommunicationMatrixCreate {
  investorName: string;
  investorId?: string;
  contactId?: string;
  isPrimary: boolean;
  emailNotification: boolean;
  roles: string[];
  categories: string[];
}

export interface UpdateInvestorCommunicationMatrixRequest {
  updates: InvestorCommunicationMatrixUpdate[];
  removes: InvestorCommunicationMatrixRemove[];
  creates: InvestorCommunicationMatrixCreate[];
}

export interface InvestorPermissions {
  investorId: string;
  investorName: string;
  permissions: InvestorContactPermissions[];
}

export interface ContactDetails {
  contact: Contact;
  permissions: ContactPermissions[];
  jobTitle?: string;
  address?: string;
  address2?: string;
  country?: string;
  postCode?: string;
  city?: string;
  state?: string;
  phoneNo?: string;
  mobilePhoneNo?: string;
  homePage?: string;
  updateAt: string;
  createdAt: string;
}

export interface CreateContactData {
  name: string;
  email?: string;
  phone?: string;
}

export interface SearchInvestorDocumentsRequest {
  paging: PagingParams;
  search?: string;
  fundIds?: string[];
  fundInvestorIds?: string[];
  categoryIds?: string[];
  publishingStatusFilter: MessagePublishStatusFilter;
}

export interface FundStructureDocument {
  id: string;
  name: string;
  documentCollectionId: string;
  documentCollectionOriginatorId?: string;
  documentCollectionName: string;
  categoryId: string;
  investorName: string;
  fundNames: string[];
  isViewed: boolean;
  viewedAt?: string;
  sentAt: string;
  status: MessagePublishStatus;
  attachmentIds: string[];
  attachmentsWithViews?: Record<string, FundStructureDocumentViews>;
}

export interface FundStructureDocumentViews {
  firstViewDate?: string;
  totalViews: number;
  totalDownloads: number;
  totalViewTimeSeconds: number;
  totalCompletionPercentage: number;
}

export interface FundraisingDocument {
  id: string;
  name: string;
  fundraisingId: string;
  fundraisingName: string;
  categoryId: string;
  fundInvestors: DocumentFundInvestor[];
  isViewed: boolean;
  viewedAt?: string;
  sentAt: string;
  status: FundraisingStatus;
  views?: FundStructureDocumentViews;
}

export interface DocumentFundInvestor {
  fundId: string;
  fundName: string;
  investorId: string;
  investorName: string;
}

export interface InvestorContact {
  contactId: string;
  name: string;
  email?: string;
}

export interface InvestorFund {
  fundInvestorId: string;
  fundId: string;
  fundName: string;
}

export interface FundWithFieldValues extends Fund, ObjectWithFieldValuesBase {}

interface SearchFundsRequest {
  fieldIds?: string[];
  includeInvestorCount: boolean;
}

export interface Investor {
  id: string;
  title: string;
  funds: InvestorFund[];
  contacts: InvestorContact[];
  createdAt: string;
  updateAt: string;
}

export interface InvestorWithFieldValues extends Investor, ObjectWithFieldValuesBase {}

interface SearchInvestorsRequest {
  fieldIds?: string[];
  includeContacts: boolean;
  includeFunds: boolean;
}

interface CreateInvestorRequest {
  name: string;
}
export interface CreateInvestorResponse {
  id: string;
  name: string;
}

export interface DeleteEntityResponse {
  isDeleted: boolean;
  displayErrorMessage?: string;
}

export interface CreateFundInvestorRequest {
  fundId: string;
  investorId?: string;
  newInvestorName?: string;
  newContacts: NewContactRequest[];
  existingContacts: ExistingContactRequest[];
}

export interface UpdateFundInvestorRequest {
  newContacts: NewContactRequest[];
  existingContacts: ExistingContactRequest[];
  removedContactIds: string[];
}

interface NewContactRequest {
  name: string;
  email: string;
  roles: PortalRole[];
  externalCategoryIds: string[];
}

interface ExistingContactRequest {
  contactId: string;
  roles: PortalRole[];
  externalCategoryIds: string[];
}

export interface InvestorBalanceRequest {
  reportingDate: Date | string;
  isInvestorPortalMode: boolean;
  fields: TabularFieldConfiguration[];
}

export interface InvestorBalanceResponse {
  balanceData: { [key: string]: TabularDataCell }[];
  fundReportingDates: Record<string, string>;
}

export interface CapCallOrDistribution {
  id: string;
  investorTitle: string;
  fundName: string;
  name: string;
  status: number;
  date: string;
  amount: number;
  unfunded: number;
  transactions: CapCallOrDistributionTransaction[];
}

export type DistributionStatus = "Unpaid" | "PartlyPaid" | "Paid";

export interface CapCallOrDistributionTransaction {
  id: string;
  transactionDescription: string;
  detailsDescription: string;
  impact: CapCallOrDistributionTransactionImpact;
  netAmount: number;
  periodEndingDate: string;
  investment: string;
  currency: string;
}

export type CapCallOrDistributionTransactionImpact = "None" | "Increases" | "Decreases";

export interface CashFlowFiltersData {
  fundNames?: string[];
  paymentStatuses?: DistributionStatus[];
  from?: string;
  to?: string;
  descriptiveDate?: DescriptiveDate;
  search?: string;
  isInvestorPortalMode: boolean;
}

export interface CashFlowRequest extends CashFlowFiltersData {
  paging: PagingParams;
}

export interface ApiGatewayEligibleUser {
  userId: string;
  username: string;
  email: string;
}

export type ApiGatewaySubscriptionState = "Active" | "Suspended";

export type ApiGatewaySubscriptionType = "EntriliaApi" | "Qashqade" | "AllocatedGlAndBankAccountLedgerApi" | "Cascata";

export interface ApiGatewaySubscription {
  id: string;
  userId: string;
  username: string;
  email: string;
  state: ApiGatewaySubscriptionState;
  type: ApiGatewaySubscriptionType;
  description?: string;
  createdBy: string;
  createdByUsername?: string;
  createdAt: string;
  updatedBy: string;
  updatedByUsername?: string;
  updatedAt: string;
}

interface ApiGatewaySubscriptionsResponse {
  subscriptions: ApiGatewaySubscription[];
}

export interface AddApiGatewaySubscriptionRequest {
  userId: string;
  description?: string;
  state: ApiGatewaySubscriptionState;
  type: ApiGatewaySubscriptionType;
}

export interface UpdateApiGatewaySubscriptionRequest {
  description?: string;
  state?: ApiGatewaySubscriptionState;
}

export interface ApiGatewaySyncLogs extends ApplicationLogs {
  description: string;
  numberOfChanges: number;
}

export interface Fund {
  id: string;
  name: string;
  investorsCount?: number;
}

export interface FundDetails {
  id: string;
  name: string;
  description: string;
  stage?: FundStage;
  createdAt: string;
  updateAt?: string;
}

export type FundStage = "Investment" | "Fundraising";

export interface FundPermissionsResponse {
  fundId: string;
  fundName: string;
  permissions: FundPermissions[];
}

export enum InvoiceProcessingStatus {
  Pending = "Pending",
  TransactionCreated = "TransactionCreated",
  TransactionPosted = "TransactionPosted",
  Rejected = "Rejected",
}
export interface Invoice {
  id: string;
  status: InvoiceProcessingStatus;
  vendor?: string;
  entity?: string;
  dueDate?: string;
  amount?: number;
  receivedDate: string;
  currencyCode?: string;
  businessCentralTransactionNo?: string;
  businessCentralPostedTransactionNo?: string;
  transactionTypeCode: string;
  updateAt?: string;
  submittedToBusinessCentralAt?: string;
  postedInBusinessCentralAt?: string;
  rejectedAt?: string;
}

export interface InvoiceFieldsConfidence {
  amount?: number;
  dueDate?: number;
  postDate?: number;
  invoiceNo?: number;
  lines?: InvoiceLineFieldsConfidence[];
}

export interface InvoiceLineFieldsConfidence {
  id: number;
  description?: number;
  amount?: number;
}

export interface InvoiceDetails {
  id: string;
  catalogueId: string;
  transactionTypeCode: string;
  businessCentralTransactionNo?: string;
  businessCentralPostedTransactionNo?: string;
  status: InvoiceProcessingStatus;
  vendor?: InvoiceEntry;
  entity?: InvoiceEntry;
  invoiceNo?: string;
  postDate?: string;
  dueDate?: string;
  amount?: number;
  currencyCode?: string;
  lines?: InvoiceLine[];
  entities?: InvoiceEntry[];
  vendors?: InvoiceEntry[];
  glAccounts?: GlAccount[];
  fieldsConfidence?: InvoiceFieldsConfidence;
  deals?: InvoiceDeal[];
}

export interface InvoiceLine {
  id: number;
  description?: string;
  amount?: number;
  glAccountName?: string;
  glAccountNo?: string;
  currencyCode?: string;
  dealId?: string;
}

export interface GlAccount {
  id: string;
  no: string;
  name: string;
}

export interface InvoiceEntry {
  id: string;
  caption: string;
}

export interface InvoiceDeal {
  id: string;
  code: string;
  description: string;
}

export type DatabaseType = "Snowflake" | "AzureSQL";

interface InvoicePagingParamsWithSearch {
  paging: PagingParams;
  statuses?: InvoiceProcessingStatus[];
  searchTerm?: string;
}

export interface UpdateInvoiceRequest {
  invoiceId: string;
  payload: {
    entityId?: string;
    vendorId?: string;
    invoiceNo?: string;
    postDate?: string;
    dueDate?: string;
    amount?: number;
    currencyCode?: string;
    lines: InvoiceLine[];
  };
}

const baseUri = getBackendBaseUrl("admin");

const getIdentity = async (params: LoadIdentityParams = { loadLargeAvatarImage: false }) => {
  const { data } = await axios.get<ApiResponse<User>>(`${baseUri}/Identity`, { params });
  return data;
};

const updateUserConsents = async (consents: IConsentAgreement[]) => {
  const { data } = await axios.patch<ApiResponse<UserConsent>>(`${baseUri}/Identity`, { consentAgreements: consents });
  return data;
};

const getUserPermissions = async () => {
  const { data } = await axios.get<ApiResponse<UserInfo>>(`${baseUri}/Users/current/permissions`);
  return data;
};

const getClients = async () => {
  const { data } = await axios.get<ApiResponse<ClientsResponse>>(`${baseUri}/Clients`);
  return data;
};

const getUserData = async (user: User): Promise<ApiResponse<UserData>> => {
  const [userInfoResp, clientsResp] = await Promise.all([getUserPermissions(), getClients()]);

  if (!userInfoResp.success) {
    return createErrorApiResponse(userInfoResp.error);
  }
  if (!clientsResp.success) {
    return createErrorApiResponse(clientsResp.error);
  }

  return createApiResponse({
    ...user,
    ...userInfoResp.data,
    ...clientsResp.data,
  });
};

const getFundAccountingAppsStatus = async () => {
  const { data } = await axios.get<ApiResponse<FundAccountingAppsStatus>>(`${baseUri}/FundAccountingApps/status`, {
    headers: getDefaultHeaders(),
    timeout: 10_000,
  });
  return data;
};

const resendInvitationEmailByMessage = async (request: ResendContactInvitationRequest): Promise<ApiResponse<null>> => {
  const { data } = await axios.post<ApiResponse<null>>(`${baseUri}/Contacts/resend-invitation-by-message`, request, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const sendInvitations = async (request: CreateInvitationRequest): Promise<ApiResponse<null>> => {
  const { data } = await axios.post<ApiResponse<null>>(`${baseUri}/Invitations`, request, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getSignOnSettings = async (): Promise<ApiResponse<SignOnSettings>> => {
  const { data } = await axios.get<ApiResponse<SignOnSettings>>(`${baseUri}/SignOnSettings/`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateSignOnSettings = async (formData: FormData): Promise<ApiResponse<SignOnSettings>> => {
  const { data } = await axios.patch<ApiResponse<SignOnSettings>>(`${baseUri}/SignOnSettings`, formData, {
    headers: {
      ...getDefaultHeaders(),
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

const getClientDictionaries = async (clientType: ClientType): Promise<ApiResponse<ClientDictionaries>> => {
  if (clientType === "FundAdmin") {
    return createApiResponse({
      countries: [],
      states: [],
      currencies: [],
      taxForms: [],
      investorCategories: [],
      formPfNames: [],
      chapter3Statuses: [],
      chapter4FatcaStatuses: [],
      irs1099Codes: [],
      industriesAndSectors: [],
      geographies: [],
    });
  }

  const { data } = await axios.get<ApiResponse<ClientDictionaries>>(`${baseUri}/dictionaries`, {
    headers: getDefaultHeaders(),
  });

  return data;
};

const getMenuBadges = async (): Promise<ApiResponse<MenuBadges>> => {
  const { data } = await axios.get<ApiResponse<MenuBadges>>(`${baseUri}/MenuBadges`, {
    headers: getDefaultHeaders(),
  });

  return data;
};

const getClientSettings = async (): Promise<ApiResponse<ClientSettings>> => {
  const { data } = await axios.get<ApiResponse<ClientSettings>>(`${baseUri}/clients/settings`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getClientData = async (clientType: ClientType): Promise<ApiResponse<ClientData>> => {
  if (!getClientCode()) {
    return createErrorApiResponse({ message: "Cannot fetch client data without client code" });
  }

  const [settingsResp, dictionariesResp] = await Promise.all([getClientSettings(), getClientDictionaries(clientType)]);

  if (!settingsResp.success) {
    return createErrorApiResponse(settingsResp.error);
  }

  if (!dictionariesResp.success) {
    return createErrorApiResponse(dictionariesResp.error);
  }

  return createApiResponse({
    settings: settingsResp.data,
    dictionaries: dictionariesResp.data,
  });
};

const updateCompanySettings = async (formData: FormData): Promise<ApiResponse<CompanyInfo>> => {
  const { data } = await axios.patch<ApiResponse<CompanyInfo>>(`${baseUri}/clients/settings`, formData, {
    headers: {
      ...getDefaultHeaders(),
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

// Document Collections

const getDocumentCollections = async (): Promise<ApiResponse<DocumentCollectionsResponse>> => {
  const { data } = await axios.get<ApiResponse<DocumentCollectionsResponse>>(`${baseUri}/DocumentCollections`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDocumentCollectionDetails = async (id: string): Promise<ApiResponse<DocumentCollectionDetails>> => {
  const { data } = await axios.get<ApiResponse<DocumentCollectionDetails>>(`${baseUri}/DocumentCollections/${id}`, {
    headers: getDefaultHeaders(),
  });

  return data;
};

const getDocumentCollectionEmails = async (id: string): Promise<ApiResponse<DocumentCollectionEmailsResponse>> => {
  const { data } = await axios.get<ApiResponse<DocumentCollectionEmailsResponse>>(
    `${baseUri}/DocumentCollections/${id}/email-tracking`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getDocCollectionFileDownloadInfo = async (
  postMessageRequestId: string,
  attachmentId: string
): Promise<ApiResponse<FileDownloadInfo>> => {
  const { data } = await axios.get<ApiResponse<FileDownloadInfo>>(
    `${baseUri}/DocumentCollections/messages/${postMessageRequestId}/attachments/${attachmentId}`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const createArchiveDownloadUrl = (clientCode: string, token: string) =>
  `${baseUri}/DocumentCollections/documents?&client=${clientCode}&token=${token}`;

const getDownloadUrlForDocumentCollection = async (id: string, clientCode: string): Promise<ApiResponse<string>> => {
  const { data } = await axios.post<ApiResponse<DownloadTokenResponse>>(
    `${baseUri}/DocumentCollections/${id}/download`,
    null,
    {
      headers: getDefaultHeaders(),
    }
  );

  return data.success
    ? createApiResponse(createArchiveDownloadUrl(clientCode, data.data.token))
    : createErrorApiResponse(data.error);
};

const getDownloadUrlForSelectedDocuments = async (
  selectedDocuments: DocumentIdentifier[],
  clientCode: string
): Promise<ApiResponse<string>> => {
  const { data } = await axios.post<ApiResponse<DownloadTokenResponse>>(
    `${baseUri}/DocumentCollections/download`,
    {
      selectedDocuments,
    },
    {
      headers: getDefaultHeaders(),
    }
  );

  return data.success
    ? createApiResponse(createArchiveDownloadUrl(clientCode, data.data.token))
    : createErrorApiResponse(data.error);
};

// Emails

const searchSentEmails = async (requestBody: SearchSentEmailsRequest): Promise<ApiResponse<SentEmailsResponse>> => {
  const { data } = await axios.post<ApiResponse<SentEmailsResponse>>(`${baseUri}/Emails/search`, requestBody, {
    headers: getDefaultHeaders(),
  });

  return data;
};

const getSentEmailsCsv = async (params: SentEmailsCsvParams): Promise<string> => {
  const { data } = await axios.get<string>(`${baseUri}/Emails/csv`, {
    params,
    headers: getDefaultHeaders(),
  });

  return data;
};

// User Management

const getOrganizationManagedCompanies = async (): Promise<ApiResponse<CompaniesResponse>> => {
  const { data } = await axios.get<ApiResponse<CompaniesResponse>>(
    `${baseUri}/UsersManagement/organization-companies`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getOrganizationMembers = async (): Promise<ApiResponse<OrganizationMembersData>> => {
  const { data } = await axios.get<ApiResponse<OrganizationMembersData>>(
    `${baseUri}/UsersManagement/organization-users`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getOrganizationDeactivatedMembers = async (): Promise<ApiResponse<OrganizationDeactivatedMembersData>> => {
  const { data } = await axios.get<ApiResponse<OrganizationDeactivatedMembersData>>(
    `${baseUri}/UsersManagement/organization-deactivated-users`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const deleteOrganizationMember = async (userId: string): Promise<ApiResponse<boolean>> => {
  const { data } = await axios.delete<ApiResponse<boolean>>(`${baseUri}/UsersManagement/organization-users/${userId}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getOrganizationMembersInvitations = async (): Promise<ApiResponse<OrganizationMembersInvitationData>> => {
  const { data } = await axios.get<ApiResponse<OrganizationMembersInvitationData>>(
    `${baseUri}/UsersManagement/organization-invited-users`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const deleteOrganizationUserInvite = async (inviteId: string): Promise<ApiResponse<boolean>> => {
  const { data } = await axios.delete<ApiResponse<boolean>>(
    `${baseUri}/UsersManagement/organization-invited-users/${inviteId}`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const resendOrganizationUserInvite = async (inviteId: string): Promise<ApiResponse<SentEmailsResponse>> => {
  const { data } = await axios.post<ApiResponse<SentEmailsResponse>>(
    `${baseUri}/UsersManagement/organization-invited-users/resend-email/${inviteId}`,
    null,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const updateOrganizationMemberAccess = async (
  userId: string,
  access: UpdateOrganizationUserAccessRequest
): Promise<ApiResponse<OrganizationMember>> => {
  const { data } = await axios.put<ApiResponse<OrganizationMember>>(
    `${baseUri}/UsersManagement/organization-invited-users/${userId}/access`,
    access,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const consentManagementEndpointBaseUri = `${baseUri}/InvestorPortalConsentsManagement`;
const getIrConsentsData = async (): Promise<ApiResponse<ConsentsData>> => {
  const { data } = await axios.get<ApiResponse<ConsentsData>>(`${consentManagementEndpointBaseUri}/consents`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getIrConsentDetails = async (consentName: string): Promise<ApiResponse<ConsentsDetails>> => {
  const { data } = await axios.get<ApiResponse<ConsentsDetails>>(
    `${consentManagementEndpointBaseUri}/consent/${consentName}/details`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const createIrConsent = async (consent: UserConsentContract): Promise<ApiResponse<UserConsent>> => {
  const { data } = await axios.post<ApiResponse<UserConsent>>(`${consentManagementEndpointBaseUri}/consent`, consent, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateIrConsent = async (
  consent: UserConsentContract,
  updateVersion: boolean
): Promise<ApiResponse<UserConsent>> => {
  const { data } = await axios.patch<ApiResponse<UserConsent>>(
    `${consentManagementEndpointBaseUri}/consent/${consent.name}?updateVersion=${updateVersion}`,
    consent,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const deleteIrConsent = async (consent: UserConsent): Promise<ApiResponse<boolean>> => {
  const { data } = await axios.delete<ApiResponse<boolean>>(
    `${consentManagementEndpointBaseUri}/consent/${consent.name}`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const sendInvitationToContacts = async (contactIds: string[]) => {
  const { data } = await axios.post<ApiResponse<StartJobResponse>>(
    `${baseUri}/contacts/start-send-invitation`,
    { contactIds },
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getContactsJobProgress = async (asyncOperationId: string) => {
  const { data } = await axios.get<ApiResponse<JobProgress>>(`${baseUri}/contacts/jobs/${asyncOperationId}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const exportContactsToCsv = async (ids: string[]): Promise<string> => {
  const url = `${baseUri}/contacts/export-csv${ids.length > 0 ? `?ids=${ids.join("&ids=")}` : ""}`;

  const { data } = await axios.get<string>(url, {
    headers: getDefaultHeaders(),
  });

  return data;
};

const getContactDetails = async (id: string) => {
  const { data } = await axios.get<ApiResponse<ContactDetails>>(`${baseUri}/contacts/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createContact = async (payload: CreateInvestorRequest): Promise<ApiResponse<CreateInvestorResponse>> => {
  const { data } = await axios.post<ApiResponse<CreateInvestorResponse>>(`${baseUri}/contacts`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const deleteContact = async (contactId: string): Promise<ApiResponse<DeleteEntityResponse>> => {
  const { data } = await axios.delete<ApiResponse<DeleteEntityResponse>>(`${baseUri}/contacts/${contactId}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getContactDocuments = async (contactId: string) => {
  const { data } = await axios.get<ApiResponse<FundStructureDocument[]>>(`${baseUri}/contacts/${contactId}/documents`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getContactFundraisingDocuments = async (contactId: string) => {
  const { data } = await axios.get<ApiResponse<FundraisingDocument[]>>(
    `${baseUri}/contacts/${contactId}/fundraising-documents`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getContactEmails = async (contactId: string): Promise<ApiResponse<SentEmail[]>> => {
  const { data } = await axios.get<ApiResponse<SentEmail[]>>(`${baseUri}/contacts/${contactId}/emails`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getAccessCategories = async () => {
  const { data } = await axios.get<ApiResponse<Category[]>>(`${baseUri}/categories/access`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getInvestorDetails = async (id: string): Promise<ApiResponse<Investor>> => {
  const { data } = await axios.get<ApiResponse<Investor>>(`${baseUri}/investors/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createInvestor = async (payload: CreateInvestorRequest): Promise<ApiResponse<CreateInvestorResponse>> => {
  const { data } = await axios.post<ApiResponse<CreateInvestorResponse>>(`${baseUri}/investors`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const deleteInvestor = async (investorId: string): Promise<ApiResponse<DeleteEntityResponse>> => {
  const { data } = await axios.delete<ApiResponse<DeleteEntityResponse>>(`${baseUri}/investors/${investorId}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getFundDetails = async (id: string): Promise<ApiResponse<FundDetails>> => {
  const { data } = await axios.get<ApiResponse<FundDetails>>(`${baseUri}/funds/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getFundPermissions = async (fundId: string): Promise<ApiResponse<FundPermissionsResponse>> => {
  const { data } = await axios.get<ApiResponse<FundPermissionsResponse>>(`${baseUri}/funds/${fundId}/permissions`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const searchFundDocuments = async (
  fundId: string,
  searchRequest: SearchInvestorDocumentsRequest
): Promise<ApiResponse<PaginatedList<FundStructureDocument>>> => {
  const { data } = await axios.post<ApiResponse<PaginatedList<FundStructureDocument>>>(
    `${baseUri}/funds/${fundId}/investor-documents-search`,
    searchRequest,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const updateFundCommunicationMatrix = async (
  fundId: string,
  payload: UpdateInvestorCommunicationMatrixRequest
): Promise<ApiResponse<FundPermissionsResponse>> => {
  const { data } = await axios.patch<ApiResponse<FundPermissionsResponse>>(
    `${baseUri}/funds/${fundId}/communication-matrix`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const createAssistantThread = async (): Promise<ApiResponse<CreateThreadResponse>> => {
  const { data } = await axios.post<ApiResponse<CreateThreadResponse>>(`${baseUri}/Assistant/threads`, null, {
    headers: getDefaultHeaders(),
  });

  return data;
};

const sendMessageToAssistant = async (
  request: SendMessageRequest,
  signal?: AbortSignal
): Promise<ApiResponse<SendMessageResponse>> => {
  const { data } = await axios.post<ApiResponse<SendMessageResponse>>(`${baseUri}/Assistant/messages`, request, {
    headers: getDefaultHeaders(),
    signal,
  });

  return data;
};

const searchInvestorDocuments = async (
  investorId: string,
  searchRequest: SearchInvestorDocumentsRequest
): Promise<ApiResponse<PaginatedList<FundStructureDocument>>> => {
  const { data } = await axios.post<ApiResponse<PaginatedList<FundStructureDocument>>>(
    `${baseUri}/investors/${investorId}/investor-documents-search`,
    searchRequest,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getInvestorFundraisingDocuments = async (investorId: string) => {
  const { data } = await axios.get<ApiResponse<FundraisingDocument[]>>(
    `${baseUri}/investors/${investorId}/fundraising-documents`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getInvestorPermissions = async (investorId: string): Promise<ApiResponse<InvestorPermissions>> => {
  const { data } = await axios.get<ApiResponse<InvestorPermissions>>(`${baseUri}/investors/${investorId}/permissions`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getInitialInvestmentsData = async (): Promise<ApiResponse<Date[]>> => {
  const { data } = await axios.get<ApiResponse<Date[]>>(`${baseUri}/investors/reporting-periods`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getInvestorBalance = async (
  investorId: string,
  request: InvestorBalanceRequest
): Promise<ApiResponse<InvestorBalanceResponse>> => {
  const { data } = await axios.post<ApiResponse<InvestorBalanceResponse>>(
    `${baseUri}/investors/${investorId}/balance`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getInvestorCapitalActivity = async (
  investorId: string,
  request: CashFlowRequest
): Promise<ApiResponse<CapCallOrDistribution[]>> => {
  const { data } = await axios.post<ApiResponse<CapCallOrDistribution[]>>(
    `${baseUri}/investors/${investorId}/capital-activity`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const updateInvestorCommunicationMatrix = async (
  payload: UpdateInvestorCommunicationMatrixRequest
): Promise<ApiResponse<void>> => {
  const { data } = await axios.patch<ApiResponse<void>>(`${baseUri}/investors/communication-matrix`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createFundInvestor = async (payload: CreateFundInvestorRequest) => {
  const { data } = await axios.post<ApiResponse<void>>(`${baseUri}/FundInvestors`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateFundInvestor = async (id: string, payload: UpdateFundInvestorRequest) => {
  const { data } = await axios.patch<ApiResponse<void>>(`${baseUri}/FundInvestors/${id}`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

// Specific Object Entities

const searchFunds = async (request: SearchFundsRequest) => {
  const { data } = await axios.post<ApiResponse<SearchResponse<FundWithFieldValues>>>(
    `${baseUri}/FundsByObjectType/search`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getFundFieldValues = async (fundId: string) => {
  const { data } = await axios.get<ApiResponse<FieldValuesResponse>>(`${baseUri}/FundsByObjectType/${fundId}/fields`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateFundFieldValues = async (fundId: string, payload: UpdateEntityFieldsRequest) => {
  const { data } = await axios.patch<ApiResponse<FieldValuesResponse>>(
    `${baseUri}/FundsByObjectType/${fundId}/fields`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const searchInvestors = async (request: SearchInvestorsRequest) => {
  const { data } = await axios.post<ApiResponse<SearchResponse<InvestorWithFieldValues>>>(
    `${baseUri}/InvestorsByObjectType/search`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getInvestorFieldValues = async (investorId: string) => {
  const { data } = await axios.get<ApiResponse<FieldValuesResponse>>(
    `${baseUri}/InvestorsByObjectType/${investorId}/fields`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const updateInvestorFieldValues = async (investorId: string, payload: UpdateEntityFieldsRequest) => {
  const { data } = await axios.patch<ApiResponse<FieldValuesResponse>>(
    `${baseUri}/InvestorsByObjectType/${investorId}/fields`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const searchContacts = async (request: SearchContactsRequest) => {
  const { data } = await axios.post<ApiResponse<SearchResponse<ContactWithFieldValues>>>(
    `${baseUri}/ContactsByObjectType/search`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getContactFieldValues = async (contactId: string) => {
  const { data } = await axios.get<ApiResponse<FieldValuesResponse>>(
    `${baseUri}/ContactsByObjectType/${contactId}/fields`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const updateContactFieldValues = async (contactId: string, payload: UpdateEntityFieldsRequest) => {
  const { data } = await axios.patch<ApiResponse<FieldValuesResponse>>(
    `${baseUri}/ContactsByObjectType/${contactId}/fields`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

// Objects

const getObjectDefinition = async (objectType: string) => {
  const { data } = await axios.get<ApiResponse<ObjectClassDefinitionWithPermissions>>(
    `${baseUri}/Objects/${objectType}/definition`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getAvailableObjectDefinitions = async () => {
  const { data } = await axios.get<ApiResponse<ObjectClassDefinitionInfoWithPermissions[]>>(
    `${baseUri}/Objects/definitions`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const searchObjects = async (objectType: string, payload: SearchObjectsRequest) => {
  const { data } = await axios.post<ApiResponse<PaginatedList<ObjectFieldValues>>>(
    `${baseUri}/Objects/${objectType}/search`,
    payload,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getObjectById = async (objectType: string, id: string) => {
  const { data } = await axios.get<ApiResponse<ObjectFieldValues>>(`${baseUri}/Objects/${objectType}/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createObject = async (objectType: string, payload: CreateObjectRequest) => {
  const { data } = await axios.post<ApiResponse<ObjectFieldValues>>(`${baseUri}/Objects/${objectType}`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateObject = async (objectType: string, id: string, payload: UpdateObjectRequest) => {
  const { data } = await axios.patch<ApiResponse<ObjectFieldValues>>(
    `${baseUri}/Objects/${objectType}/${id}`,
    payload,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const deleteObject = async (objectType: string, id: string) => {
  const { data } = await axios.delete<ApiResponse<BooleanOperationResult>>(`${baseUri}/Objects/${objectType}/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateObjectLogo = async (objectType: string, id: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const { data } = await axios.put<ApiResponse<ObjectFieldValues>>(
    `${baseUri}/Objects/${objectType}/${id}/logo`,
    formData,
    { headers: { ...getDefaultHeaders(), "Content-Type": "multipart/form-data" } }
  );

  return data;
};

const removeObjectLogo = async (objectType: string, id: string) => {
  const { data } = await axios.delete<ApiResponse<ObjectFieldValues>>(`${baseUri}/Objects/${objectType}/${id}/logo`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const validateUniqueField = async (objectType: string, payload: ValidateUniqueFieldRequest) => {
  const { data } = await axios.post<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/Objects/${objectType}/validate-unique-field`,
    payload,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getLookupOptions = async (objectType: string) => {
  const { data } = await axios.get<ApiResponse<ObjectTypeToLookupReferences>>(
    `${baseUri}/Objects/${objectType}/lookup-options`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

// Object Page Layouts

const getCardLayout = async (objectType: string) => {
  const { data } = await axios.get<ApiResponse<PageLayout>>(`${baseUri}/PageLayouts/${objectType}/card`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

// Notes

const getNotes = async (entityType: NotesEntityType, entityId: string, objectType?: string) => {
  const { data } = await axios.get<ApiResponse<Note[]>>(`${baseUri}/notes/${entityType}/${entityId}/notes`, {
    headers: getDefaultHeaders(),
    params: { objectType },
  });
  return data;
};

const createNote = async (
  entityType: NotesEntityType,
  entityId: string,
  payload: CreateNoteRequest,
  objectType?: string
) => {
  const { data } = await axios.post<ApiResponse<Note>>(`${baseUri}/notes/${entityType}/${entityId}/notes`, payload, {
    headers: getDefaultHeaders(),
    params: { objectType },
  });
  return data;
};

const updateNote = async (
  entityType: NotesEntityType,
  entityId: string,
  noteId: string,
  payload: UpdateNoteRequest,
  objectType?: string
) => {
  const { data } = await axios.patch<ApiResponse<Note>>(
    `${baseUri}/notes/${entityType}/${entityId}/notes/${noteId}`,
    payload,
    {
      headers: getDefaultHeaders(),
      params: { objectType },
    }
  );
  return data;
};

const deleteNote = async (entityType: NotesEntityType, entityId: string, noteId: string, objectType?: string) => {
  const { data } = await axios.delete<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/notes/${entityType}/${entityId}/notes/${noteId}`,
    {
      headers: getDefaultHeaders(),
      params: { objectType },
    }
  );
  return data;
};

// DocumentManagement

const getFilesViews = async (): Promise<ApiResponse<FilesViewsResponse>> => {
  const { data } = await axios.get<ApiResponse<FilesViewsResponse>>(`${baseUri}/documentManagement/views`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getFileTagSettings = async (): Promise<ApiResponse<FileTagSettingsResponse>> => {
  const { data } = await axios.get<ApiResponse<FileTagSettingsResponse>>(`${baseUri}/documentManagement/tag-settings`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createFileTag = async (request: CreateFileTagRequest): Promise<ApiResponse<FileTag>> => {
  const { data } = await axios.post<ApiResponse<FileTag>>(`${baseUri}/documentManagement/tag`, request, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateFileTag = async (catalogueId: string, request: UpdateFileTagRequest): Promise<ApiResponse<FileTag>> => {
  const { data } = await axios.patch<ApiResponse<FileTag>>(
    `${baseUri}/documentManagement/tag/${catalogueId}`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const deleteFileTag = async (catalogueId: string): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.delete<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/documentManagement/tag/${catalogueId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getCompanyFiles = async (
  filter: FilesFilterRequest,
  paging: PagingParams
): Promise<ApiResponse<PaginatedList<FileInfo>>> => {
  const { data } = await axios.get<ApiResponse<PaginatedList<FileInfo>>>(`${baseUri}/documentManagement/files`, {
    params: { ...filter, ...paging },
    headers: getDefaultHeaders(),
  });
  return data;
};

const getFileDownloadInfo = async (catalogueId: string): Promise<ApiResponse<FileDownloadInfo>> => {
  const { data } = await axios.get<ApiResponse<FileDownloadInfo>>(
    `${baseUri}/documentManagement/files/${catalogueId}`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getDownloadUrlForMultipleFiles = async (
  catalogueIds: string[],
  fileName: string
): Promise<ApiResponse<string | undefined>> => {
  const { data } = await axios.post<ApiResponse<string>>(
    `${baseUri}/documentManagement/files/token`,
    { catalogueIds },
    { headers: getDefaultHeaders() }
  );

  const token = data.data;
  const query = qs.stringify({
    fileName,
    token,
    clientCode: getClientCode(),
  });

  return token
    ? createApiResponse(`${baseUri}/documentManagement/multiple-files?${query}`)
    : createErrorApiResponse(data.error);
};

const renameFile = async (catalogueId: string, fileName: string): Promise<ApiResponse<FileInfo>> => {
  const { data } = await axios.patch<ApiResponse<FileInfo>>(
    `${baseUri}/documentManagement/files/${catalogueId}/rename`,
    { fileName },
    { headers: getDefaultHeaders() }
  );
  return data;
};

const deleteFiles = async (catalogueIds: string[]): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.delete<ApiResponse<BooleanOperationResult>>(`${baseUri}/documentManagement/files`, {
    params: { catalogueIds },
    headers: getDefaultHeaders(),
  });
  return data;
};

const getFileDetails = async (catalogueId: string): Promise<ApiResponse<FileDetails>> => {
  const { data } = await axios.get<ApiResponse<FileDetails>>(
    `${baseUri}/documentManagement/files/${catalogueId}/details`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

// EntityDocumentManagement

const getFilesViewsForEntityType = async (entityType: DataCatalogueEntityType, objectType: string | undefined) => {
  const { data } = await axios.get<ApiResponse<FilesViewsResponse>>(
    `${baseUri}/entityDocumentManagement/${entityType}/views`,
    { params: { objectType }, headers: getDefaultHeaders() }
  );
  return data;
};

const getFileTagsForEntityType = async (entityType: DataCatalogueEntityType, objectType: string | undefined) => {
  const { data } = await axios.get<ApiResponse<FileTag[]>>(`${baseUri}/entityDocumentManagement/${entityType}/tags`, {
    params: { objectType },
    headers: getDefaultHeaders(),
  });
  return data;
};

const getEntityFiles = async (
  entityType: DataCatalogueEntityType,
  entityId: string,
  objectType: string | undefined,
  filter: FilesFilterRequest,
  paging: PagingParams
) => {
  const { data } = await axios.get<ApiResponse<PaginatedList<FileInfo>>>(
    `${baseUri}/entityDocumentManagement/${entityType}/${entityId}/files`,
    { params: { ...filter, ...paging, objectType }, headers: getDefaultHeaders() }
  );
  return data;
};

const getEntityFileDownloadInfo = async (
  entityType: DataCatalogueEntityType,
  entityId: string,
  objectType: string | undefined,
  catalogueId: string
) => {
  const { data } = await axios.get<ApiResponse<FileDownloadInfo>>(
    `${baseUri}/entityDocumentManagement/${entityType}/${entityId}/files/${catalogueId}`,
    { params: { objectType }, headers: getDefaultHeaders() }
  );
  return data;
};

const getDownloadUrlForMultipleEntityFiles = async (
  entityType: DataCatalogueEntityType,
  entityId: string,
  objectType: string | undefined,
  catalogueIds: string[],
  fileName: string
): Promise<ApiResponse<string | undefined>> => {
  const { data } = await axios.post<ApiResponse<string>>(
    `${baseUri}/entityDocumentManagement/${entityType}/${entityId}/files/token`,
    { catalogueIds },
    { params: { objectType }, headers: getDefaultHeaders() }
  );

  const token = data.data;
  const query = qs.stringify({
    fileName,
    token,
    clientCode: getClientCode(),
  });

  return token
    ? createApiResponse(`${baseUri}/documentManagement/multiple-files?${query}`)
    : createErrorApiResponse(data.error);
};

const renameEntityFile = async (
  entityType: DataCatalogueEntityType,
  entityId: string,
  objectType: string | undefined,
  catalogueId: string,
  fileName: string
) => {
  const { data } = await axios.patch<ApiResponse<FileInfo>>(
    `${baseUri}/entityDocumentManagement/${entityType}/${entityId}/files/${catalogueId}/rename`,
    { fileName },
    { params: { objectType }, headers: getDefaultHeaders() }
  );
  return data;
};

const detachEntityFiles = async (
  entityType: DataCatalogueEntityType,
  entityId: string,
  objectType: string | undefined,
  catalogueIds: string[]
) => {
  const { data } = await axios.delete<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/entityDocumentManagement/${entityType}/${entityId}/files`,
    {
      params: { objectType, catalogueIds },
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getEntityFileDetails = async (
  entityType: DataCatalogueEntityType,
  entityId: string,
  objectType: string | undefined,
  catalogueId: string
) => {
  const { data } = await axios.get<ApiResponse<FileDetails>>(
    `${baseUri}/entityDocumentManagement/${entityType}/${entityId}/files/${catalogueId}/details`,
    { params: { objectType }, headers: getDefaultHeaders() }
  );
  return data;
};

const uploadEntityFile = async (
  entityType: DataCatalogueEntityType,
  entityId: string,
  objectType: string | undefined,
  file: File,
  tagId?: string
) => {
  const formData = new FormData();
  formData.append("file", file);
  if (tagId) {
    formData.append("tagId", tagId);
  }

  const { data } = await axios.post<ApiResponse<FileInfo>>(
    `${baseUri}/entityDocumentManagement/${entityType}/${entityId}/files`,
    formData,
    {
      params: { objectType },
      headers: {
        ...getDefaultHeaders(),
        "Content-Type": "multipart/form-data; boundary=fileContent",
      },
    }
  );
  return data;
};

// ExpenseManagement
const getExpenseManagementSettings = async (): Promise<ApiResponse<ExpenseManagementSettings>> => {
  const { data } = await axios.get<ApiResponse<ExpenseManagementSettings>>(`${baseUri}/expenseManagement/settings`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getInvoices = async (request: InvoicePagingParamsWithSearch): Promise<ApiResponse<PaginatedList<Invoice>>> => {
  const { data } = await axios.post<ApiResponse<PaginatedList<Invoice>>>(
    `${baseUri}/expenseManagement/invoices/search`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getInvoiceDetails = async (invoiceId: string): Promise<ApiResponse<InvoiceDetails>> => {
  const { data } = await axios.get<ApiResponse<InvoiceDetails>>(`${baseUri}/expenseManagement/invoices/${invoiceId}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateInvoice = async (request: UpdateInvoiceRequest): Promise<ApiResponse<unknown>> => {
  const { data } = await axios.put<ApiResponse<UpdateInvoiceRequest>>(
    `${baseUri}/expenseManagement/invoices/${request.invoiceId}`,
    request.payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const postInvoice = async (invoiceId: string): Promise<ApiResponse<InvoiceDetails>> => {
  const { data } = await axios.post<ApiResponse<InvoiceDetails>>(
    `${baseUri}/expenseManagement/invoices/${invoiceId}/submit-to-accounting`,
    {},
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const rejectInvoice = async (invoiceId: string): Promise<ApiResponse<unknown>> => {
  const { data } = await axios.post<ApiResponse<UpdateInvoiceRequest>>(
    `${baseUri}/expenseManagement/invoices/${invoiceId}/reject`,
    {},
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getInvoiceDownloadFileInfo = async (invoiceId: string): Promise<ApiResponse<FileDownloadInfo>> => {
  const { data } = await axios.get<ApiResponse<FileDownloadInfo>>(
    `${baseUri}/expenseManagement/invoices/${invoiceId}/file`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

// DWH
const getDwhShares = async (): Promise<ApiResponse<DwhSharesResponse>> => {
  const { data } = await axios.get<ApiResponse<DwhSharesResponse>>(`${baseUri}/dwh/shares`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const postDwhShare = async (payload: PostDwhShareRequest): Promise<ApiResponse<null>> => {
  const { data } = await axios.post<ApiResponse<null>>(`${baseUri}/dwh/shares`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const patchDwhShare = async (id: string, payload: PatchDwhShareRequest): Promise<ApiResponse<null>> => {
  const { data } = await axios.patch<ApiResponse<null>>(`${baseUri}/dwh/shares/${id}`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const deleteDwhShare = async (id: string): Promise<ApiResponse<null>> => {
  const { data } = await axios.delete<ApiResponse<null>>(`${baseUri}/dwh/shares/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDwhLogs = async (params: LogsQueryParams): Promise<ApiResponse<PaginatedList<DwhSyncLogs>>> => {
  const { data } = await axios.get<ApiResponse<PaginatedList<DwhSyncLogs>>>(`${baseUri}/dwh/logs`, {
    params,
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDwhLastSync = async (): Promise<ApiResponse<DwhSyncInfo>> => {
  const { data } = await axios.get<ApiResponse<DwhSyncInfo>>(`${baseUri}/dwh/last-sync`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const startDwhFullSync = async (): Promise<ApiResponse<StartDwhFullSyncResponse>> => {
  const { data } = await axios.post<ApiResponse<StartDwhFullSyncResponse>>(`${baseUri}/dwh/start-dwh-full-sync`, null, {
    headers: getDefaultHeaders(),
  });
  return data;
};

// API Gateway
const getApiGatewayEligibleUsers = async (): Promise<ApiResponse<ApiGatewayEligibleUser[]>> => {
  const { data } = await axios.get<ApiResponse<ApiGatewayEligibleUser[]>>(`${baseUri}/ApiGateway/eligible-users`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getApiGatewaySubscriptions = async (): Promise<ApiResponse<ApiGatewaySubscriptionsResponse>> => {
  const { data } = await axios.get<ApiResponse<ApiGatewaySubscriptionsResponse>>(
    `${baseUri}/ApiGateway/subscriptions`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const addApiGatewaySubscription = async (
  request: AddApiGatewaySubscriptionRequest
): Promise<ApiResponse<ApiGatewaySubscription>> => {
  const { data } = await axios.post<ApiResponse<ApiGatewaySubscription>>(
    `${baseUri}/ApiGateway/subscriptions`,
    request,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const updateApiGatewaySubscription = async (
  id: string,
  request: UpdateApiGatewaySubscriptionRequest
): Promise<ApiResponse<ApiGatewaySubscription>> => {
  const { data } = await axios.patch<ApiResponse<ApiGatewaySubscription>>(
    `${baseUri}/ApiGateway/subscriptions/${id}`,
    request,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const deleteApiGatewaySubscription = async (id: string): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.delete<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/ApiGateway/subscriptions/${id}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getApiGatewayLogs = async (params: LogsQueryParams): Promise<ApiResponse<PaginatedList<ApiGatewaySyncLogs>>> => {
  const { data } = await axios.get<ApiResponse<PaginatedList<ApiGatewaySyncLogs>>>(`${baseUri}/ApiGateway/logs`, {
    params,
    headers: getDefaultHeaders(),
  });
  return data;
};

// Investor Portal

const getInvestorPortalSettings = async () => {
  const { data } = await axios.get<ApiResponse<InvestorPortalSettingsResponse>>(`${baseUri}/InvestorPortalSettings`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateInvestorPortalSettings = async (settings: FormData | Partial<InvestorPortalSettings>) => {
  const isFormData = settings instanceof FormData;
  const { data } = await axios.patch<ApiResponse<InvestorPortalSettingsResponse>>(
    `${baseUri}/InvestorPortalSettings/${isFormData ? "form" : ""}`,
    settings,
    {
      headers: {
        ...getDefaultHeaders(),
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      },
    }
  );
  return data;
};

const sendTestInvitationEmail = async (request: SendTestInvitationEmailRequest) => {
  const { data } = await axios.post<ApiResponse<null>>(
    `${baseUri}/InvestorPortalSettings/sendTestInvitationEmail`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getWatermarkSettings = async () => {
  const { data } = await axios.get<ApiResponse<InvestorPortalWatermarkSettings>>(
    `${baseUri}/InvestorPortalSettings/watermark-settings`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const updateWatermarkSettings = async (settings: InvestorPortalWatermarkSettings) => {
  const { data } = await axios.put<ApiResponse<InvestorPortalWatermarkSettings>>(
    `${baseUri}/InvestorPortalSettings/watermark-settings`,
    settings,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getInvestorPortalSyncStatus = async () => {
  const { data } = await axios.get<ApiResponse<InvestorPortalSyncStatus>>(`${baseUri}/InvestorPortalSyncStatus`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

// Backup Master

const getDataBackupConnectionState = async (): Promise<ApiResponse<BackupMasterConnectionState>> => {
  const { data } = await axios.get<ApiResponse<BackupMasterConnectionState>>(
    `${baseUri}/BackupMaster/connection-state`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getDataBackupConfiguration = async (): Promise<ApiResponse<BackupMasterConfiguration>> => {
  const { data } = await axios.get<ApiResponse<BackupMasterConfiguration>>(`${baseUri}/BackupMaster/configuration`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDataBackupConfigurationOptions = async (): Promise<
  ApiResponse<{ awsRegionEndpoints: Record<string, string> }>
> => {
  const { data } = await axios.get<ApiResponse<{ awsRegionEndpoints: Record<string, string> }>>(
    `${baseUri}/BackupMaster/configuration-options`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const makeDataBackupTestConnection = async (): Promise<ApiResponse<BackupMasterTestConnectionResponse>> => {
  const { data } = await axios.post<ApiResponse<BackupMasterTestConnectionResponse>>(
    `${baseUri}/BackupMaster/test-connection`,
    {},
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const setDataBackupDailySync = async (payload: {
  enabled: boolean;
}): Promise<ApiResponse<BackupMasterConnectionState>> => {
  const { data } = await axios.put<ApiResponse<BackupMasterConnectionState>>(
    `${baseUri}/BackupMaster/connection-state`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const setDataBackupConfiguration = async (
  payload: BackupMasterConfiguration
): Promise<ApiResponse<BackupMasterConnectionState>> => {
  const { data } = await axios.put<ApiResponse<BackupMasterConnectionState>>(
    `${baseUri}/BackupMaster/configuration`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const runDataBackupNow = async (): Promise<ApiResponse<RunBackupOperationResponse>> => {
  const { data } = await axios.post<ApiResponse<RunBackupOperationResponse>>(
    `${baseUri}/BackupMaster/run-backup-operation`,
    {},
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getDataBackupLogs = async (
  params: LogsQueryParams
): Promise<ApiResponse<PaginatedList<BackupMasterApplicationLogs>>> => {
  const { data } = await axios.get<ApiResponse<PaginatedList<BackupMasterApplicationLogs>>>(
    `${baseUri}/BackupMaster/logs`,
    {
      params,
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

// Data Import

const searchDataImports = async (query: SearchImportsQuery): Promise<ApiResponse<PaginatedList<DataImportInfo>>> => {
  const { data } = await axios.post<ApiResponse<PaginatedList<DataImportInfo>>>(`${baseUri}/DataImport/search`, query, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDataImportDetails = async (id: string): Promise<ApiResponse<DataImportInfo>> => {
  const { data } = await axios.get<ApiResponse<DataImportInfo>>(`${baseUri}/DataImport/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDataImportOptions = async (): Promise<ApiResponse<DataImportOption[]>> => {
  const { data } = await axios.get<ApiResponse<DataImportOption[]>>(`${baseUri}/DataImport/options`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createDataImport = async (payload: CreateDataImportRequest): Promise<ApiResponse<DataImportInfo>> => {
  const { data } = await axios.post<ApiResponse<DataImportInfo>>(`${baseUri}/DataImport`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getCsvTemplateDownloadUrl = async (
  objectType: string,
  bcEntityType: string | undefined,
  dataImportTarget: DataImportTarget,
  clientName: string
): Promise<ApiResponse<string>> => {
  const { data } = await axios.post<ApiResponse<string>>(
    `${baseUri}/DataExport/${objectType}/csv-template/download`,
    null,
    {
      headers: getDefaultHeaders(),
      params: { dataImportTarget, bcEntityType },
    }
  );

  const token = data.data;
  const query = qs.stringify({ clientName, dataImportTarget, token });
  return data.success
    ? createApiResponse(`${baseUri}/DataExport/${objectType}/csv-template?${query}`)
    : createErrorApiResponse(data.error);
};

const getDataExportDownloadUrls = async (
  objectType: string,
  payload: DataExportRequest
): Promise<ApiResponse<DataExportResponse>> => {
  const { data } = await axios.post<ApiResponse<DataExportResponse>>(
    `${baseUri}/DataExport/${objectType}/data-export`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const uploadDataImportSourceFile = async (file: File): Promise<ApiResponse<FileInfo>> => {
  const formData = new FormData();
  formData.append("file", file);

  const { data } = await axios.post<ApiResponse<FileInfo>>(`${baseUri}/DataImport/files`, formData, {
    headers: {
      ...getDefaultHeaders(),
      "Content-Type": "multipart/form-data; boundary=fileContent",
    },
  });
  return data;
};

const validateDataImportFileContent = async (
  request: DataImportFileContentValidationRequest
): Promise<ApiResponse<DataImportFileContentValidationResult>> => {
  const { data } = await axios.post<ApiResponse<DataImportFileContentValidationResult>>(
    `${baseUri}/DataImport/validate-file-content`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const startDataImportParsingAndValidation = async (
  importId: string
): Promise<ApiResponse<{ validationId: string }>> => {
  const { data } = await axios.post<ApiResponse<{ validationId: string }>>(
    `${baseUri}/DataImport/${importId}/start-parsing-and-validation`,
    null,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getDataImportValidationStatus = async (
  importId: string,
  validationId: string
): Promise<ApiResponse<DataImportValidation>> => {
  const { data } = await axios.get<ApiResponse<DataImportValidation>>(
    `${baseUri}/DataImport/${importId}/validations/${validationId}`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getDataImportSourceFiles = async (importId: string): Promise<ApiResponse<FileInfo[]>> => {
  const { data } = await axios.get<ApiResponse<FileInfo[]>>(`${baseUri}/DataImport/${importId}/files`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDataImportSourceFileDownloadInfo = async (
  importId: string,
  catalogueId: string
): Promise<ApiResponse<FileDownloadInfo>> => {
  const { data } = await axios.get<ApiResponse<FileDownloadInfo>>(
    `${baseUri}/DataImport/${importId}/files/${catalogueId}`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const discardDataImport = async (importId: string): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.post<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/DataImport/${importId}/discard`,
    null,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const searchDataImportChanges = async (
  importId: string,
  query: GetDataImportChangesRequest
): Promise<ApiResponse<PaginatedList<DataImportChangeRequest>>> => {
  const { data } = await axios.post<ApiResponse<PaginatedList<DataImportChangeRequest>>>(
    `${baseUri}/DataImport/${importId}/changes/search`,
    query,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getDataImportChangesTotals = async (
  importId: string,
  fileDataCatalogueId?: string
): Promise<ApiResponse<DataImportChangesTotals>> => {
  const { data } = await axios.get<ApiResponse<DataImportChangesTotals>>(
    `${baseUri}/DataImport/${importId}/changes-totals`,
    {
      params: { fileDataCatalogueId },
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getDataImportMetadata = async (importId: string): Promise<ApiResponse<DataImportMetadata>> => {
  const { data } = await axios.get<ApiResponse<DataImportMetadata>>(`${baseUri}/DataImport/${importId}/metadata`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const startApplyingDataImport = async (
  importId: string,
  request: ApplyDataImportRequest
): Promise<ApiResponse<{ applicationId: string }>> => {
  const { data } = await axios.post<ApiResponse<{ applicationId: string }>>(
    `${baseUri}/DataImport/${importId}/apply`,
    request,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getDataImportApplicationStatus = async (
  importId: string,
  applicationId: string
): Promise<ApiResponse<DataImportApplication>> => {
  const { data } = await axios.get<ApiResponse<DataImportApplication>>(
    `${baseUri}/DataImport/${importId}/applications/${applicationId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const startDataImportRollback = async (importId: string): Promise<ApiResponse<{ rollbackId: string }>> => {
  const { data } = await axios.post<ApiResponse<{ rollbackId: string }>>(
    `${baseUri}/DataImport/${importId}/rollback`,
    null,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getDataImportRollbackStatus = async (
  importId: string,
  rollbackId: string
): Promise<ApiResponse<DataImportRollback>> => {
  const { data } = await axios.get<ApiResponse<DataImportRollback>>(
    `${baseUri}/DataImport/${importId}/rollbacks/${rollbackId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const addDataImportChange = async (
  importId: string,
  request: AddChangeRequest
): Promise<ApiResponse<DataImportChangeRequest>> => {
  const { data } = await axios.post<ApiResponse<DataImportChangeRequest>>(
    `${baseUri}/DataImport/${importId}/changes`,
    request,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const updateDataImportChange = async (
  importId: string,
  changeRequestId: string,
  request: UpdateChangeRequest
): Promise<ApiResponse<DataImportChangeRequest>> => {
  const { data } = await axios.patch<ApiResponse<DataImportChangeRequest>>(
    `${baseUri}/DataImport/${importId}/changes/${changeRequestId}`,
    request,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const excludeDataImportChange = async (
  importId: string,
  changeRequestId: string
): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.delete<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/DataImport/${importId}/changes/${changeRequestId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const revertExcludedDataImportChange = async (
  importId: string,
  changeRequestId: string
): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.post<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/DataImport/${importId}/changes/${changeRequestId}/revert-excluded`,
    null,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const excludeChangesWithErrors = async (importId: string): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.delete<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/DataImport/${importId}/changes-with-errors`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

// Passthrough Integration

const getPassthroughConfiguration = async (): Promise<ApiResponse<PassthroughConfiguration>> => {
  const { data } = await axios.get<ApiResponse<PassthroughConfiguration>>(`${baseUri}/passthrough/configuration`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updatePassthroughConfiguration = async (
  payload: PassthroughConfiguration
): Promise<ApiResponse<PassthroughConfiguration>> => {
  const { data } = await axios.put<ApiResponse<PassthroughConfiguration>>(
    `${baseUri}/passthrough/configuration`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const makePassthroughTestConnection = async (): Promise<ApiResponse<PassthroughCheckConnectionResponse>> => {
  const { data } = await axios.get<ApiResponse<PassthroughCheckConnectionResponse>>(
    `${baseUri}/passthrough/test-connection`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getPassthroughConnectionState = async (): Promise<ApiResponse<PassthroughConnectionState>> => {
  const { data } = await axios.get<ApiResponse<PassthroughConnectionState>>(`${baseUri}/Passthrough/connection-state`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const togglePassthroughIntegration = async (payload: {
  enabled: boolean;
}): Promise<ApiResponse<PassthroughConnectionState>> => {
  const { data } = await axios.put<ApiResponse<PassthroughConnectionState>>(
    `${baseUri}/Passthrough/connection-state`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getPassthroughLogs = async (
  params: LogsQueryParams
): Promise<ApiResponse<PaginatedList<PassthroughApplicationLogs>>> => {
  const { data } = await axios.get<ApiResponse<PaginatedList<PassthroughApplicationLogs>>>(
    `${baseUri}/Passthrough/logs`,
    {
      params,
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getPassthroughFunds = async (): Promise<ApiResponse<PassthroughFundsResponse>> => {
  const { data } = await axios.get<ApiResponse<PassthroughFundsResponse>>(
    `${baseUri}/PassthroughDataImport/passthrough-funds`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getPassthroughFundClosings = async (
  passthroughFundId: string
): Promise<ApiResponse<PassthroughFundClosingResponse>> => {
  const { data } = await axios.get<ApiResponse<PassthroughFundClosingResponse>>(
    `${baseUri}/PassthroughDataImport/passthrough-fund-closings`,
    {
      params: { passthroughFundId },
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getInternalEntities = async (): Promise<ApiResponse<InternalEntitiesResponse>> => {
  const { data } = await axios.get<ApiResponse<InternalEntitiesResponse>>(
    `${baseUri}/PassthroughDataImport/internal-entities`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const importPassthroughData = async (
  request: CreatePassthroughImportRequest
): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.post<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/PassthroughDataImport/passthrough-import`,
    request,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getPassthroughConnectionStatus = async (): Promise<ApiResponse<BooleanOperationResult>> => {
  const { data } = await axios.get<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/PassthroughDataImport/passthrough-connection-status`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

// Change Requests

const searchInvestorChangeRequests = async (
  payload: SearchInvestorChangeRequestsRequest
): Promise<ApiResponse<PaginatedList<InvestorChangeRequest>>> => {
  const { data } = await axios.post<ApiResponse<PaginatedList<InvestorChangeRequest>>>(
    `${baseUri}/InvestorChangeRequests/search`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getInvestorChangeRequestDetails = async (
  workflowId: string
): Promise<ApiResponse<InvestorChangeRequestDetails>> => {
  const { data } = await axios.get<ApiResponse<InvestorChangeRequestDetails>>(
    `${baseUri}/InvestorChangeRequests/details`,
    {
      params: { workflowId },
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const approveInvestorChangeRequest = async (
  payload: ApproveInvestorChangeRequestRequest
): Promise<ApiResponse<InvestorChangeRequest>> => {
  const { data } = await axios.post<ApiResponse<InvestorChangeRequest>>(
    `${baseUri}/InvestorChangeRequests/approve`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const rejectInvestorChangeRequest = async (
  payload: RejectInvestorChangeRequestRequest
): Promise<ApiResponse<InvestorChangeRequest>> => {
  const { data } = await axios.post<ApiResponse<InvestorChangeRequest>>(
    `${baseUri}/InvestorChangeRequests/reject`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

// Object Management

const getOrganizationObjectDefinitions = async () => {
  const { data } = await axios.get<ApiResponse<ObjectClassDefinitionInfo[]>>(`${baseUri}/ObjectDefinitions`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getOrganizationObjectDefinitionByObjectType = async (objectType: string) => {
  const { data } = await axios.get<ApiResponse<ObjectClassDefinition>>(`${baseUri}/ObjectDefinitions/${objectType}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createObjectField = async (objectType: string, payload: CreateFieldRequest) => {
  const { data } = await axios.post<ApiResponse<AddFieldToObjectClassDefinitionResponse>>(
    `${baseUri}/ObjectDefinitions/${objectType}/fields`,
    payload,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const updateObjectField = async (objectType: string, fieldId: string, payload: UpdateFieldRequest) => {
  const { data } = await axios.patch<ApiResponse<ObjectClassDefinition>>(
    `${baseUri}/ObjectDefinitions/${objectType}/fields/${encodeURIComponent(fieldId)}`,
    payload,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const deleteObjectField = async (objectType: string, fieldId: string) => {
  const { data } = await axios.delete<ApiResponse<ObjectClassDefinition>>(
    `${baseUri}/ObjectDefinitions/${objectType}/fields/${encodeURIComponent(fieldId)}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getObjectConfigurationLayout = async (objectType: string, configurationId: string) => {
  const { data } = await axios.get<ApiResponse<PageLayout>>(`${baseUri}/ObjectDefinitions/${objectType}/layout`, {
    headers: getDefaultHeaders(),
    params: { configurationId },
  });
  return data;
};

const saveObjectConfigurationLayout = async (objectType: string, payload: CreateOrganizationPageLayoutRequest) => {
  const { data } = await axios.post<ApiResponse<PageLayout>>(
    `${baseUri}/ObjectDefinitions/${objectType}/layout`,
    payload,
    { headers: getDefaultHeaders() }
  );
  return data;
};

// Data Model Configuration

const getDataModelConfigurations = async () => {
  const { data } = await axios.get<ApiResponse<DataModelConfiguration[]>>(`${baseUri}/DataModelConfigurations`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createDataModelConfiguration = async (payload: CreateDataModelConfigurationRequest) => {
  const { data } = await axios.post<ApiResponse<DataModelConfiguration>>(
    `${baseUri}/DataModelConfigurations`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const updateDataModelConfiguration = async (id: string, payload: UpdateDataModelConfigurationRequest) => {
  const { data } = await axios.patch<ApiResponse<DataModelConfiguration>>(
    `${baseUri}/DataModelConfigurations/${id}`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const deleteDataModelConfiguration = async (id: string) => {
  const { data } = await axios.delete<ApiResponse<null>>(`${baseUri}/DataModelConfigurations/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const assignClientToDataModelConfiguration = async (payload: AssignClientToConfigurationRequest) => {
  const { data } = await axios.post<ApiResponse<null>>(`${baseUri}/DataModelConfigurations/assign-client`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

// Document Activity

const searchDocumentViews = async (payload: SearchDocumentViewsRequest) => {
  const { data } = await axios.post<ApiResponse<PaginatedList<DocumentViews>>>(
    `${baseUri}/DocumentViews/search`,
    payload,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getAggregatedDocumentViewsByContact = async (attachmentId: string, contactId: string) => {
  const { data } = await axios.get<ApiResponse<AttachmentAggregatedDocumentViews[]>>(
    `${baseUri}/DocumentViews/attachments/${attachmentId}/aggregate/by-contact/${contactId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getAggregatedFundraisingDocumentViewsByContact = async (fundraisingDocumentId: string, contactId: string) => {
  const { data } = await axios.get<ApiResponse<FundraisingAggregatedDocumentViews[]>>(
    `${baseUri}/DocumentViews/fundraising-documents/${fundraisingDocumentId}/aggregate/by-contact/${contactId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getAggregatedDocumentViewsByInvestor = async (attachmentId: string, investorId: string) => {
  const { data } = await axios.get<ApiResponse<AttachmentAggregatedDocumentViews[]>>(
    `${baseUri}/DocumentViews/attachments/${attachmentId}/aggregate/by-investor/${investorId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getAggregatedFundraisingDocumentViewsByInvestor = async (fundraisingDocumentId: string, investorId: string) => {
  const { data } = await axios.get<ApiResponse<FundraisingAggregatedDocumentViews[]>>(
    `${baseUri}/DocumentViews/fundraising-documents/${fundraisingDocumentId}/aggregate/by-investor/${investorId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getMessageAttachmentInfo = async (attachmentId: string, params: { contactId?: string; investorId?: string }) => {
  const { data } = await axios.get<ApiResponse<DocumentPresentationInfo>>(
    `${baseUri}/DocumentViews/attachments/${attachmentId}/presentation-info`,
    { params, headers: getDefaultHeaders() }
  );
  return data;
};

const getFundraisingDocumentInfo = async (
  fundraisingId: string,
  fundraisingDocumentId: string,
  params: { contactId?: string; investorId?: string }
) => {
  const { data } = await axios.get<ApiResponse<DocumentPresentationInfo>>(
    `${baseUri}/DocumentViews/fundraising/${fundraisingId}/documents/${fundraisingDocumentId}/presentation-info`,
    { params, headers: getDefaultHeaders() }
  );
  return data;
};

// Fundraising

const getFundraisings = async (status?: FundraisingStatus[]) => {
  const { data } = await axios.get<ApiResponse<FundraisingInfo[]>>(`${baseUri}/Fundraisings`, {
    params: { status },
    headers: getDefaultHeaders(),
  });

  return data;
};

const getFundraisingById = async (id: string) => {
  const { data } = await axios.get<ApiResponse<Fundraising>>(`${baseUri}/Fundraisings/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getFundraisingAccess = async (fundId: string) => {
  const { data } = await axios.get<ApiResponse<FundraisingAccessItem[]>>(`${baseUri}/Fundraisings/access`, {
    params: { fundId },
    headers: getDefaultHeaders(),
  });
  return data;
};

const createFundraising = async (payload: CreateFundraisingRequest) => {
  const { data } = await axios.post<ApiResponse<Fundraising>>(`${baseUri}/Fundraisings`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateFundraising = async (id: string, payload: UpdateFundraisingRequest) => {
  const { data } = await axios.patch<ApiResponse<Fundraising>>(`${baseUri}/Fundraisings/${id}`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateFundraisingWithNewFund = async (id: string, payload: UpdateFundraisingWithNewFundRequest) => {
  const { data } = await axios.patch<ApiResponse<UpdateFundraisingWithNewFundResponse>>(
    `${baseUri}/Fundraisings/${id}/access`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const deleteFundraising = async (id: string) => {
  const { data } = await axios.delete<ApiResponse<null>>(`${baseUri}/Fundraisings/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const uploadFundraisingFile = async (fundraisingId: string, payload: UploadFundraisingFileRequest) => {
  const formData = new FormData();
  formData.append("file", payload.file);
  formData.append("externalCategoryId", payload.externalCategoryId);
  if (payload.section) {
    formData.append("section", payload.section);
  }

  const { data } = await axios.post<ApiResponse<UploadFundraisingFileResponse>>(
    `${baseUri}/Fundraisings/${fundraisingId}/files`,
    formData,
    {
      headers: {
        ...getDefaultHeaders(),
        "Content-Type": "multipart/form-data; boundary=fileContent",
      },
    }
  );
  return data;
};

const getFundraisingFileDownloadInfo = async (fundraisingId: string, fileId: string) => {
  const { data } = await axios.get<ApiResponse<FileDownloadInfo>>(
    `${baseUri}/Fundraisings/${fundraisingId}/files/${fileId}`,
    { headers: getDefaultHeaders() }
  );
  return data;
};

const getDownloadUrlForMultipleFundraisingFiles = async (
  fundraisingId: string,
  fileName: string,
  fileIds: string[]
): Promise<ApiResponse<string>> => {
  const { data } = await axios.post<ApiResponse<string>>(
    `${baseUri}/Fundraisings/${fundraisingId}/files/token`,
    { fileIds },
    { headers: getDefaultHeaders() }
  );

  const token = data.data;
  return token
    ? createApiResponse(
        `${baseUri}/Fundraisings/multiple-files?${qs.stringify({ fileName, token, clientCode: getClientCode() })}`
      )
    : createErrorApiResponse(data.error);
};

const getDownloadUrlForMultipleFilesFromFundraisings = async (
  fundraisingDocumentIdentifiers: FundraisingDocumentIdentifier[],
  fileName: string
): Promise<ApiResponse<string>> => {
  const { data } = await axios.post<ApiResponse<string>>(
    `${baseUri}/Fundraisings/files/token`,
    { fundraisingDocumentIdentifiers },
    { headers: getDefaultHeaders() }
  );

  const token = data.data;
  return token
    ? createApiResponse(
        `${baseUri}/Fundraisings/multiple-files?${qs.stringify({ fileName, token, clientCode: getClientCode() })}`
      )
    : createErrorApiResponse(data.error);
};

const sendTestFundraisingEmail = async (fundraisingId: string, payload: SendTestFundraisingEmailRequest) => {
  const { data } = await axios.post<ApiResponse<null>>(
    `${baseUri}/Fundraisings/${fundraisingId}/send-test-email`,
    payload,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const getFundraisingJobProgress = async (asyncOperationId: string) => {
  const { data } = await axios.get<ApiResponse<JobProgress>>(`${baseUri}/Fundraisings/jobs/${asyncOperationId}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createFundraisingCategory = async (payload: CreateFundraisingCategoryRequest) => {
  const { data } = await axios.post<ApiResponse<Category>>(`${baseUri}/FundraisingCategories`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateFundraisingCategory = async (id: string, payload: UpdateFundraisingCategoryRequest) => {
  const { data } = await axios.patch<ApiResponse<Category>>(`${baseUri}/FundraisingCategories/${id}`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const deleteFundraisingCategory = async (id: string) => {
  const { data } = await axios.delete<ApiResponse<null>>(`${baseUri}/FundraisingCategories/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

// Portfolio Monitoring

const getAllMetrics = async () => {
  const { data } = await axios.get<ApiResponse<Metric[]>>(`${baseUri}/Metrics`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getMetric = async (id: string) => {
  const { data } = await axios.get<ApiResponse<Metric>>(`${baseUri}/Metrics/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const createMetric = async (payload: CreateMetricRequest) => {
  const { data } = await axios.post<ApiResponse<Metric>>(`${baseUri}/Metrics`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const updateMetric = async (id: string, payload: UpdateMetricRequest) => {
  const { data } = await axios.patch<ApiResponse<Metric>>(`${baseUri}/Metrics/${id}`, payload, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const deleteMetric = async (id: string) => {
  const { data } = await axios.delete<ApiResponse<null>>(`${baseUri}/Metrics/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

// Data Collection

const getDataSubmissions = async () => {
  const { data } = await axios.get<ApiResponse<DataCollectionSubmissionInfo[]>>(`${baseUri}/DataSubmissions`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDataSubmissionDetails = async (id: string) => {
  const { data } = await axios.get<ApiResponse<DataCollectionSubmissionDetails>>(`${baseUri}/DataSubmissions/${id}`, {
    headers: getDefaultHeaders(),
  });
  return data;
};

const getDataSubmissionConfigurations = async () => {
  const { data } = await axios.get<ApiResponse<DataSubmissionConfigurationInfo[]>>(
    `${baseUri}/DataSubmissionConfigurations`,
    {
      headers: getDefaultHeaders(),
    }
  );
  return data;
};

const adminApi = {
  getIdentity,
  getUserData,
  getClientData,
  getMenuBadges,
  getFundAccountingAppsStatus,
  resendInvitationEmailByMessage,
  getSignOnSettings,
  updateSignOnSettings,
  getDocumentCollections,
  getDocumentCollectionDetails,
  getDocumentCollectionEmails,
  getDocCollectionFileDownloadInfo,
  getDownloadUrlForDocumentCollection,
  getDownloadUrlForSelectedDocuments,
  sendInvitations,
  searchSentEmails,
  getSentEmailsCsv,
  getOrganizationManagedCompanies,
  getOrganizationMembers,
  getOrganizationDeactivatedMembers,
  getOrganizationMembersInvitations,
  deleteOrganizationMember,
  deleteOrganizationUserInvite,
  resendOrganizationUserInvite,
  updateOrganizationMemberAccess,
  getIrConsentsData,
  deleteIrConsent,
  getIrConsentDetails,
  createIrConsent,
  updateIrConsent,
  updateCompanySettings,
  updateUserConsents,
  sendInvitationToContacts,
  getContactsJobProgress,
  exportContactsToCsv,
  getContactDetails,
  createContact,
  deleteContact,
  getAccessCategories,
  getContactDocuments,
  getContactFundraisingDocuments,
  getContactEmails,
  getInvestorDetails,
  createInvestor,
  deleteInvestor,
  searchFunds,
  getFundFieldValues,
  updateFundFieldValues,
  searchInvestors,
  getInvestorFieldValues,
  updateInvestorFieldValues,
  searchContacts,
  getContactFieldValues,
  updateContactFieldValues,
  getObjectDefinition,
  getAvailableObjectDefinitions,
  searchObjects,
  getObjectById,
  createObject,
  updateObject,
  deleteObject,
  updateObjectLogo,
  removeObjectLogo,
  validateUniqueField,
  getLookupOptions,
  getCardLayout,
  getFundDetails,
  getFundPermissions,
  searchFundDocuments,
  updateFundCommunicationMatrix,
  createAssistantThread,
  sendMessageToAssistant,
  searchInvestorDocuments,
  getInvestorFundraisingDocuments,
  getInvestorPermissions,
  getInitialInvestmentsData,
  getInvestorBalance,
  getInvestorCapitalActivity,
  updateInvestorCommunicationMatrix,
  createFundInvestor,
  updateFundInvestor,
  getNotes,
  createNote,
  updateNote,
  deleteNote,
  getFilesViews,
  getFileTagSettings,
  createFileTag,
  updateFileTag,
  deleteFileTag,
  getCompanyFiles,
  getFileDownloadInfo,
  getDownloadUrlForMultipleFiles,
  renameFile,
  deleteFiles,
  getFileDetails,
  getFilesViewsForEntityType,
  getFileTagsForEntityType,
  getEntityFiles,
  getEntityFileDownloadInfo,
  getDownloadUrlForMultipleEntityFiles,
  renameEntityFile,
  detachEntityFiles,
  getEntityFileDetails,
  uploadEntityFile,
  getDwhShares,
  postDwhShare,
  patchDwhShare,
  deleteDwhShare,
  getDwhLogs,
  getDwhLastSync,
  startDwhFullSync,
  getApiGatewayEligibleUsers,
  getApiGatewaySubscriptions,
  addApiGatewaySubscription,
  updateApiGatewaySubscription,
  deleteApiGatewaySubscription,
  getApiGatewayLogs,
  getInvoices,
  getInvoiceDetails,
  getInvoiceDownloadFileInfo,
  getExpenseManagementSettings,
  updateInvoice,
  postInvoice,
  rejectInvoice,
  getInvestorPortalSettings,
  updateInvestorPortalSettings,
  sendTestInvitationEmail,
  getWatermarkSettings,
  updateWatermarkSettings,
  getInvestorPortalSyncStatus,
  getDataBackupConnectionState,
  getDataBackupConfiguration,
  makeDataBackupTestConnection,
  setDataBackupDailySync,
  getDataBackupConfigurationOptions,
  setDataBackupConfiguration,
  runDataBackupNow,
  getDataBackupLogs,
  getPassthroughConfiguration,
  makePassthroughTestConnection,
  getPassthroughConnectionState,
  togglePassthroughIntegration,
  updatePassthroughConfiguration,
  getPassthroughLogs,
  searchDataImports,
  getDataImportDetails,
  getDataImportOptions,
  createDataImport,
  getCsvTemplateDownloadUrl,
  getDataExportDownloadUrls,
  uploadDataImportSourceFile,
  validateDataImportFileContent,
  startDataImportParsingAndValidation,
  getDataImportValidationStatus,
  getDataImportSourceFiles,
  getDataImportSourceFileDownloadInfo,
  discardDataImport,
  searchDataImportChanges,
  getDataImportChangesTotals,
  getDataImportMetadata,
  startApplyingDataImport,
  getDataImportApplicationStatus,
  startDataImportRollback,
  getDataImportRollbackStatus,
  addDataImportChange,
  updateDataImportChange,
  excludeDataImportChange,
  revertExcludedDataImportChange,
  excludeChangesWithErrors,
  getPassthroughFunds,
  getPassthroughFundClosings,
  getInternalEntities,
  importPassthroughData,
  getPassthroughConnectionStatus,
  searchInvestorChangeRequests,
  getInvestorChangeRequestDetails,
  approveInvestorChangeRequest,
  rejectInvestorChangeRequest,
  getOrganizationObjectDefinitions,
  getOrganizationObjectDefinitionByObjectType,
  createObjectField,
  updateObjectField,
  deleteObjectField,
  getObjectConfigurationLayout,
  saveObjectConfigurationLayout,
  getDataModelConfigurations,
  createDataModelConfiguration,
  updateDataModelConfiguration,
  deleteDataModelConfiguration,
  assignClientToDataModelConfiguration,
  searchDocumentViews,
  getAggregatedDocumentViewsByContact,
  getAggregatedFundraisingDocumentViewsByContact,
  getAggregatedDocumentViewsByInvestor,
  getAggregatedFundraisingDocumentViewsByInvestor,
  getMessageAttachmentInfo,
  getFundraisingDocumentInfo,
  getFundraisings,
  getFundraisingById,
  getFundraisingAccess,
  createFundraising,
  updateFundraising,
  updateFundraisingWithNewFund,
  deleteFundraising,
  uploadFundraisingFile,
  getFundraisingFileDownloadInfo,
  getDownloadUrlForMultipleFundraisingFiles,
  getDownloadUrlForMultipleFilesFromFundraisings,
  sendTestFundraisingEmail,
  getFundraisingJobProgress,
  createFundraisingCategory,
  updateFundraisingCategory,
  deleteFundraisingCategory,
  getAllMetrics,
  getMetric,
  createMetric,
  updateMetric,
  deleteMetric,
  getDataSubmissions,
  getDataSubmissionDetails,
  getDataSubmissionConfigurations,
};

export default adminApi;
