import { Box } from "@mui/material";
import { useCallback } from "react";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useAsyncCall from "../../../../../shared/hooks/useAsyncCall";
import { useFilterContext } from "../FilterContext";
import { FilterValue, MultiSelectFilter } from "../filterTypes";
import MultiSelectFilterEditorCore from "./MultiSelectFilterEditorCore";

interface Props {
  filter: MultiSelectFilter;
  onUpdateValue: (newValue: FilterValue) => void;
}

const MultiSelectFilterEditorWithAsyncOptions = ({ filter, onUpdateValue }: Props) => {
  const { getAsyncSelectOptions } = useFilterContext();
  const getOptions = useCallback(() => getAsyncSelectOptions(filter.id), [filter.id, getAsyncSelectOptions]);
  const [options, { isFetching }] = useAsyncCall(getOptions);

  if (isFetching || options === undefined) {
    return (
      <Box
        sx={(t) => ({
          width: t.spacing(47.5),
          height: t.spacing(25),
        })}
      >
        <InlineLoader />
      </Box>
    );
  }

  return <MultiSelectFilterEditorCore options={options} filter={filter} onUpdateValue={onUpdateValue} />;
};

export default MultiSelectFilterEditorWithAsyncOptions;
