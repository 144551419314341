import { logError } from "../../../shared/logging";
import { ValidationResult } from "../../../shared/utilities/validators";
import { Field } from "../../api/types/objectTypes";
import { EntityFieldComponentPropsBase } from "./entityFieldTypes";
import EntityCheckboxField from "./standardFields/EntityCheckboxField";
import EntityDateField from "./standardFields/EntityDateField";
import EntityEmailField from "./standardFields/EntityEmailField";
import EntityLookupField from "./standardFields/EntityLookupField";
import EntityMoneyField from "./standardFields/EntityMoneyField";
import EntityMultiselectField from "./standardFields/EntityMultiselectField";
import EntityMultiselectUdfField from "./standardFields/EntityMultiselectUdfField";
import EntityNumberField from "./standardFields/EntityNumberField";
import EntityPercentField from "./standardFields/EntityPercentField";
import EntityPhoneField from "./standardFields/EntityPhoneField";
import EntitySelectField from "./standardFields/EntitySelectField";
import EntitySelectUdfField from "./standardFields/EntitySelectUdfField";
import EntityTextField from "./standardFields/EntityTextField";
import EntityUrlField from "./standardFields/EntityUrlField";

interface Props {
  field: Field;
  fieldValue: unknown;
  onFieldValueChange: (value: unknown, fieldId: string, validationResult: ValidationResult) => void;
  fieldInputId?: string;
}

const FieldValueComponent = ({ field, fieldValue, onFieldValueChange, fieldInputId }: Props) => {
  const props: EntityFieldComponentPropsBase = {
    fieldId: field.id,
    fieldConfiguration: field.configuration,
    fieldAttributes: field.attributes,
    value: fieldValue,
    onChange: onFieldValueChange,
    fieldInputId,
  };

  switch (field.type) {
    case "Text":
      return <EntityTextField {...props} />;
    case "Email":
      return <EntityEmailField {...props} />;
    case "Phone":
      return <EntityPhoneField {...props} />;
    case "Url":
      return <EntityUrlField {...props} />;
    case "Number":
      return <EntityNumberField {...props} />;
    case "Money":
      return <EntityMoneyField {...props} />;
    case "Percent":
      return <EntityPercentField {...props} />;
    case "Date":
      return <EntityDateField {...props} />;
    case "Checkbox":
      return <EntityCheckboxField {...props} />;
    case "Select":
      return <EntitySelectField {...props} />;
    case "MultiSelect":
      return <EntityMultiselectField {...props} />;
    case "UserDefinedOptionsSelect":
      return <EntitySelectUdfField {...props} />;
    case "UserDefinedOptionsMultiSelect":
      return <EntityMultiselectUdfField {...props} />;
    case "Lookup": {
      return <EntityLookupField {...props} />;
    }
    default:
      logError(`Entity field type ${field.type} is not supported`, "[FieldValueComponent]");
      return null;
  }
};

export default FieldValueComponent;
