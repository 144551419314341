import { LoadingButton } from "@mui/lab";
import { Box, Divider, FormControl, RadioGroup, Stack, Typography } from "@mui/material";
import { useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import CopyToBufferInput from "../../../../../shared/components/inputs/CopyToBufferInput";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { combineValidators, emailValidator, requiredValidator } from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import { DownloadFileNameFormatOptions } from "../../../../api/types/investorPortalTypes";
import { useClientContext } from "../../../../context/ClientContext";
import variables from "../../../../variables";
import ApplicationStatusTag from "../../../common/ApplicationStatusTag";
import BorderedRadioLabel from "../../../common/BorderedRadioLabel";

const GeneralInvestorPortalSettings = () => {
  const { clientCode, investorPortalApp, hasPermissions } = useClientContext();
  const { sendNotificationError } = useNotificationContext();

  const [signUpEmailText, setSignUpEmailText] = useState("email@example.com");
  const [downloadFileNameFormat, setDownloadFileNameFormat] = useState<DownloadFileNameFormatOptions | null>(null);

  const [isSaving, setSaving] = useState(false);

  const [resp, fetchError, { setData: setSettings }] = useFetch(adminApi.getInvestorPortalSettings, (data) =>
    setDownloadFileNameFormat(data.settings.downloadFileNameFormat ?? null)
  );

  const investorPortalUrl = `${variables.IR_PORTAL_URI}/${clientCode}`;

  const handleSaveClick = async () => {
    if (downloadFileNameFormat === null) {
      return;
    }

    setSaving(true);
    try {
      const resp = await adminApi.updateInvestorPortalSettings({ downloadFileNameFormat });
      if (resp.data) {
        setSettings(resp.data);
      }
    } catch (error) {
      sendNotificationError("Failed to save settings");
    } finally {
      setSaving(false);
    }
  };

  if (fetchError) {
    return <DataLoadingFailed />;
  }

  if (resp === undefined) {
    return <InlineLoader />;
  }

  const { settings } = resp;
  const canEditSettings = hasPermissions(["ManageInvestorPortalSettings"]);
  const saveDisabled = downloadFileNameFormat === null || downloadFileNameFormat === settings.downloadFileNameFormat;

  return (
    <Stack pb={1} px={1} spacing={3}>
      <Stack spacing={3} width="35rem">
        <Typography variant="subtitle1">Portal Links</Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="secondary" width="3.5rem">
            Status
          </Typography>
          <ApplicationStatusTag appStatus={investorPortalApp?.status} />
        </Stack>
        <CopyToBufferInput
          disabled
          label="Base Portal URL"
          addOpenButtonForUrl
          text={investorPortalUrl}
          description="Provides access to the portal's login page."
        />
        <CopyToBufferInput
          disabled
          label="Sign-up URL"
          text={`${investorPortalUrl}/register`}
          description="Users with configured access can use this link to sign up for an account on the portal."
        />
        <CopyToBufferInput
          label="Custom sign-up URL"
          beginDisabledText={`${investorPortalUrl}/register?email=`}
          text={signUpEmailText}
          onChange={setSignUpEmailText}
          inputValidator={combineValidators(requiredValidator, emailValidator)}
          disableCopyIfNotValid
          description={
            <div>
              <Typography component="span" variant="caption" color="secondary">
                Allows inviting a specific user to register for the portal. Replace
              </Typography>
              <Typography component="span" variant="caption">
                {" email@example.com "}
              </Typography>
              <Typography component="span" variant="caption" color="secondary">
                with the recipient's email, and share the link with them.
              </Typography>
            </div>
          }
        />
      </Stack>

      <Divider />

      <Stack spacing={3} width="35rem">
        <Stack spacing={1}>
          <Typography variant="subtitle1">Download File Name Format</Typography>
          <Typography color="text.secondary">
            You can change the file name for the documents that is used when the user downloads them.
          </Typography>
        </Stack>
        <FormControl>
          <RadioGroup
            value={downloadFileNameFormat}
            onChange={({ target }) => setDownloadFileNameFormat(target.value as DownloadFileNameFormatOptions)}
            sx={{ rowGap: 2 }}
          >
            <BorderedRadioLabel<DownloadFileNameFormatOptions>
              value="OriginalFileName"
              disabled={!canEditSettings}
              selectedValue={downloadFileNameFormat}
              sx={{ pl: 2, py: 1 }}
              label={
                <Box>
                  <Typography variant="subtitle2">Use original file name</Typography>
                  <Typography color="text.secondary">
                    Downloads will use the same name that was set during the upload.
                  </Typography>
                </Box>
              }
            />
            <BorderedRadioLabel<DownloadFileNameFormatOptions>
              value="FundInvestorDocument"
              disabled={!canEditSettings}
              selectedValue={downloadFileNameFormat}
              sx={{ pl: 2, py: 1 }}
              label={
                <Box>
                  <Typography variant="subtitle2">Use predefined format</Typography>
                  <Typography color="text.secondary">Downloads will follow a predefined format:</Typography>
                  <Typography>[Fund Name]-[Investor Name]-[Document Name]</Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
        {canEditSettings && (
          <LoadingButton
            loading={isSaving}
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            disabled={saveDisabled}
            sx={{ width: "5rem" }}
          >
            Save
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

export default GeneralInvestorPortalSettings;
