import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import StatTile from "../../../common/StatTile";

interface Props {
  dataImportId: string;
  title: string;
  value: number | undefined;
}

const ImportDataStatTile = ({ dataImportId, title, value }: Props) => {
  const { clientCode } = useClientContext();

  return (
    <StatTile title={title} value={value?.toLocaleString() || "-"} sx={{ flex: 1 }}>
      {value !== undefined ? (
        <Link
          component={RouterLink}
          to={`/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}/${dataImportId}/${pageRoutes.importReview}`}
          underline="none"
        >
          View
        </Link>
      ) : (
        <Typography color="text.disabled">View</Typography>
      )}
    </StatTile>
  );
};

export default ImportDataStatTile;
