const NoAccessIcon = () => (
  <svg width="400" height="255" viewBox="0 0 400 255" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M350.759 89.4955C348.51 90.2825 346.037 89.1582 345.138 86.7973C345.025 86.4601 344.913 86.1228 344.913 85.7856C344.801 84.8862 344.126 84.2116 343.339 83.9868C341.428 83.5371 339.517 83.5371 337.718 83.762C336.819 83.8744 336.144 84.5489 335.919 85.4483C335.469 87.8092 333.221 89.3831 330.86 88.9334C330.523 88.821 330.186 88.821 329.848 88.5961C329.061 88.2589 328.05 88.4837 327.487 89.1582C326.363 90.5073 325.351 91.9688 324.677 93.5427C324.34 94.3297 324.564 95.3415 325.239 95.9036C327.038 97.4775 327.375 100.176 325.801 102.087C325.576 102.424 325.239 102.649 324.789 102.986C324.002 103.548 323.665 104.448 324.002 105.347C324.34 106.359 324.677 107.371 325.239 108.27C325.689 109.057 326.138 109.844 326.7 110.519C327.263 111.306 328.274 111.53 329.061 111.193C331.31 110.294 333.783 111.306 334.795 113.554C335.02 114.004 335.132 114.453 335.132 115.015C335.132 115.915 335.807 116.702 336.818 116.927C338.617 117.264 340.528 117.376 342.44 117.151C342.664 117.151 342.889 117.039 343.114 116.927C343.676 116.589 344.126 116.027 344.238 115.353C344.351 113.891 345.25 112.655 346.487 111.98C347.724 111.306 349.185 111.306 350.422 111.868C351.209 112.317 352.22 112.092 352.895 111.306C354.019 109.956 354.919 108.495 355.593 106.921C355.93 106.134 355.706 105.122 355.031 104.56C353.12 102.986 352.895 100.288 354.469 98.4893C354.694 98.1521 355.031 97.9272 355.368 97.7024C356.043 97.1403 356.38 96.2409 356.155 95.3415C355.93 94.4421 355.481 93.5427 355.143 92.7558C354.694 91.8564 354.132 91.0694 353.457 90.2825C352.445 89.4955 351.546 89.1583 350.759 89.4955ZM344.801 97.9272C346.262 100.625 345.138 103.998 342.44 105.46C339.741 106.921 336.369 105.797 334.907 103.099C333.446 100.401 334.57 97.0278 337.268 95.5663C339.966 94.1048 343.339 95.1167 344.801 97.9272Z"
      fill="#C7DBE5"
    />
    <path
      d="M295.446 241.603H103.541C92.4109 241.603 83.3047 232.496 83.3047 221.367V44.0759C83.3047 32.9461 92.4109 23.8398 103.541 23.8398H295.446C306.576 23.8398 315.683 32.9461 315.683 44.0759V221.367C315.683 232.496 306.689 241.603 295.446 241.603Z"
      fill="#EBF2F5"
    />
    <path
      d="M295.446 241.603H103.541C92.4109 241.603 83.3047 232.496 83.3047 221.367V44.0759C83.3047 32.9461 92.4109 23.8398 103.541 23.8398H295.446C306.576 23.8398 315.683 32.9461 315.683 44.0759V221.367C315.683 232.496 306.689 241.603 295.446 241.603Z"
      stroke="#1A1A1A"
      strokeWidth="2"
    />
    <path
      d="M103.541 215.746V49.6973C103.541 46.5495 106.014 44.0762 109.162 44.0762H289.826C292.973 44.0762 295.447 46.5495 295.447 49.6973V215.746C295.447 218.894 292.973 221.367 289.826 221.367H109.162C106.014 221.367 103.541 218.894 103.541 215.746Z"
      fill="#EBF2F5"
      stroke="#1A1A1A"
      strokeWidth="2"
    />
    <path
      d="M295.784 153.463V111.979H298.595C300.056 111.979 301.293 113.216 301.293 114.678V150.765C301.293 152.227 300.056 153.463 298.595 153.463H295.784Z"
      fill="#C7DBE5"
    />
    <path
      d="M298.482 112.766C299.494 112.766 300.393 113.553 300.393 114.678V150.765C300.393 151.777 299.607 152.677 298.482 152.677H296.571V112.879H298.482M298.482 111.08H294.885V154.25H298.482C300.393 154.25 302.08 152.676 302.08 150.653V114.565C302.08 112.766 300.506 111.08 298.482 111.08Z"
      fill="#1A1A1A"
    />
    <path
      d="M156.155 247.337H127.375C125.239 247.337 123.44 245.538 123.44 243.402V241.604H160.09V243.402C160.09 245.651 158.291 247.337 156.155 247.337Z"
      fill="#C7DBE5"
      stroke="#1A1A1A"
      strokeWidth="2"
    />
    <path
      d="M271.726 247.337H242.946C240.81 247.337 239.011 245.538 239.011 243.402V241.604H275.661V243.402C275.661 245.651 273.862 247.337 271.726 247.337Z"
      fill="#C7DBE5"
      stroke="#1A1A1A"
      strokeWidth="2"
    />
    <path
      d="M103.542 89.0451C101.743 89.0451 100.281 87.5837 100.281 85.7849V75.3296C100.281 73.5308 101.743 72.0693 103.542 72.0693C105.34 72.0693 106.802 73.5308 106.802 75.3296V85.7849C106.802 87.5837 105.34 89.0451 103.542 89.0451Z"
      fill="#C7DBE5"
      stroke="#1A1A1A"
      strokeWidth="2"
    />
    <path
      d="M103.542 193.486C101.743 193.486 100.281 192.024 100.281 190.225V179.77C100.281 177.971 101.743 176.51 103.542 176.51C105.34 176.51 106.802 177.971 106.802 179.77V190.225C106.802 192.024 105.34 193.486 103.542 193.486Z"
      fill="#C7DBE5"
      stroke="#1A1A1A"
      strokeWidth="2"
    />
    <path
      d="M199.551 169.653C219.978 169.653 236.538 153.093 236.538 132.666C236.538 112.238 219.978 95.6787 199.551 95.6787C179.123 95.6787 162.563 112.238 162.563 132.666C162.563 153.093 179.123 169.653 199.551 169.653Z"
      fill="#C7DBE5"
    />
    <path
      d="M199.55 157.961C213.52 157.961 224.845 146.636 224.845 132.666C224.845 118.696 213.52 107.371 199.55 107.371C185.58 107.371 174.255 118.696 174.255 132.666C174.255 146.636 185.58 157.961 199.55 157.961Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M199.551 144.808C206.256 144.808 211.692 139.372 211.692 132.666C211.692 125.96 206.256 120.524 199.551 120.524C192.845 120.524 187.409 125.96 187.409 132.666C187.409 139.372 192.845 144.808 199.551 144.808Z"
      fill="white"
    />
    <path d="M181.788 114.903L217.314 150.541" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M217.314 114.903L181.788 150.541" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M199.55 80.5012C205.759 80.5012 210.792 75.4678 210.792 69.2589C210.792 63.0499 205.759 58.0166 199.55 58.0166C193.341 58.0166 188.308 63.0499 188.308 69.2589C188.308 75.4678 193.341 80.5012 199.55 80.5012Z"
      fill="#C7DBE5"
    />
    <path
      d="M199.549 75.4417C202.964 75.4417 205.733 72.6734 205.733 69.2584C205.733 65.8435 202.964 63.0752 199.549 63.0752C196.135 63.0752 193.366 65.8435 193.366 69.2584C193.366 72.6734 196.135 75.4417 199.549 75.4417Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M105.902 8.66309H174.754" stroke="#C7DBE5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
    <path d="M196.365 8.66309H234.248" stroke="#C7DBE5" strokeWidth="2" strokeLinejoin="round" strokeDasharray="1 4" />
    <path
      d="M339.517 123.222V206.799C339.517 209.008 337.726 210.799 335.517 210.799H323.777"
      stroke="#C7DBE5"
      strokeWidth="2"
      strokeLinejoin="round"
      strokeDasharray="1 4"
    />
    <path
      d="M49.2411 204.84C51.4895 204.053 53.9628 205.178 54.8622 207.538C54.9746 207.876 55.087 208.213 55.087 208.55C55.1995 209.45 55.874 210.124 56.661 210.349C58.5721 210.799 60.4833 210.799 62.2821 210.574C63.1815 210.462 63.856 209.787 64.0809 208.888C64.5306 206.527 66.779 204.953 69.1399 205.402C69.4772 205.515 69.8144 205.515 70.1517 205.74C70.9386 206.077 71.9505 205.852 72.5126 205.178C73.6368 203.829 74.6486 202.367 75.3231 200.793C75.6604 200.006 75.4356 198.994 74.761 198.432C72.9623 196.858 72.625 194.16 74.1989 192.249C74.4238 191.912 74.761 191.687 75.2107 191.35C75.9977 190.787 76.3349 189.888 75.9977 188.989C75.6604 187.977 75.3231 186.965 74.761 186.066C74.3113 185.279 73.8616 184.492 73.2995 183.817C72.7374 183.03 71.7256 182.805 70.9386 183.143C68.6902 184.042 66.2169 183.03 65.2051 180.782C64.9802 180.332 64.8678 179.882 64.8678 179.32C64.8678 178.421 64.1933 177.634 63.1815 177.409C61.3827 177.072 59.4715 176.96 57.5603 177.184C57.3355 177.184 57.1106 177.297 56.8858 177.409C56.3237 177.746 55.874 178.309 55.7616 178.983C55.6492 180.445 54.7498 181.681 53.5131 182.356C52.2765 183.03 50.815 183.03 49.5783 182.468C48.7914 182.019 47.7796 182.243 47.105 183.03C45.9808 184.379 45.0814 185.841 44.4069 187.415C44.0696 188.202 44.2945 189.214 44.969 189.776C46.8802 191.35 47.105 194.048 45.5311 195.847C45.3063 196.184 44.969 196.409 44.6317 196.633C43.9572 197.196 43.6199 198.095 43.8448 198.994C44.0696 199.894 44.5193 200.793 44.8566 201.58C45.3063 202.479 45.8684 203.266 46.5429 204.053C47.5547 204.953 48.4541 205.178 49.2411 204.84ZM55.1995 196.521C53.738 193.823 54.8622 190.45 57.5603 188.989C60.2585 187.527 63.6312 188.651 65.0927 191.35C66.5542 194.048 65.4299 197.42 62.7318 198.882C60.0336 200.343 56.661 199.219 55.1995 196.521Z"
      fill="#C7DBE5"
    />
    <path
      d="M60.4834 171.227V87.6494C60.4834 85.4403 62.2743 83.6494 64.4834 83.6494H76.2226"
      stroke="#C7DBE5"
      strokeWidth="2"
      strokeLinejoin="round"
      strokeDasharray="1 4"
    />
    <path
      d="M339.854 80.2769V40.9941C339.854 38.785 338.063 36.9941 335.854 36.9941H323.777"
      stroke="#C7DBE5"
      strokeWidth="2"
      strokeLinejoin="round"
      strokeDasharray="1 4"
    />
    <path
      d="M322.203 122.436H76.7842V149.305H322.203V122.436Z"
      fill="#EBF2F5"
      stroke="#1A1A1A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M322.203 122.435L316.02 116.14"
      stroke="#1A1A1A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.7842 122.435L83.0799 116.14"
      stroke="#1A1A1A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M199.55 132.328C190.331 132.328 182.911 139.748 182.911 148.967V158.523C182.911 158.635 182.799 158.86 182.686 158.86C181.787 159.197 181 159.422 180.438 159.647C179.651 159.984 179.089 160.884 179.089 161.671V183.48C179.089 184.38 179.651 185.279 180.438 185.504C186.621 187.977 193.029 189.214 199.662 189.214C206.295 189.214 212.703 187.977 218.887 185.504C219.673 185.167 220.236 184.267 220.236 183.48V161.671C220.236 160.771 219.673 159.872 218.887 159.647C218.212 159.422 217.537 159.085 216.638 158.86C216.526 158.86 216.413 158.635 216.413 158.523V148.967C216.188 139.748 208.656 132.328 199.55 132.328ZM194.94 168.303C194.94 165.83 196.964 163.806 199.437 163.806C201.911 163.806 203.934 165.83 203.934 168.303C203.934 169.99 203.035 171.339 201.686 172.126V179.658C201.686 180.895 200.674 181.907 199.437 181.907C198.201 181.907 197.189 180.895 197.189 179.658V172.126C195.952 171.339 194.94 169.99 194.94 168.303ZM210.455 148.967V157.174C206.857 156.387 203.147 156.049 199.55 156.049C195.84 156.049 192.242 156.387 188.645 157.174V148.967C188.645 142.896 193.591 138.062 199.55 138.062C205.508 138.062 210.455 142.896 210.455 148.967Z"
      fill="#00A866"
    />
    <path
      d="M199.55 128.169C198.088 128.169 196.964 129.293 196.964 130.755V142.109C196.964 143.571 198.088 144.695 199.55 144.695C201.011 144.695 202.135 143.571 202.135 142.109V130.755C202.135 129.293 200.899 128.169 199.55 128.169Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default NoAccessIcon;
