import { Dialog } from "@mui/material";
import { useCallback, useState } from "react";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import { CopyToDialogCopying } from "./CopyToDialogCopying";
import { CopyToDialogSelection } from "./CopyToDialogSelection";
import { ReportOverridePrompt } from "./ReportOverridePrompt";
import useReportValidation from "./hooks/useReportValidation";

export interface Props {
  reports: ReportInfo[];
  onClose: (success?: boolean | undefined) => void;
}

export default function CopyToDialog({ reports, onClose }: Props) {
  const reportValidation = useReportValidation(reports);
  const [showSecondScreen, setShowSecondScreen] = useState(false);
  const [showReportOverridePrompt, setShowReportOverridePrompt] = useState(false);
  const [copyConditionValues, setCopyConditionValues] = useState(true);
  const [override, setOverride] = useState(false);

  const handleStartCopy = useCallback(() => {
    const anyOfReportWithExistName = reportValidation.selectedCompanies.some((c) =>
      c.reports.some((r) => r.result?.name.exist === true)
    );

    if (anyOfReportWithExistName) {
      setShowReportOverridePrompt(true);
    } else {
      setShowSecondScreen(true);
    }
  }, [reportValidation.selectedCompanies]);

  const handleContinueCopy = useCallback((value: boolean) => {
    setOverride(value);
    setShowReportOverridePrompt(false);
    setShowSecondScreen(true);
  }, []);

  return (
    <>
      <Dialog open fullWidth disableEscapeKeyDown PaperProps={{ sx: { height: "800px", maxWidth: "970px" } }}>
        {!showSecondScreen && (
          <CopyToDialogSelection
            reports={reports}
            reportValidation={reportValidation}
            copyConditionValues={copyConditionValues}
            onCopy={handleStartCopy}
            onClose={onClose}
            onCopyConditionValues={setCopyConditionValues}
          />
        )}
        {showSecondScreen && (
          <CopyToDialogCopying
            reports={reports}
            selectedCompanies={reportValidation.selectedCompanies}
            copyConditionValues={copyConditionValues}
            override={override}
            onClose={onClose}
          />
        )}
        {showReportOverridePrompt && (
          <ReportOverridePrompt onContinue={handleContinueCopy} onCancel={() => setShowReportOverridePrompt(false)} />
        )}
      </Dialog>
    </>
  );
}
