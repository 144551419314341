import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../utilities/typeHelper";
import { ReportGroup, ReportTemplate, Status } from "../api/biClient.types";

interface ReportTemplatesContextType {
  reportTemplates: ReportTemplate[];
  groups?: ReportGroup[] | undefined;
  groupsUi?: { loaded: boolean; error?: string };
  ui: {
    loaded: boolean;
    error?: string;
  };
  actions: {
    addReportTemplate: (template: ReportTemplate) => Promise<void>;
    reloadReportTemplates: () => Promise<void>;
    switchStatus: (template: ReportTemplate, status: Status) => Promise<boolean>;
  };
}

const ReportTemplatesContext = createContext<ReportTemplatesContextType | undefined>(undefined);

export const ReportTemplatesContextProvider = (props: PropsWithChildren<ReportTemplatesContextType>) => (
  <ReportTemplatesContext.Provider value={{ ...props }}>{props.children}</ReportTemplatesContext.Provider>
);

export const useReportTemplatesContext = () => {
  const context = useContext(ReportTemplatesContext);
  return defined(context);
};
