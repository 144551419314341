import { createSvgIcon } from "@mui/material";

const WorkflowsIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6668 15.8333V15H5.00016C4.54183 15 4.14947 14.8368 3.82308 14.5104C3.49669 14.184 3.3335 13.7917 3.3335 13.3333V6.66667V2.5H5.00016V5H11.6668V4.16667C11.6668 3.70833 11.83 3.31597 12.1564 2.98958C12.4828 2.66319 12.8752 2.5 13.3335 2.5H15.8335C16.2918 2.5 16.6842 2.66319 17.0106 2.98958C17.337 3.31597 17.5002 3.70833 17.5002 4.16667V7.5C17.5002 7.95833 17.337 8.35069 17.0106 8.67708C16.6842 9.00347 16.2918 9.16667 15.8335 9.16667H13.3335C12.8752 9.16667 12.4828 9.00347 12.1564 8.67708C11.83 8.35069 11.6668 7.95833 11.6668 7.5V6.66667H5.00016V13.3333H11.6668V12.5C11.6668 12.0417 11.83 11.6493 12.1564 11.3229C12.4828 10.9965 12.8752 10.8333 13.3335 10.8333H15.8335C16.2918 10.8333 16.6842 10.9965 17.0106 11.3229C17.337 11.6493 17.5002 12.0417 17.5002 12.5V15.8333C17.5002 16.2917 17.337 16.684 17.0106 17.0104C16.6842 17.3368 16.2918 17.5 15.8335 17.5H13.3335C12.8752 17.5 12.4828 17.3368 12.1564 17.0104C11.83 16.684 11.6668 16.2917 11.6668 15.8333ZM13.3335 15.8333V12.5H15.8335V15.8333H13.3335ZM13.3335 7.5V4.16667H15.8335V7.5H13.3335Z"
      fill="currentColor"
    />
  </svg>,
  "WorkflowsIcon"
);

export default WorkflowsIcon;
