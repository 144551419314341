import { Box, Stack } from "@mui/material";
import { Thumbnail } from "react-pdf";
import { PdfViewerState } from "./pdfViewerState";
import { pdfViewerStyles } from "./pdfViewerStyles";

interface Props {
  viewerState: PdfViewerState;
}

const PdfThumbnailsContainer = ({ viewerState }: Props) => {
  return (
    <Stack spacing={2} p={1} bgcolor={pdfViewerStyles.bgcolor}>
      {Array.from(new Array(viewerState.numPages), (_, index) => {
        const pageNumber = index + 1;
        const isSelected = viewerState.currentPageNumber === pageNumber;
        return (
          <Box
            key={`pdf-thumbnail-${index}`}
            sx={{ borderWidth: 2, borderStyle: "solid", borderColor: isSelected ? "primary.main" : "transparent" }}
          >
            <Thumbnail width={160} pageNumber={pageNumber} />
          </Box>
        );
      })}
    </Stack>
  );
};

export default PdfThumbnailsContainer;
