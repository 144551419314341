import { Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import adminApi from "../../../../api/adminApi";
import { ObjectClassDefinitionInfo } from "../../../../api/types/objectTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import { getColumnDefinitions } from "./objectsGridDataProvider";

const ObjectsList = () => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();

  const [objects, error, { isFetching }] = useFetch(adminApi.getOrganizationObjectDefinitions);

  if (error) {
    return <DataLoadingFailed title="Failed to load objects" />;
  }

  const handleCellClick = (objectType: string) => {
    navigate(`/${clientCode}/${pageRoutes.settings}/${pageRoutes.dataModel}/${pageRoutes.orgObjects}/${objectType}`);
  };

  return (
    <Container maxWidth="md" disableGutters sx={{ height: "100%" }}>
      <DataGrid<ObjectClassDefinitionInfo>
        columns={getColumnDefinitions()}
        rows={objects ?? []}
        getRowId={(row) => row.objectType}
        loading={isFetching}
        rowHeight={40}
        slots={{
          loadingOverlay: () => <InlineLoader />,
          noRowsOverlay: () => (
            <CenteredWrapper>
              <Typography color="text.secondary">No objects yet</Typography>
            </CenteredWrapper>
          ),
        }}
        onCellClick={({ row }) => handleCellClick(row.objectType)}
        selectableRows
        hideFooter
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
      />
    </Container>
  );
};

export default ObjectsList;
