import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import CheckItemsList, { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import SearchField from "../../../../../../shared/components/inputs/SearchField";

interface Props {
  open: boolean;
  selectedCompanies: string[];
  availableCompanies: ListItemData[];
  handleClose: (selectedClientCodes: string[]) => void;
}

export default function SelectCompaniesDialog({ open, availableCompanies, selectedCompanies, handleClose }: Props) {
  const [searchFilter, setSearchFilter] = useState("");
  const [companiesToAdd, setCompaniesToAdd] = useState<string[]>(selectedCompanies);

  const clientsCompaniesList: ListItemData[] = useMemo(
    () => availableCompanies.filter((client) => client.label.toLowerCase().includes(searchFilter.trim().toLowerCase())),
    [availableCompanies, searchFilter]
  );

  const selectedCompaniesList = useMemo(
    () => clientsCompaniesList.filter((company) => selectedCompanies.includes(company.value)),
    [clientsCompaniesList, selectedCompanies]
  );

  const handleSelectedCompanies = useCallback((companies: ListItemData[]) => {
    setCompaniesToAdd(companies.map((company) => company.value));
  }, []);

  return (
    <Dialog
      open={open}
      scroll="paper"
      hideBackdrop
      sx={{
        "& .MuiDialog-paper": {
          height: 540,
          width: 480,
          border: "1px solid #E0E0E0",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, pl: 2.5, pr: 1.875, py: 1.375, pb: 1 }}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6">Companies</Typography>
          <CloseIconButton onClick={() => handleClose(selectedCompanies)} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ m: 0, px: 2.5, pb: 2, pt: 0.375 }}>
          <SearchField placeholder="Enter company name" fullWidth autoFocus onSearch={setSearchFilter} />
        </Box>
        <Divider />
        <CheckItemsList
          sx={{
            ".MuiListItem-root": {
              px: 1.5,
              py: 0.2,
            },
          }}
          managed
          items={clientsCompaniesList}
          onSelectedChanged={handleSelectedCompanies}
          preselectedItems={selectedCompaniesList}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ px: 2.5, py: 2 }}>
        <Button color="secondary" variant="text" onClick={() => handleClose(selectedCompanies)}>
          Cancel
        </Button>
        <Button disabled={companiesToAdd.length === 0} variant="contained" onClick={() => handleClose(companiesToAdd)}>
          {`Select ${!companiesToAdd.length ? "" : " " + companiesToAdd.length}
          ${companiesToAdd.length <= 1 ? "Company" : "Companies"}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
