import { Grid, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import useReports from "./hooks/useReports";
import biClient from "../../../../shared/reporting/api/biClient";
import { ApiReportsContextProvider } from "./reports/api-reports/contexts/ReportsContext";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useLocalization } from "../../../hooks/useLocalization";
import ApiReportsManager from "./reports/api-reports/contexts/ReportsManager";
import { BiApiClientBase, BiApiClientProvider } from "../../../../shared/reporting/contexts/BiApiClientProviderContext";

const CompanyApiReportsPage = () => {
  const { reports: locale } = useLocalization();
  const { reports, ui: reportsUi, actions: reportsActions } = useReports(biClient.apiReports.getReports, "Api");

  if (!reportsUi.loaded) {
    return (
      <Stack sx={{ height: "100%" }}>
        <InlineLoader text={locale.loading_label} />
      </Stack>
    );
  }

  return (
    <Grid container flex={1} height="100%">
      <BiApiClientProvider<BiApiClientBase>
        api={{
          validateName: biClient.apiReports.validateReportName,
          getReportSharing: biClient.apiReports.getReportSharing,
          updateReportSharing: biClient.apiReports.updateReportSharing,
        }}
      >
        <ApiReportsContextProvider reports={reports} ui={reportsUi} actions={reportsActions}>
          <ApiReportsManager
            deleteReport={reportsActions.deleteReports}
            duplicate={reportsActions.duplicateReport}
            reports={reports}
          >
            <Outlet />
          </ApiReportsManager>
        </ApiReportsContextProvider>
      </BiApiClientProvider>
    </Grid>
  );
};

export default CompanyApiReportsPage;
