import { Link, Stack, Typography } from "@mui/material";

interface Props {
  label: string;
  consentName: string;
  url?: string;
}

const ConsentAgreementLabel = ({ label, consentName, url }: Props) => (
  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
    <Typography>{label}</Typography>
    {url ? (
      <Link href={url} target="_blank" rel="noopener noreferrer">
        {consentName}
      </Link>
    ) : (
      <Typography>{consentName}</Typography>
    )}
  </Stack>
);

export default ConsentAgreementLabel;
