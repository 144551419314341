import MailIcon from "@mui/icons-material/MailOutlineRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import {
  ValidationResult,
  combineValidators,
  emailValidator,
  invalidResult,
  maxCharactersValidator,
  requiredValidator,
  uniqueValidator,
  validResult,
} from "../../../../../shared/utilities/validators";
import adminApi, { Contact, CreateContactData } from "../../../../api/adminApi";

interface Props {
  open: boolean;
  onClose: () => void;
  onNewContactCreated: (contactId: string, contactData: CreateContactData) => void;
  contacts: Contact[];
}

const createContact = withErrorHandling(adminApi.createContact);

const maxNameLength = 100;

const ContactCreateDialog = ({ open, onClose, onNewContactCreated, contacts }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [contactData, setContactData] = useState<CreateContactData>({ name: "", email: "" });
  const [nameValidationResult, setNameValidationResult] = useState<ValidationResult>(invalidResult(""));
  const [emailValidationResult, setEmailValidationResult] = useState<ValidationResult>(validResult());
  const [nameCollisionWarning, setNameCollisionWarning] = useState<string>();
  const [isSaving, setSaving] = useState(false);

  const validateName = combineValidators(requiredValidator, maxCharactersValidator(maxNameLength));

  const validateEmail = combineValidators(
    requiredValidator,
    emailValidator,
    uniqueValidator(
      "A contact with this email already exists",
      contacts.map((c) => c.email.toLowerCase())
    )
  );

  const handleNameChange = (name: string) => {
    setContactData({ ...contactData, name });
    const validationResult = validateName(name);
    setNameValidationResult(validationResult);

    if (validationResult.isValid) {
      const uniqueValidationResult = uniqueValidator(
        "A contact with this name already exists",
        contacts.map((c) => c.name)
      )(name);

      setNameCollisionWarning(uniqueValidationResult.isValid ? undefined : uniqueValidationResult.error);
    }
  };

  const handleEmailChange = (email: string) => {
    setContactData({ ...contactData, email });
    setEmailValidationResult(validateEmail(email.toLowerCase()));
  };

  const reset = () => {
    setContactData({ name: "", email: "" });
    setNameValidationResult(invalidResult(""));
    setEmailValidationResult(validResult());
  };

  const handleCreate = async () => {
    setSaving(true);
    const [contact, error] = await createContact(contactData);
    setSaving(false);

    if (error) {
      logError(error, "[ContactCreateDialog]");
      sendNotificationError("Failed to create contact");
      return;
    }

    sendNotification("Contact created successfully");
    onNewContactCreated(contact.id, contactData);
    setTimeout(() => reset());
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => reset());
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Contact</DialogTitle>
      <DialogCloseButton onClick={handleClose} />

      <DialogContent>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Full Name"
            variant="outlined"
            onChange={(e) => handleNameChange(e.target.value)}
            error={!!nameValidationResult.error}
            helperText={nameValidationResult.error || nameCollisionWarning}
            inputProps={{ maxLength: maxNameLength }}
            InputProps={{
              endAdornment: (
                <Typography color="text.secondary" variant="caption">
                  {maxNameLength - contactData.name.length}
                </Typography>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            onChange={(e) => handleEmailChange(e.target.value)}
            value={contactData.email}
            error={!!emailValidationResult.error}
            helperText={emailValidationResult.error}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={isSaving}
          variant="contained"
          disabled={!nameValidationResult.isValid || !emailValidationResult.isValid}
          onClick={handleCreate}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContactCreateDialog;
