import { SvgIcon, SvgIconProps } from "@mui/material";

const DeletePersonIcon = (props: SvgIconProps) => (
  <SvgIcon sx={{ height: 80, width: 80 }} viewBox="0 0 80 80" {...props}>
    <path
      d="M39.4504 45.1C33.7504 45.1 29.1504 39.6 29.1504 32.8C29.1504 26 33.7504 20.5 39.4504 20.5C45.1504 20.5 49.7504 26 49.7504 32.8C49.7504 39.6 45.0504 45.1 39.4504 45.1ZM39.4504 25.1C36.3504 25.1 33.7504 28.5 33.7504 32.8C33.7504 37 36.3504 40.5 39.4504 40.5C42.5504 40.5 45.1504 37.1 45.1504 32.8C45.1504 28.6 42.5504 25.1 39.4504 25.1Z"
      fill="#C7DBE5"
    />
    <path
      d="M39.4504 46.5C35.5504 46.5 25.4504 56.8 22.6504 66.1H27.4504C30.1504 58.8 37.2504 52.3 39.3504 51.2C41.4504 52.3 48.5504 58.8 51.2504 66.1H56.0504C53.4504 56.8 43.3504 46.5 39.4504 46.5Z"
      fill="#C7DBE5"
    />
    <path
      d="M39.3502 71.7C22.2502 71.7 8.4502 57.9 8.4502 40.9C8.4502 23.9 22.2502 10 39.3502 10C56.4502 10 70.2502 23.8 70.2502 40.9C70.2502 58 56.3502 71.7 39.3502 71.7ZM39.3502 15C25.0502 15 13.4502 26.6 13.4502 40.9C13.4502 55.2 25.0502 66.8 39.3502 66.8C53.6502 66.8 65.2502 55.2 65.2502 40.9C65.2502 26.6 53.5502 15 39.3502 15Z"
      fill="#C7DBE5"
    />
    <path d="M70.5361 47.9991L53 65.5352L56.5355 69.0707L74.0716 51.5346L70.5361 47.9991Z" fill="#F44336" />
    <path d="M56.5355 47.9997L53 51.5352L70.5361 69.0712L74.0716 65.5357L56.5355 47.9997Z" fill="#F44336" />
  </SvgIcon>
);

export default DeletePersonIcon;
