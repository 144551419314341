import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../../shared/services/downloadFile";
import adminApi, { Invoice } from "../../../../../api/adminApi";
import { useClientContext } from "../../../../../context/ClientContext";
import { getBusinessCentralTransactionUrl } from "../../../../../utilities/navigationHelper";

interface Props {
  row: Invoice;
}

const getFileDownloadInfo = withErrorHandling(adminApi.getInvoiceDownloadFileInfo);

const InvoicesGridActionsCell = ({ row }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const {
    settings: { businessCentralSettings },
  } = useClientContext();

  const transactionUrl = getBusinessCentralTransactionUrl(
    businessCentralSettings,
    row.businessCentralTransactionNo,
    row.businessCentralPostedTransactionNo,
    row.transactionTypeCode
  );

  const handleOpenTransaction = () => {
    window.open(transactionUrl, "_blank");
  };

  const handleDownloadFile = async (id: string) => {
    const [fileDownloadInfo, error] = await getFileDownloadInfo(id);

    if (error) {
      sendNotificationError("Could not download the file");
      logError(error, "[InvoicesGridActionsCell]");
      return;
    }

    downloadFileFromUrl(fileDownloadInfo.downloadUrl);
  };

  return (
    <Stack direction="row" spacing={1}>
      {transactionUrl && (
        <Tooltip title="Open transaction in Fund Operations" arrow>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleOpenTransaction();
            }}
          >
            <OpenInNewRoundedIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Download Invoice file" arrow>
        <IconButton onClick={() => handleDownloadFile(row.id)}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default InvoicesGridActionsCell;
