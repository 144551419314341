import { logError } from "../../../../shared/logging";
import { EntityOptionFieldType } from "../../../api/types/objectTypes";
import { useClientContext } from "../../../context/ClientContext";
import { EntityFieldComponentProps, EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError, getSelectOptionsFromDictionaries } from "../helpers";
import EntityFieldSelectComponent from "./EntityFieldSelectComponent";

const EntitySelectField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntitySelectField]");
    return null;
  }

  if (fieldConfiguration?.$type !== "Select") {
    const error = `Invalid configuration for Select field: ${fieldConfiguration?.$type}`;
    logError(error, "[EntitySelectField]");
    return null;
  }

  return (
    <EntityFieldSelectForTypeComponent
      value={value}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      fieldType={fieldConfiguration.fieldType}
      onChange={onChange}
      fieldInputId={fieldInputId}
    />
  );
};

interface EntityFieldSelectForTypeComponentProps extends EntityFieldComponentProps<string> {
  fieldType: EntityOptionFieldType;
}

const EntityFieldSelectForTypeComponent = ({
  value,
  fieldId,
  fieldAttributes,
  fieldType,
  onChange,
  fieldInputId,
}: EntityFieldSelectForTypeComponentProps) => {
  const { dictionaries } = useClientContext();
  const options = getSelectOptionsFromDictionaries(fieldType, dictionaries);
  return (
    <EntityFieldSelectComponent
      fieldId={fieldId}
      value={value}
      options={options}
      fieldAttributes={fieldAttributes}
      onChange={(value, validationResult) => onChange(value, fieldId, validationResult)}
      fieldInputId={fieldInputId}
    />
  );
};

export default EntitySelectField;
