import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { stringComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import { EntityFieldConfiguration, LookupFieldConfiguration } from "../../../../../api/types/objectTypes";
import { useObjectDefinitionContext } from "../../ObjectDefinitionContext";
import { FieldConfigurationValidation } from "../objectFieldsState";
import { isObjectTypeAllowedAsLookupTarget } from "./userDefinedFieldConfigurations";

interface Props {
  fieldConfiguration: LookupFieldConfiguration;
  validation: FieldConfigurationValidation;
  readOnly?: boolean;
  onChange: (fieldConfiguration: EntityFieldConfiguration) => void;
}

const LookupFieldConfigEditor = ({ fieldConfiguration, validation, readOnly, onChange }: Props) => {
  const { allObjectDefinitions } = useObjectDefinitionContext();

  const lookupTargets = allObjectDefinitions.filter((def) => isObjectTypeAllowedAsLookupTarget(def.objectType));

  const handleChange = (e: SelectChangeEvent<string>) => {
    const objectType = e.target.value;
    onChange({ ...fieldConfiguration, objectType });
  };

  return (
    <Select
      disabled={readOnly}
      fullWidth
      displayEmpty
      value={fieldConfiguration.objectType}
      renderValue={(value) =>
        value ? <Typography>{value}</Typography> : <Typography color="text.secondary">Select target object</Typography>
      }
      onChange={handleChange}
      error={!validation.result.isValid}
    >
      {lookupTargets.sort(stringComparerBy((d) => d.objectName)).map((def) => (
        <MenuItem key={def.objectType} value={def.objectType}>
          {def.objectName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LookupFieldConfigEditor;
