import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { CompanyType } from "../../../../api/types/clientTypes";
import CompanyTypeBadge from "../../../common/CompanyTypeBadge";

interface Props {
  value: CompanyType;
  disabled: boolean;
  onChange: (value: CompanyType) => void;
}

const companyTypeOptions: CompanyType[] = ["Production", "Demo", "Template", "Training", "Sandbox"];

const companyTypeDescription = {
  ["Production"]: "Production company",
  ["Demo"]: "Company for demonstrations",
  ["Template"]: "Blueprint company for replicating setups",
  ["Training"]: "Company for user training",
  ["Sandbox"]: "Experimental environment for testing and development",
};

const CompanyTypeSelect = ({ value, onChange, disabled }: Props) => {
  return (
    <FormControl>
      <InputLabel>Company Type</InputLabel>
      <Select
        label="Company Type"
        value={value}
        onChange={(e) => onChange(e.target.value as CompanyType)}
        renderValue={(selected) => <Typography>{selected}</Typography>}
        disabled={disabled}
      >
        {companyTypeOptions.map((opt) => (
          <MenuItem key={opt} value={opt}>
            <Box display={"flex"} flex={1} justifyContent={"space-between"} alignItems={"center"}>
              <Stack>
                <Typography>{opt}</Typography>
                <Typography variant="body2" color="secondary">
                  {companyTypeDescription[opt]}
                </Typography>
              </Stack>
              {opt === "Production" ? (
                <Typography color="secondary">No tag</Typography>
              ) : (
                <CompanyTypeBadge type={opt} />
              )}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CompanyTypeSelect;
