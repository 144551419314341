import { Stack, Typography } from "@mui/material";
import { Field } from "../../../../api/types/objectTypes";
import KeyVerticalIcon from "../../../../icons/KeyVerticalIcon";
import FieldIcon from "../../../common/domain-icons/FieldIcon";

interface Props {
  field: Field;
}

const ObjectFieldName = ({ field }: Props) => (
  <Stack direction="row" spacing={1}>
    <FieldIcon fieldType={field.type} color="action" />
    <Typography sx={{ pl: 0.5 }}>{field.name + (field.attributes.includes("Required") ? " *" : "")}</Typography>
    {field.attributes.includes("ObjectIdentifier") && <KeyVerticalIcon color="primary" />}
  </Stack>
);

export default ObjectFieldName;
