import { Box, Tooltip } from "@mui/material";
import BadgeDetached from "../../../shared/components/BadgeDetached";
import CategoryTag from "./CategoryTag";
import MultilineTooltipTitle from "./MultilineTooltipTitle";

interface Props {
  categoryNames: string[];
  maxDisplayedCategories?: number;
}

const CategoryInlineTags = ({ categoryNames, maxDisplayedCategories }: Props) => (
  <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
    {categoryNames.slice(0, maxDisplayedCategories).map((categoryName) => (
      <CategoryTag key={categoryName} categoryName={categoryName} />
    ))}

    {maxDisplayedCategories !== undefined && categoryNames.length > maxDisplayedCategories && (
      <Tooltip arrow title={<MultilineTooltipTitle lines={categoryNames.slice(maxDisplayedCategories)} />}>
        <Box>
          <BadgeDetached color="secondary" badgeContent={`+${categoryNames.length - maxDisplayedCategories}`} />
        </Box>
      </Tooltip>
    )}
  </Box>
);

export default CategoryInlineTags;
