import { DatePicker } from "@mui/x-date-pickers-pro";
import { isValid, parseISO, formatISO } from "date-fns";
import { useState, useEffect } from "react";

interface Props {
  commitmentDate: string | undefined;
  validationError?: string;
  disabled?: boolean;
  onDateChanged: (date: string | undefined) => void;
}

const CommitmentDatePicker = ({ commitmentDate, onDateChanged, validationError, disabled }: Props) => {
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (commitmentDate && isValid(commitmentDate)) {
      setDate(parseISO(commitmentDate));
    }
  }, [commitmentDate]);

  return (
    <DatePicker
      label="Commitment Date"
      value={date}
      onChange={(date, ctx) => {
        setDate(date);
        onDateChanged(
          ctx.validationError || !date || !isValid(date) ? undefined : formatISO(date, { representation: "date" })
        );
      }}
      slotProps={{
        textField: {
          error: !!validationError,
        },
      }}
      disabled={disabled}
    />
  );
};

export default CommitmentDatePicker;
