import { LinearProgress, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { CapCallOrDistribution, CashFlowRequest, Investor } from "../../../../../api/adminApi";
import InvestorCapitalActivityFilters from "./InvestorCapitalActivityFilters";
import InvestorCapitalActivityTable from "./InvestorCapitalActivityTable";

const defaultPaging = { page: 0, size: 30, totals: false };

const InvestorDetailsCapitalActivityTab = ({ investor }: { investor: Investor }) => {
  const [capCalls, setCapitalActivityList] = useState<CapCallOrDistribution[]>([]);

  const [cashFlowRequest, setCashFlowRequest] = useState<CashFlowRequest>({
    paging: defaultPaging,
    isInvestorPortalMode: false,
  });

  const fetchCapitalActivity = useCallback(
    () => adminApi.getInvestorCapitalActivity(investor.id, cashFlowRequest),
    [investor, cashFlowRequest]
  );

  const [cashFlowCapCallsChunk, cashFlowError, { isFetching }] = useFetch(fetchCapitalActivity, (data) => {
    const items = cashFlowRequest.paging.page === 0 ? data : [...capCalls, ...data];
    setCapitalActivityList([...new Map(items.map((item) => [item["id"], item])).values()]);
  });

  if (cashFlowError) {
    logError(cashFlowError.message, "InvestorCapitalActivityPage");
    return <DataLoadingFailed bgColor="none" title="Loading investor capital activity failed" />;
  }

  if (!cashFlowCapCallsChunk) {
    return <InlineLoader />;
  }

  const loadNext = () => {
    setCashFlowRequest({
      ...cashFlowRequest,
      paging: { page: (cashFlowRequest.paging.page ?? 0) + 1, size: cashFlowRequest.paging.size, totals: false },
    });
  };

  const onFilterChange = (request: CashFlowRequest) => {
    if (request.isInvestorPortalMode !== cashFlowRequest.isInvestorPortalMode) {
      setCapitalActivityList([]);
    }
    setCashFlowRequest({ ...request, paging: defaultPaging });
  };

  const hasMore =
    capCalls.length > 0 &&
    cashFlowRequest.paging.page !== undefined &&
    cashFlowRequest.paging.size !== undefined &&
    cashFlowRequest.paging.page * cashFlowRequest.paging.size < capCalls.length;

  const showInfiniteLoader = hasMore && !!cashFlowRequest.paging.page;

  return (
    <Stack spacing={2.5} height="100%">
      <InvestorCapitalActivityFilters
        request={cashFlowRequest}
        onChange={onFilterChange}
        fundNames={investor.funds.map((fund) => fund.fundName)}
        isLoading={isFetching}
      />
      {!cashFlowError && capCalls !== undefined && (
        <InfiniteScroll
          dataLength={capCalls.length}
          next={loadNext}
          hasMore={!!hasMore}
          loader={showInfiniteLoader && <LinearProgress sx={{ width: "33%", margin: "0 auto", my: 3 }} />}
        >
          {<InvestorCapitalActivityTable isLoading={isFetching} cashFlowCapCalls={capCalls} />}
        </InfiniteScroll>
      )}
      {isFetching && <InlineLoader />}
      {cashFlowError && <DataLoadingFailed />}
    </Stack>
  );
};

export default InvestorDetailsCapitalActivityTab;
