import { logError } from "../../../../shared/logging";
import { NumberEntityFieldConfiguration } from "../../../api/types/objectTypes";
import { EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError } from "../helpers";
import EntityNumberComponent from "./EntityNumberComponent";

const EntityNumberField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "number" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityNumberField]");
    return null;
  }

  return (
    <EntityNumberComponent
      precision={(fieldConfiguration as NumberEntityFieldConfiguration)?.precision ?? 0}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      value={value}
      onChange={onChange}
      fieldInputId={fieldInputId}
    />
  );
};

export default EntityNumberField;
