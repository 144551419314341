import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Button, Popover, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { cornersBeforePositions } from "../../../common/corners";

interface Props {
  title: string;
  description: string;
  onNavigate: () => void;
}

const ContactWarning = ({ title, description, onNavigate }: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  return (
    <>
      <WarningRoundedIcon sx={{ cursor: "pointer" }} color="warning" onClick={(e) => setAnchorEl(e.currentTarget)} />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            overflow: "visible",
            mt: 1,
            ml: -2,
            p: 2,
            width: 320,
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "&:before": cornersBeforePositions.top("left", 22),
          },
        }}
      >
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography color={"textSecondary"}>{description}</Typography>
          <Button onClick={onNavigate} sx={{ alignSelf: "flex-end" }} variant="text">
            View
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default ContactWarning;
