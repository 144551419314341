export const approvalTypes = ["Payments", "InvestorRequests", "DocumentCollections"] as const;
export type ApprovalType = (typeof approvalTypes)[number];

export interface StoredApprovalRequest {
  id: string;
  type: ApprovalType;
  deriveNameFrom: "Entity" | "Counterparty";
  dueDate: string;
  transactionNo: string;
  transactionType: string;
  requestedByUserName: string;
  requestedAt: string;
  currencyCode: string;
  payments: ApprovalPaymentInfo[];
  steps: ApprovalRequestStep[];
}

export interface ApprovalRequest extends StoredApprovalRequest {
  name: string;
  status: ApprovalRequestStatus;
  currentPendingStepId?: string;
  allowUpdateForCurrentUser: boolean;
  approvedStepsCount: number;
  totalStepsCount: number;
  totalAmount: number;
  entityNames: string[];
  counterpartyNames: string[];
  counterpartyBankNames: string[];
  counterpartyAccountNames: string[];
  counterpartyAbaNumbers: string[];
  counterpartyAccountNumbers: string[];
}

export interface ApprovalRequestStep {
  id: string;
  allowUpdateForCurrentUser: boolean;
  status: ApprovalRequestStatus;
  updatedAt?: string;
  updatedByUserName?: string;
  comment?: string;
}

export type ApprovalRequestStatus = "Pending" | "Approved" | "Rejected";

export interface ApprovalPaymentInfo {
  id: string;
  counterpartyName: string;
  counterpartyBank: BankInfo;
  entityId: string;
  entityType: string;
  entityName: string;
  entityBank: BankInfo;
  amount: number;
  currencyCode: string;
}

export interface BankInfo {
  bankName: string;
  accountName: string;
  abaNumber: string;
  accountNumber: string;
}
