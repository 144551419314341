import {
  FilterHandler,
  NumberFieldValue,
  NumberFilterDefinition,
  NumberFilterOperator,
  NumberFilterValue,
  OperatorOption,
} from "../filterTypes";

export const numberFilterOperatorOptions: readonly OperatorOption<NumberFilterOperator>[] = [
  { label: "is", value: "equals" },
  { label: "is not", value: "not_equals" },
  { label: "between", value: "between" },
  { label: "greater than", value: "greater_than" },
  { label: "less than", value: "less_than" },
  { label: "is empty", value: "empty" },
  { label: "is not empty", value: "not_empty" },
] as const;

const equalityPrecision = 0.01;

export const numberFilterHandler: FilterHandler<NumberFilterValue, NumberFieldValue, NumberFilterDefinition> = {
  getDefaultValue: (definition: NumberFilterDefinition) => ({
    operator: definition.operatorOptions?.[0] ?? "equals",
    number: undefined,
  }),

  isActive: ({ operator, number, secondNumber }: NumberFilterValue) => {
    switch (operator) {
      case "equals": {
        return number !== undefined;
      }
      case "not_equals": {
        return number !== undefined;
      }
      case "between": {
        return number !== undefined && secondNumber !== undefined;
      }
      case "greater_than": {
        return number !== undefined;
      }
      case "less_than": {
        return number !== undefined;
      }
      case "empty": {
        return true;
      }
      case "not_empty": {
        return true;
      }
    }
  },

  formatValue: ({ operator, number, secondNumber }: NumberFilterValue) => {
    switch (operator) {
      case "equals": {
        return `is ${number}`;
      }
      case "not_equals": {
        return `is not ${number}`;
      }
      case "between": {
        return `is between ${number} and ${secondNumber}`;
      }
      case "greater_than": {
        return `is greater than ${number}`;
      }
      case "less_than": {
        return `is less than ${number}`;
      }
      case "empty": {
        return "is empty";
      }
      case "not_empty": {
        return "is not empty";
      }
    }
  },

  matchesFieldValue: ({ operator, number, secondNumber }: NumberFilterValue, fieldValue: NumberFieldValue) => {
    const fieldNumber = Number.isNaN(fieldValue) ? 0 : fieldValue ?? 0; // empty pr invalid value is always treated as 0

    switch (operator) {
      case "equals": {
        if (number === undefined) {
          return true;
        }
        return Math.abs(number - fieldNumber) < equalityPrecision;
      }

      case "not_equals": {
        if (number === undefined) {
          return true;
        }
        return Math.abs(number - fieldNumber) >= equalityPrecision;
      }

      case "between": {
        if (number === undefined || secondNumber === undefined) {
          return true;
        }
        return fieldNumber >= number && fieldNumber <= secondNumber;
      }

      case "greater_than": {
        if (number === undefined) {
          return true;
        }
        return fieldNumber > number;
      }

      case "less_than": {
        if (number === undefined) {
          return true;
        }
        return fieldNumber < number;
      }

      case "empty": {
        return fieldNumber === 0;
      }

      case "not_empty": {
        return fieldNumber !== 0;
      }
    }
  },
};
