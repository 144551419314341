import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { JobProgress } from "../../../../../shared/api/types";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import AsyncOperationProgress from "../../../common/AsyncOperationProgress";
import MemberDialogIcon from "../../../common/MemberDialogIcon";
import NavigateToInvitationEmailSettings from "./NavigateToInvitationEmailSettings";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  contactIds: string[];
}

const sendInvitationToContacts = withErrorHandling(adminApi.sendInvitationToContacts);
const getJobProgress = withErrorHandling(adminApi.getContactsJobProgress);

const InviteContactsDialog = ({ open, onClose, onSubmit, contactIds }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [isSending, setSending] = useState(false);
  const [asyncOperationId, setAsyncOperationId] = useState<string>();
  const [jobProgress, setJobProgress] = useState<JobProgress>();

  const handleUpdateJobProgress = useCallback(async () => {
    if (!asyncOperationId) {
      return;
    }

    const [resp, error] = await getJobProgress(asyncOperationId);
    if (error) {
      logError(error, "[InviteContactsDialog]");
      setTimeout(() => {
        setAsyncOperationId(undefined);
        setJobProgress(undefined);
      });
      return;
    }

    setJobProgress(resp);

    if (resp.finished) {
      const { totalProgressItems } = resp;
      setTimeout(() => {
        setAsyncOperationId(undefined);
        setJobProgress(undefined);
        sendNotification(
          `Invitation has been sent to ${totalProgressItems} ${totalProgressItems === 1 ? "contact" : "contacts"}`
        );
        onSubmit();
      }, 500);
    }
  }, [asyncOperationId, onSubmit, sendNotification]);

  const handleSubmit = async () => {
    if (contactIds.length === 0) {
      return;
    }

    setSending(true);
    const [resp, error] = await sendInvitationToContacts(contactIds);
    setSending(false);

    if (error) {
      logError(error, "[InviteContactsDialog]");
      sendNotificationError("Failed to send invitation");
      return;
    }

    setAsyncOperationId(resp.asyncOperationId);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Send Invitation</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        {asyncOperationId && (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <AsyncOperationProgress
              title="Sending invitation(s)..."
              showStepDetails
              jobProgress={jobProgress}
              updateIntervalMs={500}
              onUpdateProgress={handleUpdateJobProgress}
            />
          </Box>
        )}

        {!asyncOperationId && (
          <Stack alignItems="center" spacing={2}>
            <MemberDialogIcon />
            <Typography color="text.secondary" textAlign="center">
              You're about to send an invitation to <strong>{contactIds.length}</strong>{" "}
              {contactIds.length > 1 ? "contacts" : "contact"}.
            </Typography>
            <NavigateToInvitationEmailSettings />
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" color="primary" loading={isSending} onClick={handleSubmit}>
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteContactsDialog;
