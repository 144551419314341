import { SvgIcon, SvgIconProps } from "@mui/material";

export const DuplicateReportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 21, fill: "white" }} viewBox="0 0 20 21" {...props}>
      <path
        d="M6.66668 13.8334H5.00001C4.55798 13.8334 4.13406 13.6578 3.8215 13.3452C3.50894 13.0327 3.33334 12.6087 3.33334 12.1667V5.50004C3.33334 5.05801 3.50894 4.63409 3.8215 4.32153C4.13406 4.00897 4.55798 3.83337 5.00001 3.83337H11.6667C12.1087 3.83337 12.5326 4.00897 12.8452 4.32153C13.1577 4.63409 13.3333 5.05801 13.3333 5.50004V7.16671M8.33334 17.1667H15C15.442 17.1667 15.866 16.9911 16.1785 16.6786C16.4911 16.366 16.6667 15.9421 16.6667 15.5V8.83337C16.6667 8.39135 16.4911 7.96742 16.1785 7.65486C15.866 7.3423 15.442 7.16671 15 7.16671H8.33334C7.89132 7.16671 7.46739 7.3423 7.15483 7.65486C6.84227 7.96742 6.66668 8.39135 6.66668 8.83337V15.5C6.66668 15.9421 6.84227 16.366 7.15483 16.6786C7.46739 16.9911 7.89132 17.1667 8.33334 17.1667Z"
        stroke="#8E9BA9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default DuplicateReportIcon;
