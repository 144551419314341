import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { ObjectLayoutState } from "./objectLayoutState";

interface Props {
  layoutState: ObjectLayoutState;
  setLayoutState: Dispatch<SetStateAction<ObjectLayoutState>>;
}

type ContextValue = Props;

const ObjectLayoutContext = createContext<ContextValue | undefined>(undefined);

export const ObjectLayoutContextProvider = ({ layoutState, setLayoutState, children }: PropsWithChildren<Props>) => (
  <ObjectLayoutContext.Provider value={{ layoutState, setLayoutState }}>{children}</ObjectLayoutContext.Provider>
);

export const useObjectLayoutContext = () =>
  defined(useContext(ObjectLayoutContext), "Attempt to use ObjectLayoutContext without provider");
