import { Card, Divider, Stack, Typography } from "@mui/material";
import { hexStringToRgb } from "../../../../../shared/utilities/colorHelper";

interface Props {
  name: string;
  investorsCount: number;
  onClick: () => void;
}

const FundInfoCard = ({ name, investorsCount, onClick }: Props) => (
  <Card
    variant="outlined"
    onClick={onClick}
    sx={(theme) => ({
      maxWidth: theme.spacing(50),
      minWidth: theme.spacing(37.5),
      p: 2,
      alignSelf: "stretch",
      "&:hover": {
        "& .fund-name": {
          color: theme.palette.primary.main,
        },
        borderColor: (theme) => `rgba(${hexStringToRgb(theme.palette.primary.main)}, 0.5)`,
        cursor: "pointer",
      },
    })}
  >
    <Stack spacing={1.5}>
      <Typography className="fund-name" variant="h6">
        {name}
      </Typography>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
        <Typography color="text.secondary">Investors</Typography>
        <Typography>{investorsCount || "-"}</Typography>
      </Stack>
    </Stack>
  </Card>
);

export default FundInfoCard;
