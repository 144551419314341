import { createSvgIcon } from "@mui/material";

const SaltEdgeIcon = createSvgIcon(
  <svg fill="none" viewBox="0 0 30 30">
    <g fill="#000a21">
      <path d="m19.0117 9.74441v3.99539l-.0008-.0008-16.70645 6.323.00239-3.9958z" />
      <path d="m.00140431 13.1836 21.31469569-8.00999-.0002-5.17361-21.3159 8.0098z" />
      <path d="m0 29.8064 21.3155-8.0111.0006-5.1725-21.3161 8.0105z" />
    </g>
  </svg>,
  "SaltEdgeIcon"
);

export default SaltEdgeIcon;
