import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, SxProps, Tab, Theme } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../api/adminApi";
import { DocumentCollectionContextProvider } from "./DocumentCollectionContext";
import DocumentCollectionHeader from "./DocumentCollectionHeader";
import DocumentsView from "./documents-view/DocumentsView";
import DocumentsViewFundLevel from "./documents-view/DocumentsViewFundLevel";
import EmailsView from "./emails-view/EmailsView";

const tabPanelStyle: SxProps<Theme> = () => ({
  height: "100%",
  p: 0,
});

const DocumentCollection = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchDocumentCollectionDetails = useCallback(() => adminApi.getDocumentCollectionDetails(defined(id)), [id]);
  const [documentCollectionDetails, fetchError] = useFetch(fetchDocumentCollectionDetails);

  const [sentInvites, setSentInvites] = useState<Record<string, Date>>({});

  const [selectedTab, setSelectedTab] = useState("Documents");

  if (fetchError) {
    if (fetchError.type === "NotFound") {
      setTimeout(() => navigate(".."));
      return null;
    }

    logError(fetchError, "[DocumentCollection]");
    return <DataLoadingFailed title="Loading documents failed" />;
  }

  if (!documentCollectionDetails) {
    return <InlineLoader />;
  }

  const { documents, showEmailsView, messageLevel, status } = documentCollectionDetails;

  return (
    <DocumentCollectionContextProvider value={{ sentInvites, setSentInvites }}>
      <Container maxWidth={false} sx={{ height: "100%", py: 1.5, px: 3, overflowY: "hidden" }}>
        <Box display="flex" flexDirection="column" width="100%" height="100%">
          {documentCollectionDetails && (
            <DocumentCollectionHeader documentCollectionDetails={documentCollectionDetails} />
          )}
          <TabContext value={selectedTab}>
            <TabList onChange={(_, tab) => setSelectedTab(tab)}>
              <Tab label="Documents" value="Documents" />
              {showEmailsView && <Tab label="Emails" value="Emails" />}
            </TabList>
            <TabPanel value="Documents" sx={tabPanelStyle}>
              {messageLevel === "Fund" && (
                <DocumentsViewFundLevel documents={documents} showPublishingStatus={status === "PartiallyLive"} />
              )}
              {messageLevel !== "Fund" && (
                <DocumentsView documents={documents} showPublishingStatus={status === "PartiallyLive"} />
              )}
            </TabPanel>
            {showEmailsView && (
              <TabPanel value="Emails" sx={tabPanelStyle}>
                <EmailsView documentCollectionId={defined(id)} />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </Container>
    </DocumentCollectionContextProvider>
  );
};

export default DocumentCollection;
