import { useFilterContext } from "../FilterContext";
import { FilterValue, TableFilter } from "../filterTypes";
import BooleanFilterEditor from "./BooleanFilterEditor";
import DateFilterEditor from "./DateFilterEditor";
import MultiSelectFilterEditor from "./MultiSelectFilterEditor";
import MultiSelectFilterEditorWithAsyncOptions from "./MultiSelectFilterEditorWithAsyncOptions";
import NumberFilterEditor from "./NumberFilterEditor";
import TextFilterEditor from "./TextFilterEditor";

interface Props {
  filter: TableFilter;
}

const FilterEditor = ({ filter }: Props) => {
  const { dispatchFilters } = useFilterContext();

  const handleUpdateFilterValue = (newValue: FilterValue) =>
    dispatchFilters({ type: "update_filter_value", filterId: filter.id, newValue });

  switch (filter.type) {
    case "text": {
      return <TextFilterEditor filter={filter} onUpdateValue={handleUpdateFilterValue} />;
    }
    case "multi_select": {
      return filter.asyncOptions ? (
        <MultiSelectFilterEditorWithAsyncOptions filter={filter} onUpdateValue={handleUpdateFilterValue} />
      ) : (
        <MultiSelectFilterEditor filter={filter} onUpdateValue={handleUpdateFilterValue} />
      );
    }
    case "number": {
      return <NumberFilterEditor filter={filter} onUpdateValue={handleUpdateFilterValue} />;
    }
    case "date": {
      return <DateFilterEditor filter={filter} onUpdateValue={handleUpdateFilterValue} />;
    }
    case "boolean": {
      return <BooleanFilterEditor filter={filter} onUpdateValue={handleUpdateFilterValue} />;
    }
  }
};

export default FilterEditor;
