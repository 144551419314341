import { Button, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { BackupMasterApplicationLogs } from "../../../../api/adminApi";
import LogDetailsDialog from "../../../common/syncLogs/LogDetailsDialog";
import LogStatusIcon from "../../../common/syncLogs/LogStatusIcon";
import { LogsTableRowData, getRows } from "./dataBackupLogsTableDataProvider";
import LogsTableWrapper from "../../../common/syncLogs/LogsTableWrapper";

interface Props {
  logItems: BackupMasterApplicationLogs[];
}

interface LogsTableRowProps extends LogsTableRowData {
  onView: (details: BackupMasterApplicationLogs) => void;
}

const LogsTableRow = ({ severity, completed, description, details, onView }: LogsTableRowProps) => {
  const handleViewClick = () => onView(details);

  return (
    <TableRow hover>
      <TableCell width="10%">
        <LogStatusIcon severity={severity} />
      </TableCell>
      <TableCell>{completed}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell align="right" className="LogsTable-actions" width="15%" padding="checkbox">
        <Button variant="text" color="primary" onClick={handleViewClick}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

const LogsTableHeadRow = () => (
  <TableRow>
    <TableCell>Status</TableCell>
    <TableCell>Completed</TableCell>
    <TableCell>Event Type</TableCell>
    <TableCell></TableCell>
  </TableRow>
);

const DataBackupLogsTable = ({ logItems }: Props) => {
  const [logDetails, setLogDetails] = useState<BackupMasterApplicationLogs>();
  const [showLogDetails, setShowLogDetails] = useState(false);

  const handleViewDetails = (logDetails: BackupMasterApplicationLogs) => {
    setLogDetails(logDetails);
    setShowLogDetails(true);
  };

  const handleLogDetailsClose = () => setShowLogDetails(false);

  const rows = getRows(logItems);

  return (
    <>
      <LogsTableWrapper maxHeight="calc(100vh - 460px)">
        <TableHead>
          <LogsTableHeadRow />
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <LogsTableRow {...row} onView={handleViewDetails} key={row.key} />
          ))}
        </TableBody>
      </LogsTableWrapper>
      <LogDetailsDialog open={showLogDetails} onClose={handleLogDetailsClose} logDetails={logDetails} />
    </>
  );
};

export default DataBackupLogsTable;
