import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import BadgeDetached from "../../../../../../../shared/components/BadgeDetached";
import { DataImportChangesFilterValue } from "../../../../../../api/types/dataImportTypes";

interface Props {
  value: DataImportChangesFilterValue | undefined;
  onChange: (value: DataImportChangesFilterValue | undefined) => void;
  allCount: number | undefined;
  changesCount: number | undefined;
  errorsCount: number | undefined;
  excludedCount: number | undefined;
}

const DataImportChangesListFilter = ({
  value,
  onChange,
  allCount,
  changesCount,
  errorsCount,
  excludedCount,
}: Props) => {
  return (
    <ToggleButtonGroup
      value={value ?? ""}
      exclusive
      onChange={(_, newValue) => onChange(newValue ? (newValue as DataImportChangesFilterValue) : undefined)}
    >
      <ToggleButton value="" sx={{ gap: 1 }}>
        All <BadgeDetached badgeContent={allCount} max={9999} showZero color="secondary" />
      </ToggleButton>
      <ToggleButton value="Changes" sx={{ gap: 1 }}>
        New & Updated <BadgeDetached badgeContent={changesCount} max={9999} showZero color="secondary" />
      </ToggleButton>
      <ToggleButton value="Errors" sx={{ gap: 1 }}>
        Errors{" "}
        <BadgeDetached badgeContent={errorsCount} max={9999} showZero color={errorsCount ? "error" : "secondary"} />
      </ToggleButton>
      <ToggleButton value="Excluded" sx={{ gap: 1 }}>
        Excluded <BadgeDetached badgeContent={excludedCount} max={9999} showZero color="secondary" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default DataImportChangesListFilter;
