import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Stack } from "@mui/material";
import React, { useCallback, useReducer } from "react";
import { ApiResponse, PaginatedList } from "../../../../shared/api/types";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { wait } from "../../../../shared/utilities/promiseHelper";
import { ApplicationLogs, LogsQueryParams } from "../../../api/types/logTypes";
import RowsPerPageSelector from "../RowsPerPageSelector";
import LogsSeverityFilter from "./LogsSeverityFilter";
import LogsTableFooter from "./LogsTableFooter";
import PeriodFilter from "./PeriodFilter";
import { getInitialState, reducer, stateToRequestParams } from "./syncLogsState";

interface Props<T extends ApplicationLogs> {
  renderTable: (logItems: T[]) => React.ReactElement;
  fetchLogsFromApi: (params: LogsQueryParams) => Promise<ApiResponse<PaginatedList<T>>>;
  hideSeverityFilter?: boolean;
}

const LogsWrapper = <T extends ApplicationLogs>({ renderTable, fetchLogsFromApi, hideSeverityFilter }: Props<T>) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const getLogs = useCallback(
    () => fetchLogsFromApi(stateToRequestParams(state)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.page, state.rowsPerPage, state.daysPeriod, state.severityFilter]
  );

  const [logs, apiError, { setData: setLogs, fetch: fetchLogs }] = useFetch(getLogs);

  const handleRefreshClick = async () => {
    setLogs(undefined);
    await wait(100);
    if (state.page !== 0) {
      dispatch({ type: "PAGE_CHANGE", page: 0 });
    } else {
      await fetchLogs();
    }
  };

  if (apiError !== undefined) {
    logError(apiError, "Logs");
    return <DataLoadingFailed title="Loading logs failed" />;
  }

  if (logs === undefined) {
    return <InlineLoader />;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {!hideSeverityFilter && (
            <LogsSeverityFilter
              value={state.severityFilter}
              onChange={(filterValue) => dispatch({ type: "SEVERITY_CHANGE", filterValue })}
            />
          )}
          <Button startIcon={<RefreshIcon />} variant="text" color="secondary" onClick={handleRefreshClick}>
            Refresh
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <PeriodFilter
            value={state.daysPeriod}
            onChange={(daysPeriod) => dispatch({ type: "PERIOD_CHANGE", daysPeriod })}
          />
          <RowsPerPageSelector
            value={state.rowsPerPage}
            onChange={(rowsPerPage) => dispatch({ type: "ROWS_CHANGE", rowsPerPage })}
          />
        </Stack>
      </Stack>
      {renderTable(logs.items)}
      <LogsTableFooter
        page={state.page}
        totalItems={logs.total}
        totalPages={logs.totalPages}
        onPageChange={(page) => dispatch({ type: "PAGE_CHANGE", page })}
      />
    </>
  );
};

export default LogsWrapper;
