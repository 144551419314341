import { Avatar, Stack, SvgIconProps, Typography } from "@mui/material";
import { FC } from "react";

interface CardTitleProps {
  id: string;
  title: string;
  Icon?: FC<SvgIconProps>;
  logoSrc?: string;
}

const CardTitle = ({ id, title, Icon, logoSrc }: CardTitleProps) => (
  <Stack direction="row" spacing={2} alignItems="center">
    {Icon && <Icon sx={{ width: 48, height: 48 }} />}
    {logoSrc && <Avatar src={logoSrc} variant="square" alt="logo" sx={{ width: 48, height: 48 }} />}
    <Typography id={id} variant="subtitle1">
      {title}
    </Typography>
  </Stack>
);

export default CardTitle;
