import { deepPurple, green } from "@mui/material/colors";
import StatusChip from "../../../../../shared/components/StatusChip";
import { OrganizationRole } from "../../../../api/types/userManagementTypes";
import { organizationRolesDataMap } from "./definitions";

interface Props {
  role: OrganizationRole;
}

const OrganizationRoleStatusTag = ({ role }: Props) => {
  const label = organizationRolesDataMap[role].label;
  switch (role) {
    case "Org_Owner":
      return <StatusChip label={label} color={green[700]} />;
    case "Org_Member":
      return <StatusChip label={label} color={deepPurple[700]} />;
    case "Org_ExternalUser":
      return <StatusChip label={label} color={(t) => t.palette.text.secondary} />;
    default:
      return null;
  }
};

export default OrganizationRoleStatusTag;
