import { Card, CardContent, Stack } from "@mui/material";
import TypographyMultilineEllipsis from "../../../../shared/components/TypographyMultilineEllipsis";
import { ClientInfo } from "../../../api/types/clientTypes";
import CompanyTypeBadge from "../../common/CompanyTypeBadge";
import ClientLogo from "./ClientLogo";

interface Props {
  client: ClientInfo;
  onClick: () => void;
}

const ClientCard = ({ client, onClick }: Props) => (
  <Card
    variant="outlined"
    onClick={onClick}
    sx={(theme) => ({
      width: theme.spacing(38.25),
      height: theme.spacing(16),
      cursor: "pointer",
      ":hover": {
        borderColor: theme.palette.primary.main,
        ".title": {
          color: theme.palette.primary.main,
        },
      },
    })}
  >
    <CardContent>
      <Stack spacing={2}>
        {client.branding.logoMarkUrl && <ClientLogo size="medium" logoSrc={client.branding.logoMarkUrl} />}
        <Stack minHeight={48} gap={1}>
          <TypographyMultilineEllipsis
            typographyProps={{
              variant: "subtitle1",
              className: "title",
            }}
            maxLines={!client.companyType || client.companyType === "Production" ? 2 : 1}
          >
            {client.title}
          </TypographyMultilineEllipsis>
          <CompanyTypeBadge type={client.companyType} />
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default ClientCard;
