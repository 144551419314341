import { SvgIcon, SvgIconProps } from "@mui/material";

const ApiGatewayIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <path
      d="M40 0H8C3.58172 0 0 3.58172 0 8V40C0 44.4183 3.58172 48 8 48H40C44.4183 48 48 44.4183 48 40V8C48 3.58172 44.4183 0 40 0Z"
      fill="#F6F8FA"
    />
    <path
      d="M39.7411 26.6524H21.2712L24.34 22.2764H20.8734L16.8384 28.0163L20.8165 33.8699L24.2832 33.9267L21.2712 29.4939H39.7411V26.6524Z"
      fill="#00A866"
    />
    <path
      d="M26.9542 13.979L23.4876 13.9222L26.4996 18.2982H8.02966V21.1397H26.4996L23.4308 25.5156L26.8974 25.5725L30.9324 19.7758L26.9542 13.979Z"
      fill="#C7DBE5"
    />
    <path
      d="M24 41.2009C14.4524 41.2009 6.66663 33.4151 6.66663 23.8676C6.66663 14.32 14.4524 6.53424 24 6.53424C33.5475 6.53424 41.3333 14.32 41.3333 23.8676C41.3333 33.4151 33.5475 41.2009 24 41.2009ZM24 9.4326C16.0437 9.4326 9.50816 15.9113 9.50816 23.9244C9.50816 31.9375 15.9868 38.4162 24 38.4162C31.9562 38.4162 38.4918 31.9375 38.4918 23.9244C38.4918 15.9113 32.0131 9.4326 24 9.4326Z"
      fill="#00A866"
    />
  </SvgIcon>
);

export default ApiGatewayIcon;
