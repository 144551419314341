import { GridColDef } from "@mui/x-data-grid-premium";
import { convertISODate, convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { UserConsent } from "../../../../api/adminApi";
import ConsentCategoryChip from "./ConsentCategoryChip";

export const getConsentsGridColumns = (): GridColDef<UserConsent>[] => [
  {
    field: "name",
    headerName: "Consent Name",
    sortable: false,
    flex: 1.5,
    renderCell: ({ row }) => row.name,
  },
  {
    field: "categoryId",
    headerName: "Message Category",
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <ConsentCategoryChip categoryId={row.categoryId} />,
  },
  {
    field: "activeFrom",
    headerName: "Activation date",
    sortable: false,
    flex: 1,
    valueFormatter: (value) => (value ? convertISODate(value) : "-"),
  },
  {
    field: "lastModified",
    headerName: "Last modified",
    sortable: false,
    flex: 1,
    valueFormatter: (value) => (value ? convertISODateTime(value) : "-"),
  },
];
