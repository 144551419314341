import { useCallback } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { Contact, ContactDetails } from "../../../../../api/adminApi";
import { FieldIdToValueMap, ObjectClassDefinition } from "../../../../../api/types/objectTypes";
import EntitySection from "../../../../entityFields/EntitySection";
import FieldValuesManager from "../../../../entityFields/FieldValuesManager";

interface Props {
  contact: Contact;
  objectDeinition: ObjectClassDefinition;
  onContactDetailsChange: (details: Partial<ContactDetails>) => void;
  hasPermissionsToManageFields: boolean;
}

const updateFieldValues = withErrorHandling(adminApi.updateContactFieldValues);

const ContactCard = ({ contact, objectDeinition, onContactDetailsChange, hasPermissionsToManageFields }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const getFieldValues = useCallback(() => adminApi.getContactFieldValues(contact.id), [contact.id]);

  const [fieldValuesResp, fieldValuesError, { setData: setFieldValues, isFetching }] = useFetch(getFieldValues);

  const saveFieldValues = async (fieldValues: FieldIdToValueMap) => {
    const values = Object.entries(fieldValues).map(([fieldId, value]) => ({ fieldId, value }));
    const [resp, error] = await updateFieldValues(contact.id, { values });
    if (error) {
      sendNotificationError(getErrorMessage(error) || "Failed to save changes");
      logError(error, `[ContactDetailsFields] saveFieldValues`);
      return false;
    }

    sendNotification("Changes saved");
    setFieldValues(resp);

    const nameFieldValue = resp.values.find(({ fieldId }) => fieldId.toLowerCase() === "name");
    const name = nameFieldValue?.value?.toString() || contact.name;
    onContactDetailsChange({
      contact: { ...contact, name },
      updateAt: new Date().toISOString(),
    });

    return true;
  };

  if (fieldValuesError) {
    logError(fieldValuesError, "[ContactDetailsFields]");
    return <DataLoadingFailed bgColor="none" title="Loading contact fields" />;
  }

  const fieldValues = fieldValuesResp
    ? Object.fromEntries(fieldValuesResp.values.map(({ fieldId, value }) => [fieldId, value]))
    : {};

  return (
    <EntitySection isLoading={isFetching}>
      {fieldValuesResp && (
        <FieldValuesManager
          objectDefinition={objectDeinition}
          fieldValues={fieldValues}
          hasPermissionsToManageFields={hasPermissionsToManageFields}
          saveFieldValues={saveFieldValues}
        />
      )}
    </EntitySection>
  );
};

export default ContactCard;
