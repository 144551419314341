import { Typography } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { DataCollectionSubmissionInfo } from "../../../../api/types/dataCollectionTypes";
import { pageRoutes } from "../../../../routes";
import { columnDefinitions } from "./dataSubmissionsGridDataProvider";

interface Props {
  submissions: DataCollectionSubmissionInfo[];
  isLoading?: boolean;
}

const DataSubmissionsGrid = ({ submissions, isLoading }: Props) => {
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row }) => {
    navigate(`${pageRoutes.dataSubmissions}/${row.id}`);
  };

  return (
    <DataGrid<DataCollectionSubmissionInfo>
      rows={submissions}
      columns={columnDefinitions}
      loading={isLoading}
      columnHeaderHeight={36}
      rowHeight={40}
      selectableRows
      onCellClick={handleCellClick}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableColumnSorting
      disableRowSelectionOnClick
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No data submissions yet</Typography>
          </CenteredWrapper>
        ),
      }}
    />
  );
};

export default DataSubmissionsGrid;
