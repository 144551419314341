import { SvgIcon, SvgIconProps } from "@mui/material";

const CompanyIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 54 51" fill="none" {...props}>
    <path d="M53.2001 45.5H0.600098V50.5H53.2001V45.5Z" fill="#C7DBE5" />
    <path
      d="M33.3 50.5002H4.5V7.3002L33.4 0.700195V50.5002H33.3ZM9.5 45.5002H28.4V7.0002L9.5 11.3002V45.5002Z"
      fill="#C7DBE5"
    />
    <path d="M41.6001 22.1001H35.6001V27.1001H41.6001V22.1001Z" fill="#C7DBE5" />
    <path d="M41.6001 32.1001H35.6001V37.1001H41.6001V32.1001Z" fill="#C7DBE5" />
    <path d="M23.5 22.1001H13.8V27.1001H23.5V22.1001Z" fill="#C7DBE5" />
    <path d="M23.5 32.1001H13.8V37.1001H23.5V32.1001Z" fill="#C7DBE5" />
    <path d="M49.3 49.3002H44.3V15.7002H30.5V10.7002H49.3V49.3002Z" fill="#C7DBE5" />
  </SvgIcon>
);

export default CompanyIcon;
