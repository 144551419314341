import CopyAllIcon from "@mui/icons-material/CopyAll";
import ActionMenuItem from "../../../../../../shared/components/ActionMenuItem";
import { CopyToEligibility } from "../components/CopyToEligibility";

interface Props {
  onClick: () => void;
}
export function CopyToMenuItem({ onClick }: Props) {
  return (
    <CopyToEligibility>
      <ActionMenuItem key={"copyto"} icon={<CopyAllIcon />} title="Copy to..." onClick={onClick} />
    </CopyToEligibility>
  );
}
