import { Grid } from "@mui/material";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import { ReportValidationType } from "./hooks/useReportValidation";
import { SelectedCompany, SelectedCompanyReport } from "./hooks/SelectedCompany";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import { CompanySingleReportListItemContainer } from "./CompanySingleReportListItemContainer";
import { CompanyMultipleReportsListItemContainer } from "./CompanyMultipleReportsListItemContainer";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";

interface Props {
  activeCompany: SelectedCompany | undefined;
  activeCompanyReport: SelectedCompanyReport | undefined;
  availableCompanies: ListItemData[];
  validation: ReportValidationType;
  onSelectedCompanyReport: (companyReport: SelectedCompanyReport | undefined) => void;
}

export function CompaniesList({
  activeCompany,
  activeCompanyReport,
  availableCompanies,
  validation,
  onSelectedCompanyReport,
}: Props) {
  return (
    <ScrollableFlexContainer>
      <Grid container sx={{ flexDirection: "column", gap: 1 }}>
        {validation.selectedCompanies.map((company) => {
          const report = defined(company.reports.at(0));
          if (company.reports.length === 1) {
            return (
              <CompanySingleReportListItemContainer
                key={company.code}
                company={company}
                report={report}
                activeCompany={activeCompany}
                availableCompanies={availableCompanies}
                validation={validation}
                onSelectedCompanyReport={onSelectedCompanyReport}
              />
            );
          } else {
            return (
              <CompanyMultipleReportsListItemContainer
                key={company.code}
                company={company}
                activeCompany={activeCompany}
                activeCompanyReport={activeCompanyReport}
                onSelectedCompanyReport={onSelectedCompanyReport}
                availableCompanies={availableCompanies}
                validation={validation}
              ></CompanyMultipleReportsListItemContainer>
            );
          }
        })}
      </Grid>
    </ScrollableFlexContainer>
  );
}
