import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Link, Tooltip, Typography } from "@mui/material";

interface Props {
  title: string;
  href: string;
  openInNewTab?: boolean;
  showIcon?: boolean;
}

const NavigateByLink = ({ title, href, openInNewTab, showIcon }: Props) => {
  return (
    <Tooltip arrow title={openInNewTab ? "Open in new browser tab" : "Click to navigate"} placement={"bottom"}>
      <Button
        color="primary"
        component={Link}
        href={href}
        target={openInNewTab ? "_blank" : undefined}
        rel="noopener noreferrer"
        sx={{ textTransform: "none" }}
        endIcon={showIcon ? <OpenInNewIcon color="secondary" /> : null}
      >
        <Typography variant={"caption"} fontWeight={600}>
          {title}
        </Typography>
      </Button>
    </Tooltip>
  );
};

export default NavigateByLink;
