import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-premium";
import { useLayoutEffect, useRef } from "react";

interface Props extends GridRenderEditCellParams {
  valueOptions: { value: string; label: string }[];
}

const GridEditMultiSelectCell = ({ valueOptions, id, field, value, hasFocus }: Props) => {
  const apiRef = useGridApiContext();
  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (hasFocus) {
      ref.current?.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (e: SelectChangeEvent<string[]>) => {
    const newValue = e.target.value;
    if (Array.isArray(newValue)) {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }
  };

  const selectedValues = Array.isArray(value) ? (value as string[]) : [];
  const invalidValues = selectedValues.filter((val) => !valueOptions.some((o) => o.value === val));

  return (
    <Select
      multiple
      displayEmpty
      value={selectedValues}
      renderValue={(selected) => selected.join(", ")}
      onChange={handleValueChange}
      inputRef={ref}
      sx={{ width: "100%" }}
    >
      {invalidValues.map((val) => (
        <MenuItem key={val} value={val} dense>
          <Checkbox checked={selectedValues.includes(val)} />
          <ListItemText sx={(t) => ({ maxWidth: "12rem", border: `1px solid ${t.palette.error.main}` })}>
            <Typography noWrap>{val}</Typography>
          </ListItemText>
        </MenuItem>
      ))}

      {valueOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value} dense>
          <Checkbox checked={selectedValues.includes(value)} />
          <ListItemText sx={{ maxWidth: "12rem" }}>
            <Typography noWrap>{label}</Typography>
          </ListItemText>
        </MenuItem>
      ))}
    </Select>
  );
};

export default GridEditMultiSelectCell;
