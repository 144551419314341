import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import {
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useMemo, useState } from "react";
import { ClientType } from "../../../../shared/api/clientTypes";
import SearchField from "../../../../shared/components/inputs/SearchField";
import { isEntriliaClientCode } from "../../../../shared/utilities/clientHelper";
import { ClientInfo } from "../../../api/types/clientTypes";
import { useClientContext } from "../../../context/ClientContext";
import { useUserContext } from "../../../context/UserContext";
import storage from "../../../storage/storage";
import { Searchable, applySearch } from "../../../utilities/searchHelper";
import { cornersBeforePositions } from "../../common/corners";
import ClientIcon from "./ClientIcon";
import ClientSelectorValue from "./ClientSelectorValue";
import {
  GroupSelectionState,
  getInitialGroupSelectionState,
  getSortedOrganizationCompanyGroups,
  updateGroupSelectionState,
} from "./client-selector-helper";

const SELECTED_ITEM_COLOR = "#EBF8F3";
const HOVERED_SELECTED_ITEM_COLOR = "#E0F4EC";
const HOVERED_ITEM_COLOR = "#F6F7F7";

const menuItemStyle: SxProps<Theme> = (theme) => ({
  py: theme.spacing(1),
  pl: 4,
});
const subheaderMenuItemStyle: SxProps<Theme> = (theme) => ({
  px: 2,
  py: 1,
  color: theme.palette.text.primary,
});

const listItemTextProps: TypographyProps<"span"> = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

interface Props {
  selectBgColor: string;
  selectHoveredBgColor: string;
}

const ClientSelector = ({ selectBgColor, selectHoveredBgColor }: Props) => {
  const { clients } = useUserContext();
  const { clientCode, onNewClientSelected } = useClientContext();
  const [keyboardHighlightState, setKeyboardHighlightState] = useState<GroupSelectionState>(
    getInitialGroupSelectionState()
  );

  const organizations = useMemo(() => clients.filter((c) => c.type === "FundAdmin"), [clients]);

  const companies = useMemo(
    () =>
      clients
        .filter((c) => c.type === "FundManager")
        .map((client) => ({ ...client, searchTerms: [client.title.toLowerCase()] })),
    [clients]
  );

  const storedRecentClients = useMemo(
    () =>
      storage
        .getRecentClients()
        .map((clientCode) => clients.find((c) => c.clientCode === clientCode))
        .filter(Boolean) as ClientInfo[],
    [clients]
  );

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredCompanies, setFilteredCompanies] = useState<Searchable<ClientInfo>[]>(companies);

  const handleSearch = (term: string) => {
    setFilteredCompanies(applySearch(term, companies, 2));
    setSearchTerm(term);
    setKeyboardHighlightState(getInitialGroupSelectionState());
  };

  const handleSelectClient = (clientCode: string, clientType: ClientType) => () => {
    setOpen(false);

    if (clientType === "FundManager") {
      storage.updateRecentClients(clientCode);
    }

    onNewClientSelected(clientCode);
  };

  const organizationCompanyGroups = getSortedOrganizationCompanyGroups(organizations, filteredCompanies);

  const selectedClient = clients.find((c) => c.clientCode === clientCode);

  if (!selectedClient) {
    return null;
  }

  const selectedClientOrganizationCode =
    selectedClient.managedByClients.find((code) => !isEntriliaClientCode(code)) ?? selectedClient.managedByClients[0];

  const selectedClientOrganization =
    selectedClientOrganizationCode === undefined
      ? undefined
      : clients.find((c) => c.clientCode === selectedClientOrganizationCode);

  const showRecentClients = companies.length >= 10 && !searchTerm;
  const recentClients = showRecentClients ? storedRecentClients : [];

  const groupNodeLengths = organizationCompanyGroups.map((group) => group.companies.length);

  const highlightedGroup = organizationCompanyGroups[keyboardHighlightState[0]];
  const highlightedCompany = highlightedGroup?.companies[keyboardHighlightState[1]];
  const highlightedOrganization = highlightedCompany !== undefined ? undefined : highlightedGroup?.organization;

  const handleHighlightPreviousClient = () => {
    setKeyboardHighlightState((current) => updateGroupSelectionState(current, groupNodeLengths, -1));
  };

  const handleHighlightNextClient = () => {
    setKeyboardHighlightState((current) => updateGroupSelectionState(current, groupNodeLengths, 1));
  };

  const handleSelectHighlightedClient = () => {
    const client = highlightedCompany ?? highlightedOrganization;
    if (client !== undefined) {
      handleSelectClient(client.clientCode, client.type)();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (showRecentClients || !searchTerm) {
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        handleHighlightNextClient();
        return;
      case "ArrowUp":
        e.preventDefault();
        handleHighlightPreviousClient();
        return;
      case "Enter":
        e.preventDefault();
        handleSelectHighlightedClient();
        return;
    }
  };

  const handleOpen = () => {
    setKeyboardHighlightState(getInitialGroupSelectionState());
    setFilteredCompanies(companies);
    setOpen(true);
  };

  return (
    <Select
      open={open}
      variant="outlined"
      readOnly={clients.length <= 1}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      value={[clientCode]}
      multiple
      IconComponent={ArrowRightRoundedIcon}
      renderValue={() => (
        <ClientSelectorValue client={selectedClient} clientOrganization={selectedClientOrganization} />
      )}
      sx={(theme) => ({
        width: theme.spacing(32),
        px: 0,
        ".MuiSelect-select": { pl: 1.75, py: 1 },
        ".MuiOutlinedInput-input": {
          backgroundColor: selectBgColor,
          ":hover": {
            bgcolor: selectHoveredBgColor,
          },
        },
        ".MuiOutlinedInput-notchedOutline": { border: "none" },
        "& .MuiSelect-icon": { mr: 0.7 },
      })}
      MenuProps={{
        anchorOrigin: {
          vertical: "center",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "center",
          horizontal: "left",
        },
        PaperProps: {
          elevation: 0,
          sx: (theme) => ({
            minWidth: theme.spacing(32),
            width: "auto",
            overflow: "visible",
            "&:before": cornersBeforePositions.left("top", 60),
            marginLeft: theme.spacing(2),
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            maxHeight: "100%",
          }),
        },
      }}
    >
      <Box mx={2} mt={1} mb={2}>
        <SearchField onSearch={handleSearch} fullWidth onKeyDown={handleKeyDown} autoFocus />
      </Box>
      <Divider />
      <List
        onMouseEnter={() => setKeyboardHighlightState(getInitialGroupSelectionState())}
        sx={(t) => ({
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "calc(86vh)",
          width: t.spacing(45),
          "& ul": { padding: 0 },
          p: 0,
          my: 1,
        })}
      >
        <Box component={"li"} key="recent">
          <Box component={"ul"}>
            {recentClients.length > 0 && (
              <ListSubheader sx={subheaderMenuItemStyle}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: "subtitle2",
                  }}
                >
                  Recent
                </ListItemText>
              </ListSubheader>
            )}
            {recentClients.map((client) => (
              <MenuItem
                key={client.clientCode + "_recent"}
                onClick={handleSelectClient(client.clientCode, client.type)}
                sx={menuItemStyle}
              >
                <ListItemIcon>
                  <ClientIcon clientType={client.type} logoUrl={client.branding.logoMarkUrl} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    sx: listItemTextProps,
                  }}
                >
                  {client.title}
                </ListItemText>
              </MenuItem>
            ))}
          </Box>
        </Box>
        {organizationCompanyGroups.length === 0 ? (
          <Typography color="text.secondary" sx={{ m: 2 }}>
            No companies found.
          </Typography>
        ) : (
          organizationCompanyGroups.map((clientGroup, i) => {
            return (
              <Box component={"li"} key={i}>
                <Box component={"ul"}>
                  {clientGroup.organization !== undefined && (
                    <ListSubheader
                      key={clientGroup.organization.clientCode}
                      onClick={handleSelectClient(clientGroup.organization.clientCode, clientGroup.organization.type)}
                      sx={(t) => ({
                        ...subheaderMenuItemStyle(t),
                        cursor: "pointer",
                        bgcolor:
                          clientGroup.organization?.clientCode === clientCode
                            ? SELECTED_ITEM_COLOR
                            : clientGroup.organization?.clientCode === highlightedOrganization?.clientCode
                              ? HOVERED_ITEM_COLOR
                              : undefined,
                        ":hover": {
                          bgcolor:
                            clientGroup.organization?.clientCode === clientCode
                              ? HOVERED_SELECTED_ITEM_COLOR
                              : HOVERED_ITEM_COLOR,
                        },
                      })}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "subtitle2",
                        }}
                      >
                        {clientGroup.organization.title}
                      </ListItemText>
                    </ListSubheader>
                  )}
                  {clientGroup.companies.map((company) => (
                    <MenuItem
                      key={company.clientCode}
                      onClick={handleSelectClient(company.clientCode, company.type)}
                      sx={(t) => ({
                        ...menuItemStyle(t),
                        bgcolor:
                          company.clientCode === clientCode
                            ? SELECTED_ITEM_COLOR
                            : company.clientCode === highlightedCompany?.clientCode &&
                                clientGroup.organization?.clientCode === highlightedGroup?.organization?.clientCode
                              ? HOVERED_ITEM_COLOR
                              : undefined,
                        ":hover": {
                          bgcolor: company.clientCode === clientCode ? HOVERED_SELECTED_ITEM_COLOR : undefined,
                        },
                      })}
                    >
                      <ListItemIcon>
                        <ClientIcon clientType={company.type} logoUrl={company.branding.logoMarkUrl} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          sx: listItemTextProps,
                        }}
                      >
                        {company.title}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Box>
              </Box>
            );
          })
        )}
      </List>
    </Select>
  );
};

export default ClientSelector;
