import { Box, Stack, Typography } from "@mui/material";
import { Field } from "../../api/types/objectTypes";
import FieldIcon from "../common/domain-icons/FieldIcon";
import FieldValueComponent from "./FieldValueComponent";
import { useFieldValuesContext } from "./FieldValuesContext";

interface Props {
  field: Field;
  fieldValue: unknown;
  fieldInputId?: string;
}

const FieldComponent = ({ field, fieldValue, fieldInputId }: Props) => {
  const { onFieldValueChange } = useFieldValuesContext();

  return (
    <Stack width="100%" direction="row" alignItems="flex-start" spacing={1.5}>
      <Box pt={1.25}>
        <FieldIcon fieldType={field.type} color="action" />
      </Box>
      <Box width={160} mr={1.5} pt={1.25}>
        <Typography color="text.secondary">
          {field.name}
          {field.attributes.includes("Required") && (
            <Typography component="span" color="error">
              {" *"}
            </Typography>
          )}
        </Typography>
      </Box>
      <Box px={1} width="100%">
        <FieldValueComponent
          field={field}
          fieldValue={fieldValue}
          onFieldValueChange={onFieldValueChange}
          fieldInputId={fieldInputId}
        />
      </Box>
    </Stack>
  );
};

export default FieldComponent;
