import { Stack } from "@mui/material";
import FileIcon from "../../../../../shared/components/FileIcon";
import { getFileExtension } from "../../../../../shared/utilities/fileHelper";
import TextHoverAction from "../../TextHoverAction";
import { useFilesContext } from "../FilesContext";
import { FilesRowModel } from "../filesState";

interface Props {
  row: FilesRowModel;
  fileName: string;
}

const FileNameCell = ({ row, fileName }: Props) => {
  const { onPreviewFile } = useFilesContext();

  if (!fileName) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <FileIcon fileExtension={getFileExtension(fileName)} />
      <TextHoverAction onClick={() => onPreviewFile(row)} actionDisabled={row.uploadState !== undefined}>
        {fileName}
      </TextHoverAction>
    </Stack>
  );
};

export default FileNameCell;
