import { Stack, Typography } from "@mui/material";
import { TextEntityFieldConfiguration } from "../../../../../api/types/objectTypes";

interface Props {
  fieldConfiguration: TextEntityFieldConfiguration;
}

const TextFieldConfigViewer = ({ fieldConfiguration }: Props) => {
  if (fieldConfiguration.maxLength === undefined) {
    return null;
  }

  return (
    <Stack spacing={1} pt={2}>
      <Typography variant="subtitle2">Constraints</Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography color="text.secondary">Maximum length</Typography>
        <Typography>{`${fieldConfiguration.maxLength} characters`}</Typography>
      </Stack>
    </Stack>
  );
};

export default TextFieldConfigViewer;
