import { Autocomplete, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import UserAvatar from "../../../../shared/components/UserAvatar";
import { ApiGatewayEligibleUser, ApiGatewaySubscriptionType } from "../../../api/adminApi";

interface Props {
  users: ApiGatewayEligibleUser[] | undefined;
  form: SubscriptionFormState;
  onChange: (value: SubscriptionFormState) => void;
  disableUserSelection?: boolean;
  disableTypeSelection?: boolean;
}

export interface SubscriptionFormState {
  user: ApiGatewayEligibleUser | null;
  description: string;
  type: ApiGatewaySubscriptionType;
}

const ApiGatewaySubscriptionForm = ({ users, form, onChange, disableUserSelection, disableTypeSelection }: Props) => {
  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="subtitle2">Type</Typography>
        <Select
          fullWidth
          variant="outlined"
          value={form.type}
          onChange={(e) => onChange({ ...form, type: e.target.value as ApiGatewaySubscriptionType })}
          disabled={disableTypeSelection}
        >
          <MenuItem key="EntriliaApi" value="EntriliaApi">
            Entrilia API
          </MenuItem>
          <MenuItem key="Qashqade" value="Qashqade">
            Qashqade
          </MenuItem>
          <MenuItem key="AllocatedGlAndBankAccountLedgerApi" value="AllocatedGlAndBankAccountLedgerApi">
            Allocated General Ledger and Bank Account Ledger API
          </MenuItem>
          <MenuItem key="Cascata" value="Cascata">
            Cascata
          </MenuItem>
        </Select>
      </Stack>

      <Stack spacing={1}>
        {!disableUserSelection && (
          <Typography color="text.secondary" mb={1}>
            Please add a user who will have access to the API Gateway
          </Typography>
        )}
        <Typography variant="subtitle2">Users</Typography>
        <Autocomplete
          id="api-gateway-users-autocomplete"
          disabled={disableUserSelection || users === undefined || users.length === 0}
          loading={users === undefined}
          fullWidth
          value={form.user}
          options={users ?? []}
          getOptionLabel={(value) => `${value.username} <${value.email}>`}
          onChange={(_, value) => onChange({ ...form, user: value })}
          renderInput={(inputProps) => (
            <TextField {...inputProps} placeholder="Enter a username/email or select from the list..." />
          )}
          renderOption={(props, option) => (
            <Stack {...props} key={option.userId} component="li" direction="row" alignItems="center" spacing={1.5}>
              <UserAvatar size="large" userName={option.username} />
              <Stack>
                <Typography>{option.username}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.email}
                </Typography>
              </Stack>
            </Stack>
          )}
        />
      </Stack>

      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2">Description</Typography>
          <Typography color="text.secondary">(Optional)</Typography>
        </Stack>
        <TextField
          placeholder="Add text..."
          value={form.description}
          onChange={(e) => onChange({ ...form, description: e.target.value })}
        />
      </Stack>
    </Stack>
  );
};

export default ApiGatewaySubscriptionForm;
