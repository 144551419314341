import { Checkbox, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { MemberRole } from "../../../../../api/types/userManagementTypes";
import { entriliaProductCaptionMap } from "../../../../../utilities/enumCaptions";
import EntriliaProductIcon from "../../../../common/domain-icons/EntriliaProductIcon";
import { CompanyProductWithRoles, roleNamesMap } from "../definitions";
import { useInviteMembersContext } from "./InviteMembersContext";
import PermissionSelect from "./PermissionSelect";

interface Props {
  clientCode: string;
  selectedRoles: MemberRole[];
  onSelectedChanged: (selectedItems: MemberRole[]) => void;
}

const ProductPermissions = ({ clientCode, selectedRoles, onSelectedChanged }: Props) => {
  const { getAvailableProductsWithRoles } = useInviteMembersContext();

  const productsWithRoles = getAvailableProductsWithRoles(clientCode);

  const setSelectedRole = (item: CompanyProductWithRoles, selectedRole: MemberRole) => {
    const newSelectedRoles = selectedRoles.filter((role) => !item.roles.includes(role));
    newSelectedRoles.push(selectedRole);
    onSelectedChanged(newSelectedRoles);
  };

  const handleSelectItem = (item: CompanyProductWithRoles, checked: boolean) => {
    if (checked) {
      const newSelectedRoles = selectedRoles.filter((role) => !item.roles.includes(role));
      onSelectedChanged(newSelectedRoles);
    } else if (item.roles[0]) {
      const rolesToAdd = selectedRoles.filter((role) => !item.roles.includes(role));
      rolesToAdd.push(item.roles[0]);
      onSelectedChanged(rolesToAdd);
    }
  };

  return (
    <Stack spacing={2}>
      <List
        disablePadding
        subheader={
          <Typography sx={{ mb: 1.5 }} variant="subtitle2">
            Product Permissions
          </Typography>
        }
      >
        {productsWithRoles.map((item) => {
          const checked = selectedRoles.some((role) => item.roles.includes(role));
          return (
            <ListItem disablePadding key={item.key}>
              <Checkbox checked={checked} onClick={() => handleSelectItem(item, checked)} />
              <EntriliaProductIcon product={item.key} color="primary" fontSize="small" sx={{ mr: 1 }} />
              <ListItemText primary={<Typography>{entriliaProductCaptionMap[item.key]}</Typography>} />
              {checked && (
                <PermissionSelect
                  selectedValue={item.roles.find((role) => selectedRoles.includes(role))}
                  onChange={(selectedRole) => setSelectedRole(item, selectedRole)}
                  options={item.roles.map((role) => {
                    return {
                      value: role,
                      label: roleNamesMap[role],
                    };
                  })}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
};

export default ProductPermissions;
