import { Avatar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import EntriliaLogo from "../../../../shared/images/greylogo.png";
import { Field, ObjectFieldValues } from "../../../api/types/objectTypes";
import { TableColumnDefinition } from "../../common/columns/columnTypes";
import { getGridColumnForField } from "../fieldGridDefinitions";

interface Options {
  showLogos?: boolean;
}

const getStaticColumns = ({ showLogos }: Options): GridColDef<ObjectFieldValues>[] => {
  const staticColumns: GridColDef<ObjectFieldValues>[] = [];

  if (showLogos) {
    staticColumns.push({
      field: "logoUrl",
      headerName: "",
      sortable: false,
      resizable: false,
      width: 72,
      renderCell: ({ row }) => (
        <Avatar
          src={row.logoUrl || EntriliaLogo}
          variant="rounded"
          alt="logo"
          slotProps={{ img: { sx: { objectFit: "contain" } } }}
          sx={{ width: 60, height: 24 }}
        />
      ),
    });
  }

  return staticColumns;
};

const getColumnsForAllEntityFields = (
  fields: Field[],
  visibleColumns: TableColumnDefinition[]
): GridColDef<ObjectFieldValues>[] => {
  return visibleColumns.reduce<GridColDef<ObjectFieldValues>[]>((result, column, i) => {
    const field = fields.find((field) => field.id === column.id);
    if (field) {
      const colDef: GridColDef<ObjectFieldValues> = getGridColumnForField(field);
      if (i === 0) {
        colDef.cellClassName = "highlighted-action";
      }
      result.push(colDef);
    }
    return result;
  }, []);
};

export const getEntitiesGridColumns = (
  entityFields: Field[],
  visibleColumns: TableColumnDefinition[],
  options: Options = {}
): GridColDef<ObjectFieldValues>[] => [
  ...getStaticColumns(options),
  ...getColumnsForAllEntityFields(entityFields, visibleColumns),
];
