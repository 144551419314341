import { logError } from "../../../../shared/logging";
import { CurrencyEntityFieldConfiguration } from "../../../api/types/objectTypes";
import { EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getCurrencySymbol, getInvalidFieldValueError } from "../helpers";
import EntityNumberComponent from "./EntityNumberComponent";

const EntityMoneyField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "number" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityMoneyField]");
    return null;
  }

  const precision = (fieldConfiguration as CurrencyEntityFieldConfiguration)?.precision ?? 0;
  const currencyCode = (fieldConfiguration as CurrencyEntityFieldConfiguration)?.currencyCode;
  const currencySymbol = currencyCode ? getCurrencySymbol(currencyCode) : "";

  return (
    <EntityNumberComponent
      precision={precision}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      fieldInputId={fieldInputId}
      prefix={currencySymbol}
      value={value}
      onChange={onChange}
    />
  );
};

export default EntityMoneyField;
