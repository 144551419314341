import { Stack, Typography } from "@mui/material";
import { useLocalization } from "../../../../hooks/useLocalization";

const PassthroughConfigurationHeader = () => {
  const { passthrough: locale } = useLocalization();

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">{locale.configuration_header_title}</Typography>
      <Typography color="secondary">{locale.configuration_header_subtitle}</Typography>
    </Stack>
  );
};

export default PassthroughConfigurationHeader;
