import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import crmConnectorsApi from "../../../api/crmConnectorsApi";
import { useCrmConnectorContext } from "./CrmConectorContext";

interface Props {
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const DeleteConnectionDialog = ({ open, onSuccess, onCancel }: Props) => {
  const [apiError, setApiError] = useState<string>();

  const { crmName, crmApiName, logoSrc } = useCrmConnectorContext();

  const handleCancel = () => {
    setApiError(undefined);
    onCancel();
  };

  const handleDeleteClick = async () => {
    setApiError(undefined);

    const [, error] = await withErrorHandling(crmConnectorsApi[crmApiName].deleteConnection)();
    if (error) {
      setApiError(getErrorMessage(error));
      return;
    }

    onSuccess();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Connection</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <DialogContent sx={{ px: 8 }}>
        <Stack my={2} alignItems="center">
          <Avatar src={logoSrc} variant="square" alt="logo" sx={{ width: 80, height: 80 }} />
          <Typography variant="subtitle1">{crmName}</Typography>
        </Stack>
        <Typography textAlign="center">Are you sure you want to delete the connection?</Typography>
        {apiError && (
          <Typography textAlign="center" sx={(theme) => ({ color: theme.palette.error.main, mt: theme.spacing(1) })}>
            {apiError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="primary" autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleDeleteClick}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConnectionDialog;
