import { ApplicationLogs } from "./logTypes";

export enum PassthroughEnvironment {
  Sandbox = "Sandbox",
  Us = "Us",
  Eu = "Eu",
}

export interface PassthroughConfiguration {
  environment: PassthroughEnvironment;
  apiKey: string;
}

export interface PassthroughCheckConnectionResponse {
  isSuccess: boolean;
  error: string;
}

export interface PassthroughConnectionState {
  enabled: boolean;
  lastChangedBy?: string;
  lastChangedOn?: string;
}

export interface PassthroughApplicationLogs extends ApplicationLogs {
  description: string;
}

export interface PassthroughFundsResponse {
  items: PassthroughFund[];
}

export interface PassthroughFund {
  id: string;
  name: string;
}

export interface PassthroughFundClosingResponse {
  items: PassthroughFundClosing[];
}

export interface PassthroughFundClosing {
  id: string;
  name: string;
}

export interface CreatePassthroughImportRequest {
  passthroughFundId: string;
  passthroughFundClosingId: string;
  entityId: string;
  commitmentDate: string;
}

export interface InternalEntitiesResponse {
  items: InternalEntity[];
}

export interface InternalEntity {
  id: string;
  name: string;
  communicationGroupName: string;
}
