import { SxProps, Theme } from "@mui/material";
import Quill from "quill";
import { CSSProperties } from "react";

const SnowTheme = Quill.import("themes/snow");

class SnowThemeFix extends SnowTheme {
  override init(): void {
    super.init();

    // Fix for https://github.com/slab/quill/issues/1107
    // Using `any` as types are missing the `tooltip` property
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataset = (this as any).tooltip?.textbox?.dataset;
    if (dataset && dataset.link) {
      dataset.link = "";
    }
  }
}

Quill.register("themes/snow", SnowThemeFix, true);

export interface HtmlEditorStyles {
  editor: CSSProperties;
}

export const getHtmlEditorStyles =
  (customStyles: HtmlEditorStyles | undefined): SxProps<Theme> =>
  (theme: Theme) => {
    const { editor } = customStyles ?? {};

    return {
      "& .ql-toolbar": {
        padding: 0,
        border: `1px solid ${theme.palette.divider} !important`,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        borderBottom: "none !important",
        "& .ql-formats": {
          padding: "6px",
          marginRight: 0,
          borderRight: `1px solid ${theme.palette.divider}`,
          "button, & .ql-picker-label, & .ql-picker-item": {
            color: theme.palette.text.primary,
            "& .ql-stroke": {
              stroke: theme.palette.action.active,
            },
            "&.ql-active": {
              color: theme.palette.primary.main,
              "& .ql-stroke": {
                stroke: theme.palette.primary.main,
              },
            },
            "&:hover": {
              color: theme.palette.primary.main,
              "& .ql-stroke": {
                stroke: theme.palette.primary.main,
              },
            },
          },
        },
      },
      "& .ql-container": {
        border: `1px solid ${theme.palette.divider} !important`,
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        "& a": {
          color: theme.palette.primary.main,
        },

        "& .ql-editor": {
          ...editor,
        },
      },
      "& .ql-preview": {
        input: {
          borderColor: theme.palette.divider,
        },
      },
      "& .ql-tooltip": {
        zIndex: theme.zIndex.tooltip,
        left: "8px !important",
      },
    };
  };
