import { Box, Stack } from "@mui/material";
import { Page } from "react-pdf";
import { PdfViewerState } from "./pdfViewerState";

interface Props {
  viewerState: PdfViewerState;
  pageRefs: React.MutableRefObject<HTMLElement[] | null>;
  pageHeight: number | undefined;
  pageWidth: number | undefined;
}

const PdfPagesContainer = ({ viewerState, pageRefs, pageHeight, pageWidth }: Props) => {
  return (
    <Stack alignItems="center">
      {Array.from(new Array(viewerState.numPages), (_, pageIndex) => (
        <Box
          key={`pdf-page-${pageIndex}`}
          ref={(el: HTMLElement) => {
            if (pageRefs.current) {
              pageRefs.current[pageIndex] = el;
            } else {
              pageRefs.current = [el];
            }
          }}
          p={2}
        >
          <Page pageNumber={pageIndex + 1} scale={viewerState.scale} height={pageHeight} width={pageWidth} />
        </Box>
      ))}
    </Stack>
  );
};

export default PdfPagesContainer;
