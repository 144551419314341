import { useCallback } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useNotesContext } from "../../../notes/NotesContext";
import NotesList from "../../../notes/NotesList";

const ApprovalNotesSection = () => {
  const { entityType, entityId } = useNotesContext();

  const fetchNotes = useCallback(async () => adminApi.getNotes(entityType, entityId), [entityId, entityType]);

  const [notes, fetchError] = useFetch(fetchNotes);

  if (fetchError) {
    logError(fetchError, "[ApprovalNotesSection]");
    return <DataLoadingFailed title="Failed to load notes" />;
  }

  if (notes === undefined) {
    return <InlineLoader />;
  }

  return <NotesList notes={notes} />;
};

export default ApprovalNotesSection;
