import { Box, Typography } from "@mui/material";
import {
  InfoSectionBlockContent,
  LayoutBlock,
  LayoutBlockType,
  MonthlyFinancialsBlockContent,
} from "../../../../../api/types/inputFormTypes";
import ContainerFormBlock from "./ContainerFormBlock";
import { useDataSubmissionFormContext } from "./DataSubmissionFormContext";
import InfoSectionFormBlock from "./InfoSectionFormBlock";
import MonthlyFinancialsFormBlock from "./portfolio-monitoring/MonthlyFinancialsFormBlock";

interface Props {
  layout: LayoutBlock;
}

const UnsupportedBlock = ({ type }: { type: LayoutBlockType }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="4rem"
      width="100%"
      sx={(t) => ({ border: `1px dashed ${t.palette.divider}` })}
    >
      <Typography color="text.secondary">Unsupported block type: {type}</Typography>
    </Box>
  );
};

const DataSubmissionFormBlock = ({ layout }: Props) => {
  const { getBlockContent } = useDataSubmissionFormContext();

  const content = getBlockContent(layout.id);

  if (layout.type === "Container") {
    return <ContainerFormBlock attributes={layout.attributes} />;
  }

  if (layout.type === "InfoSection") {
    return content ? (
      <InfoSectionFormBlock attributes={layout.attributes} content={content as InfoSectionBlockContent} />
    ) : null;
  }

  if (layout.type === "MonthlyFinancials") {
    return (
      <MonthlyFinancialsFormBlock attributes={layout.attributes} content={content as MonthlyFinancialsBlockContent} />
    );
  }

  return <UnsupportedBlock type={layout.type} />;
};

export default DataSubmissionFormBlock;
