import { Stack, Typography } from "@mui/material";
import { autoFormatCamelCase } from "../../../../shared/utilities/stringHelper";
import { MetricDataType } from "../../../api/types/portfolioMonitoringTypes";
import MetricDataTypeIcon from "./MetricDataTypeIcon";

interface Props {
  dataType: MetricDataType;
}

const MetricDataTypeItem = ({ dataType }: Props) => {
  return (
    <Stack direction="row" spacing={1.5}>
      <MetricDataTypeIcon dataType={dataType} color="action" />
      <Typography component="span">{autoFormatCamelCase(dataType)}</Typography>
    </Stack>
  );
};

export default MetricDataTypeItem;
