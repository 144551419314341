import { useEffect } from "react";

const useEventListener = (
  element: Window | Document | Element | null,
  eventName: string,
  handler: EventListenerOrEventListenerObject
) => {
  useEffect(() => {
    if (!element) {
      return;
    }

    element.addEventListener(eventName, handler);

    return () => {
      element.removeEventListener(eventName, handler);
    };
  }, [eventName, element, handler]);
};

export default useEventListener;
