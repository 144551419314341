import { Typography } from "@mui/material";

interface Props {
  records: number;
  total: number;
  hide?: boolean;
}

const RecordCounter = ({ records, total, hide }: Props) => {
  const totalCounterText = total === 1 ? `${total} record` : `${total} records`;
  const text = total === records ? totalCounterText : `${records} of ${totalCounterText}`;
  return (
    <Typography variant="subtitle2" visibility={hide ? "hidden" : "visible"}>
      {text}
    </Typography>
  );
};

export default RecordCounter;
