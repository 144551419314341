const MemberInvitedDialogIcon = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="80" height="80" rx="8" fill="white" />
      <path
        d="M40.0988 44.2499C34.3988 44.2499 29.7988 38.7499 29.7988 31.9499C29.7988 25.1499 34.3988 19.6499 40.0988 19.6499C45.7988 19.6499 50.3988 25.1499 50.3988 31.9499C50.3988 38.7499 45.6988 44.2499 40.0988 44.2499ZM40.0988 24.2499C36.9988 24.2499 34.3988 27.6499 34.3988 31.9499C34.3988 36.1499 36.9988 39.6499 40.0988 39.6499C43.1988 39.6499 45.7988 36.2499 45.7988 31.9499C45.7988 27.7499 43.1988 24.2499 40.0988 24.2499Z"
        fill="#C7DBE5"
      />
      <path
        d="M40.0988 45.6499C36.1988 45.6499 26.0988 55.9499 23.2988 65.2499H28.0988C30.7988 57.9499 37.8988 51.4499 39.9988 50.3499C42.0988 51.4499 49.1988 57.9499 51.8988 65.2499H56.6988C54.0988 55.9499 43.9988 45.6499 40.0988 45.6499Z"
        fill="#C7DBE5"
      />
      <path
        d="M39.9996 70.8499C22.8996 70.8499 9.09961 57.0499 9.09961 40.0499C9.09961 23.0499 22.8996 9.1499 39.9996 9.1499C57.0996 9.1499 70.8996 22.9499 70.8996 40.0499C70.8996 57.1499 56.9996 70.8499 39.9996 70.8499ZM39.9996 14.1499C25.6996 14.1499 14.0996 25.7499 14.0996 40.0499C14.0996 54.3499 25.6996 65.9499 39.9996 65.9499C54.2996 65.9499 65.8996 54.3499 65.8996 40.0499C65.8996 25.7499 54.1996 14.1499 39.9996 14.1499Z"
        fill="#C7DBE5"
      />
      <path d="M69.2992 18.6499H50.6992V23.6499H69.2992V18.6499Z" fill="#00A866" />
      <path d="M62.1992 12.0498H57.1992V30.6498H62.1992V12.0498Z" fill="#00A866" />
    </svg>
  );
};

export default MemberInvitedDialogIcon;
