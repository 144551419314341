import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../shared/utilities/typeHelper";
import { NotesEntityType } from "../../api/types/notesTypes";

interface ContextValue {
  entityId: string;
  entityType: NotesEntityType;
  objectType?: string;
  hasPermissionsToManageNotes: boolean;
}

const NotesContext = createContext<ContextValue | undefined>(undefined);

export const NotesContextProvider = ({
  entityId,
  entityType,
  objectType,
  hasPermissionsToManageNotes,
  children,
}: PropsWithChildren<ContextValue>) => (
  <NotesContext.Provider value={{ entityId, entityType, objectType, hasPermissionsToManageNotes }}>
    {children}
  </NotesContext.Provider>
);

export const useNotesContext = () =>
  defined(useContext(NotesContext), "Attempted to use NotesContext outside of its provider");
