import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, Stack } from "@mui/material";
import { CopyToEligibility } from "../components/CopyToEligibility";
import TooltipWrapper from "../../../../../../shared/components/TooltipWrapper";
import { useCallback, useMemo } from "react";
import { ReportAccessType, ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import { checkAccess } from "../../../../../../shared/reporting/components/sharing/utils";

interface Props {
  selectedReports: ReportInfo[];
  onCopy?: (ids: string[]) => void;
  onDelete: (ids: string[]) => void;
  hideCopy?: boolean;
}
export function CustomToolbarActions({ selectedReports, onCopy, onDelete, hideCopy }: Props) {
  const availableToDelete = useMemo(
    () => selectedReports.filter((r) => checkAccess(r.authorization.access, ReportAccessType.Owner)),
    [selectedReports]
  );

  const allItemsCanBeDeleted = useMemo(
    () => availableToDelete.length === selectedReports.length,
    [availableToDelete.length, selectedReports.length]
  );

  const deleteButtonText = useMemo(() => {
    if (allItemsCanBeDeleted) {
      return "Delete";
    }
    return `Delete (${availableToDelete.length})`;
  }, [allItemsCanBeDeleted, availableToDelete]);

  const deleteButtonToolTipText = useMemo(() => {
    if (allItemsCanBeDeleted) {
      return undefined;
    }
    return "Only the reports to which you have appropriate access will be deleted";
  }, [allItemsCanBeDeleted]);

  const handleOnCopy = useCallback(() => {
    onCopy?.(selectedReports.map((r) => r.reportId));
  }, [onCopy, selectedReports]);

  const handleOnDelete = useCallback(() => {
    onDelete(availableToDelete.map((r) => r.reportId));
  }, [onDelete, availableToDelete]);

  return (
    <Stack direction="row" gap={1}>
      {!hideCopy && (
        <CopyToEligibility>
          <Button
            color="secondary"
            variant="text"
            startIcon={<CopyAllIcon sx={{ color: "secondary.light" }} />}
            onClick={handleOnCopy}
          >
            Copy Report{selectedReports.length > 1 ? "s" : ""}
          </Button>
        </CopyToEligibility>
      )}
      {availableToDelete !== undefined && availableToDelete.length > 0 && (
        <TooltipWrapper title={deleteButtonToolTipText}>
          <Button variant="text" color="error" startIcon={<DeleteOutlineIcon color="error" />} onClick={handleOnDelete}>
            {deleteButtonText}
          </Button>
        </TooltipWrapper>
      )}
    </Stack>
  );
}
