import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";
import { DataModelConfiguration } from "../../../api/types/dataModelConfigurationTypes";
import { ObjectClassDefinition, ObjectClassDefinitionInfo } from "../../../api/types/objectTypes";
import { useClientContext } from "../../../context/ClientContext";

interface Props {
  objectDefinition: ObjectClassDefinition;
  onUpdateObjectDefinition: (objectDefinition: ObjectClassDefinition) => void;
  configurations: DataModelConfiguration[];
  allObjectDefinitions: ObjectClassDefinitionInfo[];
}

interface ContextValue extends Props {
  hasEditPermissions: boolean;
}

const ObjectDefinitionContext = createContext<ContextValue | undefined>(undefined);

export const ObjectDefinitionContextProvider = ({
  objectDefinition,
  onUpdateObjectDefinition,
  configurations,
  allObjectDefinitions,
  children,
}: PropsWithChildren<Props>) => {
  const { hasPermissions } = useClientContext();

  const hasEditPermissions = hasPermissions(["ManageOrganizationDataModel"]);

  return (
    <ObjectDefinitionContext.Provider
      value={{
        objectDefinition,
        onUpdateObjectDefinition,
        configurations,
        allObjectDefinitions,
        hasEditPermissions,
      }}
    >
      {children}
    </ObjectDefinitionContext.Provider>
  );
};

export const useObjectDefinitionContext = () =>
  defined(useContext(ObjectDefinitionContext), "Attempt to use ObjectDefinitionContext without provider");
