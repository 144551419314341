import CleanIcon from "@mui/icons-material/CleaningServicesOutlined";
import { Button } from "@mui/material";
import { useRef, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../shared/hooks/useFetch";
import { generateGuid } from "../../../../shared/utilities/generateGuid";
import adminApi from "../../../api/adminApi";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import Chat from "./Chat";
import { ChatItem, addChatItems, removeLastPendingItem, updateResponseItem } from "./chatState";

const sendMessage = withErrorHandling(adminApi.sendMessageToAssistant);

const AssistantPage = () => {
  const [items, setItems] = useState<ChatItem[]>([]);
  const abortSignalRef = useRef<AbortController | null>(null);

  const [threadData, createThreadError, { isFetching: isCreatingThread, fetch: recreateThread }] = useFetch(
    adminApi.createAssistantThread
  );

  if (createThreadError) {
    return <DataLoadingFailed title="Failed to start the chat" />;
  }

  const handleClearChat = async () => {
    setItems([]);
    await recreateThread();
  };

  const handleSubmitPrompt = async (messageText: string) => {
    if (threadData === undefined) {
      return;
    }

    const { threadId } = threadData;
    const responseItemId = generateGuid();

    setItems(
      addChatItems([
        { id: generateGuid(), source: "user", messageText },
        { id: responseItemId, source: "assistant", isPending: true },
      ])
    );

    const abortController = new AbortController();
    abortSignalRef.current = abortController;

    const [messageResp, error] = await sendMessage({ messageText, threadId }, abortController.signal);

    abortSignalRef.current = null;

    if (error) {
      setItems(
        updateResponseItem(
          responseItemId,
          "Could not process the message. Please try to restate your prompt and send again."
        )
      );
      return;
    }

    const { answers, citations } = messageResp;
    if (answers.length === 0) {
      setItems(
        updateResponseItem(
          responseItemId,
          "No answer was found for the provided prompt. Please try to restate your prompt and send again."
        )
      );
      return;
    }

    setItems(updateResponseItem(responseItemId, answers[0]?.textContent ?? "", answers[0]?.imageUrl));

    const additionalAnswers = answers.slice(1);
    if (additionalAnswers.length > 0) {
      setItems(
        addChatItems(
          additionalAnswers.map((answer) => ({
            id: generateGuid(),
            source: "assistant",
            messageText: answer.textContent,
            imageUrl: answer.imageUrl,
          }))
        )
      );
    }

    if (citations && citations.length > 0) {
      setItems(
        addChatItems(
          citations.map((citation) => ({
            id: generateGuid(),
            source: "assistant",
            messageText: citation.textContent,
            webUrl: citation.url,
          }))
        )
      );
    }
  };

  const handleStopGenerating = () => {
    setItems(removeLastPendingItem());
    if (abortSignalRef.current) {
      abortSignalRef.current.abort();
    }
  };

  const isLastItemPending = items.length > 0 && items[items.length - 1]?.isPending == true;
  const promptDisabled = threadData === undefined || isCreatingThread || isLastItemPending;

  return (
    <>
      <GeneralPageHeader title="Chat Session">
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CleanIcon color="secondary" />}
          onClick={handleClearChat}
          disabled={isCreatingThread}
        >
          New Chat
        </Button>
      </GeneralPageHeader>
      <Chat
        items={items}
        promptDisabled={promptDisabled}
        onSubmit={handleSubmitPrompt}
        onStopGenerating={handleStopGenerating}
      />
    </>
  );
};

export default AssistantPage;
