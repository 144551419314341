import CloseIcon from "@mui/icons-material/Close";
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { useReducer } from "react";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import adminApi from "../../../../../api/adminApi";
import { InternalEntity, PassthroughFund, PassthroughFundClosing } from "../../../../../api/types/passthroughTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { useImportDataContext } from "../../ImportDataContext";
import CommitmentDatePicker from "./CommitmentDatePicker";
import CommunicationGroupNameControl from "./CommunicationGroupNameControl";
import FundClosingControl from "./FundClosingControl";
import FundControl from "./FundControl";
import ImportInProgressAlert from "./ImportInProgressAlert";
import IntegrationNotConnected from "./IntegrationNotConnected";
import InternalFundControl from "./InternalFundControl";
import ImportPassthroughDataDialogActions from "./PassthroughDataProviderActions";
import { createImportDataPayload, getFundClosings, importPassthroughData } from "./passthroughDataProviderHelpers";
import { getInitialState, reducer } from "./passthroughDataProviderState";

interface Props {
  onClose: () => void;
}

const PassthroughDataProviderDialog = ({ onClose }: Props) => {
  const { clientCode } = useClientContext();
  const { sendNotificationError } = useNotificationContext();
  const [state, dispatch] = useReducer(reducer, getInitialState([], []));

  const { refreshData } = useImportDataContext();

  const [connectionResponse, getConnectionError] = useFetch(adminApi.getPassthroughConnectionStatus);

  const [fetchFundsResponse, fetchFundsError] = useFetch(adminApi.getPassthroughFunds, (data) => {
    dispatch({ type: "SetFunds", payload: data.items });
  });

  const [fetchInternalEntitiesResponse, fetchInternalEntitiesError] = useFetch(adminApi.getInternalEntities, (data) => {
    dispatch({ type: "SetInternalEntities", payload: data.items });
  });

  const fetchError = getConnectionError || fetchFundsError || fetchInternalEntitiesError;

  const startImport = async () => {
    const payload = createImportDataPayload(
      state.selectedFund?.id,
      state.selectedFundClosing?.id,
      state.selectedInternalFund?.id,
      state.commitmentDate
    );

    if (payload) {
      dispatch({ type: "SetIsImporting", payload: true });

      const [importDataResponse, error] = await importPassthroughData(payload);
      if (error?.isCanceledRequest) {
        dispatch({ type: "SetIsImporting", payload: false });
        return;
      }

      if (error || importDataResponse.result === false) {
        const errorMessage =
          error?.type === "PreconditionFailed" ? error.message : "An error occurred while importing data";

        sendNotificationError(errorMessage);
      } else {
        dispatch({ type: "SetIsImportInProgress", payload: true });
      }

      dispatch({ type: "SetIsImporting", payload: false });
    }
  };

  const selectFund = async (fund?: PassthroughFund) => {
    if (fund) {
      dispatch({ type: "UpdateAndValidateForm", payload: { ...state, selectedFund: fund } });
      await fetchFundClosings(fund);
    }
  };

  const fetchFundClosings = async (fund: PassthroughFund) => {
    dispatch({ type: "SetIsLoadingFundClosings", payload: true });

    const [fundClosingsResponse, error] = await getFundClosings(fund.id);
    if (error) {
      sendNotificationError("An error occurred while fetching fund closings");
    } else {
      dispatch({ type: "SetFundClosings", payload: fundClosingsResponse.items });
    }

    dispatch({ type: "SetIsLoadingFundClosings", payload: false });
  };

  const selectFundClosing = (fundClosing?: PassthroughFundClosing) => {
    dispatch({ type: "UpdateAndValidateForm", payload: { ...state, selectedFundClosing: fundClosing } });
  };

  const selectEntity = (internalFund?: InternalEntity) => {
    dispatch({ type: "UpdateAndValidateForm", payload: { ...state, selectedInternalFund: internalFund } });
  };

  const selectCommitmentDate = (commitmentDate: string | undefined) => {
    dispatch({ type: "UpdateAndValidateForm", payload: { ...state, commitmentDate: commitmentDate } });
  };

  const handleClose = () => {
    onClose();

    if (state.isImportInProgress) {
      refreshData();
    }
  };

  const isFetching =
    connectionResponse === undefined ||
    fetchFundsResponse === undefined ||
    fetchInternalEntitiesResponse === undefined ||
    state.isLoadingFundClosings;
  const showNoConnectionAlert = connectionResponse?.result === false;
  const showFetchErrorAlert = fetchError !== undefined;
  const areControlsDisabled =
    isFetching || showNoConnectionAlert || showFetchErrorAlert || state.isImporting || state.isImportInProgress;

  return (
    <Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle borderBottom={1} borderColor={"divider"}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" noWrap>
            Import from Passthrough
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={(t) => ({ position: "relative", minWidth: t.spacing(64) })}>
        <Stack spacing={4} pt={2.5}>
          {state.isImportInProgress && <ImportInProgressAlert />}
          {showNoConnectionAlert && <IntegrationNotConnected clientCode={clientCode} />}
          {showFetchErrorAlert && <Alert severity="error">An error occurred while fetching data for importing.</Alert>}
          <Stack spacing={2.5}>
            <Stack spacing={0.5}>
              <Typography variant="subtitle1">Source</Typography>
              <Typography color="secondary">
                Select a fund and a closing you wish to import from Passthrough.
              </Typography>
            </Stack>
            <FundControl
              value={state.selectedFund}
              options={state.funds}
              onChange={selectFund}
              disabled={areControlsDisabled}
            />
            <FundClosingControl
              value={state.selectedFundClosing}
              options={state.fundClosings}
              onChange={selectFundClosing}
              disabled={!state.selectedFund || areControlsDisabled}
            />
            <Stack spacing={2.5}>
              <Typography>Specify the Commitment Date to be used for the Commitment posting.</Typography>
              <CommitmentDatePicker
                commitmentDate={state.commitmentDate}
                onDateChanged={selectCommitmentDate}
                disabled={areControlsDisabled}
              />
            </Stack>
          </Stack>
          <Stack spacing={2.5}>
            <Stack spacing={0.5}>
              <Typography variant="subtitle1">Target</Typography>
              <Typography color="secondary">Select an Entity to link the investors to.</Typography>
            </Stack>
            <InternalFundControl
              value={state.selectedInternalFund}
              options={state.internalEntities}
              onChange={selectEntity}
              disabled={areControlsDisabled}
            />
            <CommunicationGroupNameControl
              communicationGroupName={state.selectedInternalFund?.communicationGroupName}
            />
          </Stack>
        </Stack>
        {isFetching && <InlineLoader overlay sx={{ pointerEvents: "auto", top: 0, left: 0, zIndex: 2 }} />}
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2, borderTop: 1, borderColor: "divider" }}>
        <ImportPassthroughDataDialogActions
          isImportInProgress={state.isImportInProgress}
          isImporting={state.isImporting}
          importDisabled={!state.isFormValid}
          onStartImport={startImport}
          onClose={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PassthroughDataProviderDialog;
