import { Stack, Typography } from "@mui/material";

interface Props {
  clientTitle?: string;
  logoUrl?: string;
  showTitle?: boolean;
}

const ConsentAgreementClientTitle = ({ logoUrl, clientTitle, showTitle }: Props) => (
  <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
    {logoUrl && <img alt="Client Logo" style={{ maxHeight: "2rem" }} src={logoUrl} />}
    {(!logoUrl || showTitle) && (
      <Typography sx={{ fontSize: "2rem", fontWeight: 500 }}>{clientTitle?.toLocaleUpperCase()}</Typography>
    )}
  </Stack>
);

export default ConsentAgreementClientTitle;
