import { useEffect } from "react";

const useResizeObserver = (
  element: Element | null,
  options: ResizeObserverOptions | undefined,
  observerCallback: ResizeObserverCallback
): void => {
  useEffect(() => {
    if (!element || !("ResizeObserver" in window)) {
      return;
    }

    const observer = new ResizeObserver(observerCallback);
    observer.observe(element, options);

    return () => {
      observer.disconnect();
    };
  }, [element, options, observerCallback]);
};

export default useResizeObserver;
