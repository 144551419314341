import { LinearProgress, Stack, Typography } from "@mui/material";
import { JobProgress } from "../../../shared/api/types";
import useSetInterval from "../../../shared/hooks/useSetInterval";
import { formatPercentage } from "../../../shared/utilities/formatters";

interface Props {
  title: string;
  showStepDetails?: boolean;
  jobProgress: JobProgress | undefined;
  updateIntervalMs?: number;
  onUpdateProgress: () => void;
}

const AsyncOperationProgress = ({ title, showStepDetails, jobProgress, updateIntervalMs, onUpdateProgress }: Props) => {
  useSetInterval(onUpdateProgress, updateIntervalMs ?? 1000);

  const progressPercent = jobProgress?.progressPercent || 0;

  return (
    <Stack spacing={1.5} alignItems="center">
      <Typography color="text.secondary" noWrap maxWidth="40rem">
        {title}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        {showStepDetails && jobProgress?.currentProgressItemName && (
          <Typography variant="caption" color="text.secondary" noWrap maxWidth="40rem">
            {jobProgress.currentProgressItemName}
          </Typography>
        )}
        {jobProgress?.errorMessage && (
          <Typography variant="caption" color="error">
            {jobProgress.errorMessage}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <LinearProgress variant="determinate" value={progressPercent} sx={{ width: "12.5rem" }} />
        <Typography color="text.secondary">{formatPercentage(progressPercent / 100)}</Typography>
      </Stack>
    </Stack>
  );
};

export default AsyncOperationProgress;
