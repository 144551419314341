import { formatMoney, formatMultiplier, formatNumber, formatPercentage } from "../../shared/utilities/formatters";
import { MetricFormatConfiguration } from "../api/types/portfolioMonitoringTypes";

export const formatMetricNumberValue = (value: number, format: MetricFormatConfiguration | undefined) => {
  if (format?.numberDisplayStyle === "Percentage") {
    return formatPercentage(value, {
      maximumFractionDigits: format.numberOfDecimals,
    });
  }

  if (format?.numberDisplayStyle === "Multiple") {
    return formatMultiplier(value, {
      maximumFractionDigits: format.numberOfDecimals,
    });
  }

  return formatNumber(value, {
    maximumFractionDigits: format?.numberOfDecimals,
    factor: format?.numberDisplayStyle,
  });
};

export const formatMetricMoneyValue = (
  value: number,
  currencyCode: string,
  format: MetricFormatConfiguration | undefined
) => {
  if (format?.numberDisplayStyle === "Percentage") {
    return formatPercentage(value, {
      maximumFractionDigits: format.numberOfDecimals,
    });
  }

  if (format?.numberDisplayStyle === "Multiple") {
    return formatMultiplier(value, {
      maximumFractionDigits: format.numberOfDecimals,
    });
  }

  return formatMoney(value, currencyCode, {
    maximumFractionDigits: format?.numberOfDecimals,
    factor: format?.numberDisplayStyle,
  });
};
