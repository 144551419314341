import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import { ClientConfigurationAssignment } from "./configurationsListState";

interface Props {
  configurations: DataModelConfiguration[];
  onRenameConfiguration: (id: string) => void;
  onDeleteConfiguration: (id: string) => void;
  onAssignClient: (clientAssignment: ClientConfigurationAssignment) => void;
}

const ConfigurationsGridActionsContext = createContext<Props | undefined>(undefined);

export const ConfigurationsGridActionsContextProvider = ({
  configurations,
  onRenameConfiguration,
  onDeleteConfiguration,
  onAssignClient,
  children,
}: PropsWithChildren<Props>) => (
  <ConfigurationsGridActionsContext.Provider
    value={{ configurations, onRenameConfiguration, onDeleteConfiguration, onAssignClient }}
  >
    {children}
  </ConfigurationsGridActionsContext.Provider>
);

export const useConfigurationsGridActionsContext = () =>
  defined(
    useContext(ConfigurationsGridActionsContext),
    "Attempt to use ConfigurationsGridActionsContext without provider"
  );
