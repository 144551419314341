import ExportIcon from "@mui/icons-material/ExitToAppOutlined";
import { Box, Button, Grid, Stack } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import saveAs from "file-saver";
import { useMemo, useState } from "react";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { DocumentInfo } from "../../../../../api/types/documentCollectionTypes";
import { createSearchFilter } from "../../searchFilter";
import { documentsToCsv } from "../csv-formatters";
import DocumentsFilter, { DocumentsFilterValue } from "./DocumentsFilter";
import DocumentsTable from "./DocumentsTable";
import DocumentPreviewContainer from "./document-preview/DocumentPreviewContainer";

interface Props {
  documents: DocumentInfo[];
  showPublishingStatus: boolean;
}

const applySearch = createSearchFilter<DocumentInfo>(3, (c) => [c.name, c.investorTitle, c.category]);

const applyFilter = (documents: DocumentInfo[], filterValue: DocumentsFilterValue) => {
  if (filterValue === "Viewed") {
    return documents.filter((d) => d.isViewed);
  }
  if (filterValue === "NotViewed") {
    return documents.filter((d) => !d.isViewed);
  }
  return documents;
};

const getFilterValueCounts = (documents: DocumentInfo[]) => {
  let allCount = 0;
  let viewedCount = 0;
  let notViewedCount = 0;

  for (const doc of documents) {
    if (doc.isViewed) {
      viewedCount++;
    } else {
      notViewedCount++;
    }
    allCount++;
  }

  return { allCount, viewedCount, notViewedCount };
};

const DocumentsView = ({ documents, showPublishingStatus }: Props) => {
  const [filteredItems, setFilteredItems] = useState<DocumentInfo[]>(documents);
  const [filterValue, setFilterValue] = useState<DocumentsFilterValue>("All");
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState<DocumentInfo[]>([]);

  const { allCount, viewedCount, notViewedCount } = useMemo(() => getFilterValueCounts(documents), [documents]);

  const updateFilteredItems = (documents: DocumentInfo[], searchTerm: string, filterValue: DocumentsFilterValue) => {
    const result = applyFilter(applySearch(documents, searchTerm), filterValue);
    setFilteredItems(result);
  };

  const handleSearch = (searchTerm: string) => {
    updateFilteredItems(documents, searchTerm, filterValue);
    setCurrentSearchTerm(searchTerm);
  };

  const handleFilterChange = (newValue: DocumentsFilterValue) => {
    updateFilteredItems(documents, currentSearchTerm, newValue);
    setFilterValue(newValue);
  };

  const handleExportToCsvClick = () => {
    const csv = documentsToCsv(filteredItems, "Investor");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const fileName = "document_views.csv";
    saveAs(blob, fileName);
  };

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setSelectedDocuments(documents.filter((doc) => newSelection.includes(doc.id)));
  };

  return (
    <Grid container height="100%">
      <Grid item xs={6} height="100%" sx={{ pb: 8 }}>
        <Box
          mt={2}
          mb={1}
          mr={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          rowGap={1}
        >
          <DocumentsFilter
            allCount={allCount}
            viewedCount={viewedCount}
            notViewedCount={notViewedCount}
            value={filterValue}
            onChange={handleFilterChange}
          />
          <Stack direction="row" spacing={1} alignItems="center">
            <SearchField onSearch={handleSearch} />
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleExportToCsvClick}
              startIcon={<ExportIcon />}
              disabled={filteredItems.length === 0}
            >
              Export
            </Button>
          </Stack>
        </Box>
        <DocumentsTable
          documents={filteredItems}
          showPublishingStatus={showPublishingStatus}
          onSelectionChange={handleSelectionChange}
        />
      </Grid>
      <Grid item xs={6} height="100%">
        <DocumentPreviewContainer messageLevel="Investor" documents={selectedDocuments} />
      </Grid>
    </Grid>
  );
};

export default DocumentsView;
