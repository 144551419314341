import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocalization } from "../../hooks/useLocalization";
import DocumentIcon from "../../icons/DocumentIcon";
import { downloadFileFromUrl } from "../../services/downloadFile";

interface Props {
  url: string;
  fileName: string;
  onClose: () => void;
}

const FileCannotBePreviewedDialog = ({ url, fileName, onClose }: Props) => {
  const locale = useLocalization();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const page = locale.pages.cant_preview_file;

  return (
    <Dialog open fullScreen={fullScreen} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" noWrap>
            Preview
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box textAlign="center" p={3}>
          <Stack spacing={2} alignItems="center">
            <DocumentIcon sx={{ width: 80, height: 80 }} />
            <Typography variant="h6">{fileName}</Typography>
            <Typography color="text.secondary">{page.subtitle}</Typography>
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions sx={{ mr: "1.5rem", mb: "1rem", columnGap: "0.5rem" }}>
        <Button color="secondary" onClick={onClose}>
          {page.cancelButton}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={() => downloadFileFromUrl(url)}
        >
          {page.downloadButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileCannotBePreviewedDialog;
