import ImportDataIcon from "@mui/icons-material/ExitToAppRounded";
import { Avatar } from "@mui/material";
import ExcelIcon from "../../../../shared/icons/fileFormats/ExcelIcon";
import { DataImportSource } from "../../../api/types/dataImportTypes";
import PassthroughLogo from "../../../images/passthrough.png";

interface Props {
  importSource: DataImportSource;
}

const ImportSourceIcon = ({ importSource }: Props) => {
  switch (importSource) {
    case "File":
      return <ExcelIcon />;
    case "Passthrough":
      return <Avatar src={PassthroughLogo} variant="rounded" sx={{ width: 24, height: 24 }} />;
    default:
      return <ImportDataIcon />;
  }
};

export default ImportSourceIcon;
