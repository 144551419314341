import { SvgIcon, SvgIconProps } from "@mui/material";

export const ImagePlaceholderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 44 26" {...props}>
      <path d="M0 5.2C0 2.32817 2.40243 0 5.36585 0C8.32928 0 10.7317 2.32817 10.7317 5.2C10.7317 8.07183 8.32928 10.4 5.36585 10.4C2.40243 10.4 0 8.07183 0 5.2Z" fill="#E4E7EB"/>
      <path d="M44 26H1.07317V19.76L6.37383 14.623C7.00257 14.0139 8.02182 14.0139 8.65043 14.623L13.9512 19.76L29.9836 4.22305C30.6123 3.61387 31.6316 3.61387 32.2602 4.22305L44 15.6V26Z" fill="#E4E7EB"/>
    </SvgIcon>
  );
};

export default ImagePlaceholderIcon;