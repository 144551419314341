import { Card, CardContent, Stack, SvgIconProps, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface Props {
  title: string;
  Icon: FC<SvgIconProps>;
}

const AppCard = ({ title, Icon, children }: PropsWithChildren<Props>) => (
  <Card variant="outlined" sx={(theme) => ({ width: theme.spacing(41) })}>
    <CardContent>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Icon sx={{ width: 48, height: 48 }} />
        <Stack spacing={1}>
          <Typography variant="subtitle1" pl={1}>
            {title}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={2}>
            {children}
          </Stack>
        </Stack>
      </Stack>
    </CardContent>
  </Card>
);

export default AppCard;
