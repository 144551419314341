import AccessTimeIcon from "@mui/icons-material/AccessTimeRounded";
import { SvgIconProps } from "@mui/material";
import FilesIcon from "../../../icons/FilesIcon";
import ViewsIcon from "../../../icons/ViewsIcon";

interface Props extends SvgIconProps {
  viewName: string;
}

const FilesViewIcon = ({ viewName, ...props }: Props) => {
  switch (viewName) {
    case "AllFiles":
      return <FilesIcon {...props} />;
    case "RecentlyUploaded":
      return <AccessTimeIcon {...props} />;
    default:
      return <ViewsIcon {...props} />;
  }
};

export default FilesViewIcon;
