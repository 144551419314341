import { Grid, Popover, Typography } from "@mui/material";
import CloseIconButton from "../../../../shared/components/CloseIconButton";
import HorizontalFill from "../../../../shared/components/HorizontalFill";

const examples = [
  `Please provide the latest performance report for the [Fund Name] for the past year.`,
  "I need the historical NAV (Net Asset Value) data for the [Fund Name] for the last five years.",
  "Hi, I'd like to know the top ten holdings of the [Fund Name] Can you please share the list?",
  "I need assistance with processing a redemption request for my investment in the [Fund Name]",
  "Can you give me an overview of the asset allocation for the [Fund Name].",
  "Please calculate the total expense ratio for the [Fund Name] for this quarter.",
  "Could you provide the performance attribution analysis for the [Fund Name] over the past six months?",
  "I'd like to schedule a performance review meeting for the [Fund Name]. Please suggest suitable dates and times.",
];

interface Props {
  anchorEl: HTMLElement | null;
  setPrompt: (text: string) => void;
  onClose: () => void;
}

const RequestExamples = ({ anchorEl, setPrompt, onClose }: Props) => {
  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      disableScrollLock
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{ mb: 2 }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: { p: 1.5, mt: -2, filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.32))" },
        },
      }}
    >
      <Grid container sx={{ mb: 1 }}>
        <Grid container sx={{ flex: 1, alignItems: "start" }}>
          <Grid>
            <Typography variant="subtitle1">Examples of requests</Typography>
            <Typography variant="caption" color="secondary">
              Click on prompt to add it to the chat input.
            </Typography>
          </Grid>
          <HorizontalFill />
          <CloseIconButton onClick={onClose} />
        </Grid>
      </Grid>
      <Grid container sx={{ display: "grid", gridTemplateColumns: "auto auto", gap: 1 }}>
        {examples.map((v, index) => (
          <Grid
            item
            key={`example_${index}`}
            sx={(theme) => ({
              p: 1,
              maxWidth: theme.spacing(43),
              border: "1px solid rgba(229, 230, 233, 1)",
              borderRadius: "4px",
              fontSize: "13px",
              "&:hover": {
                cursor: "pointer",
                bgcolor: "#f5f5f5",
              },
            })}
            dangerouslySetInnerHTML={{ __html: v.replace("[Fund Name]", "<b>[Fund Name]</b>") }}
            onClick={() => setPrompt(v)}
          ></Grid>
        ))}
      </Grid>
    </Popover>
  );
};

export default RequestExamples;
