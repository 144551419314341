import { Grid, Stack } from "@mui/material";
import { ContainerBlockAttributes } from "../../../../../api/types/inputFormTypes";
import DataSubmissionFormBlock from "./DataSubmissionFormBlock";

interface Props {
  attributes: ContainerBlockAttributes;
}

const ContainerFormBlock = ({ attributes }: Props) => {
  const xs =
    attributes.relativeWidth !== undefined && attributes.relativeWidth > 0
      ? Math.min(12, Math.round(attributes.relativeWidth * 12))
      : attributes.fixedWidth !== undefined && attributes.fixedWidth > 0
        ? true
        : "auto";

  return (
    <Grid item xs={xs} sx={{ width: attributes.fixedWidth }}>
      <Stack spacing={2.5}>
        {attributes.children.map((child) => (
          <DataSubmissionFormBlock key={child.id} layout={child} />
        ))}
      </Stack>
    </Grid>
  );
};

export default ContainerFormBlock;
