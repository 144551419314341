import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import {
  combineValidators,
  maxCharactersValidator,
  requiredValidator,
  uniqueValidator,
  ValidationResult,
} from "../../../../../../../shared/utilities/validators";

interface Props {
  onClose: () => void;
  onCreate: (name: string) => void;
  existingInvestorNames: string[];
}

const maxNameLength = 100;

interface FormState {
  name: string;
  validationResult: ValidationResult;
}

const CreateInvestorDialog = ({ onClose, onCreate, existingInvestorNames }: Props) => {
  const [formState, setFormState] = useState<FormState>({
    name: "",
    validationResult: { isValid: false, error: "" },
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validate = combineValidators(
      requiredValidator,
      maxCharactersValidator(maxNameLength),
      uniqueValidator("An investor with this name already exists", existingInvestorNames)
    );

    setFormState((prev) => ({
      ...prev,
      name: e.target.value,
      validationResult: validate(e.target.value),
    }));
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Investor</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          onChange={handleNameChange}
          value={formState.name}
          helperText={formState.validationResult.error}
          error={!!formState.validationResult.error}
          inputProps={{ maxLength: maxNameLength }}
          InputProps={{
            endAdornment: (
              <Typography color="text.secondary" variant="caption">
                {maxNameLength - formState.name.length}
              </Typography>
            ),
          }}
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!formState.validationResult.isValid}
          onClick={() => onCreate(formState.name)}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateInvestorDialog;
