import { useCallback } from "react";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { FundPermissionsResponse } from "../../../../../api/adminApi";
import { Category } from "../../../../../api/types/accessTypes";
import FundCommunicationMatrix from "./FundCommunicationMatrix";

interface Props {
  categories: Category[];
  fundPermissions: FundPermissionsResponse;
}

const FundCommunicationMatrixTab = ({ categories, fundPermissions }: Props) => {
  const getContacts = useCallback(
    async () =>
      adminApi.searchContacts({ fieldIds: [], includeFunds: true, includeInvestors: true, includeInboxData: false }),
    []
  );

  const fetchInvestors = useCallback(
    () => adminApi.searchInvestors({ fieldIds: [], includeFunds: true, includeContacts: true }),
    []
  );

  const [contactsResp, contactsLoadError] = useFetch(getContacts);
  const [investorsResp, investorsError] = useFetch(fetchInvestors);

  if (contactsLoadError || investorsError) {
    logError(contactsLoadError || investorsError, "FundCommunicationMatrixPage");
    return <DataLoadingFailed bgColor="none" title="Loading fund communication matrix failed" />;
  }

  if (contactsResp === undefined || investorsResp === undefined) {
    return <InlineLoader />;
  }

  return (
    <FundCommunicationMatrix
      categories={categories}
      permissionsResponse={fundPermissions}
      contacts={contactsResp.items}
      investors={investorsResp.items}
    />
  );
};

export default FundCommunicationMatrixTab;
