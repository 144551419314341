import AddIcon from "@mui/icons-material/AddRounded";
import { Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-premium";

interface Props {
  addRowDisabled?: boolean;
  onAddRowClick: () => void;
}

const DataImportChangesGridToolbar = ({ addRowDisabled, onAddRowClick }: Props) => {
  return (
    <GridToolbarContainer>
      <Button variant="text" startIcon={<AddIcon />} onClick={onAddRowClick} disabled={addRowDisabled}>
        Add row
      </Button>
    </GridToolbarContainer>
  );
};

export default DataImportChangesGridToolbar;
