import ExportIcon from "@mui/icons-material/OutputRounded";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../../shared/services/downloadFile";
import adminApi from "../../../../../api/adminApi";
import { useClientContext } from "../../../../../context/ClientContext";
import { useImportCsvDataContext } from "../ImportCsvDataContext";
import {
  ImportCsvTargetOption,
  dataImportTargetsWithSupportedExport,
  setFileUploadCompletedAction,
  setFileUploadErrorAction,
} from "../importCsvDataPageState";
import ImportSourceFileTable from "./ImportSourceFileTable";
import ImportSourceFileUploadArea from "./ImportSourceFileUploadArea";

interface Props {
  targetOption: ImportCsvTargetOption;
}

const getCsvTemplateDownloadUrl = withErrorHandling(adminApi.getCsvTemplateDownloadUrl);
const getDataExportDownloadUrls = withErrorHandling(adminApi.getDataExportDownloadUrls);
const uploadDataImportSourceFile = withErrorHandling(adminApi.uploadDataImportSourceFile);
const validateDataImportFileContent = withErrorHandling(adminApi.validateDataImportFileContent);

const ImportCsvDataFileUpload = ({ targetOption }: Props) => {
  const { clientTitle } = useClientContext();
  const { sendNotificationError } = useNotificationContext();
  const { pageState, setPageState } = useImportCsvDataContext();

  const [loading, setLoading] = useState(false);

  const isExportExistingDataSupported = dataImportTargetsWithSupportedExport.includes(targetOption.target);

  const sourceFileInfo = pageState.sourceFiles.find((file) => file.targetOptionKey === targetOption.key);

  const handleDownloadBlankTemplate = async () => {
    setLoading(true);
    const [url, error] = await getCsvTemplateDownloadUrl(
      targetOption.objectType,
      targetOption.bcEntityTypeCode,
      targetOption.target,
      clientTitle
    );
    setLoading(false);

    if (error) {
      logError(error, "[ImportCsvDataFileUpload] getCsvTemplateDownloadUrl");
      sendNotificationError("Failed to generate the template.");
      return;
    }

    downloadFileFromUrl(url);
  };

  const handleExportExistingData = async () => {
    if (!isExportExistingDataSupported) {
      return;
    }

    setLoading(true);
    const [resp, error] = await getDataExportDownloadUrls(targetOption.objectType, {
      exportTarget: targetOption.target,
      bcEntityType: targetOption.bcEntityTypeCode,
    });
    setLoading(false);

    if (error) {
      logError(error, "[ImportCsvDataFileUpload] getDataExportDownloadUrls");
      sendNotificationError("Failed to generate the export file.");
      return;
    }

    const urls = resp.filesDownloadUrls;
    if (urls.length === 0) {
      sendNotificationError("No data to export.");
      return;
    }

    urls.forEach((url) => downloadFileFromUrl(url));
  };

  const handleUploadStart = async (targetOptionKey: string, file: File) => {
    const [fileInfo, uploadError] = await uploadDataImportSourceFile(file);
    if (uploadError) {
      logError(uploadError, "[ImportCsvDataFileUpload] uploadDataImportSourceFile");
      setPageState(setFileUploadErrorAction(targetOptionKey, "Upload failed."));
      return;
    }

    const [validateFileResult, validateError] = await validateDataImportFileContent({
      fileDataCatalogueId: fileInfo.catalogueId,
      importTypeTarget: targetOption.target,
      importObjectTypeTarget: targetOption.objectType,
      importEntityTypeTarget: targetOption.bcEntityTypeCode,
    });

    if (validateError) {
      logError(validateError, "[ImportCsvDataFileUpload] validateDataImportFileContent");
      setPageState(setFileUploadErrorAction(targetOptionKey, "File validation failed."));
      return;
    }

    if (!validateFileResult.success) {
      setPageState(
        setFileUploadErrorAction(targetOptionKey, validateFileResult.errorMessage || "File validation failed.")
      );
      return;
    }

    setPageState(setFileUploadCompletedAction(targetOptionKey, fileInfo));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <Typography variant="subtitle2">{targetOption.label}</Typography>
        <Stack direction="row" spacing={1.5} alignItems="center">
          {loading && <CircularProgress size={20} color="primary" />}
          <Button variant="text" startIcon={<DownloadIcon />} disabled={loading} onClick={handleDownloadBlankTemplate}>
            Download blank template
          </Button>
          <Button
            variant="text"
            startIcon={<ExportIcon />}
            disabled={!isExportExistingDataSupported || loading}
            onClick={handleExportExistingData}
          >
            Export existing data
          </Button>
        </Stack>
      </Box>
      {sourceFileInfo && <ImportSourceFileTable sourceFile={sourceFileInfo} />}
      {!sourceFileInfo && (
        <ImportSourceFileUploadArea targetOptionKey={targetOption.key} onUploadStart={handleUploadStart} />
      )}
    </Box>
  );
};

export default ImportCsvDataFileUpload;
