import HomeIcon from "@mui/icons-material/HomeRounded";
import ReceiptIcon from "@mui/icons-material/ReceiptLongOutlined";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import ReportingPortalIcon from "@mui/icons-material/ShowChartRounded";
import { Box, MenuList } from "@mui/material";
import { useMatch } from "react-router-dom";
import { useClientContext } from "../../context/ClientContext";
import InvestorRelationsIcon from "../../icons/CardAccountDetailsIcon";
import FilesIcon from "../../icons/FilesIcon";
import PortfolioMonitoringIcon from "../../icons/QueryStatsIcon";
import SparklesIcon from "../../icons/SparklesIcon";
import WorkflowsIcon from "../../icons/WorkflowsIcon";
import { pageRoutes } from "../../routes";
import FeatureToggleBoundary from "../common/FeatureToggleBoundary";
import MenuGroupItem from "../common/MenuGroupItem";
import MenuLinkItem from "../common/MenuLinkItem";
import CompanySmartViewsMenu from "./CompanySmartViewsMenu";
import ExpenseManagementMenu from "./ExpenseManagementMenu";
import FundOpsMenuLinkItem from "./FundOpsMenuLinkItem";
import InvestorRelationsMenu from "./InvestorRelationsMenu";
import OrganizationSmartViewsMenu from "./OrganizationSmartViewsMenu";
import PortfolioMenu from "./PortfolioMenu";
import SettingsMenu from "./SettingsMenu";
import WorkflowsMenu from "./WorkflowsMenu";

const MainMenu = () => {
  const routeMatch = useMatch("/:client/:pageName/*");
  const currentPageName = routeMatch?.params.pageName;

  const {
    clientCode,
    isFundAdmin,
    businessCentralApp,
    investorPortalApp,
    reportingPortalApp,
    expenseManagementPortalApp,
    hasAnyPermission,
  } = useClientContext();

  const hasAccessToViewFundOps = hasAnyPermission(["ViewFundOps"]);
  const hasAccessToInvestorPortalSettings = hasAnyPermission([
    "ViewInvestorPortalSettings",
    "ManageInvestorPortalSettings",
  ]);
  const hasAccessToReportingPortal = hasAnyPermission(["ManageReports", "ViewReports"]);
  const hasAccessToOrganizationUsers = hasAnyPermission(["ManageOrganizationUsers", "ViewOrganizationUsers"]);
  const hasAccessToCompanySettings = hasAnyPermission(["ViewCompanySettings", "ManageCompanySettings"]);
  const hasAccessToIntegrationSettings = hasAnyPermission(["ViewClientIntegrations", "ManageClientIntegrations"]);
  const hasAccessToDocumentCollections = hasAnyPermission(["ViewDocumentCollections", "ManageFundStructure"]);
  const hasAccessToFundStructure = hasAnyPermission(["ViewFundStructure", "ManageFundStructure"]);
  const hasAccessToInvestorRelations =
    hasAccessToInvestorPortalSettings || hasAccessToDocumentCollections || hasAccessToFundStructure;
  const hasAccessToAssistant = hasAnyPermission(["UseAiAssistant"]);
  const hasAccessToSettings = isFundAdmin
    ? hasAccessToOrganizationUsers
    : hasAccessToCompanySettings || hasAccessToIntegrationSettings;
  const hasAccessToCompanyFiles = hasAnyPermission(["ViewAllCompanyFiles", "ManageAllCompanyFiles"]);
  const hasAccessToOrganizationReports = hasAnyPermission([
    "ManageOrganizationReports",
    "ManageOrganizationReportTemplates",
  ]);
  const hasAccessToExpenseManagement = hasAnyPermission(["ViewExpenses", "ManageExpenses"]);
  const hasAccessToPortfolioMonitoring = hasAnyPermission(["ViewPortfolioMonitoring", "ManagePortfolioMonitoring"]);

  return (
    <MenuList>
      {hasAccessToAssistant && (
        <FeatureToggleBoundary featureToggle="AiAssistantEnabled">
          <Box mb={1} sx={(t) => ({ border: "1px solid " + t.palette.divider, borderRadius: 0.5 })}>
            <MenuLinkItem
              active={currentPageName === pageRoutes.assistant}
              title="Your Assistant"
              href={`/${clientCode}/${pageRoutes.assistant}`}
              Icon={SparklesIcon}
            />
          </Box>
        </FeatureToggleBoundary>
      )}
      {!isFundAdmin && (
        <MenuLinkItem
          active={currentPageName === pageRoutes.home}
          title="Home"
          href={`/${clientCode}/${pageRoutes.home}`}
          Icon={HomeIcon}
        />
      )}

      {!isFundAdmin && businessCentralApp && hasAccessToViewFundOps && <FundOpsMenuLinkItem />}

      {!isFundAdmin && hasAccessToCompanyFiles && (
        <FeatureToggleBoundary featureToggle="EntityFilesEnabled">
          <MenuLinkItem
            active={currentPageName === pageRoutes.files}
            title="Files"
            href={`/${clientCode}/${pageRoutes.files}`}
            Icon={FilesIcon}
          />
        </FeatureToggleBoundary>
      )}

      <Box width="100%" height="1.25rem" />

      {!isFundAdmin && reportingPortalApp && hasAccessToReportingPortal && (
        <MenuGroupItem title="SmartViews" Icon={ReportingPortalIcon}>
          <CompanySmartViewsMenu />
        </MenuGroupItem>
      )}

      {isFundAdmin && hasAccessToOrganizationReports && (
        <MenuGroupItem title="SmartViews" Icon={ReportingPortalIcon}>
          <OrganizationSmartViewsMenu />
        </MenuGroupItem>
      )}

      {!isFundAdmin && investorPortalApp && hasAccessToInvestorRelations && (
        <MenuGroupItem title="Investor Relations" Icon={InvestorRelationsIcon}>
          <InvestorRelationsMenu />
        </MenuGroupItem>
      )}

      {!isFundAdmin && hasAccessToFundStructure && (
        <FeatureToggleBoundary featureToggle="ApprovalsPocEnabled">
          <MenuGroupItem title="Workflows" Icon={WorkflowsIcon}>
            <WorkflowsMenu />
          </MenuGroupItem>
        </FeatureToggleBoundary>
      )}

      {!isFundAdmin && hasAccessToPortfolioMonitoring && (
        <FeatureToggleBoundary featureToggle="PortfolioMonitoringEnabled">
          <MenuGroupItem title="Portfolio" Icon={PortfolioMonitoringIcon}>
            <PortfolioMenu />
          </MenuGroupItem>
        </FeatureToggleBoundary>
      )}

      {expenseManagementPortalApp && !isFundAdmin && hasAccessToExpenseManagement && (
        <MenuGroupItem title="Expense Management" Icon={ReceiptIcon}>
          <ExpenseManagementMenu />
        </MenuGroupItem>
      )}

      {hasAccessToSettings && (
        <MenuGroupItem title="Settings" Icon={SettingsIcon}>
          <SettingsMenu />
        </MenuGroupItem>
      )}
    </MenuList>
  );
};

export default MainMenu;
