import { Stack, Typography } from "@mui/material";
import CategoryInlineTags from "../../../../common/CategoryInlineTags";
import FundraisingConfigurationCard from "./FundraisingConfigurationCard";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const FundraisingConfigurationSection = () => {
  const { fundraising, fundraisingCategories, isContentEditable, onTabChange } = useFundraisingDetailsPageContext();

  return (
    <Stack width="100%" spacing={1}>
      <Typography variant="subtitle1" pt={2} pb={1}>
        Configuration
      </Typography>

      <FundraisingConfigurationCard
        checked={fundraisingCategories.length > 0}
        title="Access Categories"
        actionLabel={isContentEditable ? "Edit" : "View"}
        onActionClick={() => onTabChange("categories")}
      >
        <Typography variant="body2" color="text.secondary">
          Define categories to control contact access to documents.
        </Typography>
        <CategoryInlineTags categoryNames={fundraisingCategories.map((c) => c.name)} maxDisplayedCategories={5} />
      </FundraisingConfigurationCard>

      <FundraisingConfigurationCard
        checked={Boolean(fundraising.fund)}
        title="Access"
        actionLabel={isContentEditable ? "Edit" : "View"}
        onActionClick={() => onTabChange("access")}
      >
        <Typography variant="body2" color="text.secondary">
          Choose an exising or create a new fund to manage access to this fundraising.
        </Typography>
        {fundraising.fund && <Typography>{fundraising.fund.name}</Typography>}
      </FundraisingConfigurationCard>

      <FundraisingConfigurationCard
        checked={Boolean(fundraising.content?.title)}
        title="Content Layout"
        actionLabel={isContentEditable ? "Edit" : "View"}
        onActionClick={() => onTabChange("content")}
      >
        <Typography variant="body2" color="text.secondary">
          Review and adjust the appearance of your fundraising page.
        </Typography>
      </FundraisingConfigurationCard>

      <FundraisingConfigurationCard
        checked={fundraising.files.length > 0}
        title="Documents"
        actionLabel={isContentEditable ? "Add" : "View"}
        onActionClick={() => onTabChange("documents")}
      >
        <Typography variant="body2" color="text.secondary">
          Upload documents and other materials. Select the appropriate category for each file to manage permissions.
        </Typography>
      </FundraisingConfigurationCard>

      <FundraisingConfigurationCard
        checked={fundraising.notificationEmailSettings !== undefined}
        title="Notifications"
        actionLabel={isContentEditable ? "Edit" : "View"}
        onActionClick={() => onTabChange("notifications")}
      >
        <Typography variant="body2" color="text.secondary">
          Set up notifications to inform your audience when the fundraising page goes live.
        </Typography>
      </FundraisingConfigurationCard>
    </Stack>
  );
};

export default FundraisingConfigurationSection;
