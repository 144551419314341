import { LogSeverity } from "../../../../api/types/logTypes";
import { PassthroughApplicationLogs } from "../../../../api/types/passthroughTypes";
import { formatLogTimestamp } from "../../../common/syncLogs/utils";

export interface LogsTableRowData {
  key: string;
  severity: LogSeverity;
  date: string;
  details: PassthroughApplicationLogs;
  description: string;
}

const itemToRowData = (item: PassthroughApplicationLogs, index: number): LogsTableRowData => ({
  key: `${index}_${item.startTime}_${item.endTime}`,
  severity: item.severity,
  date: formatLogTimestamp(item.endTime),
  details: item,
  description: item.description,
});

export const getRows = (items: PassthroughApplicationLogs[]): LogsTableRowData[] => items.map(itemToRowData);
