import SendIcon from "@mui/icons-material/SendRounded";
import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { combineValidators, emailValidator, requiredValidator } from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import { useUserContext } from "../../../../context/UserContext";

interface Props {
  disabled?: boolean;
}

const sendTestInvitationEmail = withErrorHandling(adminApi.sendTestInvitationEmail);

const validateEmail = combineValidators(requiredValidator, emailValidator);

const SendTestInvitationEmail = ({ disabled }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const { name, email } = useUserContext();
  const [testEmailAddress, setTestEmailAddress] = useState(email);
  const [isSending, setSending] = useState(false);

  const handleButtonClick = async () => {
    setSending(true);

    const [, error] = await sendTestInvitationEmail({
      recipientName: name,
      recipientEmail: testEmailAddress,
    });

    setSending(false);

    if (error) {
      logError(error, "[SendTestInvitationEmail]");
      sendNotificationError(`Could not send test invitation email: ${getErrorMessage(error)}`);
      return;
    }

    sendNotification(`Test invitation email sent to ${testEmailAddress}`);
  };

  const { isValid } = validateEmail(testEmailAddress);

  return (
    <Stack spacing={2} maxWidth="32rem">
      <Typography variant="subtitle1">Test Email</Typography>
      <TextField
        variant="outlined"
        label="Email"
        value={testEmailAddress}
        onChange={({ target }) => setTestEmailAddress(target.value)}
      />
      <LoadingButton
        variant="outlined"
        color="primary"
        loading={isSending}
        endIcon={<SendIcon />}
        disabled={!!disabled || !isValid}
        onClick={handleButtonClick}
        sx={{ width: "7.5rem" }}
      >
        Send Email
      </LoadingButton>
    </Stack>
  );
};

export default SendTestInvitationEmail;
