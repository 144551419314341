import { IconButton } from "@mui/material";
import { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ClientInfo, ReportTemplate } from "../../../../../../shared/reporting/api/biClient.types";
import ReportTemplateActions from "./ReportTemplateActions";
import { getEditReportTemplateUrl } from "../../reports/utilities/editReportUrl";

interface Props {
  template: ReportTemplate;
  companies: ClientInfo[];
  readonly?: boolean;
}

export default function ActionMenuButton({ template, companies, readonly }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <IconButton
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <ReportTemplateActions
        open={!!anchorEl}
        anchorEl={anchorEl}
        template={template}
        onClose={() => setAnchorEl(null)}
        getEditReportTemplateUrl={getEditReportTemplateUrl}
        readonly={readonly}
        companies={companies}
      />
    </>
  );
}
