import CopyAllIcon from "@mui/icons-material/CopyAll";
import { Tooltip } from "@mui/material";
import { useState } from "react";

interface Props {
  textToCopy: string;
  color?: "secondary" | "action";
}

const CopyToBufferButton = ({ textToCopy, color }: Props) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  };

  const handleMouseLeave = () => setCopied(false);

  return (
    <Tooltip onMouseLeave={handleMouseLeave} title={copied ? "Copied!" : "Click to Copy"} placement="bottom-end">
      <CopyAllIcon color={color ?? "secondary"} sx={{ cursor: "pointer" }} onClick={handleCopyClick} />
    </Tooltip>
  );
};

export default CopyToBufferButton;
