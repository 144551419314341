import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { logError } from "../../../../../shared/logging";
import { numberComparerBy, stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../api/adminApi";
import { SelectOption } from "../../../common/filters/filterTypes";

const getAccessCategories = withErrorHandling(adminApi.getAccessCategories);

const getCategoryOptions = async () => {
  const [categories, error] = await getAccessCategories();
  if (error) {
    logError(error, "[getSelectOptions] getAccessCategories");
    return [];
  }

  return categories.sort(numberComparerBy((c) => c.sortOrder)).map((c) => ({ value: c.id, label: c.name }));
};

const searchFunds = withErrorHandling(adminApi.searchFunds);

const getFundOptions = async () => {
  const [resp, error] = await searchFunds({ fieldIds: [], includeInvestorCount: false });
  if (error) {
    logError(error, "[getSelectOptions] searchFunds");
    return [];
  }

  return resp.items.map((i) => ({ value: i.id, label: i.name })).sort(stringComparerBy((c) => c.label));
};

const searchInvestors = withErrorHandling(adminApi.searchInvestors);

const getInvestorOptions = async () => {
  const [resp, error] = await searchInvestors({ fieldIds: [], includeFunds: false, includeContacts: false });
  if (error) {
    logError(error, "[getSelectOptions] searchInvestors");
    return [];
  }

  return resp.items.map((i) => ({ value: i.id, label: i.title })).sort(stringComparerBy((c) => c.label));
};

export const getSelectOptionsForDocumentViews = async (filterId: string): Promise<SelectOption[]> => {
  switch (filterId) {
    case "categoryIds":
      return getCategoryOptions();
    case "fundIds":
      return getFundOptions();
    case "investorIds":
      return getInvestorOptions();
    default: {
      logError(`Unknown async select filterId: ${filterId}`, "[getSelectOptionsForDocumentViews]");
      return [];
    }
  }
};
