import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import {
  ValidationResult,
  combineValidators,
  invalidResult,
  maxCharactersValidator,
  requiredValidator,
  uniqueValidator,
} from "../../../../../shared/utilities/validators";
import adminApi, { Investor } from "../../../../api/adminApi";

interface Props {
  open: boolean;
  onClose: () => void;
  investors: Investor[];
  onCreated: (investorId: string, name: string) => void;
}

const createInvestor = withErrorHandling(adminApi.createInvestor);

const maxNameLength = 100;

const InvestorCreateDialog = ({ open, onClose, investors, onCreated }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [name, setName] = useState("");
  const [validationResult, setValidationResult] = useState<ValidationResult>(invalidResult(""));
  const [isSaving, setSaving] = useState(false);

  const validateName = combineValidators(
    requiredValidator,
    maxCharactersValidator(maxNameLength),
    uniqueValidator(
      "An investor with this name already exists",
      investors.map((i) => i.title.toLowerCase())
    )
  );

  const handleNameChange = (name: string) => {
    setName(name);
    setValidationResult(validateName(name.toLowerCase()));
  };

  const handleCreate = async () => {
    setSaving(true);
    const [investor, error] = await createInvestor({ name });
    setSaving(false);

    if (error) {
      logError(error, "[InvestorCreateDialog]");
      sendNotificationError("Failed to create investor");
      return;
    }

    sendNotification("Investor created successfully");
    onCreated(investor.id, name);
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => setName(""));
    setTimeout(() => setValidationResult(invalidResult("")));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Investor</DialogTitle>
      <DialogCloseButton onClick={handleClose} />

      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          onChange={(e) => handleNameChange(e.target.value)}
          error={!!validationResult.error}
          helperText={validationResult.error}
          inputProps={{ maxLength: maxNameLength }}
          InputProps={{
            endAdornment: (
              <Typography color="text.secondary" variant="caption">
                {maxNameLength - name.length}
              </Typography>
            ),
          }}
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={isSaving}
          variant="contained"
          disabled={!validationResult.isValid}
          onClick={handleCreate}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InvestorCreateDialog;
