import { createContext, PropsWithChildren, useContext, useState } from "react";
import { defined } from "../../shared/utilities/typeHelper";
import { UserData } from "../api/adminApi";
import { ClientInfo } from "../api/types/clientTypes";
import storage from "../storage/storage";

const UserContext = createContext<ContextValue | undefined>(undefined);

interface Props {
  userData: UserData;
}

interface ContextValue extends UserData {
  updateClientData: (clientCode: string, clientData: Partial<ClientInfo>) => void;
  isBetaAccessAvailable: boolean;
  isBetaAccessEnabled: boolean;
  updateBetaAccessEnabled: (value: boolean) => void;
}

export const UserContextProvider = ({ userData, children }: PropsWithChildren<Props>) => {
  const [data, setData] = useState<UserData>(userData);
  const [betaAccessEnabled, setBetaAccessEnabled] = useState(
    storage.getBetaAccessEnabled() ?? userData.betaAccess?.enabledByDefault === true
  );

  const updateClientData = (clientCode: string, clientData: Partial<ClientInfo>) => {
    setData((prev) => ({
      ...prev,
      clients: prev.clients.map((c) => (c.clientCode === clientCode ? { ...c, ...clientData } : c)),
    }));
  };

  const updateBetaAccessEnabled = (enabled: boolean) => {
    setBetaAccessEnabled(enabled);
    storage.setBetaAccessEnabled(enabled);
  };

  const isBetaAccessAvailable = data.betaAccess?.available === true;

  const isBetaAccessEnabled = betaAccessEnabled && isBetaAccessAvailable;

  return (
    <UserContext.Provider
      value={{
        ...data,
        updateClientData,
        isBetaAccessAvailable,
        isBetaAccessEnabled,
        updateBetaAccessEnabled,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const identity = useContext(UserContext);
  return defined(identity);
};
