import React, { PropsWithChildren, useContext } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";

interface ReportContextType {
  deleteReports: (ids: string[]) => void;
  duplicateReport: (report: ReportInfo) => void;
  shareReport: (report: ReportInfo) => void;
}

const ApiReportsManagerContextContext = React.createContext<ReportContextType | undefined>(undefined);

export const ApiReportsManagerContextProvider = ({ children, ...props }: PropsWithChildren<ReportContextType>) => {
  return <ApiReportsManagerContextContext.Provider value={props}>{children}</ApiReportsManagerContextContext.Provider>;
};

export const useApiReportsManagerContext = () => {
  const context = useContext(ApiReportsManagerContextContext);
  return defined(context);
};
