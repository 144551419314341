import { Box, Grid } from "@mui/material";
import objectHash from "object-hash";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import PreviewImageDialog from "../../../../../shared/components/previewFile/PreviewImageDialog";
import { ChatItem } from "../chatState";
import ChatMessage from "./ChatMessage";

interface Props {
  items: ChatItem[];
  scrollToBottomTrigger: string;
  onScrolled: (scrolledFromBottom: boolean) => void;
}

const ChatHistory = ({ items, scrollToBottomTrigger, onScrolled }: Props) => {
  const [imageDialogSrc, setImageDialogSrc] = useState("");

  const anchorElementRef = useRef<HTMLDivElement>();
  const containerElementRef = useRef<HTMLDivElement>();

  const itemsHash = useMemo(() => objectHash(items), [items]);

  useEffect(() => {
    setTimeout(() => {
      anchorElementRef.current?.scrollIntoView({ behavior: "smooth" });
    });
  }, [itemsHash, scrollToBottomTrigger]);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const { current: containerElement } = containerElementRef;
      if (containerElement) {
        const isScrolledFromBottom =
          containerElement.scrollHeight - containerElement.scrollTop - containerElement.clientHeight > 32;
        onScrolled(isScrolledFromBottom);
      }
    };

    if (containerElementRef.current) {
      containerElementRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      containerElementRef.current?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container sx={{ flex: 1, position: "relative" }}>
      <Box
        ref={(r: HTMLDivElement) => (containerElementRef.current = r)}
        sx={{
          display: "flex",
          position: "absolute",
          height: "100%",
          width: "100%",
          overflowY: "auto",
          flexDirection: "column",
          "&::-webkit-scrollbar": {
            background: "transparent",
            width: "16px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            minHeight: 24,
            border: "4px solid transparent",
            backgroundClip: "padding-box",
          },
          "&:hover::-webkit-scrollbar-thumb": {
            backgroundColor: "#cccaca",
          },
          "&::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
        }}
      >
        <Grid
          container
          sx={{
            marginTop: "auto",
            flexDirection: "column",
            gap: 2.5,
            flexWrap: "nowrap",
          }}
        >
          {items.map((item) => (
            <ChatMessage key={item.id} item={item} onShowAsDialog={(imageSrc) => setImageDialogSrc(imageSrc)} />
          ))}
        </Grid>
        <Box ref={(r: HTMLDivElement) => (anchorElementRef.current = r)} />
      </Box>
      <PreviewImageDialog src={imageDialogSrc} title="Image Preview" onClose={() => setImageDialogSrc("")} />
    </Grid>
  );
};

export default ChatHistory;
