import { Stack } from "@mui/material";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { FileInfo } from "../../../../api/types/fileTypes";
import EmptyFileDetailsPanel from "./EmptyFileDetailsPanel";
import MultipleFilesDetailsPanel from "./MultipleFilesDetailsPanel";
import SingleFileDetailsPanel from "./SingleFileDetailsPanel";

interface Props {
  files: FileInfo[];
}

const FileDetailsPanel = ({ files }: Props) => {
  const centerContent = files.length === 0;

  return (
    <Stack
      overflow="auto"
      height="100%"
      py={2}
      px={2.5}
      flexGrow={0}
      flexShrink={1}
      flexBasis="24rem"
      spacing={2}
      alignItems={centerContent ? "center" : "normal"}
      justifyContent={centerContent ? "center" : "normal"}
    >
      {files.length === 0 && <EmptyFileDetailsPanel />}
      {files.length === 1 && <SingleFileDetailsPanel catalogueId={defined(files[0]).catalogueId} />}
      {files.length > 1 && <MultipleFilesDetailsPanel files={files} />}
    </Stack>
  );
};

export default FileDetailsPanel;
