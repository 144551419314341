import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import FieldGroupSection from "./FieldGroupSection";
import { useFieldValuesContext } from "./FieldValuesContext";
import { getFieldInputIdsPerGroup } from "./entityFieldNavigator";

const FieldList = () => {
  const { fieldValuesState, fieldGroups, objectType } = useFieldValuesContext();

  const fieldInputIdsPerGroup = getFieldInputIdsPerGroup(objectType, fieldGroups);

  return (
    <Stack spacing={1}>
      {fieldGroups.map((group, index) => (
        <React.Fragment key={`field-group-${group.name}-${index}`}>
          {
            <FieldGroupSection
              group={group}
              fieldValues={fieldValuesState.fieldValues}
              fieldInputIds={fieldInputIdsPerGroup[index] ?? new Map<string, string>()}
            />
          }
          {index !== fieldGroups.length - 1 && (
            <Box py={2}>
              <Divider />
            </Box>
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default FieldList;
