import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Grid, Stack, SvgIconProps, SvgIconTypeMap, Typography } from "@mui/material";
import { ComponentType, PropsWithChildren, useCallback, useMemo } from "react";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { CompanyMultipleReportsListItem } from "./CompanyMultipleReportsListItem";
import { Validated } from "./CompanySingleReportListItemContainer";
import { Failed } from "./controls/Failed";
import { InProgressIcon } from "./controls/InProgressIcon";
import { ReportInProgress } from "./controls/ReportInProgress";
import { WaitingForValidation } from "./controls/WaitingForValidation";
import { ReportValidationType } from "./hooks/useReportValidation";
import { SelectedCompany, SelectedCompanyReport } from "./hooks/SelectedCompany";
import { getInvalidFieldsCount } from "./utils/getInvalidFieldsCount";
import { getItemSx } from "./utils/getItemSx";

interface Props {
  company: SelectedCompany;
  activeCompany: SelectedCompany | undefined;
  activeCompanyReport: SelectedCompanyReport | undefined;
  availableCompanies: ListItemData[];
  validation: ReportValidationType;
  onSelectedCompanyReport: (companyReport: SelectedCompanyReport | undefined) => void;
}

export function CompanyMultipleReportsListItemContainer({
  company,
  activeCompany,
  activeCompanyReport,
  availableCompanies,
  validation,
  onSelectedCompanyReport,
}: Props) {
  const isReportActive = useCallback(
    (report: SelectedCompanyReport) => {
      return (
        activeCompany?.code === report.companyCode && report.report.reportId === activeCompanyReport?.report.reportId
      );
    },
    [activeCompany?.code, activeCompanyReport?.report.reportId]
  );

  const isValidating = useMemo(() => company.reports.some((r) => r.validating), [company.reports]);

  return (
    <CompanyMultipleReportsListItem
      key={company.code}
      company={company}
      companyBranding={availableCompanies.find((c) => c.value === company.code)}
      Action={
        <CloseIconButton
          onClick={() => validation.removeCompany(company.code)}
          color="secondary"
          iconProps={{ sx: (theme) => ({ color: theme.palette.secondary.light }) }}
        />
      }
      Summary={isValidating ? <ValidatingInfo company={company} /> : <ValidatedInfo company={company} />}
    >
      {company.reports.map((report) => (
        <ValidationItem
          key={report.report.reportId}
          report={report}
          active={isReportActive(report)}
          validation={validation}
          onSelected={() => onSelectedCompanyReport(report)}
        />
      ))}
    </CompanyMultipleReportsListItem>
  );
}

interface ItemProps {
  report: SelectedCompanyReport;
  active: boolean;
  validation: ReportValidationType;
  onSelected: () => void;
}

function ValidationItem({ report, active, validation, onSelected }: ItemProps) {
  const { reports_copyto: locale } = useLocalization();

  return (
    <ValidationItemContainer
      report={report.report}
      active={active}
      completed={report.validated}
      selectable
      onSelected={onSelected}
    >
      {!report.validating && !report.validated && !report.error && <WaitingForValidation />}
      {report.validating && <ReportInProgress text={locale.validating} />}
      {report.validated && <Validated companyReport={report} />}
      {report.error && <Failed onRetry={() => validation.retryValidation(report)} />}
    </ValidationItemContainer>
  );
}

interface ValidationItemContainerProps {
  report: ReportInfo;
  active?: boolean;
  completed: boolean;
  selectable?: boolean;
  Action?: JSX.Element;
  onSelected?: () => void;
}
export function ValidationItemContainer({
  report,
  active,
  completed,
  selectable,
  children,
  Action,
  onSelected,
}: PropsWithChildren<ValidationItemContainerProps>) {
  return (
    <Grid
      container
      sx={(theme) => ({
        bgcolor: "background.paper",
        p: 1,
        gap: 1,
        borderRadius: 1,
        border: "1px solid",
        flexDirection: "row",
        ...getItemSx(selectable, active, !completed, theme),
      })}
      onClick={onSelected}
    >
      <Stack direction="column" spacing={1} flex={1}>
        <Typography> {report.name}</Typography>
        <Stack direction="row" height={20} position="relative">
          {children}
        </Stack>
      </Stack>
      {Action}
    </Grid>
  );
}

function ValidatingInfo({ company }: { company: SelectedCompany }) {
  const validatedCount = useMemo(() => {
    return company.reports.filter((r) => r.validated || r.error).length;
  }, [company.reports]);

  return (
    <Stack direction={"row"} spacing={1}>
      <Typography color={"secondary"}>
        {validatedCount}/{company.reports.length}
      </Typography>
      <InProgressIcon />
    </Stack>
  );
}
function ValidatedInfo({ company }: { company: SelectedCompany }) {
  const failedCount = useMemo(() => {
    return company.reports.filter((r) => r.error).length;
  }, [company.reports]);

  const warningCount = useMemo(() => {
    return company.reports.filter((r) => getInvalidFieldsCount(r.result) > 0).length;
  }, [company.reports]);

  const successCount = useMemo(() => {
    return company.reports.filter((r) => r.validated && getInvalidFieldsCount(r.result) === 0 && !r.error).length;
  }, [company.reports]);

  return (
    <Stack direction="row" spacing={1.5}>
      <ValidationInfoItem count={failedCount} Icon={ErrorRoundedIcon} color="error" />
      <ValidationInfoItem count={warningCount} Icon={WarningRoundedIcon} color="warning" />
      <ValidationInfoItem count={successCount} Icon={CheckRoundedIcon} color="success" />
    </Stack>
  );
}

export function ValidationInfoItem({
  count,
  Icon,
  color,
}: {
  count: number | string;
  Icon: ComponentType<SvgIconProps>;
  color?: SvgIconTypeMap["props"]["color"];
}) {
  return count ? (
    <Stack direction="row" spacing={0.2} alignItems="center">
      <Typography variant="caption" color="secondary">
        {count}
      </Typography>
      <Icon color={color} />
    </Stack>
  ) : null;
}
