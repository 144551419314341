import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Container, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { useUserContext } from "../../../../context/UserContext";
import { useGridGroupsExpanded } from "../../../common/grid/gridHooks";
import AssignClientToConfigurationDialog from "./AssignClientToConfigurationDialog";
import { ConfigurationsGridActionsContextProvider } from "./ConfigurationsGridActionsContext";
import CreateConfigurationDialog from "./CreateConfigurationDialog";
import DeleteConfigurationDialog from "./DeleteConfigurationDialog";
import EditConfigurationDialog from "./EditConfigurationDialog";
import {
  ConfigurationRow,
  getColumnDefinitions,
  getGroupingColumnDefinition,
  getRows,
} from "./configurationsGridDataProvider";
import { ClientConfigurationAssignment, ClientConfigurationListState } from "./configurationsListState";

const ConfigurationsList = () => {
  const { clients } = useUserContext();
  const { clientCode, hasPermissions } = useClientContext();
  const gridApiRef = useGridApiRef();
  const { isGroupExpanded } = useGridGroupsExpanded(gridApiRef);

  const [state, setState] = useState<ClientConfigurationListState>({});

  const [configurations, error, { isFetching, setData: setConfigurations }] = useFetch(
    adminApi.getDataModelConfigurations
  );

  const rows = useMemo(() => getRows(configurations ?? [], clientCode, clients), [clientCode, clients, configurations]);

  if (error) {
    logError(error, "[ConfigurationsList]");
    return <DataLoadingFailed title="Failed to load configurations" />;
  }

  const hasEditPermissions = hasPermissions(["ManageOrganizationDataModel"]);

  const handleAddConfiguration = () => {
    setState({ openDialog: "create_configuration" });
  };

  const handleRenameConfiguration = (id: string) => {
    const editedConfiguration = (configurations ?? []).find((config) => config.id === id);
    if (editedConfiguration) {
      setState({ openDialog: "edit_configuration", editedConfiguration });
    }
  };

  const handleDeleteConfiguration = (id: string) => {
    const deletedConfiguration = (configurations ?? []).find((config) => config.id === id);
    if (deletedConfiguration) {
      setState({ openDialog: "delete_configuration", deletedConfiguration });
    }
  };

  const handleAssignClient = (clientAssignment: ClientConfigurationAssignment) => {
    setState({ openDialog: "assign_client", clientAssignment });
  };

  const handleConfigurationCreated = (newConfiguration: DataModelConfiguration) => {
    setState({});
    setConfigurations((prevConfigurations) =>
      prevConfigurations ? [...prevConfigurations, newConfiguration] : [newConfiguration]
    );
  };

  const handleConfigurationUpdated = (updatedConfiguration: DataModelConfiguration) => {
    setState({});
    setConfigurations((prevConfigurations) =>
      prevConfigurations
        ? prevConfigurations.map((config) => (config.id === updatedConfiguration.id ? updatedConfiguration : config))
        : undefined
    );
  };

  const handleConfigurationDeleted = (deletedConfiguration: DataModelConfiguration) => {
    setState({});
    setConfigurations((prevConfigurations) =>
      prevConfigurations ? prevConfigurations.filter((config) => config.id !== deletedConfiguration.id) : undefined
    );
  };

  const handleClientAssigned = (clientAssignment: ClientConfigurationAssignment) => {
    setState({});
    setConfigurations((prevConfigurations) =>
      prevConfigurations
        ? prevConfigurations.map((config) => {
            if (config.id === clientAssignment.toConfiguration.id) {
              return { ...config, clientCodes: [...config.clientCodes, clientAssignment.clientCode] };
            }
            if (config.id === clientAssignment.fromConfiguration.id) {
              return {
                ...config,
                clientCodes: config.clientCodes.filter((code) => code !== clientAssignment.clientCode),
              };
            }
            return config;
          })
        : undefined
    );
  };

  return (
    <ConfigurationsGridActionsContextProvider
      configurations={configurations ?? []}
      onRenameConfiguration={handleRenameConfiguration}
      onDeleteConfiguration={handleDeleteConfiguration}
      onAssignClient={handleAssignClient}
    >
      <Container maxWidth="md" disableGutters sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {hasEditPermissions && (
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2.5}>
            <Typography color="text.secondary">
              Create company configurations to customize object type layouts.
            </Typography>
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddConfiguration}>
              Add Configuration
            </Button>
          </Box>
        )}
        <DataGrid<ConfigurationRow>
          apiRef={gridApiRef}
          treeData
          getTreeDataPath={(row) => [row.group, row.clientCode]}
          groupingColDef={getGroupingColumnDefinition()}
          columns={getColumnDefinitions({ hasEditPermissions })}
          rows={rows}
          loading={isFetching}
          rowHeight={40}
          isGroupExpandedByDefault={isGroupExpanded}
          slots={{
            loadingOverlay: () => <InlineLoader />,
            noRowsOverlay: () => (
              <CenteredWrapper>
                <Typography color="text.secondary">No configurations yet</Typography>
              </CenteredWrapper>
            ),
          }}
          hideFooter
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableColumnReorder
          disableRowSelectionOnClick
          disableMultipleRowSelection
        />
      </Container>
      {state.openDialog === "create_configuration" && (
        <CreateConfigurationDialog onClose={() => setState({})} onCreated={handleConfigurationCreated} />
      )}
      {state.openDialog === "edit_configuration" && state.editedConfiguration && (
        <EditConfigurationDialog
          editedConfiguration={state.editedConfiguration}
          onClose={() => setState({})}
          onUpdated={handleConfigurationUpdated}
        />
      )}
      {state.openDialog === "delete_configuration" && state.deletedConfiguration && (
        <DeleteConfigurationDialog
          deletedConfiguration={state.deletedConfiguration}
          onClose={() => setState({})}
          onDeleted={handleConfigurationDeleted}
        />
      )}
      {state.openDialog === "assign_client" && state.clientAssignment && (
        <AssignClientToConfigurationDialog
          assignment={state.clientAssignment}
          onClose={() => setState({})}
          onAssigned={handleClientAssigned}
        />
      )}
    </ConfigurationsGridActionsContextProvider>
  );
};

export default ConfigurationsList;
