import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Button, Menu, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import CategoryTag from "../../../../../common/CategoryTag";
import CustomDataGridHeaderToolbar from "../../../../../common/grid/CustomDataGridHeaderToolbar";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import { deleteDocumentsAction, updateDocumentsCategoryAction } from "./fundraisingDocumentsState";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  disabled?: boolean;
}

const FundraisingDocumentsGridToolbar = ({ selectedIds, setSelectedIds, disabled }: Props) => {
  const { fundraisingCategories, isContentEditable } = useFundraisingDetailsPageContext();
  const { documentsState, setDocumentsState, onDownloadFile, onDownloadMultipleFiles } =
    useFundraisingDocumentsContext();

  const [isCategorySelectorOpen, setCategorySelectorOpen] = useState(false);
  const changeCategoryButtonRef = useRef<HTMLButtonElement>(null);

  const handleDownloadClick = () => {
    selectedIds.length === 1 ? onDownloadFile(defined(selectedIds[0])) : onDownloadMultipleFiles(selectedIds);
  };

  const isAnyUploadingFileSelected = selectedIds.some(
    (id) => documentsState.documents.find((doc) => doc.fileId === id)?.uploadStatus !== undefined
  );

  return (
    <CustomDataGridHeaderToolbar sx={{ height: "3.25rem" }} selection={[selectedIds, setSelectedIds]}>
      {isContentEditable && (
        <Button
          ref={changeCategoryButtonRef}
          disabled={disabled}
          onClick={() => setCategorySelectorOpen(true)}
          color="secondary"
          variant="text"
          startIcon={<EditIcon />}
        >
          Change Category
        </Button>
      )}

      <Button
        disabled={disabled || isAnyUploadingFileSelected}
        onClick={handleDownloadClick}
        color="secondary"
        variant="text"
        startIcon={<DownloadIcon />}
      >
        Download
      </Button>

      {isContentEditable && (
        <Button
          disabled={disabled}
          onClick={() => setDocumentsState(deleteDocumentsAction(selectedIds))}
          color="error"
          variant="text"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      )}

      <Menu
        open={isCategorySelectorOpen}
        onClose={() => setCategorySelectorOpen(false)}
        anchorEl={changeCategoryButtonRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {fundraisingCategories.map((category) => (
          <MenuItem
            key={category.externalId}
            value={category.externalId}
            dense
            onClick={() =>
              setDocumentsState(
                updateDocumentsCategoryAction(selectedIds, fundraisingCategories, category.externalId, category.name)
              )
            }
          >
            <CategoryTag categoryName={category.name} />
          </MenuItem>
        ))}
      </Menu>
    </CustomDataGridHeaderToolbar>
  );
};

export default FundraisingDocumentsGridToolbar;
