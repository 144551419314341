import { Table, TableProps } from "@mui/material";
import useMergedSx from "../hooks/useMergedSx";

const TableWithActions = ({ children, sx, ...otherProps }: TableProps) => {
  const mergedSx = useMergedSx(sx, {
    ".MuiTableRow-root": {
      "&:hover": {
        ".MuiTableCell-root.table-row-actions": {
          button: {
            visibility: "visible",
          },
        },
      },
    },
    ".MuiTableCell-root": {
      "&.table-row-actions": {
        button: {
          visibility: "hidden",
        },
      },
    },
  });

  return (
    <Table {...otherProps} sx={mergedSx}>
      {children}
    </Table>
  );
};

export default TableWithActions;
