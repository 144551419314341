import { localStorageService } from "../../shared/services/storage";
import { FilterType, FilterValue } from "../components/common/filters/filterTypes";

const RECENT_CLIENTS_KEY = "recent_clients";
const MAX_RECENT_CLIENTS = 3;

const getRecentClients = () => localStorageService.getItemWithFallback<string[]>(RECENT_CLIENTS_KEY, []);

const updateRecentClients = (clientCode: string) => {
  const recentClients = getRecentClients();
  recentClients.unshift(clientCode);

  const newRecentClients = recentClients.reduce<string[]>((result, code) => {
    if (!result.includes(code) && result.length < MAX_RECENT_CLIENTS) {
      result.push(code);
    }
    return result;
  }, []);

  localStorageService.setItem(RECENT_CLIENTS_KEY, newRecentClients);
};

const BETA_ACCESS_KEY = "beta";
const getBetaAccessEnabled = () => localStorageService.getItem<boolean>(BETA_ACCESS_KEY);
const setBetaAccessEnabled = (value: boolean) => localStorageService.setItem(BETA_ACCESS_KEY, value);

export interface PersistedFilterState {
  filters: PersistedFilter[];
  columns: PersistedColumn[];
}

interface PersistedFilter {
  id: string;
  type: FilterType;
  value: FilterValue;
}

interface PersistedColumn {
  id: string;
}

const getFilterState = (filterId: string) => localStorageService.getItem<PersistedFilterState>(filterId);

const saveFilterState = (filterId: string, filterState: PersistedFilterState) =>
  localStorageService.setItem(filterId, filterState);

const clearFilterState = (filterId: string) => localStorageService.removeItem(filterId);

const CLOSED_MAIN_MENU_ITEMS_KEY = "closed_main_menu_items";
const getClosedMainMenuItems = () => localStorageService.getItemWithFallback<string[]>(CLOSED_MAIN_MENU_ITEMS_KEY, []);
const saveClosedMainMenuItems = (items: string[]) => localStorageService.setItem(CLOSED_MAIN_MENU_ITEMS_KEY, items);

const storage = {
  getRecentClients,
  updateRecentClients,
  getBetaAccessEnabled,
  setBetaAccessEnabled,
  getFilterState,
  saveFilterState,
  clearFilterState,
  getClosedMainMenuItems,
  saveClosedMainMenuItems,
};

export default storage;
