import React from "react";
import SaveReportFormWrapper from "../../../../../../shared/reporting/components/reports/SaveReportFormWrapper";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import ReportGroupsConnector from "../../../../../../shared/reporting/components/reports/ReportGroupsConnector";
import PromptDialog from "../../../../../../shared/components/dialog/PromptDialog";
import { useSharedReportingLocalization } from "../../../../../../shared/reporting/hooks/useLocalization";

interface Props {
  report: ReportInfo;
  duplicateReport: (report: ReportInfo, name: string, groupId: string) => Promise<ReportInfo | undefined>;
  onChanged: (reportId: string) => void;
  onClose: () => void;
}

export default function DuplicateReport({ report, duplicateReport, onChanged, onClose }: Props) {
  const { report: locale } = useSharedReportingLocalization();

  const [error, setError] = React.useState<string>();
  const [saving, setSaving] = React.useState(false);

  const onSaveClicked = React.useCallback(
    (name: string, groupId: string) => {
      setSaving(true);

      duplicateReport(report, name, groupId)
        .then((response) => {
          setSaving(false);
          onChanged(response?.reportId || "");
          onClose();
        })
        .catch(() => {
          setError(locale.duplicating_error);
          setSaving(false);
        });
    },
    [duplicateReport, report, onChanged, onClose, locale.duplicating_error]
  );

  return (
    <PromptDialog open={true}>
      <ReportGroupsConnector clientCode={report.clientCode}>
        <SaveReportFormWrapper
          report={report}
          error={error}
          title={"Duplicate Report"}
          saveBtnTitle={"Save"}
          saving={saving}
          showName
          showLevel
          newReport
          onClose={onClose}
          onSave={onSaveClicked}
        />
      </ReportGroupsConnector>
    </PromptDialog>
  );
}
