import { SvgIcon, SvgIconProps } from "@mui/material";

const CancelReportsCopyingIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 80 80" sx={{ height: 80, width: 80, fill: "none" }} {...props}>
    <g clipPath="url(#clip0_3355_169378)">
      <path
        d="M8 0H72C76.4 0 80 3.6 80 8V72C80 76.4 76.4 80 72 80H8C3.6 80 0 76.4 0 72V8C0 3.6 3.6 0 8 0Z"
        fill="white"
      />
      <path d="M23 65H62V27" stroke="#C7DBE5" strokeWidth="4" />
      <path
        d="M57 60.0001H15.4629V8.7251H46.4003L57 19.3248V60.0001ZM19.7717 55.6913H52.605V21.1345L44.5905 13.1201H19.7717V55.6913Z"
        fill="#C7DBE5"
      />
      <path d="M54.8454 23.892H42.2637V10.8794H46.5725V19.5832H54.8454V23.892Z" fill="#C7DBE5" />
      <path d="M47.3488 30.6143H25.1152V34.9231H47.3488V30.6143Z" fill="#C7DBE5" />
      <path d="M39.1935 39.9858H25.4053V44.2947H39.1935V39.9858Z" fill="#C7DBE5" />
      <path d="M71.5361 52.0001L54 69.5361L57.5355 73.0716L75.0716 55.5356L71.5361 52.0001Z" fill="#F44336" />
      <path d="M57.5355 52.0001L54 55.5356L71.5361 73.0717L75.0716 69.5362L57.5355 52.0001Z" fill="#F44336" />
    </g>
    <defs>
      <clipPath id="clip0_3355_169378">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CancelReportsCopyingIcon;
