import RefreshIcon from "@mui/icons-material/CachedRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Button } from "@mui/material";

interface Props {
  showValidate?: boolean;
  showDelete?: boolean;
  onValidate: () => void;
  onDelete: () => void;
}

const ReviewImportActions = ({ showValidate, showDelete, onValidate, onDelete }: Props) => {
  return (
    <>
      {showValidate && (
        <Button variant="outlined" onClick={onValidate} startIcon={<RefreshIcon />}>
          Validate
        </Button>
      )}
      {showDelete && (
        <Button variant="outlined" color="error" onClick={onDelete} startIcon={<DeleteIcon />}>
          Delete
        </Button>
      )}
    </>
  );
};

export default ReviewImportActions;
