import { createSvgIcon } from "@mui/material";

const DeleteTagIcon = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="none">
    <path d="M51.8 19H26V24H51.8V19Z" fill="#C7DBE5" />
    <path d="M17.5001 65.5V11H61.0001V65.5L40 48L17.5001 65.5Z" stroke="#C7DBE5" strokeWidth="5" />
    <path d="M68.5361 52.0001L51 69.5361L54.5355 73.0716L72.0716 55.5356L68.5361 52.0001Z" fill="#F44336" />
    <path d="M54.5355 52.0001L51 55.5356L68.5361 73.0717L72.0716 69.5362L54.5355 52.0001Z" fill="#F44336" />
  </svg>,
  "DeleteTagIcon"
);

export default DeleteTagIcon;
