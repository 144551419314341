import { ApprovalType } from "../../../api/mock/approvals/approvalsTypes";

export const formatApprovalType = (approvalType: ApprovalType) => {
  switch (approvalType) {
    case "Payments":
      return "Payments";
    case "InvestorRequests":
      return "Investor Requests";
    case "DocumentCollections":
      return "Document Collections";
    default:
      return approvalType;
  }
};

export const oneOrMultipleText = (values: string[]) => {
  if (values.length === 0) {
    return "-";
  }
  if (values.length === 1) {
    return values[0];
  }
  return "Multiple";
};
