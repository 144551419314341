import { Chip, Stack, Typography } from "@mui/material";
import { TagSelectItem } from "../fileDetailsHelper";

interface Props {
  fileTags: TagSelectItem[];
}

const FileTagsSection = ({ fileTags }: Props) => {
  return (
    <Stack spacing={1.5}>
      <Typography variant="subtitle1">Tags</Typography>
      <Stack direction="row" flexWrap="wrap" spacing={1} rowGap={1}>
        {fileTags.length === 0 ? (
          <Typography color="text.secondary">No tags yet</Typography>
        ) : (
          fileTags.map(({ id, label }) => <Chip variant="outlined" key={id} label={label} />)
        )}
      </Stack>
    </Stack>
  );
};

export default FileTagsSection;
