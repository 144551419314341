import { createSvgIcon } from "@mui/material";

const PaperIcon = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="none">
    <rect x="14.5" y="11.5" width="43" height="54" stroke="#C7DBE5" strokeWidth="5" />
    <path d="M50 21H22V26H50V21Z" fill="#C7DBE5" />
    <path d="M50 31H22V36H50V31Z" fill="#C7DBE5" />
    <path d="M39 41H22V46H39V41Z" fill="#C7DBE5" />
  </svg>,
  "PaperIcon"
);

export default PaperIcon;
