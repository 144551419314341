import { Alert, Box } from "@mui/material";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";

export interface TestConnectionState {
  status: "hidden" | "loading" | "success" | "error";
  errorMessage?: string;
}

interface Props {
  state: TestConnectionState;
  onClose: () => void;
}

const AlertMessage = ({ status, errorMessage }: TestConnectionState) => {
  if (status === "success") {
    return (
      <>
        Test connection was successful. All logs you can see in tab <strong>Logs</strong>.
      </>
    );
  }

  let msg = "Test connection failed";
  if (errorMessage) {
    msg += `: ${errorMessage}`;
  }

  return <>{msg}</>;
};

const TestConnectionInfo = ({ state, onClose }: Props) => {
  const { status, errorMessage } = state;

  if (status === "hidden") {
    return null;
  }

  if (status === "loading") {
    return (
      <Box mt={3}>
        <InlineLoader />
      </Box>
    );
  }

  return (
    <Alert severity={status} onClose={onClose} sx={{ mt: 3 }}>
      <AlertMessage status={status} errorMessage={errorMessage} />
    </Alert>
  );
};

export default TestConnectionInfo;
