import CheckIcon from "@mui/icons-material/CheckRounded";
import LanguageIcon from "@mui/icons-material/LanguageRounded";
import { FundraisingStatus } from "../../../../../shared/api/fundraisingTypes";
import LanguageOffIcon from "../../../../icons/LanguageOffIcon";
import VisibleIcon from "../../../../icons/VisibleIcon";
import { FundraisingStatusTransition } from "./fundraisingsPageTypes";

const statusTransitions: FundraisingStatusTransition[] = [
  {
    from: "Draft",
    to: "Preview",
    label: "Publish to Preview",
    dialogActionLabel: "Publish",
    detailLines: [
      "You are about to publish the fundraising page in Preview mode.",
      "The page won't be available to investors yet, but you can inspect the portal as an investor contact.",
    ],
    Icon: VisibleIcon,
  },
  {
    from: "Preview",
    to: "Live",
    notificationsEnabled: true,
    label: "Publish to Live",
    dialogActionLabel: "Publish",
    detailLines: ["You are about to make fundraising Live and available for investors."],
    Icon: LanguageIcon,
  },
  {
    from: "Live",
    to: "Preview",
    label: "Unpublish to Preview",
    dialogActionLabel: "Unpublish",
    detailLines: ["The content will be returned in “Preview” and will not be available for viewing by users."],
    Icon: LanguageOffIcon,
  },
  {
    from: "Live",
    to: "Completed",
    label: "Mark as Completed",
    dialogActionLabel: "Complete",
    detailLines: [
      "You are about to finalize and complete the fundraising.",
      "This action will close the fundraising and users will no longer have access to it.",
    ],
    Icon: CheckIcon,
  },
  {
    from: "Completed",
    to: "Live",
    notificationsEnabled: true,
    label: "Publish to Live",
    dialogActionLabel: "Publish",
    detailLines: ["You are about to make fundraising Live and available for investors."],
    Icon: LanguageIcon,
  },
];

export const getAvailableStatusTransitions = (from: FundraisingStatus): FundraisingStatusTransition[] =>
  statusTransitions.filter((transition) => transition.from === from);
