import { createSvgIcon } from "@mui/material";

const SuccessIcon = createSvgIcon(
  <svg viewBox="0 0 80 80" fill="none">
    <path d="M29.5355 37.0006L26 40.5361L36.2011 50.7372L39.7366 47.2017L29.5355 37.0006Z" fill="#00A866" />
    <path d="M50.5361 30.0001L33 47.5361L36.5355 51.0716L54.0716 33.5356L50.5361 30.0001Z" fill="#00A866" />
    <circle cx="40" cy="40" r="26.5" stroke="#C7DBE5" strokeWidth="5" />
  </svg>,
  "SuccessIcon"
);

export default SuccessIcon;
