import { Box, Divider, Typography } from "@mui/material";
import { useCallback } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { useNotesContext } from "../../notes/NotesContext";
import NotesList from "../../notes/NotesList";

const ChangeRequestsNotesSection = () => {
  const { entityType, entityId } = useNotesContext();

  const fetchNotes = useCallback(async () => adminApi.getNotes(entityType, entityId), [entityId, entityType]);

  const [notes, fetchError] = useFetch(fetchNotes);

  if (fetchError) {
    logError(fetchError, "[ChangeRequestsNotesSection]");
    return <DataLoadingFailed title="Failed to load notes" />;
  }

  if (notes === undefined) {
    return <InlineLoader />;
  }

  return (
    <Box display="flex" width="100%" height="100%">
      <Divider orientation="vertical" flexItem />
      <Box width="100%">
        <Box px={2.5} pt={2.5} pb={2} width="100%">
          <Typography variant="h6">Notes</Typography>
        </Box>
        <NotesList notes={notes} />
      </Box>
    </Box>
  );
};

export default ChangeRequestsNotesSection;
