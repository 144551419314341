import { Stack, Typography } from "@mui/material";
import { DataImportInfo } from "../../../api/types/dataImportTypes";
import ImportSourceIcon from "./ImportSourceIcon";

interface Props {
  dataImport: DataImportInfo;
}

const ImportSourceLabel = ({ dataImport }: Props) => {
  return (
    <Stack direction="row" spacing={1} useFlexGap alignItems="center">
      <ImportSourceIcon importSource={dataImport.source} />
      <Typography noWrap>{dataImport.sourceTitle}</Typography>
    </Stack>
  );
};

export default ImportSourceLabel;
