import axios from "axios";
import { createApiResponse, createErrorApiResponse } from "../../shared/api/axiosHelper";
import { clientCodeHeader } from "../../shared/api/clientApiContext";
import { ApiResponse, PaginatedList } from "../../shared/api/types";
import { wait } from "../../shared/utilities/promiseHelper";
import { getBackendBaseUrl } from "../../shared/variables";
import { ApplicationLogs, LogsQueryParams } from "./types/logTypes";

const baseUri = getBackendBaseUrl("crm-connectors");

type ConnectorSetup = "HubSpotConnectorSetup" | "PipedriveConnectorSetup" | "SalesforceConnectorSetup";

export interface CrmConnectionInfo {
  environment?: string;
  createdBy?: string;
  createdAt: string;
  lastSyncAt?: string;
  connectedTo?: string;
  connectionError?: string;
  connectionState?: string;
}

export interface ConnectorSettings {
  syncSettings: CrmSyncSettings;
  syncEnabled: boolean;
  connectionState?: string;
  connectionError?: string;
}

export type CrmSyncObjectKey = "crmContactsSyncSettings" | "crmInvestorsSyncSettings";

export interface CrmSyncSettings {
  crmContactsSyncSettings: CrmObjectSyncSettings;
  crmInvestorsSyncSettings: CrmObjectSyncSettings;
  crmInvestorContactRelationSyncSettings: CrmInvestorContactRelationSyncSettings;
}

export interface CrmObjectSyncSettings {
  crmObjectName: string;
  crmApiObjectName: string;
  entriliaObjectName: string;
  entriliaApiObjectName: string;
  fieldsMapping: CrmFieldMapping[];
  accountTypeFilterField?: string;
  accountTypeFilterValue?: string;
  accountTypeFilterValueId?: string;
}

export interface CrmFieldMapping {
  entriliaField: string;
  crmField: string;
  isIdField: boolean;
}

interface CrmInvestorContactRelationSyncSettings {
  crmObjectApiName?: string;
}

export interface TestConnectionResult {
  success: boolean;
  message?: string;
}

export interface ObjectDefinition {
  fields: Record<string, ObjectFieldDefinition>;
}

export interface ObjectFieldDefinition {
  name: string;
  readOnly: boolean;
  allowedValues?: [{ value: string }];
}

export interface CrmSyncSettingsValidationResult {
  errors: ValidationError[];
}

export interface ValidationError {
  area: ValidationErrorArea;
  error: string;
  errorFieldName: string;
  errorObjectSide: ObjectSide;
}

export interface BooleanOperationResult {
  result: boolean;
}

type ValidationErrorArea = "Unknown" | "InvestorFieldsMapping" | "ContactFieldsMapping" | "Connection";

type ObjectSide = "NotRelevant" | "Entrilia" | "Crm" | "Both";

export interface CrmSyncLogs extends ApplicationLogs {
  investorsAdded: number;
  investorsUpdated: number;
  contactsAdded: number;
  contactsUpdated: number;
  contactsRemoved: number;
}

const getConnection = (connector: ConnectorSetup) => async () => {
  const { data } = await axios.get<ApiResponse<CrmConnectionInfo>>(`${baseUri}/${connector}/Connection`, {
    headers: clientCodeHeader(),
  });
  return data;
};

const deleteConnection = (connector: ConnectorSetup) => async (): Promise<ApiResponse<void>> => {
  await wait(250);

  return createErrorApiResponse({
    message: `Deleting a '${connector}' connection is not possible at the moment`,
    code: 1,
    type: "General",
  });
};

const testConnection = (connector: ConnectorSetup) => async () => {
  const { data } = await axios.post<ApiResponse<TestConnectionResult>>(
    `${baseUri}/${connector}/SyncSettings/TestConnection`,
    null,
    { headers: clientCodeHeader() }
  );
  return data;
};

const getConnectorSettings = (connector: ConnectorSetup) => async () => {
  const { data } = await axios.get<ApiResponse<ConnectorSettings>>(`${baseUri}/${connector}/ConnectorSettings`, {
    headers: clientCodeHeader(),
  });
  return data;
};

const postDataSyncEnabled = (connector: ConnectorSetup) => async (enabled: boolean) => {
  const { data } = await axios.post<ApiResponse<ConnectorSettings>>(
    `${baseUri}/${connector}/SyncSettings/DataSyncEnabled`,
    { enabled },
    { headers: clientCodeHeader() }
  );
  return data;
};

const getOurObjectNames = (connector: ConnectorSetup) => async () => {
  const { data } = await axios.get<ApiResponse<string[]>>(`${baseUri}/${connector}/Objects/Entrilia`, {
    headers: clientCodeHeader(),
  });
  return data;
};

const getCrmObjectNames = (connector: ConnectorSetup) => async () => {
  const { data } = await axios.get<ApiResponse<string[]>>(`${baseUri}/${connector}/Objects/Crm`, {
    headers: clientCodeHeader(),
  });
  return data;
};

const getOurObjectDefinitions = (connector: ConnectorSetup) => async (objectName: string) => {
  const { data } = await axios.get<ApiResponse<ObjectDefinition>>(
    `${baseUri}/${connector}/ObjectDefinitions/Entrilia/${objectName}`,
    { headers: clientCodeHeader() }
  );
  return data;
};

const getCrmObjectDefinitions = (connector: ConnectorSetup) => async (objectName: string) => {
  const { data } = await axios.get<ApiResponse<ObjectDefinition>>(
    `${baseUri}/${connector}/ObjectDefinitions/Crm/${objectName}`,
    { headers: clientCodeHeader() }
  );
  return data;
};

const validateSyncSettings = (connector: ConnectorSetup) => async (syncSettings: CrmSyncSettings) => {
  const { data } = await axios.post<ApiResponse<CrmSyncSettingsValidationResult>>(
    `${baseUri}/${connector}/SyncSettings/Validate`,
    syncSettings,
    { headers: clientCodeHeader() }
  );
  return data;
};

const postSyncSettings = (connector: ConnectorSetup) => async (syncSettings: CrmSyncSettings) => {
  const { data } = await axios.post<ApiResponse<CrmSyncSettings>>(
    `${baseUri}/${connector}/SyncSettings`,
    syncSettings,
    { headers: clientCodeHeader() }
  );
  return data;
};

const getExecutionLogs = (connector: ConnectorSetup) => async (params: LogsQueryParams) => {
  const { data } = await axios.get<ApiResponse<PaginatedList<CrmSyncLogs>>>(`${baseUri}/${connector}/ExecutionLogs`, {
    params,
    headers: clientCodeHeader(),
  });
  return data;
};

const startFullResyncJob = (connector: ConnectorSetup) => async () => {
  const { data } = await axios.post<ApiResponse<BooleanOperationResult>>(
    `${baseUri}/${connector}/start-full-resync-job`,
    {},
    { headers: clientCodeHeader() }
  );
  return data;
};

export type ObjectDefinitions = Record<string, ObjectDefinition>;

const getObjects = async (
  getObjectNames: () => Promise<ApiResponse<string[]>>,
  getObjectDefinitions: (objectName: string) => Promise<ApiResponse<ObjectDefinition>>
): Promise<ApiResponse<ObjectDefinitions>> => {
  const { data: objectNames, success, error } = await getObjectNames();
  if (!success) {
    return { success, error };
  }

  const definitionResponses = await Promise.all(objectNames.map((objectName) => getObjectDefinitions(objectName)));
  const allSucceeded = definitionResponses.every((r) => r.success);
  if (!allSucceeded) {
    const error = definitionResponses.find((r) => r.error)?.error;
    return { success: allSucceeded, error };
  }

  const data = objectNames.reduce<ObjectDefinitions>((result, name, i) => {
    const definition = definitionResponses[i]?.data;
    return definition ? { ...result, [name]: definition } : { ...result };
  }, {});

  return createApiResponse(data);
};

const getOurObjects = (connector: ConnectorSetup) => async () =>
  getObjects(getOurObjectNames(connector), getOurObjectDefinitions(connector));

const getCrmObjects = (connector: ConnectorSetup) => async () =>
  getObjects(getCrmObjectNames(connector), getCrmObjectDefinitions(connector));

const createApi = (connector: ConnectorSetup) => ({
  getConnection: getConnection(connector),
  deleteConnection: deleteConnection(connector),
  testConnection: testConnection(connector),
  getConnectorSettings: getConnectorSettings(connector),
  postDataSyncEnabled: postDataSyncEnabled(connector),
  getOurObjectNames: getOurObjectNames(connector),
  getCrmObjectNames: getCrmObjectNames(connector),
  getOurObjectDefinitions: getOurObjectDefinitions(connector),
  getCrmObjectDefinitions: getCrmObjectDefinitions(connector),
  validateSyncSettings: validateSyncSettings(connector),
  postSyncSettings: postSyncSettings(connector),
  getExecutionLogs: getExecutionLogs(connector),
  getOurObjects: getOurObjects(connector),
  getCrmObjects: getCrmObjects(connector),
  startFullResyncJob: startFullResyncJob(connector),
});

const crmConnectorsApi = {
  salesforce: createApi("SalesforceConnectorSetup"),
  hubspot: createApi("HubSpotConnectorSetup"),
};

export type CrmId = keyof typeof crmConnectorsApi;

export default crmConnectorsApi;
