import InvoicesLineWarningTitleItem from "./InvoicesLineWarningTitleItem";
import { useLocalization } from "../../../../../../hooks/useLocalization";

interface Props {
  description: { validationError?: string; isLowConfident?: boolean };
  account: { validationError?: string; isLowConfident?: boolean };
  amount: { validationError?: string; isLowConfident?: boolean };
}

const InvoicesLineWarningTitle = ({ account, amount, description }: Props) => {
  const {
    invoices: { low_confidence },
  } = useLocalization();

  const fields = [
    { name: "Description", data: description },
    { name: "Account", data: account },
    { name: "Amount", data: amount },
  ];

  const hasValidationErrors = fields.some((field) => field.data.validationError);
  const lowConfidence = fields.map((field) => (field.data.isLowConfident ? field.name : "")).filter(Boolean);

  return (
    <>
      {hasValidationErrors &&
        fields.map(({ name, data }) => {
          if (hasValidationErrors && data.validationError) {
            return <InvoicesLineWarningTitleItem key={name} fieldName={name} message={data.validationError} />;
          }
          return null;
        })}
      {!hasValidationErrors && lowConfidence.length > 0 && (
        <InvoicesLineWarningTitleItem fieldName={lowConfidence.join(", ")} message={low_confidence} />
      )}
    </>
  );
};

export default InvoicesLineWarningTitle;
