import AddIcon from "@mui/icons-material/AddRounded";
import { Button } from "@mui/material";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import { useFundraisingsPageContext } from "./FundraisingsPageContext";

const FundraisingsPageHeader = () => {
  const { hasEditPermissions, onCreate } = useFundraisingsPageContext();

  return (
    <GeneralPageHeader title="Fundraising" hideBorderLine>
      {hasEditPermissions && (
        <Button variant="contained" startIcon={<AddIcon />} onClick={onCreate}>
          New Fundraising
        </Button>
      )}
    </GeneralPageHeader>
  );
};

export default FundraisingsPageHeader;
