import { Stack } from "@mui/material";
import { PropsWithChildren } from "react";

const VerticalNavigationWrapper = ({ children }: PropsWithChildren) => (
  <Stack spacing={2} p={2.5} overflow="auto" sx={(t) => ({ width: t.spacing(38) })}>
    {children}
  </Stack>
);

export default VerticalNavigationWrapper;
