import { Alert, Stack, Typography } from "@mui/material";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";
import CategoriesEditor from "./edit-categories/CategoriesEditor";

const FundraisingCategoriesConfig = () => {
  const { isContentEditable, fundraisingCategories, allCategories, refreshCategories, refreshFundraising } =
    useFundraisingDetailsPageContext();

  const maxCategorySortOrder = Math.max(...allCategories.map((c) => c.sortOrder));

  const handleCategoriesUpdated = () => {
    refreshCategories();
    refreshFundraising();
  };

  return (
    <Stack spacing={3} flex={1} pt={2.5}>
      <Stack spacing={0.5} pt={2}>
        <Typography variant="subtitle1">Access Categories</Typography>
        <Typography variant="caption" color="text.secondary">
          Define categories to control contact access to documents.
        </Typography>
      </Stack>

      <Alert severity="info">
        Access categories are shared across all fundraising data rooms. Any changes to name or order will apply to all
        active fundraisings.
      </Alert>

      <CategoriesEditor
        categories={fundraisingCategories}
        maxCategorySortOrder={maxCategorySortOrder}
        readOnly={!isContentEditable}
        onUpdated={handleCategoriesUpdated}
      />
    </Stack>
  );
};

export default FundraisingCategoriesConfig;
