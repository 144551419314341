import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { CrmSyncLogs } from "../../../../api/crmConnectorsApi";
import LogDetailsDialog from "../../../common/syncLogs/LogDetailsDialog";
import LogStatusIcon from "../../../common/syncLogs/LogStatusIcon";
import { LogsTableRowData, getRows } from "./logsTableDataProvider";

interface Props {
  logItems: CrmSyncLogs[];
}

interface LogsTableRowProps extends LogsTableRowData {
  onView: (details: CrmSyncLogs) => void;
}

const LogsTableHeadRow = () => (
  <TableRow>
    <TableCell>Status</TableCell>
    <TableCell>Completed</TableCell>
    <TableCell align="right">Contacts</TableCell>
    <TableCell align="right">Investors</TableCell>
    <TableCell></TableCell>
  </TableRow>
);

const LogsTableRow = ({ severity, completed, contacts, investors, details, onView }: LogsTableRowProps) => {
  const handleViewClick = () => onView(details);

  return (
    <TableRow>
      <TableCell width="10%">
        <LogStatusIcon severity={severity} />
      </TableCell>
      <TableCell>{completed}</TableCell>
      <TableCell align="right">{contacts}</TableCell>
      <TableCell align="right">{investors}</TableCell>
      <TableCell align="right" width="15%" padding="checkbox">
        <Button variant="text" color="primary" onClick={handleViewClick}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

const LogsTable = ({ logItems }: Props) => {
  const [logDetails, setLogDetails] = useState<CrmSyncLogs>();
  const [showLogDetails, setShowLogDetails] = useState(false);

  const handleViewDetails = (logDetails: CrmSyncLogs) => {
    setLogDetails(logDetails);
    setShowLogDetails(true);
  };

  const handleLogDetailsClose = () => setShowLogDetails(false);

  const rows = getRows(logItems);

  return (
    <>
      <TableContainer sx={(theme) => ({ maxHeight: theme.spacing(70) })}>
        <Table stickyHeader>
          <TableHead>
            <LogsTableHeadRow />
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <LogsTableRow {...row} onView={handleViewDetails} key={row.key} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LogDetailsDialog open={showLogDetails} onClose={handleLogDetailsClose} logDetails={logDetails} />
    </>
  );
};

export default LogsTable;
