import { WorkflowStatus } from "../../../../shared/api/changeRequestTypes";
import StatusChip from "../../../../shared/components/StatusChip";

interface Props {
  workflowStatus: WorkflowStatus;
}

const WorkflowStatusTag = ({ workflowStatus }: Props) => {
  switch (workflowStatus) {
    case "InProgress":
      return <StatusChip label="Pending" withDot color={(t) => t.palette.warning.main} />;
    case "Completed":
      return <StatusChip label="Completed" withDot color={(t) => t.palette.success.main} />;
    case "Rejected":
      return <StatusChip label="Rejected" withDot color={(t) => t.palette.error.main} />;
    case "Cancelled":
      return <StatusChip label="Canceled" withDot color={(t) => t.palette.text.secondary} />;
    default:
      return null;
  }
};

export default WorkflowStatusTag;
