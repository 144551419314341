import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/EditOutlined";
import {
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { convertISODate } from "../../../../shared/utilities/dateUtils";
import { ApiGatewaySubscription, ApiGatewaySubscriptionState, ApiGatewaySubscriptionType } from "../../../api/adminApi";

interface BodyRowProps {
  username: string;
  email: string;
  type: ApiGatewaySubscriptionType;
  description?: string;
  createdByUsername?: string;
  createdAt: string;
  state: ApiGatewaySubscriptionState;
  disableStateToggle?: boolean;
  showActions: boolean;
  onEditClick: () => void;
  onToggleState: (newState: ApiGatewaySubscriptionState) => void;
  onDeleteClick: () => void;
}

interface Props {
  subscriptions: ApiGatewaySubscription[];
  updatingSubscriptionIds: string[];
  readOnly?: boolean;
  onEditClick: (subscription: ApiGatewaySubscription) => void;
  onToggleState: (subscription: ApiGatewaySubscription, newState: ApiGatewaySubscriptionState) => void;
  onDeleteClick: (subscription: ApiGatewaySubscription) => void;
}

const HeadRow = ({ showActions }: { showActions: boolean }) => (
  <TableRow>
    <TableCell>User</TableCell>
    <TableCell>Type</TableCell>
    <TableCell>Description</TableCell>
    <TableCell>Created by</TableCell>
    <TableCell>Created at</TableCell>
    <TableCell align="center">State</TableCell>
    {showActions && <TableCell></TableCell>}
  </TableRow>
);

const BodyCell = (props: TableCellProps) => <TableCell {...props} sx={(t) => ({ p: t.spacing(2) })} />;

const formatSubscriptionType = (type: ApiGatewaySubscriptionType) => {
  switch (type) {
    case "EntriliaApi":
      return "Entrilia API";
    case "Qashqade":
      return "Qashqade Integration API";
    case "AllocatedGlAndBankAccountLedgerApi":
      return "Allocated General Ledger and Bank Account Ledger";
    case "Cascata":
      return "Cascata Integration API";
    default:
      return type;
  }
};

const BodyRow = ({
  username,
  email,
  type,
  description,
  createdByUsername,
  createdAt,
  state,
  disableStateToggle,
  showActions,
  onEditClick,
  onToggleState,
  onDeleteClick,
}: BodyRowProps) => {
  return (
    <TableRow hover>
      <BodyCell>
        <Stack>
          <Typography variant="subtitle2">{username}</Typography>
          <Typography variant="caption" color="text.secondary">
            {email}
          </Typography>
        </Stack>
      </BodyCell>
      <BodyCell width="120">
        <Typography>{formatSubscriptionType(type)}</Typography>
      </BodyCell>
      <BodyCell>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 2,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {description}
        </Typography>
      </BodyCell>
      <BodyCell>
        <Typography noWrap>{createdByUsername}</Typography>
      </BodyCell>
      <BodyCell width="120">
        <Typography color="text.secondary">{convertISODate(createdAt)}</Typography>
      </BodyCell>
      <BodyCell width="60">
        <Switch
          checked={state === "Active"}
          onChange={(e) => onToggleState(e.target.checked ? "Active" : "Suspended")}
          disabled={disableStateToggle}
        />
      </BodyCell>
      {showActions && (
        <BodyCell width="10%" className="ApiGatewaySubscriptionsTable-actions">
          <Stack direction="row" spacing={1}>
            <Tooltip title="Edit" arrow>
              <IconButton onClick={onEditClick}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <IconButton color="error" onClick={onDeleteClick}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </BodyCell>
      )}
    </TableRow>
  );
};

const EmptyTableRow = () => {
  return (
    <TableRow sx={{ height: 120 }}>
      <TableCell align="center" colSpan={5} sx={{ borderBottom: 0 }}>
        No subscriptions yet
      </TableCell>
    </TableRow>
  );
};

const ApiGatewaySubscriptionsTable = ({
  subscriptions,
  updatingSubscriptionIds,
  readOnly,
  onEditClick,
  onToggleState,
  onDeleteClick,
}: Props) => {
  const handleEditClick = (subscription: ApiGatewaySubscription) => () => onEditClick(subscription);

  const handleToggleState = (subscription: ApiGatewaySubscription) => (newState: ApiGatewaySubscriptionState) =>
    onToggleState(subscription, newState);

  const handleDeleteClick = (subscription: ApiGatewaySubscription) => () => onDeleteClick(subscription);

  return (
    <TableContainer>
      <Table
        sx={{
          ".MuiTableRow-root": {
            "&:hover": {
              ".MuiTableCell-root.ApiGatewaySubscriptionsTable-actions": {
                button: { visibility: readOnly ? "hidden" : "visible" },
              },
            },
          },
          ".MuiTableCell-root.ApiGatewaySubscriptionsTable-actions": {
            button: { visibility: "hidden" },
          },
        }}
      >
        <TableHead>
          <HeadRow showActions={!readOnly} />
        </TableHead>
        <TableBody>
          {subscriptions.length === 0 ? (
            <EmptyTableRow />
          ) : (
            subscriptions.map((subscription) => (
              <BodyRow
                {...subscription}
                key={subscription.id}
                disableStateToggle={readOnly || updatingSubscriptionIds.includes(subscription.id)}
                showActions={!readOnly}
                onEditClick={handleEditClick(subscription)}
                onToggleState={handleToggleState(subscription)}
                onDeleteClick={handleDeleteClick(subscription)}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApiGatewaySubscriptionsTable;
