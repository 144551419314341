import { Stack, Typography } from "@mui/material";
import MonitorWithBusinessAppIcon from "../../icons/MonitorWithBusinessAppIcon";

interface Props {
  title: string;
}

const ConsentAgreementDefaultContent = ({ title }: Props) => (
  <Stack spacing={1} justifyContent="space-evenly" alignItems="center">
    <MonitorWithBusinessAppIcon />
    <Typography variant="h5">{title}</Typography>
  </Stack>
);

export default ConsentAgreementDefaultContent;
