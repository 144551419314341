import { Alert, Container, Stack } from "@mui/material";
import { addHours, startOfToday } from "date-fns";
import { useReducer, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import { formatString } from "../../../../shared/utilities/stringFormatter";
import adminApi, { BackupMasterConfiguration } from "../../../api/adminApi";
import { useClientContext } from "../../../context/ClientContext";
import { useLocalization } from "../../../hooks/useLocalization";
import DataBackupIcon from "../../../icons/DataBackupIcon";
import IntegrationHeader from "../integration/IntegrationHeader";
import DataBackupSetup from "./DataBackupSetup";
import DataBackupSyncActions from "./DataBackupSyncActions";
import DataBackupTabs from "./DataBackupTabs";
import { getInitialState, reducer } from "./dataBackupPageState";
import BackupNowDialog from "./dialogs/BackupNowDialog";

const makeTestConnection = withErrorHandling(adminApi.makeDataBackupTestConnection);
const toggleDailySync = withErrorHandling(adminApi.setDataBackupDailySync);
const updateConfiguration = withErrorHandling(adminApi.setDataBackupConfiguration);

const buildConfiguration = (configuration: BackupMasterConfiguration): BackupMasterConfiguration => {
  return {
    ...configuration,
    hour: addHours(startOfToday(), configuration.hour || 0).getUTCHours(),
  };
};

export const DataBackupPage = () => {
  const { dataBackup: locale } = useLocalization();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { dataBackupApp, updateUserData, hasPermissions } = useClientContext();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [showBackupDialog, setShowBackupDialog] = useState(false);

  const canManageDataBackup = hasPermissions(["ManageClientIntegrations"]);

  const [connectionStateResult, connectionStateFetchError] = useFetch(adminApi.getDataBackupConnectionState, (data) => {
    dispatch({ type: "SetConnectionState", payload: { ...data } });
  });

  const [configurationResult, configurationFetchError] = useFetch(adminApi.getDataBackupConfiguration, (data) => {
    dispatch({ type: "SetConfiguration", payload: { ...data } });
  });

  const [configurationOptionsResult, configurationOptionsFetchError] = useFetch(
    adminApi.getDataBackupConfigurationOptions,
    (data) => {
      dispatch({ type: "SetConfigurationOptions", payload: data.awsRegionEndpoints });
    }
  );

  const handleTestConnection = async () => {
    dispatch({ type: "SetActiveProcess", payload: "testingConnection" });
    const [testConnectionResponse, error] = await makeTestConnection();
    if (!error && testConnectionResponse.success) {
      dispatch({ type: "SetAlert", payload: { severity: "info", message: locale.test_connection_success } });
    } else {
      dispatch({ type: "SetAlert", payload: { severity: "error", message: locale.test_connection_error } });
    }
    dispatch({ type: "ResetActiveProcess" });
  };

  const handleToggleDailyBackup = async () => {
    dispatch({ type: "SetActiveProcess", payload: "togglingDailySync" });
    const value = !state.enabled;
    const [toggleConnectionResponse, error] = await toggleDailySync({ enabled: value });
    if (!error && value === toggleConnectionResponse.enabled) {
      dispatch({ type: "SetConnectionState", payload: { ...toggleConnectionResponse } });
      sendNotification(formatString(locale.backup_toggle_success, value ? "enabled" : "disabled"));
      updateUserData();
    } else {
      sendNotificationError(formatString(locale.backup_toggle_error, value ? "enabling" : "disabling"));
    }
    dispatch({ type: "ResetActiveProcess" });
  };

  const handleSaveConfiguration = async () => {
    if (!state.configuration) return;
    dispatch({ type: "SetActiveProcess", payload: "updatingConfiguration" });
    const [, error] = await updateConfiguration(buildConfiguration(state.configuration));
    if (!error) {
      sendNotification(locale.save_configuration_success);
      dispatch({ type: "ResetConfigurationDirty" });
    } else {
      sendNotificationError(locale.save_configuration_error);
    }
    dispatch({ type: "ResetActiveProcess" });
  };

  if (connectionStateFetchError || configurationFetchError || configurationOptionsFetchError) {
    return <DataLoadingFailed title="Could not load backup data" />;
  }

  if (!connectionStateResult || !configurationResult || !configurationOptionsResult) {
    return <InlineLoader />;
  }

  return (
    <>
      <IntegrationHeader
        title="Data Backup"
        Icon={DataBackupIcon}
        text={locale.promotion_text}
        appStatus={dataBackupApp?.status}
      />
      <Container maxWidth={false} disableGutters sx={{ position: "relative" }}>
        <Stack px={3} spacing={3} mt={2}>
          <DataBackupSetup
            lastChangedBy={state?.lastChangedBy}
            lastChangedOn={state?.lastChangedOn}
            lastBackupDate={state?.lastBackupDate}
            isLatestBackupSuccessful={state?.isLatestBackupSuccessful}
          />
          <DataBackupSyncActions
            dailyBackupEnabled={state.enabled}
            onBackupNow={() => setShowBackupDialog(true)}
            onToggleDailyBackup={handleToggleDailyBackup}
            onTestConnection={handleTestConnection}
            testConnectionProcessing={state.activeProcess === "testingConnection"}
            toggleDailyBackupProcessing={state.activeProcess === "togglingDailySync"}
            readonly={!canManageDataBackup}
            disabled={state.isConfigurationDirty}
          />
          {state.alertMessage && (
            <Alert severity={state.alertMessage.severity} onClose={() => dispatch({ type: "SetAlert" })}>
              {state.alertMessage.message}
            </Alert>
          )}
          {state.configuration && state.configurationOptions && (
            <DataBackupTabs
              state={state}
              onConfigurationSave={handleSaveConfiguration}
              onConfigurationChange={(data) => dispatch({ type: "UpdateConfiguration", payload: data })}
              readonly={!canManageDataBackup}
            />
          )}
        </Stack>
        {state.activeProcess && <InlineLoader overlay sx={{ pointerEvents: "auto", top: 0, left: 0, zIndex: 2 }} />}
      </Container>
      {showBackupDialog && (
        <BackupNowDialog
          onCancel={() => setShowBackupDialog(false)}
          onBackupStarted={() => {
            dispatch({ type: "SetAlert", payload: { severity: "info", message: locale.backup_started } });
            setShowBackupDialog(false);
          }}
          onOperationInProgress={() => {
            dispatch({ type: "SetAlert", payload: { severity: "error", message: locale.backup_in_progress } });
            setShowBackupDialog(false);
          }}
          onConnectionFailed={(msg) => {
            dispatch({ type: "SetAlert", payload: { severity: "error", message: msg } });
            setShowBackupDialog(false);
          }}
        />
      )}
    </>
  );
};
