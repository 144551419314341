import qs from "qs";
import { BusinessCentralSettings } from "../api/types/clientTypes";
import variables from "../variables";

export const getBusinessCentralUrl = (bcAppSettings: BusinessCentralSettings | undefined) => {
  if (!bcAppSettings) {
    return undefined;
  }

  const { tenantId, environment, companyName } = bcAppSettings;
  return `https://businesscentral.dynamics.com/${tenantId}/${environment}?${qs.stringify({ company: companyName })}`;
};

export const getInvestorPortalImpersonationUrl = (
  clientCode: string,
  subPath: string,
  userEmail?: string | undefined,
  fundraisingId?: string | undefined
): string => {
  const user = userEmail || (fundraisingId ? `for-fundraising:${fundraisingId}` : "") || "any";
  const queryParams = { impersonation: "true", user };
  return `${variables.IR_PORTAL_URI}/${clientCode}${subPath}?${qs.stringify(queryParams)}`;
};

export const getBusinessCentralTransactionUrl = (
  bcAppSettings: BusinessCentralSettings | undefined,
  businessCentralTransactionNo: string | undefined,
  businessCentralPostedTransactionNo: string | undefined,
  transactionTypeCode: string | undefined
) => {
  if (!bcAppSettings || !businessCentralTransactionNo || !transactionTypeCode) {
    return undefined;
  }

  const pageNo = businessCentralPostedTransactionNo !== undefined ? 50428 : 50425;
  const transactionNo = businessCentralPostedTransactionNo || businessCentralTransactionNo;

  const { tenantId, environment, companyName } = bcAppSettings;
  const queryParams = {
    company: companyName,
    page: pageNo,
    filter: `'No.' IS '${transactionNo}' AND 'Transaction Type Code' IS '${transactionTypeCode}'`,
  };
  return `https://businesscentral.dynamics.com/${tenantId}/${environment}?${qs.stringify(queryParams)}`;
};
