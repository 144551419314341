import { useState } from "react";
import ConfirmOperationDialog, { ConfirmDialogData } from "../components/common/ConfirmOperationDialog";

function useConfirmationDialog<T>() {
  const [confirmDialogData, setConfirmDialogData] = useState<ConfirmDialogData<T> | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isOpen = confirmDialogData !== undefined;

  return {
    isOpenConfirmationDialog: isOpen,
    closeConfirmationDialog: () => setConfirmDialogData(undefined),
    confirmDialogData,
    setConfirmDialogData,
    ConfirmationDialogComponent: (
      <ConfirmOperationDialog
        open={isOpen}
        onClose={() => setConfirmDialogData(undefined)}
        onConfirm={() => {
          setIsLoading(true);
          confirmDialogData?.confirmCallback?.(confirmDialogData.ids);
          setConfirmDialogData(undefined);
          setIsLoading(false);
        }}
        data={confirmDialogData}
        isLoading={isLoading}
      />
    ),
  };
}

export default useConfirmationDialog;
