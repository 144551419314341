import { Grid, Paper, Stack, Typography } from "@mui/material";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import { SelectedCompany } from "./hooks/SelectedCompany";
import { PropsWithChildren } from "react";
import { getItemSx } from "./utils/getItemSx";

export interface CompanyItemProps {
  company: SelectedCompany;
  companyBranding: ListItemData | undefined;
  active?: boolean;
  selectable?: boolean;
  Action?: JSX.Element;
  inProgress: boolean;
  onSelected?: (company: SelectedCompany) => void;
}

export function CompanySingleReportListItem({
  company,
  companyBranding,
  active,
  selectable,
  children,
  Action,
  inProgress,
  onSelected,
}: PropsWithChildren<CompanyItemProps>) {
  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        ...getItemSx(selectable, active, inProgress, theme),
      })}
      onClick={() => selectable && onSelected?.(company)}
    >
      <Stack>
        <Stack direction="row" sx={{ pr: 1, alignItems: "center" }}>
          <Grid
            container
            sx={{
              pr: 1,
              pl: 2,
              pt: 1.5,
              spacing: 1.5,
              alignItems: "center",
              direction: "row",
            }}
          >
            <Stack direction="row" spacing={1.5} alignItems="center" flexGrow={1}>
              <img src={companyBranding?.iconSrc} alt="" width={24} height={24} />
              <Typography variant="subtitle1">{companyBranding?.label}</Typography>
            </Stack>
          </Grid>
          {Action}
        </Stack>
        <Grid container>
          <Grid
            container
            sx={{
              flex: 1,
              pl: 2,
              pr: 1.5,
              pb: 0.5,
              height: (theme) => theme.spacing(4.8),
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {children}
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
}
