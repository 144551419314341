import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";

interface Props {
  value: number;
  onChange: (value: number) => void;
}

const PeriodFilter = ({ value, onChange }: Props) => {
  const options = [
    { optionValue: 0, optionText: "All Time" },
    { optionValue: 1, optionText: "Today" },
    { optionValue: 7, optionText: "Last Week" },
  ];

  const handleChange = (e: SelectChangeEvent<number>) => onChange(+e.target.value);

  return (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      <Typography variant="caption" color="text.secondary">
        Period
      </Typography>
      <Select<number>
        value={value}
        onChange={handleChange}
        disableUnderline
        variant="standard"
        renderValue={(val) => (
          <Typography variant="body2">{options.find((o) => o.optionValue === val)?.optionText}</Typography>
        )}
      >
        {options.map(({ optionValue, optionText }) => (
          <MenuItem key={optionValue.toString()} value={optionValue}>
            {optionText}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default PeriodFilter;
