import { useClientContext } from "../../../context/ClientContext";
import ClientsPage from "./ClientsPage";
import ManagerHomePage from "./ManagerHomePage";

const HomePage = () => {
  const { isFundAdmin } = useClientContext();
  return isFundAdmin ? <ClientsPage /> : <ManagerHomePage />;
};

export default HomePage;
