import { Box, Button, Dialog, DialogActions, Grid, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import DialogHeader from "../../../../../../shared/components/dialog/DialogHeader";
import { useLocalization } from "../../../../../hooks/useLocalization";

type ChoiceType = "copy" | "override";

interface Props {
  onContinue: (override: boolean) => void;
  onCancel: () => void;
}

export function ReportOverridePrompt({ onCancel, onContinue }: Props) {
  const { reports_copyto: locale } = useLocalization();
  const [choice, setChoice] = useState<ChoiceType>("copy");

  return (
    <Dialog
      open
      hideBackdrop
      PaperProps={{
        sx: {
          p: 0.5,
          width: 508,
          height: 350,
          boxShadow:
            "0px 3px 3px -2px rgba(16, 24, 40, 0.20), 0px 3px 4px 0px rgba(16, 24, 40, 0.14), 0px 1px 8px 0px rgba(16, 24, 40, 0.12)",
        },
      }}
    >
      <DialogHeader text={locale.duplicated_names.header} onClose={onCancel} />
      <Grid container sx={{ px: 2.5, py: 1 }}>
        <Typography color={"secondary"}>{locale.duplicated_names.question}</Typography>
      </Grid>
      <Grid container sx={{ px: 2.5, pt: 1.5, flex: 1 }}>
        <RadioGroup sx={{ flex: 1, gap: 1.5 }} value={choice} onChange={(_, value) => setChoice(value as ChoiceType)}>
          <CopyItem selectedChoice={choice} onClick={() => setChoice("copy")} />
          <OverrideItem selectedChoice={choice} onClick={() => setChoice("override")} />
        </RadioGroup>
      </Grid>
      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => onContinue(choice === "override")}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface ItemProps {
  selectedChoice: ChoiceType;
  onClick: () => void;
}

function CopyItem(props: ItemProps) {
  const { reports_copyto: locale } = useLocalization();
  return (
    <ItemWrapper value="copy" {...props}>
      <Typography variant="subtitle2">{locale.duplicated_names.copy_text_1}</Typography>
      <Typography color={"secondary"}>{locale.duplicated_names.copy_text_2}</Typography>
      <Stack direction={"row"} spacing={0.5}>
        <Typography color={"secondary"}>{locale.duplicated_names.copy_text_3}</Typography>
        <Typography>{locale.duplicated_names.copy_text_4}</Typography>
      </Stack>
    </ItemWrapper>
  );
}

function OverrideItem(props: ItemProps) {
  const { reports_copyto: locale } = useLocalization();
  return (
    <ItemWrapper value="override" {...props}>
      <Typography variant="subtitle2">{locale.duplicated_names.override_text_1}</Typography>
      <Typography color={"secondary"}>{locale.duplicated_names.override_text_2}</Typography>
    </ItemWrapper>
  );
}

interface ItemWrapperProps extends ItemProps {
  value: ChoiceType;
}

function ItemWrapper({ value, selectedChoice, onClick, children }: PropsWithChildren<ItemWrapperProps>) {
  const active = selectedChoice === value;

  return (
    <Grid
      container
      sx={{
        pl: 1.5,
        pr: 1,
        py: 1,
        borderRadius: 1,
        border: "1px solid",
        borderColor: "divider",
        alignItems: "center",
        ...(active && { borderColor: "primary.main" }),
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Stack flex={1}>{children}</Stack>
      <Box>
        <Radio value={value} />
      </Box>
    </Grid>
  );
}
