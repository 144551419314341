import { Box, Stack } from "@mui/material";

interface Props {
  title: string;
  details?: string;
}

const DetailedTooltipTitle = ({ title, details }: Props) => (
  <Stack spacing={1}>
    <Box>{title}</Box>
    {details && <Box sx={{ fontWeight: 400 }}>{details}</Box>}
  </Stack>
);

export default DetailedTooltipTitle;
