import { Typography } from "@mui/material";

interface Props {
  fieldName: string;
  message?: string;
}

const InvoicesLineWarningTitleItem = ({ fieldName, message }: Props) => {
  return (
    <>
      <Typography variant="subtitle2" color="white" fontSize="inherit">
        {fieldName}
      </Typography>
      <Typography color="text.disabled" fontSize="inherit">
        {message}
      </Typography>
    </>
  );
};

export default InvoicesLineWarningTitleItem;
