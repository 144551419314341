import { SvgIconProps } from "@mui/material";
import { ComponentType } from "react";
import DefaultFileIcon from "../icons/fileFormats/DefaultFileIcon";
import ExcelIcon from "../icons/fileFormats/ExcelIcon";
import ImageFileIcon from "../icons/fileFormats/ImageFileIcon";
import PdfIcon from "../icons/fileFormats/PdfIcon";
import PowerPointIcon from "../icons/fileFormats/PowerPointIcon";
import WordIcon from "../icons/fileFormats/WordIcon";

interface Props extends SvgIconProps {
  fileExtension: string;
}

const fileExtensionsMap = new Map<string, ComponentType<SvgIconProps>>([
  [".pdf", PdfIcon],
  [".docx", WordIcon],
  [".doc", WordIcon],
  [".xls", ExcelIcon],
  [".xlsx", ExcelIcon],
  [".xlsm", ExcelIcon],
  [".csv", ExcelIcon],
  [".pptx", PowerPointIcon],
  [".ppt", PowerPointIcon],
  [".png", ImageFileIcon],
  [".jpg", ImageFileIcon],
  [".jpeg", ImageFileIcon],
  [".webp", ImageFileIcon],
]);

const FileIcon = ({ fileExtension, ...props }: Props) => {
  const Icon = fileExtensionsMap.get(fileExtension) ?? DefaultFileIcon;
  return <Icon {...props} />;
};

export default FileIcon;
