import { Box, Stack } from "@mui/material";

interface Props {
  width: number;
  left?: boolean;
  right?: boolean;
}

const ArrowEnd = ({ transform }: { transform: string }) => (
  <Box
    component="span"
    sx={(theme) => ({
      display: "inline-block",
      width: "5px",
      height: "5px",
      borderTop: "1px solid",
      borderRight: "1px solid",
      borderColor: theme.palette.secondary.main,
      transform,
    })}
  />
);

const Arrows = ({ width, left, right }: Props) => (
  <Stack direction="row" spacing={0} alignItems="center">
    {left && <ArrowEnd transform="rotate(-135deg)" />}
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(width),
        height: "1px",
        mx: left ? "-5px" : "0",
        mr: right ? "-5px" : "0",
      })}
    />
    {right && <ArrowEnd transform="rotate(45deg)" />}
  </Stack>
);

export default Arrows;
