import { logError } from "../../../../../shared/logging";
import { CrmFieldMapping, CrmObjectSyncSettings, ObjectDefinitions } from "../../../../api/crmConnectorsApi";

export enum MappingType {
  None = "None",
  FromCrm = "FromCrm",
  FromCrmId = "FromCrmId",
}

export interface FieldMapping {
  entriliaFieldId: string;
  entriliaFieldName: string;
  crmFieldId: string;
  mappingType: MappingType;
}

export interface FieldMappingsState {
  mappings: FieldMapping[];
  crmFilterValue: string | undefined;
}

type Action =
  | {
      type: "UPDATE";
      payload: FieldMapping;
    }
  | {
      type: "UPDATE_CRM_FILTER";
      payload: string;
    };

export const getInitialState = (
  objectSyncSettings: CrmObjectSyncSettings,
  ourObjects: ObjectDefinitions,
  crmObjects: ObjectDefinitions
): FieldMappingsState => {
  const ourObjectDefinition = ourObjects[objectSyncSettings.entriliaObjectName];
  const crmObjectDefinition = crmObjects[objectSyncSettings.crmObjectName];

  if (ourObjectDefinition === undefined) {
    logError(
      `entriliaObjectName from SyncSettings ${objectSyncSettings.entriliaObjectName} not in Definition`,
      "CRMFieldMappings"
    );
    return {
      mappings: [],
      crmFilterValue: undefined,
    };
  }

  if (crmObjectDefinition === undefined) {
    logError(
      `crmObjectName from SyncSettings ${objectSyncSettings.crmObjectName} not in Definition`,
      "CRMFieldMappings"
    );
    return {
      mappings: [],
      crmFilterValue: undefined,
    };
  }

  const mappings: FieldMapping[] = Object.entries(ourObjectDefinition.fields)
    .filter(([, fieldDefinition]) => !fieldDefinition.readOnly)
    .map(([entriliaFieldId, fieldDefinition]) => {
      const entriliaFieldName = fieldDefinition.name;
      const existingMapping = objectSyncSettings.fieldsMapping.find((m) => m.entriliaField === entriliaFieldId);

      if (existingMapping === undefined) {
        return {
          entriliaFieldId,
          entriliaFieldName,
          crmFieldId: "",
          mappingType: MappingType.None,
        };
      }

      const crmFieldId = existingMapping.crmField;
      return {
        entriliaFieldId,
        entriliaFieldName,
        crmFieldId,
        mappingType: existingMapping.isIdField ? MappingType.FromCrmId : MappingType.FromCrm,
      };
    });

  const { accountTypeFilterValue: crmFilterValue } = objectSyncSettings;

  return {
    mappings,
    crmFilterValue,
  };
};

export const reducer = (state: FieldMappingsState, { type, payload }: Action): FieldMappingsState => {
  switch (type) {
    case "UPDATE": {
      const mappings = [...state.mappings];
      const updatedIndex = mappings.findIndex((m) => m.entriliaFieldId === payload.entriliaFieldId);
      if (updatedIndex >= 0) {
        mappings[updatedIndex] = payload;
      }
      return {
        mappings,
        crmFilterValue: state.crmFilterValue,
      };
    }
    case "UPDATE_CRM_FILTER": {
      return {
        mappings: state.mappings,
        crmFilterValue: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const getFieldsMapping = (state: FieldMappingsState): CrmFieldMapping[] =>
  state.mappings
    .filter((m) => m.mappingType !== MappingType.None)
    .map((m) => ({
      entriliaField: m.entriliaFieldId,
      crmField: m.crmFieldId,
      isIdField: m.mappingType === MappingType.FromCrmId,
    }));
