import { Typography } from "@mui/material";

interface Props {
  text: string;
}

const FundLevelDataName = ({ text }: Props) => (
  <Typography noWrap sx={{ ml: 5 }}>
    {text}
  </Typography>
);

export default FundLevelDataName;
