import { useCallback, useState } from "react";
import useEventListener from "./useEventListener";

type ChangeReason = "visibilitychange" | "focus" | "blur";

const useIsUserActive = (onChange?: (isUserActive: boolean, reason: ChangeReason) => void) => {
  const [isActive, setIsActive] = useState(true);

  const handleUpdate = useCallback(
    (reason: ChangeReason) => {
      const active = !document.hidden && document.hasFocus();
      if (isActive !== active) {
        onChange?.(active, reason);
      }
      setIsActive(active);
    },
    [isActive, onChange]
  );

  const handleVisibilityChange = useCallback(() => handleUpdate("visibilitychange"), [handleUpdate]);
  const handleFocus = useCallback(() => handleUpdate("focus"), [handleUpdate]);
  const handleBlur = useCallback(() => handleUpdate("blur"), [handleUpdate]);

  useEventListener(document, "visibilitychange", handleVisibilityChange);
  useEventListener(window, "focus", handleFocus);
  useEventListener(window, "blur", handleBlur);

  return isActive;
};

export default useIsUserActive;
