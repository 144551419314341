import { Box, Stack, Typography } from "@mui/material";
import { formatDurationInSeconds } from "../../../../../../shared/utilities/formatters";
import { AggregatedDocumentViews } from "../../../../../api/types/documentActivityTypes";
import StatTile from "../../../../common/StatTile";

interface Props {
  aggregatedDocumentViews: AggregatedDocumentViews;
}

const DocumentActivitySummary = ({ aggregatedDocumentViews }: Props) => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">Summary</Typography>
      <Box display="flex" gap={2}>
        <StatTile title="Views" value={aggregatedDocumentViews.totalViews.toLocaleString()} sx={{ flex: 1 }} />
        <StatTile title="Downloads" value={aggregatedDocumentViews.totalDownloads.toLocaleString()} sx={{ flex: 1 }} />
        <StatTile
          title="Total View Duration"
          value={formatDurationInSeconds(aggregatedDocumentViews.totalViewDuration)}
          sx={{ flex: 1 }}
        />
        <StatTile
          title="Total Completion"
          value={`${aggregatedDocumentViews.completionPercentage.toLocaleString()}%`}
          sx={{ flex: 1 }}
        />
      </Box>
    </Stack>
  );
};

export default DocumentActivitySummary;
