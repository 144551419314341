import { Stack, Typography } from "@mui/material";
import ColorEdit from "../../../../../shared/components/ColorEdit";

interface Props {
  title: string;
  subtitle: string;
  color: string;
  disabled?: boolean;
  onChange: (newColor: string) => void;
}

const ColorEditorSection = ({ disabled, title, subtitle, color, onChange }: Props) => (
  <Stack>
    <Stack direction="row" spacing={2} alignItems="center" sx={(theme) => ({ maxWidth: theme.spacing(89) })}>
      <Typography variant="subtitle1" flexGrow={1}>
        {title}
      </Typography>
      <Typography>{color.toUpperCase()}</Typography>
      <ColorEdit
        disabled={disabled}
        color={color}
        buttonSize={24}
        onColorChange={onChange}
        popover={{
          anchorOrigin: { vertical: "center", horizontal: "right" },
          transformOrigin: { vertical: "center", horizontal: "left" },
        }}
        showAchor="leftCenter"
      />
    </Stack>
    <Typography color="text.secondary">{subtitle}</Typography>
  </Stack>
);

export default ColorEditorSection;
