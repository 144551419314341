import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { IconButton, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import InlineItemsList from "../../../../../shared/components/InlineItemsList";
import StaticCircularProgress from "../../../../../shared/components/StaticCircularProgress";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { formatDurationInSeconds } from "../../../../../shared/utilities/formatters";
import { FundStructureDocument } from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import { useClientContext } from "../../../../context/ClientContext";
import AnalyticsIcon from "../../../../icons/AnalyticsIcon";
import CategoryTag from "../../../common/CategoryTag";
import TextHoverAction from "../../../common/TextHoverAction";
import DocumentCollectionStatusTag from "../../insights/DocumentCollectionStatusTag";

interface ActionsCellProps {
  row: FundStructureDocument;
  attachmentId: string | undefined;
  onNavigateToDocumentActivity?: (postMessageRequestId: string, attachmentId: string) => void;
  onDownloadClick: (row: FundStructureDocument) => void;
}

const ActionsCell = ({ row, attachmentId, onNavigateToDocumentActivity, onDownloadClick }: ActionsCellProps) => {
  const { hasPermissions } = useClientContext();

  const hasAccessToDocumentActivity = hasPermissions(["ViewInvestorPortalDocumentViewsAnalytics"]);

  return (
    <Stack direction="row" spacing={0.5} width="100%" justifyContent="flex-end">
      {hasAccessToDocumentActivity && onNavigateToDocumentActivity && attachmentId && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onNavigateToDocumentActivity(row.documentCollectionId, attachmentId);
          }}
        >
          <AnalyticsIcon />
        </IconButton>
      )}
      {row.attachmentIds.length > 0 && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDownloadClick(row);
          }}
        >
          <DownloadIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default ActionsCell;

const getViewsInfo = (row: FundStructureDocument) => {
  const entry = Object.entries(row.attachmentsWithViews ?? {})?.find(
    ([, value]) => value.totalViews || value.totalDownloads
  );

  return entry ? { attachmentId: entry[0], viewsInfo: entry[1] } : undefined;
};

export const getColumnDefinitions = (
  onDocumentPreview: (doc: FundStructureDocument) => void,
  onDocumentDownload: (doc: FundStructureDocument) => void,
  onDocCollectionClick: (doc: FundStructureDocument) => void,
  categories: Category[],
  excludeColumns: string[],
  onNavigateToDocumentActivity?: (postMessageRequestId: string, attachmentId: string) => void
): GridColDef<FundStructureDocument>[] => {
  const columnDefs: GridColDef<FundStructureDocument>[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 160,
      cellClassName: "highlighted-action",
      renderCell: ({ row }) => <TextHoverAction onClick={() => onDocumentPreview(row)}>{row.name}</TextHoverAction>,
    },
    {
      field: "investorName",
      headerName: "Investor",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) => <TypographyTooltipEllipsis text={row.investorName} />,
    },
    {
      field: "fundNames",
      headerName: "Fund",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) =>
        row.fundNames.length === 0 ? (
          "-"
        ) : (
          <InlineItemsList<string>
            displayCount={1}
            items={distinct(row.fundNames)}
            propGetter={(prop) => prop}
            justify="flex-start"
          />
        ),
    },
    {
      field: "categoryId",
      headerName: "Category",
      width: 250,
      renderCell: ({ row }) => {
        const category = categories.find((c) => c.externalId === row.categoryId);
        return category ? <CategoryTag categoryName={category.name} /> : null;
      },
    },
    {
      field: "documentCollectionName",
      headerName: "Document Collection",
      flex: 1,
      minWidth: 160,
      cellClassName: "highlighted-action",
      renderCell: ({ row }) => (
        <TextHoverAction onClick={() => onDocCollectionClick(row)}>{row.documentCollectionName}</TextHoverAction>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: ({ row }) => <DocumentCollectionStatusTag status={row.status} />,
    },
    {
      field: "sentAt",
      headerName: "Published Live",
      width: 160,
      valueFormatter: (value: string) => convertISODateTime(value),
    },
    {
      field: "views",
      headerName: "Views",
      type: "number",
      width: 100,
      valueGetter: (_, row) => getViewsInfo(row)?.viewsInfo.totalViews || null,
    },
    {
      field: "downloads",
      headerName: "Downloads",
      type: "number",
      width: 100,
      valueGetter: (_, row) => getViewsInfo(row)?.viewsInfo.totalDownloads || null,
    },
    {
      field: "totalViewTimeSeconds",
      headerName: "Total Time Spent",
      headerAlign: "right",
      align: "right",
      width: 140,
      valueGetter: (_, row) => {
        const totalViewTimeSeconds = getViewsInfo(row)?.viewsInfo.totalViewTimeSeconds;
        return totalViewTimeSeconds ? formatDurationInSeconds(totalViewTimeSeconds) : null;
      },
    },
    {
      field: "totalCompletionPercentage",
      headerName: "Total Completion",
      type: "number",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        const completionPercentage = getViewsInfo(row)?.viewsInfo.totalCompletionPercentage;
        if (!completionPercentage) {
          return null;
        }

        return (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography>{completionPercentage + "%"}</Typography>
            <StaticCircularProgress size={20} value={completionPercentage} />
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 90,
      cellClassName: "grid-row-actions",
      align: "right",
      renderCell: ({ row }) => (
        <ActionsCell
          row={row}
          attachmentId={getViewsInfo(row)?.attachmentId}
          onNavigateToDocumentActivity={onNavigateToDocumentActivity}
          onDownloadClick={onDocumentDownload}
        />
      ),
    },
  ];

  return columnDefs.filter((c) => !excludeColumns.includes(c.field));
};
