import { Stack, Typography } from "@mui/material";
import { convertISODate, convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import { ContactDetails, ContactStatus } from "../../../../../api/adminApi";
import InvestorPortalIcon from "../../../../../icons/InvestorPortalIcon";
import EntitySection from "../../../../entityFields/EntitySection";
import EntitySectionHeader from "../../../../entityFields/EntitySectionHeader";

const ContactAccessSection = ({ contactDetails }: { contactDetails: ContactDetails }) => {
  const { registrationDate, status, invitationSentAt } = contactDetails.contact;

  return (
    <EntitySection>
      <EntitySectionHeader title="Access" />
      <Stack width="100%" px={2.5} pb={2} pt={1} spacing={1.5}>
        <Stack spacing={1} direction="row" alignItems="center">
          <InvestorPortalIcon />
          <Typography variant="subtitle2">Investor portal</Typography>
        </Stack>
        <Stack direction="row" spacing={20}>
          <Stack>
            {status === ContactStatus.Registered && (
              <>
                <Typography>Registered</Typography>
                <Typography variant="caption" color="textSecondary">
                  {registrationDate ? convertISODate(registrationDate) : ""}
                </Typography>
              </>
            )}
            {status !== ContactStatus.Registered && <Typography>Not Registered</Typography>}
          </Stack>
          <Stack>
            {invitationSentAt && (
              <>
                <Typography>Invited</Typography>
                <Typography variant="caption" color="textSecondary">
                  {convertISODateTime(invitationSentAt)}
                </Typography>
              </>
            )}
            {!invitationSentAt && <Typography>Not Invited</Typography>}
          </Stack>
        </Stack>
      </Stack>
    </EntitySection>
  );
};

export default ContactAccessSection;
