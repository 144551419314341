import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import DataBackupConfigurationContainer from "./configuration/DataBackupConfigurationContainer";
import { BackupMasterConfiguration } from "../../../api/adminApi";
import DataBackupLogsPage from "./logs/DataBackupLogsPage";
import { DataBackupPageState } from "./dataBackupPageState";

type Tabs = "backup_configuration" | "logs";

interface Props {
  state: DataBackupPageState;
  readonly: boolean;
  onConfigurationChange: (data: Partial<BackupMasterConfiguration>) => void;
  onConfigurationSave: () => void;
}

const DataBackupTabs = ({ state, onConfigurationSave, onConfigurationChange, readonly }: Props) => {
  const [selectedTab, setSelectedTab] = useState<Tabs>("backup_configuration");

  const handleTabChange = (_: SyntheticEvent, value: Tabs) => setSelectedTab(value);
  return (
    <TabContext value={selectedTab}>
      <TabList onChange={handleTabChange}>
        <Tab label="Backup Configuration" value="backup_configuration" />
        <Tab label="Logs" value="logs" />
      </TabList>
      <TabPanel value="backup_configuration" sx={{ p: 0, pb: 2 }}>
        <DataBackupConfigurationContainer
          state={state}
          onConfigurationSave={onConfigurationSave}
          onConfigurationChange={onConfigurationChange}
          readonly={readonly}
        />
      </TabPanel>
      <TabPanel value="logs" sx={{ p: 0, pb: 2 }}>
        <DataBackupLogsPage />
      </TabPanel>
    </TabContext>
  );
};

export default DataBackupTabs;
