import { GridColDef } from "@mui/x-data-grid-premium";
import { Field, ObjectWithFieldValuesBase } from "../../api/types/objectTypes";
import EntityValueGridCell from "./EntityValueGridCell";

export const getGridColumnForField = <R extends ObjectWithFieldValuesBase>(fieldDefinition: Field): GridColDef<R> => ({
  field: fieldDefinition.id,
  headerName: fieldDefinition.name,
  sortable: false,
  flex: 1,
  renderCell: ({ row, field }) => {
    const entityFieldValue = row.fieldValues[field];
    if (entityFieldValue === undefined || entityFieldValue === null) {
      return null;
    }

    return (
      <EntityValueGridCell
        type={fieldDefinition.type}
        configuration={fieldDefinition.configuration}
        value={entityFieldValue}
      />
    );
  },
});
