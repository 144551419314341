import { Box, FormControlLabel, FormGroup, Stack, Switch, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { formatISO, isBefore, startOfToday } from "date-fns";
import { useCallback, useState } from "react";

interface ConsentActivationDateProps {
  disabled?: boolean;
  activeFrom?: string | null;
  onChange: (date: string | undefined | null) => void;
}

const ConsentActivationDate = ({ activeFrom, onChange, disabled }: ConsentActivationDateProps) => {
  const [enableDate, setEnableDate] = useState(activeFrom !== undefined);
  const [selectedDate, setSelectedDate] = useState<Date | null>(activeFrom ? new Date(activeFrom) : null);
  const [isValid, setIsValid] = useState(true);

  const handleToggleChange = (checked: boolean) => {
    if (checked) {
      const date = selectedDate || new Date();
      setSelectedDate(date);
      onChange(formatISO(date, { representation: "date" }));
    } else {
      onChange(undefined);
    }
    setEnableDate(checked);
  };

  const handleDateChange = useCallback(
    (date: Date | null) => {
      setSelectedDate(date);
      if (date?.toString() === "Invalid Date") {
        onChange(null);
        setIsValid(false);
      } else {
        setIsValid(true);
        onChange(date ? formatISO(date, { representation: "date" }) : undefined);
      }
    },
    [onChange]
  );

  const isPastDateDisabled = (date: Date) => {
    return isBefore(date, startOfToday());
  };

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "divider",
        borderRadius: 1,
        padding: 2,
        width: "100%",
      }}
    >
      <FormGroup>
        <FormControlLabel
          sx={{ alignItems: "flex-start" }}
          control={
            <Switch disabled={disabled} checked={enableDate} onChange={(e) => handleToggleChange(e.target.checked)} />
          }
          label={
            <Stack sx={{ pl: 2 }}>
              <Typography variant="subtitle2">Activation Date</Typography>
              <Typography color="textSecondary" sx={{ mb: 1 }}>
                Date when consent will be activated on the portal. If the date is not set, consent won’t be visible to
                investors. After activation, you can deactivate the consent by turning off this toggle. To activate
                again, turn the toggle back on and pick a new date.
              </Typography>
              <DatePicker
                disablePast
                value={selectedDate}
                onChange={handleDateChange}
                disabled={!enableDate || disabled}
                shouldDisableDate={isPastDateDisabled}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: (theme) => ({ width: theme.spacing(20) }),
                    InputProps: {
                      sx: (theme) => ({
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: `${isValid ? theme.palette.divider : theme.palette.error.main} !important`,
                        },
                      }),
                    },
                  },
                }}
              />
            </Stack>
          }
        />
      </FormGroup>
    </Box>
  );
};

export default ConsentActivationDate;
