import { Link, LinkProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useClientContext } from "../../context/ClientContext";
import { pageRoutes } from "../../routes";

interface Props extends LinkProps {
  objectType: string;
  objectId: string;
}

const getObjectPageRoute = (clientCode: string, objectType: string, objectId: string): string => {
  switch (objectType) {
    case "Fund":
      return `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.funds}/${objectId}/main`;
    case "Investor":
      return `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investors}/${objectId}/main`;
    case "Contact":
      return `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.contacts}/${objectId}/main`;
    case "PortfolioCompany":
      return `/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.portfolioCompanies}/${objectId}`;
    case "FundInvestment":
      return `/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.fundInvestments}/${objectId}`;
    case "Vendor":
      return `/${clientCode}/${pageRoutes.expenseManagement}/${pageRoutes.vendors}/${objectId}`;
    default:
      return `/${clientCode}/${pageRoutes.settings}/${pageRoutes.records}/${objectType}/${objectId}`;
  }
};

const ObjectPageLink = ({ objectType, objectId, children, ...linkProps }: PropsWithChildren<Props>) => {
  const { clientCode } = useClientContext();

  return (
    <Link
      component={RouterLink}
      to={getObjectPageRoute(clientCode, objectType, objectId)}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      {...linkProps}
    >
      {children}
    </Link>
  );
};

export default ObjectPageLink;
