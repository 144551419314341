import { SvgIcon, SvgIconProps } from "@mui/material";

const InvestorPortalIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <path
      d="M40 0H8C3.58172 0 0 3.58172 0 8V40C0 44.4183 3.58172 48 8 48H40C44.4183 48 48 44.4183 48 40V8C48 3.58172 44.4183 0 40 0Z"
      fill="#F6F8FA"
    />
    <path
      d="M35.879 23.9804L31.7122 28.2035L29.122 25.8948L24.73 30.0053V26.1764L29.0657 22.0659L31.5996 24.3182L35.8227 19.9825L35.879 23.9804Z"
      fill="#00A866"
    />
    <path
      d="M41.9038 18.6872V30.8498C41.9038 33.1021 40.0457 34.9603 37.7933 34.9603H23.66V32.1449H37.7933C38.5254 32.1449 39.0884 31.5818 39.0884 30.8498V18.6872C39.0884 17.9552 38.5254 17.3921 37.7933 17.3921H21.2388V14.5767H37.8497C40.102 14.5767 41.9038 16.4349 41.9038 18.6872Z"
      fill="#C7DBE5"
    />
    <path
      d="M16.1147 24.7122C12.6236 24.7122 9.80823 21.3337 9.80823 17.2232C9.80823 13.0564 12.6236 9.73425 16.1147 9.73425C19.5495 9.73425 22.3649 13.0564 22.3649 17.2232C22.3649 21.3337 19.5495 24.7122 16.1147 24.7122ZM16.1147 12.5497C14.2003 12.5497 12.6236 14.633 12.6236 17.2232C12.6236 19.8134 14.2003 21.8968 16.1147 21.8968C18.0292 21.8968 19.6058 19.8134 19.6058 17.2232C19.5495 14.633 18.0292 12.5497 16.1147 12.5497Z"
      fill="#00A866"
    />
    <path
      d="M16.1148 26.0636C13.6935 26.0636 7.55594 32.3701 5.8667 38.0009H8.85103C10.484 33.5526 14.876 29.5547 16.1148 28.9353C17.4099 29.5547 21.7456 33.5526 23.3785 38.0009H26.3628C24.6736 32.3701 18.4797 26.0636 16.1148 26.0636Z"
      fill="#00A866"
    />
  </SvgIcon>
);

export default InvestorPortalIcon;
