import { Investor } from "../../../../../api/adminApi";
import { ObjectClassDefinition, ObjectPermissions } from "../../../../../api/types/objectTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import ScrollableColumns from "../../../../common/layout/ScrollableColumns";
import CreatedAndModifiedTimestamps from "../../../../entityFields/CreatedAndModifiedTimestamps";
import EntityNotesSection from "../../../../entityFields/EntityNotesSection";
import { getNotesEntityType } from "../../../../entityFields/entityTypesHelper";
import { NotesContextProvider } from "../../../../notes/NotesContext";
import InvestorCard from "./InvestorCard";

interface Props {
  investor: Investor;
  objectDefinition: ObjectClassDefinition;
  objectPermissions: ObjectPermissions;
  onInvestorDetailsChange: (details: Partial<Investor>) => void;
}

const InvestorMainInfoTab = ({ investor, objectDefinition, objectPermissions, onInvestorDetailsChange }: Props) => {
  const { hasAnyPermission } = useClientContext();

  const hasPermissionsToManageFields = hasAnyPermission(objectPermissions.objectDataWritePermissions);
  const hasPermissionsToViewNotes = hasAnyPermission(objectPermissions.notesReadPermissions);
  const hasPermissionsToManageNotes = hasAnyPermission(objectPermissions.notesWritePermissions);

  return (
    <ScrollableColumns gridProps={{ columnSpacing: 2.5 }}>
      <>
        <InvestorCard
          investor={investor}
          objectDefinition={objectDefinition}
          onInvestorDetailsChange={onInvestorDetailsChange}
          hasPermissionsToManageFields={hasPermissionsToManageFields}
        />
        <CreatedAndModifiedTimestamps
          createdAt={investor.createdAt}
          modifiedAt={investor.updateAt}
          stackProps={{ mt: 2.5 }}
        />
      </>

      {hasPermissionsToViewNotes && (
        <NotesContextProvider
          entityType={getNotesEntityType(objectDefinition.objectType)}
          entityId={investor.id}
          hasPermissionsToManageNotes={hasPermissionsToManageNotes}
        >
          <EntityNotesSection />
        </NotesContextProvider>
      )}
    </ScrollableColumns>
  );
};

export default InvestorMainInfoTab;
