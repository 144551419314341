import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { ChangeEventHandler, useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../../../shared/logging";
import adminApi, { InvoiceEntry } from "../../../../../../../api/adminApi";
import { DictionaryItem } from "../../../../../../../api/types/clientTypes";
import { FieldIdToValueMap, ObjectFieldValues } from "../../../../../../../api/types/objectTypes";
import { useLocalization } from "../../../../../../../hooks/useLocalization";
import { getInitialState, updateState } from "./createVendorState";

interface Props {
  open: boolean;
  currencyCodeValues: DictionaryItem[];
  vendors: InvoiceEntry[];
  onCancel: () => void;
  onSaved: (vendor: ObjectFieldValues) => void;
}

const createObject = withErrorHandling(adminApi.createObject);
const objectType = "Vendor";
const entityTypeCode = "Vendor";

const CreateVendorDialog = ({ open, onCancel, onSaved, currencyCodeValues, vendors }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const {
    invoices: { create_vendor_dialog: locale },
  } = useLocalization();
  const [isSaving, setSaving] = useState(false);
  const [vendorState, setVendorState] = useState(getInitialState());
  const existingVendorNames = useMemo(() => vendors.map((v) => v.caption.toLocaleLowerCase()), [vendors]);

  const handleSubmit = async () => {
    setSaving(true);

    const fieldValues: FieldIdToValueMap = { Name: vendorState.name, CurrencyCode: vendorState.currencyCode };
    const [object, error] = await createObject(objectType, { fieldValues, entityTypeCode });

    if (error) {
      sendNotificationError(locale.failed_to_create);
      logError(error, "[CreateVendorDialog] handleSubmit");
    } else {
      sendNotification(locale.created);
      onSaved(object);
    }
    setVendorState(getInitialState());
    setSaving(false);
  };

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const name = e.target.value;
    setVendorState((prev) => updateState({ ...prev, name: name }, existingVendorNames));
  };

  const handleCurrencyCodeChange = (e: SelectChangeEvent<string>) => {
    const code = e.target.value;
    setVendorState((prev) => updateState({ ...prev, currencyCode: code }, existingVendorNames));
  };

  const handleCancel = () => {
    setVendorState(getInitialState());
    onCancel();
  };

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>{locale.title}</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <DialogContent sx={{ p: 3 }}>
        <Stack gap={1}>
          <TextField
            fullWidth
            label={locale.name_field.label}
            variant="outlined"
            value={vendorState.name}
            placeholder={locale.name_field.placeholder}
            onChange={handleNameChange}
            helperText={vendorState.errors.name}
            error={!!vendorState.errors.name}
            sx={{ mt: 2, flex: 1 }}
          />
          <FormControl size="small" sx={{ mt: 2, flex: 0.25 }}>
            <InputLabel>{locale.currency_field.label}</InputLabel>
            <Select
              value={vendorState.currencyCode}
              label={locale.currency_field.label}
              size="small"
              onChange={handleCurrencyCodeChange}
            >
              {currencyCodeValues.map((v) => (
                <MenuItem key={v.code} value={v.code}>
                  <Typography>{v.displayName}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="text" color="secondary" onClick={handleCancel}>
          {locale.cancel}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isSaving}
          onClick={handleSubmit}
          disabled={!vendorState.isValid}
        >
          {locale.submit}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateVendorDialog;
