import { Box, Grid, Typography } from "@mui/material";
import { convertISODateTime } from "../../../../shared/utilities/dateUtils";
import KeyValueColumn from "../../common/KeyValueColumn";
import { useCrmConnectorContext } from "./CrmConectorContext";

const CrmSetupInfo = () => {
  const { connectionInfo } = useCrmConnectorContext();
  const { environment, createdBy, createdAt, lastSyncAt, connectedTo } = connectionInfo;

  return (
    <Box px={3} py={2}>
      <Typography variant="subtitle1" mb={1}>
        Setup
      </Typography>
      <Grid container spacing={5}>
        {environment && (
          <Grid item>
            <KeyValueColumn title="Environment" value={environment} />
          </Grid>
        )}
        <Grid item>
          <KeyValueColumn title="Created by" value={createdBy || ""} />
        </Grid>
        <Grid item>
          <KeyValueColumn title="Created at" value={convertISODateTime(createdAt)} />
        </Grid>
        <Grid item>
          <KeyValueColumn title="Last synchronization" value={convertISODateTime(lastSyncAt ?? "")} />
        </Grid>
        <Grid item>
          <KeyValueColumn link title="Connected to" value={connectedTo || ""} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CrmSetupInfo;
