import { Paper, Stack, SxProps, Theme, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  title: string;
  value: string;
  sx?: SxProps<Theme>;
}

const StatTile = ({ title, value, sx, children }: PropsWithChildren<Props>) => {
  return (
    <Paper variant="outlined" sx={{ p: 2, ...sx }}>
      <Stack spacing={1}>
        <Typography color="text.secondary">{title}</Typography>
        <Typography variant="h2">{value}</Typography>
        {children}
      </Stack>
    </Paper>
  );
};

export default StatTile;
