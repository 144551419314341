import HelpIcon from "@mui/icons-material/HelpOutline";
import { LinearProgress, Stack, Tooltip, Typography } from "@mui/material";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { PropsWithChildren, useState } from "react";
import { Document } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { getErrorMessage } from "../../../api/axiosHelper";
import { PdfViewerState } from "./pdfViewerState";
import { initPdfViewer } from "./pdfjsHelper";

interface Props {
  fileSrc: string;
  viewerState: PdfViewerState;
  onDocumentLoad: (documentProxy: PDFDocumentProxy) => void;
  onLoadingProgress: (progress: number) => void;
}

initPdfViewer();

interface ErrorMessageProps {
  errorDetails?: string;
}

const ErrorMessage = ({ errorDetails, children }: PropsWithChildren<ErrorMessageProps>) => (
  <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} width="100%">
    <Typography variant="subtitle1" color="error.main" width="100%" textAlign="center" pt={2}>
      {children}
    </Typography>
    {errorDetails && (
      <Tooltip title={errorDetails}>
        <HelpIcon color="error" />
      </Tooltip>
    )}
  </Stack>
);

const pdfDocumentOptions = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const PdfDocumentWrapper = ({
  fileSrc,
  viewerState,
  onDocumentLoad,
  onLoadingProgress,
  children,
}: PropsWithChildren<Props>) => {
  const [loadError, setLoadError] = useState<Error>();

  const handleLoadSuccess = (documentProxy: PDFDocumentProxy) => {
    setLoadError(undefined);
    onDocumentLoad(documentProxy);
  };

  const handleLoadProgress = ({ loaded, total }: { loaded: number; total: number }) => {
    const progressPercentage = Math.min((loaded * 100) / total, 100);
    onLoadingProgress(progressPercentage);
  };

  return (
    <Document
      file={fileSrc}
      options={pdfDocumentOptions}
      onLoadSuccess={handleLoadSuccess}
      onLoadError={setLoadError}
      onLoadProgress={handleLoadProgress}
      loading={<LinearProgress variant="determinate" value={viewerState.loadingProgress} />}
      error={<ErrorMessage errorDetails={getErrorMessage(loadError)}>Error loading PDF</ErrorMessage>}
      noData={<ErrorMessage>Unsupported file format</ErrorMessage>}
    >
      {children}
    </Document>
  );
};

export default PdfDocumentWrapper;
