import { createSvgIcon } from "@mui/material";

const KeyVerticalIcon = createSvgIcon(
  <svg viewBox="0 0 16 16" fill="currentColor">
    <path
      id="key_vertical_2"
      d="M6.99992 4.66667C6.99992 4.3 7.13047 3.98611 7.39159 3.725C7.6527 3.46389 7.96659 3.33333 8.33325 3.33333C8.69992 3.33333 9.01381 3.46389 9.27492 3.725C9.53603 3.98611 9.66658 4.3 9.66658 4.66667C9.66658 5.03333 9.53603 5.34722 9.27492 5.60833C9.01381 5.86944 8.69992 6 8.33325 6C7.96659 6 7.6527 5.86944 7.39159 5.60833C7.13047 5.34722 6.99992 5.03333 6.99992 4.66667ZM4.33325 4.66667C4.33325 3.55555 4.72214 2.61111 5.49992 1.83333C6.2777 1.05555 7.22214 0.666666 8.33325 0.666666C9.44436 0.666666 10.3888 1.05555 11.1666 1.83333C11.9444 2.61111 12.3333 3.55555 12.3333 4.66667C12.3333 5.41111 12.1499 6.08611 11.7833 6.69167C11.4166 7.29722 10.9333 7.77778 10.3333 8.13333V13.7167C10.3333 13.8056 10.3166 13.8917 10.2833 13.975C10.2499 14.0583 10.1999 14.1333 10.1333 14.2L8.79992 15.5333C8.73325 15.6 8.66103 15.6472 8.58325 15.675C8.50547 15.7028 8.42214 15.7167 8.33325 15.7167C8.24436 15.7167 8.16103 15.7028 8.08325 15.675C8.00547 15.6472 7.93325 15.6 7.86659 15.5333L5.74992 13.4167C5.69436 13.3611 5.64992 13.2944 5.61659 13.2167C5.58325 13.1389 5.56103 13.0611 5.54992 12.9833C5.53881 12.9056 5.54436 12.8278 5.56659 12.75C5.58881 12.6722 5.6277 12.6 5.68325 12.5333L6.33325 11.6667L5.61659 10.7167C5.57214 10.6611 5.53881 10.6 5.51659 10.5333C5.49436 10.4667 5.48325 10.4 5.48325 10.3333C5.48325 10.2667 5.49436 10.1972 5.51659 10.125C5.53881 10.0528 5.57214 9.98889 5.61659 9.93333L6.33325 8.91667V8.13333C5.73325 7.77778 5.24992 7.29722 4.88325 6.69167C4.51659 6.08611 4.33325 5.41111 4.33325 4.66667ZM5.66659 4.66667C5.66659 5.28889 5.85547 5.83611 6.23325 6.30833C6.61103 6.78055 7.08881 7.09444 7.66658 7.25V9.33333L6.98325 10.3L7.99992 11.6667L7.08325 12.85L8.33325 14.1L8.99992 13.4333V7.25C9.5777 7.09444 10.0555 6.78055 10.4333 6.30833C10.811 5.83611 10.9999 5.28889 10.9999 4.66667C10.9999 3.93333 10.7388 3.30555 10.2166 2.78333C9.69436 2.26111 9.06659 2 8.33325 2C7.59992 2 6.97214 2.26111 6.44992 2.78333C5.9277 3.30555 5.66659 3.93333 5.66659 4.66667Z"
    />
  </svg>,
  "KeyVerticalIcon"
);

export default KeyVerticalIcon;
