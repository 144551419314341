import { useState } from "react";
import SalesforceLogo from "../../../images/salesforce.png";
import CrmSetupInfo from "./CrmSetupInfo";
import CrmActions from "./CrmActions";
import CrmManagement from "./CrmManagement";
import crmConnectorsApi, { CrmSyncSettings } from "../../../api/crmConnectorsApi";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import TestConnectionInfo, { TestConnectionState } from "./TestConnectionInfo";
import FullResyncInfo, { FullResyncState } from "./FullResyncInfo";
import { useNavigate } from "react-router-dom";
import { CrmConnectorContextProvider } from "./CrmConectorContext";
import { logError } from "../../../../shared/logging";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import IntegrationHeader from "../integration/IntegrationHeader";
import { useClientContext } from "../../../context/ClientContext";
import { pageRoutes } from "../../../routes";

const promotionText =
  "You can use REST API tools to create, manipulate, and search data in Salesforce by sending HTTP requests to endpoints in Salesforce. Depending on where you send requests, you access and operate on different pieces of information, called resources. Resources include records, query results, metadata, and more.";

const SalesforceConnectorPage = () => {
  const { clientCode, salesforceApp } = useClientContext();
  const navigate = useNavigate();

  const [connectionInfo] = useFetch(crmConnectorsApi.salesforce.getConnection);
  const [connectorSettings, fetchError, { setData: setConnectorSettings }] = useFetch(
    crmConnectorsApi.salesforce.getConnectorSettings
  );
  const [ourObjects] = useFetch(crmConnectorsApi.salesforce.getOurObjects);
  const [crmObjects] = useFetch(crmConnectorsApi.salesforce.getCrmObjects);

  const [testConnectionState, setTestConnectionState] = useState<TestConnectionState>({ status: "hidden" });
  const [fullResyncState, setFullResyncState] = useState<FullResyncState>({ status: "hidden" });

  if (fetchError) {
    logError(fetchError.message || "fetch connectorSettings failed", "SalesforceConnectorPage");
    return <DataLoadingFailed title="Loading connector settings failed" />;
  }

  if (
    connectionInfo === undefined ||
    connectorSettings === undefined ||
    ourObjects === undefined ||
    crmObjects === undefined
  ) {
    return <InlineLoader />;
  }

  const handleDelete = () => navigate(`/${clientCode}/${pageRoutes.settings}/${pageRoutes.settingsIntegrations}`);

  // Not implemented
  const handleReAuthorize = () => undefined;

  const handleTestConnection = async () => {
    setTestConnectionState({ status: "loading" });

    const { data: testConnectionResult, success, error } = await crmConnectorsApi.salesforce.testConnection();
    if (success) {
      setTestConnectionState(
        testConnectionResult.success
          ? { status: "success" }
          : { status: "error", errorMessage: testConnectionResult.message }
      );
    } else {
      setTestConnectionState({ status: "error", errorMessage: error?.message });
    }
  };

  const handleCloseTestConnectionInfo = () => setTestConnectionState({ status: "hidden" });

  const handleDataSyncToggle = async (enabled: boolean) => {
    await crmConnectorsApi.salesforce.postDataSyncEnabled(enabled);
  };

  const handleFullResync = async () => {
    setFullResyncState({ status: "starting" });

    const { data: startFullResyncResult, success, error } = await crmConnectorsApi.salesforce.startFullResyncJob();
    if (success) {
      setFullResyncState(
        startFullResyncResult.result
          ? { status: "success" }
          : { status: "error", errorMessage: "Operation failed to start." }
      );
    } else {
      setFullResyncState({ status: "error", errorMessage: error?.message });
    }
  };

  const handleCloseFullResyncInfo = () => setFullResyncState({ status: "hidden" });

  const handleSyncSettingsUpdate = (syncSettings: CrmSyncSettings) =>
    setConnectorSettings({ ...connectorSettings, syncSettings });

  return (
    <CrmConnectorContextProvider
      crmName="Salesforce"
      crmApiName="salesforce"
      logoSrc={SalesforceLogo}
      connectionInfo={connectionInfo}
      connectorSettings={connectorSettings}
      ourObjects={ourObjects}
      crmObjects={crmObjects}
      onSyncSettingsUpdate={handleSyncSettingsUpdate}
    >
      <IntegrationHeader
        title="Salesforce"
        logoSrc={SalesforceLogo}
        text={promotionText}
        appStatus={salesforceApp?.status}
      />
      <CrmSetupInfo />
      <CrmActions
        onDelete={handleDelete}
        onReAuthorize={handleReAuthorize}
        onTestConnection={handleTestConnection}
        onDataSyncToggle={handleDataSyncToggle}
        onFullResync={handleFullResync}
      />
      <TestConnectionInfo state={testConnectionState} onClose={handleCloseTestConnectionInfo} />
      <FullResyncInfo state={fullResyncState} onClose={handleCloseFullResyncInfo} />
      <CrmManagement />
    </CrmConnectorContextProvider>
  );
};

export default SalesforceConnectorPage;
