import { Box, Paper, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  title: string;
}

const EntitySectionHeader = ({ title, children }: PropsWithChildren<Props>) => {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        px: 2.5,
        borderBottom: 1,
        borderColor: "divider",
        borderRadius: 0,
        zIndex: 100,
      }}
      elevation={0}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" height="4rem">
        <Typography variant="h6">{title}</Typography>
        {children}
      </Box>
    </Paper>
  );
};

export default EntitySectionHeader;
