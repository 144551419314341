import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { fieldTypeCaptionMap } from "../../../../utilities/enumCaptions";
import FieldSourceBadge from "../../../entityFields/FieldSourceBadge";
import ObjectFieldName from "./ObjectFieldName";
import { FieldRow } from "./objectFieldsState";

interface Actions {
  onEditField: (field: FieldRow) => void;
}

export const getRowEditButtonId = (fieldId: string) => `object-field-row-edit-${fieldId}`;

export const getColumnDefinitions = ({ onEditField }: Actions): GridColDef<FieldRow>[] => {
  const commonColumnProps: Partial<GridColDef<FieldRow>> = {
    sortable: false,
    cellClassName: ({ row }) => (row.isHighlighted ? "grid-cell-highlighted" : ""),
  };

  return [
    {
      ...commonColumnProps,
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: ({ row }) => <ObjectFieldName field={row} />,
    },
    {
      ...commonColumnProps,
      field: "type",
      headerName: "Type",
      flex: 0.5,
      minWidth: 100,
      valueGetter: (_, row) => fieldTypeCaptionMap[row.type],
    },
    {
      ...commonColumnProps,
      field: "source",
      headerName: "Source",
      flex: 0.5,
      minWidth: 100,
      renderCell: ({ row }) => <FieldSourceBadge fieldSource={row.source} />,
    },
    {
      ...commonColumnProps,
      field: "actions",
      headerName: "",
      width: 50,
      renderCell: ({ row }) => (
        <IconButton onClick={() => onEditField(row)} id={getRowEditButtonId(row.id)}>
          <ChevronRightIcon />
        </IconButton>
      ),
    },
  ];
};
