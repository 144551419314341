import { PaginatedList, PagingParams } from "../../shared/api/types";

export interface PaginatedItemsState<TItem> {
  items: TItem[];
  page: number;
  totalPages: number;
  totalRecords: number;
  searchTerm: string;
}

export const getInitialPaginatedItemsState = <TItem>(): PaginatedItemsState<TItem> => ({
  items: [],
  page: 0,
  totalPages: 0,
  totalRecords: 0,
  searchTerm: "",
});

// Actions

type StateAction<TItem, S extends PaginatedItemsState<TItem> = PaginatedItemsState<TItem>> = (state: S) => S;

export const loadItemsAction =
  <TItem, S extends PaginatedItemsState<TItem> = PaginatedItemsState<TItem>>(
    data: PaginatedList<TItem>
  ): StateAction<TItem, S> =>
  (state) => ({
    ...state,
    items: state.page === 0 ? data.items : [...state.items, ...data.items],
    totalPages: data.totalPages ?? state.totalPages,
    totalRecords: data.total ?? state.totalRecords,
  });

export const nextPageAction =
  <TItem, S extends PaginatedItemsState<TItem> = PaginatedItemsState<TItem>>(): StateAction<TItem, S> =>
  (state) => {
    const canLoadMore = state.totalPages > 0 && state.page < state.totalPages - 1;
    return canLoadMore
      ? {
          ...state,
          page: state.page + 1,
        }
      : state;
  };

export const firstPageAction =
  <TItem, S extends PaginatedItemsState<TItem> = PaginatedItemsState<TItem>>(): StateAction<TItem, S> =>
  (state) => ({
    ...state,
    page: 0,
  });

export const searchItemsAction =
  <TItem, S extends PaginatedItemsState<TItem> = PaginatedItemsState<TItem>>(
    searchTerm: string
  ): StateAction<TItem, S> =>
  (state) => ({
    ...state,
    page: 0,
    searchTerm,
  });

// Selectors
export const getPagingParams = (page: number, pageSize = 50): PagingParams => ({
  page,
  size: pageSize,
  totals: page === 0,
});
