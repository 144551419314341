import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import adminApi from "../../../../../api/adminApi";
import { CreatePassthroughImportRequest } from "../../../../../api/types/passthroughTypes";

export const getFundClosings = withErrorHandling((fundId: string) => adminApi.getPassthroughFundClosings(fundId));

export const importPassthroughData = withErrorHandling((data: CreatePassthroughImportRequest) =>
  adminApi.importPassthroughData(data)
);

export const createImportDataPayload = (
  fundId: string | undefined,
  fundClosingId: string | undefined,
  entityId: string | undefined,
  commitmentDate: string | undefined
): CreatePassthroughImportRequest | undefined => {
  if (!fundId || !fundClosingId || !entityId || !commitmentDate) {
    return undefined;
  }

  return {
    passthroughFundId: fundId,
    passthroughFundClosingId: fundClosingId,
    entityId: entityId,
    commitmentDate: commitmentDate,
  };
};
