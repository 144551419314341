import { blue, green } from "@mui/material/colors";
import { FundraisingStatus } from "../api/fundraisingTypes";
import StatusChip from "./StatusChip";

interface Props {
  status: FundraisingStatus;
}

const FundraisingStatusTag = ({ status }: Props) => {
  switch (status) {
    case "Draft":
      return <StatusChip label="Draft" withDot color={(t) => t.palette.text.secondary} />;
    case "Preview":
      return <StatusChip label="Preview" withDot color={blue[700]} />;
    case "Live":
      return <StatusChip label="Live" withDot color={green[700]} />;
    case "Completed":
      return <StatusChip label="Completed" withDot color={(t) => t.palette.text.secondary} />;
    default:
      return null;
  }
};

export default FundraisingStatusTag;
