import { Box, Divider, Paper } from "@mui/material";
import { PropsWithChildren } from "react";

// Large right padding is to reserve space for Intercom chat button

const GeneralPageFooter = ({ children }: PropsWithChildren) => {
  return (
    <Paper sx={{ position: "sticky", bottom: 0, left: "auto", right: 0, width: "100%", zIndex: 1100 }}>
      <Divider />
      <Box py={2} pl={3} pr={10} display="flex" justifyContent="space-between">
        {children}
      </Box>
    </Paper>
  );
};

export default GeneralPageFooter;
