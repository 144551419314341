import { generateGuid } from "../../../../shared/utilities/generateGuid";
import { Validator } from "../../../../shared/utilities/validators";
import { FilesRowModel } from "./filesState";

export interface UploadingFile {
  id: string;
  rowModel: FilesRowModel;
  file?: File;
}

export const getUploadingFiles = (files: File[], validateFile: Validator<File>): UploadingFile[] => {
  return files.map((file) => {
    const id = generateGuid();

    const rowModel: FilesRowModel = {
      id,
      catalogueId: id,
      fileName: file.name,
      createdBy: "",
      createdAt: "",
      fileSize: file.size,
    };

    const validationResult = validateFile(file);

    if (validationResult.isValid) {
      rowModel.uploadState = { status: "in_progress" };
      return { id, rowModel, file };
    } else {
      rowModel.uploadState = { status: "error", validationError: validationResult.error };
      return { id, rowModel };
    }
  });
};
