import { EntityFieldConfiguration } from "../../../../../api/types/objectTypes";
import { FieldConfigurationValidation } from "../objectFieldsState";
import LookupFieldConfigEditor from "./LookupFieldConfigEditor";
import MoneyFieldConfigEditor from "./MoneyFieldConfigEditor";
import NumberFieldConfigEditor from "./NumberFieldConfigEditor";
import SelectFieldConfigViewer from "./SelectFieldConfigViewer";
import TextFieldConfigViewer from "./TextFieldConfigViewer";
import UdfOptionsFieldConfigEditor from "./UdfOptionsFieldConfigEditor";

interface Props {
  fieldConfiguration: EntityFieldConfiguration;
  validation: FieldConfigurationValidation;
  isNewField: boolean;
  readOnly?: boolean;
  onChange: (fieldConfiguration: EntityFieldConfiguration) => void;
}

const FieldConfigEditor = ({ fieldConfiguration, validation, isNewField, readOnly, onChange }: Props) => {
  switch (fieldConfiguration.$type) {
    case "Text": {
      return <TextFieldConfigViewer fieldConfiguration={fieldConfiguration} />;
    }
    case "Number":
    case "Percent": {
      return (
        <NumberFieldConfigEditor fieldConfiguration={fieldConfiguration} readOnly={readOnly} onChange={onChange} />
      );
    }
    case "Money": {
      return <MoneyFieldConfigEditor fieldConfiguration={fieldConfiguration} readOnly={readOnly} onChange={onChange} />;
    }
    case "Select": {
      return <SelectFieldConfigViewer fieldConfiguration={fieldConfiguration} />;
    }
    case "UserDefinedOptionsSelect": {
      return (
        <UdfOptionsFieldConfigEditor
          fieldConfiguration={fieldConfiguration}
          validation={validation}
          isNewField={isNewField}
          readOnly={readOnly}
          onChange={onChange}
        />
      );
    }
    case "Lookup": {
      return (
        <LookupFieldConfigEditor
          fieldConfiguration={fieldConfiguration}
          validation={validation}
          readOnly={readOnly || !isNewField}
          onChange={onChange}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default FieldConfigEditor;
