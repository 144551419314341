import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import DeleteFileIcon from "../../../../shared/icons/DeleteFileIcon";
import adminApi from "../../../api/adminApi";
import { useFilesContext } from "./FilesContext";

interface Props {
  catalogueIds: string[];
  description: string;
  open: boolean;
  onCancel: () => void;
  onSubmit: (catalogueIds: string[]) => void;
}

const deleteFiles = withErrorHandling(adminApi.deleteFiles);
const detachEntityFiles = withErrorHandling(adminApi.detachEntityFiles);

const DeleteFilesDialog = ({ catalogueIds, description, open, onCancel, onSubmit }: Props) => {
  const { entityInfo } = useFilesContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [deleting, setDeleting] = useState(false);

  const title = catalogueIds.length === 1 ? `Delete file?` : "Delete files?";

  const handleDelete = async () => {
    if (catalogueIds.length === 0) {
      return;
    }

    setDeleting(true);

    const [resp, error] = entityInfo
      ? await detachEntityFiles(entityInfo.entityType, entityInfo.entityId, entityInfo.objectType, catalogueIds)
      : await deleteFiles(catalogueIds);

    if (error || !resp.result) {
      sendNotificationError(`Failed to delete ${description}`);
    } else {
      sendNotification(`${description} deleted successfully`);
      onSubmit(catalogueIds);
    }

    setDeleting(false);
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={onCancel} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DeleteFileIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={0.5}>
            <Typography variant="h6">{title}</Typography>
            <Typography color="text.secondary" textAlign="center">
              You're about to delete <strong>{description}</strong>
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              This action is permanent and can't be undone.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={deleting} onClick={handleDelete}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFilesDialog;
