import { Box, Divider, MenuList, MenuListProps } from "@mui/material";

const SubMenuList = (props: MenuListProps) => (
  <Box display="flex" mb={1.5}>
    <Divider orientation="vertical" flexItem sx={{ mb: 1, ml: -1, mr: 1 }} />
    <MenuList {...props} sx={{ pl: 0.5, pt: 0.5, pb: 1, width: "100%" }}>
      {props.children}
    </MenuList>
  </Box>
);

export default SubMenuList;
