import { ApiError, PaginatedList } from "../../../../shared/api/types";
import { FileInfo } from "../../../api/types/fileTypes";

export interface FilesState {
  files: FilesRowModel[];
  uploadingFiles: FilesRowModel[];
  page: number;
  totalPages: number;
  totalRecords: number;
  searchTerm: string;
}

export interface FilesRowModel extends FileInfo {
  uploadState?: UploadFileState;
}

export interface UploadFileState {
  status: UploadFileStatus;
  validationError?: string;
  uploadError?: ApiError;
}

type UploadFileStatus = "in_progress" | "completed" | "error";

export type FilesAction =
  | {
      type: "load_files";
      data: PaginatedList<FileInfo>;
    }
  | {
      type: "next_page";
    }
  | {
      type: "search";
      searchTerm: string;
    }
  | {
      type: "remove_files";
      catalogueIds: string[];
    }
  | {
      type: "rename_file";
      catalogueId: string;
      newFileName: string;
    }
  | {
      type: "start_upload";
      uploadingFileModels: FilesRowModel[];
    }
  | {
      type: "update_upload_state";
      fileId: string;
      uploadingState: UploadFileState;
    }
  | {
      type: "finish_upload";
      fileId: string;
      fileInfo: FileInfo;
    };

export const getInitialState = (): FilesState => ({
  files: [],
  uploadingFiles: [],
  page: 0,
  totalPages: 0,
  totalRecords: 0,
  searchTerm: "",
});

export const reducer = (state: FilesState, action: FilesAction): FilesState => {
  switch (action.type) {
    case "load_files": {
      const { items, total, totalPages } = action.data;
      const files = state.page === 0 ? items : [...state.files, ...items];
      return {
        ...state,
        files,
        totalPages: totalPages ?? state.totalPages,
        totalRecords: total ?? state.totalRecords,
      };
    }
    case "next_page": {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    case "search": {
      return {
        ...state,
        page: 0,
        searchTerm: action.searchTerm,
      };
    }
    case "remove_files": {
      return {
        ...state,
        totalRecords: state.totalRecords - action.catalogueIds.length,
        files: state.files.filter((fileModel) => !action.catalogueIds.includes(fileModel.catalogueId)),
        uploadingFiles: state.uploadingFiles.filter(
          (fileModel) => !action.catalogueIds.includes(fileModel.catalogueId)
        ),
      };
    }
    case "rename_file": {
      return {
        ...state,
        files: state.files.map((fileModel) =>
          fileModel.catalogueId === action.catalogueId ? { ...fileModel, fileName: action.newFileName } : fileModel
        ),
      };
    }
    case "start_upload": {
      return {
        ...state,
        uploadingFiles: action.uploadingFileModels,
      };
    }
    case "update_upload_state": {
      return {
        ...state,
        uploadingFiles: state.uploadingFiles.map((fileModel) =>
          fileModel.catalogueId === action.fileId ? { ...fileModel, uploadState: action.uploadingState } : fileModel
        ),
      };
    }
    case "finish_upload": {
      return {
        ...state,
        totalRecords: state.totalRecords + 1,
        files: [action.fileInfo, ...state.files],
        uploadingFiles: state.uploadingFiles.filter((fileModel) => fileModel.catalogueId !== action.fileId),
      };
    }
    default: {
      return state;
    }
  }
};
