import { Typography } from "@mui/material";
import CenteredWrapper from "../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { Metric } from "../../../api/types/portfolioMonitoringTypes";
import { columnDefinitions } from "./metricsGridDataProvider";

interface Props {
  metrics: Metric[];
  isLoading: boolean;
}

const MetricsGrid = ({ metrics, isLoading }: Props) => {
  return (
    <DataGrid<Metric>
      rows={metrics}
      columns={columnDefinitions}
      loading={isLoading}
      columnHeaderHeight={36}
      rowHeight={40}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableColumnSorting
      disableRowSelectionOnClick
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="text.secondary">No metrics yet</Typography>
          </CenteredWrapper>
        ),
      }}
    />
  );
};

export default MetricsGrid;
