import { Stack, SxProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  sx?: SxProps;
}

const ConsentDialogContent = ({ sx, children }: PropsWithChildren<Props>) => {
  const defaultCommonSx: SxProps = {
    minHeight: "60vh",
  };

  const combinedSx: SxProps = { ...defaultCommonSx, ...sx };

  return <Stack sx={combinedSx}>{children}</Stack>;
};

export default ConsentDialogContent;
