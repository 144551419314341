const fileExtensionRegExp = /(?:(\.[^.]+))?$/;

const imageFileExtensions = [".png", ".jpg", ".jpeg", ".gif", ".webp"];

export const getFileExtension = (filename: string): string =>
  filename.toLowerCase().match(fileExtensionRegExp)?.[1] || "";

export const isPdfFile = (filename: string): boolean => getFileExtension(filename) === ".pdf";

export const isImageFile = (filename: string): boolean => imageFileExtensions.includes(getFileExtension(filename));

export const formatFileSize = (fileSize: number): string => {
  if (Number.isNaN(fileSize) || !Number.isFinite(fileSize)) {
    return "";
  }

  if (fileSize <= 0) {
    return "0 bytes";
  }

  const units = ["bytes", "kB", "MB", "GB", "TB"];
  const unitIndex = Math.min(Math.floor(Math.log(fileSize) / Math.log(1024)), units.length - 1);
  const sizeInUnit = Math.round(fileSize / Math.pow(1024, unitIndex));
  return `${sizeInUnit} ${units[unitIndex]}`;
};
