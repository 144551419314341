import { ListItemText, MenuItem, Select, SelectProps } from "@mui/material";

type Props = {
  precision: number;
  maxPrecision: number;
  onPrecisionChange: (precision: number) => void;
} & SelectProps<number>;

const getDecimalPrecisionLabel = (precision: number) => (precision === 0 ? "1" : `1.${"0".repeat(precision)}`);

const NumberPrecisionSelect = ({ precision, maxPrecision, onPrecisionChange, ...selectProps }: Props) => {
  return (
    <Select<number>
      {...selectProps}
      value={precision}
      onChange={(e) => onPrecisionChange(e.target.value as number)}
      renderValue={(value) => getDecimalPrecisionLabel(value)}
    >
      {Array(maxPrecision + 1)
        .fill(0)
        .map((_, index) => (
          <MenuItem key={`precision-${index}`} value={index}>
            <ListItemText primary={getDecimalPrecisionLabel(index)} />
          </MenuItem>
        ))}
    </Select>
  );
};

export default NumberPrecisionSelect;
