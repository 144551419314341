import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Stack, Tooltip } from "@mui/material";
import { useLocalization } from "../../../../../hooks/useLocalization";

interface Props {
  validationError?: string;
  isLowConfident?: boolean;
}

const InvoiceControlWarning = ({ isLowConfident, validationError }: Props) => {
  const {
    invoices: { low_confidence },
  } = useLocalization();

  const message = validationError || low_confidence;

  return (
    <Tooltip title={message} arrow>
      <Stack
        sx={{
          visibility: isLowConfident || validationError ? "visible" : "hidden",
          cursor: "pointer",
          minWidth: "20px",
        }}
      >
        {validationError ? (
          <ErrorRoundedIcon color="error" />
        ) : isLowConfident ? (
          <WarningRoundedIcon sx={{ color: "warning.light" }} />
        ) : null}
      </Stack>
    </Tooltip>
  );
};

export default InvoiceControlWarning;
