import { SvgIcon, SvgIconProps } from "@mui/material";

const ShowPanelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20, fill: "none" }} {...props}>
      <path
        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM8 19V5H5V19H8ZM10 19H19V5H10V19Z"
        fill="#8E9BA9"
      />
      <path
        d="M15.7656 11.633C15.9216 11.789 16 11.9947 16 12.1994C16 12.4042 15.9216 12.6079 15.7656 12.7639L13.3652 15.1662C13.2112 15.3192 13.0088 15.3987 12.8008 15.3987C12.6978 15.3987 12.5931 15.3791 12.4941 15.3381C12.1941 15.2141 12 14.9228 12 14.5998V13.1994H10C9.448 13.1994 9 12.7514 9 12.1994C9 11.6474 9.448 11.1994 10 11.1994H12V9.79906C12 9.47606 12.1951 9.18477 12.4941 9.06077C12.7941 8.93777 13.1362 9.00465 13.3652 9.23265L15.7656 11.633Z"
        fill="#8E9BA9"
      />
    </SvgIcon>
  );
};

export default ShowPanelIcon;
