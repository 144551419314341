import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";

interface Props {
  onExcludeChange: (id: string) => void;
  onRevertExcludedChange: (id: string) => void;
  onCancelEditing: (id: string) => void;
  onApplyChanges: (id: string) => void;
}

const DataImportChangesListActionsContext = createContext<Props | undefined>(undefined);

export const DataImportChangesListActionsContextProvider = ({
  onExcludeChange,
  onRevertExcludedChange,
  onCancelEditing,
  onApplyChanges,
  children,
}: PropsWithChildren<Props>) => (
  <DataImportChangesListActionsContext.Provider
    value={{ onExcludeChange, onRevertExcludedChange, onCancelEditing, onApplyChanges }}
  >
    {children}
  </DataImportChangesListActionsContext.Provider>
);

export const useDataImportChangesListActionsContext = () =>
  defined(
    useContext(DataImportChangesListActionsContext),
    "Attempt to use DataImportChangesListActionsContext without provider"
  );
