import { createSvgIcon } from "@mui/material";

const CommentDeletedIcon = createSvgIcon(
  <svg viewBox="0 0 66 60" fill="none">
    <path d="M49.4999 16H11.5V20.8193H49.4999V16Z" fill="#C7DBE5" />
    <path d="M39.5 26H11.5V31H39.5V26Z" fill="#C7DBE5" />
    <path d="M3.43604 3V37L3 54.5L17.1284 44H56.9999V3H3.43604Z" stroke="#C7DBE5" strokeWidth="4.8" />
    <path d="M62.0361 38.0001L44.5 55.5361L48.0355 59.0716L65.5716 41.5356L62.0361 38.0001Z" fill="#F44336" />
    <path d="M48.0355 37.9997L44.5 41.5352L62.0361 59.0712L65.5716 55.5357L48.0355 37.9997Z" fill="#F44336" />
  </svg>,
  "CommentDeletedIcon"
);

export default CommentDeletedIcon;
