import SaveIcon from "@mui/icons-material/FileDownloadOutlined";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { convertISODate } from "../../../../shared/utilities/dateUtils";
import { DocumentCollection } from "../../../api/types/documentCollectionTypes";
import CategoryInlineTags from "../../common/CategoryInlineTags";
import DocumentCollectionStatusTag from "./DocumentCollectionStatusTag";

export const getColumns = (onDownloadAllClick: (id: string) => void): GridColDef<DocumentCollection>[] => [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    flex: 1.5,
    minWidth: 160,
    cellClassName: "highlighted-action",
  },
  {
    field: "fundName",
    headerName: "Fund",
    sortable: false,
    flex: 1.5,
    minWidth: 160,
  },
  {
    field: "categories",
    headerName: "Category",
    sortable: false,
    flex: 1.5,
    minWidth: 256,
    renderCell: ({ row }) => <CategoryInlineTags categoryNames={row.categories} maxDisplayedCategories={2} />,
  },
  {
    field: "messageLevel",
    headerName: "Level",
    sortable: false,
    width: 96,
  },
  {
    field: "publishDate",
    headerName: "Publish Date",
    sortable: false,
    width: 120,
    valueFormatter: (value) => convertISODate(value),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 120,
    renderCell: ({ row }) => <DocumentCollectionStatusTag status={row.status} />,
  },
  {
    field: "numberOfDocuments",
    type: "number",
    headerName: "Documents",
    sortable: false,
    width: 96,
  },
  {
    field: "totalEmails",
    headerName: "Emails",
    headerAlign: "center",
    align: "center",
    sortable: false,
    width: 112,
    renderCell: ({ row }) =>
      row.totalEmails ? (
        <Tooltip title={row.notDeliveredEmails > 0 ? "All Emails / Not Delivered Emails" : "All Emails"} arrow>
          <Stack direction="row">
            <Typography>{row.totalEmails}</Typography>
            {row.notDeliveredEmails > 0 && (
              <>
                <Typography>&nbsp;/&nbsp;</Typography>
                <Typography color="error">{row.notDeliveredEmails}</Typography>
              </>
            )}
          </Stack>
        </Tooltip>
      ) : (
        <Tooltip title="No Emails" arrow>
          <Typography>-</Typography>
        </Tooltip>
      ),
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    resizable: false,
    width: 32,
    cellClassName: "grid-row-actions",
    renderCell: ({ row }) => (
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onDownloadAllClick(row.id);
        }}
      >
        <SaveIcon />
      </IconButton>
    ),
  },
];
