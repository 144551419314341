import { SvgIconComponent } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  Icon: SvgIconComponent;
  label: string;
}

const ImportDataDetailsAttribute = ({ Icon, label, children }: PropsWithChildren<Props>) => {
  return (
    <Stack direction="row" spacing={1.5} width="100%" alignItems="flex-start">
      <Stack direction="row" alignItems="center" spacing={1.5} width="10rem" py={1}>
        <Icon color="action" />
        <Typography color="text.secondary">{label}</Typography>
      </Stack>
      <Stack py={1}>{children}</Stack>
    </Stack>
  );
};

export default ImportDataDetailsAttribute;
