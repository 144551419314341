import { Link, MenuItem, Typography } from "@mui/material";

export const ActionMenuItemLink = (props: {
  icon: JSX.Element;
  title: string;
  disabled?: boolean;
  href: string;
  onClick: () => void;
}) => {
  const { icon, title, disabled, href, onClick } = props;

  return (
    <MenuItem
      onClick={onClick}
      sx={(theme) => ({
        svg: {
          color: theme.palette.secondary.light,
        },
        p: 0,
      })}
      disabled={disabled}
    >
      <Link
        href={href}
        target="_blank"
        underline="none"
        sx={(theme) => ({ color: theme.palette.text.primary, px: 2, py: 0.75, display: "flex", width: "100%" })}
      >
        {icon}
        <Typography sx={{ pl: 1 }}>{title}</Typography>
      </Link>
    </MenuItem>
  );
};

export default ActionMenuItemLink;
