import { Stack, Tooltip } from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import InvoicesLineWarningTitle from "./InvoicesLineWarningTitle";

interface Props {
  description: { validationError?: string; isLowConfident?: boolean };
  account: { validationError?: string; isLowConfident?: boolean };
  amount: { validationError?: string; isLowConfident?: boolean };
}

const InvoicesLineWarningCell = ({ account, amount, description }: Props) => {
  const hasErrors = [account, amount, description].some((v) => v.validationError);
  const hasLowConfidence = [account, amount, description].some((v) => v.isLowConfident);

  return (
    <Tooltip arrow title={<InvoicesLineWarningTitle description={description} account={account} amount={amount} />}>
      <Stack
        sx={{
          visibility: hasErrors || hasLowConfidence ? "visible" : "hidden",
          cursor: "pointer",
          minWidth: "20px",
        }}
      >
        {hasErrors ? (
          <ErrorRoundedIcon color="error" />
        ) : hasLowConfidence ? (
          <WarningRoundedIcon sx={{ color: "warning.light" }} />
        ) : null}
      </Stack>
    </Tooltip>
  );
};

export default InvoicesLineWarningCell;
