import { Paper, Stack, Typography } from "@mui/material";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import ConsentCategoryChip from "./ConsentCategoryChip";
import ConsentSettings from "./ConsentSettings";
import { useConsentsContext } from "./ConsentsContext";

const LastModified = ({ lastModified }: { lastModified: string }) => {
  return (
    <Stack spacing={0.5} direction="row" alignItems="center">
      <Typography variant="caption" color="textSecondary">
        Last modified:
      </Typography>
      <Typography variant="caption">{convertISODateTime(lastModified)}</Typography>
    </Stack>
  );
};

const ConsentEdit = () => {
  const { editableConsent, removeEditableConsent } = useConsentsContext();

  const { name, lastModified, isNew, categoryId } = editableConsent || {};

  return (
    <Paper sx={{ width: "40rem" }}>
      <Stack px={3} py={1.5} spacing={isNew ? 1 : 3}>
        <Stack direction="row" spacing={1} alignItems="start" justifyContent="space-between">
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">{`User consent ${name}`}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {!isNew && <ConsentCategoryChip categoryId={categoryId} />}
              {lastModified && !isNew && <LastModified lastModified={lastModified} />}
            </Stack>
          </Stack>
          <CloseIconButton onClick={() => removeEditableConsent()} />
        </Stack>
        <ConsentSettings />
      </Stack>
    </Paper>
  );
};

export default ConsentEdit;
