import { clientCodeHeader } from "../../shared/api/clientApiContext";
import storage from "../storage/storage";

export const getDefaultHeaders = () => {
  const headers: Record<string, string> = clientCodeHeader();

  if (storage.getBetaAccessEnabled()) {
    headers["x-entrilia-beta-access"] = "all";
  }

  return headers;
};
