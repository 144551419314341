import { Stack } from "@mui/material";
import { CrmObjectSyncSettings, ObjectFieldDefinition } from "../../../../api/crmConnectorsApi";
import Arrows from "../../../common/Arrows";
import MappingObjectText from "./MappingObjectText";
import CrmFilterEdit from "./CrmFilterEdit";

interface Props {
  objectSyncSettings: CrmObjectSyncSettings;
  crmFieldsObject?: Record<string, ObjectFieldDefinition>;
  isEditing?: boolean;
  onFilterValueChange?: (filterValue: string) => void;
  crmFilterValue: string | undefined;
}

const getEntriliaObjectText = (objectSyncSettings: CrmObjectSyncSettings) => objectSyncSettings.entriliaObjectName;

const formatFilterValue = (filterValue: string, filterField: string, crmObjectName: string) =>
  `${crmObjectName} (${filterField}: ${filterValue})`;

const getCrmObjectText = (objectSyncSettings: CrmObjectSyncSettings, crmFilterValue: string | undefined) => {
  const { crmObjectName, accountTypeFilterField } = objectSyncSettings;
  return accountTypeFilterField && crmFilterValue
    ? formatFilterValue(crmFilterValue, accountTypeFilterField, crmObjectName)
    : crmObjectName;
};

const getFilterOptions = (
  objectSyncSettings: CrmObjectSyncSettings,
  crmFieldsObject: Record<string, ObjectFieldDefinition> | undefined
) => {
  if (crmFieldsObject === undefined) {
    return undefined;
  }

  const { crmObjectName, accountTypeFilterField } = objectSyncSettings;
  if (accountTypeFilterField === undefined) {
    return undefined;
  }

  return crmFieldsObject[accountTypeFilterField]?.allowedValues?.map((v) => ({
    value: v.value,
    text: formatFilterValue(v.value, accountTypeFilterField, crmObjectName),
  }));
};

const CrmObjectMapping = ({
  objectSyncSettings,
  crmFilterValue,
  isEditing,
  crmFieldsObject,
  onFilterValueChange,
}: Props) => {
  const handleFilterValueChange = (filterValue: string) => onFilterValueChange && onFilterValueChange(filterValue);
  const options = getFilterOptions(objectSyncSettings, crmFieldsObject);

  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <MappingObjectText text={getEntriliaObjectText(objectSyncSettings)} />
      <Arrows width={13} left />
      {isEditing && options ? (
        <CrmFilterEdit value={crmFilterValue} options={options} onChange={handleFilterValueChange} />
      ) : (
        <MappingObjectText text={getCrmObjectText(objectSyncSettings, crmFilterValue)} />
      )}
    </Stack>
  );
};

export default CrmObjectMapping;
