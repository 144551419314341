import MailIcon from "@mui/icons-material/MailOutlineRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import {
  combineValidators,
  emailValidator,
  maxCharactersValidator,
  requiredValidator,
  uniqueValidator,
  ValidationResult,
} from "../../../../../../../shared/utilities/validators";

interface Props {
  onClose: () => void;
  onCreate: (name: string, email: string) => void;
  existingContactNames: string[];
  existingContactEmails: string[];
}

const maxNameLength = 100;

interface FormState {
  name: string;
  email: string;
  nameValidationResult: ValidationResult;
  emailValidationResult: ValidationResult;
}

const CreateContactDialog = ({ onClose, onCreate, existingContactNames, existingContactEmails }: Props) => {
  const [formState, setFormState] = useState<FormState>({
    name: "",
    email: "",
    nameValidationResult: { isValid: false, error: "" },
    emailValidationResult: { isValid: false, error: "" },
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validate = combineValidators(
      requiredValidator,
      maxCharactersValidator(maxNameLength),
      uniqueValidator("A contact with this name already exists", existingContactNames)
    );

    setFormState((prev) => ({
      ...prev,
      name: e.target.value,
      nameValidationResult: validate(e.target.value),
    }));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validate = combineValidators(
      requiredValidator,
      emailValidator,
      uniqueValidator("A contact with this email already exists", existingContactEmails)
    );

    setFormState((prev) => ({
      ...prev,
      email: e.target.value,
      emailValidationResult: validate(e.target.value.toLowerCase()),
    }));
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Contact</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Full Name"
            variant="outlined"
            value={formState.name}
            onChange={handleNameChange}
            helperText={formState.nameValidationResult.error}
            error={!!formState.nameValidationResult.error}
            inputProps={{ maxLength: maxNameLength }}
            InputProps={{
              endAdornment: (
                <Typography color="text.secondary" variant="caption">
                  {maxNameLength - formState.name.length}
                </Typography>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={formState.email}
            onChange={handleEmailChange}
            helperText={formState.emailValidationResult.error}
            error={!!formState.emailValidationResult.error}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!formState.nameValidationResult.isValid}
          onClick={() => onCreate(formState.name, formState.email.toLowerCase())}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContactDialog;
