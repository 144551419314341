// https://en.wikipedia.org/wiki/Template:Most_traded_currencies
export const majorCurrencyCodes = [
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "CNY",
  "AUD",
  "CAD",
  "CHF",
  "HKD",
  "SGD",
  "SEK",
  "NZD",
  "KRW",
  "NOK",
  "MXN",
  "INR",
  "TWD",
  "ZAR",
  "BRL",
  "DKK",
].sort((a, b) => a.localeCompare(b));
