import { Stack, StackProps, Typography, useTheme } from "@mui/material";
import { convertISODateTime } from "../../../shared/utilities/dateUtils";

interface Props {
  createdAt?: string;
  modifiedAt?: string;
  stackProps?: StackProps;
}
const CreatedAndModifiedTimestamps = ({ createdAt, modifiedAt, stackProps }: Props) => {
  const theme = useTheme();
  const labelColor = theme.palette.text.secondary;
  return (
    <Stack {...stackProps}>
      {createdAt && (
        <Typography>
          <span style={{ color: labelColor }}>Created at: </span> {convertISODateTime(createdAt)}
        </Typography>
      )}
      {modifiedAt && (
        <Typography>
          <span style={{ color: labelColor }}> Modified at:</span> {convertISODateTime(modifiedAt)}
        </Typography>
      )}
    </Stack>
  );
};

export default CreatedAndModifiedTimestamps;
