import { Box, DialogContent, DialogTitle } from "@mui/material";
import PoweredBy from "../PoweredBy";
import HtmlPreview from "../htmlEditor/HtmlPreview";
import ConsentAgreementClientTitle from "./ConsentAgreementClientTitle";
import ConsentAgreementDefaultContent from "./ConsentAgreementDefaultContent";

interface ConsentDialogBodyProps {
  clientTitle?: string;
  logoUrl?: string;
  consentContent?: string;
  saveButton: React.ReactNode;
  acceptFormGroup: React.ReactNode;
  disabled?: boolean;
  showTitle?: boolean;
  title: string;
}

const ConsentDialogBody = ({
  saveButton,
  acceptFormGroup,
  logoUrl,
  clientTitle,
  consentContent,
  showTitle,
  title,
}: ConsentDialogBodyProps) => {
  return (
    <>
      <DialogTitle>
        <ConsentAgreementClientTitle logoUrl={logoUrl} clientTitle={clientTitle} showTitle={showTitle} />
      </DialogTitle>
      <DialogContent dividers={consentContent !== undefined}>
        <Box
          px={1}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              minWidth: "27rem",
            },
          })}
        >
          {consentContent === undefined ? (
            <ConsentAgreementDefaultContent title={title} />
          ) : (
            <HtmlPreview htmlContent={consentContent} />
          )}
        </Box>
      </DialogContent>
      <Box px={3} pb={2}>
        {acceptFormGroup}

        {saveButton}

        <Box mt={4} display="flex" justifyContent="flex-end">
          <PoweredBy />
        </Box>
      </Box>
    </>
  );
};

export default ConsentDialogBody;
