import { logError } from "../../../../shared/logging";
import { EntityFieldComponentPropsBase } from "../entityFieldTypes";
import {
  fieldsEditSettings,
  getInvalidFieldValueError,
  getMultiselectOptionsFromValue,
  isStringArray,
} from "../helpers";
import EntityFieldMultiSelectComponent from "./EntityFieldMultiSelectComponent";

const EntityMultiselectUdfField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (!isStringArray(value) && typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityMultiselectUdfField]");
    return null;
  }

  if (fieldConfiguration?.$type !== "UserDefinedOptionsSelect") {
    const error = `Invalid configuration for Multi-Select UDF field: ${fieldConfiguration?.$type}`;
    logError(error, "[EntityMultiselectUdfField]");
    return null;
  }

  return (
    <EntityFieldMultiSelectComponent
      values={getMultiselectOptionsFromValue(value)}
      options={fieldConfiguration.userDefinedOptions}
      fieldAttributes={fieldAttributes}
      fieldInputId={fieldInputId}
      onChange={(newValues, validationResult) =>
        onChange(
          newValues.length > 0 ? newValues.join(fieldsEditSettings.multiselectItemsSeparator) : undefined,
          fieldId,
          validationResult
        )
      }
    />
  );
};

export default EntityMultiselectUdfField;
