import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { CrmObjectSyncSettings, CrmSyncObjectKey } from "../../../../api/crmConnectorsApi";
import { useCrmConnectorContext } from "../CrmConectorContext";
import CrmObjectMapping from "./CrmObjectMapping";
import FieldMappingDialog from "./FieldMappingDialog";

interface ObjectMappingRowProps {
  objectSyncSettings: CrmObjectSyncSettings;
  onFieldMappingClick: () => void;
}

const ObjectMappingRow = ({ objectSyncSettings, onFieldMappingClick }: ObjectMappingRowProps) => (
  <Stack direction="row" spacing={2} mb={2}>
    <CrmObjectMapping
      objectSyncSettings={objectSyncSettings}
      crmFilterValue={objectSyncSettings.accountTypeFilterValue}
    />
    <Button variant="text" color="secondary" startIcon={<SyncAltIcon />} onClick={onFieldMappingClick}>
      Field Mapping
    </Button>
  </Stack>
);

const ConnectionManagement = () => {
  const [editObjectKey, setEditObjectKey] = useState<CrmSyncObjectKey>();

  const { crmName, connectorSettings } = useCrmConnectorContext();

  const handleFieldMappingClick = (key: CrmSyncObjectKey) => () => setEditObjectKey(key);
  const handleFieldMappingCancel = () => setEditObjectKey(undefined);

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Objects synchronization
      </Typography>
      <Typography mb={3} color="text.secondary">
        Specify the data you want to synchronize
      </Typography>
      <Stack direction="row" spacing={36} mt={3} mb={2}>
        <Typography variant="subtitle2">Entrilia</Typography>
        <Typography variant="subtitle2">{crmName}</Typography>
      </Stack>
      <ObjectMappingRow
        objectSyncSettings={connectorSettings.syncSettings.crmContactsSyncSettings}
        onFieldMappingClick={handleFieldMappingClick("crmContactsSyncSettings")}
      />
      <ObjectMappingRow
        objectSyncSettings={connectorSettings.syncSettings.crmInvestorsSyncSettings}
        onFieldMappingClick={handleFieldMappingClick("crmInvestorsSyncSettings")}
      />
      {editObjectKey && (
        <FieldMappingDialog objectKey={editObjectKey} open={!!editObjectKey} onCancel={handleFieldMappingCancel} />
      )}
    </>
  );
};

export default ConnectionManagement;
