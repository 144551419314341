import { createSvgIcon } from "@mui/material";

const CreateNewFolderIcon = createSvgIcon(
  <svg viewBox="0 0 18 18" fill="currentColor">
    <path d="M10.5 12H12V10.5H13.5V9H12V7.5H10.5V9H9V10.5H10.5V12ZM3 15C2.5875 15 2.23438 14.8531 1.94063 14.5594C1.64688 14.2656 1.5 13.9125 1.5 13.5V4.5C1.5 4.0875 1.64688 3.73438 1.94063 3.44063C2.23438 3.14688 2.5875 3 3 3H7.5L9 4.5H15C15.4125 4.5 15.7656 4.64688 16.0594 4.94063C16.3531 5.23438 16.5 5.5875 16.5 6V13.5C16.5 13.9125 16.3531 14.2656 16.0594 14.5594C15.7656 14.8531 15.4125 15 15 15H3ZM3 13.5H15V6H8.38125L6.88125 4.5H3V13.5Z" />
  </svg>,
  "CreateNewFolderIcon"
);

export default CreateNewFolderIcon;
