import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { DwhShare } from "../../../api/types/dwhTypes";
import { useClientContext } from "../../../context/ClientContext";
import useConfirmationPopUp from "../../../hooks/useConfirmationPopup";
import DwhShareCreatedIcon from "../../../icons/DwhShareCreatedIcon";
import AddShareDialog from "./AddShareDialog";
import DeleteShareDialog from "./DeleteShareDialog";
import DwhSharesTable from "./DwhSharesTable";
import UpdateShareDialog from "./UpdateShareDialog";

const DwhDataSharing = () => {
  const { hasPermissions } = useClientContext();

  const [resp, fetchError, { fetch: fetchShares }] = useFetch(adminApi.getDwhShares);
  const [openAddShareDialog, setOpenAddShareDialog] = useState(false);
  const [editingShare, setEditingShare] = useState<DwhShare>();
  const [deletingShare, setDeletingShare] = useState<DwhShare>();
  const { ConfirmationPopupComponent, setConfirmationPopupData } = useConfirmationPopUp();

  if (fetchError) {
    logError(fetchError.message || "getDwhShares failed", "DwhDataSharing");
    return <DataLoadingFailed title="Loading DWH shares failed" />;
  }

  if (resp === undefined) {
    return <InlineLoader />;
  }

  const hasAccessToManage = hasPermissions(["ManageClientIntegrations"]);

  const handleShareButtonClick = () => {
    if (hasAccessToManage) {
      setOpenAddShareDialog(true);
    }
  };

  const handleEditClick = (share: DwhShare) => {
    if (hasAccessToManage) {
      setEditingShare(share);
    }
  };

  const handleDeleteClick = (share: DwhShare) => {
    if (hasAccessToManage) {
      setDeletingShare(share);
    }
  };

  const handleConfirmShareCreatedClose = async () => {
    setConfirmationPopupData(undefined);
    await fetchShares();
  };

  const handleShareCreated = (accountCode: string) => {
    setOpenAddShareDialog(false);

    setConfirmationPopupData({
      title: "Sharing is successfully created!",
      description: accountCode,
      icon: <DwhShareCreatedIcon sx={{ width: 80, height: 80 }} />,
      onClose: handleConfirmShareCreatedClose,
    });
  };

  const handleShareUpdated = async () => {
    setEditingShare(undefined);
    await fetchShares();
  };

  const handleShareDeleted = async () => {
    setDeletingShare(undefined);
    await fetchShares();
  };

  return (
    <>
      <Stack spacing={2.5}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="subtitle1">Data Sharing</Typography>
            <Typography color="text.secondary">
              Share data hosted by Entrilia with another Snowflake account.
            </Typography>
          </Stack>
          {hasAccessToManage && (
            <Button variant="contained" onClick={handleShareButtonClick}>
              Share Data
            </Button>
          )}
        </Stack>
        <DwhSharesTable
          shares={resp.shares}
          readOnly={!hasAccessToManage}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      </Stack>

      <AddShareDialog
        open={openAddShareDialog}
        onCancel={() => setOpenAddShareDialog(false)}
        onShareCreated={handleShareCreated}
      />
      <UpdateShareDialog
        share={editingShare}
        onCancel={() => setEditingShare(undefined)}
        onShareUpdated={handleShareUpdated}
      />
      <DeleteShareDialog
        share={deletingShare}
        onCancel={() => setDeletingShare(undefined)}
        onShareDeleted={handleShareDeleted}
      />
      {ConfirmationPopupComponent}
    </>
  );
};

export default DwhDataSharing;
