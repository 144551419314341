import AddIcon from "@mui/icons-material/Add";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Button, Stack } from "@mui/material";
import { useMembersContext } from "../../MembersContext";

const MembersGridActionsBar = () => {
  const {
    hasAccessToManageOrganizationUsers,
    filters,
    openInviteMembersDialog,
    isLoading,
    exportToCsv,
    members,
    filteredMembers,
  } = useMembersContext();

  const disableExportToCsv =
    isLoading || members.length === 0 || (filteredMembers.length === 0 && filters.search.trim() !== "");

  if (!hasAccessToManageOrganizationUsers) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Button
        sx={{ whiteSpace: "nowrap" }}
        disabled={disableExportToCsv}
        onClick={exportToCsv}
        variant="outlined"
        startIcon={<ExitToAppIcon />}
      >
        Export to CSV
      </Button>
      <Button
        sx={{ whiteSpace: "nowrap" }}
        onClick={openInviteMembersDialog}
        variant="contained"
        startIcon={<AddIcon />}
      >
        Invite Members
      </Button>
    </Stack>
  );
};

export default MembersGridActionsBar;
