import { Button, Stack, Tooltip } from "@mui/material";
import { useInviteMembersContext } from "./InviteMembersContext";

const DistributeCompanyPermissions = () => {
  const { distributePermissions } = useInviteMembersContext();

  return (
    <Stack>
      <Tooltip placement="top" title="Apply current permissions to all companies" arrow>
        <Button onClick={distributePermissions}>Distribute permissions</Button>
      </Tooltip>
    </Stack>
  );
};

export default DistributeCompanyPermissions;
