import { generatePath, useMatch } from "react-router-dom";
import { useClientContext } from "../../context/ClientContext";
import { pageRoutes } from "../../routes";
import FeatureToggleBoundary from "../common/FeatureToggleBoundary";
import MenuLinkItem from "../common/MenuLinkItem";
import SubMenuList from "./SubMenuList";

const SettingsMenu = () => {
  const { hasAnyPermission, isFundAdmin, clientCode, reportingPortalApp } = useClientContext();

  const routePattern = `/:clientCode/${pageRoutes.settings}/:page/*`;
  const routeMatch = useMatch(routePattern);
  const currentPage = routeMatch?.params["page"];

  const hasAccessToOrganizationUsers = hasAnyPermission(["ManageOrganizationUsers", "ViewOrganizationUsers"]);
  const hasAccessToCompanySettings = hasAnyPermission(["ViewCompanySettings", "ManageCompanySettings"]);
  const hasAccessToIntegrationSettings = hasAnyPermission(["ViewClientIntegrations", "ManageClientIntegrations"]);
  const hasAccessToImportData = hasAnyPermission([
    "ViewInvestorRelationsDataImports",
    "ManageInvestorRelationsDataImports",
    "ViewPortfolioMonitoringDataImports",
    "ManagePortfolioMonitoringDataImports",
    "ViewExpenseManagementDataImports",
    "ManageExpenseManagementDataImports",
  ]);
  const hasAccessToOrganizationDataModel = hasAnyPermission([
    "ViewOrganizationDataModel",
    "ManageOrganizationDataModel",
  ]);

  const buildFullPath = (page: string) => generatePath(routePattern, { clientCode, page });

  return (
    <>
      {isFundAdmin && (
        <SubMenuList>
          {hasAccessToOrganizationUsers && (
            <MenuLinkItem
              subItem
              active={currentPage === pageRoutes.settingsMembers}
              title="Members"
              href={buildFullPath(pageRoutes.settingsMembers)}
            />
          )}
          {hasAccessToOrganizationDataModel && (
            <MenuLinkItem
              subItem
              active={currentPage === pageRoutes.dataModel}
              title="Data Model"
              href={buildFullPath(pageRoutes.dataModel)}
            />
          )}
        </SubMenuList>
      )}

      {!isFundAdmin && (
        <SubMenuList>
          {hasAccessToCompanySettings && (
            <MenuLinkItem
              subItem
              active={currentPage === pageRoutes.settingsCompanyGeneral}
              title="General"
              href={buildFullPath(pageRoutes.settingsCompanyGeneral)}
            />
          )}
          {hasAccessToIntegrationSettings && (
            <MenuLinkItem
              subItem
              active={currentPage === pageRoutes.settingsIntegrations}
              title="Apps & Integrations"
              href={buildFullPath(pageRoutes.settingsIntegrations)}
            />
          )}
          {hasAccessToImportData && !!reportingPortalApp && (
            <MenuLinkItem
              subItem
              active={currentPage === pageRoutes.importData}
              title="Import Data"
              href={buildFullPath(pageRoutes.importData)}
            />
          )}
          {hasAccessToCompanySettings && (
            <FeatureToggleBoundary featureToggle="CustomObjectsEnabled">
              <MenuLinkItem
                subItem
                active={currentPage === pageRoutes.records}
                title="Other Records"
                href={buildFullPath(pageRoutes.records)}
              />
            </FeatureToggleBoundary>
          )}
        </SubMenuList>
      )}
    </>
  );
};

export default SettingsMenu;
