import { Stack, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers-pro";
import { addHours, getHours, isValid, startOfToday } from "date-fns";

interface Props {
  hour?: number;
  readonly: boolean;
  onHourChange: (hour?: number) => void;
  validationError?: string;
}

const DataBackupConfigurationTimeSettings = ({ hour, onHourChange, readonly, validationError }: Props) => {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Typography variant="subtitle2">Settings</Typography>
        <Typography color="secondary">
          Set the time for the daily backup. The selected time is displayed in your local timezone.
        </Typography>
      </Stack>
      <TimePicker
        views={["hours"]}
        disableOpenPicker
        ampm={true}
        sx={(t) => ({ maxWidth: t.spacing(12) })}
        value={hour !== undefined ? addHours(startOfToday(), hour) : null}
        onChange={(newValue) => {
          if (newValue && isValid(newValue)) {
            onHourChange(getHours(newValue.getTime()));
          } else {
            onHourChange(undefined);
          }
        }}
        disabled={readonly}
        slotProps={{
          textField: {
            error: !!validationError,
          },
        }}
      />
    </Stack>
  );
};

export default DataBackupConfigurationTimeSettings;
