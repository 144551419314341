import { Parser } from "@json2csv/plainjs";
import { convertISODateTimeShort } from "../../../../../shared/utilities/dateUtils";
import { formatDurationInSeconds } from "../../../../../shared/utilities/formatters";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";

const createCsvParser = (): Parser<DocumentViews> =>
  new Parser<DocumentViews>({
    fields: [
      {
        label: "Contact Name",
        value: (row) => row.userInfo.userName,
      },
      {
        label: "Contact Email",
        value: (row) => row.userInfo.userName,
      },
      {
        label: "Investor",
        value: (row) => row.documentInfo.investorNames.join(", "),
      },
      {
        label: "Fund",
        value: (row) => row.documentInfo.fundNames.join(", "),
      },
      {
        label: "Category",
        value: (row) => row.documentInfo.categories.join(", "),
      },
      {
        label: "Document",
        value: (row) => row.documentInfo.fileName,
      },
      {
        label: "Viewed at",
        value: (row) => convertISODateTimeShort(row.sessionStart),
      },
      {
        label: "View Duration",
        value: (row) => (row.totalViewDuration ? formatDurationInSeconds(row.totalViewDuration) : ""),
      },
      {
        label: "Total Completion",
        value: (row) => (row.completionPercentage ? row.completionPercentage + "%" : ""),
      },
      {
        label: "Downloaded at",
        value: (row) => {
          const lastDownloadTime = row.downloadTimes[row.downloadTimes.length - 1];
          return lastDownloadTime ? convertISODateTimeShort(lastDownloadTime) : "";
        },
      },
    ],
  });

export const getCsvToExport = (rows: DocumentViews[]) => {
  const csvParser = createCsvParser();
  return csvParser.parse(rows);
};
