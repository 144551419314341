import { generatePath, useMatch } from "react-router-dom";
import { useClientContext } from "../../context/ClientContext";
import { pageRoutes } from "../../routes";
import MenuLinkItem from "../common/MenuLinkItem";
import SubMenuList from "./SubMenuList";

const ExpenseManagementMenu = () => {
  const { clientCode, menuBadges } = useClientContext();
  const routePattern = `/:clientCode/${pageRoutes.expenseManagement}/:page/*`;
  const routeMatch = useMatch(routePattern);
  const currentPage = routeMatch?.params["page"];

  const buildFullPath = (page: string) => generatePath(routePattern, { clientCode, page });

  return (
    <SubMenuList>
      <MenuLinkItem
        subItem
        active={currentPage === pageRoutes.invoices}
        title="Invoices"
        href={buildFullPath(pageRoutes.invoices)}
        badgeCount={menuBadges?.pendingInvoiceCount}
      />
      <MenuLinkItem
        subItem
        active={currentPage === pageRoutes.vendors}
        title="Vendors"
        href={buildFullPath(pageRoutes.vendors)}
      />
    </SubMenuList>
  );
};

export default ExpenseManagementMenu;
