import { Box, Button, Typography } from "@mui/material";

interface Props {
  onClick: () => void;
}

const AddNoteButton = ({ onClick }: Props) => (
  <Box width="100%" pt={2} pb={1}>
    <Button
      fullWidth
      variant="outlined"
      color="secondary"
      sx={{ bgcolor: "background.grey", justifyContent: "flex-start", py: 1 }}
      onClick={onClick}
    >
      <Typography color="text.secondary">Add note</Typography>
    </Button>
  </Box>
);

export default AddNoteButton;
