import { Typography, TypographyProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  maxLines: number;
  typographyProps?: TypographyProps;
}
const TypographyMultilineEllipsis = ({ maxLines, typographyProps, children }: PropsWithChildren<Props>) => {
  const { sx, ...props } = typographyProps ?? {};

  return (
    <Typography
      {...props}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: maxLines,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default TypographyMultilineEllipsis;
