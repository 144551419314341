import { TextField } from "@mui/material";

interface Props {
  communicationGroupName: string | undefined;
}

const CommunicationGroupNameControl = ({ communicationGroupName }: Props) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label="Communication Group"
      value={communicationGroupName || ""}
      aria-readonly={true}
      disabled
    />
  );
};

export default CommunicationGroupNameControl;
