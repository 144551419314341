import { DwhSyncLogs } from "../../../../api/types/dwhTypes";
import { LogSeverity } from "../../../../api/types/logTypes";
import { formatLogTimestamp } from "../../../common/syncLogs/utils";

export interface LogsTableRowData {
  key: string;
  severity: LogSeverity;
  completed: string;
  eventType: string;
  numberOfChanges: number;
  details: DwhSyncLogs;
}

const itemToRowData = (item: DwhSyncLogs, index: number): LogsTableRowData => ({
  key: `${index}_${item.startTime}_${item.endTime}`,
  severity: item.severity,
  completed: formatLogTimestamp(item.endTime),
  eventType: item.eventType,
  numberOfChanges: item.numberOfChanges,
  details: item,
});

export const getRows = (items: DwhSyncLogs[]): LogsTableRowData[] => items.map((item, i) => itemToRowData(item, i));
