import { Grid, Stack } from "@mui/material";
import { PropsWithChildren, useEffect } from "react";
import { Outlet } from "react-router-dom";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import biClient from "../../../../shared/reporting/api/biClient";
import { GroupsContextProvider } from "../../../../shared/reporting/components/groups/contexts/GroupsContext";
import useGroups from "../../../../shared/reporting/components/groups/hooks/useGroups";
import { ReportTemplatesContextProvider } from "../../../../shared/reporting/contexts/ReportTemplatesContext";
import { useSharedReportingLocalization } from "../../../../shared/reporting/hooks/useLocalization";
import { useClientContext } from "../../../context/ClientContext";
import ReportTemplatesUiManager from "./contexts/ReportTemplatesUiManager";
import useReportTemplates from "./templates/hooks/useReportTemplates";

export default function OrganizationTemplatesPage() {
  const { report_templates: locale } = useSharedReportingLocalization();
  const { clientCode } = useClientContext();
  const {
    actions: reportTemplateActions,
    templates,
    ui: templatesUi,
  } = useReportTemplates({ getTemplates: biClient.getOrganizationReportTemplates });
  const { groups, actions: groupActions, ui: groupsUi } = useGroups({ ...biClient.reportTemplateGroup });

  useEffect(() => {
    groupActions.load(clientCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCode, groupActions.load]);

  if (!templatesUi.loaded || !groupsUi.loaded) {
    return (
      <FullSize>
        <InlineLoader text={locale.loading_label} />
      </FullSize>
    );
  }

  if (templatesUi.errorLoad !== undefined || groupsUi.error !== undefined) {
    return (
      <FullSize>
        <DataLoadingFailed />
      </FullSize>
    );
  }

  return (
    <Grid container flex={1} height="100%">
      <ReportTemplatesContextProvider actions={reportTemplateActions} reportTemplates={templates} ui={templatesUi}>
        <GroupsContextProvider
          groups={groups}
          ui={groupsUi}
          actions={{ ...groupActions, reloadReports: reportTemplateActions.reloadReportTemplates }}
        >
          <ReportTemplatesUiManager onDeleted={() => reportTemplateActions.reloadReportTemplates()}>
            <Outlet />
          </ReportTemplatesUiManager>
        </GroupsContextProvider>
      </ReportTemplatesContextProvider>
    </Grid>
  );
}

function FullSize({ children }: PropsWithChildren) {
  return <Stack sx={{ height: "100%", width: "100%" }}>{children}</Stack>;
}
