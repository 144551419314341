import DesktopIcon from "@mui/icons-material/DesktopWindowsRounded";
import { Button, ButtonProps, Link } from "@mui/material";
import { useClientContext } from "../../../context/ClientContext";
import { getInvestorPortalImpersonationUrl } from "../../../utilities/navigationHelper";

interface Props {
  portalUrlSubPath?: string;
  userEmail?: string;
  fundraisingId?: string;
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  label?: string;
}

const InvestorPortalImpersonateButton = ({
  portalUrlSubPath,
  userEmail,
  fundraisingId,
  variant,
  color,
  label,
}: Props) => {
  const { clientCode, hasAnyPermission } = useClientContext();
  const investorPortalUrl = getInvestorPortalImpersonationUrl(
    clientCode,
    portalUrlSubPath ?? "",
    userEmail,
    fundraisingId
  );

  const canInspectInvestorPortal = hasAnyPermission(["ViewInvestorPortalOnBehalfOfInvestor"]);

  if (!canInspectInvestorPortal) {
    return null;
  }

  return (
    <Button
      component={Link}
      variant={variant}
      color={color}
      href={investorPortalUrl}
      target="_blank"
      rel="noopener noreferrer"
      startIcon={variant !== "text" && <DesktopIcon />}
      sx={{ whiteSpace: "nowrap" }}
    >
      {label ?? "Impersonate"}
    </Button>
  );
};

export default InvestorPortalImpersonateButton;
