import { useGridApiContext } from "@mui/x-data-grid-premium";
import { PropsWithChildren, useCallback, useMemo } from "react";
import CustomDataGridHeaderToolbar from "./CustomDataGridHeaderToolbar";
import { checkSelection } from "../../../../shared/utilities/dataGridApiHelper";

interface Props {
  headerHeight: number;
  selectedIds: string[];
}

const CustomDataGridHeaderWithSelectCheckboxes = ({
  children,
  headerHeight,
  selectedIds,
}: PropsWithChildren<Props>) => {
  const apiRef = useGridApiContext();

  const { children: selectionChildren, checked } = useMemo(() => checkSelection(apiRef), [apiRef]);

  const clearSelection = useCallback(() => {
    apiRef.current.selectRows(selectionChildren, false);
  }, [apiRef, selectionChildren]);

  return (
    <CustomDataGridHeaderToolbar
      sx={{
        px: 0,
        pl: 0.7,
        height: headerHeight,
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
      fullSelection={checked}
      selection={[selectedIds, clearSelection]}
    >
      {children}
    </CustomDataGridHeaderToolbar>
  );
};

export default CustomDataGridHeaderWithSelectCheckboxes;
