const valueMatchesSearchTerm = (value: string | undefined, searchTerm: string) =>
  (value ?? "").toLowerCase().includes(searchTerm.toLowerCase());

export const createSearchFilter =
  <T>(minLength: number, filterFields: (i: T) => Array<string | undefined>) =>
  (items: T[], searchTerm: string): T[] =>
    searchTerm.length >= minLength
      ? items.filter((item) => filterFields(item).some((fieldValue) => valueMatchesSearchTerm(fieldValue, searchTerm)))
      : items;

export const createNestedSearchFilter =
  <T extends { [key in P]: N[] }, N, P extends keyof T>(
    minLength: number,
    filterFields: (i: T) => Array<string | undefined>,
    nestedItemsProp: P,
    nestedFilterFields: (i: N) => Array<string | undefined>
  ) =>
  (items: T[], searchTerm: string): T[] => {
    if (searchTerm.length < minLength) {
      return items;
    }

    return items.reduce<T[]>((result, item) => {
      if (filterFields(item).some((fieldValue) => valueMatchesSearchTerm(fieldValue, searchTerm))) {
        result.push(item);
      } else {
        const nestedItems = item[nestedItemsProp].filter((item) =>
          nestedFilterFields(item).some((fieldValue) => valueMatchesSearchTerm(fieldValue, searchTerm))
        );

        if (nestedItems.length > 0) {
          result.push({
            ...item,
            [nestedItemsProp]: nestedItems,
          });
        }
      }

      return result;
    }, []);
  };
