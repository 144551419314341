import { Container, Stack } from "@mui/material";
import { useClientContext } from "../../../context/ClientContext";
import ApiGatewayIcon from "../../../icons/ApiGatewayIcon";
import IntegrationHeader from "../integration/IntegrationHeader";
import ApiGatewayTabs from "./ApiGatewayTabs";

const promotionText =
  "Manage subscriptions to the Entrilia API by granting users access to the Entrilia Developer Portal. This enables software developers to utilize Entrilia APIs, access documentation, and manage API keys, facilitating integration development and data management within Entrilia.";

const ApiGatewayPage = () => {
  const { apiGatewayApp } = useClientContext();

  return (
    <>
      <IntegrationHeader
        title="API Gateway"
        Icon={ApiGatewayIcon}
        text={promotionText}
        appStatus={apiGatewayApp?.status}
        maxWidth="lg"
      />
      <Container maxWidth="lg" disableGutters sx={{ mx: 0 }}>
        <Stack px={3} spacing={3} mt={3}>
          <ApiGatewayTabs />
        </Stack>
      </Container>
    </>
  );
};

export default ApiGatewayPage;
