import { Grid, Stack, Typography } from "@mui/material";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import CompanyIcon from "../../../../../../icons/CompanyIcon";

export default function NoCompanySelected() {
  const { reports_copyto: locale } = useLocalization();
  return (
    <Grid container sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Stack sx={{ flexDirection: "column", alignItems: "center", gap: 3, textAlign: "center" }}>
        <CompanyIcon sx={{ height: "51px", width: "54px" }} />
        <Typography color="secondary">{locale.no_company_selected}</Typography>
      </Stack>
    </Grid>
  );
}
