import { GridGroupNode, GridRowId } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useCallback, useEffect, useState } from "react";

export const useGridGroupsCollapsed = (apiRef: React.MutableRefObject<GridApiPremium>) => {
  const [collapsedGroupIds, setCollapsedGroupIds] = useState<GridRowId[]>([]);

  const isGroupExpanded = useCallback(
    (groupNode: GridGroupNode) => !collapsedGroupIds.includes(groupNode.id),
    [collapsedGroupIds]
  );

  useEffect(() => {
    return apiRef.current.subscribeEvent("rowExpansionChange", ({ id, childrenExpanded }) => {
      setCollapsedGroupIds((ids) => (childrenExpanded ? ids.filter((nodeId) => nodeId !== id) : [...ids, id]));
    });
  }, [apiRef]);

  return { isGroupExpanded };
};

export const useGridGroupsExpanded = (apiRef: React.MutableRefObject<GridApiPremium>) => {
  const [expandedGroupIds, setExpandedGroupIds] = useState<GridRowId[]>([]);

  const isGroupExpanded = useCallback(
    (groupNode: GridGroupNode) => expandedGroupIds.includes(groupNode.id),
    [expandedGroupIds]
  );

  useEffect(() => {
    return apiRef.current.subscribeEvent("rowExpansionChange", ({ id, childrenExpanded }) => {
      setExpandedGroupIds((ids) => (childrenExpanded ? [...ids, id] : ids.filter((nodeId) => nodeId !== id)));
    });
  }, [apiRef]);

  return { isGroupExpanded };
};
