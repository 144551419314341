import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";

interface ConsentUpdateVariantDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (updateVersion: boolean) => void;
}

const ConsentUpdateVariantDialog = ({ open, onClose, onSave }: ConsentUpdateVariantDialogProps) => {
  const [selectedOption, setSelectedOption] = useState<string>("new");

  const getOptionFrameStyle = (selected: boolean) => ({
    border: 1,
    borderColor: selected ? "primary.main" : "divider",
    borderRadius: 2,
    p: 1,
  });

  const handleOptionChange = (value: "new" | "all") => {
    setSelectedOption(value);
  };

  const handleSaveClick = () => {
    onSave(selectedOption === "all");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6">Consent update</Typography>
        <CloseIconButton onClick={onClose} sx={{ padding: 0 }} />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ pb: 2 }}>
          <Stack spacing={0.5}>
            <Typography variant="h6">Version update</Typography>
            <Typography color="textSecondary">Select who has to agree with the updated consent version.</Typography>
          </Stack>

          <Stack sx={{ ...getOptionFrameStyle(selectedOption === "new") }}>
            <FormControlLabel
              value="new"
              control={<Radio color="primary" />}
              label={
                <Stack spacing={0.5}>
                  <Typography variant="subtitle2">New users</Typography>
                  <Typography>Only newly registered portal users will agree to the updated version.</Typography>
                </Stack>
              }
              checked={selectedOption === "new"}
              onChange={() => handleOptionChange("new")}
            />
          </Stack>

          <Stack sx={{ ...getOptionFrameStyle(selectedOption === "all") }}>
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label={
                <Stack spacing={0.5}>
                  <Typography variant="subtitle2">All Users</Typography>
                  <Typography>
                    All portal users have to agree with the updated version. <br /> The prompt will be displayed on
                    their next activity on the portal.
                  </Typography>
                </Stack>
              }
              checked={selectedOption === "all"}
              onChange={() => handleOptionChange("all")}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ borderTop: 1, borderColor: "divider", px: 2, py: 1.5 }}>
        <Button onClick={onClose} variant="text" color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSaveClick} variant="contained">
          Update and Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentUpdateVariantDialog;
