import { BackupMasterApplicationLogs } from "../../../../api/adminApi";
import { LogSeverity } from "../../../../api/types/logTypes";
import { formatLogTimestamp } from "../../../common/syncLogs/utils";

export interface LogsTableRowData {
  key: string;
  severity: LogSeverity;
  completed: string;
  details: BackupMasterApplicationLogs;
  description: string;
}

const itemToRowData = (item: BackupMasterApplicationLogs, index: number): LogsTableRowData => ({
  key: `${index}_${item.startTime}_${item.endTime}`,
  severity: item.severity,
  completed: formatLogTimestamp(item.endTime),
  details: item,
  description: item.description,
});

export const getRows = (items: BackupMasterApplicationLogs[]): LogsTableRowData[] => items.map(itemToRowData);
