import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";
import { logError } from "../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../shared/services/downloadFile";
import adminApi from "../../../api/adminApi";
import { DocumentCollection } from "../../../api/types/documentCollectionTypes";
import { useClientContext } from "../../../context/ClientContext";
import { pageRoutes } from "../../../routes";
import { getColumns } from "./documentCollectionsDataProvider";

interface Props {
  documentCollections: DocumentCollection[];
  loading: boolean;
  selectedId?: string;
}

const getDownloadUrlForDocumentCollection = withErrorHandling(adminApi.getDownloadUrlForDocumentCollection);

const DocumentCollectionsTable = ({ documentCollections, loading, selectedId }: Props) => {
  const navigate = useNavigate();
  const { clientCode } = useClientContext();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row, field }) => {
    if (field === "name") {
      navigate(`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.documentCollections}/${row.id}`);
    }
  };

  const handleDownloadAllClick = async (id: string) => {
    const [url, error] = await getDownloadUrlForDocumentCollection(id, clientCode);
    if (error) {
      logError(error, "[DocumentCollectionsTable] handleDownloadAllClick");
      return;
    }

    downloadFileFromUrl(url);
  };

  return (
    <DataGrid<DocumentCollection>
      columns={getColumns(handleDownloadAllClick)}
      rows={documentCollections}
      rowHeight={40}
      loading={loading}
      slots={{ loadingOverlay: () => <InlineLoader /> }}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableRowSelectionOnClick
      onCellClick={handleCellClick}
      selectableRows
      rowSelectionModel={selectedId}
    />
  );
};

export default DocumentCollectionsTable;
