import TimeIcon from "@mui/icons-material/AccessTimeRounded";
import CheckIcon from "@mui/icons-material/CheckRounded";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import useFetch from "../../../../../shared/hooks/useFetch";
import useSetInterval from "../../../../../shared/hooks/useSetInterval";
import { logError } from "../../../../../shared/logging";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import adminApi from "../../../../api/adminApi";

const getInvestorPortalSyncStatus = withErrorHandling(adminApi.getInvestorPortalSyncStatus);

const InvestorPortalSyncStatusInfo = () => {
  const [syncStatus, , { setData: setSyncStatus }] = useFetch(adminApi.getInvestorPortalSyncStatus);

  const updateSyncStatus = useCallback(async () => {
    const [resp, error] = await getInvestorPortalSyncStatus();
    if (error) {
      logError(error, "[InvestorPortalSyncStatusInfo] getInvestorPortalSyncStatus");
    } else {
      setSyncStatus(resp);
    }
  }, [setSyncStatus]);

  useSetInterval(updateSyncStatus, 12_000);

  if (syncStatus === undefined) {
    return null;
  }

  const { isSyncActive, lastSyncTimestamp } = syncStatus;
  if (isSyncActive) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <TimeIcon color="action" />
        <Typography>Sync in progress...</Typography>
      </Stack>
    );
  }

  if (lastSyncTimestamp) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <CheckIcon color="primary" />
        <Typography>
          <Typography component="span" color="text.secondary">
            Last Sync:
          </Typography>
          <Typography component="span"> {convertISODateTime(lastSyncTimestamp)}</Typography>
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CheckIcon color="primary" />
      <Typography>Sync successful</Typography>
    </Stack>
  );
};

export default InvestorPortalSyncStatusInfo;
