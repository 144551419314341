import { SvgIcon, SvgIconProps } from "@mui/material";

const EmailIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 64 54" {...props}>
    <path
      d="M60.9996 45.1499H0.0996094V0.149902H60.9996V45.1499ZM5.09961 40.1499H55.9996V5.1499H5.09961V40.1499Z"
      fill="#C7DBE5"
    />
    <path
      d="M31.5998 22.8498L30.3998 22.2498C21.3998 17.5498 2.0998 5.24981 1.2998 4.74981L3.9998 0.549805C4.1998 0.649805 22.2998 12.1498 31.4998 17.1498C37.6998 13.4498 56.8998 0.749805 57.1998 0.549805L59.9998 4.74981C59.0998 5.34981 37.2998 19.7498 32.8998 22.2498L31.5998 22.8498Z"
      fill="#C7DBE5"
    />
    <path
      d="M49.2 46.2498C45.8 46.2498 43 43.4498 43 40.0498V37.7498C43 34.3498 45.8 31.5498 49.2 31.5498C52.6 31.5498 55.4 34.3498 55.4 37.7498V40.0498C55.4 43.5498 52.6 46.2498 49.2 46.2498ZM49.2 36.6498C48.6 36.6498 48 37.1498 48 37.8498V40.1498C48 40.7498 48.5 41.3498 49.2 41.3498C49.9 41.3498 50.4 40.8498 50.4 40.1498V37.8498C50.4 37.1498 49.8 36.6498 49.2 36.6498Z"
      fill="#00A866"
    />
    <path
      d="M49.0992 53.8499C40.9992 53.8499 34.2992 47.2499 34.1992 39.1499C34.1992 35.1499 35.6992 31.4499 38.4992 28.5499C41.2992 25.7499 44.9992 24.1499 48.9992 24.1499C52.9992 24.1499 56.6992 25.6499 59.4992 28.4499C62.3992 31.2499 63.8992 35.0499 63.8992 39.0499V39.4499C63.8992 43.2499 60.7992 46.2499 57.0992 46.2499C53.3992 46.2499 50.2992 43.1499 50.2992 39.4499V32.8499H55.2992V39.4499C55.2992 40.4499 56.0992 41.2499 57.0992 41.2499C58.0992 41.2499 58.8992 40.4499 58.8992 39.4499V39.0499C58.8992 36.3499 57.7992 33.8499 55.8992 31.9499C53.9992 30.0499 51.5992 29.0499 48.9992 29.0499C48.9992 29.0499 48.9992 29.0499 48.8992 29.0499C46.2992 29.0499 43.7992 30.1499 41.8992 31.9499C39.9992 33.8499 38.9992 36.3499 39.0992 38.9499C39.1992 44.3499 43.5992 48.7499 48.9992 48.7499C50.8992 48.7499 52.7992 48.1499 54.3992 47.1499L57.0992 51.3499C54.8992 53.0499 52.0992 53.8499 49.0992 53.8499C49.1992 53.8499 49.0992 53.8499 49.0992 53.8499Z"
      fill="#00A866"
    />
  </SvgIcon>
);

export default EmailIcon;
