import { Avatar, Box, Stack, Switch, Typography } from "@mui/material";
import { allObjectFeatures, ObjectFeature } from "../../../../api/types/objectTypes";
import { objectFeatureCaptionMap } from "../../../../utilities/enumCaptions";
import ObjectFeatureIcon from "../../../common/domain-icons/ObjectFeatureIcon";

interface Props {
  features: ObjectFeature[];
  onChange?: (features: ObjectFeature[]) => void;
  disabled?: boolean;
}

const objectFeatureDescriptionMap: Record<ObjectFeature, string> = {
  Notes: "Allows adding notes",
  Files: "Allows attaching files",
  PortfolioMetrics: "Allows creating metrics and metric values for Portfolio Monitoring",
  DataImport: "Allows importing data",
};

const ObjectSupportedFeaturesSection = ({ features, onChange, disabled }: Props) => {
  return (
    <Stack spacing={1.5}>
      {allObjectFeatures.map((feature) => (
        <Box key={feature} width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1.5}>
            <Avatar variant="rounded" sx={{ height: 32, width: 32 }}>
              <ObjectFeatureIcon objectFeature={feature} color="action" />
            </Avatar>
            <Stack>
              <Typography variant="subtitle2">{objectFeatureCaptionMap[feature]}</Typography>
              <Typography color="text.secondary">{objectFeatureDescriptionMap[feature]}</Typography>
            </Stack>
          </Stack>
          <Switch
            checked={features.includes(feature)}
            onChange={(_, checked) => {
              if (!onChange) {
                return;
              }
              const newFeatures = checked ? [...features, feature] : features.filter((f) => f !== feature);
              onChange(newFeatures);
            }}
            disabled={disabled}
          />
        </Box>
      ))}
    </Stack>
  );
};

export default ObjectSupportedFeaturesSection;
