import { SvgIcon, SvgIconProps } from "@mui/material";

const ReportingIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <g clipPath="url(#clip0_5454_5779)">
      <path
        d="M40 0H8C3.58172 0 0 3.58172 0 8V40C0 44.4183 3.58172 48 8 48H40C44.4183 48 48 44.4183 48 40V8C48 3.58172 44.4183 0 40 0Z"
        fill="#F6F8FA"
      />
      <path d="M43.2 24.8232H5.06668V27.2246H43.2V24.8232Z" fill="#C7DBE5" />
      <path
        d="M40.6546 28.9535H38.2532C38.2052 24.3429 37.3887 16.7547 35.3716 14.7375C34.9874 14.3533 34.6992 14.3053 34.5071 14.3533C32.0577 14.3533 31.0492 19.4922 30.1367 24.0067C28.936 29.962 27.7353 36.1095 22.9807 36.1095C18.3701 36.1095 16.5931 30.4903 15.0082 25.5916C13.8075 21.8455 12.5588 18.0034 10.5897 18.0034C9.67723 18.0034 9.00485 20.1165 8.76472 21.9416H6.36337C6.55548 20.0685 7.41996 15.602 10.5897 15.602C14.3358 15.602 15.8247 20.3086 17.2655 24.8712C18.6582 29.2416 20.0991 33.7081 22.9326 33.7562C25.7182 33.7562 26.7268 28.5693 27.7353 23.5745C28.888 17.9073 30.0406 12 34.4591 12C35.3716 12 36.2361 12.3842 37.0045 13.1046C40.3184 16.2264 40.6546 26.36 40.6546 28.9535Z"
        fill="#00A866"
      />
    </g>
    <defs>
      <clipPath id="clip0_5454_5779">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ReportingIcon;
