import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ReportGroup } from "../../../../../../../shared/reporting/api/biClient.types";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import { SelectedCompany, SelectedCompanyReport } from "../hooks/SelectedCompany";

interface Props {
  company: SelectedCompany;
  companyReport: SelectedCompanyReport;
  updateCompanyReport: (report: SelectedCompanyReport, changes: Partial<SelectedCompanyReport>) => void;
}
export default function Group({ company, companyReport, updateCompanyReport }: Props) {
  const { reports_copyto: locale } = useLocalization();
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [groups, setGroups] = useState<ReportGroup[]>([]);

  useEffect(() => {
    let actualGroups = [...(company.groups || [])];
    if (!companyReport.result?.group.exist) {
      actualGroups = [
        ...(company.groups || []),
        {
          id: companyReport.result?.group.id || "new",
          caption: companyReport.result?.group.name || "New Group",
          order: -1,
        },
      ];
    }
    actualGroups.sort((a, b) => a.order - b.order);
    setGroups(actualGroups);
    setSelectedGroup(companyReport.selectedGroup?.id || companyReport.result?.group.id || "new");
  }, [
    company.groups,
    companyReport.result?.group.exist,
    companyReport.result?.group.id,
    companyReport.result?.group.name,
    companyReport.selectedGroup?.id,
  ]);

  const handleChange = useCallback(
    (groupId: string) => {
      setSelectedGroup(groupId);
      updateCompanyReport(companyReport, {
        selectedGroup: groups.find((g) => g.id === groupId && g.id !== "new"),
      });
    },
    [companyReport, groups, updateCompanyReport]
  );

  const isNewGroup = useCallback(
    (caption: string) => {
      return companyReport.result?.group.exist === false && companyReport.result?.group.name === caption;
    },
    [companyReport.result?.group.exist, companyReport.result?.group.name]
  );

  return (
    <FormControl>
      <InputLabel id="group-select-label">Group</InputLabel>
      <Select
        labelId="group-select-label"
        label="Group"
        value={selectedGroup}
        disabled={company.groupsLoading}
        renderValue={(value) => <Typography>{groups.find((g) => g.id === value)?.caption || ""}</Typography>}
        onChange={(e) => handleChange(e.target.value)}
      >
        {groups.map((group) => (
          <MenuItem
            key={group.id}
            value={group.id}
            sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}
          >
            <Typography>{group.caption}</Typography>
            {isNewGroup(group.caption) && (
              <Typography variant="caption" color={(theme) => `${theme.palette.secondary.main} !important`}>
                {locale.will_be_added_to_new_group}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
