import { Outlet } from "react-router-dom";
import { NotificationContextProvider } from "../../../../../../shared/contexts/NotificationContext";
import biApi from "../../../../../api/biApi";
import { UiReportsContextProvider } from "../../../reporting/reports/ui-reports/contexts/ReportsContext";
import UiReportsManager from "../../../reporting/reports/ui-reports/contexts/ReportsUiManager";
import useReports from "../../../reporting/hooks/useReports";
import { GroupsContextProvider } from "../../../../../../shared/reporting/components/groups/contexts/GroupsContext";
import useGroups from "../../../../../../shared/reporting/components/groups/hooks/useGroups";
import biClient from "../../../../../../shared/reporting/api/biClient";
import { Grid } from "@mui/material";
import {
  BiApiClientBase,
  BiApiClientProvider,
} from "../../../../../../shared/reporting/contexts/BiApiClientProviderContext";
import GlobalNotificationSnackbar from "../../../../../../shared/components/GlobalNotificationSnackbar";
import GlobalNotificationErrorSnackbar from "../../../../../../shared/components/GlobalNotificationErrorSnackbar";

const OrganizationReportsPage = () => {
  const { reports, ui, actions } = useReports(biApi.uiOrganizationReports.getOrganizationReports, "Ui");
  const { groups, actions: groupActions, ui: groupsUi } = useGroups({ ...biClient.reportGroup });

  return (
    <Grid container flex={1} width={"100%"}>
      <BiApiClientProvider<BiApiClientBase>
        api={{
          validateName: biClient.uiReports.validateReportName,
          getReportSharing: biClient.uiReports.getReportSharing,
          updateReportSharing: biClient.uiReports.updateReportSharing,
        }}
      >
        <NotificationContextProvider>
          <UiReportsContextProvider reports={reports} ui={{ loaded: ui.loaded, error: ui.error }} actions={actions}>
            <GroupsContextProvider
              groups={groups}
              ui={groupsUi}
              actions={{ ...groupActions, reloadReports: actions.reloadReports }}
            >
              <UiReportsManager
                onCopied={actions.reloadReports}
                onDeleted={actions.reloadReports}
                reports={reports}
                deleteReport={actions.deleteReports}
                duplicate={actions.duplicateReport}
                generateApiReport={actions.generateApiReport}
              >
                <Outlet />
                <GlobalNotificationSnackbar />
                <GlobalNotificationErrorSnackbar />
              </UiReportsManager>
            </GroupsContextProvider>
          </UiReportsContextProvider>
        </NotificationContextProvider>
      </BiApiClientProvider>
    </Grid>
  );
};

export default OrganizationReportsPage;
