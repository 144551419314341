import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi, { Invoice, InvoiceProcessingStatus } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import {
  getInitialPaginatedItemsState,
  getPagingParams,
  loadItemsAction,
  nextPageAction,
  PaginatedItemsState,
} from "../../../../state/paginatedState";
import RecordCounter from "../../../common/filters/RecordCounter";
import { InvoicesTab } from "./InvoicesTab";
import InvoicesGrid from "./grid/InvoicesGrid";

const mapToInvoiceProcessingStatus = (tab: InvoicesTab): InvoiceProcessingStatus[] | undefined => {
  switch (tab) {
    case InvoicesTab.InProgress:
      return [InvoiceProcessingStatus.Pending];
    case InvoicesTab.TransactionCreated:
      return [InvoiceProcessingStatus.TransactionCreated];
    case InvoicesTab.TransactionPosted:
      return [InvoiceProcessingStatus.TransactionPosted];
    case InvoicesTab.Rejected:
      return [InvoiceProcessingStatus.Rejected];
    default:
      return undefined;
  }
};

interface Props {
  tab: InvoicesTab;
}

const InvoicesList = ({ tab }: Props) => {
  const { updateMenuBadges } = useClientContext();
  const [invoicesState, setInvoicesState] = useState<PaginatedItemsState<Invoice>>(getInitialPaginatedItemsState());

  const getInvoices = useCallback(
    () =>
      adminApi.getInvoices({
        paging: getPagingParams(invoicesState.page),
        statuses: mapToInvoiceProcessingStatus(tab),
      }),
    [tab, invoicesState.page]
  );

  const [, getInvoicesError, { isFetching }] = useFetch(getInvoices, (data) => {
    setInvoicesState(loadItemsAction(data));
    if (tab === InvoicesTab.InProgress) {
      updateMenuBadges();
    }
  });

  const handleRowScrollEnd = () => {
    setTimeout(() => {
      setInvoicesState(nextPageAction());
    });
  };

  if (getInvoicesError) {
    logError(getInvoicesError, "[InvoicesList]");
    return <DataLoadingFailed bgColor="none" title="Could not load invoices" />;
  }

  return (
    <Stack width="100%" height="100%" spacing={2.5} flex={1} py={1}>
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
        <RecordCounter
          records={invoicesState.items.length}
          total={invoicesState.totalRecords}
          hide={isFetching || invoicesState.totalRecords === 0}
        />
      </Stack>
      <InvoicesGrid
        invoices={invoicesState.items}
        loading={isFetching}
        onRowsScrollEnd={handleRowScrollEnd}
        currentStatus={tab}
      />
    </Stack>
  );
};

export default InvoicesList;
