import { useCallback, useEffect, useMemo } from "react";
import { ReportAuthorizationInfo, ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import { useUserContext } from "../../../../../context/UserContext";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { ReportCompany } from "../../../../../../shared/reporting/components/sharing/ShareReport.types";
import ShareReport from "../../../../../../shared/reporting/components/sharing/ShareReport";

interface ShareReportWrapperProps {
  report: ReportInfo;
  onClose: () => void;
  updateReportAuthorizationInfo: (reportId: string, authorizationInfo: ReportAuthorizationInfo) => Promise<void>;
}
export default function ShareReportWrapper({
  report,
  onClose,
  updateReportAuthorizationInfo,
}: ShareReportWrapperProps) {
  const { id, name, email, clients } = useUserContext();
  const { sendNotification } = useNotificationContext();

  const reportCompany = useMemo((): ReportCompany | undefined => {
    const client = clients.find((c) => c.clientCode === report.clientCode);
    return client
      ? { clientCode: client.clientCode, name: client.title, logoMarkUrl: client.branding.logoMarkUrl }
      : undefined;
  }, [clients, report.clientCode]);

  useEffect(() => {
    if (!reportCompany) {
      sendNotification("Failed to load report company. Refresh page and try again.");
      onClose();
    }
  }, [reportCompany, sendNotification, onClose]);

  const handleAuthorizationUpdated = useCallback(
    (authorization: ReportAuthorizationInfo) => {
      updateReportAuthorizationInfo(report.reportId, authorization);
    },
    [updateReportAuthorizationInfo, report.reportId]
  );

  return (
    <ShareReport
      report={report}
      currentUser={{ id, name, email }}
      reportCompany={reportCompany!}
      onClose={onClose}
      onAuthorizationUpdated={handleAuthorizationUpdated}
    />
  );
}
