import CollapseIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandIcon from "@mui/icons-material/ExpandMoreRounded";
import { Button } from "@mui/material";

interface Props {
  expanded: boolean;
  expandButtonText?: string;
  collapseButtonText?: string;
  onClick: () => void;
}

const ShowMoreLessButton = ({ expanded, onClick, expandButtonText, collapseButtonText }: Props) => {
  return (
    <Button variant="text" endIcon={expanded ? <CollapseIcon /> : <ExpandIcon />} sx={{ p: 0 }} onClick={onClick}>
      {expanded ? collapseButtonText || "Show less" : expandButtonText || "Show more"}
    </Button>
  );
};

export default ShowMoreLessButton;
