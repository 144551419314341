import { DataSelector, Parser } from "@json2csv/plainjs";
import { convertISODateShort } from "../../../../../../shared/utilities/dateUtils";
import { CompanyInfo } from "../../../../../api/types/clientTypes";
import {
  AccessRole,
  ClientUserType,
  ClientsAccess,
  OrganizationDeactivatedMember,
  OrganizationMember,
} from "../../../../../api/types/userManagementTypes";
import { entriliaProductCaptionMap } from "../../../../../utilities/enumCaptions";
import {
  CompanyProductWithRoles,
  filterOrganizationRoles,
  formatClientUserType,
  getAllCompanyProductsWithRoles,
  organizationRolesDataMap,
  roleNamesMap,
} from "../definitions";

interface MemberCsvRow {
  isDeactivated: boolean;
  name: string;
  email: string;
  userType?: ClientUserType;
  lastActivity?: string;
  firstAppearanceDate?: string;
  organizationPermissions?: {
    roles: AccessRole[];
  };
  clientsAccess?: ClientsAccess;
  disabledAt?: string;
  companyTitle: string;
}

const getProductPermissionField = (product: CompanyProductWithRoles): DataSelector<MemberCsvRow> => ({
  label: entriliaProductCaptionMap[product.key],
  value: (row) => {
    if (row.clientsAccess === undefined) {
      return "None";
    }

    const productRole = row.clientsAccess.clientPermissions.roles.find((role) => product.roles.includes(role));
    return productRole ? roleNamesMap[productRole] : "None";
  },
});

const createCsvParser = (): Parser<MemberCsvRow> =>
  new Parser<MemberCsvRow>({
    fields: [
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Email",
        value: "email",
      },
      {
        label: "Type",
        value: (row) => (row.userType ? formatClientUserType(row.userType) : ""),
      },
      {
        label: "Status",
        value: (row) => (row.isDeactivated ? "Inactive" : "Active"),
      },
      {
        label: "Organization Role",
        value: (row) => {
          if (row.organizationPermissions === undefined) {
            return "None";
          }
          const orgRoles = filterOrganizationRoles(row.organizationPermissions.roles);
          return orgRoles.length > 0 && orgRoles[0] ? organizationRolesDataMap[orgRoles[0]].label : "None";
        },
      },
      {
        label: "First invited at",
        value: (row) => (row.firstAppearanceDate ? convertISODateShort(row.firstAppearanceDate) : ""),
      },
      {
        label: "Last Activity",
        value: (row) => (row.lastActivity ? convertISODateShort(row.lastActivity) : ""),
      },
      {
        label: "Deactivated at",
        value: (row) => (row.disabledAt ? convertISODateShort(row.disabledAt) : ""),
      },
      {
        label: "Company",
        value: "companyTitle",
      },
      ...getAllCompanyProductsWithRoles().map(getProductPermissionField),
    ],
  });

const getCsvRows = (
  members: OrganizationMember[],
  deactivatedMembers: OrganizationDeactivatedMember[],
  companies: CompanyInfo[]
): MemberCsvRow[] => {
  const sortedCompanies = companies.sort((a, b) => a.title.localeCompare(b.title));
  const result: MemberCsvRow[] = [];

  for (const company of sortedCompanies) {
    const membersForClient = [
      ...members
        .filter((m) => m.userType !== "Support")
        .map((m) => ({
          isDeactivated: false,
          companyTitle: company.title,
          name: m.name,
          email: m.email,
          userType: m.userType,
          lastActivity: m.lastActivity,
          firstAppearanceDate: m.firstAppearanceDate,
          organizationPermissions: m.organizationPermissions,
          clientsAccess: m.clientsAccess.find(({ clientCode }) => clientCode === company.clientCode),
        })),
      ...deactivatedMembers.map((d) => ({
        isDeactivated: true,
        companyTitle: company.title,
        name: d.name,
        email: d.email,
        disabledAt: d.disabledAt,
      })),
    ].sort((a, b) => a.name.localeCompare(b.name));

    result.push(...membersForClient);
  }

  return result;
};

export const getCsvToExport = (
  members: OrganizationMember[],
  deactivatedMembers: OrganizationDeactivatedMember[],
  companies: CompanyInfo[]
) => {
  const csvParser = createCsvParser();
  const csvRows = getCsvRows(members, deactivatedMembers, companies);
  return csvParser.parse(csvRows);
};
