import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import { grey } from "@mui/material/colors";

const StaticCircularProgress = (props: CircularProgressProps) => {
  return (
    <Box sx={{ position: "relative" }} width={props.size} height={props.size}>
      <CircularProgress
        thickness={8}
        {...props}
        variant="determinate"
        value={100}
        sx={{
          color: grey[200],
          position: "absolute",
          left: 0,
        }}
      />
      <CircularProgress
        thickness={8}
        {...props}
        variant="determinate"
        color="primary"
        sx={{
          position: "absolute",
          left: 0,
        }}
      />
    </Box>
  );
};

export default StaticCircularProgress;
