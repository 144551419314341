import { logError } from "../../../shared/logging";
import { Maybe } from "../../../shared/types";
import { ClientDictionaries } from "../../api/types/clientTypes";
import {
  EntityOptionFieldType,
  FieldConfigurationType,
  LookupObjectReference,
  MultiLabelSelectOption,
  SelectOption,
} from "../../api/types/objectTypes";

export const isStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((item) => typeof item === "string");

export const getMultiselectOptionsFromValue = (value: string | string[] | undefined | null) => {
  if (value === undefined || value === null) {
    return [];
  }

  const valueArray = Array.isArray(value) ? value : value.split(fieldsEditSettings.multiselectItemsSeparator);
  return valueArray.filter((item) => Boolean(item.trim()));
};

const optionFieldTypeMap: Record<EntityOptionFieldType, keyof ClientDictionaries> = {
  Country: "countries",
  State: "states",
  Currency: "currencies",
  TaxForm: "taxForms",
  InvestorCategory: "investorCategories",
  FormPfName: "formPfNames",
  Chapter3Status: "chapter3Statuses",
  Chapter4FatcaStatus: "chapter4FatcaStatuses",
  Irs1099Code: "irs1099Codes",
  IndustryOrSector: "industriesAndSectors",
  Geography: "geographies",
};

export const getSelectOptionsFromDictionaries = (
  optionFieldType: EntityOptionFieldType,
  clientDictionaries: ClientDictionaries
): SelectOption[] =>
  clientDictionaries[optionFieldTypeMap[optionFieldType]]
    .filter((o) => !!o.code.trim() && !!o.displayName.trim())
    .map((o) => ({ label: o.displayName, value: o.code }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getSelectOptionsForLookup = (lookupReferences: LookupObjectReference[]): MultiLabelSelectOption[] =>
  lookupReferences.map((ref) => ({
    value: ref.objectId,
    label: ref.primaryFieldValue,
    secondaryLabel: ref.secondaryFieldValue,
  }));

export const getLookupDisplayValue = (
  value: Maybe<string>,
  availableLookupReferences: LookupObjectReference[],
  objectLookupReferences: LookupObjectReference[]
): string | undefined => {
  if (!value) {
    return undefined;
  }

  // First use all reference options if available when editing
  const selectedOption = availableLookupReferences.find((ref) => ref.objectId === value);
  if (selectedOption) {
    return selectedOption.primaryFieldValue;
  }

  // Fallback to lookup objects from stored object - all reference options are not available when lookup field is read-only
  const lookupReference = objectLookupReferences.find((ref) => ref.objectId === value);
  if (lookupReference) {
    return lookupReference.primaryFieldValue;
  }

  return value;
};

export const resolveDictionaryValueFromCode = (
  code: string,
  optionFieldType: EntityOptionFieldType,
  clientDictionaries: ClientDictionaries
) => {
  const dictionary = clientDictionaries[optionFieldTypeMap[optionFieldType]];
  return dictionary?.find((item) => item.code === code)?.displayName;
};

export const getCurrencySymbol = (currencyCode: string) => {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
    })
      .format(0)
      .replace(/[^\p{Sc}]/gu, "");
  } catch (error) {
    logError(error, "getCurrencySymbolByCode");
    return "";
  }
};

export const fieldsEditSettings = {
  multiselectItemsSeparator: ";",
};

export const getInvalidFieldValueError = (
  fieldId: string,
  fieldType: FieldConfigurationType | undefined,
  value: unknown
) => `Invalid value in ${fieldType ?? ""} field ${fieldId}: ${JSON.stringify(value)} (${typeof value})`;
