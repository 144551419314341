import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CopyCompany, CopyCompanyReport } from "./CopyCompany";
import { SelectedCompany } from "./SelectedCompany";

export type CopyingState = {
  companies: CopyCompany[];
};

const initialState: CopyingState = { companies: [] };

const copyingStateSlice = createSlice({
  name: "copyingState",
  initialState: initialState,
  reducers: {
    setCompaniesAction: (state, action: PayloadAction<{ companies: SelectedCompany[] }>) => {
      const { companies } = action.payload;

      state.companies = companies.map(
        (c): CopyCompany => ({
          company: c,
          reports: c.reports.map(
            (r): CopyCompanyReport => ({
              toCompanyCode: c.code,
              copying: false,
              copied: false,
              report: r.report,
              userSelectedGroupId: r.selectedGroup?.id,
            })
          ),
        })
      );
    },
    updateReportAction: (
      state,
      action: PayloadAction<{ report: CopyCompanyReport; changes: Partial<CopyCompanyReport> }>
    ) => {
      const report = state.companies
        .find((c) => c.company.code === action.payload.report.toCompanyCode)
        ?.reports.find((r) => r.report.reportId === action.payload.report.report.reportId);

      if (report) {
        Object.assign(report, action.payload.changes);
      }
    },
  },
});

export default copyingStateSlice;

export const actions = copyingStateSlice.actions;
