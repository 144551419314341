import { useCallback } from "react";
import { useParams } from "react-router-dom";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import DataSubmissionPageTitle from "./DataSubmissionPageTitle";
import DataSubmissionForm from "./submission-forms/DataSubmissionForm";

const DataSubmissionPage = () => {
  const { clientCode } = useClientContext();

  const { id } = useParams();

  const getDataSubmissionDetails = useCallback(() => adminApi.getDataSubmissionDetails(defined(id)), [id]);

  const [submissionDetails, fetchError, { isFetching }] = useFetch(getDataSubmissionDetails);

  if (fetchError) {
    logError(fetchError, "[DataSubmissionPage] getDataSubmissionDetails");
    return <DataLoadingFailed title="Could not load data submission details" />;
  }

  if (isFetching || submissionDetails === undefined) {
    return <InlineLoader />;
  }

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<DataSubmissionPageTitle submissionDetails={submissionDetails} />}
        showDefaultBackButtonTo={`/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataCollections}?view=data_submissions`}
      />
      <DataSubmissionForm layout={submissionDetails.layout} blockContents={submissionDetails.blockContents} />
    </>
  );
};

export default DataSubmissionPage;
