import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { useState } from "react";

export interface ListItemData {
  value: string;
  label: string;
  description?: string;
  iconSrc?: string;
}

interface Props {
  items: ListItemData[];
  preselectedItems: ListItemData[];
  disabledItems?: ListItemData[];
  hideSelectAll?: boolean;
  disablePadding?: boolean;
  primaryTypographyVariant?: Variant;
  managed?: boolean;
  sx?: SxProps<Theme>;
  onSelectedChanged: (selectedItems: ListItemData[]) => void;
}

const CheckItemsList = ({
  items,
  preselectedItems,
  disabledItems,
  hideSelectAll,
  disablePadding,
  primaryTypographyVariant,
  managed,
  sx,
  onSelectedChanged,
}: Props) => {
  const [selectedManagedItems, setSelectedManagedItems] = useState<ListItemData[]>(preselectedItems || []);

  const setSelected = (items: ListItemData[]) => {
    setSelectedManagedItems(items);
    onSelectedChanged(items);
  };

  const selectedItems = managed ? selectedManagedItems : preselectedItems;
  const allSelected = items.length === selectedItems.length;

  const handleSelectAll = () => {
    if (allSelected) {
      setSelected([]);
    } else {
      setSelected(items);
    }
  };

  const handleSelectItem = (item: ListItemData) => {
    const index = selectedItems.findIndex((i) => i.value === item.value);
    if (index === -1) {
      setSelected([...selectedItems, item]);
    } else {
      setSelected(selectedItems.filter((i) => i.value !== item.value));
    }
  };

  const sortedItems = items.sort((a, b) => {
    const aDisabled = (disabledItems || []).some((i) => i.value === a.value);
    const bDisabled = (disabledItems || []).some((i) => i.value === b.value);
    if (aDisabled && !bDisabled) {
      return 1;
    }
    if (!aDisabled && bDisabled) {
      return -1;
    }
    return 0;
  });

  const anyIconExists = sortedItems.some((i) => i.iconSrc);
  const checkboxSize = anyIconExists ? "medium" : "small";

  return (
    <List disablePadding={disablePadding} sx={{ ...sx }}>
      {!hideSelectAll && (
        <ListItem dense disablePadding={disablePadding}>
          <Checkbox
            size={checkboxSize}
            disabled={disabledItems && disabledItems.length === items.length}
            checked={allSelected}
            onChange={handleSelectAll}
            sx={{ mr: anyIconExists ? 1.5 : 0 }}
          />
          <Stack direction={"row"} spacing={1}>
            <Typography>Select all</Typography>
            <Typography color="textSecondary">
              ({selectedItems.length} / {items.length})
            </Typography>
          </Stack>
        </ListItem>
      )}
      {sortedItems.map((item) => (
        <ListItem
          disablePadding={disablePadding}
          disabled={(disabledItems || []).some((i) => i.value === item.value)}
          key={item.value}
          dense
          button
          onClick={() => handleSelectItem(item)}
        >
          <Checkbox size={checkboxSize} checked={selectedItems.some((i) => i.value === item.value)} />
          {item.iconSrc && (
            <ListItemIcon sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={item.iconSrc} alt="" width={24} height={24} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={<Typography variant={primaryTypographyVariant}>{item.label}</Typography>}
            secondary={item.description}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CheckItemsList;
