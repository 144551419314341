import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { Category } from "../../../../../../api/types/accessTypes";

export interface AccessValue {
  selectedCategoryIds: string[];
}

interface Props {
  value: AccessValue;
  onChange: (update: Partial<AccessValue>) => void;
  categories: Category[];
}

const AccessEditor = ({ value, onChange, categories }: Props) => {
  const handleAllCategoriesChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange({ selectedCategoryIds: checked ? categories.map((c) => c.externalId) : [] });
  };

  const handleCategoryChange = (categoryId: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange({
      selectedCategoryIds: checked
        ? [...value.selectedCategoryIds, categoryId]
        : value.selectedCategoryIds.filter((id) => id !== categoryId),
    });
  };

  const areAllCategoriesSelected = value.selectedCategoryIds.length === categories.length;
  const isAnyCategorySelected = value.selectedCategoryIds.length > 0;

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
        Access Categories
      </Typography>
      <Stack spacing={0.5} pt={0.5}>
        {categories.length > 1 && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>All Categories</Typography>
            <Checkbox
              checked={areAllCategoriesSelected}
              indeterminate={!areAllCategoriesSelected && isAnyCategorySelected}
              onChange={handleAllCategoriesChange}
            />
          </Box>
        )}
        {categories.map((category) => (
          <Box key={category.externalId} display="flex" justifyContent="space-between" alignItems="center">
            <Typography>{category.name}</Typography>
            <Checkbox
              checked={value.selectedCategoryIds.includes(category.externalId)}
              onChange={handleCategoryChange(category.externalId)}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default AccessEditor;
