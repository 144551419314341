export interface ChatItem {
  id: string;
  source: "user" | "assistant";
  isPending?: boolean;
  messageText?: string;
  imageUrl?: string;
  webUrl?: string;
}

export const addChatItems = (newItems: ChatItem[]) => (prevItems: ChatItem[]) => [...prevItems, ...newItems];

export const updateResponseItem = (itemId: string, messageText: string, imageUrl?: string) => (prevItems: ChatItem[]) =>
  prevItems.map((item) => (item.id === itemId ? { ...item, isPending: false, messageText, imageUrl } : item));

export const removeLastPendingItem = () => (prevItems: ChatItem[]) => {
  const lastPendingItemIndex = prevItems.findIndex((item) => item.isPending);
  if (lastPendingItemIndex === -1) {
    return prevItems;
  }
  return [...prevItems.slice(0, lastPendingItemIndex), ...prevItems.slice(lastPendingItemIndex + 1)];
};
