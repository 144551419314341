const defaultStyles = {
  content: '""',
  display: "block",
  position: "absolute",
  zIndex: 0,
  height: 10,
  width: 10,
  bgcolor: "background.paper",
  transform: "translateY(-50%) rotate(45deg)",
};

export const cornersBeforePositions = {
  left: (offsetFrom: "top" | "bottom", offset: number) => ({
    ...defaultStyles,
    [offsetFrom]: offset,
    left: -5,
  }),
  top: (offsetFrom: "left" | "right", offset: number) => ({
    ...defaultStyles,
    top: 0,
    [offsetFrom]: offset,
  }),
} as const;
