import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { formatISO, isAfter, isValid } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { createApiResponse } from "../../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import DateSelector from "../../../../../../shared/components/DateSelector";
import {
  getBalanceFieldSet,
  mapFieldsToTabularConfiguration,
} from "../../../../../../shared/components/balances/balanceFieldset";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { pastQuarterLastDay } from "../../../../../../shared/utilities/dateUtils";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi, { InvestorBalanceResponse } from "../../../../../api/adminApi";
import InvestorBalanceTable from "./InvestorBalanceTable";

const isValidDate = (date: Date) => {
  if (!isValid(date)) {
    return false;
  }

  const year = date.getFullYear();
  if (year < 1970) {
    return false;
  }

  const isInFuture = isAfter(date, new Date());
  if (isInFuture) {
    return false;
  }

  return true;
};

const InvestorDetailsInvestmentsTab = ({ investorId }: { investorId: string }) => {
  const pastQLastDay = pastQuarterLastDay(new Date());
  const [reportingDate, setReportingDate] = useState<Date | undefined | null>(pastQLastDay);
  const [balanceResponse, setBalanceResponse] = useState<InvestorBalanceResponse>();
  const [isInvestorPortalMode, setIsInvestorPortalMode] = useState<boolean>(false);

  const fields = useMemo(() => mapFieldsToTabularConfiguration(getBalanceFieldSet(false)), []);

  const [dates, datesFetchError] = useFetch(adminApi.getInitialInvestmentsData);

  const fetchInvestorBalance = useCallback(() => {
    if (!reportingDate || !isValidDate(reportingDate)) {
      return Promise.resolve(createApiResponse(undefined));
    }

    return adminApi.getInvestorBalance(investorId, {
      reportingDate: formatISO(reportingDate, { representation: "date" }),
      fields,
      isInvestorPortalMode,
    });
  }, [fields, investorId, isInvestorPortalMode, reportingDate]);

  const [, balanceFetchError] = useFetch(fetchInvestorBalance, (data) => {
    setBalanceResponse(data);
  });

  const onReportingDateChange = useDebounce((date: Date | null) => setReportingDate(date), 300);

  const onInvestorPortalModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isIpMode = event.target.checked;
    if (isIpMode) {
      if (dates && dates.length > 0) {
        const [firstReportingDate] = dates;
        setReportingDate(new Date(defined(firstReportingDate)));
      } else {
        setBalanceResponse(undefined);
      }
    } else {
      setReportingDate(pastQLastDay);
    }
    setIsInvestorPortalMode(isIpMode);
  };

  if (datesFetchError || balanceFetchError) {
    logError(datesFetchError || balanceFetchError, "InvestorInvestmentsPage");
    return <DataLoadingFailed bgColor="none" title="Loading investor investments failed" />;
  }

  if (!dates) {
    return <InlineLoader />;
  }

  const isLoading = !dates || !balanceResponse;

  return (
    <Stack spacing={2.5} height="100%">
      <Stack justifyContent={"space-between"} direction={"row"}>
        <Stack direction={"row"} spacing={2} alignItems="center">
          <Typography color="text.secondary">Effective reporting date:</Typography>
          {isInvestorPortalMode && (
            <DateSelector
              disabled={isLoading}
              initialDate={reportingDate}
              optionDates={dates.map((d) => new Date(d))}
              onChange={setReportingDate}
            />
          )}
          {!isInvestorPortalMode && (
            <DatePicker
              disableFuture
              disabled={isLoading}
              value={reportingDate}
              onChange={onReportingDateChange}
              slotProps={{
                textField: {
                  size: "small",
                  sx: (theme) => ({ width: theme.spacing(20) }),
                },
              }}
            />
          )}
        </Stack>
        <FormControlLabel
          value="end"
          control={
            <Switch
              disabled={isLoading}
              checked={isInvestorPortalMode}
              onChange={onInvestorPortalModeChange}
              color="primary"
            />
          }
          label="Investor Portal Data"
          labelPlacement="end"
        />
      </Stack>
      <InvestorBalanceTable balanceResponse={balanceResponse} isLoading={isLoading} />
    </Stack>
  );
};

export default InvestorDetailsInvestmentsTab;
