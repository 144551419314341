import { Autocomplete, FormControl, ListItem, TextField, Typography } from "@mui/material";
import { PassthroughFundClosing } from "../../../../../api/types/passthroughTypes";

interface Props {
  disabled?: boolean;
  value: PassthroughFundClosing | undefined;
  options: PassthroughFundClosing[];
  onChange: (value: PassthroughFundClosing | undefined) => void;
}

const FundClosingControl = ({ disabled, value, options, onChange }: Props) => {
  return (
    <FormControl size="small">
      <Autocomplete
        fullWidth
        value={value || null}
        options={options}
        onChange={(_, value) => onChange(value || undefined)}
        getOptionLabel={({ name }) => name}
        renderInput={(inputProps) => <TextField {...inputProps} placeholder={"Fund Closing"} label={"Fund Closing"} />}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.id}>
            <Typography>{option.name}</Typography>
          </ListItem>
        )}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default FundClosingControl;
