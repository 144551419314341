import { useCallback } from "react";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../../api/adminApi";
import { DocumentInfo, InvestorDeliveryInfo, MessageLevel } from "../../../../../../api/types/documentCollectionTypes";
import { useClientContext } from "../../../../../../context/ClientContext";
import EmptyDocumentPreview from "../../../../../common/document-preview/EmptyDocumentPreview";
import MultiDocumentPreview from "../../../../../common/document-preview/MultiDocumentPreview";
import DocumentDetails from "./DocumentDetails";
import DocumentPreviewWrapper from "./DocumentPreviewWrapper";

interface Props {
  messageLevel: MessageLevel;
  documents: DocumentInfo[];
  investor?: InvestorDeliveryInfo;
}

const getDownloadUrl = withErrorHandling(adminApi.getDownloadUrlForSelectedDocuments);

const DocumentPreviewContainer = ({ messageLevel, documents, investor }: Props) => {
  const { clientCode } = useClientContext();

  const getDownloadUrlForSelectedDocuments = useCallback(() => {
    const documentIdentifiers = documents.map((doc) => ({
      id: doc.id,
      postMessageRequestId: doc.postMessageRequestId,
    }));

    return getDownloadUrl(documentIdentifiers, clientCode);
  }, [documents, clientCode]);

  const hidePreviewTab = messageLevel === "Fund" && investor !== undefined;
  const hideDetailsTab = messageLevel === "Fund" && investor === undefined;

  return (
    <DocumentPreviewWrapper>
      {documents.length === 0 && <EmptyDocumentPreview />}
      {documents.length === 1 && (
        <DocumentDetails
          messageLevel={messageLevel}
          document={defined(documents[0])}
          investor={investor}
          hidePreviewTab={hidePreviewTab}
          hideDetailsTab={hideDetailsTab}
        />
      )}
      {documents.length > 1 && (
        <MultiDocumentPreview
          documentCount={documents.length}
          getDownloadUrlForSelectedDocuments={getDownloadUrlForSelectedDocuments}
        />
      )}
    </DocumentPreviewWrapper>
  );
};

export default DocumentPreviewContainer;
