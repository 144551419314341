import { Typography } from "@mui/material";
import CenteredWrapper from "../../../../../shared/components/CenteredWrapper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { DataImportInfo } from "../../../../api/types/dataImportTypes";
import { nextPageAction } from "../../../../state/paginatedState";
import { useImportDataContext } from "../ImportDataContext";
import { getImportDataGridColumns } from "./importDataGridProvider";

interface Props {
  isLoading: boolean;
}

const ImportDataGrid = ({ isLoading }: Props) => {
  const { pageState, setPageState } = useImportDataContext();

  const handleScrollEnd = () => {
    setTimeout(() => setPageState(nextPageAction()), 100);
  };

  return (
    <DataGrid<DataImportInfo>
      columns={getImportDataGridColumns()}
      getRowId={getRowId}
      columnHeaderHeight={36}
      rowHeight={40}
      onRowsScrollEnd={handleScrollEnd}
      rows={pageState.items}
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableColumnReorder
      disableRowSelectionOnClick
      disableColumnSorting
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => <NoRows />,
      }}
      loading={isLoading}
    />
  );
};

const NoRows = () => (
  <CenteredWrapper>
    <Typography color="text.secondary">You don't have any imports yet</Typography>
  </CenteredWrapper>
);

const getRowId = (row: DataImportInfo) => row.id;

export default ImportDataGrid;
