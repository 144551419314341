import FilesDropArea from "../../../../../../../shared/components/FilesDropArea";
import { formatFileSize } from "../../../../../../../shared/utilities/fileHelper";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import { acceptedFileExtensions, addDocumentsToUploadAction, maxFileSize } from "./fundraisingDocumentsState";

const FundraisingDocumentsUploadArea = () => {
  const { isContentEditable } = useFundraisingDetailsPageContext();
  const { defaultCategory, setDocumentsState } = useFundraisingDocumentsContext();

  if (!isContentEditable) {
    return null;
  }

  const subtitle = `Maximum file size: ${formatFileSize(
    maxFileSize
  )}. Supported formats: pdf, doc(x), xls(x)(m), ppt(x), jpg, jpeg, png, webp.`;

  const handleFilesAdd = (files: FileList) => {
    setDocumentsState(addDocumentsToUploadAction(files, defaultCategory));
  };

  return (
    <FilesDropArea
      allowMultiple
      acceptedFileExtensions={acceptedFileExtensions}
      subtitle={subtitle}
      onFilesAdd={handleFilesAdd}
    />
  );
};

export default FundraisingDocumentsUploadArea;
