import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { FilterOperator, OperatorOption } from "./filterTypes";

interface Props<T extends FilterOperator> {
  filterName: string;
  options: readonly OperatorOption<T>[];
  value: T;
  onChange?: (value: T) => void;
}

const OperatorSelect = <T extends FilterOperator>({ filterName, options, value, onChange }: Props<T>) => {
  return (
    <Stack direction="row" alignItems="baseline" spacing={1} width="100%">
      <Typography color="text.secondary">{filterName}</Typography>
      {options.length > 1 ? (
        <Select<T>
          variant="standard"
          disableUnderline
          value={value}
          onChange={({ target }) => onChange?.(target.value as T)}
          renderValue={(value) => (
            <Typography variant="subtitle2">{options.find((o) => o.value === value)?.label}</Typography>
          )}
          sx={{
            ".MuiSelect-select": { "&:focus": { bgcolor: "unset" } },
          }}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              <Typography>{label}</Typography>
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography variant="subtitle2">{options[0]?.label}</Typography>
      )}
    </Stack>
  );
};

export default OperatorSelect;
