import { Typography } from "@mui/material";
import { requiredValidator, ValidationResult } from "../../../../shared/utilities/validators";
import { EntityFieldComponentProps } from "../entityFieldTypes";
import { removeDecimalNotSupportedCharacters } from "../fieldValuesManagement";
import EntityTextFieldComponent from "./EntityTextFieldComponent";

interface EntityFieldNumberComponentPropsTyped extends EntityFieldComponentProps<number> {
  precision: number;
  prefix?: string;
  postfix?: string;
  textPostfix?: string;
}

const EntityNumberComponent = ({
  value,
  onChange,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  precision,
  prefix,
  postfix,
  textPostfix,
  fieldInputId,
}: EntityFieldNumberComponentPropsTyped) => {
  const handleChange = (value: string, fieldId: string, validationResult: ValidationResult) => {
    onChange(value ? parseFloat(value) : undefined, fieldId, validationResult);
  };

  const numberPostfix = precision > 0 ? `.${Array(precision).fill("0").join("")}` : "";

  const validator = fieldAttributes.includes("Required") ? requiredValidator : undefined;

  return (
    <EntityTextFieldComponent
      prefix={prefix}
      postfix={postfix || numberPostfix}
      value={value !== undefined && value !== null ? value + "" : ""}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      fieldInputId={fieldInputId}
      onChange={handleChange}
      transformOnChangeFunc={removeDecimalNotSupportedCharacters}
      displayValueComponent={
        value !== undefined ? (
          <Typography>{`${prefix ?? ""}${value?.toFixed(precision)}${textPostfix ?? ""}`}</Typography>
        ) : null
      }
      validator={validator}
      textPostfix={textPostfix}
    />
  );
};

export default EntityNumberComponent;
