import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import { Grid, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import AssistantAvatar from "../AssistantAvatar";

interface Props {
  showRequestExamples: () => void;
}

const HelpInfoItem = ({ children }: PropsWithChildren) => (
  <Stack
    sx={(theme) => ({
      flexDirection: "row",
      alignItems: "center",
      gap: 1,
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
        svg: {
          color: theme.palette.primary.main,
        },
      },
    })}
  >
    {children}
  </Stack>
);

const HelpInfo = ({ showRequestExamples }: Props) => {
  return (
    <Grid container sx={{ flex: 1, alignItems: "center", maxWidth: "500px" }}>
      <Stack sx={{ alignItems: "center", gap: 1.5 }}>
        <AssistantAvatar variant="large" />
        <Typography variant="h5" mt={1}>
          Welcome to Entrilia Assistant!
        </Typography>
        <Typography color="text.secondary" sx={{ textAlign: "center" }}>
          Meet our Fund Administration Assistant Chat AI - the ultimate virtual companion for efficient fund management!
          Embrace the future of fund administration today!
        </Typography>
        <Typography color="text.secondary" sx={{ textAlign: "center", mt: 1 }}>
          Need Help?
        </Typography>
        <Stack sx={{ gap: 0.8 }}>
          <HelpInfoItem>
            <HelpOutlineOutlinedIcon sx={(theme) => ({ fontSize: "17px", color: theme.palette.secondary.light })} />
            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
              About assistant chat
            </Typography>
          </HelpInfoItem>
          <HelpInfoItem>
            <DriveFileRenameOutlineOutlinedIcon
              sx={(theme) => ({ fontSize: "17px", color: theme.palette.secondary.light })}
            />
            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
              How to write requests?
            </Typography>
          </HelpInfoItem>
          <HelpInfoItem>
            <LightModeRoundedIcon sx={(theme) => ({ fontSize: "17px", color: theme.palette.secondary.light })} />
            <Typography variant="subtitle2" sx={{ textAlign: "center" }} onClick={showRequestExamples}>
              Examples of requests
            </Typography>
          </HelpInfoItem>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default HelpInfo;
