import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { FundraisingAccessItem } from "../../../../../../api/types/fundraisingTypes";

interface Props {
  onEditAccess: (row: FundraisingAccessItem) => void;
  onRemoveAccess: (row: FundraisingAccessItem) => void;
}

const FundraisingAccessGridContext = createContext<Props | undefined>(undefined);

export const FundraisingAccessGridContextProvider = ({
  onEditAccess,
  onRemoveAccess,
  children,
}: PropsWithChildren<Props>) => (
  <FundraisingAccessGridContext.Provider value={{ onEditAccess, onRemoveAccess }}>
    {children}
  </FundraisingAccessGridContext.Provider>
);

export const useFundraisingAccessGridContext = () =>
  defined(useContext(FundraisingAccessGridContext), "Attempt to use FundraisingAccessGridContext without provider");
