import { generatePath, useMatch } from "react-router-dom";
import approvalsApi from "../../api/mock/approvals/approvalsMockApi";
import { useClientContext } from "../../context/ClientContext";
import { pageRoutes } from "../../routes";
import MenuLinkItem from "../common/MenuLinkItem";
import SubMenuList from "./SubMenuList";

const WorkflowsMenu = () => {
  const { clientCode } = useClientContext();

  const routePattern = `/:clientCode/${pageRoutes.workflows}/:page/*`;

  const routeMatch = useMatch(routePattern);
  const currentPage = routeMatch?.params["page"];

  const buildFullPath = (page: string) => generatePath(routePattern, { clientCode, page });

  const approvalsCount = approvalsApi.getApprovalsCounterForCurrentUser();

  return (
    <SubMenuList>
      <MenuLinkItem
        subItem
        active={currentPage === pageRoutes.approvals}
        title="Approvals"
        href={buildFullPath(pageRoutes.approvals)}
        badgeCount={approvalsCount}
      />
    </SubMenuList>
  );
};

export default WorkflowsMenu;
