import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Avatar, Breakpoint, Container, IconButton, Stack, SvgIconProps, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IntegrationAppStatus } from "../../../api/types/clientTypes";
import { pageRoutes } from "../../../routes";
import ApplicationStatusTag from "../../common/ApplicationStatusTag";

interface Props {
  title: string;
  logoSrc?: string;
  Icon?: FC<SvgIconProps>;
  text: string;
  appStatus: IntegrationAppStatus | undefined;
  maxWidth?: Breakpoint;
}

const IntegrationHeader = ({ title, logoSrc, Icon, text, appStatus, maxWidth }: Props) => {
  const { client } = useParams();
  const navigate = useNavigate();

  const handleBackClick = () => navigate(`/${client}/${pageRoutes.settings}/${pageRoutes.settingsIntegrations}`);

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center" borderBottom={1} borderColor="divider" px={3} py={2}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackRoundedIcon />
        </IconButton>
        {logoSrc && <Avatar src={logoSrc} variant="square" alt="logo" sx={{ width: 32, height: 32 }} />}
        {Icon && <Icon sx={{ width: 32, height: 32 }} />}
        <Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6">{title}</Typography>
            <ApplicationStatusTag appStatus={appStatus} />
          </Stack>
        </Stack>
      </Stack>
      <Container disableGutters maxWidth={maxWidth ?? false} sx={{ mx: 0 }}>
        <Stack spacing={1} px={3} pt={2.5}>
          <Typography variant="subtitle1">Overview</Typography>
          <Typography>{text}</Typography>
        </Stack>
      </Container>
    </>
  );
};

export default IntegrationHeader;
