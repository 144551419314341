import { useCallback, useState } from "react";
import {
  AddReportGroupRequest,
  ReorderReportGroupsRequest,
  ReplaceReportGroupRequest,
  ReportGroup,
  ReportGroupsResponse,
  UpdateReportGroupRequest,
} from "../../../api/biClient.types";
import { AnyObject } from "../../../../types";
import { ApiResponse } from "../../../../api/types";

interface Props {
  getReportGroups: (clientCode: string) => Promise<ApiResponse<ReportGroupsResponse>>;
  addReportGroup: (request: AddReportGroupRequest) => Promise<ApiResponse<ReportGroup>>;
  updateReportGroup: (request: UpdateReportGroupRequest) => Promise<ApiResponse<ReportGroup>>;
  deleteReportGroup: (clientCode: string, groupId: string) => Promise<ApiResponse<ReportGroup>>;
  replaceReportGroup: (request: ReplaceReportGroupRequest) => Promise<ApiResponse<AnyObject>>;
  reorderReportGroups: (request: ReorderReportGroupsRequest) => Promise<ApiResponse<ReportGroupsResponse>>;
}
export default function useGroups({
  getReportGroups: getGroups,
  addReportGroup: add,
  updateReportGroup: update,
  deleteReportGroup: deleteGroup,
  replaceReportGroup: replace,
  reorderReportGroups: reorder,
}: Props) {
  const [groups, setGroups] = useState<Record<string, ReportGroup[]>>({});
  const [error, setError] = useState<string | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);

  const reload = useCallback(async (clientCode: string) => {
    const resp = await getGroups(clientCode);
    if (resp.success) {
      setGroups((prev) => {
        const values = { ...prev };
        values[clientCode] = resp.data;
        return values;
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const replaceReportGroup = useCallback(
    async (clientCode: string, fromGroup: string, toGroup: string, reloadReports: () => Promise<void>) => {
      const resp = await replace({ clientCode, fromGroup, toGroup });
      if (resp.success) {
        await reloadReports();
        return resp.data;
      }
      throw new Error(resp.error?.message);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateReportGroup = useCallback(
    async (clientCode: string, group: ReportGroup) => {
      const resp = await update({ clientCode, group });
      try {
        if (resp.success) {
          await reload(clientCode);
          return;
        }
        setError(resp.error?.message);
      } catch {
        setError("Report groups updating failed");
      } finally {
        setLoaded(true);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [reload]
  );

  const addReportGroup = useCallback(
    async (clientCode: string, group: ReportGroup) => {
      const resp = await add({ clientCode, group });
      try {
        if (resp.success) {
          await reload(clientCode);
          return resp.data;
        }
        setError(resp.error?.message);
        return;
      } catch {
        setError("Report groups adding failed");
        return;
      } finally {
        setLoaded(true);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [reload]
  );

  const deleteReportGroup = useCallback(
    async (clientCode: string, groupId: string) => {
      const resp = await deleteGroup(clientCode, groupId);
      try {
        if (resp.success) {
          await reload(clientCode);
          return;
        }
        setError(resp.error?.message);
      } catch {
        setError("Report groups deleting failed");
      } finally {
        setLoaded(true);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [reload]
  );

  const reorderReportGroups = useCallback(async (clientCode: string, groups: ReportGroup[]) => {
    try {
      const resp = await reorder({ clientCode, groups });
      if (resp.success) {
        setGroups((prev) => {
          const values = { ...prev };
          values[clientCode] = resp.data;
          return values;
        });
        return;
      }
      setError(resp.error?.message);
    } catch {
      setError("Report groups reordering failed");
    } finally {
      setLoaded(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = useCallback(async (clientCode: string) => {
    try {
      const resp = await getGroups(clientCode);
      if (resp.success === false) {
        setError(resp.error?.message);
        return;
      }
      setGroups((prev) => {
        const values = { ...prev };
        values[clientCode] = resp.data;
        return values;
      });
    } catch {
      setError("Report groups loading failed");
    } finally {
      setLoaded(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ui: {
      error,
      loaded,
    },
    actions: {
      load,
      reorder: reorderReportGroups,
      delete: deleteReportGroup,
      add: addReportGroup,
      update: updateReportGroup,
      replace: replaceReportGroup,
    },
    groups,
  };
}
