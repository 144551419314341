import { FormControl, FormHelperText, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { DataCatalogueEntityType } from "../../../../api/types/fileTypes";
import { getEntityTitle, orderedEntityTypesForTags } from "../../../common/files/fileDetailsHelper";
import { TagFormState } from "./dialogState";

interface Props {
  form: TagFormState;
  onChange: (form: TagFormState) => void;
  disableEntitySelection?: boolean;
}

const TagEditForm = ({ form, onChange, disableEntitySelection }: Props) => {
  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        placeholder="Type tag name..."
        value={form.name}
        onChange={(e) => onChange({ ...form, name: e.target.value })}
        error={!!form.errors.name}
        helperText={form.errors.name}
      />
      <Stack spacing={1}>
        <Typography variant="subtitle2">Entity</Typography>
        <FormControl error={!!form.errors.entityType}>
          <Select
            fullWidth
            disabled={disableEntitySelection}
            value={form.entityType}
            onChange={(e) => onChange({ ...form, entityType: e.target.value as DataCatalogueEntityType })}
          >
            {orderedEntityTypesForTags.map((entityType) => (
              <MenuItem key={entityType} value={entityType}>
                <Typography>{getEntityTitle(entityType)}</Typography>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{form.errors.entityType}</FormHelperText>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default TagEditForm;
