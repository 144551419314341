import { logError } from "../../../../shared/logging";
import { EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError } from "../helpers";
import EntityFieldSelectComponent from "./EntityFieldSelectComponent";

const EntitySelectUdfField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntitySelectUdfField]");
    return null;
  }

  if (fieldConfiguration?.$type !== "UserDefinedOptionsSelect") {
    const error = `Invalid configuration for Select UDF field: ${fieldConfiguration?.$type}`;
    logError(error, "[EntitySelectUdfField]");
    return null;
  }

  return (
    <EntityFieldSelectComponent
      fieldId={fieldId}
      value={value}
      options={fieldConfiguration.userDefinedOptions}
      fieldAttributes={fieldAttributes}
      onChange={(value, validationResult) => onChange(value, fieldId, validationResult)}
      fieldInputId={fieldInputId}
    />
  );
};

export default EntitySelectUdfField;
