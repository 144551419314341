import CopyToBufferButton from "../../../../shared/components/inputs/CopyToBufferButton";
import { logError } from "../../../../shared/logging";
import { ValidationResult } from "../../../../shared/utilities/validators";
import { EntityFieldComponentProps, EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError } from "../helpers";
import EntityFieldTypeWrapper from "./EntityFieldTypeWrapper";
import EntityTextFieldComponent from "./EntityTextFieldComponent";

const EntityPhoneField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityPhoneField]");
    return null;
  }

  return (
    <EntityFieldTypeWrapper
      value={value}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={onChange}
      fieldInputId={fieldInputId}
      ComponentToRender={EntityPhoneFieldComponent}
    />
  );
};

const EntityPhoneFieldComponent = ({
  value,
  onChange,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  fieldInputId,
}: EntityFieldComponentProps<string>) => {
  const handleInputValue = (value: string) => {
    return value.replace(/[^\d()+\-\s]/g, "");
  };

  const onChanged = (value: string, fieldId: string, validationResult: ValidationResult) => {
    onChange(handleInputValue(value), fieldId, validationResult);
  };

  return (
    <EntityTextFieldComponent
      value={value ? value + "" : ""}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      fieldInputId={fieldInputId}
      onChange={onChanged}
      transformOnChangeFunc={handleInputValue}
      displayHoverComponent={value ? <CopyToBufferButton color="action" textToCopy={value} /> : null}
    />
  );
};

export default EntityPhoneField;
