import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../shared/components/DialogeCloseButton";
import adminApi from "../../api/adminApi";
import CommentDeletedIcon from "../../icons/CommentDeletedIcon";
import { useNotesContext } from "./NotesContext";

interface Props {
  noteId: string | undefined;
  onCancel: () => void;
  onDeleted: (noteId: string) => void;
}

const deleteNote = withErrorHandling(adminApi.deleteNote);

const DeleteNoteDialog = ({ noteId, onCancel, onDeleted }: Props) => {
  const { entityType, entityId, objectType } = useNotesContext();
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    onCancel();
    setErrorMessage("");
  };

  const handleDelete = async () => {
    if (!noteId) {
      return;
    }

    setDeleting(true);
    const [resp, error] = await deleteNote(entityType, entityId, noteId, objectType);
    if (error || !resp.result) {
      setErrorMessage("Failed to delete the note");
    } else {
      onDeleted(noteId);
    }
    setDeleting(false);
  };

  return (
    <Dialog open={Boolean(noteId)} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={handleClose} />
      <DialogContent sx={(theme) => ({ px: theme.spacing(4) })}>
        <Stack alignItems="center" spacing={2}>
          <CommentDeletedIcon sx={{ width: 66, height: 60, my: 2 }} />
          <Stack alignItems="center" spacing={0.5}>
            <Typography variant="h6">Are you sure you want to delete this note?</Typography>
            {errorMessage && (
              <Typography color="error" mt={2}>
                {errorMessage}
              </Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={deleting} onClick={handleDelete}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteNoteDialog;
