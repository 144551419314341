import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import { IconButton, Tooltip } from "@mui/material";
import { OrganizationMember } from "../../../../../api/types/userManagementTypes";
import ActionsMenuButton from "../../../../common/ActionsMenuButton";
import { useMembersContext } from "../MembersContext";

interface MemberRowActionsMenuProps {
  member: OrganizationMember;
  onSelected: (member: OrganizationMember | undefined) => void;
}

const MemberRowActionsMenu = ({ member, onSelected }: MemberRowActionsMenuProps) => {
  const { deleteMembers, duplicateNewMembersFrom, manageAccess, isLoading, isCurrentUser } = useMembersContext();

  const handleDelete = () => {
    deleteMembers([member.userId]);
  };

  const handleDuplicate = () => {
    duplicateNewMembersFrom(member);
  };

  const handleManageAccess = () => {
    manageAccess(member);
  };

  const ManageRowButtonComponent = (
    <IconButton sx={{ mr: 0.5 }} disabled={isLoading} onClick={handleManageAccess}>
      <ManageAccountsIcon />
    </IconButton>
  );

  return (
    <>
      {isLoading ? (
        ManageRowButtonComponent
      ) : (
        <Tooltip placement="top" title="Manage access" arrow>
          {ManageRowButtonComponent}
        </Tooltip>
      )}
      <ActionsMenuButton
        onOpen={() => onSelected(member)}
        onClose={() => onSelected(undefined)}
        items={[
          {
            icon: <PersonAddAltIcon sx={{ color: "action.active" }} color="secondary" />,
            label: "New with the same access",
            onClick: handleDuplicate,
          },
          {
            icon: <ManageAccountsIcon sx={{ color: "action.active" }} />,
            label: "Manage access",
            onClick: handleManageAccess,
          },
          {
            icon: <DeleteOutlineIcon color="error" />,
            label: "Remove",
            onClick: handleDelete,
            disabled: isCurrentUser(member.email),
          },
        ]}
      />
    </>
  );
};

export default MemberRowActionsMenu;
