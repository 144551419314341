import { SvgIconComponent } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  Icon: SvgIconComponent;
  label: string;
}

const FundraisingAttributeLine = ({ Icon, label, children }: PropsWithChildren<Props>) => {
  return (
    <Stack direction="row" spacing={1.5} alignItems="center" py={1}>
      <Stack direction="row" spacing={1.5} alignItems="center" width="8.5rem">
        <Icon color="action" />
        <Typography color="text.secondary">{label}</Typography>
      </Stack>
      {children}
    </Stack>
  );
};

export default FundraisingAttributeLine;
