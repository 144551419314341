import { AnyRecord } from "../../../../shared/types";
import { distinct } from "../../../../shared/utilities/arrayHelper";
import storage, { PersistedFilterState } from "../../../storage/storage";
import { TableColumnDefinition } from "../columns/columnTypes";
import { FilterValue, SearchFilter, SearchFilterDefinition, TableFilter, TableFilterDefinition } from "./filterTypes";
import {
  filterTableRows,
  getDefaultFilterValue,
  getFilterDefinition,
  initializeFilter,
  initializeSearchFilter,
} from "./handlers/filterHandlers";

export interface FilterState<R extends AnyRecord> {
  initialized: boolean;
  filterId: string;
  showFilters: boolean;
  visibleFilters: TableFilter<R>[];
  availableFilters: TableFilterDefinition<R>[];
  search: SearchFilter<R>;
  visibleColumns: TableColumnDefinition[];
  availableColumns: TableColumnDefinition[];
}

export type FilterAction<R extends AnyRecord> =
  | {
      type: "init";
      filterDefinitions: TableFilterDefinition<R>[];
      columnDefinitions: TableColumnDefinition[];
      searchDefinition: SearchFilterDefinition<R>;
    }
  | { type: "reset_filters" }
  | { type: "toggle_visibility" }
  | { type: "update_filter_value"; filterId: string; newValue: FilterValue }
  | { type: "search"; searchTerm: string }
  | { type: "add_filter"; filterId: string }
  | { type: "remove_filter"; filterId: string }
  | { type: "update_columns"; visibleColumns: TableColumnDefinition[]; availableColumns: TableColumnDefinition[] };

const initializeFilters = <R extends AnyRecord>(
  filterDefinitions: TableFilterDefinition<R>[],
  persistedState: PersistedFilterState | undefined
) => {
  if (persistedState) {
    const visibleFilters: TableFilter<R>[] = [];
    const visibleFilterIds = new Set<string>();
    for (const storedFilter of persistedState.filters) {
      const definition = filterDefinitions.find((def) => def.id === storedFilter.id && def.type === storedFilter.type);
      if (definition) {
        const filter = initializeFilter(definition);
        filter.value = storedFilter.value;
        visibleFilters.push(filter);
        visibleFilterIds.add(definition.id);
      }
    }

    const availableFilters = filterDefinitions.filter((def) => !visibleFilterIds.has(def.id));
    return { visibleFilters, availableFilters };
  }

  return { visibleFilters: [], availableFilters: [...filterDefinitions] };
};

const initializeColumns = (
  columnDefinitions: TableColumnDefinition[],
  persistedState: PersistedFilterState | undefined
) => {
  const visibleColumns: TableColumnDefinition[] = [];
  const visibleColumnIds = new Set<string>();

  if (persistedState) {
    for (const storedColumn of persistedState.columns) {
      const definition = columnDefinitions.find((def) => def.id === storedColumn.id);
      if (definition) {
        visibleColumns.push(definition);
        visibleColumnIds.add(definition.id);
      }
    }
  } else {
    for (const def of columnDefinitions) {
      if (!def.defaultHidden) {
        visibleColumns.push({ ...def });
        visibleColumnIds.add(def.id);
      }
    }

    if (visibleColumns.length === 0) {
      const firstColumn = columnDefinitions[0];
      if (firstColumn) {
        visibleColumns.push({ ...firstColumn });
        visibleColumnIds.add(firstColumn.id);
      }
    }
  }

  const availableColumns = columnDefinitions.filter((def) => !visibleColumnIds.has(def.id));

  return {
    visibleColumns,
    availableColumns,
  };
};

export const getEmptyState = <R extends AnyRecord>(filterId: string): FilterState<R> => ({
  initialized: false,
  filterId,
  showFilters: false,
  visibleFilters: [],
  availableFilters: [],
  search: { value: "", getFieldValues: () => [] },
  visibleColumns: [],
  availableColumns: [],
});

export const getInitialState = <R extends AnyRecord>(
  filterId: string,
  filterDefinitions: TableFilterDefinition<R>[],
  searchDefinition: SearchFilterDefinition<R>,
  columnDefinitions: TableColumnDefinition[]
): FilterState<R> => {
  const persistedState = storage.getFilterState(filterId);
  const { visibleFilters, availableFilters } = initializeFilters(filterDefinitions, persistedState);
  const { visibleColumns, availableColumns } = initializeColumns(columnDefinitions, persistedState);
  const search = initializeSearchFilter(searchDefinition);

  return {
    initialized: true,
    filterId,
    showFilters: false,
    visibleFilters,
    availableFilters,
    search,
    visibleColumns,
    availableColumns,
  };
};

const saveFilterState = <R extends AnyRecord>(state: FilterState<R>) => {
  const filters = state.visibleFilters.map(({ id, type, value }) => ({
    id,
    type,
    value,
  }));

  const columns = state.visibleColumns.map(({ id }) => ({ id }));

  storage.saveFilterState(state.filterId, { filters, columns });
};

export const createReducer =
  <R extends AnyRecord>() =>
  (state: FilterState<R>, action: FilterAction<R>): FilterState<R> => {
    switch (action.type) {
      case "init": {
        const persistedState = storage.getFilterState(state.filterId);
        const { visibleFilters, availableFilters } = initializeFilters(action.filterDefinitions, persistedState);
        const { visibleColumns, availableColumns } = initializeColumns(action.columnDefinitions, persistedState);
        const search = initializeSearchFilter(action.searchDefinition);
        return {
          ...state,
          initialized: true,
          visibleFilters,
          availableFilters,
          search,
          visibleColumns,
          availableColumns,
        };
      }
      case "toggle_visibility": {
        return { ...state, showFilters: !state.showFilters };
      }
      case "reset_filters": {
        state.visibleFilters.forEach((filter) => {
          filter.value = getDefaultFilterValue(filter);
        });

        saveFilterState(state);
        return { ...state };
      }
      case "update_filter_value": {
        const filter = state.visibleFilters.find((f) => f.id === action.filterId);
        if (filter) {
          filter.value = action.newValue;
        }

        saveFilterState(state);
        return { ...state };
      }
      case "search": {
        return { ...state, search: { ...state.search, value: action.searchTerm.trim() } };
      }
      case "add_filter": {
        const filterToAdd = state.availableFilters.find((f) => f.id === action.filterId);
        if (!filterToAdd) {
          return { ...state };
        }

        const newState = {
          ...state,
          visibleFilters: [...state.visibleFilters, initializeFilter(filterToAdd)],
          availableFilters: state.availableFilters.filter((f) => f.id !== action.filterId),
        };

        saveFilterState(newState);
        return newState;
      }
      case "remove_filter": {
        const filterToRemove = state.visibleFilters.find((f) => f.id === action.filterId);
        if (!filterToRemove) {
          return { ...state };
        }

        const newState = {
          ...state,
          visibleFilters: state.visibleFilters.filter((f) => f.id !== action.filterId),
          availableFilters: [...state.availableFilters, getFilterDefinition(filterToRemove)],
        };

        saveFilterState(newState);
        return newState;
      }
      case "update_columns": {
        const newState = {
          ...state,
          visibleColumns: action.visibleColumns,
          availableColumns: action.availableColumns,
        };
        saveFilterState(newState);
        return newState;
      }
      default: {
        return state;
      }
    }
  };

export const getAppliedMultiSelectFilterValues = <R extends AnyRecord>(filterId: string, state: FilterState<R>) => {
  const filter = state.visibleFilters.find(({ id }) => id === filterId);
  if (filter?.type === "multi_select" && filter.value.operator === "one_of") {
    return filter.value.selectedValues ?? [];
  }
  return [];
};

export const filterRecords = <R extends AnyRecord>(state: FilterState<R>, rows: R[]): R[] =>
  filterTableRows(rows, state.visibleFilters, state.search);

export const getVisibleColumnsAndFiltersIds = <R extends AnyRecord>(state: FilterState<R>): string[] => {
  const visibleColumnsFieldIds = state.visibleColumns.filter((col) => col.dynamic).map(({ id }) => id);
  const visibleFiltersFieldIds = state.visibleFilters.filter((filter) => filter.dynamic).map(({ id }) => id);
  return distinct([...visibleColumnsFieldIds, ...visibleFiltersFieldIds]);
};
