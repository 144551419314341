import { SvgIcon, SvgIconProps } from "@mui/material";

const FundAccountingIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 48 48" {...props}>
    <path
      d="M40 0H8C3.58172 0 0 3.58172 0 8V40C0 44.4183 3.58172 48 8 48H40C44.4183 48 48 44.4183 48 40V8C48 3.58172 44.4183 0 40 0Z"
      fill="#F6F8FA"
    />
    <path
      d="M40.3073 38.5356H23.3444L26.3715 42.9334H22.8875L18.8895 37.0506L20.9456 34.1378L22.9446 31.2821H26.4857L23.3444 35.6799H34.8815L28.4847 26.1989L34.8815 16.7751H23.5729V13.9194H40.3073L31.9115 26.1989L40.3073 38.5356Z"
      fill="#C7DBE5"
    />
    <path
      d="M24.8865 5.12379L21.4025 5.06668L24.4296 9.52158H7.46667L15.8053 21.8011L7.46667 34.1378H20.9456L22.9446 31.2821H12.8354L19.2893 21.8011L12.8354 12.3773H24.3725L21.2883 16.7751H24.8294L26.8284 13.9194L28.8845 10.9494L24.8865 5.12379Z"
      fill="#00A866"
    />
  </SvgIcon>
);

export default FundAccountingIcon;
