import { addDays, formatISO, startOfToday } from "date-fns";
import { LogsQueryParams } from "../../../api/types/logTypes";

interface SyncLogsState {
  page: number;
  severityFilter: SeverityFilter;
  rowsPerPage: number;
  daysPeriod: number;
}

export type SeverityFilter = "All" | "WarningsOnly" | "ErrorsOnly";

export const getInitialState = (): SyncLogsState => ({
  page: 0,
  rowsPerPage: 10,
  daysPeriod: 0,
  severityFilter: "All",
});

type Action =
  | {
      type: "PAGE_CHANGE";
      page: number;
    }
  | {
      type: "SEVERITY_CHANGE";
      filterValue: SeverityFilter;
    }
  | {
      type: "PERIOD_CHANGE";
      daysPeriod: number;
    }
  | {
      type: "ROWS_CHANGE";
      rowsPerPage: number;
    };

export const reducer = (state: SyncLogsState, action: Action): SyncLogsState => {
  switch (action.type) {
    case "PAGE_CHANGE": {
      return { ...state, page: action.page };
    }
    case "SEVERITY_CHANGE": {
      return { ...state, severityFilter: action.filterValue, page: 0 };
    }
    case "PERIOD_CHANGE": {
      return { ...state, daysPeriod: action.daysPeriod, page: 0 };
    }
    case "ROWS_CHANGE": {
      return { ...state, rowsPerPage: action.rowsPerPage, page: 0 };
    }
    default: {
      return state;
    }
  }
};

const getStartFromDate = (periodValue: number) =>
  periodValue > 0 ? formatISO(addDays(startOfToday(), 1 - periodValue)) : undefined;

export const stateToRequestParams = (state: SyncLogsState): LogsQueryParams => ({
  includeTotals: state.page === 0,
  page: state.page,
  size: state.rowsPerPage,
  startFromDate: getStartFromDate(state.daysPeriod),
  onlyWithWarnings: state.severityFilter === "WarningsOnly",
  onlyWithErrors: state.severityFilter === "ErrorsOnly",
});
