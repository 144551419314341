import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Contact } from "../../../../../api/adminApi";
import SimpleDialog from "../../../../common/SimpleDialog";
import MultiselectAutocomplete from "../../common/MultiselectAutocomplete";

interface Props {
  open: boolean;
  options: Contact[];
  title: string;
  description: string;
  onClose: () => void;
  onCreateNew: () => void;
  onContactsSelected: (contacts: Contact[]) => void;
}

const AssignInvestorContactDialog = ({
  open,
  options,
  title,
  description,
  onClose,
  onCreateNew,
  onContactsSelected,
}: Props) => {
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

  const handleAddContacts = () => {
    onContactsSelected(selectedContacts);
    setSelectedContacts([]);
  };

  const handleDialogClose = () => {
    setSelectedContacts([]);
    onClose();
  };

  return (
    <SimpleDialog
      dialogProps={{ maxWidth: "md", fullWidth: true }}
      title={title}
      open={open}
      onClose={handleDialogClose}
      onSubmit={handleAddContacts}
      submitButtonDisabled={selectedContacts.length === 0}
      submitButtonText="Add Contact(s)"
    >
      <Stack spacing={2}>
        <Typography color="text.secondary">{description}</Typography>
        <MultiselectAutocomplete
          options={options}
          values={selectedContacts}
          onSelected={setSelectedContacts}
          getOptionLabel={(option: Contact) => option.name}
          getOptionValue={(option: Contact) => option.id}
          getOptionDescription={(option: Contact) => option.email}
          noItemsText="No contacts"
          onCreateOption={onCreateNew}
        />
      </Stack>
    </SimpleDialog>
  );
};

export default AssignInvestorContactDialog;
