import { Box, Container, TextField, Typography } from "@mui/material";
import FullPageStatusProgress from "../../FullPageStatusProgress";
import { useImportCsvDataContext } from "../ImportCsvDataContext";
import { getSelectedTargetOptions, updateImportDescriptionAction } from "../importCsvDataPageState";
import ImportCsvCreationError from "./ImportCsvCreationError";
import ImportCsvDataFileUpload from "./ImportCsvDataFileUpload";

const ImportCsvDataFilesUploadStep = () => {
  const { pageState, setPageState } = useImportCsvDataContext();

  if (pageState.importCreationError) {
    return <ImportCsvCreationError />;
  }

  if (pageState.createImportStatus !== undefined) {
    return (
      <FullPageStatusProgress
        title="Please wait while we process the files. This may take some time."
        operationStatus={pageState.createImportStatus}
      />
    );
  }

  return (
    <Container sx={{ height: "100%" }}>
      <Box py={2.5}>
        <TextField
          variant="outlined"
          fullWidth
          label="Import name"
          value={pageState.importDescription}
          onChange={(e) => setPageState(updateImportDescriptionAction(e.target.value))}
          error={Boolean(pageState.importDescriptionValidationError)}
          helperText={pageState.importDescriptionValidationError}
        />
        <Typography color="text.secondary" mt={4} mb={2} textAlign="center">
          Download the template or export file, populate it with your data, and upload it for further review and import.
        </Typography>
        {getSelectedTargetOptions(pageState).map((target) => (
          <ImportCsvDataFileUpload key={target.key} targetOption={target} />
        ))}
      </Box>
    </Container>
  );
};

export default ImportCsvDataFilesUploadStep;
