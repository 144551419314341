import { Link } from "@mui/material";
import CopyToBufferButton from "../../../../shared/components/inputs/CopyToBufferButton";
import { logError } from "../../../../shared/logging";
import {
  combineValidators,
  emailValidator,
  requiredValidator,
  ValidationResult,
} from "../../../../shared/utilities/validators";
import { EntityFieldComponentProps, EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError } from "../helpers";
import EntityTextFieldComponent from "./EntityTextFieldComponent";

const EntityEmailField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityEmailField]");
    return null;
  }

  return (
    <EntityEmailFieldComponent
      value={value}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={onChange}
      fieldInputId={fieldInputId}
    />
  );
};

const EntityEmailFieldComponent = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentProps<string>) => {
  const handleFieldValueChange = (value: string | undefined, fieldId: string, validationResult: ValidationResult) => {
    //save empty email if not required as not defined value, the same behavior as for other fields. Backend validation does not allow empty email
    onChange(value === undefined || value === "" ? undefined : value, fieldId, validationResult);
  };

  const validator = fieldAttributes.includes("Required")
    ? combineValidators(requiredValidator, emailValidator)
    : emailValidator;

  return (
    <>
      <EntityTextFieldComponent
        value={value || ""}
        fieldId={fieldId}
        fieldConfiguration={fieldConfiguration}
        fieldAttributes={fieldAttributes}
        onChange={handleFieldValueChange}
        fieldInputId={fieldInputId}
        validator={validator}
        displayValueComponent={
          value ? (
            <Link
              sx={(t) => ({ color: t.palette.text.primary })}
              onClick={(e) => e.stopPropagation()}
              href={`mailto:${value}`}
              underline="hover"
              target="_blank"
            >
              {value}
            </Link>
          ) : null
        }
        displayHoverComponent={value ? <CopyToBufferButton color={"action"} textToCopy={value} /> : null}
      />
    </>
  );
};

export default EntityEmailField;
