import { Box, Grid, Stack, Typography } from "@mui/material";
import { useClientContext } from "../../../context/ClientContext";
import ClientCard from "./ClientCard";
import { OrganizationCompaniesGroup } from "../../leftSideMenu/client-selector/client-selector-helper";
import BadgeDetached from "../../../../shared/components/BadgeDetached";

interface Props {
  groups: OrganizationCompaniesGroup[];
}

const ClientsGridView = ({ groups }: Props) => {
  const { onNewClientSelected } = useClientContext();

  return (
    <>
      {groups.map((value, i) => (
        <Stack key={i} gap={1}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, py: 1 }}>
            <Typography variant="subtitle1">{value.organization?.title || "Unmanaged"}</Typography>
            <BadgeDetached
              badgeContent={value.companies.length}
              color="secondary"
              sx={(t) => ({ color: t.palette.secondary.main })}
            />
          </Box>
          <Grid container spacing={2}>
            {value.companies.map((client) => (
              <Grid item key={client.clientCode}>
                <ClientCard client={client} onClick={() => onNewClientSelected(client.clientCode)} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
    </>
  );
};

export default ClientsGridView;
