import { Link } from "@mui/material";
import { logError } from "../../../../shared/logging";
import { tryGetUrlWithProtocol } from "../../../../shared/utilities/urlHelper";
import { combineValidators, requiredValidator, websiteUrlValidator } from "../../../../shared/utilities/validators";
import { EntityFieldComponentProps, EntityFieldComponentPropsBase } from "../entityFieldTypes";
import { getInvalidFieldValueError } from "../helpers";
import EntityFieldTypeWrapper from "./EntityFieldTypeWrapper";
import EntityTextFieldComponent from "./EntityTextFieldComponent";

const EntityUrlField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityUrlField]");
    return null;
  }

  return (
    <EntityFieldTypeWrapper
      value={value}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={onChange}
      fieldInputId={fieldInputId}
      ComponentToRender={EntityUrlFieldComponent}
    />
  );
};

export default EntityUrlField;

const EntityUrlFieldComponent = ({
  value,
  onChange,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  fieldInputId,
}: EntityFieldComponentProps<string>) => {
  const url = value ? tryGetUrlWithProtocol(value) : undefined;

  const validator = fieldAttributes.includes("Required")
    ? combineValidators(requiredValidator, websiteUrlValidator)
    : websiteUrlValidator;

  return (
    <EntityTextFieldComponent
      value={value || ""}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={onChange}
      fieldInputId={fieldInputId}
      validator={validator}
      displayValueComponent={
        url ? (
          <Link onClick={(e) => e.stopPropagation()} color="primary" href={url.href} underline="hover" target="_blank">
            {value}
          </Link>
        ) : null
      }
    />
  );
};
