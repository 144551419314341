import { parseISO } from "date-fns";
import { numberComparerDescBy } from "../../../shared/utilities/arrayHelper";
import { Note } from "../../api/types/notesTypes";

export type NoteItem = Note;

interface NotesState {
  notes: NoteItem[];
  editingNoteId: string | undefined;
  addingNote: boolean;
}

type Action =
  | {
      type: "Add";
    }
  | {
      type: "Edit";
      noteId: string;
    }
  | {
      type: "CancelEdit";
    }
  | {
      type: "Created";
      note: Note;
    }
  | {
      type: "Updated";
      note: Note;
    }
  | {
      type: "Deleted";
      noteId: string;
    };

const sortNotes = (notes: NoteItem[]) =>
  [...notes].sort(numberComparerDescBy((note) => parseISO(note.updatedAt).getTime()));

export const getInitialState = (storedNotes: Note[]): NotesState => ({
  notes: sortNotes(storedNotes),
  editingNoteId: undefined,
  addingNote: false,
});

export const reducer = (state: NotesState, action: Action): NotesState => {
  switch (action.type) {
    case "Add": {
      return { ...state, editingNoteId: undefined, addingNote: true };
    }
    case "Edit": {
      return { ...state, editingNoteId: action.noteId, addingNote: false };
    }
    case "CancelEdit": {
      return { ...state, editingNoteId: undefined, addingNote: false };
    }
    case "Created": {
      const updatedNotes = [...state.notes, action.note];
      return { ...state, notes: sortNotes(updatedNotes), editingNoteId: undefined, addingNote: false };
    }
    case "Updated": {
      const updatedNotes = state.notes.map((n) => (n.id === action.note.id ? action.note : n));
      return { ...state, notes: sortNotes(updatedNotes), editingNoteId: undefined, addingNote: false };
    }
    case "Deleted": {
      const updatedNotes = state.notes.filter((n) => n.id !== action.noteId);
      return { ...state, notes: sortNotes(updatedNotes) };
    }
    default: {
      return state;
    }
  }
};
