import { Stack, Typography } from "@mui/material";
import BadgeDetached from "../../../../../shared/components/BadgeDetached";
import { groupByToMap } from "../../../../../shared/utilities/arrayHelper";
import { AssignedEntityChange } from "../../../../api/types/changeRequestTypes";
import { formatAssignedEntityType } from "../changeRequestsFormatters";

interface Props {
  assignedEntityChanges: AssignedEntityChange[];
}

const AssignedEntitiesSection = ({ assignedEntityChanges }: Props) => {
  const changesByType = [...groupByToMap(assignedEntityChanges, (change) => change.type)];

  return changesByType.map(([assignedEntityType, changes]) => (
    <Stack spacing={1.5} alignItems="flex-start">
      <Stack direction="row" spacing={0.5}>
        <Typography variant="subtitle2">{formatAssignedEntityType(assignedEntityType)}</Typography>
        <BadgeDetached badgeContent={changes.length} color="secondary" />
      </Stack>
      <Stack spacing={0.5} alignItems="flex-start">
        {changes.map((assignedEntity) => (
          <Typography key={assignedEntity.id}>{assignedEntity.displayName}</Typography>
        ))}
      </Stack>
    </Stack>
  ));
};

export default AssignedEntitiesSection;
