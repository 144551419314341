import { Stack } from "@mui/material";
import { useReducer, useState } from "react";
import { Note } from "../../api/types/notesTypes";
import AddNoteButton from "./AddNoteButton";
import DeleteNoteDialog from "./DeleteNoteDialog";
import NoNotes from "./NoNotes";
import NoteCard from "./NoteCard";
import NoteEditor from "./NoteEditor";
import { useNotesContext } from "./NotesContext";
import { getInitialState, reducer } from "./notesState";

interface Props {
  notes: Note[];
}

const NotesList = ({ notes }: Props) => {
  const { hasPermissionsToManageNotes } = useNotesContext();

  const [state, dispatch] = useReducer(reducer, getInitialState(notes));
  const [deletingNoteId, setDeletingNoteId] = useState<string>();

  const handleAddNote = () => {
    dispatch({ type: "Add" });
  };

  const handleEditNote = (noteId: string) => {
    dispatch({ type: "Edit", noteId });
  };

  const handleCancelEditingNote = () => {
    dispatch({ type: "CancelEdit" });
  };

  const handleDeleteNote = (noteId: string) => {
    setDeletingNoteId(noteId);
  };

  const handleNoteSaved = (noteId: string | undefined, note: Note) => {
    const isNewNote = !noteId;
    if (isNewNote) {
      dispatch({ type: "Created", note });
    } else {
      dispatch({ type: "Updated", note });
    }
  };

  const handleNoteDeleted = (noteId: string) => {
    dispatch({ type: "Deleted", noteId });
    setDeletingNoteId(undefined);
  };

  return (
    <Stack width="100%" px={2.5} pb={2} spacing={1.5}>
      {hasPermissionsToManageNotes && !state.addingNote && <AddNoteButton onClick={handleAddNote} />}
      {state.notes.length === 0 && !state.addingNote && <NoNotes />}
      {state.addingNote && (
        <NoteEditor initialContent="" onSaved={handleNoteSaved} onCancel={handleCancelEditingNote} />
      )}
      {state.notes.map((note) =>
        state.editingNoteId === note.id ? (
          <NoteEditor
            key={note.id}
            id={note.id}
            initialContent={note.htmlContent}
            onSaved={handleNoteSaved}
            onCancel={handleCancelEditingNote}
          />
        ) : (
          <NoteCard key={note.id} note={note} onEdit={handleEditNote} onDelete={handleDeleteNote} />
        )
      )}
      <DeleteNoteDialog
        noteId={deletingNoteId}
        onCancel={() => setDeletingNoteId(undefined)}
        onDeleted={handleNoteDeleted}
      />
    </Stack>
  );
};

export default NotesList;
