import { pageRoutes } from "../../../../routes";
import ObjectsListPage from "../../../entityFields/grid/ObjectsListPage";

const VendorsPage = () => {
  return (
    <ObjectsListPage
      objectType="Vendor"
      objectDetailsPathPattern={`/:clientCode/${pageRoutes.expenseManagement}/${pageRoutes.vendors}/:id`}
      objectsTitle="Vendors"
    />
  );
};

export default VendorsPage;
