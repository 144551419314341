import { Stack, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { EntriliaProduct } from "../../../../../shared/api/types";
import RoundedTable from "../../../../../shared/components/RoundedTable";
import { entriliaProductCaptionMap } from "../../../../utilities/enumCaptions";
import EntriliaProductIcon from "../../../common/domain-icons/EntriliaProductIcon";

interface Props {
  products: EntriliaProduct[];
}

const ObjectProductsTable = ({ products }: Props) => {
  if (!products.length) {
    return null;
  }

  return (
    <RoundedTable>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map((product) => (
          <TableRow key={product}>
            <TableCell>
              <Stack direction="row" spacing={1.5}>
                <EntriliaProductIcon product={product} color="action" />
                <Typography>{entriliaProductCaptionMap[product]}</Typography>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </RoundedTable>
  );
};

export default ObjectProductsTable;
