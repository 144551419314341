import { pdfjs } from "react-pdf";

// https://github.com/wojtekmaj/react-pdf/tree/main?tab=readme-ov-file#browser-support
// https://github.com/wojtekmaj/react-pdf/issues/1811
let isModernPdfJsBuildSupported = true;

// @ts-expect-error Polyfill for Promise
if (typeof Promise.withResolvers !== "function") {
  isModernPdfJsBuildSupported = false;
  if (window) {
    // @ts-expect-error Polyfill for Promise
    window.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  }
}

export const initPdfViewer = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = isModernPdfJsBuildSupported
    ? new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString()
    : new URL("pdfjs-dist/legacy/build/pdf.worker.min.mjs", import.meta.url).toString();
};
