import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import DocumentSuccessIcon from "../../../../../shared/icons/DocumentSuccess";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { InvestorChangeRequest } from "../../../../api/types/changeRequestTypes";

interface Props {
  workflowId: string;
  open: boolean;
  onClose: () => void;
  onConfirmed: (changeRequest: InvestorChangeRequest) => void;
}

const approveRequest = withErrorHandling(adminApi.approveInvestorChangeRequest);

const ApprovalConfirmationDialog = ({ workflowId, open, onClose, onConfirmed }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  const handleApproveClick = async () => {
    setLoading(true);
    const [changeRequest, error] = await approveRequest({ workflowId });
    setLoading(false);

    if (error) {
      logError(error, "[ApprovalConfirmationDialog]");
      sendNotificationError("Failed to approve the request");
      return;
    }

    onClose();
    sendNotification("The request was approved");
    onConfirmed(changeRequest);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Approve</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DocumentSuccessIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={0.5}>
            <Typography variant="h6">Approve request?</Typography>
            <Typography color="text.secondary" textAlign="center">
              The changes will be applied to the system immediately after approval.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={loading} onClick={handleApproveClick}>
          Approve
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalConfirmationDialog;
