import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import SkipErrorsIcon from "../../../icons/SkipErrorsIcon";

interface Props {
  errorsCount?: number;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ImportErrorsWarningDialog = ({ errorsCount, open, onClose, onConfirm }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Delete Data Import</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <SkipErrorsIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Skip {errorsCount || ""} rows with errors?</Typography>
            <Typography color="text.secondary">Rows with errors will be excluded from the final import.</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Continue Editing
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Start Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportErrorsWarningDialog;
